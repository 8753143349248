/**
 * USER_ROLE
 * Description: Enum about user roles
 * Author: Humberto S
 */
export enum USER_ROLE {
  owner = 7,
  officer = 6,
  manager = 3,
  member = 5
}
