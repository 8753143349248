import './styles.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MembersFilters from '@components/Members/MembersFilters/MembersFilters';
import FiltersOverview from '@components/Shared/FiltersOverview/FiltersOverview';
import { MembersData, MembersModel } from '@models/members.model';
import { filterMembersData } from '@utils/filters';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE } from '@utils/routes';
//import { useQuery } from '@apollo/client';
//import { GET_MEMBERS } from '@graphql/members.queries';
import MembersTable from '@components/Members/MembersTable/MembersTable';
import { RootState } from '@store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
//import { setOrganizationLoading } from '@store/Organization/Info/info.action';
import { getMembersNoLimit } from '@services/members.service';
import { Button, Icon } from '@material-ui/core';
import AddMemberModal from '@components/Members/AddMemberModal/AddMemberModal';
import { setAddMemberModal } from '@store/Shared/shared.action';
import { USER_ROLE } from '@utils/user';
import { TOUR_MEMBERS_TABLE } from '@samples/tour.members.sample';

const MembersContainer = () => {
  const dispatch = useDispatch();
  const addMemberModal = useSelector((state: RootState) => state.Shared.modals.addMemberModal);
  const tegduRole = useSelector((state: RootState) => state.User.Info.user.role.id);
  const [data, setData] = useState<MembersModel[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(undefined);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const isTourOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  //const organizationInfoLoading = useSelector((state: RootState) => state.Organization.Info.loading);
  // const { data, loading, error, refetch } = useQuery<GqlMembers>(GET_MEMBERS,
  //   {
  //     variables: { id: organizationId, start: 0, limit: 100 },
  //     //fetchPolicy: 'no-cache'
  //   }
  // );

  const getInfoMembers = useCallback(async () => {
    if (organizationId !== null) {
      setLoading(true);
      try {
        const response = await getMembersNoLimit(organizationId);
        setLoading(false);
        setData(response);
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    }
  }, [organizationId]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [roleValue, setRoleValue] = useState('');
  const [teamValue, setTeamValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [clearFilters, setClearFilters] = useState(false);
  const history = useHistory();

  const filteredData = useMemo(() => {
    if (data !== undefined) {
      return filterMembersData(data, { searchValue, roleValue, teamValue, statusValue });
    } else {
      return [];
    }
  }, [searchValue, roleValue, teamValue, statusValue, data]);

  // const onUpdateState = useMemo(() => {
  //   return organizationInfoLoading === 'UPDATED'
  // }, [organizationInfoLoading])

  const getFilterValues = useCallback((filters: any) => {
    //setSimulatePag(true)
    setSearchValue(filters.searchValue);
    setRoleValue(filters.roleValue);
    setTeamValue(filters.teamValue);
    setStatusValue(filters.statusValue);
  }, []);

  const resetFilters = useCallback(() => {
    setClearFilters(true);
    setSearchValue('');
    setRoleValue('');
    setTeamValue('');
    setStatusValue('');
    //setSimulatePag(false)
  }, []);

  const showFiltersOverview = useMemo(() => {
    if (searchValue === '' && roleValue === '' && teamValue === '' && statusValue === '') {
      return false;
    } else {
      setClearFilters(false);
      return true;
    }
  }, [searchValue, roleValue, teamValue, statusValue]);

  const onRowClicked = useCallback(
    (member: MembersData) => {
      history.push(generatePath(ROUTE.membersDetail.path, { id: member.id }));
    },
    [history]
  );

  useEffect(() => {
    if (organizationId !== null) {
      getInfoMembers();
    }
  }, [getInfoMembers, organizationId]);

  const onCloseAddModal = useCallback(() => {
    dispatch(setAddMemberModal(false));
  }, [dispatch]);

  const onOpenAddModal = useCallback(() => {
    dispatch(setAddMemberModal(true));
  }, [dispatch]);

  return (
    <div className='container-members'>
      <div className='title-container'>
        <h1 className='screen-title'>
          <FormattedMessage id='members.screen.title' />
        </h1>

        {(tegduRole === USER_ROLE.officer || tegduRole === USER_ROLE.owner) && (
          <Button className='primary' onClick={onOpenAddModal}>
            <Icon>add</Icon>
            <FormattedMessage id='objectives.button.addMember' />
          </Button>
        )}
      </div>

      <MembersFilters
        setFilterVaules={getFilterValues}
        cleanFilters={clearFilters}
        onCleanFilters={() => setClearFilters(!clearFilters)}
        disabled={loading}
      />

      <FiltersOverview
        length={filteredData.length}
        onClear={resetFilters}
        showFiltersOverview={showFiltersOverview}
      />

      <MembersTable
        data={isTourOpen ? TOUR_MEMBERS_TABLE : filteredData}
        loading={isTourOpen ? false : loading}
        error={error}
        onRowClicked={onRowClicked}
        retryCallback={getInfoMembers}
        //onChangePage={getMoreData}
        //totalRows={simulatePag === false ? 943 : undefined}
      />

      <AddMemberModal isOpen={addMemberModal} onClose={onCloseAddModal} />
    </div>
  );
};

export default MembersContainer;
