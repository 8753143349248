import { TegduCreatableSelectOnChangeModel, TegduSelectOptionsModel } from '@models/global.model';
import { useCallback, useMemo } from 'react';
import Creatable from 'react-select/creatable';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormattedMessage, useIntl } from 'react-intl';
import './styles.scss';

export interface TegduCreatableSelectProps {
  isCreatableDisabled?: boolean
  labelMessageId?: string
  options: TegduSelectOptionsModel []
  placeholderId?: string
  value: any
  getCreatedOptions?: (data: string []) => void
  onBlur?: any
  onChange: (data: any) => void  
}

export const TegduCreatableSelect = (props: TegduCreatableSelectProps) => {
  const { labelMessageId, options, value, isCreatableDisabled, placeholderId } = props;
  const { getCreatedOptions, onBlur, onChange } = props;
  const intl = useIntl();

  const handleChange = useCallback((data: any) => {
    onChange(data)
    const componentData = data as TegduCreatableSelectOnChangeModel []
    
    if(componentData.length > 0 && getCreatedOptions){
      let labelsCreated: string [] = [];
      componentData.forEach((element: TegduCreatableSelectOnChangeModel) => {
        if(element.__isNew__ && element.__isNew__ === true){
          labelsCreated.push(element.label)
        }
      })
      getCreatedOptions(labelsCreated)
    }

  }, [onChange, getCreatedOptions])

  const isCreatable = useMemo(() => {
    if(isCreatableDisabled){
      return false
    }else{
      return true
    }
 }, [isCreatableDisabled])

  const isValidNewOption = useCallback((inputValue, selectValue, selectOptions) => {
    if(isCreatable === true){
      const exactValueExists = selectOptions.find((el: any) => el.label === inputValue);
      const valueIsNotEmpty = inputValue.trim().length;
  
      return !exactValueExists && valueIsNotEmpty;
    }else{
      return isCreatable
    }
    
 }, [isCreatable])

  return(
    <div className='tegdu-creatable-select'>

      {labelMessageId &&
        <div className='label-message'>
          <span>
            <FormattedMessage id={labelMessageId}/>
          </span>
        </div>
      }

      <Creatable 
        isMulti
        className={"tegdu-creatable-select-container"}
        classNamePrefix={"tegdu-creatable-select-prefix"}
        placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
        value={value}
        options={options}
        onChange={handleChange}
        onBlur={onBlur}
        components={
          {
            DropdownIndicator: () => <ArrowDropDownIcon />,
          }
        }
        isValidNewOption={isValidNewOption}
      />
    </div>
  )
}

export default TegduCreatableSelect;