import { FormattedMessage } from "react-intl";
import { BottomSheet } from "react-spring-bottom-sheet";
import { ReviewsFiltersMobile } from "../ReviewsFilters/ReviewsFilters";
import "react-spring-bottom-sheet/dist/style.css";
import "./styles.scss";
import TegduDatePicker from "@components/TegduFormComponents/TegduDatePicker/TegduDatePicker";
import withDatePickerController from "@hocs/withDatePickerController";
import { FormProvider, useForm } from "react-hook-form";
import { reviewsFilterFormInitialValues, ReviewFiltersModel, ReviewsFiltersSelected, } from "lib/TegduForms/MobileFilterReviewFormUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import withSelectController from "@hocs/withSelectController";
import TegduSelect from "@components/TegduFormComponents/TegduSelect/TegduSelect";
import { StarRateRounded } from "@material-ui/icons";
import { TegduSelectOptionsModel } from "@models/global.model";
import moment from "moment";

interface ReviewsBottomSheetProps {
  onCloseSheet: () => void;
  openSheet: boolean;
  optionSelectedRole?: number;
  getSelectedOptionRole: (option: number) => void;
  onClearFilter: () => void;
  filtersToHandle: (data: ReviewsFiltersSelected) => void
}

const TegduDatePickerForm = withDatePickerController(TegduDatePicker);
const TegduSelectInputForm = withSelectController(TegduSelect);

const ObjectivesBottomSheet = ({
  onCloseSheet,
  openSheet,
  optionSelectedRole,
  getSelectedOptionRole,
  onClearFilter,
  filtersToHandle
}: ReviewsBottomSheetProps) => {
  const [isScoreSelectDisabled, setIsScoreSelectDisabled] = useState<boolean>(false)
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState<boolean>(false)

  const methods = useForm<ReviewFiltersModel>({
    defaultValues: reviewsFilterFormInitialValues,
  });

  const minor_score: TegduSelectOptionsModel<{}> = methods.watch("minor_score");
  const { value: minorScoreValue } = minor_score
  const major_score: TegduSelectOptionsModel<{}> = methods.watch("major_score");
  const { value: majorScoreValue } = major_score
  const startDateValue: string | null | undefined = methods.watch("start_date")
  const endDateValue: string | null | undefined = methods.watch("end_date")

  const rateOptions = useMemo(() => [
    { value: 1, label: <StarRateRounded /> },
    { value: 2, label: <><StarRateRounded /><StarRateRounded /></> },
    { value: 3, label: <><StarRateRounded /><StarRateRounded /><StarRateRounded /></> },
    { value: 4, label: <><StarRateRounded /><StarRateRounded /><StarRateRounded /><StarRateRounded /></> },
    { value: 5, label: <><StarRateRounded /><StarRateRounded /><StarRateRounded /><StarRateRounded /><StarRateRounded /></> },
  ], [])

  const slicedMajorOptions = useCallback((minorScore: number, arr: TegduSelectOptionsModel[]) => {
    if (minorScore !== 0 && minorScore < 5) {
      const copy = [...arr]
      setIsScoreSelectDisabled(false)
      const indexOfScore = copy.indexOf(copy[minorScore])
      const data = copy.slice(indexOfScore)

      return data
    } else if (Number(minorScore) === 5) {
      setIsScoreSelectDisabled(true)
      methods.resetField("major_score")
      return []
    } else {
      return rateOptions
    }
  }, [methods, rateOptions])

  const filteredRateOptions = useMemo(() => {
    let options: TegduSelectOptionsModel[]

    if (minorScoreValue !== 0) {
      options = slicedMajorOptions(minorScoreValue as number, rateOptions)
      return options
    } else {
      return rateOptions
    }
  }, [minorScoreValue, rateOptions, slicedMajorOptions])

  useEffect(() => {
    if (moment(startDateValue).format() !== "Invalid date" && moment(endDateValue).format() !== "Invalid date") {
      if (moment(startDateValue).format().slice(0, 10) === moment(endDateValue).format().slice(0, 10)) {
        methods.setError('start_date', { type: "focus", message: "mobile.review.same.date.filter" }, { shouldFocus: true })
      } else {
        return methods.clearErrors("start_date")
      }
    }
  }, [endDateValue, methods, startDateValue])

  useEffect(() => {
    const today = moment().format().slice(0, 10)
    // If any score or range is selected and today is selected on the first datePicker, then disable second datePicker
    if (moment(startDateValue).format().slice(0, 10) === today && moment(endDateValue).format() === "Invalid date") {
      setIsDatePickerDisabled(true)
    } else {
      setIsDatePickerDisabled(false)
    }
  }, [endDateValue, minorScoreValue, startDateValue])

  useEffect(() => {
    // Pass data to ReviewsContainer via `filtersToHandle` function, only when each field is differt of 0 or null
    // otherwhise when we clear the filters we will pass a 0 to the container.
    if (minorScoreValue !== 0 || majorScoreValue !== 0 || startDateValue !== null || endDateValue !== null) {
      filtersToHandle({
        minorScore: minorScoreValue,
        majorScore: majorScoreValue,
        startDate: moment(startDateValue).format(),
        endDate: moment(endDateValue).format()
      })
    }
  }, [endDateValue, filtersToHandle, majorScoreValue, minorScoreValue, startDateValue])

  const handleClearFilter = useCallback(() => {
    onClearFilter()
    methods.reset()
  }, [methods, onClearFilter])

  return (
    <BottomSheet
      className="bottom-sheet"
      onDismiss={onCloseSheet}
      open={openSheet}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
    >
      <div className="reviews-bottom-sheet-container">
        <div className="header-sheet">
          <div>
            <span>
              <FormattedMessage id="mobile.reviews.sheet.title.filter" />
            </span>
          </div>
          <div>
            <span onClick={handleClearFilter}>
              <FormattedMessage id="mobile.reviews.sheet.title.clear" />
            </span>
          </div>
        </div>
        <div className="content-sheet">
          <div className="role-content">
            <div className="title-content">
              <span>
                <FormattedMessage
                  id="mobile.reviews.sheet.title.filter.type"
                />
              </span>
            </div>
            <div className="buttons-content-filters">
              <ReviewsFiltersMobile
                optionSelected={optionSelectedRole}
                onSelectOption={getSelectedOptionRole}
              />
            </div>
          </div>
          <FormProvider {...methods}>
            <form
              className="reviews-form-component"
            >
              <div className="mobile-reviews-score-filter">
                <FormattedMessage id="mobile.review.score.filter" />
                <div className="score-wrapper">
                  <TegduSelectInputForm
                    name="minor_score"
                    control={methods.control}
                    options={rateOptions}
                    isClearable={false}
                    isSearchable
                    isMulti={false}
                    formatOptions="reviews"
                    placeholderId="mobile.reviews.blank.placeholder"
                  />
                  <span className="divider" />
                  <TegduSelectInputForm
                    name="major_score"
                    control={methods.control}
                    options={filteredRateOptions}
                    isClearable={false}
                    isSearchable
                    isMulti={false}
                    formatOptions="reviews"
                    placeholderId="mobile.reviews.blank.placeholder"
                    disabled={isScoreSelectDisabled}
                  />
                </div>
              </div>

              <div className="mobile-reviews-date-filter">
                <FormattedMessage id="mobile.review.date.filter" />
                <div className="datepicker-wrapper">
                  <TegduDatePickerForm
                    name="start_date"
                    control={methods.control}
                    editMode={true}
                  />
                  <span className="divider" />
                  <TegduDatePickerForm
                    name="end_date"
                    control={methods.control}
                    editMode={true}
                    disabled={isDatePickerDisabled}
                  />
                  {startDateValue !== "Invalid date" && endDateValue !== "Invalid date" && methods.formState.errors.start_date?.message &&
                    <span className="error-label">
                      <FormattedMessage id={methods.formState.errors && methods.formState.errors.start_date?.message} />
                    </span>
                  }
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ObjectivesBottomSheet;
