import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import Tour from 'reactour';
import { ROUTE } from '@utils/routes';
import WelcomeStep from '@screens/Tour/WelcomeStep';
import { updateUserTour } from '@store/User/Info/info.actions';
import { Button } from '@material-ui/core';
import { setTourHighlightMenu, setTourIsOpen } from '@store/Shared/shared.action';
import StepContent from '@components/Tour/StepContent';
import { USER_ROLE } from '@utils/user';
interface BeginingTourProps {
  isMobile: boolean;
}

const BeginingTour = ({ isMobile }: BeginingTourProps) => {
  const invalidTokenError = useSelector((state: RootState) => state.Errors.invalidToken);
  const localState = useSelector((state: RootState) => state.User.Info.localState);
  const tour = useSelector((state: RootState) => state.User.Info.user.tour);
  const tourIsOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const color = useSelector((state: RootState) => state.Organization.Info.data.color);
  const userRoleId = useSelector((state: RootState) => state.User.Info.user.role.id);
  const [currentTour, setCurrentTour] = useState<any[]>([]); // Corregido: Cambiado el tipo de estado a 'any[]'
  const [currentStep, setCurrentStep] = useState(0);

  const history = useHistory();

  let accentColor;
  // Asignar valor a accentColor según el color recibido
  switch (color) {
    case 'green':
      accentColor = '#39d3c0';
      break;
    case 'purple':
      accentColor = '#716ad0';
      break;
    case 'yellow':
      accentColor = '#e9c078';
      break;
    case 'pink':
      accentColor = '#e277b0';
      break;
    case 'blue':
      accentColor = '#7991d1';
      break;
    case 'red':
      accentColor = '#e0726d';
      break;
    default:
      accentColor = '#39d3c0'; // Color predeterminado
  }

  const dispatch = useDispatch();

  const handleStart = useCallback(() => {
    setCurrentStep(1);
    dispatch(setTourHighlightMenu(true));
  }, [dispatch]);

  const handleClose = useCallback(async () => {
    setCurrentStep(1);
    dispatch(setTourIsOpen(false));
    history.push(ROUTE.dashboard.path);
    dispatch(updateUserTour(true));
  }, [dispatch, history]);

  const tourSteps = useMemo(
    () => ({
      dashboard: {
        selector: '#tourstep-dashboard',
        content: () => (
          <StepContent
            title='Dashboard'
            text='This is your dashboard where you can see the latest updates in the objectives and cultural attributes.'
          />
        ),
        showMenuOption: false,
      },
      heading: {
        selector: '#heading',
        content: () => (
          <StepContent
            title='My Account'
            text='You click on this tab, it will take you to the section where you have everything relating to your account.'
          />
        ),
        next: ROUTE.account.path,
        showMenuOption: false,
      },
      account: {
        selector: '#account',
        content: () => (
          <StepContent title='My Account' text='Here you can edit the personal information regarding your account.' />
        ),
        prev: ROUTE.dashboard.path,
        showMenuOption: false,
      },
      security: {
        selector: '#security',
        content: () => <StepContent title='Security' text='Here you can edit your account’s password.' />,
        next: ROUTE.objetives.path,
        showMenuOption: false,
      },
      objectives: {
        selector: '#tourstep-objectives',
        content: () => (
          <StepContent
            title='Objectives'
            text='In this section, you can see the objectives and their details. You can search them by name, ID, or filter with your role in them.'
          />
        ),
        prev: ROUTE.account.path,
        showMenuOption: true,
      },
      objectiveButton: {
        selector: '#btnobjective-tour',
        content: () => (
          <StepContent title='New objective' text='You can create a new objective by clicking on this button.' />
        ),
        next: ROUTE.reviews.path,
        showMenuOption: false,
      },
      reviews: {
        content: () => (
          <StepContent
            title='Rate your team’s work'
            text='After you have given progress or closed an OKR you have the option to review the team’s work. You can do it by rating each member of the team or doing a general rating and include comments.'
          />
        ),
        prev: ROUTE.objetives.path,
        showMenuOption: false,
      },
      reviewDescription: {
        selector: '#tourstep-reviews',
        content: () => (
          <StepContent
            title='Reviews'
            text='This section is for you to see all the reviews and comments you have given or received. The received reviews are private, only you can see them.'
          />
        ),
        next: userRoleId && userRoleId === USER_ROLE.member ? ROUTE.teams.path : ROUTE.members.path,
        showMenuOption: true,
      },
      members: {
        selector: '#tourstep-members',
        content: () => (
          <StepContent
            title='Members'
            text='This section can be used to search collaborators, see which ones are active, suspended and find information such as what teams they are part of.'
          />
        ),
        next: ROUTE.teams.path,
        prev: ROUTE.reviews.path,
        showMenuOption: true,
      },
      teams: {
        selector: '#tourstep-teams',
        content: () => (
          <StepContent
            title='Teams'
            text='Here you can create and see all the teams of an organization. You can see all the information about a Team, such as the members, the date it was created, and the leaders.'
          />
        ),
        next: ROUTE.settings.path,
        prev: userRoleId && userRoleId === USER_ROLE.member ? ROUTE.reviews.path : ROUTE.members.path,
        showMenuOption: true,
      },
      settings: {
        selector: '#tourstep-settings',
        content: () => (
          <div>
            <StepContent
              title='Settings'
              text='The settings notification is mainly to configure the type of notifications you can get. These notifications will be received in the bell in the upper bar.'
            />
            <Button style={{ margin: '20px auto 0', display: 'block' }} onClick={handleClose}>
              Finish tour
            </Button>
          </div>
        ),
        prev: ROUTE.teams.path,
        showMenuOption: true,
      },
    }),
    [handleClose, userRoleId]
  );

  const stepsForGeneral = useMemo(
    () => [
      {
        content: () => <WelcomeStep onStart={handleStart} onSkip={handleClose} loading={false} />,
      },
      tourSteps.dashboard,
      tourSteps.heading,
      tourSteps.account,
      tourSteps.security,
      tourSteps.objectives,
      tourSteps.objectiveButton,
      tourSteps.reviews,
      tourSteps.reviewDescription,
      tourSteps.members,
      tourSteps.teams,
      tourSteps.settings,
    ],
    [handleClose, handleStart, tourSteps]
  );

  const stepsForMembers = useMemo(
    () => [
      {
        content: () => <WelcomeStep onStart={handleStart} onSkip={handleClose} loading={false} />,
      },
      tourSteps.dashboard,
      tourSteps.heading,
      tourSteps.account,
      tourSteps.security,
      tourSteps.objectives,
      tourSteps.objectiveButton,
      tourSteps.reviews,
      tourSteps.reviewDescription,
      tourSteps.teams,
      tourSteps.settings,
    ],
    [handleClose, handleStart, tourSteps]
  );

  const handleNavigation = (action: 'prev' | 'next') => {
    if (currentTour.length) {
      const actualStep = currentTour[currentStep];
      let newStep;
      // Avanzar a una siguiente pantalla
      if (action === 'next') {
        if (actualStep.next) {
          history.push(actualStep.next);
        }
      } else {
        if (actualStep.prev) {
          history.push(actualStep.prev);
        }
      }

      if (action === 'prev') {
        if (currentStep > 0) {
          const newStepNum = currentStep - 1;
          newStep = currentTour[newStepNum];
          setCurrentStep(newStepNum);
          dispatch(setTourHighlightMenu(newStep.showMenuOption));
        }
      } else if (action === 'next') {
        if (currentStep < currentTour.length - 1) {
          const newStepNum = currentStep + 1;
          newStep = currentTour[newStepNum];
          setCurrentStep(newStepNum);
        }
      }

      dispatch(setTourHighlightMenu(newStep.showMenuOption));
    }
  };

  // Decide which tour should be shown
  useEffect(() => {
    if (userRoleId === USER_ROLE.member) {
      setCurrentTour(stepsForMembers);
    } else {
      setCurrentTour(stepsForGeneral);
    }
  }, [stepsForGeneral, stepsForMembers, userRoleId]);

  // Decide if the tour should be shown
  useEffect(() => {
    if (!isMobile && localState === 'accessState' && invalidTokenError === false && userRoleId) {
      dispatch(setTourIsOpen(!tour));
      if (!tour) {
        history.push(ROUTE.dashboard.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, localState, invalidTokenError, tour, history]);
  return tourIsOpen ? (
    <Tour
      isOpen={tourIsOpen}
      onRequestClose={handleClose}
      steps={currentTour}
      rounded={10}
      accentColor={accentColor}
      showNavigation={false}
      disableDotsNavigation={currentStep === 0 ? false : true}
      showButtons={currentStep === 0 || currentStep === currentTour.length - 1 ? false : true}
      closeWithMask={false}
      nextStep={() => handleNavigation('next')}
      prevStep={() => handleNavigation('prev')}
      goToStep={currentStep}
      showNumber={false}
      disableInteraction={true}
    />
  ) : (
    <></>
  );
};

export default BeginingTour;
