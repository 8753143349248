import Spinner from '@components/Shared/Spinner/Spinner';
import { Button } from '@material-ui/core';
import { ApiGetObjective } from '@models/objetives.model';
import { getObjectives } from '@services/objectives.service';
import moment from 'moment';
import DoubleCircularProgress from 'pwa/components/Shared/DoubleCircularProgress/DoubleCircularProgress';
import TegduSearchBar from 'pwa/components/Shared/TegduSearchbar/TegduSearchBar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

interface SearchObjectiveContainerProps {
  objectiveRoleSelected: number;
  onClose: () => void;
  onRowClicked: (tableData: ApiGetObjective) => void;
}

const SearchObjectiveContainer = (props: SearchObjectiveContainerProps) => {
  const { onClose, onRowClicked } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();
  const [data, setData] = useState<ApiGetObjective[]>([]);
  const [searchData, setSearchData] = useState<string>('');

  const handleSearchBar = useCallback((searchElement: string) => {
    setSearchData(searchElement);
  }, []);

  const getObjectivesCallback = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getObjectives();
      setData(response);
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getObjectivesCallback();
  }, [getObjectivesCallback]);

  const filteredData = useMemo(() => {
    if (searchData === '') {
      return [];
    } else {
      return data.filter(
        (element: ApiGetObjective) =>
          element.name.toLowerCase().includes(searchData.toLowerCase()) ||
          element.id.toString().includes(searchData)
      );
    }
  }, [searchData, data]);

  return (
    <div className='search-objective-container'>
      <div className='search-area'>
        <TegduSearchBar
          placeholderId='objectives.searchbar.placeholder'
          onSearch={handleSearchBar}
          isDisabled={loading}
        />
        <span onClick={onClose}>
          <FormattedMessage id='general.cancel' />
        </span>
      </div>

      <div className='info-area'>
        {loading === true && (
          <div className='info-loading'>
            <Spinner color='primary' size={40} thickness={5} />
          </div>
        )}

        {loading === false && error === true && (
          <div className='info-error'>
            <FormattedMessage id='generic.error.label' values={{ table: "OKR's" }} />
            <Button onClick={getObjectivesCallback}>
              <FormattedMessage id='general.button.try.again' />
            </Button>
          </div>
        )}

        {loading === false && error === false && (
          <div className='info-success'>
            {filteredData.map((element: ApiGetObjective) => (
              <div className='info-row' onClick={() => onRowClicked(element)}>
                <div className='okr-name'>{element.name}</div>

                <div className='okr-date'>{moment(element.due_date).format('MM/DD/YYYY')}</div>

                <div className='okr-progress'>
                  <DoubleCircularProgress
                    isTable
                    sizeCirular={36}
                    value={element.progress * 100}
                    valueToShow={element.progress.toFixed(1)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchObjectiveContainer;
