import React, { useCallback, useEffect, useState } from 'react';
// import StatementForm from '@components/Organizations/Statements/StatementsForm/StatementsForm';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { GqlArchiveStatementMutation, GqlGetStatements, Statement } from '@models/statement.model';
import { ARCHIVE_STATEMENT, GET_STATEMENTS } from '@graphql/organization.queries';
import StatementForm from '@components/Organizations/Statements/StatementsForm/StatementsForm';
import Spinner from '@components/Shared/Spinner/Spinner';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import BackButton from '@components/Shared/BackButton/BackButton';
import useToastNotification from 'hooks/useToastNotification';

const ArchiveStatements = () => {
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const [statements, setStatement] = useState<Statement[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { loading, error, data } = useQuery<GqlGetStatements>(GET_STATEMENTS, {
    variables: { organizationId, isArchived: true },
    fetchPolicy: 'no-cache',
  });
  const [archiveStatement, { data: dataArchive, error: errorArchive }] =
    useMutation<GqlArchiveStatementMutation>(ARCHIVE_STATEMENT);
  
  const toastNotification = useToastNotification();

  useEffect(() => {
    if (data) {
      const statements: Statement[] = data.tgdStatements.map((statement) => ({
        id: statement.id.toString(),
        statement: statement.name,
        date: statement.valid_until,
        description: statement.description,
        loadingArchive: false,
      }));

      if (statements.length) {
        setStatement(statements);
      }
    }
  }, [data]);

  const handleUnarchive = useCallback(
    (id: string) => {
      const statementIndex = statements.findIndex((statement) => statement.id === id);
      let loadingStatement = statements;
      loadingStatement[statementIndex].loadingArchive = true;
      setStatement(loadingStatement);
      archiveStatement({
        variables: {
          id: id,
          isArchived: false,
        },
      });
    },
    [archiveStatement, statements]
  );

  // useEffect for unarchive statement
  useEffect(() => {
    if (errorArchive) {
      const statementsNotLoading: Statement[] = statements.map((statement) => ({
        ...statement,
        loadingArchive: false,
      }));
      setShowErrorModal(true);
      setStatement(statementsNotLoading);
    } else {
      if (dataArchive) {
        const archivedStatement = dataArchive.updateTgdStatement.tgdStatement;
        if (!archivedStatement.is_archived) {
          const newStatements: Statement[] = statements.filter(
            (statement) => statement.id !== archivedStatement.id
          );
          toastNotification('statement.success.unarchive', false);
          setStatement(newStatements);
        }
      }
    }
    // eslint-disable-next-line
  }, [errorArchive, dataArchive]);

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, []);

  return (
    <>
      <div className='archive-statement-container'>
        <BackButton id='statements' labelId='statement.archive.back' />
        <h1 className='archive-title'>
          <FormattedMessage id={'statement.archive.title'} />
        </h1>
        <h3 className='archive-description'>
          <FormattedMessage id={'statement.archive.description'} />
        </h3>
        {loading ? (
          <div className='screen-loader'>
            <Spinner size={40} color='primary' thickness={4} />
          </div>
        ) : error ? (
          <div className='screen-loader'>
            <p>Ha ocurrido un error</p>
          </div>
        ) : statements.length > 0 ? (
          <StatementForm
            statements={statements}
            archiveMode={true}
            editMode={false}
            onArchive={(id) => handleUnarchive(id)}
          />
        ) : (
          <div className='empty-screen'>
            <p id='message'>
              <FormattedMessage id={'statement.archive.empty.label'} />
            </p>
          </div>
        )}
      </div>
      <GenericErrorModal
        isOpen={showErrorModal}
        handleClose={handleCloseErrorModal}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: 'unarchiving', subject: 'statement' },
        }}
      />
    </>
  );
};

export default ArchiveStatements;
