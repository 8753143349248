import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.scss';
import _ from "lodash";
import TegduDatePickerHeader from "../TegduDatePickerHeader/TegduDatePickerHeader";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import DateRangeIcon from '@material-ui/icons/DateRange';

export interface TegduDatePickerProps {
  disabled?: boolean
  editMode: boolean
  labelId?: string
  value: Date | null
  onBlur: any
  onChange: (data: any) => void
}


const TegduDatePicker = (props: TegduDatePickerProps) => {
  const { disabled, value, onBlur, editMode, labelId, onChange } = props;

  const years = _.range(1990, 2080);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="tegdu-date-picker-container">
      {labelId &&
        <div className="label-message">
          <FormattedMessage
            id={labelId}
          />
        </div>
      }

      <div className={`tegdu-date-picker ${editMode ? '' : 'edit-mode'}`}>
        {(editMode === true) ?
          <DatePicker
            disabled={disabled}
            placeholderText="MM/DD/YYYY"
            selected={value}
            onChange={onChange}
            onBlur={onBlur}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <TegduDatePickerHeader
                date={date}
                months={months}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                years={years}
                changeYear={changeYear}
                changeMonth={changeMonth}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
              />
            )}
          />
          
          :
          <div className="date-view-mode">
            <span>{moment(value).format('MM/DD/YYYY')}</span>
          </div>
        }
        <DateRangeIcon />
      </div>
    </div>

  )
}

export default TegduDatePicker;