import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.scss";
interface ObjectivesFiltersStatusProps {
  onSelectOption: (option: number, optionSelected:number) => void;
  optionSelected?: number;
  onOpenBottomSheet?: () => void;
}
const ObjectivesFiltersStatus = ({
  onSelectOption,
  onOpenBottomSheet,
  optionSelected,
}: ObjectivesFiltersStatusProps) => {
  

  return (
    <div className="container-buttons-filters-status">
      <Button
        onClick={() => onSelectOption(-1, optionSelected || 0)}
        className={
          optionSelected === -1 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.all" />
      </Button>
      <Button
        onClick={() => onSelectOption(2, optionSelected || 0)}
        className={
          optionSelected === 2 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.active" />
      </Button>
      <Button
        onClick={() => onSelectOption(3, optionSelected || 0)}
        className={
          optionSelected === 3 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.closed" />
      </Button>
      <Button
        onClick={() => onSelectOption(1, optionSelected || 0)}
        className={
          optionSelected === 1 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.draft" />
      </Button>
    </div>
  );
};

export default ObjectivesFiltersStatus;
