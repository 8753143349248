import { useCallback, useState } from 'react'
import { ApiGetObjective } from '@models/objetives.model';
import { getObjectives, getObjectivesRecentService } from '@services/objectives.service'

export const useGetObjectives = () => {
  const [objectivesRecent, setObjectivesRecent] = useState<Array<any>>([]);
  const [allObjectives, setAllObjectives] = useState<ApiGetObjective[]>([]);
  const [loadAllObjectives, setLoadAllObjectives] = useState<boolean>(false);
  const [loadObjectivesRecent, setLoadObjectivesRecent] = useState<boolean>(false);
  const [errorObjectives, setErrorObjectives] = useState <boolean>(false);

  const getAllObjectives = useCallback(
    async() => {
      setLoadAllObjectives(true)
      try {
        const data = await getObjectives()
        setAllObjectives(data)
        setLoadAllObjectives(false)
      } catch (error) {
        setLoadAllObjectives(false)
        setErrorObjectives(true)
      }
    },[],)

  const getObjectivesRecent = useCallback(
    async() => {
      setLoadObjectivesRecent(true)
      try {
        const data = await getObjectivesRecentService();
        setObjectivesRecent(data);
        setLoadObjectivesRecent(false)
      } catch (error) {
        setLoadObjectivesRecent(false)
        setErrorObjectives(true)
      }
    },
    [],
  )
  return {
    getObjectivesRecent,
    objectivesRecent,
    getAllObjectives,
    allObjectives,
    loadAllObjectives,
    loadObjectivesRecent,
    errorObjectives
  }
}
