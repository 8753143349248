import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ButtonTapBottom from 'pwa/components/Shared/ButtonTapBottom/ButtonTapBottom';
import { useIntl } from 'react-intl';
import ScoreObjectiveDetailTitle from '../ScoreObjectiveDetailTitle/ScoreObjectiveDetailTitle';
import './styles.scss';

export interface ScoreObjectiveDetailContainerData {
  score: number;
  titleObjectiveReview: string;
  dateObjectiveReview: string;
  name: string;
  reviewBy: string;
  type: "General" | "Individual";
  comment: string;
}

interface ScoreObjectiveDetailContainerProps {
  dataModal: ScoreObjectiveDetailContainerData
  onActionReviewAgain?: () => void
}

const ScoreObjectiveDetailContainer = (props: ScoreObjectiveDetailContainerProps) => {
  const { formatMessage: intl } = useIntl()
  const { onActionReviewAgain, dataModal } = props;
  const { score, dateObjectiveReview, titleObjectiveReview, comment, name, reviewBy, type } = dataModal;
  return (
    <div className='containerScoreObjectiveDetailContent'>
      <div className="containerHeader">
        <ScoreObjectiveDetailTitle score={score} titleObjectiveReview={titleObjectiveReview} dateObjectiveReview={dateObjectiveReview} />
      </div>
      <div className="containerInfoContent">
        <div>{intl({ id: "mobile.objectives.review.details.name" })}<span>{name}</span><span id='parentheses'>{intl({ id: "mobile.objectives.review.details.team" })}</span></div>
        <div>{intl({ id: "mobile.objectives.review.details.review" })}<span>{reviewBy}</span><span id='parentheses'>{intl({ id: "mobile.objectives.review.details.coach" })}</span></div>
        <div>{intl({ id: "mobile.objectives.review.details.type" })}<span>{type}</span></div>
        <div>{intl({ id: "mobile.objectives.review.details.comment" })}</div>
        <div className='comment'><span>{comment}</span></div>
      </div>
      <ButtonTapBottom
        onAction={onActionReviewAgain}
        clasNamecontainerStyles='containerFooterBtn'
        iconLeft={<StarRoundedIcon />}
        title={'mobile.objectives.review.details.btn.review'}
      />
    </div>
  )
}

export default ScoreObjectiveDetailContainer