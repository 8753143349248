import { TegduSelectOptionsModel } from "@models/global.model";
import { EditObjectivePut, EditObjectiveTgdCulturalAttributes, EditObjectiveTgdKeyResults, EditObjectiveTgdRole, EditObjectiveTgdTags, EditObjectiveTgdTeams, ObjectiveDataGraphModel, ObjectiveKeyResultsGraphModel, ObjectiveRoleUsersGraphModel, TgdElementGraphModel } from "@models/objectives.models";
import { OBJECTIVE_ROLE } from "@utils/objectives";
import { CreateObjectiveMainFormModel, ObjectivesKeyResultsFormModel } from "lib/TegduForms/CreateObjectiveMainFormUtils";

const buildEditTgdObjRole = (
  initialGraphData: ObjectiveRoleUsersGraphModel [] ,
  owner: TegduSelectOptionsModel | null, 
  coaches: TegduSelectOptionsModel [] | null
): EditObjectiveTgdRole [] => {    
  let editedTgdObjRoles: EditObjectiveTgdRole [] = [];
  
  if(owner !== null){
    let ownerIndexRemoved: number = -1;
    initialGraphData.forEach((element, index) => {        
      if(element.tgd_role.id === "1" && element.user.id !== owner.value.toString()){
        ownerIndexRemoved = index
      }
    })

    if(ownerIndexRemoved !== -1){
      editedTgdObjRoles.push({
        id: parseInt(initialGraphData[ownerIndexRemoved].creationId),
        active: false
      })

      editedTgdObjRoles.push({
        tgd_role: OBJECTIVE_ROLE.owner,
        user: owner.value as number
      })
    }
  }

  if(coaches !== null){
    let coachesDeletedAsBoolean: boolean [] = [];
    let coachesDeletedIndexs: number [] = [];
    let coachesAddedAsBoolean: boolean [] = [];
    let coachesAddedIndexs: number [] = [];

    initialGraphData.forEach((element) => {
      if(element.tgd_role.id ==="1"){
        coachesDeletedAsBoolean.push(true)
      }else {
        coachesDeletedAsBoolean.push(false)
      }      
    })

    coaches.forEach(() => {
      coachesAddedAsBoolean.push(false)
    })
    
    /* Find deleted elements and put them to FALSE */
    initialGraphData.forEach((initial, index) => {
      coaches.forEach((current) => {
        if(initial.tgd_role.id === "2" && initial.user.id === current.value.toString()){
          coachesDeletedAsBoolean[index] = true
        }
      })
    })

    /* Find index of deleted elements  */
    coachesDeletedAsBoolean.forEach((element, index) => {
      if(element === false){
        coachesDeletedIndexs.push(index)
      }
    })

    /* Create object about deleted elements using indexs */
    coachesDeletedIndexs.forEach((element) => {
      editedTgdObjRoles.push({
        id: parseInt(initialGraphData[element].creationId),
        active: false
      })
    })

    /* Find added elements */
    coaches.forEach((current, index) => {
      initialGraphData.forEach((intital) => {
        if(current.value.toString() === intital.user.id ){
          coachesAddedAsBoolean[index] = true
        }
      })
    })

    /* Find index of addded elements */
    coachesAddedAsBoolean.forEach((element, index) => {
      if(element === false){
        coachesAddedIndexs.push(index)
      }
    })

    /* Create object about added elements  */
    coachesAddedIndexs.forEach((element) => {
      editedTgdObjRoles.push({
        tgd_role: OBJECTIVE_ROLE.coach,
        user: parseInt(coaches[element].value as string)
      })
    })
    
  }
  return editedTgdObjRoles;
}

const buildEditTgdTags = ( 
  initialGraphData: TgdElementGraphModel [] , 
  currentTags: TegduSelectOptionsModel [] | null
): EditObjectiveTgdTags []  => {
  
  let editedTegduElement: EditObjectiveTgdTags [] = [];

  if(currentTags !== null){
    let tagsIndexRemoved: number [] = [];
    let tagsFindRemoved: boolean [] = [];
    let tagsFindAdded: boolean [] = [];
    let tagsIndexAdded: number [] = [];

    /* Make array copies to initilize values in false */
    currentTags.forEach(() => {
      tagsFindAdded.push(false)
    })

    initialGraphData.forEach(() => {
      tagsFindRemoved.push(false)
    })

    /* Find deleted elements  */
    initialGraphData.forEach((element, index) => {
      currentTags.forEach((tag) => {
        if(element.tgd_element.id === tag.value.toString()){
          tagsFindRemoved[index] = true
        }
      })
    })

    /* Find index of deleted elements  */
    tagsFindRemoved.forEach((element, index) => {
      if(element === false){
        tagsIndexRemoved.push(index)
      }
    })

    /* Create object about deleted elements using indexs */
    tagsIndexRemoved.forEach((element) => {
      editedTegduElement.push({
        id: parseInt(initialGraphData[element].creationId),
        active: false
      })
    })

    /* Find added elements */
    currentTags.forEach((element, index) => {
      initialGraphData.forEach((initialElement) => {
        if(element.value.toString() === initialElement.tgd_element.id){
          tagsFindAdded[index] = true
        }
      })
    })

    /* Find index of addded elements */
    tagsFindAdded.forEach((element, index) => {
      if(element === false){
        tagsIndexAdded.push(index)
      }
    })

    /* Create object about added elements  */
    tagsIndexAdded.forEach((element) => {
      editedTegduElement.push({
        tgd_tag: parseInt(currentTags[element].value as string)
      })
    })

    return editedTegduElement

  }else {
    return []
  }
}

const buildEditCulturalAttributes = (
  initialGraphData: TgdElementGraphModel [] , 
  currentCulturalAttributes: TegduSelectOptionsModel [] | null
): EditObjectiveTgdCulturalAttributes [] => {
  let editedTegduElement: EditObjectiveTgdCulturalAttributes [] = [];

  if(currentCulturalAttributes !== null){
    let tagsIndexRemoved: number [] = [];
    let tagsFindRemoved: boolean [] = [];
    let tagsFindAdded: boolean [] = [];
    let tagsIndexAdded: number [] = [];

    /* Make array copies to initilize values in false */
    currentCulturalAttributes.forEach(() => {
      tagsFindAdded.push(false)
    })

    initialGraphData.forEach(() => {
      tagsFindRemoved.push(false)
    })

    /* Find deleted elements  */
    initialGraphData.forEach((element, index) => {
      currentCulturalAttributes.forEach((tag) => {
        if(element.tgd_element.id === tag.value.toString()){
          tagsFindRemoved[index] = true
        }
      })
    })

    /* Find index of deleted elements  */
    tagsFindRemoved.forEach((element, index) => {
      if(element === false){
        tagsIndexRemoved.push(index)
      }
    })

    /* Create object about deleted elements using indexs */
    tagsIndexRemoved.forEach((element) => {
      editedTegduElement.push({
        id: parseInt(initialGraphData[element].creationId),
        active: false
      })
    })

    /* Find added elements */
    currentCulturalAttributes.forEach((element, index) => {
      initialGraphData.forEach((initialElement) => {
        if(element.value.toString() === initialElement.tgd_element.id){
          tagsFindAdded[index] = true
        }
      })
    })

    /* Find index of addded elements */
    tagsFindAdded.forEach((element, index) => {
      if(element === false){
        tagsIndexAdded.push(index)
      }
    })

    /* Create object about added elements  */
    tagsIndexAdded.forEach((element) => {
      editedTegduElement.push({
        tgd_cultural_attribute: parseInt(currentCulturalAttributes[element].value as string)
      })
    })

    return editedTegduElement

  }else {
    return []
  }
}

const buildEditTgdKeyResults = (
  initialGraphData: ObjectiveKeyResultsGraphModel [], 
  currentKeyResults:  ObjectivesKeyResultsFormModel []
): EditObjectiveTgdKeyResults [] => {
  let editedTgdKeyResult: EditObjectiveTgdKeyResults [] = [];

  let krIndexRemoved: number [] = [];
  let krFindRemoved: boolean [] = [];
  let krFindAdded: boolean [] = [];
  let krIndexAdded: number [] = [];

  /* Make array copies to initilize values in false */
  currentKeyResults.forEach(() => {
    krFindAdded.push(false)
  })

  initialGraphData.forEach(() => {
    krFindRemoved.push(false)
  })

  /* Find deleted elements  */
  initialGraphData.forEach((element, index) => {
    currentKeyResults.forEach((keyresult) => {
      if(element.creationId === keyresult.id){
        krFindRemoved[index] = true
      }
    })
  })

  /* Find index of deleted elements  */
  krFindRemoved.forEach((element, index) => {
    if(element === false){
      krIndexRemoved.push(index)
    }
  })

  /* Create object about deleted elements using indexs */
  krIndexRemoved.forEach((element) => {
    editedTgdKeyResult.push({
      id: parseInt(initialGraphData[element].creationId),
      active: false
    })
  })

  /* Find added elements */
  currentKeyResults.forEach((element, index) => {
    initialGraphData.forEach((initialElement) => {
      if(element.id === initialElement.creationId){
        krFindAdded[index] = true
      }
    })
  })

  /* Find index of addded elements */
  krFindAdded.forEach((element, index) => {
    if(element === false){
      krIndexAdded.push(index)
    }
  })

  /* Create object about added elements  */
  krIndexAdded.forEach((element) => {
    editedTgdKeyResult.push({
      id: (currentKeyResults[element].id !== undefined) ? parseInt(currentKeyResults[element].id as string ): null,
      name: currentKeyResults[element].name,
      description: currentKeyResults[element].description,
      init_value: currentKeyResults[element].init_value,
      target_value: currentKeyResults[element].target_value,
      unit: currentKeyResults[element].unit?.value as number
    })
  })

  /* Find edited elements */
  const addedOrRemovedIndexes: number [] = krIndexAdded.concat(krIndexRemoved)

  for(let k = 0; k < currentKeyResults.length; k++){
    if(!addedOrRemovedIndexes.includes(k)){
      editedTgdKeyResult.push({
        id: (currentKeyResults[k].id !== undefined) ? parseInt(currentKeyResults[k].id as string ): null,
        name: currentKeyResults[k].name,
        description: currentKeyResults[k].description,
        init_value: currentKeyResults[k].init_value,
        target_value: currentKeyResults[k].target_value,
        unit: currentKeyResults[k].unit?.value as number
      })
    }
  }
  

  return editedTgdKeyResult
}

const buildEditTgdTeams = (
  initialGraphData: TgdElementGraphModel [],
  currentTeams: TegduSelectOptionsModel [] | null
): EditObjectiveTgdTeams [] => {
  let editedTgdTeams: EditObjectiveTgdTeams [] = []
  if(currentTeams !== null){
    let teamsIndexRemoved: number [] = [];
    let teamsFindRemoved: boolean [] = [];
    let teamsFindAdded: boolean [] = [];
    let teamsIndexAdded: number [] = [];

    /* Make array copies to initilize values in false */
    currentTeams.forEach(() => {
      teamsFindAdded.push(false)
    })

    initialGraphData.forEach(() => {
      teamsFindRemoved.push(false)
    })

    /* Find deleted elements  */
    initialGraphData.forEach((element, index) => {
      currentTeams.forEach((tag) => {
        if(element.tgd_element.id === tag.value.toString()){
          teamsFindRemoved[index] = true
        }
      })
    })

    /* Find index of deleted elements  */
    teamsFindRemoved.forEach((element, index) => {
      if(element === false){
        teamsIndexRemoved.push(index)
      }
    })

    /* Create object about deleted elements using indexs */
    teamsIndexRemoved.forEach((element) => {
      editedTgdTeams.push({
        id: parseInt(initialGraphData[element].creationId),
        active: false
      })
    })

    /* Find added elements */
    currentTeams.forEach((element, index) => {
      initialGraphData.forEach((initialElement) => {
        if(element.value.toString() === initialElement.tgd_element.id){
          teamsFindAdded[index] = true
        }
      })
    })

    /* Find index of addded elements */
    teamsFindAdded.forEach((element, index) => {
      if(element === false){
        teamsIndexAdded.push(index)
      }
    })

     /* Create object about added elements  */
     teamsIndexAdded.forEach((element) => {
      editedTgdTeams.push({
        tgd_team: parseInt(currentTeams[element].value as string)
      })
    })


    return editedTgdTeams
  }else {
    return []
  }

}


export const buildEditObjectiveRequest = (
  formData: CreateObjectiveMainFormModel,
  initialData: ObjectiveDataGraphModel
): EditObjectivePut => {            
  return {
    name: formData.name,
    description: formData.description,
    tgd_timeframe: (formData.timeframe !== null) ? parseInt(formData.timeframe.value as string) : 0,
    tgd_obj_role_users:  buildEditTgdObjRole(initialData.tgd_obj_role_users, formData.owner, formData.coach),
    tgd_teams: buildEditTgdTeams(initialData.tgd_objective_teams, formData.team),
    tgd_tags: buildEditTgdTags(initialData.tgd_tag_objectives, formData.tags),
    tgd_cultural_attributes: buildEditCulturalAttributes(initialData.tgd_obj_pillars, formData.cultural_attributes),
    tgd_key_results: buildEditTgdKeyResults(initialData.tgd_key_results, formData.key_results)
  }  
}



























