import Spinner from '@components/Shared/Spinner/Spinner';
import React from 'react';

const LoginLoader = () => {
    return(
        <div className="login_loader">
            <Spinner color="primary" size={50} thickness={4}/>
        </div>
    )
}

export default LoginLoader;