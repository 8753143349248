import './styles.scss'
import React, { useMemo } from 'react'
import { Button, Dialog, DialogContent, Grid, Icon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TagsModel } from '@models/helpful.model';
import { useCallback } from 'react';
import { useState } from 'react';
import { unarchiveTags } from '@services/settings.service';
import Spinner from '@components/Shared/Spinner/Spinner';

interface ModalUnarchiveTagProps{
    isOpen: boolean
    tagInfo: TagsModel;
    onClose: () =>  void
    refetch: () => void
}

const ModalUnarchiveTag = (props: ModalUnarchiveTagProps) => {
    const { onClose , refetch } = props;
    const [loading, setLoading] = useState(false);

    const idTag = useMemo(() => {
        if (props.tagInfo !== undefined) {
          return props.tagInfo.id;
        } else {
          return '';
        }
    }, [props.tagInfo]);

    const unarchiveTag = useCallback(async() => {
        setLoading(true);
        try{
            await unarchiveTags(idTag);
            setLoading(false);
            refetch()
            onClose()
        }catch{
            setLoading(false);
        }
    }, [idTag, refetch, onClose])


    return(
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            className={'modal-unarchive-tag'}
            scroll={'body'}
            maxWidth='xs'
            disableBackdropClick
        >
            <DialogContent className='content'>
                <Grid container className={'content-container'}>
                    <Grid item className={'title'} xs={12} sm={12} md={12}>
                        <p>
                            <FormattedMessage id={'settings.tag.tags.archive.title.modal'} />
                        </p>
                    </Grid>
                
                    
                    <Grid item className={'tag-name'} xs={12} sm={12} md={12}>
                        <p id="label">
                            <FormattedMessage id={'general.form.label.new.tag'} />
                        </p>
                        <p id="value">
                            {props.tagInfo.name}
                        </p>
                    </Grid>

                    <Grid item className={'objectives'} xs={12} sm={12} md={12}>
                        <p id='label'>
                            <FormattedMessage
                              id={'settings.tab.tags.editag.form.objectives'} 
                              values={{ objectives: props.tagInfo.tgd_tag_objectives.length }}
                            />
                        </p>

                        {props.tagInfo.tgd_tag_objectives.length !== 0 ? (
                            <div className = {`
                                objectives-row 
                                ${props.tagInfo.tgd_tag_objectives.length > 5 ? 'overflow':'overflow-none'}`
                            }>
                                {props.tagInfo.tgd_tag_objectives.map((data, index) => {
                                    return <p key={index}>{data.tgd_objective.name}</p>;
                                })}
                            </div>
                        ) : (
                            <div className='objectives-empty'>
                                <p>
                                    <FormattedMessage id='settings.tab.tags.no.objectives' />
                                </p>
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className={'buttons'}>                                                 
                        <div className={'cancel-btn'}>
                            <Button className={'secondary'} onClick={props.onClose}>
                                <FormattedMessage id={'statement.cancel'} />
                            </Button>
                        </div>
                        
                        <div className={'save-btn'}>
                            <Button className={'secondary'} onClick={unarchiveTag}>    
                                {loading === true ?
                                    <Spinner color='white' size={15} thickness={5} />  
                                :
                                    <Icon>unarchive</Icon>                              
                                }                                                                       
                                <FormattedMessage id={'settings.tag.tags.unarchive.tag'} />
                            </Button>
                        </div>                                                                           
                    </Grid>
                    
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ModalUnarchiveTag;