import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiGetObjective } from '@models/objetives.model';
import LettersCircle from '@components/Shared/LettersCircle/LettersCircle';
import moment from 'moment';
import ProgressBar from '@components/Shared/ProgressBar/ProgressBar';
//import TagsComponent from '@components/Shared/TagsComponent/TagsComponent';
import ActionsBarButtons from '../ActionsBarButtons/ActionsBarButtons';
import { ObjectivesMenuDotOptions } from '../ObjectivesMenuDotOptions/ObjectivesMenuDotOptions';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { MEMBER_ROLE_LABEL } from '@utils/members';
import { OBJECTIVE_ROLE, OBJECTIVE_STATUS } from '@utils/objectives';

import './styles.scss';
import ObjectiveStatusComponent from '../ObjectiveStatusComponent/ObjectiveStatusComponent';
import { Button, Icon } from '@material-ui/core';

export const ObjectivesTableColumns = (
  withRole: boolean,
  getActionSelected: (keyName: string, objectiveId: number, ownerName: string) => void,
  creationDate?: boolean
) => {
  /* Redux data */
  const userId = useSelector((state: RootState) => state.User.Info.user.id);

  const columns = useMemo(() => {
    const owner = {
      id: 'owner',
      maxWidth: '90px',
      minWidth: '90px',
      name: (
        <div className='column-title column-owner'>
          <FormattedMessage id='objectives.table.title.owner' />
        </div>
      ),
      cell: (row: ApiGetObjective) => (
        <>
          {row.read === false ? <div className='dot-red'></div> : <></>}
          <LettersCircle
            className='obj-ownerletters'
            name={row.owner.first_name + ' ' + row.owner.last_name}
            size='md'
          />
        </>
      ),
      hide: 'sm',
    };
    const name = {
      id: 'name',
      selector: 'name',
      minWidth: '300px',
      maxWidth: '300px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='objectives.table.title.name' />
        </div>
      ),
    };
    const creation = {
      id: 'creationDate',
      selector: 'creationDate',
      minWidth: '100px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='objectives.table.drafts.title' />
        </div>
      ),
      cell: (row: ApiGetObjective) => {
        return (
          <span data-tag='allowRowEvents' className={`row-name`}>
            {moment(row.created_at).format('MM/DD/YYYY')}
          </span>
        );
      },
    };
    const edit = {
      id: 'edit',
      minWidth: '10px',
      cell: (row: ApiGetObjective) => (
        <Button className='secondary' onClick={() => ''}>
          <Icon className='icon_file'>mode_edit_icon</Icon>
          <FormattedMessage id={'objectives.button.editDrafts'} />
        </Button>
      ),
    };
    if (creationDate) {
      return [owner, name, creation, edit];
    }
    if (withRole) {
      return [
        owner,
        name,
        {
          id: 'role',
          minWidth: '130px',
          maxWidth: '150px',
          selector: 'role',
          name: (
            <div className='column-title'>
              <FormattedMessage id='objectives.table.title.role' />
            </div>
          ),
          format: (row: ApiGetObjective) => (row.role ? MEMBER_ROLE_LABEL[row.role] : '- - -'),
          hide: 'sm',
        },
        {
          id: 'progress',
          minWidth: '150px',
          name: (
            <div className='column-title'>
              <FormattedMessage id='objectives.table.title.progress' />
            </div>
          ),
          cell: (row: ApiGetObjective) => (
            <ProgressBar value={row.progress} thickness={4} labelLeft />
          ),
          hide: 'sm',
        },
        {
          id: 'status',
          maxWidth: '150px',
          name: (
            <div className='column-title'>
              <FormattedMessage id='inputlabel.status' />
            </div>
          ),
          cell: (row: ApiGetObjective) => <ObjectiveStatusComponent status={row.status} />,
          hide: 'md',
        },
        {
          id: 'actions',
          maxWidth: '60px',
          minWidth: '60px',
          cell: (row: ApiGetObjective) => (
            <div>
              {(row.owner.id === parseInt(userId) || row.role === OBJECTIVE_ROLE.coach) &&
                row.status !== OBJECTIVE_STATUS.closed && (
                  <ActionsBarButtons
                    objetiveId={row.id}
                    name={row.owner.first_name + ' ' + row.owner.last_name}
                    menuActions={ObjectivesMenuDotOptions(row.status, false, true)}
                    getActionSelected={getActionSelected}
                  />
                )}
            </div>
          ),
        },
      ];
    } else {
      return [
        owner,
        {
          id: 'name',
          minWidth: '200px',
          maxWidth: '400px',
          selector: 'name',
          name: (
            <div className='column-title'>
              <FormattedMessage id='objectives.table.title.name' />
            </div>
          ),
        },
        {
          id: 'progress',
          minWidth: '150px',
          name: (
            <div className='column-title'>
              <FormattedMessage id='objectives.table.title.progress' />
            </div>
          ),
          cell: (row: ApiGetObjective) => (
            <ProgressBar value={row.progress} thickness={4} labelLeft />
          ),
        },
        {
          id: 'status',
          maxWidth: '150px',
          name: (
            <div className='column-title'>
              <FormattedMessage id='inputlabel.status' />
            </div>
          ),
          cell: (row: ApiGetObjective) => <ObjectiveStatusComponent status={row.status} />,
          hide: 'md',
        },
        {
          id: 'actions',
          maxWidth: '60px',
          minWidth: '60px',
          cell: (row: ApiGetObjective) => (
            <div>
              {(row.owner.id === parseInt(userId) || row.role === OBJECTIVE_ROLE.coach) &&
                row.status !== OBJECTIVE_STATUS.closed && (
                  <ActionsBarButtons
                    objetiveId={row.id}
                    name={row.owner.first_name + ' ' + row.owner.last_name}
                    menuActions={ObjectivesMenuDotOptions(
                      row.status,
                      row.role === OBJECTIVE_ROLE.coach ? true : false,
                      true
                    )}
                    getActionSelected={getActionSelected}
                  />
                )}
            </div>
          ),
        },
      ];
    }
  }, [withRole, getActionSelected, userId, creationDate]);
  return columns;
};
