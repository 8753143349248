import { Checkbox } from "@material-ui/core";
import React, { useCallback } from "react";
import './styles.scss';

export interface TegduCheckboxProps {
  disabled?: boolean
  labelElement?: JSX.Element
  value?: boolean
  onChange?: (data: boolean) => void;
  onChangeForm?: (data: any) => void;
  onBlur?: () => void;
  size?: 'small' | 'medium';
  name?:string;
  viewMode?: boolean;
}

const TegduCheckbox = (props: TegduCheckboxProps) => {
  const { disabled, labelElement, value, onChange, size, name, onBlur, onChangeForm } = props;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.checked)
  }, [onChange])

  return(
    <div className="tegdu-checkbox">
      <Checkbox 
        onBlur={onBlur}
        defaultChecked={value}
        value={value}
        name={name}
        onChange={onChangeForm ? onChangeForm : handleChange}
        disabled={disabled}
        size={size}
      />
      <span className="checkbox-message">
        {labelElement}
      </span>
    </div>
  )
}

export default TegduCheckbox;