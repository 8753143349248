import React, { useMemo, useState, useCallback, useEffect } from 'react';
import TeamsTable from '@components/Teams/TeamsTable/TeamsTable';
import Card from '@material-ui/core/Card';
import { Button } from '@material-ui/core';
import { GetAllTeamsResponse, TeamMember, TeamsData, TeamTableData } from '@models/teams.model';
import Searchbar from '@components/Shared/Searchbar/Searchbar';
import AddTeamContainer from '../AddTeamContainer/AddTeamContainer';
import TeamDetailsContainer from '../TeamDetailsContainer/TeamDetailsContainer';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import './styles.scss';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import { FormattedMessage } from 'react-intl';
import { getAllTeams } from '@services/teams.service';
import { TOUR_TEAMS_DATA } from '@samples/tour.sample.teams';

const TeamsContainer = () => {
  const isTourOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const [searchText, setSearchText] = useState<string>('');
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<TeamsData | null>(null);
  const [teamName, setTeamName] = useState<string>('');
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const actions = useSelector((state: RootState) => state.User.Info.actions.teams);
  const [data, setData] = useState<GetAllTeamsResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  // const { loading, error, data, refetch } = useQuery<GqlGetTeams>(GET_TEAMS, {
  //   variables: { organizationId },
  // });

  const getAllTeamsCallback = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
        const response = await getAllTeams(organizationId ? organizationId : -1);
        setData(response);
        setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  }, [organizationId]);

  useEffect(() => {
    getAllTeamsCallback();
  }, [getAllTeamsCallback]);

  const teams: TeamTableData[] = useMemo(
    () =>
      data && data.length !== 0
        ? data.map((team) => {
            const arrayMembers: string[] = team.members.map((user) =>
              user.user ? user.user.first_name + ' ' + user.user.last_name : ''
            );

            const arrayLeads: string[] = team.leader.map((user) =>
              user.user ? user.user.first_name + ' ' + user.user.last_name : ''
            );
            const acceptableMembers = arrayMembers.slice(0, 15).sort();
            const acceptableLeaders = arrayLeads.slice(0, 15).sort();
            const andMore =
              arrayMembers.length > acceptableMembers.length
                ? ` and ${arrayMembers.length - acceptableMembers.length} more`
                : '';
            const members = acceptableMembers.length
              ? acceptableMembers.join(', ') + andMore
              : '- - -';
            const leaders = acceptableLeaders.length
              ? acceptableLeaders.join(', ') + andMore
              : '- - -';
            const fullMembers = arrayMembers.join(' ');
            return {
              id: team.id,
              name: team.name,
              leads: leaders,
              create_time: team.created_at,
              members,
              fullMembers, // TODO: Remove when pagination
            };
          })
        : [],
    [data]
  );

  const fileredData = useMemo(
    () =>
      teams
        ? teams.filter(
            (item) =>
              item.name.toLowerCase().includes(searchText.toLowerCase()) ||
              item.leads.toLowerCase().includes(searchText.toLowerCase()) ||
              item.fullMembers.toLowerCase().includes(searchText.toLowerCase())
          )
        : [],
    [teams, searchText]
  );

  const allowAdd = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.EDIT)) return true;
    return false;
  }, [actions]);

  const onOpenDetails = useCallback(
    (teamId: string) => {
      if (data) {
        setOpenDetails(true);
        const tgdTeam = data.find((team) => team.id === teamId);
        if (tgdTeam) {
          const teamLeaders: TeamMember[] = tgdTeam.leader.map((lead) => ({
            teamUserId: lead.teamUserId ? lead.teamUserId : '',
            id: lead.user ? lead.user.id : '',
            first_name: lead.user ? lead.user.first_name : '',
            last_name: lead.user ? lead.user.last_name : '',
          }));

          const teamMembers: TeamMember[] = tgdTeam.members.map((member) => ({
            teamUserId: member.teamUserId ? member.teamUserId : '',
            id: member.user ? member.user.id : '',
            first_name: member.user ? member.user.first_name : '',
            last_name: member.user ? member.user.last_name : '',
          }));
          const team: TeamsData = {
            id: tgdTeam.id,
            name: tgdTeam.name,
            leads: teamLeaders,
            create_time: tgdTeam.created_at,
            members: teamMembers,
          };
          setSelectedTeam(team);
        }
      }
    },
    [data]
  );

  const onCloseAddTeam = useCallback(() => {
    setOpenAdd(false);
    setTeamName('');
  }, []);

  const onSearchTeam = useCallback((term) => {
    setSearchText(term);
    setTeamName(term);
  }, []);

  const onOpenAddTeam = useCallback(() => {
    setOpenAdd(true);
    setSearchText('');
  }, []);

  const onCloseDetails = useCallback(() => {
    setOpenDetails(false);
    setSelectedTeam(null);
  }, []);

  return (
    <>
      <div className='title'>
        <h1 className='screen-title'>
          <FormattedMessage id='teams.screen.title' />
        </h1>

        <div className='responsive-button'>
          {allowAdd && <Button onClick={() => onOpenAddTeam()}>Add team</Button>}
        </div>
      </div>
      <div className='container-teams'>
        <div className='container-filters'>
          <Searchbar
            intlId='teams.searchbar.placeholder'
            onSearch={(term) => onSearchTeam(term)}
            className='teams-searchbar'
            valueInput={searchText}
          />
          {allowAdd && <Button onClick={() => onOpenAddTeam()}>Add team</Button>}
        </div>
        <Card>
          <TeamsTable
            data={isTourOpen ? TOUR_TEAMS_DATA :fileredData}
            loading={isTourOpen ? false : loading}
            error={error}
            onOpenDetails={onOpenDetails}
          />
        </Card>
      </div>
      <AddTeamContainer
        teamName={teamName}
        open={openAdd}
        onClose={() => onCloseAddTeam()}
        refetch={getAllTeamsCallback}
      />
      <TeamDetailsContainer
        open={openDetails}
        onClose={onCloseDetails}
        refetch={getAllTeamsCallback}
        team={selectedTeam}
      />
    </>
  );
};

export default TeamsContainer;
