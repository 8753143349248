import React, { useEffect } from 'react';
import { RootState } from '@store/rootReducer';
import { getOrganizationData } from '@store/Organization/Info/info.action';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeZoneCatalogueData } from '@store/Catalogs/TimeZone/timeZone.action';
import { getFrequencyGoalsCatalogueData } from '@store/Catalogs/FrequencyGoals/frequencyGoals.action';
import { getCompanySizeCatalogueData } from '@store/Catalogs/CompanySize/companySize.action';
import { getRolesCatalog } from '@store/Catalogs/Roles/roles.action';
import { requestRingNotifications } from '@store/Notifications/notifications.actions';

const DataInitializer: React.FC = () => {
  const localState = useSelector((state: RootState) => state.User.Info.localState);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const timeZoneCatalogueData = useSelector((state: RootState) => state.Catalogs.TimeZone.data);
  const frequencyGoalsCatalogueData = useSelector(
    (state: RootState) => state.Catalogs.FrequencyGoals.data
  );
  const companySizeCatalogueData = useSelector(
    (state: RootState) => state.Catalogs.CompanySize.data
  );
  const rolesCatalogueData = useSelector(
    (state: RootState) => state.Catalogs.Roles.data
  );
  const ringNotificationsData = useSelector((state: RootState) => state.Notifications.ringNotificationsData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId !== null) {
      dispatch(getOrganizationData(organizationId));
    }
  }, [organizationId, dispatch]);

  // Get Catalogs at first load
  useEffect(() => {
    if (localState !== 'initial') {
      if (!timeZoneCatalogueData.length) {
        dispatch(getTimeZoneCatalogueData());
      }
      if (!frequencyGoalsCatalogueData.length) {
        dispatch(getFrequencyGoalsCatalogueData());
      }
      if (!companySizeCatalogueData.length) {
        dispatch(getCompanySizeCatalogueData());
      }
      if (!rolesCatalogueData.length) {
        dispatch(getRolesCatalog())
      }
      if (!ringNotificationsData?.data?.length) {
        dispatch(requestRingNotifications())
      }
    }
    // eslint-disable-next-line
  }, [dispatch, localState]);

  return <></>;
};

export default DataInitializer;
