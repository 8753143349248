import CloseIcon from '@material-ui/icons/Close';
import { Dialog } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './styles.scss';
interface TegduMobileModalProps {
  isOpen: boolean
  container: JSX.Element
  messageHeaderId?: string
  type?: 'close' | 'back'
  onClose?: () => void
  classNameContent?: string
  classNameHeader?: string
}

const TegduMobileModal = (props: TegduMobileModalProps) => {
  const { isOpen, onClose, container, type, messageHeaderId, classNameContent, classNameHeader } = props;
  return(
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
    >
      <div className={classNameContent ? classNameContent :`tegdu-modal-content`}>
        {(type !== undefined) &&
          <div className={classNameHeader ? classNameHeader :`modal-header`}>
            <div className='with-close' onClick={onClose}>
              {type === 'close' ? <CloseIcon /> : <ArrowBackIcon />}
            </div>

            {(messageHeaderId) &&
              <div className='with-title'>
                <FormattedMessage 
                  id={messageHeaderId}
                />
              </div>
            }
          </div>
        }
       
        {container}
      </div>
    </Dialog>
  )
}

export default TegduMobileModal;