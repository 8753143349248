import TgdClient from '@helpers/HttpClients/TgdClient';
import { ApiCatalogueLabel } from '@models/catalogs.model';
import { NotificationsPreferences, NotificationsStatus, RingNotificationsResponse, UpdateExpirationsPreferences } from '@models/notifications.models';
import { ExpirationDataProps } from 'hooks/useNotificatonsSettings';

// Notifications preferences
export const getNotificationsPreferences = async (): Promise<NotificationsPreferences[]> => {
  return TgdClient.get(`/tgd-user-notification-preferences`)
}

// UPDATE notification preference
export const updateNotificationPreferences = async (id: string, isWebApp: boolean): Promise<any> => {
  return TgdClient.put(`/tgd-user-notification-preferences/${id}`, { webapp: isWebApp })
}

export const getNotificationsService = (): Promise<RingNotificationsResponse> => {
  return TgdClient.get(`/tgd-notifications?page=1`);
};

// Update notification status
export const updateNotificationStatus = async (id: number, status: string): Promise<NotificationsStatus[]> => {
  return TgdClient.put(`/tgd-notifications/${id}/status`, { status })
}

// Catalogues
export const getNotificationPeriodicityCatalogue = async (): Promise<ApiCatalogueLabel[]> =>
  TgdClient.get<ApiCatalogueLabel[]>('/tgd-notification-periodicity-catalogs');

export const getNotificationExpirationCatalogue = async (): Promise<ApiCatalogueLabel[]> =>
  TgdClient.get<ApiCatalogueLabel[]>('/tgd-notification-expiration-catalogs');

// UPDATE expiration notifications
export const updateExpirationPreferences = async (id: string, data: ExpirationDataProps): Promise<UpdateExpirationsPreferences> => {
  let interval
  if (data.periodicity_id) {
    interval = 1
  }
  return TgdClient.put(`/tgd-expiration-notification-preferences/${id}`, { expiration_id: data.expiration_id, interval: interval || data.interval, periodicity_id: data.periodicity_id })
}

// UPDATE periodic notification preferences
export const updatePeriodicityPreferences = async (id: string, interval?: number, periodicity_id?: number, expiration_id?: number): Promise<any> => {
  return TgdClient.put(`/tgd-periodic-notification-preferences/${id}`, { interval, periodicity_id, expiration_id })
}