import { FormikTextInputProps } from "@components/FormikComponents/FormikTextInput/FormikTextInput"
import { FastField } from "formik"
import useFormikShouldUpdate from "hooks/useFormikShouldUpdate"

type FormikTextInputPropsHOC = Omit<FormikTextInputProps, 'field' | 'meta'>

const withTextFastField = (WrappedComponent: (props: FormikTextInputProps) => JSX.Element) => {
   const WithTextFastField = (props: FormikTextInputPropsHOC) => {
      const { viewMode, name, adornment } = props
      const shouldUpdate = useFormikShouldUpdate(viewMode, adornment)
      return(
         <FastField name={name} shouldUpdate={shouldUpdate}>
            {({field, meta} : any) => (
               <WrappedComponent 
                  {...props}
                  field={field}
                  meta={meta}
               />
            )}
         </FastField>
      )
   }
   return WithTextFastField;
}

export default withTextFastField;