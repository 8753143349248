import React, { useCallback } from 'react';
import MenuDots, { menuDotsOptions } from '@components/Shared/MenuDots/menuDots';

export interface ActionsBarButtonsProps {
  name: string;
  menuActions: menuDotsOptions[];
  objetiveId: number;
  getActionSelected: (keyName: string, objectiveId: number, ownerName: string) => void;
}

const ActionsBarButtons = ({
  objetiveId,
  name,
  menuActions,
  getActionSelected,
}: ActionsBarButtonsProps) => {
  /* TODO: MANEJAR EL keyName como catálogo de números y no como cadena */
  const handleOptionSelected = useCallback(
    (keyName: string) => {
      if (
        keyName === 'AddProgress' ||
        keyName === 'EditObjective' ||
        keyName === 'MarkClosed' ||
        keyName === 'ReplicateObjective' ||
        keyName === 'ArchiveObjective' ||
        keyName === 'UnarchiveObjective' ||
        keyName === 'DeleteObjective' ||
        keyName === 'ReviewObjective'
      ) {
        getActionSelected(keyName, objetiveId, name);
      }
    },
    [objetiveId, getActionSelected, name]
  );

  return (
    <div className='component-action-column'>
      <MenuDots
        id={'menuOption'}
        menuOptions={menuActions}
        onOptionSelected={(keyname) => handleOptionSelected(keyname)}
      />
    </div>
  );
};

export default ActionsBarButtons;
