import React, { useEffect } from 'react';
import InvitationsOnboardingContainer from '@containers/Invitations/InvitationsOnboardingContainer/InvitationsOnboardingContainer';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getOnboardingInvitationInfo } from '@store/Invitations/invitations.action';

const InvitationsOnboarding = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    if((search.indexOf("?code=") !== -1)){
      dispatch(
        getOnboardingInvitationInfo(search.substring(search.indexOf("=") + 1, search.length))
      )
    }
  }, [search, dispatch])
  return (
    <div
      className='invitations-onboarding' 
      style={{ backgroundImage: `url('/images/Fondo.svg')` }}
    >
      <InvitationsOnboardingContainer />
    </div>
  )
}

export default InvitationsOnboarding;