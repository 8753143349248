import { Button, Grid } from "@material-ui/core";
import { ROUTE } from "@utils/routes";
import React from "react";
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router";
import './styles.scss'


const InvalidScreenContainer = () => {
  const history = useHistory()
  return (
    <div className='InvalidScreen-container'>
      <Grid container spacing={2}>
        <Grid className={'logo-row'} item sm={12} lg={12} >
          <div className='logo__container'>
            <img src='images/tegdu.png' alt='logo' />
          </div>
        </Grid>
        <Grid className={'img-row'} item sm={12} lg={12}>
          <div className='imageCentral_container'>
            <img src='images/Tegdu_Error.png' alt='imageReference' />
          </div>
        </Grid>
        <Grid className={'title-row'} item sm={12} lg={12}>
          <h2><FormattedMessage id={'inactiveScreen.title'} /></h2>
        </Grid>
        <Grid className={'subtitle-row'} item sm={12} lg={12}>
          <h3><FormattedMessage id={'inactiveScreen.subtitle'} /></h3>
        </Grid>
        <Grid item sm={4} lg={4}> </Grid>
        <Grid className={'instructions-row'} item sm={4} lg={4}>
          <Grid item sm={12} lg={12}>
            <h5><FormattedMessage id='inactiveScreen.instructions.title' /></h5>
          </Grid>
          <Grid style={{ paddingLeft: '1rem' }} item sm={12} lg={12}>
            <ul >
              <li><h5><FormattedMessage id='inactiveScreen.instructions.first.point' /></h5></li>
              <li><h5><FormattedMessage id='inactiveScreen.instructions.second.point' /></h5></li>
            </ul>
          </Grid>
        </Grid>
        <Grid item sm={4} lg={4}> </Grid>
        <Grid className={'button-row'} item sm={12} lg={12}>
          <Button
            className='primary button-full'
            type='button'
            size='large'
            onClick={() => history.push(ROUTE.login.path)}
          >
            <FormattedMessage id='inactiveScreen.button.text' />
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default InvalidScreenContainer;