import { OptionsModel } from "@models/helpful.model";
import { getIn } from "formik";
import { useCallback } from "react";

export const useFormikDropdownShouldUpdate = (viewMode: boolean, loading: boolean, disabled: boolean, options: OptionsModel []) => {

   const shouldUpdate = useCallback((nextProps: any, currentProps: any) => {
      return (
         nextProps.name !== currentProps.name ||
         nextProps.required !== currentProps.required ||
         nextProps.disabled !== currentProps.disabled ||
         nextProps.readOnly !== currentProps.readOnly ||
         nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
         Object.keys(nextProps).length !== Object.keys(currentProps).length ||
         getIn(nextProps.formik.values, currentProps.name) !==
         getIn(currentProps.formik.values, currentProps.name) ||
         getIn(nextProps.formik.errors, currentProps.name) !==
         getIn(currentProps.formik.errors, currentProps.name) ||
         getIn(nextProps.formik.touched, currentProps.name) !==
         getIn(currentProps.formik.touched, currentProps.name) ||
         viewMode || loading || disabled || options

      );
   },[viewMode, loading, disabled, options]);

   return shouldUpdate;
}

export default useFormikDropdownShouldUpdate;