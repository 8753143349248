import * as Yup from 'yup';
//import Reference from 'yup/lib/Reference';

export const LoginSchema = Yup.object().shape({
	email: Yup.string().email('form.validation.email').required('form.validation.required'),
	password: Yup.string().required('form.validation.required'),
});

export const ResetPassEmailSchema = Yup.object().shape({
	email: Yup.string().email('form.validation.email').required('form.validation.required'),
});

export const ResetPassSchema = Yup.object().shape({
	password: Yup.string()
		.required('form.validation.required')
		.matches(/^.*[!@#$%^&*()_+\-=[\]{};':'\\|,.<>//?].*$/, {
			message: 'resetPassword.ErrorMessage.1specialCharacter',
		})
		.matches(/^.*[A-Z].*$/, { message: 'resetPassword.ErrorMessage.1capitalletter' })
		.matches(/^.*[0-9].*$/, { message: 'resetPassword.ErrorMessage.1number' })
		.min(8, 'resetPassword.ErrorMessage.8characters'),
	confirmPassword: Yup.string()
		.required('form.validation.required')
		.oneOf([Yup.ref('password'), null], 'resetPassword.ErrorMessage.passwordNoMatch'),
});

/* Validations of MembersInfoForm component */
export const MembersInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('form.validation.required')
    .max(80, 'form.validation.maxLength')
    .matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
      message: 'form.validation.alphanumeric',
    }),
  lastName: Yup.string()
    .required('form.validation.required')
    .max(80, 'form.validation.maxLength')
    .matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
      message: 'form.validation.alphanumeric',
    }),
  role: Yup.string().required('form.validation.required'),
  jobTitle: Yup.string()
    .max(80, 'form.validation.maxLength')
    .matches(/^[a-zA-Z\u00C0-\u024F0-9.,-/][a-zA-Z\u00C0-\u024F0-9.,-/\s]*$/, {
      message: 'form.validation.alphanumeric',
    })
    .nullable(),
});

/* Validations of TeamsForm component */
export const TeamSchema = Yup.object().shape({
	name: Yup.string().required('form.validation.required'),
	lead: Yup.array().of(Yup.string()).nullable(),
	members: Yup.array().of(Yup.string()),
});

/* Validations of OrganizationInfoForm component */
export const OrganizationInfoSchema = Yup.object().shape({
	publicName: Yup.string().required('form.validation.required'),
	legalName: Yup.string().required('form.validation.required'),
	companySize: Yup.string().required('form.validation.required'),
	companyWebsite: Yup.string()
		.matches(
			/((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
			'form.validation.url'
		)
		.required('form.validation.required'),
	frequencyGoals: Yup.string().required('form.validation.required'),
	timeZone: Yup.string().required('form.validation.required'),
});

export const StatementsSchema = Yup.object().shape({
	statements: Yup.array().of(
		Yup.object().shape({
			statement: Yup.string().required('form.validation.required'),
			date: Yup.string().required('form.validation.required'),
			description: Yup.string().required('form.validation.required'),
		})
	),
});

/* Validations of PillarsFrom component */
export const PillarsSchema = Yup.object().shape({
	pillars: Yup.array().of(
		Yup.object().shape({
			pillar: Yup.string().required('form.validation.required'),
			date: Yup.string().required('form.validation.required'),
			owner: Yup.number().required('form.validation.required'),
			description: Yup.string().required('form.validation.required'),
		})
	),
});

export const ObjectiveSchema = Yup.object().shape({
	objectiveTitle: Yup.string().required('form.validation.required'),
	objectiveDescription: Yup.string(),
	//objectiveDueDate: Yup.string().required('form.validation.required'),
	tgd_timeframe: Yup.string().required('form.validation.required'),
	//objectivePillar: Yup.string().required('form.validation.required'),
	objectiveOwner: Yup.number().required('form.validation.required'),
	objectiveKeyResults: Yup.array().of(
		Yup.object().shape({
			keyTitle: Yup.string().required('form.validation.required'),
			keyInitValue: Yup.number()
				.typeError('form.validation.specifynumber')
				.required('form.validation.required')
				.when('keyTargetValue', {
					is: (data: any) => data !== undefined,
					then: Yup.number().required(),
					//.lessThan(Yup.ref('keyTargetValue') as Reference<number>, 'form.validation.leesThan.keyResult'),
				}),
			keyTargetValue: Yup.number()
				.typeError('form.validation.specifynumber')
				.required('form.validation.required'),
			//.moreThan(Yup.ref('keyInitValue') as Reference<number>, 'form.validation.moreThan.keyResult'),
		})
	),
});

export const ObjectiveSchemaAsDraft = Yup.object().shape({
	objectiveTitle: Yup.string().required('form.validation.required'),
});

export const KeyResultSchema = Yup.object().shape({
	keyTitle: Yup.string()
		.required('form.validation.required')
		.matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
			message: 'form.validation.alphanumeric',
		}),
	keyInitValue: Yup.string().required('form.validation.required'),
	keyTargetValue: Yup.string().required('form.validation.required'),
});

export const KeyResultSchemaEmpty = Yup.object().shape({
	keyTitle: Yup.string(),
	keyInitValue: Yup.string(),
	keyTargetValue: Yup.string(),
});

/* Validation of Add new tag form */
export const NewTagSchema = Yup.object().shape({
	tag: Yup.string()
		.required('form.validation.required')
		.matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
			message: 'form.validation.alphanumeric',
		}),
});

/* Validation of EditTag form */
export const EditTagSchema = Yup.object().shape({
	name: Yup.string()
		.required('form.validation.required')
		.matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
			message: 'form.validation.alphanumeric',
		}),
});

/* Validations of ModalObjectiveUpdate form */
export const UpdateObjectiveSchema = Yup.object().shape({
	keyResults: Yup.array().of(
		Yup.object().shape({
			new_progress: Yup.string().matches(/^-?\d{1,10}(\.\d{1,2})?$/, {
				message: 'form.validation.number',
				excludeEmptyString: true,
			}),
			//update_date: Yup.string()
		})
	),
});

/* Validations of PersonalInfoForm */
export const PersonalInfoSchema = Yup.object().shape({
	first_name: Yup.string()
		.required('form.validation.required')
		.max(80, 'form.validation.maxLength')
		.matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
			message: 'form.validation.alphanumeric',
		}),
	last_name: Yup.string()
		.required('form.validation.required')
		.max(80, 'form.validation.maxLength')
		.matches(/^[a-zA-Z\u00C0-\u024F0-9][a-zA-Z\u00C0-\u024F0-9\s]*$/, {
			message: 'form.validation.alphanumeric',
		}),
	job_title: Yup.string()
		.max(80, 'form.validation.maxLength')
		.matches(/^[a-zA-Z\u00C0-\u024F0-9.,-/][a-zA-Z\u00C0-\u024F0-9.,-/\s]*$/, {
			message: 'form.validation.alphanumeric',
		}).nullable(),
});

/* Validations of UpdatePasswordForm */
export const UpdatePasswordSchema = Yup.object().shape({
	password: Yup.string().required('form.validation.required'),
	new_password: Yup.string()
		.required('form.validation.required')
		.matches(/^.*[!@#$%^&*()_+\-=[\]{};':'\\|,.<>//?].*$/, {
			message: 'resetPassword.ErrorMessage.1specialCharacter',
		})
		.matches(/^.*[A-Z].*$/, { message: 'resetPassword.ErrorMessage.1capitalletter' })
		.matches(/^.*[0-9].*$/, { message: 'resetPassword.ErrorMessage.1number' })
		.min(8, 'resetPassword.ErrorMessage.8characters'),
	confirm_password: Yup.string()
		.required('form.validation.required')
		.oneOf([Yup.ref('new_password'), null], 'resetPassword.ErrorMessage.passwordNoMatch'),
});

/* Validations of RateGeneralReview component */
export const RateGeneralReviewSchema = Yup.object().shape({
	comments: Yup.string().required('form.validation.required'),
});

export const InvitationsOnboardingBasicInfoSchema = Yup.object().shape({
	first_name: Yup.string().required('form.validation.required'),
	last_name: Yup.string().required('form.validation.required'),
});

/* Change leader Validations */
export const ChangeLeaderSchema = Yup.object().shape({
	team: Yup.string().required('form.validation.required'),
	newLeader: Yup.string().required('form.validation.required'),
});
