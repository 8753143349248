import * as Yup from 'yup';

export interface CreatePasswordFormModel {
  password: string
  confirmPassword: string
}

export const createPasswordFormInitialValue: CreatePasswordFormModel = {
  password: "",
  confirmPassword: ""
}

export const CreatePasswordFormSchema = Yup.object().shape({
  password: Yup.string().required("form.validation.required"),
  confirmPassword: Yup.string().required("form.validation.required").oneOf([Yup.ref('password')], "form.validation.samePassword")
})