import './styles.scss';
import React, { useCallback, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import StyledDropdown from '@components/Shared/StyledDropdown/StyledDropdown';
import useGetObjectiveStatus from 'hooks/useGetObjectiveStatus';
//import { useHistory } from 'react-router-dom';
//import { ROUTE } from '@utils/routes';
import { OBJECTIVES_ROLE_FILTER } from 'lib/enums/objectives.enum';
import { useDispatch, useSelector } from 'react-redux';
import {
  getObjectivesReport,
  setObjectivesRoleFilter,
  setObjectivesStatusFilter
} from '@store/Objectives/ObjectivesSection/objectivesSection.action';
import { RootState } from '@store/rootReducer';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Spinner from "@components/Shared/Spinner/Spinner";
import { USER_ROLE } from '@utils/user';
import useToastNotification from 'hooks/useToastNotification';

interface ObjectivesFiltersProps {
  defaultOptionSelected?: 0 | 1 | 2 | 3;
  getSelectedOption: (selectedOption: number) => void;
  getStatusSelected: (statusSelected: number) => void;
}

export const ObjectivesFilters = (props: ObjectivesFiltersProps) => {
  const { getSelectedOption, getStatusSelected } = props;
  const { getObjectiveStatus, data, loading } = useGetObjectiveStatus();
  //const history = useHistory();
  const dispatch = useDispatch();
  const filterSelected = useSelector((state: RootState) => state.Objectives.ObjectiveSection.okrFilterRoleSelected);
  const isDownLoading = useSelector((state: RootState) => state.Objectives.ObjectiveSection.isReportLoading);
  const userRole = useSelector((state: RootState) => state.User.Info.user.role.id);
  const toastNotification = useToastNotification();

  const handleReportError = useCallback(() => {
    toastNotification("objective.generate.report.error", true)
  }, [toastNotification])


  const requestObjectiveReport = useCallback ( () => {
    dispatch(getObjectivesReport(handleReportError))
  }, [dispatch, handleReportError])


  const handleFilterSelected = useCallback((filter: OBJECTIVES_ROLE_FILTER) => {
    dispatch(setObjectivesRoleFilter(filter))
  }, [dispatch])

  useEffect(() => {
    getSelectedOption(filterSelected);
  }, [getSelectedOption, filterSelected]);

  useEffect(() => {
    getObjectiveStatus();
  }, [getObjectiveStatus]);

  const getStatus = useCallback((data: any) => {
    if (data !== null) {
      getStatusSelected(parseInt(data));
      dispatch(setObjectivesStatusFilter(parseInt(data)));
    } else {
      getStatusSelected(-1);
      dispatch(setObjectivesStatusFilter(data));
    }
  },[getStatusSelected, dispatch]);

  // @ts-ignore
  return (
    <div className='objectives_filters'>
      <div className='objective_buttons'>
        <Button
          className={`${filterSelected === OBJECTIVES_ROLE_FILTER.all ? 'many-selected' : 'many-no-selected'}`}
          disabled={filterSelected === OBJECTIVES_ROLE_FILTER.all ? true : false}
          onClick={() => handleFilterSelected(OBJECTIVES_ROLE_FILTER.all)}
        >
          <FormattedMessage id='objectives.filters.all' />
        </Button>

        <Button
          className={`${filterSelected === OBJECTIVES_ROLE_FILTER.owner ? 'many-selected' : 'many-no-selected'}`}
          disabled={filterSelected === OBJECTIVES_ROLE_FILTER.owner ? true : false}
          onClick={() => handleFilterSelected(OBJECTIVES_ROLE_FILTER.owner)}
        >
          <FormattedMessage id='objectives.filters.owner' />
        </Button>

        <Button
          className={`${filterSelected === OBJECTIVES_ROLE_FILTER.coach ? 'many-selected' : 'many-no-selected'}`}
          disabled={filterSelected === OBJECTIVES_ROLE_FILTER.coach ? true : false}
          onClick={() => handleFilterSelected(OBJECTIVES_ROLE_FILTER.coach)}
        >
          <FormattedMessage id='objectives.filters.coach' />
        </Button>

        <Button
          className={`${filterSelected === OBJECTIVES_ROLE_FILTER.collaborator ? 'many-selected' : 'many-no-selected'}`}
          disabled={filterSelected === OBJECTIVES_ROLE_FILTER.collaborator ? true : false}
          onClick={() => handleFilterSelected(OBJECTIVES_ROLE_FILTER.collaborator)}
        >
          <FormattedMessage id='objectives.filters.collaborator' />
        </Button>

        {/* <Button
            className= 'graphic'
            onClick={requestObjectiveReport}
            disabled={isDownLoading}
            style={{width:'160px'}}
        >
          {isDownLoading ?
            <Spinner size={15}/>
          :
            <>
              <EqualizerIcon className='icon'/>
              <FormattedMessage id='objective.generate.report' />
            </>
          }

        </Button> */}
      </div>

      <div className='objective_dropdowns'>
        <div className='status_dropdown'>
          <StyledDropdown
            isClearable
            cleanFilter={true}
            isDisabled={loading}
            isSearchable={false}
            options={data ? data : []}
            onSelectOption={getStatus}
            placeholder='general.placeholder.dropdown.objective.status'
            viewMode={false}
            isBorderTransparent


          />
        </div>
        


        {(userRole === USER_ROLE.owner || userRole === USER_ROLE.officer) &&
        <>
          <div className="line-sepatator-report">

          </div>

          <div className="report_dropdown">
            <Button
              className= 'graphic'
              onClick={requestObjectiveReport}
              disabled={isDownLoading}
              style={{width:'160px', color: '#A5A5A5', background: 'none'}}
            >
              {isDownLoading ?
                <Spinner size={15} color="primary"/>
                :
                <>
                  <EqualizerIcon className='icon'/>
                  <FormattedMessage id='objective.generate.report' />
                </>
              }

            </Button>
          </div>
        </>
        }
        {/* {!filterSelected && (
          <>
            <div className="line_separator" />
            <Button className='secondary' onClick={() => history.push(ROUTE.objectivesDrafts.path)}>
              <Icon className="icon_file">insert_drive_file</Icon>
              <FormattedMessage id={"objectives.buttonSeeDrafts"} />
            </Button>
          </>
        )} */}
      </div>
    </div>
  );
};

export default ObjectivesFilters;
