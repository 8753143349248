import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

//TODO: Backgroundcolor debe ser una variable extraída de los colores del theme
const CustomToolTip = withStyles({
  tooltip: {
    backgroundColor: '#7D7D7D',
    fontSize: 10,
    marginLeft: '-10px',
    borderRadius: '0px',
    marginTop: '-30px',
  },
})(Tooltip);

export default CustomToolTip;