import { TegduSelectOptionsModel } from "@models/global.model";
import { CreateObjectiveKeyResultsPost, CreateObjectivePost } from "@models/objectives.models";
import { OBJECTIVE_STATUS } from "@utils/objectives";
import { OBJECTIVE_TYPES } from "lib/enums/objectives.enum";
import { CreateObjectiveMainFormModel, ObjectivesKeyResultsFormModel } from "lib/TegduForms/CreateObjectiveMainFormUtils";

export const builaInitialValuesToAddKeyResults = (keyResults: TegduSelectOptionsModel []): ObjectivesKeyResultsFormModel  => {
  return {
    name: "",
    description: "",
    unit: { value: keyResults[0].value, label: keyResults[0].label },
    init_value: "",
    target_value: ""
  }
}

const buildTgdTeams = (teams: TegduSelectOptionsModel []): number [] => {
  let tgdTeams: number [] = [];
  teams.forEach((element) => {
    tgdTeams.push(parseInt(element.value as string))
  })
  return tgdTeams
}

const buildTgdObjRoleUsers = (owner: TegduSelectOptionsModel | null, coach: TegduSelectOptionsModel [] | null): { tgd_role: number, user: number } [] => {
  let objRoleUsers: { tgd_role: number, user: number } [] = [];

  if(owner !== null){
    objRoleUsers.push({
      tgd_role: 1,
      user: owner.value as number
    })
  }

  if(coach !== null){
    coach.forEach((element) => {
      objRoleUsers.push({
        tgd_role: 2,
        user: element.value as number
      })
    })
    
  }

  return objRoleUsers
} 

const buildTgdCulturalAttributes = (culturalAttributes: TegduSelectOptionsModel []): number [] => {
  let tgdCulturalAttributes: number [] = [];
  culturalAttributes.forEach((element) => {
    tgdCulturalAttributes.push(parseInt(element.value as string))
  })
  return tgdCulturalAttributes
}

const buildTgdKeyResults = (keyResults: ObjectivesKeyResultsFormModel []): CreateObjectiveKeyResultsPost [] => {
  let tgdKeyResults: CreateObjectiveKeyResultsPost [] = []

  keyResults.forEach((element) => {
    tgdKeyResults.push({
      name: element.name,
      description: element.description,
      init_value: parseFloat(element.init_value),
      target_value: parseFloat(element.target_value),
      unit: (element.unit !== null) ? parseInt(element.unit.value as string) : 1
    })
  })
  return tgdKeyResults
}

export const buildCreateObjectiveRequest = (
    objective: CreateObjectiveMainFormModel, 
    objectiveType: OBJECTIVE_TYPES | null,
    status: OBJECTIVE_STATUS
  ): CreateObjectivePost => {
  const objectiveRequest: CreateObjectivePost = {
    name: objective.name,
    description: objective.description,
    tgd_objective_type: objectiveType,
    tgd_timeframe: (objective.timeframe === null) ? null : parseInt(objective.timeframe.value as string),
    tgd_obj_role_users: (objective.owner === null && objective.coach === null) ? null : buildTgdObjRoleUsers(objective.owner, objective.coach),
    tgd_teams: (objective.team !== null) ? buildTgdTeams(objective.team) : [],
    tgd_tags: (objective.tags !== null) ? buildTgdTeams(objective.tags) : [],
    tgd_cultural_attributes: (objective.cultural_attributes !== null) ? buildTgdCulturalAttributes(objective.cultural_attributes) : [],
    tgd_key_results: (objective.key_results !== null) ? buildTgdKeyResults(objective.key_results) : null,
    status: status
  };

  return objectiveRequest;
}