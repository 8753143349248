import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { FormStatementsValues, Statement } from '@models/statement.model';
import { Button } from '@material-ui/core';
import FormikTextArea from '@components/FormikComponents/FormikTextArea/FormikTextArea';
import FormikTextDate from '@components/FormikComponents/FormikTextDate/FormikTextDate';
import Icon from '@material-ui/core/Icon';
import { FormattedMessage } from 'react-intl';
import AddStatement from '../AddStatment/AddStatement';
import { StatementsSchema } from '@formsValidations/formsValidations';
import { emptyStatement } from '@utils/empty';
import { scrollToBottom } from '@utils/browser';
import Spinner from '@components/Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import FixedEditHeader from '@components/Shared/FixedEditHeader/FixedEditHeader';
import moment from 'moment';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import withTextFastField from '@hocs/withTextFastField';
import withTextAreaFastField from '@hocs/withTextAreaFastField';
import withDateFastField from '@hocs/withDateFastField';
import './styles.scss';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikTextAreaFastField = withTextAreaFastField(FormikTextArea);
const FormikTextDateFastField = withDateFastField(FormikTextDate);

interface StatementFormProps {
  // Form
  statements: Statement[];
  archiveMode: boolean;
  editMode: boolean;
  onEdit?: (edit: boolean) => void;
  loadingSave?: boolean;
  onArchive: (id: string) => void;
  onSubmit?: (data: FormStatementsValues) => void;
}

const StatementForm = ({ onEdit, ...props }: StatementFormProps) => {
  const actions = useSelector((state: RootState) => state.User.Info.actions.organization);
  const minDate = useMemo(() => moment().format('YYYY-MM-DD'), []);
  const initialValues: FormStatementsValues = useMemo(
    () => ({
      statements: props.statements,
    }),
    [props.statements]
  );

  const allowEdit = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.EDIT)) return true;
    return false;
  }, [actions]);

  const allowArchived = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.ARCHIVE)) return true;
    return false;
  }, [actions]);

  const allowUnarchived = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.UNARCHIVE)) return true;
    return false;
  }, [actions]);

  const handleSubmit = useCallback(
    (data: FormStatementsValues) => {
      if (props.onSubmit) {
        props.onSubmit(data);
      }
    },
    [props]
  );

  const onArchiveUnarchive = useCallback(
    (id: string) => {
      props.onArchive(id);
    },
    [props]
  );

  const handleEdit = useCallback(
    (formik: FormikProps<FormStatementsValues>) => {
      if (onEdit) {
        onEdit(true);
        formik.setTouched({ ...formik.touched });
      }
    },
    [onEdit]
  );

  const handleCancel = useCallback(
    (formik: FormikProps<FormStatementsValues>) => {
      formik.resetForm({ values: initialValues });
      if (onEdit && formik.values.statements[0].id) {
        onEdit(false);
        formik.setTouched({ ...formik.touched });
      }
    },
    [initialValues, onEdit]
  );

  return (
    <div className='statement-form'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={StatementsSchema}
        enableReinitialize
      >
        {(formik) => {
          const areEqual = JSON.stringify(formik.values) === JSON.stringify(initialValues);
          const validForm = formik.isValid;
          return (
            <>
              <FixedEditHeader
                refId='statements-description'
                form='form-statements'
                editMode={props.editMode}
                onCancel={() => handleCancel(formik)}
                onEdit={() => handleEdit(formik)}
                cancelDisabled={!props.editMode || props.loadingSave}
                submitDisabled={!validForm || areEqual === validForm || props.loadingSave}
                loading={props.loadingSave}
              />
              {!props.archiveMode && (
                <div id='statements-description' className='description-container'>
                  <Grid container className='grid-container-form' spacing={4}>
                    <Grid item xs container alignItems='center'>
                      <p>
                        <FormattedMessage id={'organization.statements.description'} />
                      </p>
                    </Grid>
                    <Grid item>
                      <div className='edit-div'>
                        {!props.editMode ? (
                          allowEdit && (
                            <Button
                              disabled={props.editMode}
                              className='secondary'
                              onClick={() => handleEdit(formik)}
                            >
                              <Icon className='icon'>edit</Icon>
                              <FormattedMessage id={'statement.edit'} />
                            </Button>
                          )
                        ) : (
                          <>
                            <Button
                              className='secondary'
                              disabled={!props.editMode || props.loadingSave}
                              onClick={() => handleCancel(formik)}
                            >
                              <FormattedMessage id={'statement.cancel'} />
                            </Button>
                            <Button
                              form='form-statements'
                              disabled={!validForm || areEqual === validForm || props.loadingSave}
                              type='submit'
                            >
                              {props.loadingSave && <Spinner />}{' '}
                              <FormattedMessage id={'statement.save'} />
                            </Button>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              <Form id='form-statements'>
                <FieldArray
                  name='statements'
                  render={({ remove, push }) => {
                    const onPush = () => {
                      push(emptyStatement);
                      scrollToBottom();
                    };

                    return (
                      <>
                        <div className='statement'>
                          {formik.values.statements.length > 0 &&
                            formik.values.statements.map((statement, index) => {
                              return (
                                <Grid
                                  key={index}
                                  container
                                  spacing={3}
                                  className='statement-fields'
                                >
                                  <Grid item xs={6} className='first-row-container'>

                                    <FormikTextInputFastField
                                      name={`statements.${index}.statement`}
                                      labelId='organization.statement'
                                      labelValues={{ number: `${index + 1}` }}
                                      placeholder='organization.statements.statement.placeholder'
                                      charsLimit={140}
                                      viewMode={!props.editMode}
                                      validateOnClick
                                    />

                                  </Grid>
                                  <Grid item xs={6} className='first-row-container'>
                                    <FormikTextDateFastField
                                      name={`statements.${index}.date`}
                                      label='organization.statement.validdate'
                                      viewMode={!props.editMode}
                                      min={minDate}
                                      validateOnClick
                                    />
                                  </Grid>
                                  <Grid item xs={12} className='description-row-container'>
                                    <FormikTextAreaFastField
                                      name={`statements.${index}.description`}
                                      label='organization.statements.description.title'
                                      placeholder=''
                                      rowsMin={4}
                                      charsLimit={200}
                                      viewMode={!props.editMode}
                                      validateOnClick
                                    />
                                  </Grid>
                                  {props.archiveMode ? (
                                    <Grid
                                      container
                                      className='footer-actions'
                                      direction='row'
                                      justify={'flex-end'}
                                    >
                                      {allowUnarchived && (
                                        <Button
                                          className='unarchive archive-button'
                                          onClick={() => onArchiveUnarchive(statement.id)}
                                          disabled={statement.loadingArchive}
                                        >
                                          {statement.loadingArchive ? (
                                            <Spinner />
                                          ) : (
                                            <Icon>unarchive</Icon>
                                          )}
                                          <FormattedMessage id={'statement.unarchive'} />
                                        </Button>
                                      )}
                                    </Grid>
                                  ) : props.editMode ? (
                                    !statement.id &&
                                    formik.values.statements.length > 1 && (
                                      <Grid
                                        container
                                        className='footer-actions'
                                        direction='row'
                                        justify={'flex-end'}
                                      >
                                        <Button className='secondary' onClick={() => remove(index)}>
                                          <FormattedMessage id='general.button.remove' />
                                        </Button>
                                      </Grid>
                                    )
                                  ) : (
                                    <Grid
                                      container
                                      className='footer-actions'
                                      direction='row'
                                      justify={'flex-end'}
                                    >
                                      {allowArchived && (
                                        <Button
                                          className='secondary archive-button'
                                          onClick={() => onArchiveUnarchive(statement.id)}
                                          disabled={statement.loadingArchive}
                                        >
                                          {statement.loadingArchive ? (
                                            <Spinner />
                                          ) : (
                                            <Icon>archive</Icon>
                                          )}
                                          <FormattedMessage id={'statement.archive'} />
                                        </Button>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              );
                            })}
                        </div>
                        {props.editMode && formik.values.statements.length < 10 && (
                          <AddStatement
                            disabled={
                              !validForm ||
                              (formik.values.statements.length > 0 &&
                                formik.values.statements[formik.values.statements.length - 1]
                                  .statement === '')
                            }
                            onClick={onPush}
                          />
                        )}
                      </>
                    );
                  }}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default StatementForm;
