import { useFormikContext } from 'formik';
import React, { useEffect } from 'react'

interface FormikListenerProps{
    getIsValid?: (value: boolean) => void 
    getValues?: (values: any) => void
}

const FormikListener = (props: FormikListenerProps) => {
    const { getIsValid, getValues } = props;
    const { isValid, values } = useFormikContext();


    useEffect(() => {
        if(getIsValid !== undefined){
            getIsValid(isValid);
        }    
    }, [getIsValid, isValid]);

    useEffect(() => {
        if(getValues !== undefined){
            getValues(values)
        }
    }, [getValues, values])

    return <></>
}

export default FormikListener;