import { Icon } from '@material-ui/core';
import { ObjectiveGet } from '@models/objetives.model';
import { buildRingNotificationsMessage, redirectToCorrespondingScreen } from '@utils/notifications';
import {
  RING_KEY_ENTITIES,
  RING_KEY_NOTIFICATIONS,
  RING_NOTIFICATION_ICON,
} from 'lib/enums/notifications/notifications.enums';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import './style.scss';

export interface NotificationProps {
  user: string;
  keyDescription: RING_KEY_NOTIFICATIONS;
  entity: RING_KEY_ENTITIES;
  date: string;
  status: boolean;
  handleUpdateStatus: (status: boolean, notificationId: number) => void;
  notificationId: number;
  OKR_name: string;
  culturalAttribute_name: string;
  dispatch: Dispatch<any>;
  details:
    | {
        tgd_objective: ObjectiveGet;
      }
    | any;
}

const IndividualNotification = (props: NotificationProps) => {
  const {
    user,
    keyDescription,
    date,
    status,
    handleUpdateStatus,
    notificationId,
    OKR_name,
    culturalAttribute_name,
    dispatch,
    details,
  } = props;
  const objective = details.tgd_objective;
  const history = useHistory();

  const handleUpdate = useCallback(() => {
    handleUpdateStatus(status, notificationId);
    redirectToCorrespondingScreen(keyDescription as string, dispatch, history, objective?.id);
  }, [dispatch, handleUpdateStatus, history, keyDescription, notificationId, status, objective]);

  return (
    <div
      className={`individual-notification ${status ? 'notification-opened' : 'new-notification'}`}
      onClick={handleUpdate}
    >
      <Icon className='icon'>{RING_NOTIFICATION_ICON[keyDescription]}</Icon>
      <div className='notification-info'>
        <div className='description'>
          <span className='user'>{user && `${user}`}</span>
          <span>
            <FormattedMessage
              id={buildRingNotificationsMessage(keyDescription)}
              values={{
                OKR_name: OKR_name && <b>{`${OKR_name.length >= 55 ? `${OKR_name}...` : OKR_name}`}</b>,
                culturalAttribute_name: culturalAttribute_name && (
                  <b>{`${
                    culturalAttribute_name.length >= 55 ? `${culturalAttribute_name}...` : culturalAttribute_name
                  }`}</b>
                ),
              }}
            />
          </span>
        </div>
        <div className='date'>{date}</div>
      </div>
    </div>
  );
};

export default IndividualNotification;
