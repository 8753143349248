import React, { FunctionComponent, useMemo, useState } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import { ROUTE } from '@utils/routes';
import { useLocation } from 'react-router-dom';

const Layout: FunctionComponent = ({ children }) => {
  const [responsiveMenu, setResponsiveMenu] = useState(false)
  const location = useLocation();
  const screen = useMemo(() => {
    const pathname = Object.values(ROUTE).find((route) => route.path === location.pathname);
    return pathname ? pathname.title : '';
  }, [location.pathname]);

  return (
    <div>
      <Header onClickReponsiveIcon={() => setResponsiveMenu(true)}/>
      <SideBar 
        screen={screen}
        isResponsiveMenuOpen={responsiveMenu}
        closeResponsiveMenu={() => setResponsiveMenu(false)}
      >
        {children}
      </SideBar>
    </div>
  );
};

export default Layout;
