import TgdClient from '@helpers/HttpClients/TgdClient';
import {
  ApiPostGeneralScoreBody,
  ApiPostIndividualScoreBody,
  ApiPostScore,
} from '@models/score.model';

export const sendGeneralScore = async (body: ApiPostGeneralScoreBody): Promise<ApiPostScore> =>
  TgdClient.post('/tgd-objective-scores/general', body);

export const sendIndividualScore = async (
  body: ApiPostIndividualScoreBody
): Promise<ApiPostScore> => TgdClient.post('/tgd-objective-scores', body);
