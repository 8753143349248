import Spinner from '@components/Shared/Spinner/Spinner';
import { ApiGetObjective } from '@models/objetives.model';
import { ROUTE } from '@utils/routes';
import { useGetObjectives } from 'hooks/useGetObjectives';
import useToastNotification from 'hooks/useToastNotification';
import ObjectiveRowToSelect from 'pwa/components/Objectives/ObjectiveRowToSelect/ObjectiveRowToSelect';
import ObjectivesModalEdit from 'pwa/components/Objectives/ObjectivesModalEdit/ObjectivesModalEdit';
import TegduSearchBar from 'pwa/components/Shared/TegduSearchbar/TegduSearchBar'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import "./styles.scss";

export interface dataToAddProgressI {
  nameObjective: string;
  id: number;
  due: string;
  progress: number;
}

const initialDataToAddProgress:dataToAddProgressI = {
  nameObjective: '',
  id: 0,
  due: '',
  progress: 0
}

interface ModalSearchObjectivesContainerProps{
  onHasBeenAddedProgress: ()=>void;
}

const  ModalSearchObjectivesContainer = (props:ModalSearchObjectivesContainerProps)=> {
  const { onHasBeenAddedProgress}=  props;
  const [searchData, setSearchData] = useState<string>("");
  const [showModalAddProgress, setShowModalAddProgress] = useState<boolean>(false);
  const [dataToAddProgress, setDataToAddProgress] = useState<dataToAddProgressI>(initialDataToAddProgress)
  const {getObjectivesRecent, objectivesRecent, allObjectives, getAllObjectives, loadAllObjectives, loadObjectivesRecent, errorObjectives} = useGetObjectives();
  const showToastNotification = useToastNotification();

  const {formatMessage: i} = useIntl();
  const history = useHistory();

  const handleSearchBar = useCallback((searchElement: string) => {
    setSearchData(searchElement)
  }, [])

  const handleModalAddProgress = useCallback(
    () => {setShowModalAddProgress(!showModalAddProgress)},
    [showModalAddProgress], )

  const getDataObjectiveSelected = useCallback(
    ({nameObjective, due, id, progress}:dataToAddProgressI) => {
      handleModalAddProgress()
      setDataToAddProgress({nameObjective, due, id, progress})
    }, [handleModalAddProgress],) 

  const getNewObjectives = useCallback(() => {
    history.push(ROUTE.dashboard.path);
    handleModalAddProgress();
    onHasBeenAddedProgress()
  }, [handleModalAddProgress, history, onHasBeenAddedProgress]);

  const filteredData = useMemo(() => {
    if (searchData === "")  return []
      return allObjectives.filter((review: ApiGetObjective) =>
      review.name.toLowerCase().includes(searchData.toLowerCase()) 
      && review.status === 2
      && review.role !== 3)
  }, [searchData, allObjectives])
  
  useEffect(() => {
    getAllObjectives()
    getObjectivesRecent()
  }, [getAllObjectives, getObjectivesRecent]);

  useEffect(() => {
    if (errorObjectives) {
      showToastNotification("score.rate.modal.error", true, true);
    } 
  }, [errorObjectives, showToastNotification])
  
  if (loadAllObjectives && loadObjectivesRecent)
   return (<div className='screen-loader' children={<Spinner size={40} color='primary' thickness={4} />}/>);

  return (
    <div className="objectives-search-review-container">
      {/* INPUT SEARCH */}
      <div
         className="input-search-area"
         children={
         <TegduSearchBar
            placeholderId="objectives.searchbar.from.add.progress.placeholder"
            onSearch={handleSearchBar}
            isDisabled={false}/>
          }
        />
        {/* OBJECTIVES TO SHOW */}
        <div className='container-items-objective-searched'>
          {searchData.length === 0 &&
          <div className="containerRecent">
            <span id='title-recent'>{i({id: "objectives.recent.title.mobile"})}</span>
            {objectivesRecent.length &&
                objectivesRecent.map((e, index)=>(
                <ObjectiveRowToSelect
                key={index}
                getDataObjectiveSelected={getDataObjectiveSelected}
                nameObjective={e.name}
                id={e.id}
                due={e.due_date ? e.due_date : e.created_at}
                progress={e.current_progress}
                />
              ))}
            </div>}
          {filteredData?.map((e:ApiGetObjective, index)=>(
            <ObjectiveRowToSelect
             key={index}
             getDataObjectiveSelected={getDataObjectiveSelected}
             nameObjective={e.name}
             id={e.id}
             due={e.due_date ? e.due_date : e.created_at}
             progress={e.progress}
            />
          ))}
      </div>
      <ObjectivesModalEdit
          nameObjective={dataToAddProgress?.nameObjective}
          due={dataToAddProgress?.due}
          progress={dataToAddProgress?.progress}
          id={dataToAddProgress.id}
          open={showModalAddProgress}
          onClose={handleModalAddProgress}
          refetchObjectives={getNewObjectives}
        />
  </div>
  )
}

export default ModalSearchObjectivesContainer