import TegduTextInputComponent from "@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent";
import withTextInputController from "@hocs/withTextInputController";
import { timeFramesFormInitialValue, TimeFramesFormModel, TimeFramesFormSchema } from "lib/TegduForms/TimeFramesFormUtils";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import OrganizationsFormsHeader from "../../OrganizationsFormsHeader/OrganizationsFormsHeader";
import { useCallback, useEffect, useState } from "react";
import withDatePickerController from "@hocs/withDatePickerController";
import TegduDatePicker from "@components/TegduFormComponents/TegduDatePicker/TegduDatePicker";
import { Button, Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@material-ui/icons/Delete';
import './styles.scss';

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);
const TegduDatePickerForm = withDatePickerController(TegduDatePicker);

interface TimeFramesFormProps {
  initialData?: TimeFramesFormModel
  isEditMode: boolean
  isFirstTime: boolean
  isSubmitLoading: boolean
  handleFormSubmit: (data: TimeFramesFormModel) => void
  updateEditMode: () => void
}

const TimeFramesForm = (props: TimeFramesFormProps) => {
  const { isEditMode, isFirstTime, handleFormSubmit, initialData, isSubmitLoading, updateEditMode } = props;
  const [sameData, setSameData] = useState<boolean>(true);

  const methods = useForm<TimeFramesFormModel>({
    defaultValues: initialData ? initialData : timeFramesFormInitialValue,
    resolver: yupResolver(TimeFramesFormSchema)
  });
  const { fields, append, remove } = useFieldArray({ name: 'timeframe', control: methods.control });

  const handleEditCallback = useCallback(() => {
    updateEditMode()
  }, [updateEditMode])

  const handleCancelCallback = useCallback(() => {
    methods.reset()
    updateEditMode()
  }, [updateEditMode, methods])

  useEffect(() => {
    const watcher = methods.watch((data) => {
      if (JSON.stringify(data.timeframe) === JSON.stringify(initialData?.timeframe)) {
        setSameData(true)
      } else {
        setSameData(false)
      }
    })

    return () => watcher.unsubscribe()
  }, [initialData, methods])

  return (
    <FormProvider {...methods}>
      <form
        className="timeframes-form-component"
        onSubmit={methods.handleSubmit(handleFormSubmit)}
      >
        <OrganizationsFormsHeader
          editMode={isEditMode}
          isCancelDisabled={isFirstTime}
          isLoading={isSubmitLoading}
          isValidForm={methods.formState.isValid && (sameData === false)}
          titleId={"organization.timeframes.description"}
          editCallback={handleEditCallback}
          cancelCallback={handleCancelCallback}
        />

        {fields.map((field, index) => {
          return (
            <div className="timeframes-form-inputs" key={index}>
              <div className="input-form">
                <TegduTextInputForm
                  key={field.id}
                  name={`timeframe.${index}.name`}
                  control={methods.control}
                  isEditMode={isEditMode}
                  labelId={index === 0 ? "timeframesform.name.label" : undefined}
                  index={index}
                  adornmentsType={(field.automatic !== undefined && field.automatic === true) ? 4 : undefined}
                  showAdornmentViewMode
                />
              </div>

              <div className="input-startdate">
                <TegduDatePickerForm
                  key={field.id}
                  name={`timeframe.${index}.date_initial`}
                  control={methods.control}
                  editMode={isEditMode}
                  labelId={index === 0 ? "timeframesform.date.label" : undefined}
                  disabled={(field.automatic !== undefined && field.automatic === true) ? true : false}
                />
              </div>

              <div className="separator">
                <hr />
              </div>

              <div className="input-endDate">
                <TegduDatePickerForm
                  key={field.id}
                  name={`timeframe.${index}.date_until`}
                  control={methods.control}
                  editMode={isEditMode}
                  disabled={(field.automatic !== undefined && field.automatic === true) ? true : false}
                />
              </div>

              {(index !== 0 && field.newElement === true) &&
                <div className="delete-icon">
                  <DeleteIcon onClick={() => remove(index)} />
                </div>
              }
            </div>
          )
        })}
        <div className="add-timeframe-button">
          {isEditMode &&
            <Button
              className='outline'
              disabled={!methods.formState.isValid}
              onClick={() => {
                append(timeFramesFormInitialValue.timeframe)
              }}
            >
              <Icon>add</Icon>
              <p>
                <FormattedMessage id={'timeframesform.add.label'} />
              </p>
            </Button>
          }
        </div>
      </form>
    </FormProvider>
  )
}

export default TimeFramesForm;