import StarRoundedIcon from '@material-ui/icons/StarRounded';
import moment from 'moment';
import './styles.scss';

interface ScoreObjectiveDetailTitleProps{
  score: number;
  titleObjectiveReview: string;
  dateObjectiveReview:string;
}

const ScoreObjectiveDetailTitle = (props:ScoreObjectiveDetailTitleProps) => {
  const {score, titleObjectiveReview, dateObjectiveReview} = props;
  return (
    <div className='containerScoreObjectiveDetailTitle'>
      {/* Icon Start Score */}
      <div className="containerStart">
        <StarRoundedIcon id='icon-start' />
        <div className="scoreReviewAsigned">
          <span id='score'>{score}</span>
        </div>
      </div>
      {/* Title and Date */}
      <div className="containerTitleDate">
        <span id="txtTitle">{titleObjectiveReview}</span>
        <span id="txtDate">{moment(dateObjectiveReview).format('DD/MM/YYYY')}</span>
      </div>
    </div>
  )
}

export default ScoreObjectiveDetailTitle