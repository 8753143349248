import { UPDATE_LOCALE_INTL } from "./language.action";
import { LanguageActionTypes, LanguageState } from "./language.model";

const initialState: LanguageState = {
    locale:"en"
}

export default function languageReducer(state: LanguageState = initialState, action: LanguageActionTypes){
    switch(action.type){
        case UPDATE_LOCALE_INTL:
            return{
                ...state,
                locale: action.payload
            };
        default:
            return state;
    }
}