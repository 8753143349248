import { Checkbox } from '@material-ui/core'
import { forwardRef } from 'react';

interface CheckNameProps {
    onChange: () => void
    checked: boolean | undefined
    disabled: boolean | undefined
    indeterminate: boolean | undefined;
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
    name: string | undefined
}
const CheckName: any = (props: CheckNameProps) => {
    const { name, checked, disabled, indeterminate, onClick } = props
    return (
        <Checkbox
            name={name}
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            onClick={onClick}
        />
    )
}
export default forwardRef(CheckName);