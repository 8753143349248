import { useCallback, useEffect, useState } from "react";
import { getObjectives } from "@services/objectives.service";
import { ObjectivesFiltersMobile } from "pwa/components/Objectives/ObjectivesFilters/ObjectivesFilters";
import { ObjectivesTableMobile } from "pwa/components/Objectives/ObjectivesTable/ObjectivesTable";
import ObjectivesBottomSheet from "pwa/components/Objectives/ObjectivesBottomSheet/ObjectivesBottomSheet";
import "./styles.scss";
import TegduSearchBar from "pwa/components/Shared/TegduSearchbar/TegduSearchBar";
import TegduMobileModal from "pwa/components/Shared/TegduMobileModal/TegduMobileModal";
import SearchObjectiveContainer from "./SearchObjectiveContainer/SearchObjectiveContainer";
import { generatePath, useHistory } from "react-router-dom";
import { ApiGetObjective } from "@models/objetives.model";
import { ROUTE } from "@utils/routes";
import FilterListIcon from "@material-ui/icons/FilterList";

const ObjectivesContainer = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [objectivesTableInfo, setObjectivesTableInfo] = useState<any>([]);
  const [optionHaveSelected, setOptionHaveSelected] = useState<number>(0);
  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);
  const [statusSelected, setStatusSelected] = useState<number>(-1);
  const [searchModal, setSearchModal] = useState<boolean>(false);

  const history = useHistory();
  
  const closeSearchModal = useCallback(() => {
    setSearchModal(false);
  }, [])

  const openSearchModal = useCallback(() => {
    setSearchModal(true);
  }, [])

  const getSelectedOption = useCallback(async (selectedOption: number) => {
    setLoadingTable(true);
    setOptionHaveSelected(selectedOption);
    try {
      const response = await getObjectives(
        selectedOption === 0 ? undefined : selectedOption
      );

      setObjectivesTableInfo(response);

      setLoadingTable(false);
    } catch {
      setLoadingTable(false);
    }
  }, []);
  const clearFilters = () => {
    setOpenBottomSheet(false);
    getSelectedOption(0);
    setStatusSelected(-1);
  };
  const onGetStatus = async (option: number) => {
    setStatusSelected(option)
    setLoadingTable(true);
    try {
      const response = await getObjectives(
        optionHaveSelected === 0 ? undefined : optionHaveSelected
      );
      setObjectivesTableInfo(response.filter((objective) => objective.status === option));
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);

    }

  };

  const onRowClicked = useCallback(
    (tableData: ApiGetObjective) => {
      history.push(
        generatePath(ROUTE.objectivesDetail.path, { id: tableData.id })
      );
    }, [history]);

  const handleOpenBottomSheet = useCallback(() => {
    setOpenBottomSheet(true)
  }, [])

  useEffect(() => {
    if (optionHaveSelected === 0) {
      getSelectedOption(optionHaveSelected);
    }
  }, [getSelectedOption, optionHaveSelected]);

  return (
    <div className="container-objectives-mobile">
      <div className="search-filter-wrapper">
        <div className="searchbar-mobile" onClick={openSearchModal}>
          <TegduSearchBar
            placeholderId="objectives.searchbar.placeholder"
            onSearch={() => null}
            isDisabled={false}
          />
        </div>
        <FilterListIcon onClick={handleOpenBottomSheet} className="filter-icon" />
      </div>

      {/* FILTERS */}
      <ObjectivesFiltersMobile
        optionSelected={optionHaveSelected}
        onSelectOption={getSelectedOption}
      />
      {/* TABLE */}
      <ObjectivesTableMobile
        loading={loadingTable}
        dataObjectives={objectivesTableInfo}
      />
      <ObjectivesBottomSheet
        onClearFilter={clearFilters}
        getSelectedOptionStatus={onGetStatus}
        optionHaveSelectedStatus={statusSelected}
        openSheet={openBottomSheet}
        getSelectedOptionRole={getSelectedOption}
        optionHaveSelectedRole={optionHaveSelected}
        onCloseSheet={() => setOpenBottomSheet(false)}
      />

      <TegduMobileModal
        isOpen={searchModal}
        onClose={closeSearchModal}
        container={
          <SearchObjectiveContainer
            onClose={closeSearchModal}
            objectiveRoleSelected={optionHaveSelected}
            onRowClicked={onRowClicked}
          />
        }
      />
    </div>
  );
};

export { ObjectivesContainer as ObjectivesContainerMobile };
