import React from 'react';
import GenericTable from '@components/Shared/GenericTable/GenericTable';
import StatusComponent from '@components/Shared/StatusComponent/StatusComponent';
import { Card } from '@material-ui/core';
import { MembersModel } from '@models/members.model';
import moment from 'moment';
import LettersCircle from '@components/Shared/LettersCircle/LettersCircle';
import './styles.scss';

const columns = [
  {
    width: '60px', // custom width for icon button
    style: {
      paddingBottom: '5px',
    },
    cell: (row: MembersModel) => (
      <LettersCircle name={row.first_name + ' ' + row.last_name} size='md' />
    ),
  },
  {
    name: 'NAME',
    selector: (row: MembersModel) => row.first_name,
    sortable: true,
    cell: (row: MembersModel) => <>{row.first_name + ' ' + row.last_name}</>,
    maxWidth: '400px',
  },
  {
    name: 'ROLE',
    selector: (row: MembersModel) => row.role.name,
    sortable: true,
    maxWidth: '130px',
    cell: (row: MembersModel) => <> {row.role === null ? <p>- - -</p> : row.role.name} </>,
  },
  {
    name: 'JOB TITLE',
    selector: (row: MembersModel) => row.job_title,
    sortable: true,
    cell: (row: MembersModel) => <> {row.job_title === null ? <p>- - -</p> : row.job_title} </>,
    maxWidth: '400px',
    hide: 'sm',
  },
  {
    name: 'MEMBER SINCE',
    selector: (row: MembersModel) => row.created_at,
    sortable: true,
    maxWidth: '150px',
    format: (row: MembersModel) => moment(row.created_at).format('MM/DD/YYYY'),
    hide: 'sm',
  },
  {
    name: 'STATUS',
    selector: (row: MembersModel) => row.active,
    sortable: true,
    maxWidth: '135px',
    cell: (row: MembersModel) => <StatusComponent status={row.active} size={'table'} />,
    hide: 'sm',
  },
  // {
  //   name: 'TEAMS',
  //   selector: 'teams',
  //   sortable: false,
  //   maxWidth: '200px',
  //   cell: (row: MembersModel) => <TeamTagsComponent tag={row.tgd_team_role_users} />,
  // },
];

interface MembresTableProps {
  data?: MembersModel[];
  loading: boolean;
  error: any;
  totalRows?: number;
  onRowClicked: (data: any) => void;
  retryCallback: () => void;
  onChangePage?: () => void;
}
const MembersTable = (props: MembresTableProps) => {
  return (
    <Card className='card-container'>
      <GenericTable
        label={'members'}
        columns={columns}
        data={props.data || []}
        onRowClicked={props.onRowClicked}
        pointerOnHover={true}
        pagination={true}
        loading={props.loading}
        error={props.error}
        retryCallback={props.retryCallback}
        totalRows={props.totalRows}
        onChangePage={props.onChangePage}
      />
    </Card>
  );
};

export default MembersTable;
