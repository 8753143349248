import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { ReviewsModelI } from "@store/Reviews/reviews.model";
import "./styles.scss";

const ReviewsTableColumns = (isGiven?: boolean) => {
  const columns = useMemo(() => {
    const okrreview = {
      id: "okrreview",
      name: (
        <div className="title-okrname">
          <FormattedMessage id="mobile.review.table.column.title.OKR" />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <span id="span-text-review">{row?.tgd_objective?.name}</span>
      ),
      minWidth: '178px',
    };
    const name = {
      id: "name",
      name: (
        <div className="column-title">
          <FormattedMessage id="mobile.review.table.column.title.name" />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <span>
          {row?.reviewed?.first_name + " " + row?.reviewed?.last_name}
        </span>
      ),
    };
    const review = {
      id: "review",
      name: (
        <div className="mobile-column-title-review">
          <FormattedMessage id="mobile.review.table.column.title.review" />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <div className="mobile-rate-icon">
          <StarRoundedIcon style={{ width: "30px", height: "30px" }} />
          <span>{row?.score}</span>
        </div>
      ),
      width: "80px",
    };
    if (isGiven) {
      return [okrreview, name, review];
    } else {
      return [okrreview, review];
    }
  }, [isGiven]);
  return columns;
};

export default ReviewsTableColumns;