import { ROUTE } from '@utils/routes';

export const menuOptionsFooter = [
  {
    icon: 'settings',
    title: 'sidebar.settings',
    href: ROUTE.settings.path,
  },
  {
    icon: 'help',
    title: 'sidebar.help',
    href: ROUTE.faqs.path,
  },
  {
    icon: 'exit_to_app',
    title: 'sidebar.logout',
    href: ROUTE.login.path,
  },
];
