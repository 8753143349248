import { createObjectiveAction, editObjectiveAction, getObjectivesInitialData } from "@store/Objectives/ObjectivesMainActions/objectivesMainActions.actions";
import { GetObjectivesInitialData } from "@store/Objectives/ObjectivesMainActions/objectivesMainActions.model";
import { RootState } from "@store/rootReducer";
import { OBJECTIVE_STATUS } from "@utils/objectives";
import { buildCreateObjectiveRequest } from "@utils/objectives/add.objectives.utils";
import { findObjectiveStatus, findObjectiveType, initializeFromObjectiveCreated } from "@utils/objectives/restore.objectives.utils";
import { buildCreateObjectiveInitialValues } from "@utils/objectives/requiredCatalogs.objectives.utils";
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from "lib/enums/objectives.enum";
import { createObjectiveMainFormInitialValues, CreateObjectiveMainFormModel } from "lib/TegduForms/CreateObjectiveMainFormUtils";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './styles.scss';
import { buildEditObjectiveRequest } from "@utils/objectives/edit.objectives.utils";
import useToastNotification from "hooks/useToastNotification";
import CreateObjectiveMainForm from "pwa/components/Objectives/CreateObjective/CreateObjectiveMainForm/CreateObjectiveMainForm";
import ObjectivesMainContainerLoading from "./ObjectivesMainContainerLoading";

interface ObjectivesMainActionsContainerProps {
  action: CREATE_OBJECTIVES_ACTIONS
  objectiveId?: number
  getObjectives: () => void
  handleClose: () => void  
}


const ObjectivesMainActionsContainer = (props: ObjectivesMainActionsContainerProps) => {
  const { action, objectiveId, handleClose, getObjectives } = props;
  const dispatch = useDispatch();
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const userId = useSelector((state: RootState) => state.User.Info.user.id);
  const requiredDataLoading = useSelector((state: RootState) => state.Objectives.ObjectivesMainActions.isInitializingObjectivesData);
  const toastNotification = useToastNotification();
  const [objectiveStatus, setObjectiveStatus] = useState<OBJECTIVE_STATUS | null>(null);
  const [objectiveType, setObjectiveType] = useState<OBJECTIVE_TYPES | null>(null);
  const [initialData, setInitialData] = useState<CreateObjectiveMainFormModel>(createObjectiveMainFormInitialValues);
  const [requiredData, setRequiredData] = useState<GetObjectivesInitialData>({ 
    teams: [], timeframes: [], members: [], culturalAttributes: [], 
    tags: [], okrUnits: [], objectiveCurrentValue: null, membersLeaders: [],
    isLeader: false
  });

  const handleSuccessEvents = useCallback(() => {
    handleClose()
    getObjectives()

    switch(action){
      case CREATE_OBJECTIVES_ACTIONS.create:
      case CREATE_OBJECTIVES_ACTIONS.replicate:
        toastNotification("objectiveform.success", false, true)
        break;
      case CREATE_OBJECTIVES_ACTIONS.edit:
        toastNotification("objectiveform.success.edit", false, true)
        break;
      default:
        return null
    }
    
    
  }, [toastNotification, getObjectives, handleClose, action])

  const handleFormSubmit = useCallback((data: CreateObjectiveMainFormModel) => {
    switch(action){
      case CREATE_OBJECTIVES_ACTIONS.create:
        dispatch(createObjectiveAction(
          buildCreateObjectiveRequest(data, objectiveType, OBJECTIVE_STATUS.active),
          handleSuccessEvents
        ))
      break;

      case CREATE_OBJECTIVES_ACTIONS.edit:
        if(objectiveId !== undefined && requiredData.objectiveCurrentValue !== null){
          dispatch(editObjectiveAction(
            buildEditObjectiveRequest(data, requiredData.objectiveCurrentValue),
            objectiveId.toString(),
            handleSuccessEvents,
            objectiveStatus
          ))
        }else {
          console.log("ERROR EDIT NO OBJECTIVE ID")
        }
      break;

      case CREATE_OBJECTIVES_ACTIONS.replicate:
        dispatch(createObjectiveAction(
          buildCreateObjectiveRequest(data, objectiveType, OBJECTIVE_STATUS.active),
          handleSuccessEvents
        ))
      break;
    }
  }, [action, objectiveType, dispatch, objectiveId, requiredData, handleSuccessEvents, objectiveStatus])

  const handleFormAsDraft = useCallback((data: CreateObjectiveMainFormModel) => {
    switch(action){
      case CREATE_OBJECTIVES_ACTIONS.create:
      case CREATE_OBJECTIVES_ACTIONS.replicate:
        dispatch(createObjectiveAction(
          buildCreateObjectiveRequest(data, objectiveType, OBJECTIVE_STATUS.draft),
          handleSuccessEvents
        ));
        break;
      case CREATE_OBJECTIVES_ACTIONS.edit:
        if(objectiveId !== undefined && requiredData.objectiveCurrentValue !== null){
          dispatch(editObjectiveAction(
            buildEditObjectiveRequest(data, requiredData.objectiveCurrentValue), objectiveId.toString(),
            handleSuccessEvents
          ))
        }else {
          console.log("ERROR EDIT NO OBJECTIVE ID")
        }
        
        break;
      default:
        return null
    }
    
  }, [dispatch, objectiveType, action, objectiveId, requiredData, handleSuccessEvents])

  const handleObjectiveType = useCallback((data: OBJECTIVE_TYPES) => {
    setObjectiveType(data)
  }, [])

  const handleObtainedData = useCallback((data: GetObjectivesInitialData) => {
    if(data.objectiveCurrentValue === null){
      // Create an empty object when we are in the creation flow
      setInitialData(buildCreateObjectiveInitialValues(data, userId))
      setRequiredData(data)
    }else {
      const isReplica = action === CREATE_OBJECTIVES_ACTIONS.replicate ? true : false;
      // Create an object based on the existing one for editing or replicate
      setInitialData(initializeFromObjectiveCreated(data.objectiveCurrentValue, data, isReplica))
      setObjectiveType(findObjectiveType(data.objectiveCurrentValue.tgd_objective_type))
      setObjectiveStatus(findObjectiveStatus(data.objectiveCurrentValue.status.id))
      setRequiredData(data)
    }
    
  }, [userId, action])

  const requestInitialData = useCallback((organization: number) => {
    dispatch(getObjectivesInitialData(
      organization,
      handleObtainedData,
      objectiveId
    ))
  }, [dispatch, handleObtainedData, objectiveId])

  useEffect(() => {
    if(organizationId !== null){
      requestInitialData(organizationId)
    }
  }, [organizationId, requestInitialData])

  //console.log("INITIAL DATA: ", initialData)

  return(
    <div className="objectives-main-actions-container">

      <div className="main-form-area">
        {requiredDataLoading === false ?       
          <CreateObjectiveMainForm 
            action={action}
            handleClose={handleClose}
            handleSubmit={handleFormSubmit}
            requiredData={requiredData}
            initialData={initialData}
            objectiveType={objectiveType}
            setObjectiveType={handleObjectiveType}
            handleSaveAsDraft={handleFormAsDraft}
          />        
        :
          <ObjectivesMainContainerLoading />
        }
      </div>                  
    </div>
  )
}

export default ObjectivesMainActionsContainer;

