import {
  SET_ORGANIZATION_COLOR,
  SET_ORGANIZATION_ID,
  SET_ORGANIZATION_INFO_BEGIN,
  SET_ORGANIZATION_INFO_ERROR,
  SET_ORGANIZATION_INFO_SUCCESS,
  SET_ORGANIZATION_LOADING,
  UPDATE_ORGANIZATION_INFO,
} from './info.action';
import { InfoState, InfoActionTypes } from './info.model';

const initialState: InfoState = {
  organizationId: null,
  data: {
    publicName: '',
    legalName: '',
    companyWebsite: '',
    color: '',
    companySize: null,
    frequencyGoals: null,
    timeZone: null,
  },
  loading: 'SUCCESS',
  error: null,
};

export default function infoReducer(
  state: InfoState = initialState,
  action: InfoActionTypes
): InfoState {
  switch (action.type) {
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.payload,
      };
    case SET_ORGANIZATION_INFO_BEGIN:
      return {
        ...state,
        loading: 'PENDING',
        error: null
      };
    case SET_ORGANIZATION_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: 'SUCCESS',
        error: null,
      };
    case SET_ORGANIZATION_INFO_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: 'FAILED',
      };
    case UPDATE_ORGANIZATION_INFO:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ORGANIZATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ORGANIZATION_COLOR:
      return {
        ...state,
        data: {
          ...state.data,
          color: action.payload
        },
      };
    default:
      return state;
  }
}
