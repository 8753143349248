import FormikListener from "@components/FormikComponents/FormikListener/ForrmikListener";
import FormikTextDate from "@components/FormikComponents/FormikTextDate/FormikTextDate";
import FormikTextInput from "@components/FormikComponents/FormikTextInput/FormikTextInput";
import { UpdateObjectiveSchema } from "@formsValidations/formsValidations";
import withDateFastField from "@hocs/withDateFastField";
import withTextFastField from "@hocs/withTextFastField";
import { ParseUpdateKeyResult } from "@models/objetives.model";
import { FieldArray, Form, Formik } from "formik";
import ButtonTapBottom from "pwa/components/Shared/ButtonTapBottom/ButtonTapBottom";
import DoubleCircularProgress from "pwa/components/Shared/DoubleCircularProgress/DoubleCircularProgress";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikTextDateFastField = withDateFastField(FormikTextDate);
interface ObjectivesKRSFormProps {
  keyResults: ParseUpdateKeyResult;
  initialValues: any[]
  getFormValues: (values: any) => void;
  getIsValidForm: (value: boolean) => any;
  onSubmitForm: (values: any) => any;
  isDisableButton?: boolean;
}

const ObjectivesKRSForm = ({
  keyResults,
  initialValues,
  getFormValues,
  getIsValidForm,
  onSubmitForm,
  isDisableButton
}: ObjectivesKRSFormProps) => {
  
  const kr_currentValues = useMemo(() => {
    if (initialValues !== undefined && initialValues.length !== 0) {
      const currentValues = [];
      for (let k = 0; k < initialValues.length; k++) {
        currentValues.push({
          current_val: initialValues[k].current_value,
        });
      }

      return currentValues;
    } else {
      return [];
    }
  }, [initialValues]);

  const getValues = useCallback(
    (info: any) => {
      if (info.keyResults !== undefined) {
        for (let k = 0; k < info.keyResults.length; k++) {
          if (info.keyResults[k] !== undefined) {
            if (info.keyResults[k].new_progress.length !== 0) {
              kr_currentValues[k].current_val = parseFloat(
                info.keyResults[k].new_progress
              );
            } else {
              kr_currentValues[k].current_val = parseFloat(
                initialValues[k].current_value
              );
            }
          }
        }
        getFormValues(kr_currentValues);
      }
    },
    [initialValues, kr_currentValues, getFormValues]
  );

  const getIsValid = useCallback(
    (info: any) => {
      getIsValidForm(info);
    },
    [getIsValidForm]
  );
  return (
    <div className="container-objectives-KRS-form">
      {/* INPUTS */}
      <div className="container-inputs-add-progress">
        <Formik
          initialValues={keyResults}
          validationSchema={UpdateObjectiveSchema}
          onSubmit={onSubmitForm}
        >
          {(formik) => {
            return (
              <Form id={"add-form-progress-mobile"}>
                <FormikListener getValues={getValues} getIsValid={getIsValid} />
                <FieldArray
                  name="keyResults"
                  render={() => {
                    return (
                      <>
                        {formik.values.key_results !== undefined &&
                          formik.values.key_results.length > 0 &&
                          formik.values.key_results.map(
                            (keyResult: any, index: any) => {
                              return (
                                <>
                                  {/* INFO */}
                                  <div className="content-info-kr">
                                    <div className="progress-circular">
                                      <DoubleCircularProgress
                                        isTable
                                        sizeCirular={36}
                                        value={
                                          keyResult?.current_progress * 100
                                        }
                                        valueToShow={
                                          keyResult?.current_progress
                                        }
                                      />
                                    </div>
                                    <div className="description-target-initial">
                                      <div className="description">
                                        <p>
                                          <b>{keyResult?.name}:</b>&nbsp;
                                          {keyResult?.description}
                                        </p>
                                      </div>
                                      <div className="values">
                                        <div className="text-values">
                                          <span><FormattedMessage id={"objectives.modal.title.mobile.initial.value"}/></span>
                                          <span>
                                            &nbsp;{keyResult?.init_value}
                                          </span>
                                        </div>
                                        <div className="text-values">
                                          <span><FormattedMessage id={"objectives.modal.title.mobile.target.value"}/></span>
                                          <span>
                                            &nbsp;{keyResult?.target_value}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="container-input-progress-date">
                                    <span>
                                      <FormattedMessage
                                        id={
                                          "objectives.modal.title.mobile.add.new.progress"
                                        }
                                      />
                                    </span>
                                    <FormikTextInputFastField
                                      name={`keyResults.${index}.new_progress`}
                                      viewMode={false}
                                      adornment={keyResult.unit}
                                      maxWidth="100%"
                                      charsLimit={9}
                                      placeholder="objectives.modal.update.progress"
                                      placeholderValues={{
                                        value:
                                          keyResult.current_value === null
                                            ? keyResult.init_value
                                            : keyResult.current_value,
                                      }}
                                      hideCharsLimitDescription
                                      validateOnClick
                                    />
                                  </div>
                                  <div className="container-input-progress-date">
                                    <span>
                                      <FormattedMessage
                                        id={
                                          "objectives.modal.title.mobile.date.progress"
                                        }
                                      />
                                    </span>
                                    <FormikTextDateFastField
                                      name={`keyResults.${index}.update_date`}
                                      viewMode={false}
                                      specialViewMode={true}
                                    />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </>
                    );
                  }}
                />
                {/* BUTTON ADD PROGRESS */}
                <div className="btn-form-update-objective">
                  <ButtonTapBottom
                    isSubmit
                    disable={isDisableButton}
                    title={"objectiveform.save"}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ObjectivesKRSForm;
