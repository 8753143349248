import React, { useState, useEffect, useCallback, useMemo } from 'react';
import StatementForm from '@components/Organizations/Statements/StatementsForm/StatementsForm';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ROUTE } from '@utils/routes';
import {
  Statement,
  GqlGetStatements,
  FormStatementsValues,
  GqlArchiveStatementMutation,
} from '@models/statement.model';
import './styles.scss';
import { useMutation, useQuery } from '@apollo/client';
import { ARCHIVE_STATEMENT, GET_STATEMENTS } from '@graphql/organization.queries';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';
import { emptyStatement } from '@utils/empty';
import { getStatementsCount, postAddStatements, putEditStatements } from '@services/organization.service';
import Spinner from '@components/Shared/Spinner/Spinner';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import GenericErrorComponent from '@components/Shared/GenericErrorComponent/GenericErrorComponent';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import useToastNotification from 'hooks/useToastNotification';

// año-mes-dia
const StatemntContainer = () => {
  const actions = useSelector((state: RootState) => state.User.Info.actions.organization);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const [statements, setStatement] = useState<Statement[]>([emptyStatement]);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState({
    action: '',
    subject: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [archivedStatementsCount, setArchivedStatementsCount] = useState<number>(0);
  const history = useHistory();
  const {
    loading: loadingCall,
    error,
    data,
    refetch,
  } = useQuery<GqlGetStatements>(GET_STATEMENTS, {
    variables: { organizationId, isArchived: false },
    fetchPolicy: 'no-cache',
  });
  const [archiveStatement, { data: dataArchive, error: errorArchive }] =
    useMutation<GqlArchiveStatementMutation>(ARCHIVE_STATEMENT);

  const toastNotification = useToastNotification();

  const showArchived = useMemo(
    () =>
      actions?.find((action) => action.key_name === ACTIONS_CATALOGS.SHOW_ARCHIVED) && archivedStatementsCount > 0
        ? true
        : false,
    [actions, archivedStatementsCount]
  );

  const allowAdd = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.ADD)) return true;
    return false;
  }, [actions]);

  useEffect(() => {
    if (data) {
      setLoading(true);
      const statements: Statement[] = data.tgdStatements.map((statement) => ({
        id: statement.id.toString(),
        statement: statement.name,
        date: statement.valid_until,
        description: statement.description,
        loadingArchive: false,
      }));

      if (!statements.length && allowAdd) {
        statements.push(emptyStatement);
        setEditMode(true);
      }

      setStatement(statements);
      setLoading(false);
    }
  }, [allowAdd, data]);

  const redirect = useCallback(
    (href: string) => {
      history.push(href);
    },
    [history]
  );

  const onArchiveStatement = useCallback(
    (id: string) => {
      const statementIndex = statements.findIndex((statement) => statement.id === id);
      let loadingStatement = statements;
      loadingStatement[statementIndex].loadingArchive = true;
      setStatement(loadingStatement);
      archiveStatement({
        variables: {
          id: id,
          isArchived: true,
        },
      });
      setArchivedStatementsCount(archivedStatementsCount + 1);
    },
    [archiveStatement, statements, archivedStatementsCount]
  );

  // useEffect exec when a statement is archived successfully
  useEffect(() => {
    if (errorArchive) {
      const statementsNotLoading: Statement[] = statements.map((statement) => ({
        ...statement,
        loadingArchive: false,
      }));
      setErrorModalMessage({
        action: 'archiving',
        subject: 'statement',
      });
      setShowErrorModal(true);
      setStatement(statementsNotLoading);
    } else {
      if (dataArchive) {
        const archivedStatement = dataArchive.updateTgdStatement.tgdStatement;
        if (archivedStatement.is_archived) {
          const newStatements: Statement[] = statements.filter((statement) => statement.id !== archivedStatement.id);

          if (!newStatements.length) {
            newStatements.push(emptyStatement);
            setEditMode(true);
          }

          toastNotification('statement.success.archive', false);
          setStatement(newStatements);
        }
      }
    }
    // eslint-disable-next-line
  }, [errorArchive, dataArchive]);

  const onSave = useCallback(
    async (data: FormStatementsValues) => {
      setLoadingSave(true);
      if (organizationId !== null) {
        const postData: Statement[] = data.statements.filter((statement) => !statement.id);
        const putData: Statement[] = data.statements.filter((statement) => statement.id);

        try {
          if (putData.length) {
            await putEditStatements(organizationId, putData);
          }
          if (postData.length) {
            await postAddStatements(organizationId, postData);
          }
          refetch();
          toastNotification('general.modal.success.changes', false);
          setEditMode(false);
        } catch (error) {
          console.error('onSave', error);
          setErrorModalMessage({
            action: 'saving',
            subject: 'statements',
          });
          setShowErrorModal(true);
        } finally {
          setLoadingSave(false);
        }
      }
    },
    [organizationId, refetch, toastNotification]
  );

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
    setErrorModalMessage({
      action: '',
      subject: '',
    });
  }, []);

  const getArchivedStatementsCount = useCallback(async () => {
    if (organizationId) {
      try {
        const count = await getStatementsCount(true, organizationId);
        setArchivedStatementsCount(count);
      } catch (error) {
        console.error(error);
      }
    }
  }, [organizationId]);

  useEffect(() => {
    getArchivedStatementsCount();
  }, [getArchivedStatementsCount]);

  return (
    <>
      <div className='statement-container'>
        {loading || loadingCall ? (
          <div className='screen-loader'>
            <Spinner size={40} color='primary' thickness={4} />
          </div>
        ) : error ? (
          <div className='screen-loader'>
            <GenericErrorComponent label={'statements'} retryCallback={() => refetch()} />
          </div>
        ) : (
          <>
            <StatementForm
              statements={statements}
              archiveMode={false}
              editMode={editMode}
              onEdit={(edit: boolean) => setEditMode(edit)}
              onSubmit={onSave}
              loadingSave={loadingSave}
              onArchive={(id) => onArchiveStatement(id)}
            />
            {showArchived && (
              <div className='organization-footer'>
                <span onClick={() => redirect(ROUTE.archiveStatements.path)}>
                  <FormattedMessage id={'statement.archive.see'} />
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <GenericErrorModal
        isOpen={showErrorModal}
        handleClose={handleCloseErrorModal}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: errorModalMessage.action, subject: errorModalMessage.subject },
        }}
      />
    </>
  );
};

export default StatemntContainer;
