import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

interface KrDescriptionProps {
  description: string;
}

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    width: '101px',
    padding: 0,
    marginBottom: -1,
    minHeight: 19,
    '&$expanded': {
      minHeight: 19,
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '10px 0 0',
  },
}))(MuiAccordionDetails);

const KrDescription = ({ description }: KrDescriptionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Accordion
      className='component-krdescription'
      square
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        aria-controls='panel-content'
        id='panel-header'
        expandIcon={<ExpandMoreIcon style={{ fontSize: 33 }} />}
      >
        <span className='description-caption'>
          <FormattedMessage id='general.label.description' />
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <p className='description-text'>{description}</p>
      </AccordionDetails>
    </Accordion>
  );
};

export default KrDescription;
