import './styles.scss';

interface TegduSkeletonLoadingProps {
  height?: string
  marginTop?: string
  type: 'row' | 'form' | 'chart'
  width?: string
}

const TegduSkeletonLoading = (props: TegduSkeletonLoadingProps) => {
  const { type, marginTop, height, width } = props;
  return(
    <div className={`
      tegdu-skeleton-loading ${type}`} 
      style={{
        width: width ? width : "100%",
        marginTop: marginTop ? marginTop : undefined,
        height: height ? height : undefined
      }}
    >

    </div>
  )
}

export default TegduSkeletonLoading;