import GenericTable from '@components/Shared/GenericTable/GenericTable'
import { Card } from '@material-ui/core'
import { GetSkillsAptitudesModelI } from '@store/HRTeam/HRTeamTabs/hrteam.model'
import SkillsAptitudesColumns from '../SkillsAptitudesColumns/SkillsAptitudesColumns'
import './styles.scss'

interface SkillsAptitudesTableProps {
    dataTable: GetSkillsAptitudesModelI[] | any;
    loading: boolean;
}

const ExpandedComponent = ({data}: GetSkillsAptitudesModelI[] | any) => {
  console.log(data.description);
  return (
    <div className='expandable-row'>
      Description: {data.description}
    </div>
  )
}

const SkillsAptitudesTable = ({dataTable, loading}: SkillsAptitudesTableProps) => {
  return (
    <div className='skills-aptitudes-table-container'>
      <Card>
        <GenericTable
            label='skills-aptitudes'
            data={dataTable}
            pointerOnHover
            loading={loading}
            error={undefined}
            columns={SkillsAptitudesColumns()}
            pagination
            expandableRows
            expandableRowsComponent={<ExpandedComponent />}
        />
      </Card>
    </div>
  )
}

export default SkillsAptitudesTable
