import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core';
import StyledDropdown from '@components/Shared/StyledDropdown/StyledDropdown';
import StatusComponent from '@components/Shared/StatusComponent/StatusComponent';
import { useQuery } from '@apollo/client';
import { GET_TEAMS_CATALOG } from '@graphql/teams.queries';
import { TeamsCatalogue } from '@models/teams.model';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import './styles.scss'
import { getGraphqlData } from '@services/graphql.service';
import { GET_ROLES_PLAIN } from '@graphql/members.queries';
import Searchbar from '@components/Shared/Searchbar/Searchbar';


interface MembersFiltersProps {
  setFilterVaules: (values: any) => void
  cleanFilters: boolean
  onCleanFilters: () => void
  disabled: boolean
}

const statusOptions = [
  { value: 'false', label: <StatusComponent size='table' status={false} /> },
  { value: 'true', label: <StatusComponent size='table' status={true} /> },
]

const MembersFilters = ({ cleanFilters, disabled, setFilterVaules, onCleanFilters }: MembersFiltersProps) => {
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const { data, loading, error } = useQuery<TeamsCatalogue>(GET_TEAMS_CATALOG, {
    variables: {
      organizationId
    }
  })
  const [searchValue, setSearchValue] = useState('');
  const [roleValue, setRoleValue] = useState('');
  const [teamValue, setTeamValue] = useState('');
  const [statusValue, setStatusValue] = useState('')

  const [roleOptions, setRoleOptions] = useState([])

  const getSearchValue = useCallback((value: string) => {
    setSearchValue(value)
  }, [])

  useEffect(() => {
    if (cleanFilters === true) {
      setSearchValue('')
      setRoleValue('')
      setTeamValue('')
      setStatusValue('')
      onCleanFilters()
    } else {
      setFilterVaules({ searchValue, roleValue, teamValue, statusValue })
    }
  }, [onCleanFilters, cleanFilters, setFilterVaules, searchValue, roleValue, teamValue, statusValue])

  const teamsCatalogue = useMemo(() => {
    if (loading === false && error === undefined && data !== undefined) {
      return data.tgdTeams
    } else {
      return []
    }
  }, [loading, error, data])

  const getRoleCatalog = useCallback(async () => {
    try {
      const response = await getGraphqlData(GET_ROLES_PLAIN);
      setRoleOptions(response.roles.filter((response: any) => response.visible === true))
    } catch {
      setRoleOptions([])
    }
  }, [])

  useEffect(() => {
    getRoleCatalog()
  }, [getRoleCatalog])

  return (
    <div className="members-filters">
      <Grid container className='members-filter-container' spacing={1}>
        <Grid item md={5} sm={12} xs={12} className={'members-search-bar'}>
          <Searchbar intlId="members.searchbar.placeholder" onSearch={getSearchValue} className="members-text-field" valueInput={searchValue} />
        </Grid>

        <Grid item md={6} sm={12} className='members-multi-dropdowns'>
          <Grid container spacing={1}>
            {/* ROLE DROPDOWN */}
            <Grid item md={4} sm={4} xs={12} className='drop-filters'>
              <StyledDropdown
                label={'inputlabel.role'}
                viewMode={false}
                options={roleOptions}
                onSelectOption={(data: any) => setRoleValue(data)}
                cleanFilter={cleanFilters}
                isSearchable={false}
                isDisabled={disabled}
              />
            </Grid>

            {/* *TEAMS DROPDOWN*/}
            <Grid item md={4} sm={4} xs={12} className='drop-filters'>
              <StyledDropdown
                label={'inputlabel.team'}
                viewMode={false}
                options={teamsCatalogue}
                onSelectOption={(data: any) => setTeamValue(data)}
                cleanFilter={cleanFilters}
                isSearchable={true}
                isDisabled={disabled}
              />
            </Grid>

            {/* STATUS DROPDOWN */}
            <Grid item md={4} sm={4} xs={12} className='drop-filters'>
              <StyledDropdown
                label={'inputlabel.status'}
                viewMode={false}
                options={statusOptions}
                onSelectOption={(data: any) => setStatusValue(data)}
                cleanFilter={cleanFilters}
                isSearchable={false}
                isDisabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1} sm={12} xs={12} className='members-button'>

        </Grid>
      </Grid>
    </div>
  )
}

export default MembersFilters;