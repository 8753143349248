import { CompanySizeCatalogueActionTypes, CompanySizeCatalogueState } from './companySize.model';
import {
  SET_COMPANYSIZE_CATALOGUE_BEGIN,
  SET_COMPANYSIZE_CATALOGUE_SUCCESS,
  SET_COMPANYSIZE_CATALOGUE_ERROR,
} from './companySize.action';

const initialState: CompanySizeCatalogueState = {
  data: [],
  loading: false,
  error: null,
};

export default function companySizeReducer(
  state: CompanySizeCatalogueState = initialState,
  action: CompanySizeCatalogueActionTypes
): CompanySizeCatalogueState {
  switch (action.type) {
    case SET_COMPANYSIZE_CATALOGUE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SET_COMPANYSIZE_CATALOGUE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SET_COMPANYSIZE_CATALOGUE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
