import TgdClient from '@helpers/HttpClients/TgdClient';
import { ApiPostLogin, LoginParams, User } from '@models/user.model';

/**=========================
 * @desc All FXN endpoints
 * @required FxnClient
 =========================*/
//login
export const login = async ({ email, password }: LoginParams): Promise<ApiPostLogin> =>
  TgdClient.post('/auth/local', {
    identifier: email,
    password: password,
  });

//automaticLogin
export const autoLogin = async (jwt: string): Promise<ApiPostLogin> =>
  TgdClient.post('', {
    token: jwt,
  });

//logout
// export const logout = async () => FxnClient.post('/logoutdummy');
export const logout = async () => await new Promise<boolean>((resolve) => setTimeout(() => resolve(true), 0));

// Update tour flag
export const updateTour = async (tour: boolean): Promise<User> =>
  TgdClient.put('/tgd-users/tour', {
    tour,
  });
