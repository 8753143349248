import { 
  SET_CULTURAL_ATTRIBUTES_ARCHIVE_LOADING,
  SET_CULTURAL_ATTRIBUTES_ERROR, 
  SET_CULTURAL_ATTRIBUTES_LOADING, 
  SET_CULTURAL_ATTRIBUTES_UPDATE_ERROR, 
  SET_CULTURAL_ATTRIBUTES_UPDATE_LOADING
} from "./culturalAttributes.actions";
import { 
  CulturalAttributesActions, 
  CulturalAttributesStateModel 
} from "./culturalAttributes.model";

const initialState: CulturalAttributesStateModel = {
  isRequestLoading: false,
  isRequestError: false,
  isUpdateLoading: false,
  isUpdateError: false,
  isArchiveLoading: false
}

const culturalAttributesReducer = (
  state: CulturalAttributesStateModel = initialState,
  actions: CulturalAttributesActions
): CulturalAttributesStateModel => {
  switch(actions.type){
    case SET_CULTURAL_ATTRIBUTES_LOADING:
      return {
        ...state,
        isRequestLoading: actions.payload
      }
    case SET_CULTURAL_ATTRIBUTES_ERROR:
      return {
        ...state,
        isRequestError: actions.payload
      }
    case SET_CULTURAL_ATTRIBUTES_UPDATE_LOADING:
      return {
        ...state,
        isUpdateLoading: actions.payload
      }
    case SET_CULTURAL_ATTRIBUTES_UPDATE_ERROR:
      return {
        ...state,
        isUpdateError: actions.payload
      }
    case SET_CULTURAL_ATTRIBUTES_ARCHIVE_LOADING:
      return {
        ...state,
        isArchiveLoading: actions.payload
      }
    default:
      return state
  }
}

export default culturalAttributesReducer;