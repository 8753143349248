import GenericTable from "@components/Shared/GenericTable/GenericTable";
import { ApiGetObjective } from "@models/objetives.model";
import { ROUTE } from "@utils/routes";
import { ObjectivesTableColumns } from "pwa/components/Objectives/ObjectivesTableColmuns/ObjectivesTableColumns";
import { useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom";
import "./styles.scss"

interface ObjectivesTableProps {
  loading?: boolean;
  dataObjectives?: any;
}

const ObjectivesTable = ({
  loading = false,
  dataObjectives,
}: ObjectivesTableProps) => {
  const history = useHistory();
  const onRowClicked = useCallback(
    (tableData: ApiGetObjective) => {
      history.push(
        generatePath(ROUTE.objectivesDetail.path, { id: tableData.id })
      );
    },
    [history]
  );
  return (
    <div className="container-table-mobile">
      <GenericTable
        label={"objetives"}
        columns={ObjectivesTableColumns()}
        data={dataObjectives}
        pagination
        pointerOnHover={false}
        loading={loading}
        error={undefined}
        paginationPerPage={14}
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export { ObjectivesTable as ObjectivesTableMobile };
