import { ApiGetObjective, DashboardActiveObjective } from '@models/objetives.model';
import { DashboardCatalog } from '@models/catalogs.model';
export const TOUR_DASHBOARD_STATEMENTS_SAMPLE: ApiGetObjective[] = [
  {
    id: 0,
    owner: {
      id: 0,
      first_name: 'Jhon',
      last_name: 'Doe',
    },
    name: 'Improve OKR Performance',
    role: 1,
    due_date: '2023-06-30T00:00:00.000Z',
    progress: 1,
    status: 2,
    created_at: '2023-05-08T19:36:12.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: 'Jhon',
      last_name: 'Doe',
    },
    name: 'Implement OKRs in the Team',
    role: 2,
    due_date: '2024-01-12T06:00:00.000Z',
    progress: 0.5,
    status: 2,
    created_at: '2023-05-08T19:23:30.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
];

export const TOUR_DASHBOARD_STATEMENTS: DashboardCatalog[] = [
  {
    name: 'Mission',
    description:
      'To revolutionize the technological landscape by delivering innovative solutions that empower individuals and businesses to thrive in a digital world. Our mission is to create cutting-edge technologies, provide exceptional services, and foster a culture of continuous learning and growth.',
  },
  {
    name: 'Expertise',
    description:
      'We want to be the top of mind for technological expertise and mission-critical systems in Latin America.',
  },
  {
    name: 'Attention',
    description:
      'We prioritize understanding our clients goals and challenges, and we work collaboratively to develop tailored strategies and solutions. By combining our technical expertise with a deep understanding of industry trends, we empower our clients to embrace innovation and stay ahead in a rapidly evolving digital landscape.',
  },
];

export const TOUR_DASHBOARD_CULTURAL_ATT: DashboardCatalog[] = [
  {
    name: 'Innovation',
    description:
      ' We foster a culture of curiosity, exploration, and experimentation, constantly pushing the boundaries of whats possible in the world of technology. We believe that innovation is the key to staying ahead in a rapidly evolving industry.',
  },
  {
    name: 'Agility',
    description:
      'We prioritize agility and adaptability, embracing change as an opportunity for growth. By fostering a culture that embraces and celebrates innovation, we aim to shape the future of technology and provide our customers with transformative solutions that unlock their full potential.',
  },
  {
    name: 'Corage',
    description:
      'We encourage our team members to think creatively, challenge conventional wisdom, and explore new ideas. We invest in research and development, staying abreast of emerging technologies and trends. Through continuous learning and collaboration, we strive to bring groundbreaking solutions to market that disrupt the status quo and drive positive change.',
  },
];

export const TOUR_DASHBOARD_OBJ_PROGRESS: DashboardActiveObjective = {
  owner: {
    totals: [1,1]
  },
  coach: {
    totals: []
  },
  collaborator: {
    totals: []
  },
};
