import './styles.scss';

const TegduWarningMessage = () => {
  return(
    <div className="tegdu-warning-message">
      <div className="circle">
        !
      </div>
      <span>
        The initial and target value of a KR cannot be the same 
      </span>
    </div>
  )
}

export default TegduWarningMessage;