import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
//import { OKR_TYPES } from '@utils/objectives';

import './styles.scss';
interface RoundButtonProps {
  text: string;
  type: any;
  selected?: boolean;
  onButtonClick: (type: any) => void;
  disabled?: boolean;
  styles?: "culturalAttributes"
}

function RoundButton({ text, type, selected, onButtonClick, disabled, styles }: RoundButtonProps) {
  const [buttonStyle] = useState(styles !== undefined ? styles : "objective")
  const onClick = () => {
    onButtonClick(type);
    console.log(type);
  };

  return (
    <Button
      disabled={disabled ? true : false}
      onClick={onClick}
      className={
        'secondary-gray ' + (selected ? `${buttonStyle}-type-button selected` : `${buttonStyle}-type-button`)
      }
    >
      <FormattedMessage id={text} />
    </Button>
  );
}

export default RoundButton;
