import TegduMobileModal from 'pwa/components/Shared/TegduMobileModal/TegduMobileModal';
import TegduSearchBar from 'pwa/components/Shared/TegduSearchbar/TegduSearchBar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './style.scss';
import SearchReviewContainer from './SearchReviewContainer/SearchReviewContainer';
import { ReviewsFiltersMobile } from 'pwa/components/Reviews/ReviewsFilters/ReviewsFilters';
import { ReviewsTableMobile } from 'pwa/components/Reviews/ReviewsTable/ReviewsTable';
import { getReviewsBy } from '@services/reviews.service';
import { ReviewsModelI } from '@store/Reviews/reviews.model';
import ReviewsBottomSheet from 'pwa/components/Reviews/ReviewsBottomSheet/ReviewsBottomSheet';
import { ReviewsFiltersSelected } from 'lib/TegduForms/MobileFilterReviewFormUtils';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ROUTE } from '@utils/routes';
import { ApiGetObjective } from '@models/objetives.model';
import { generatePath, useHistory } from 'react-router-dom';
import ScoreObjectiveDetailContainer, {
  ScoreObjectiveDetailContainerData,
} from 'pwa/components/Score/ScoreObjectiveDetailContainer/ScoreObjectiveDetailContainer';
import moment from 'moment';
import ReviewAgainIndividualContainer from './ReviewAgainIndividualContainer/ReviewAgainIndividualContainer';

const initialDataModalReview: ScoreObjectiveDetailContainerData = {
  score: 0,
  titleObjectiveReview: '',
  dateObjectiveReview: '',
  name: '',
  reviewBy: '',
  type: 'General',
  comment: '',
};

const ReviewsContainer = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reviewsTableData, setReviewsTableData] = useState<ReviewsModelI | any>([]);
  const [optionSelected, setOptionSelected] = useState<number>(0);
  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);
  const [openModalDetailReview, setOpenModalDetailReview] = useState<boolean>(false);
  const [dataModalDetailReview, setDataModalDetailReview] =
    useState<ScoreObjectiveDetailContainerData>(initialDataModalReview);
  const [openIndividualRateModal, setOpenIndividualRateModal] = useState<boolean>(false);
  const [dataInvidualRateModal, setDataIndividualRateModal] =
    useState<ApiGetObjective | null>(null);
  const [searchModal, setSearchModal] = useState<boolean>(false);
  const [dataToFilter, setDataToFilter] = useState<ReviewsFiltersSelected>();

  const history = useHistory();

  const memoizedData = useMemo(() => dataToFilter, [dataToFilter]);

  const sortDataByScore = (arr: ReviewsModelI[]) => {
    return (
      arr !== undefined &&
      arr.sort((firstValue, secondValue) => firstValue.score - secondValue.score)
    );
  };

  const filteredDataToRender = useMemo(() => {
    let filteredData: ReviewsModelI[];
    const today = moment().format().slice(0, 10);

    if (
      (memoizedData?.minorScore !== undefined && memoizedData?.majorScore !== undefined) ||
      (memoizedData?.startDate !== undefined && memoizedData?.endDate !== undefined)
    ) {
      if (
        memoizedData?.minorScore !== 0 &&
        memoizedData?.majorScore !== 0 &&
        memoizedData.startDate === 'Invalid date' &&
        memoizedData.endDate === 'Invalid date'
      ) {
        // Filter by Score range
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) =>
            val.score >= memoizedData?.minorScore! && val.score <= memoizedData?.majorScore!
        );
        return sortDataByScore(filteredData);
      } else if (
        memoizedData.startDate !== 'Invalid date' &&
        memoizedData.endDate !== 'Invalid date' &&
        memoizedData?.minorScore === 0 &&
        memoizedData?.majorScore === 0
      ) {
        // Filter by Date range
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) =>
            val.created_at.slice(0, 10) >= memoizedData?.startDate?.slice(0, 10)! &&
            val.created_at.slice(0, 10) <= memoizedData?.endDate?.slice(0, 10)!
        );
        return filteredData;
      } else if (
        memoizedData.startDate !== 'Invalid date' &&
        memoizedData.endDate !== 'Invalid date' &&
        memoizedData?.minorScore === 5 &&
        memoizedData?.majorScore === 0
      ) {
        // Filter for Score 5 and Date range
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) =>
            val.score >= memoizedData?.minorScore! &&
            val.created_at.slice(0, 10) >= memoizedData?.startDate?.slice(0, 10)! &&
            val.created_at.slice(0, 10) <= memoizedData?.endDate?.slice(0, 10)!
        );
        return filteredData;
      } else if (
        memoizedData.startDate === 'Invalid date' &&
        memoizedData.endDate === 'Invalid date' &&
        memoizedData?.minorScore === 5 &&
        memoizedData?.majorScore === 0
      ) {
        // Filter only for Score = 5
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) => val.score === memoizedData?.minorScore!
        );
        return filteredData;
      } else if (
        memoizedData.startDate !== 'Invalid date' &&
        memoizedData.endDate === 'Invalid date' &&
        memoizedData?.minorScore! === 0 &&
        memoizedData?.majorScore! === 0
      ) {
        // Only today
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) => val.created_at.slice(0, 10) === today
        );
        return filteredData;
      } else if (
        memoizedData.startDate !== 'Invalid date' &&
        memoizedData.endDate === 'Invalid date' &&
        memoizedData?.minorScore! > 0 &&
        memoizedData?.majorScore !== 0
      ) {
        // Today and a Score range
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) =>
            val.created_at.slice(0, 10) === today &&
            val.score >= memoizedData?.minorScore! &&
            val.score <= memoizedData?.majorScore!
        );
        return filteredData;
      } else if (
        memoizedData.startDate !== 'Invalid date' &&
        memoizedData?.minorScore! === 5 &&
        memoizedData.endDate === 'Invalid date' &&
        memoizedData?.majorScore === 0
      ) {
        // Today and score = 5
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) =>
            val.created_at.slice(0, 10) === today && val.score === memoizedData?.minorScore!
        );
        return filteredData;
      } else if (
        memoizedData.minorScore! > 0 &&
        memoizedData.majorScore! > 0 &&
        memoizedData.startDate !== 'Invalid date' &&
        memoizedData.endDate !== 'Invalid date'
      ) {
        // Filter by range Score & range Date
        filteredData = reviewsTableData.filter(
          (val: ReviewsModelI) =>
            val.score >= memoizedData?.minorScore! &&
            val.score <= memoizedData?.majorScore! &&
            val.created_at.slice(0, 10) >= memoizedData?.startDate?.slice(0, 10)! &&
            val.created_at.slice(0, 10) <= memoizedData?.endDate?.slice(0, 10)!
        );
        return filteredData;
      } else if (
        memoizedData.minorScore === 0 &&
        memoizedData.majorScore === 0 &&
        memoizedData.startDate === 'Invalid date' &&
        memoizedData.endDate === 'Invalid date'
      ) {
        filteredData = reviewsTableData;
        return filteredData;
      }
    } else {
      filteredData = reviewsTableData;
      return filteredData;
    }
  }, [memoizedData, reviewsTableData]);

  const closeSearchModal = useCallback(() => setSearchModal(false), []);

  const openSearchModal = useCallback(() => setSearchModal(true), []);

  const closeReviewDetailModal = useCallback(() => {
    setOpenModalDetailReview(false);
    searchModal && closeSearchModal();
  }, [closeSearchModal, searchModal]);

  const onRowClicked = useCallback(
    (tableData: ApiGetObjective) => {
      generateDataModalDetailReview(tableData);
      setOpenModalDetailReview(!openModalDetailReview);
      history.push(generatePath(ROUTE.reviews.path, { id: tableData.id }));
    },
    [history, openModalDetailReview]
  );

  const generateDataModalDetailReview = (tableData: ApiGetObjective) => {
    setDataIndividualRateModal(tableData);
    const nameCompletedReviewed = `${tableData?.reviewed?.first_name}  ${tableData?.reviewed?.last_name}`;
    const nameCompletedReviewer = `${tableData?.reviewer?.first_name}  ${tableData?.reviewer?.last_name}`;
    const generalOrType = tableData.general_review ? 'General' : 'Individual';
    setDataModalDetailReview({
      comment: `${tableData.comment}`,
      dateObjectiveReview: `${tableData.created_at}`,
      name: nameCompletedReviewed,
      reviewBy: nameCompletedReviewer,
      titleObjectiveReview: `${tableData.tgd_objective?.name}`,
      score: tableData.score || 0,
      type: generalOrType,
    });
  };
  const getSelectedOption = useCallback(async (selectedOption: number) => {
    setLoadingTable(true);
    setIsError(false);
    setOptionSelected(selectedOption);
    try {
      const response = await getReviewsBy(selectedOption === 0 ? 'given' : 'received');
      setReviewsTableData(response);
      setLoadingTable(false);
    } catch {
      setLoadingTable(false);
      setIsError(true);
    }
  }, []);

  useEffect(() => {
    getSelectedOption(0);
  }, [getSelectedOption]);

  const clearFilters = useCallback(() => {
    setOpenBottomSheet(false);
    setDataToFilter({
      minorScore: 0,
      majorScore: 0,
      startDate: 'Invalid date',
      endDate: 'Invalid date',
    });
    getSelectedOption(0);
  }, [getSelectedOption]);

  const retryGetReviews = useCallback(() => {
    setDataToFilter({ minorScore: 0, majorScore: 0, startDate: '', endDate: '' });
    getSelectedOption(0);
  }, [getSelectedOption]);

  const handleBottomSheetOpen = useCallback(() => setOpenBottomSheet(true), []);

  const handleBottomSheetClose = useCallback(() => setOpenBottomSheet(false), []);

  const filtersToHandle = useCallback((data: ReviewsFiltersSelected) => {
    setDataToFilter(data);
  }, []);

  const closeIndividualReviewModalSuccess = useCallback(() => {
    setOpenModalDetailReview(false);
    setOpenIndividualRateModal(false);
    closeSearchModal();
    getSelectedOption(0);
  }, [closeSearchModal, getSelectedOption]);

  return (
    <div className='reviews-mobile-container'>
      <div className='search-filter-wrapper'>
        <div className='searchbar-mobile' onClick={openSearchModal}>
          <TegduSearchBar
            placeholderId='objectives.searchbar.placeholder'
            onSearch={() => null}
            isDisabled={false}
          />
        </div>
        <FilterListIcon onClick={handleBottomSheetOpen} className='filter-icon' />
      </div>

      {/* FILTERS */}
      <ReviewsFiltersMobile optionSelected={optionSelected} onSelectOption={getSelectedOption} />
      {/* TABLE */}
      <ReviewsTableMobile
        loading={loadingTable}
        dataReviews={filteredDataToRender}
        error={isError}
        retryCallback={retryGetReviews}
        onRowClicked={onRowClicked}
      />
      <ReviewsBottomSheet
        onClearFilter={clearFilters}
        openSheet={openBottomSheet}
        getSelectedOptionRole={getSelectedOption}
        optionSelectedRole={optionSelected}
        onCloseSheet={handleBottomSheetClose}
        filtersToHandle={filtersToHandle}
      />

      <TegduMobileModal
        isOpen={searchModal}
        onClose={closeSearchModal}
        classNameContent='reviews-search'
        container={<SearchReviewContainer onClose={closeSearchModal} onRowClicked={onRowClicked} />}
      />
      <TegduMobileModal
        isOpen={openModalDetailReview}
        onClose={closeReviewDetailModal}
        type='back'
        classNameContent={'contentModalDetailsObjectives'}
        classNameHeader={'contentModalDetailsObjectivesHeader'}
        container={
          <ScoreObjectiveDetailContainer
            dataModal={dataModalDetailReview}
            onActionReviewAgain={() => setOpenIndividualRateModal(true)}
          />
        }
      />

      <TegduMobileModal
        isOpen={openIndividualRateModal}
        type='close'
        onClose={() => setOpenIndividualRateModal(false)}
        container={
          <ReviewAgainIndividualContainer
            data={dataInvidualRateModal}
            handleCloseModal={() => setOpenIndividualRateModal(false)}
            handleCloseModalSuccess={closeIndividualReviewModalSuccess}
          />
        }
      />
    </div>
  );
};

export default ReviewsContainer;
