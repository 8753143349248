import Spinner from "@components/Shared/Spinner/Spinner";
import { Button, Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import './styles.scss';

interface OrganizationsFormsHeaderProps {
  editMode: boolean
  isCancelDisabled: boolean
  isLoading: boolean
  isValidForm: boolean
  titleId: string
  editCallback: () => void
  cancelCallback: () => void
}


const OrganizationsFormsHeader = (props: OrganizationsFormsHeaderProps) => {
  const { editMode, titleId, isCancelDisabled, isLoading, isValidForm, editCallback, cancelCallback } = props;
  return(
    <div className="organizations-forms-header">
      <span>
        <FormattedMessage 
          id={titleId}
        />
      </span>

      <div className='action-buttons'>
        {!editMode ? (
          <Button 
            disabled={editMode || isLoading} 
            className='secondary' 
            onClick={isLoading ? () => null : editCallback}
          >
            <Icon className='icon'>edit</Icon>
            <FormattedMessage id={'general.edit'} />
          </Button>
        ) : (
          <>
            <Button 
              className='secondary' 
              disabled={isCancelDisabled || isLoading} 
              onClick={isLoading ? () => null : cancelCallback} 
              style={{marginRight: "12px"}}
            >
              <FormattedMessage id={'general.cancel'} />
            </Button>
            <Button disabled={!isValidForm || isLoading} type='submit' >
              {isLoading && <Spinner />} <FormattedMessage id={'statement.save'} />
            </Button>
          </>
        )}          
      </div>
    </div>
  )
}

export default OrganizationsFormsHeader;