import { CulturalAttributesObjectModel, CulturalAttributesTagsModel } from "@models/culturalAttributes.models";
import { TegduSelectOptionsModel } from "@models/global.model";
import { ApiEditPillars, ApiPillarUser } from "@models/pillars.model";
import { PutPillarUpdateTags } from "@models/tags.model";
import { MEMBER_ROLE } from "@utils/members";
import { CulturalAttributesFormObjectModel } from "lib/TegduForms/CulturalAttributesFormUtils";

export const buildCulturalAttributesTags = (
  initialTags: CulturalAttributesTagsModel [],
  formTags: TegduSelectOptionsModel []
): PutPillarUpdateTags [] => {
  let tags: PutPillarUpdateTags [] = [];

  // Add new tags
  for(let k = 0; k < formTags.length; k++){
    let isValue: boolean = false;
    for(let j = 0; j < initialTags.length; j++){
      if(initialTags[j].tgd_tag !== null){
        if(formTags[k].value === initialTags[j].tgd_tag.id){
          isValue = true
        }
      }
     
    }

    if(isValue === false){
      tags.push({
       tgd_tag: formTags[k].value as number
      });
    }
  }

  // Remove tags
  for(let j = 0; j < initialTags.length; j++){
    let isValue: boolean = false;
    for(let k = 0; k < formTags.length; k++){
      if(initialTags[j].tgd_tag !== null){ 
        if(formTags[k].value === initialTags[j].tgd_tag.id){
          isValue = true
        }
      }     
    }
    
    if(isValue === false){
      tags.push({
        id: parseInt(initialTags[j].id),
        active: false,
      });
    }
  }
  
  return tags
}



export const buildEditCulturalAttributesToEdit = (
  formData: CulturalAttributesFormObjectModel [],
  initialData: CulturalAttributesObjectModel [],
  organizationId: number
): ApiEditPillars []  => { 

  const culturalAttributesBody: ApiEditPillars [] = formData.map((formAttribute, index) => {
    let users: ApiPillarUser [] = [];
    
    if(formAttribute.id !== undefined){
      const initialCulturalAttribute = initialData.find((element) => parseInt(element.id) === formAttribute.id);
      // OWNER
      // When culturalAttribute already has an owner
      if(initialCulturalAttribute && initialCulturalAttribute.owner){
        if(parseInt(initialCulturalAttribute.owner[0].user.id) !== formAttribute.owner.value){
          // Deactivate owner
          users.push({
            id: initialCulturalAttribute.owner[0].pillarUserId,
            active: false
          })

          // Register new owner
          if(formAttribute.owner.value !== -1){
            users.push({
              tgd_role: MEMBER_ROLE.owner,
              tgd_user: formAttribute.owner.value.toString()
            })
          }
        }     
      }

      // COACH
      // When culturalAttributes already have a coach
      if(
        formAttribute.coach && 
        formAttribute.coach.length > 0 && 
        initialCulturalAttribute && 
        initialCulturalAttribute.coach
      ){
        // Add new coaches
        for(let k = 0; k < formAttribute.coach.length; k++){
          let isValue: boolean = false;
          for(let j = 0; j < initialCulturalAttribute.coach.length; j++){
            if(formAttribute.coach[k].value === parseInt(initialCulturalAttribute.coach[j].user.id)){
              isValue = true
            }
          }

          if(isValue === false){
            users.push({
              tgd_role: MEMBER_ROLE.coach,
              tgd_user: formAttribute.coach[k].value.toString(),
            });
          }
        }

        // Remove coaches
        for(let j = 0; j < initialCulturalAttribute.coach.length; j++){
          let isValue: boolean = false;
          for(let k = 0; k < formAttribute.coach.length; k++){
            if(formAttribute.coach[k].value === parseInt(initialCulturalAttribute.coach[j].user.id)){
              isValue = true
            }
          }

          if(isValue === false){
            users.push({
              id: initialCulturalAttribute.coach[j].pillarUserId,
              active: false,
            });
          }
        }
      }

    }
    return {
      tgd_organization: organizationId,
      id: (formAttribute.id) !== undefined ? formAttribute.id : -100,
      name: formAttribute.name,
      description: formAttribute.description,
      valid_until: formAttribute.valid_until.toISOString(),
      tgd_tags: buildCulturalAttributesTags(
        initialData[index].tgd_pilar_tags, 
        formAttribute.tgd_pilar_tags
      ),
      tgd_pilar_role_users: users,
      is_archived: false,
      type: formAttribute.type
    }
  })

  return culturalAttributesBody;
}