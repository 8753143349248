import React from 'react'

interface TabPanelProps{
    children?: React.ReactNode
    index: any
    value: any
}

const TabPanel = (props: TabPanelProps) => {
    return(
        <div hidden={props.value !== props.index}>
            {props.children}
        </div>
    )
}

export default TabPanel;