export const truncNumber = (value: number | string, decimals: number = 1): string => {
  const num = typeof value === 'string' ? Number(value) : value;
  if (num === 0 || num === 1) {
    return num.toFixed(decimals);
  } else {
    const trunc =
      Math.floor((num + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
    if (trunc === 0 || trunc === 1) {
      return num.toFixed(decimals);
    } else {
      return trunc.toString();
    }
  }
};
