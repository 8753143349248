import {
  CULTURAL_ATTRIBUTES_DESCRIPTION,
  CULTURAL_ATTRIBUTES_TYPES,
} from 'lib/enums/organizations.enums';
import { FormattedMessage } from 'react-intl';
import TegduTagComponent from '../TegduTagComponent/TegduTagComponent';
import './styles.scss';

interface TegduSelectCulturalAttributesOptionsProps {
  label: string;
  culturalAttributeType: CULTURAL_ATTRIBUTES_TYPES;
}

const TegduSelectCulturalAttributesOptions = (props: TegduSelectCulturalAttributesOptionsProps) => {
  const { label, culturalAttributeType } = props;
  console.log('culturalAttributeType', culturalAttributeType);

  return (
    <div className='tegdu-select-cultural-attributes-options'>
      <span>{label}</span>

      <TegduTagComponent
        type='timeframe-options'
        element={<FormattedMessage id={CULTURAL_ATTRIBUTES_DESCRIPTION[culturalAttributeType]} />}
      />
    </div>
  );
};

export default TegduSelectCulturalAttributesOptions;
