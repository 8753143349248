import Spinner from '@components/Shared/Spinner/Spinner';
import { Button } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PeopleIcon from '@material-ui/icons/People';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

interface ObjectivesCloseActionBottomContainerProps {
  objectiveName: string
  teamName?: string
  closeObjectiveAction: () => void
  cancelAction: () => void
  isMarkAsClosedLoading: boolean;
}

const ObjectivesCloseActionBottomContainer = (props: ObjectivesCloseActionBottomContainerProps) => {
  const { objectiveName, teamName, cancelAction, closeObjectiveAction, isMarkAsClosedLoading } = props;

  return (
    <div className="objectives-close-action-bottom-container">
      <div className="objectives-close-action-title">
        <FormattedMessage
          id='mobile.objectives.close.title'
        />
      </div>

      <div className="objectives-close-action-description">
        <div className='objective-instructions'>
          <FormattedMessage
            id='mobile.objectives.close.description'
          />
        </div>

        <div className="objective-icon-text">
          <ListAltOutlinedIcon />
          <span>{objectiveName}</span>
        </div>

        {teamName &&
          <div className="objective-icon-text">
            <PeopleIcon />
            <span>{teamName}</span>
          </div>
        }


        <div className='buttons'>
          <Button onClick={closeObjectiveAction}>
            {isMarkAsClosedLoading !== undefined && isMarkAsClosedLoading === true
              ? <Spinner color='white' size={15} thickness={5} />
              : <FormattedMessage id='mobile.objectives.close.button' />
            }
          </Button>

          <div className='cncl-action' onClick={cancelAction}>
            <span><FormattedMessage id='objectiveform.cancel' /></span>
          </div>
        </div>
      </div>


    </div>
  )
}

export default ObjectivesCloseActionBottomContainer;