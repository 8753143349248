import TegduSkeletonLoading from "@components/Shared/TegduSkeletonLoading/TegduSkeletonLoading";
import TegduSkeletonLoadingForm from "@components/Shared/TegduSkeletonLoadingForm/TegduSkeletonLoadingForm";


const ObjectivesMainContainerLoading = () => {
  return(
    <>
      <div style={{height: "64px"}}>
        <div style={{padding: "14px 20px", display: "flex", justifyContent:"space-between"}}>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{marginRight: "10px"}}>
              <TegduSkeletonLoading 
                type="form"
                width="40px"
              />
            </div>
            
            <TegduSkeletonLoading 
              type="form"
              width="200px"
            />
          </div>
          <TegduSkeletonLoading 
            type="form"
            width="40px"
          />
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "column", width: "370px", margin: "0px auto"}}>
      
      <div style={{paddingTop: "24px"}}>
        <TegduSkeletonLoading 
          type="row"
          width="250px"
        />

        <TegduSkeletonLoadingForm 
          type="textInput"
          marginTop="24px"
        />

        <TegduSkeletonLoadingForm 
          type="textArea"
          marginTop="24px"
        />

        <TegduSkeletonLoading 
          type="row"
          width="250px"
            marginTop="24px"
          />

          <TegduSkeletonLoading 
            type="row"
            width="150px"
            marginTop="4px"
          />

          <TegduSkeletonLoading 
            type="row"
            width="100%"
            marginTop="24px"
          />

          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{width: "370px", marginRight: "16px"}}>
              <TegduSkeletonLoadingForm 
                type="textInput"
                marginTop="24px"
              />
            </div>

            <TegduSkeletonLoadingForm 
              type="textInput"
              marginTop="24px"
            />
          </div>

          <TegduSkeletonLoading 
            type="row"
            width="100%"
            marginTop="24px"
          />

          <TegduSkeletonLoadingForm 
            type="textInput"
            marginTop="24px"
          />

          <TegduSkeletonLoading 
            type="row"
            width="100%"
            marginTop="24px"
          />

          <TegduSkeletonLoadingForm 
            type="textArea"
            marginTop="24px"
          />

          <TegduSkeletonLoadingForm 
            type="textArea"
            marginTop="24px"
          />

          <TegduSkeletonLoadingForm 
            type="textArea"
            marginTop="24px"
          />
        </div>
      </div>
    </>
    
  )
}

export default ObjectivesMainContainerLoading;