import ProgressBar from '@components/Shared/ProgressBar/ProgressBar';
import SimpleTag from '@components/Shared/SimpleTag/SimpleTag';
import { Button, Grid } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import { GqlTgdTimeframe, TgdTagObjective } from '@models/objetives.model';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import ActionsBarButtons from '../ActionsBarButtons/ActionsBarButtons';
import { ObjectivesMenuDotOptions } from '../ObjectivesMenuDotOptions/ObjectivesMenuDotOptions';
import { TgdObjPillars } from '@models/pillars.model';
import { checkOutOfDate, OBJECTIVE_STATUS } from '@utils/objectives';
import { useMemo } from 'react';
import moment from 'moment';
import { truncNumber } from '@utils/numbers';
import ObjectiveStatusComponent from '../ObjectiveStatusComponent/ObjectiveStatusComponent';

interface ObjectiveDetailsMainComponentProps {
  objectiveId: string;
  name: string;
  dueDate: string;
  progress: number;
  timeframe: GqlTgdTimeframe | null;
  tags: TgdTagObjective[];
  description: string;
  culturalAttributes: TgdObjPillars[];
  statusID?: any;
  isCoach: boolean;
  isOwner: boolean;
  onActionProgress?: () => void;
  getActionSelected: (keyName: string, objectiveId: number, ownerName: string) => void;
}

const ObjectiveDetailsMainComponent = ({
  description,
  dueDate,
  name,
  culturalAttributes,
  timeframe,
  progress,
  tags,
  onActionProgress,
  objectiveId,
  isCoach,
  isOwner,
  statusID,
  getActionSelected,
}: ObjectiveDetailsMainComponentProps) => {
  const isPastDate = useMemo(() => (dueDate ? checkOutOfDate(dueDate) : false), [dueDate]);
  
  return (
    <Grid className='component-objectivedetails' container spacing={3}>
      {/* HEADER */}
      <Grid
        className={`objectivedetails-header ${
          statusID !== OBJECTIVE_STATUS.active.toString() ? 'nowrap' : ''
        }`}
        container
        item
        xs={12}
        alignItems='center'
        justify='space-between'
      >
        <div className='container-image-title'>
          <div>
            <img src='/images/maletin.svg' alt='e' />
          </div>
          <h2>{name}</h2>
        </div>
        {isOwner || isCoach ? (
          <div className='container-actionbuttons'>
            {statusID === OBJECTIVE_STATUS.active.toString() ? (
              <Button onClick={onActionProgress} startIcon={<UpdateIcon />}>
                <FormattedMessage id='objectives.button.addProgress' />
              </Button>
            ) : (
              <></>
            )}
            <ActionsBarButtons
              name={''}
              objetiveId={parseInt(objectiveId || '')}
              menuActions={ObjectivesMenuDotOptions(parseInt(statusID || ''), isCoach)}
              getActionSelected={getActionSelected}
            />
          </div>
        ) : (
          <></>
        )}
      </Grid>
      {/* DATES */}
      <Grid container item xs={12} spacing={3} alignItems="center">
        {statusID !== undefined && 
          <Grid item>       
            <ObjectiveStatusComponent status={parseInt(statusID) as OBJECTIVE_STATUS} />        
          </Grid>
        }
        <Grid item>          
          <label>
            <FormattedMessage id={'objectives.main.dueDate.label'} />
            <span className={isPastDate ? 'text-outdated' : ''}>
              {moment(dueDate).format('MMMM DD')}
            </span>
          </label>
        </Grid>
        {timeframe ? (
          <Grid item>
            <label>
              <FormattedMessage id={'objectives.main.timeframe.label'} />
              <span>{timeframe.name}</span>
              <span className='timeframe-dates'>{`${moment(timeframe.date_initial).format(
                'DD MMM'
              )} - ${moment(timeframe.date_until).format('DD MMM')}`}</span>
            </label>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        <ProgressBar value={progress} thickness={8} />
        <p className='progress-text'>
          <FormattedMessage id={'objectives.main.progress'} />
          <span>{truncNumber(progress)} / 1.0</span>
        </p>
      </Grid>
      {tags.length > 0 && (
        <Grid className='container-tags' container item xs>
          {tags.map((e) => (
            <SimpleTag key={e.id} color={'soft'} tag={e.tgd_tag.name || ''} width='72px' />
          ))}
        </Grid>
      )}
      <Grid className='container-descriptionpillar' container item spacing={3}>
        <Grid item xs={12} md={culturalAttributes.length ? 6 : 12}>
          <label>
            <FormattedMessage id={'objectives.main.description'} />
            {description ? (
              <span>{description}</span>
            ) : (
              <span className='objectivedetails-label-nodata'>
                <FormattedMessage id={'objectives.main.description.text'} />
              </span>
            )}
          </label>
        </Grid>
        {culturalAttributes.length ? (
          <Grid item xs={12} md={6}>
            <label>
              <FormattedMessage id={'objectives.main.culturalAttributes'} />
              <span>{culturalAttributes.map((e) => e.tgd_pilar.name).join(', ')}</span>
            </label>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default ObjectiveDetailsMainComponent;
