import {
  FrequencyGoalsCatalogueActionTypes,
  FrequencyGoalsCatalogueState,
} from './frequencyGoals.model';
import {
  SET_FREQUENCYGOALS_CATALOGUE_BEGIN,
  SET_FREQUENCYGOALS_CATALOGUE_SUCCESS,
  SET_FREQUENCYGOALS_CATALOGUE_ERROR,
} from './frequencyGoals.action';

const initialState: FrequencyGoalsCatalogueState = {
  data: [],
  loading: false,
  error: null,
};

export default function frequencyGoalsReducer(
  state: FrequencyGoalsCatalogueState = initialState,
  action: FrequencyGoalsCatalogueActionTypes
): FrequencyGoalsCatalogueState {
  switch (action.type) {
    case SET_FREQUENCYGOALS_CATALOGUE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SET_FREQUENCYGOALS_CATALOGUE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SET_FREQUENCYGOALS_CATALOGUE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
