import Spinner from "@components/Shared/Spinner/Spinner";
import TegduTextInputComponent from "@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent";
import withTextInputController from "@hocs/withTextInputController";
import { Button } from "@material-ui/core";
import React from "react";
import { FormProvider, useForm } from "react-hook-form"
import {FormattedMessage} from "react-intl";
import './styles.scss';

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);

interface ForgotPasswordFormProps{
  isLoading: boolean
  handleSubmit: (data: any) => void
}

const ForgotPasswordForm = (props: ForgotPasswordFormProps ) => {
  const methods = useForm();
  const { handleSubmit, isLoading } = props //destructuracion para manejar un objeto

  return(
  
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className="forgot-password-form">
      <span className="label-email">
        <FormattedMessage id={"label.email"}/>
      </span>
        <TegduTextInputForm
          name="email"
          control={methods.control}
          isEditMode={true}
        />
        <div className="button-continue">
          <Button 
            type="submit"
            disabled={isLoading}
            style={{display: "flex", justifyContent: "center", width: "234px"}}
          >
            {isLoading ? 
              <Spinner color='white' size={15} thickness={5} />
            :
              <FormattedMessage id={"label.email.continue"}/>
            }
            
          </Button>
        </div>
      </form>
    </FormProvider>
  
  )
}

export default ForgotPasswordForm;
