import { getCompanySizeCatalogue } from '@services/catalogs.service';
import { RootState } from '@store/rootReducer';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { OptionsModel } from '@models/helpful.model';
import {
  SetCompanySizeCatalogueBeginAction,
  SetCompanySizeCatalogueSuccessAction,
  SetCompanySizeCatalogueErrorAction,
} from './companySize.model';

export const SET_COMPANYSIZE_CATALOGUE_BEGIN = 'SET_COMPANYSIZE_CATALOGUE_BEGIN';
export const SET_COMPANYSIZE_CATALOGUE_SUCCESS = 'SET_COMPANYSIZE_CATALOGUE_SUCCESS';
export const SET_COMPANYSIZE_CATALOGUE_ERROR = 'SET_COMPANYSIZE_CATALOGUE_ERROR';

export const setCompanySizeBegin = (): SetCompanySizeCatalogueBeginAction => ({
  type: SET_COMPANYSIZE_CATALOGUE_BEGIN,
});

export const setCompanySizeSuccess = (
  data: OptionsModel[]
): SetCompanySizeCatalogueSuccessAction => ({
  type: SET_COMPANYSIZE_CATALOGUE_SUCCESS,
  payload: data,
});

export const setCompanySizeError = (error: any): SetCompanySizeCatalogueErrorAction => ({
  type: SET_COMPANYSIZE_CATALOGUE_ERROR,
  payload: error,
});

export const getCompanySizeCatalogueData = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch(setCompanySizeBegin());
  return await getCompanySizeCatalogue()
    .then((data) => {
      const options: OptionsModel[] = data.map((option) => ({
        value: option.id.toString(),
        label: option.description,
      }));
      dispatch(setCompanySizeSuccess(options));
    })
    .catch((error) => {
      dispatch(setCompanySizeError(error));
    });
};
