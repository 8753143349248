import { gql } from '@apollo/client';

export const GET_STATEMENTS_DASHBOARD = gql`
  query GetStatements($organizationId: ID!) {
    tgdStatements(where: { tgd_organization: { id: $organizationId }, is_archived: false }) {
      name
      description
    }
  }
`;

export const GET_PILLARS_DASHBOARD = gql`
  query GetPilars($organizationId: ID!) {
    tgdPilars(where: { tgd_organization: { id: $organizationId }, is_archived: false }) {
      name
      description
    }
  }
`;
