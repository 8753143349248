import { createMuiTheme } from '@material-ui/core/styles';
import BluePalette from './MuiBluePalette.json';
import DefaultPalette from './MuiDefaultPalette.json';
import PinkPalette from './MuiPinkPalette.json';
import PurplePalette from './MuiPurplePalette.json';
import RedPalette from './MuiRedPalette.json';
import YellowPalette from './MuiYellowPalette.json';
import fonts from './MuiTypography.json';

const getMuiTheme = (palette: string) => {
  const colors =
    palette === 'blue'
      ? BluePalette
      : palette === 'pink'
      ? PinkPalette
      : palette === 'purple'
      ? PurplePalette
      : palette === 'red'
      ? RedPalette
      : palette === 'yellow'
      ? YellowPalette
      : DefaultPalette;

  return createMuiTheme({
    palette: colors,
    typography: fonts,
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        root: {
          fullWidth: false,
          padding: 24,
        },
        text: {
          // Some CSS
          background: colors.primary.main,
          borderRadius: 8,
          border: 0,
          color: 'white',
          height: 36,
          padding: '7px 12px',
          textTransform: 'none',
          '&:hover': {
            color: 'white',
            background: colors.secondary.main,
          },
          '&:disabled': {
            color: 'white',
            background: colors.disabled.main,
          },
          '&.material-icons': {
            padding: '0px 24px',
          },
          '&.secondary': {
            color: '#999999',
            backgroundColor: 'transparent',
            '&:hover': {
              color: colors.primary.main,
              background: colors.primary.chromatic,
            },
            '&:disabled': {
              opacity: 0.4,
            },
          },
          '&.secondary-gray': {
            color: '#666666',
            backgroundColor: '#F1F1F1',
            '&:hover': {
              color: colors.primary.main,
              background: colors.primary.chromatic,
            },
            '&:disabled': {
              opacity: 0.4,
            },
          },
          '&.outline': {
            color: colors.secondary.main,
            backgroundColor: 'transparent',
            border: `1px solid ${colors.secondary.main}`,
            '&:hover': {
              background: colors.primary.chromaticLight,
            },
            '&:disabled': {
              borderColor: colors.disabled.main,
              color: colors.disabled.main,
            },
          },
          '&.unarchive': {
            color: colors.primary.main,
            backgroundColor: 'transparent',
            '&:hover': {
              background: colors.primary.chromatic,
            },
          },
          '&.flat': {
            color: '#666666',
            backgroundColor: 'transparent',
            '&:hover': {
              color: colors.primary.main,
              background: 'transparent',
            },
            '&:disabled': {
              opacity: 0.4,
            },
          },
        },
        label: {
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        },
      },
      MuiOutlinedInput: {
        root: {
          position: 'relative',
          '&$focused $notchedOutline': {
            borderColor: colors.primary.main,
            borderWidth: 1,
          },
          '&$error': {
            color: colors.error.main,
          },
        },
        input: {
          padding: '7px 8px 6px',
          minHeight: '32px',
        },
        adornedStart: {
          paddingLeft: '8px',
        },
      },
      MuiFormHelperText: {
        contained: {
          '&$error': {
            color: colors.error.main,
            marginLeft: '0px',
          },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '0px !important',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#444444',
          '&$focused': {
            color: '#444444',
          },
          fontSize: '16px',
          fontWeight: 600,
          marginBottom: '4px',
          lineHeight: '24px',
        },
      },
      MuiInputLabel: {
        formControl: {
          marginBottom: '4px',
          fontSize: '16px',
          position: 'relative',
          transform: 'none',
          lineHeight: '24px',
          fontWeight: 600,
        },
        shrink: {
          transform: 'none',
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },
      MuiTextField: {
        root: {
          width: '100%',
          borderRadius: '4px',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '14px',
          lineHeight: 1,
        },
      },
      MuiCard: {
        root: {
          borderRadius: '12px',
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
        colorPrimary: {
          color: '#444444',
          backgroundColor: '#FFFFFF',
        },
      },
      MuiTabs: {
        scroller: {
          borderBottom: '1px solid #ECECEC',
        },
      },
      MuiTab: {
        root: {
          fontSize: '14px',
          textTransform: 'none',
        },
        textColorInherit: {
          minWidth: 'auto',
          '&.Mui-selected': {
            color: colors.secondary.main,
          },
        },
        wrapper: {
          '&:hover': {
            color: colors.secondary.main,
          },
        },
      },
      MuiSelect: {
        root: {
          paddingTop: '9px',
          paddingBottom: '9px',
          paddingLeft: '8px',
          minHeight: '32px',
        },
      }
    },
  });
};
export default getMuiTheme;
