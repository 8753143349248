import { Switch as MuiSwitch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

interface SwitchProps {
  checked: boolean;
  name: string;
  onChange: (check: boolean) => void;
  disabled?: boolean;
}

const TgdSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex',
    overflow: 'initial',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(19px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: '0px 3px 6px #0000003D',
  },
  track: {
    border: `1px solid #DDE4EA`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: '#DDE4EA',
  },
  checked: {},
}))(MuiSwitch);

const Switch = ({ checked, onChange, name, disabled }: SwitchProps) => {
  const [state, setState] = useState<boolean>(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
    onChange(event.target.checked);
  };

  useEffect(() => {
    setState(checked);
  }, [checked]);

  return <TgdSwitch checked={state} onChange={handleChange} name={name} disabled={disabled} />;
};

export default Switch;
