import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

interface ObjectivesFiltersProps {
  onSelectOption: (option: number) => void;
  optionSelected?: number;
}

const ObjectivesFilters = ({
  onSelectOption,
  optionSelected,
}: ObjectivesFiltersProps) => {
  return (
    <div className="container-buttons-filters">
      <Button
        onClick={() => onSelectOption(0)}
        className={
          optionSelected === 0 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.all" />
      </Button>
      <Button
        onClick={() => onSelectOption(1)}
        className={
          optionSelected === 1 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.owner" />
      </Button>
      <Button
        onClick={() => onSelectOption(2)}
        className={
          optionSelected === 2 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.collaborator" />
      </Button>
      <Button
        onClick={() => onSelectOption(3)}
        className={
          optionSelected === 3 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="objectives.table.filter.mobile.btn.coach" />
      </Button>
    </div>
  );
};

export { ObjectivesFilters as ObjectivesFiltersMobile };
