import ForgotPasswordForm from "pwa/components/login/ForgotPasswordForm/ForgotPasswordForm";
import {FormattedMessage} from "react-intl";
import React, { useState } from "react";
import './styles.scss';
import {sendEmailForgotPassword} from "@services/login.service";
import useToastNotification from "hooks/useToastNotification";

interface ForgotPasswordRequestContainerProps {
  handleContinue: (email: string) => void
}

const ForgotPasswordRequestContainer = (props: ForgotPasswordRequestContainerProps) => {
  const {handleContinue} = props
  const [loading, setLoading] = useState<boolean>(false);
  const toastNotification = useToastNotification();

  const handleSubmit = async (data: any) => {
    setLoading(true)
    try {
      await sendEmailForgotPassword(data.email, true)
      handleContinue(data.email)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      toastNotification("resetPassword.toast.error.request", true, true)
      console.log("error", e)
    }
  }
  return (
    <div className="forgot-password-request-container">
      <div className="forgot-password-box">
        <span id='forgot-password'>
          <FormattedMessage id={"forgot.password.question"}/>
        </span>
      </div>
      <div className="forgot-password-des">
        <span id='description'>
          <FormattedMessage id={"forgot.password.description"}/>
        </span>
      </div>

      <ForgotPasswordForm 
        handleSubmit={handleSubmit}
        isLoading={loading}
      />

    </div>

  )
}

export default ForgotPasswordRequestContainer;
