import {
  ApiBodyOrganizationInfo,
  ApiPutOrganizationInfo,
  AutomaticTimeframesResponse,
  FormOrganizationValues,
  OrganizationData,
  SetAutomaticTimeframesRequest,
} from '@models/organizations.model';
import { OrganizationInfoSample } from '@samples/organizations.sample';
import TgdClient from '@helpers/HttpClients/TgdClient';
import { 
  ApiBodySaveStatements, 
  ApiPostAddStatements, 
  Statement 
} from '@models/statement.model';
import {
  ApiEditPillars,
  ApiSavePillars,
} from '@models/pillars.model';
import { getGraphqlData } from './graphql.service';
import { TimeFramesFormObjectModel } from 'lib/TegduForms/TimeFramesFormUtils';

export const getOrganizationInfo = async (organizationId: number): Promise<OrganizationData> =>
  await new Promise<OrganizationData>((resolve) =>
    setTimeout(() => resolve(OrganizationInfoSample), 1500)
  );

export const updateOrganization = async (
  organizationId: number,
  organization: FormOrganizationValues,
  color: string
): Promise<ApiPutOrganizationInfo> => {
  const organizationBody: ApiBodyOrganizationInfo = {
    id: organizationId,
    public_name: organization.publicName,
    legal_name: organization.legalName,
    company_website: organization.companyWebsite,
    company_size_id: organization.companySize ? organization.companySize : null,
    frequency_goals_id: organization.frequencyGoals ? organization.frequencyGoals : null,
    timezone_id: organization.timeZone ? organization.timeZone : null,
    color: color,
  };
  return await TgdClient.put('/custom-organizations', organizationBody);
};

export const putEditStatements = async (
  organizationId: number,
  statements: Statement[]
): Promise<ApiPostAddStatements[]> => {
  const statementsBody: ApiBodySaveStatements[] = statements.map((statement) => ({
    id: statement.id,
    tgd_organization: organizationId,
    name: statement.statement,
    description: statement.description,
    valid_until: statement.date,
    is_archived: false,
  }));

  return await TgdClient.put<ApiPostAddStatements[]>(
    '/custom-organizations/statements',
    statementsBody
  );
};

export const postAddStatements = async (
  organizationId: number,
  statements: Statement[]
): Promise<ApiPostAddStatements[]> => {
  const statementsBody: ApiBodySaveStatements[] = statements.map((statement) => ({
    tgd_organization: organizationId,
    name: statement.statement,
    description: statement.description,
    valid_until: statement.date,
    is_archived: false,
  }));

  return await TgdClient.post<ApiPostAddStatements[]>(
    '/custom-organizations/statements',
    statementsBody
  );
};

/* ADD PILLARS */
export const addPillars = async (attributes: ApiSavePillars[]): Promise<any> => {
  return TgdClient.post(`/custom-organizations/pilars`, attributes);
};

/* PUT(UPDATE) PILLARS */
export const putPillars = async (culturalAttributesBody: ApiEditPillars[]): Promise<any> => {
  return TgdClient.put(`/custom-organizations/pilars`, culturalAttributesBody);
};

/* Archive / Unarchive pillar */
export const updatePillarArchive = async (id: string, value: boolean): Promise<any> => {
  return TgdClient.put(`/tgd-pilars/${id}`, { is_archived: value });
};

/* GET ID ROLE PILLARS */
export const getIdRolePillars = async (
  query: string,
  organizationId: number,
  pillarsId: number[]
): Promise<any> => {
  const data = await getGraphqlData(query, { organizationId: organizationId, rolesId: pillarsId });
  return data;
};

export const setFirstTimeTimeframesData = (data: TimeFramesFormObjectModel []) => {
  return TgdClient.post(`/tgd-timeframes`, data )
}

export const updateTimeFramesDataService = (data: TimeFramesFormObjectModel []) => {
  return TgdClient.put(`/tgd-timeframes/`, data)
}

export const setAutomaticTimeFramesDataService = (data: SetAutomaticTimeframesRequest) => {
  return TgdClient.post(`/tgd-automatic-timeframe-configs`, data)
}

export const validateAutomaticTimeframesExist = (): Promise<AutomaticTimeframesResponse> => {
  return TgdClient.get(`/tgd-automatic-timeframe-configs/own`)
}

export const disableAutomaticTimeframesService = (id: number) => { 
  return TgdClient.delete(`/tgd-automatic-timeframe-configs/${id}`)
}

// COUNTS
export const getStatementsCount = (is_archived: boolean, tgd_organization: number): Promise<number> => 
  TgdClient.get(`/tgd-statements/count?is_archived=${is_archived}&tgd_organization=${tgd_organization}`)

export const getCulturalAttributesCount = (is_archived: boolean, tgd_organization: number): Promise<number> => 
TgdClient.get(`/tgd-pilars/count?is_archived=${is_archived}&tgd_organization=${tgd_organization}`)
