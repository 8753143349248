import { TegduSelectOptionsModel } from "@models/global.model"
import { TIMEFRAMES_AUTOMATIC_TYPES } from "lib/enums/timeframes.enum"

export const AutomaticTimeframesCatalogue: TegduSelectOptionsModel[] = [
  {
    value: TIMEFRAMES_AUTOMATIC_TYPES.quarterly,
    label: "Quarterly"
  },
  {
    value: TIMEFRAMES_AUTOMATIC_TYPES.monthly,
    label: "Monthly"
  },
  {
    value: TIMEFRAMES_AUTOMATIC_TYPES.biannually,
    label: "Biannually"
  }

]

export interface AutomaticTimeframesFormModel {
  timeframe: TegduSelectOptionsModel
  start_date: Date
}