import { TegduSelectOptionsModel } from "@models/global.model"

export interface ReviewFiltersModel {
  minor_score: TegduSelectOptionsModel
  major_score: TegduSelectOptionsModel
  start_date: string | null | undefined
  end_date: string | null | undefined
}
export const reviewsFilterFormInitialValues: ReviewFiltersModel = {
  minor_score: { value: 0, label: '' },
  major_score: { value: 0, label: '' },
  start_date: null,
  end_date: null,
}
export interface ReviewsFiltersSelected {
  minorScore: number | string | undefined;
  majorScore: number | string | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}