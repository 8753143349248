import { ROUTE } from '@utils/routes';

export const bottomBarMenuOptions = [
  {
    icon: 'dashboard',
    title: 'sidebar.dashboard',
    href: ROUTE.dashboard.path,
  },
  {
    icon: 'list_alt',
    title: 'sidebar.objectives',
    href: ROUTE.objetives.path,
  },
  {
    icon: 'add_circle',
    title: 'sidebar.dashboard',
    href: ROUTE.dashboard.path,
  },
  {
    icon: 'star_icon',
    title: 'sidebar.reviews',
    href: ROUTE.reviews.path,
  },
  {
    icon: 'notifications',
    title: 'sidebar.dashboard',
    href: ROUTE.dashboard.path,
  },
];
