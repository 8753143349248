import './styles.scss'
import FormikDropdown from '@components/FormikComponents/FormikDropdown/FormikDropdown';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import { Button, Grid, Icon } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { PersonalInfoSchema } from '@formsValidations/formsValidations';
import { MembersModel } from '@models/members.model';
import { transformTeams } from 'lib/parseFunctions/organizationFunctions';
import Spinner from '@components/Shared/Spinner/Spinner';
import GenericSuccessModal from '@components/Shared/GenericSuccessModal/GenericSuccessModal';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import withTextFastField from '@hocs/withTextFastField';
import withDropFastField from '@hocs/withDropFastField';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikDropdownFastField = withDropFastField(FormikDropdown);

interface PersonalInfoFormProps {
  data: MembersModel
  errorModal: boolean
  loadingSubmit: boolean
  succesModal: boolean
  teamsCatalogue: any
  closeErrorModal: () => void
  closeSuccesModal: () => void
  onSubmitForm: (info: any) => void
}

const PersonalInfoForm = (props: PersonalInfoFormProps) => {
  const roleName = useSelector((state: RootState) => state.User.Info.user.role.name);
  const { onSubmitForm, closeErrorModal, closeSuccesModal } = props
  const [viewMode, setViewMode] = useState(true);

  const onSubmit = useCallback((info: any) => {
    onSubmitForm(info)
  }, [onSubmitForm])

  const initialValues = useMemo(() => {
    return {
      first_name: props.data.first_name,
      last_name: props.data.last_name,
      job_title: props.data.job_title,
      teams: transformTeams(props.data.tgd_team_role_users),
    }
  }, [props.data])

  return (
    <div className='personal-info-form'>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={PersonalInfoSchema}
      >
        {(formik) => {
          let areEqual = false;
          let validForm = formik.isValid;

          if (JSON.stringify(formik.values) === JSON.stringify(initialValues)) {
            areEqual = true;
          } else {
            areEqual = false;
          }

          const resetForm = () => {
            formik.resetForm({ values: initialValues });
            setViewMode(!viewMode);
            // eslint-disable-next-line
            formik.setTouched({ ...formik.touched, ['job_title']: false });
          }


          const updateViewMode = () => {
            setViewMode(!viewMode);
            // eslint-disable-next-line
            formik.setTouched({ ...formik.touched, ['job_title']: false });
          };

          const onGotIt = () => {
            setViewMode(true);
            // eslint-disable-next-line
            formik.setTouched({ ...formik.touched, ['job_title']: false });
            closeSuccesModal();
          }

          const onErrorGotIt = () => {
            closeErrorModal();
          }

          return (
            <Form>
              <div className='description-row'>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className='text-description'>
                      <FormattedMessage id='account.screen.tab.personalInfo.desc' />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className='edit-buttons'>
                      {viewMode === true ?
                        <div className='buttons-container'>
                          <Button className='secondary' onClick={updateViewMode}>
                            <Icon>edit</Icon>
                            <FormattedMessage id='general.edit' />
                          </Button>
                        </div>
                        :
                        <div className='buttons-container'>
                          <div className={'edit-btn'}>
                            <Button
                              className={'secondary'}
                              disabled={!formik.isValid}
                              onClick={resetForm}
                            >
                              <FormattedMessage id={'statement.cancel'} />
                            </Button>
                          </div>
                          <div className={'save-btn'}>
                            <Button
                              type='submit'
                              disabled={areEqual === validForm && !props.loadingSubmit}
                            >
                              {props.loadingSubmit === true && (
                                <Spinner color='white' size={15} thickness={5} />
                              )}
                              <FormattedMessage id={'statement.save'} />
                            </Button>
                          </div>
                        </div>
                      }
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='role'>
                <FormattedMessage id={'account.personalInfo.role'} />
                <span id='role-light'>{roleName}</span>
              </div>
              <Grid container className='grid-container-form' spacing={4}>
                <Grid item xs={6} sm={4} md={4}>
                  <FormikTextInputFastField
                    name='first_name'
                    viewMode={viewMode}
                    labelId='general.form.label.firstName'
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <FormikTextInputFastField
                    name='last_name'
                    viewMode={viewMode}
                    labelId='general.form.label.lastName'
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <FormikTextInputFastField
                    name='job_title'
                    viewMode={viewMode}
                    labelId='general.form.label.jobTitle'
                  />
                </Grid>
              </Grid>

              <Grid container className='teams-row'>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    <FormattedMessage id={'members.component.teams.title'} />
                  </p>
                  <FormikDropdownFastField
                    name='teams'
                    options={props.teamsCatalogue}
                    cleanFilter={false}
                    isClearable={false}
                    isMulti={true}
                    isSearchable={false}
                    viewMode={true}
                  />
                </Grid>
              </Grid>

              <GenericSuccessModal
                isOpen={props.succesModal}
                handleClose={onGotIt}
                messageId={'general.modal.success.changes'}
              />
              <GenericErrorModal
                isOpen={props.errorModal}
                handleClose={onErrorGotIt}
                intlMessage={{ id: 'general.modal.error.label', values: { action: 'updating', subject: 'personal info' } }}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PersonalInfoForm;