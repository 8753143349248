import { useCallback, useState } from 'react';
import { ObjectivesLogs } from '@models/objetives.model';
import ObjectiveLog from '../ObjectiveLog/ObjectiveLog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';

import './styles.scss';

interface ObjectiveLogsGroupProps {
  data: ObjectivesLogs[];
}

const rowsPerView = 4;

const ObjectiveLogsGroup = ({ data }: ObjectiveLogsGroupProps) => {
  const [rows, setRows] = useState<number>(rowsPerView);

  const expandMore = useCallback(() => setRows((prev) => prev + rowsPerView), []);

  return (
    <div className='component-objectiveslogsgroup'>
      <div className='container-logs'>
        {data.slice(0, rows).map((e, index: any) => {
          return (
            <ObjectiveLog
              key={index}
              logType={e.description.name}
              date={e.created_at}
              nameUser={e.author.first_name}
              statusTarget={e.status.id}
              assignee={e.assignee ? e.assignee.first_name : ''}
            />
          );
        })}
      </div>
      {rows < data.length ? (
        <div className='container-seemore'>
          <div onClick={expandMore} className='seemore'>
            <span className='caption'>
              <FormattedMessage id='objectives.logs.seemore' />
            </span>
            <IconButton aria-label='seemore'>
              <ExpandMoreIcon style={{ fontSize: 33 }} />
            </IconButton>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ObjectiveLogsGroup;
