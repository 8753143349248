import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddTeamContainer from '@containers/Teams/AddTeamContainer/AddTeamContainer';

const AddTeamScreen = () => {
  return (
    <div className='narrow-container'>
      <h1 className='screen-title'>
        <FormattedMessage id='teams.add.screen.title' />
      </h1>
      <AddTeamContainer
        open={false}
        onClose={() => console.log('')} // CLosing
        refetch={() => console.log('')} // refetch
      />
    </div>
  );
};

export default AddTeamScreen;
