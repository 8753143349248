import { useCallback, useState } from "react";
import ForgotPaswwordCodeContainer from "../ForgotPasswordCodeContainer/ForgotPasswordCodeContainer";
import ForgotPasswordCreatePasswordContainer from "../ForgotPasswordCreatePasswordContainer/ForgotPasswordCreatePasswordContainer";
import ForgotPasswordRequestContainer from "../ForgotPasswordRequestContainer/ForgotPasswordRequestContainer";

interface ForgotPasswordContainerProps {
  handleCloseModal: () => void
}

const ForgotPasswordContainer = (props: ForgotPasswordContainerProps) => {
  const { handleCloseModal } = props;
  const [forgotPasswordStep, setForgotPasswordStep] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [codeToken, setCodeToken] = useState<string>("");

  const handleSuccessVerification = useCallback((token: string) => {
    setCodeToken(token)
    setForgotPasswordStep(2)
  }, [])

  const handleSuccessRequest = useCallback((email: string) => {
    setEmail(email)
    setForgotPasswordStep(1)
  }, [])

  switch (forgotPasswordStep) {
    case 0:
      return <ForgotPasswordRequestContainer 
        handleContinue={handleSuccessRequest}
      />
    case 1:
      return <ForgotPaswwordCodeContainer 
        handleContinue={handleSuccessVerification}
        email={email}
      />
    case 2:
      return <ForgotPasswordCreatePasswordContainer 
        token={codeToken} 
        handleCloseModal={handleCloseModal}
      />
    default:
      return <ForgotPasswordRequestContainer 
        handleContinue={handleSuccessRequest}
      />

  }
}

export default ForgotPasswordContainer;
