import { Grid } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import moment from "moment";

const StyledRating = withStyles({
  icon: {
    padding: "0px",
  },
  iconEmpty: {
    color: "#F0F0F0",
  },
  iconFilled: {
    color: "#FFD460",
  },
})(Rating);

interface ObjectivesRateTeammatesProps {
  nameTeamMate?: string;
  message?: string;
  reviewer?: string;
  onActionMore?: ()=>void;
  team?: number;
  dateRate?: string;
  rateValue?: number;
  dateComment?: string;
}

const ObjectivesRateTeammates = ({
  nameTeamMate,
  dateComment,
  onActionMore,
  dateRate,
  message,
  reviewer,
  rateValue,
  team,
}: ObjectivesRateTeammatesProps) => {
  const [showMessageMore, setShowMessageMore] = useState<boolean>(false);
  return (
    <Grid container item xs={12} md={4} className="container-rates-main">
      <Grid item xs={10} md={12} className="container-rate-by-teammate">
        <Grid item xs={10} md={8} className="name-grader">
          <span>
            <b>{nameTeamMate}</b>
            <span>&nbsp;{team && team}</span>
          </span>
          <span>
            <FormattedMessage id={"objectives.rate.by.message"} /> {reviewer}
          </span>
        </Grid>
        <Grid item md={4} className="rate-starts-date">
          <Grid item xs={12} md={12} >
            <StyledRating
              readOnly
              name={`score-`}
              icon={
                <StarRoundedIcon style={{ width: "30px", height: "30px" }} />
              }
              value={rateValue}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <span>{moment(dateRate).format("DD/MM/YY")}</span>
          </Grid>
        </Grid>
      </Grid>
      {message?.length && (
        <Grid item xs={10} md={12} className="container-comments-rate">
          <div
            className={
              !showMessageMore ? `show-more-message` : "show-more-message-more"
            }
          >
            <span>
              <b>
                <FormattedMessage id={"objectives.rate.comments"} />
              </b>
              {message}
            </span>
            <div onClick={() => setShowMessageMore(!showMessageMore)}>
              <ExpandMoreIcon className="view-more-comment" />
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default ObjectivesRateTeammates;
