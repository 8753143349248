import { InvitationData, VerifyInvitationInfo } from "@models/invitations.model"
import { SET_ADD_MEMBERS_LOADING, SET_INVITATIONS_INFO, SET_INVITATIONS_TAB_STATE, SET_LOADING_INVITATIONS, SET_ONBOARDING_INVITATION_INFO, SET_ONBOARDING_INVITATION_INFO_LOADING, SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING } from "./invitations.action"

export interface InvitationsState {
  isAddMembersLoading: boolean
  validatingLandingCode: {
    isLoading: boolean
    validatedData: VerifyInvitationInfo | null
  }
  submitOnboardingInfo: {
    isLoading: boolean
  },
  invitationsActiveTabState: InvitationsTabStates,
  invitations: {
    data: InvitationData[] | null
  }
  isInvitationsLoading: {
    isLoading: boolean;
  }
}

export enum InvitationsTabStates {
  requests = 0,
  pending = 1,
  accepted = 2,
}

export interface SetAddMembersLoadingAction {
  type: typeof SET_ADD_MEMBERS_LOADING
  payload: boolean
}

export interface SetOnboardingInvitationInfoLoading {
  type: typeof SET_ONBOARDING_INVITATION_INFO_LOADING
  payload: boolean
}

export interface SetOnboardingInvitationInfo {
  type: typeof SET_ONBOARDING_INVITATION_INFO
  payload: VerifyInvitationInfo | null
}

export interface SetSubmitInvitationLOading {
  type: typeof SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING
  payload: boolean
}

export interface SetInvitationsActiveTabStateAction {
  type: typeof SET_INVITATIONS_TAB_STATE;
  payload: InvitationsTabStates;
}

export interface SetInvitationsLoadingInfo {
  type: typeof SET_LOADING_INVITATIONS
  payload: boolean
}

export interface SetInvitationsInfo {
  type: typeof SET_INVITATIONS_INFO
  payload: InvitationData[] | null
}


export type InvitationsActions =
  SetAddMembersLoadingAction |
  SetOnboardingInvitationInfoLoading |
  SetOnboardingInvitationInfo |
  SetSubmitInvitationLOading | SetInvitationsActiveTabStateAction |
  SetInvitationsLoadingInfo | SetInvitationsInfo