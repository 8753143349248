import { Grid } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'

interface FiltersOverviewProps{
    length: number
    onClear: () => void
    showFiltersOverview: boolean 
}

const FiltersOverview = (props: FiltersOverviewProps) => {
    return(
        <div className={`filters-overview-general ${props.showFiltersOverview === false ? 'hide' : ''}`}>
            <Grid container className='filters-overview-container'>
                <Grid item md={10} className="result-preview">
                    <FormattedMessage id={'filtersoverview.results'} values={{results: props.length}}/>
                </Grid>
                <Grid item md={2} className='clear-button'>
                    <p onClick={props.onClear}>
                        <span>X</span> <FormattedMessage id={'clearresults.button'}/>
                    </p>
                </Grid>
            </Grid>
        </div>
    )
}

export default FiltersOverview;