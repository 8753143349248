import { FormattedMessage } from "react-intl";
import "./styles.scss";
interface CardShadowProps {
  children: React.ReactNode;
  title?: string;
  howmanyItems?: number;
}
const CardShadow = ({ children, title, howmanyItems }: CardShadowProps) => {
  return (
    <div className="container-card">
      {title && (
        <div className="title">
          <span>
            <FormattedMessage id={title} />
            {(howmanyItems) && ` (${howmanyItems})`}
          </span>
        </div>
      )}
      {children}
    </div>
  );
};

export default CardShadow;
