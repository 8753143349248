import { ResetPasswordStructure } from "@containers/Password/ResetPasswordWizard";
import { ApiPostLogin } from "@models/user.model";
import { recoverPassword, verifyForgotPasswordCodeService } from "@services/login.service";
import { Dispatch } from "redux";

export const SET_FORGOT_PASSWORD_VERIFY_LOADING = "SET_FORGOT_PASSWORD_VERIFY_LOADING";
export const SET_FORGOT_PASSWORD_NEW_PASSWORD_LOADING = "SET_FORGOT_PASSWORD_NEW_PASSWORD_LOADING";

/* Action creators */
const setForgotPasswordVerifyLoading = (isLoading: boolean) => {
  return {
    type: SET_FORGOT_PASSWORD_VERIFY_LOADING,
    payload: isLoading
  }
}

const setForgotPasswordNewPasswordLoading = (isLoading: boolean) => {
  return {
    type: SET_FORGOT_PASSWORD_NEW_PASSWORD_LOADING,
    payload: isLoading
  }
}

/* Async actions */
export const verifyForgotPasswordCode = (
  email: string,
  code: string,
  handleSuccess: (token: string) => void,
  handleError: () => void
) => async (dispatch: Dispatch) => {
  dispatch(setForgotPasswordVerifyLoading(true))
  try {
    const response = await verifyForgotPasswordCodeService(email, code)
    dispatch(setForgotPasswordVerifyLoading(false))
    handleSuccess(response.resetPasswordToken)
  } catch {
    dispatch(setForgotPasswordVerifyLoading(false))
    handleError()
  }
}

export const createNewPassword = (
  data: ResetPasswordStructure,
  handleSuccess: (data: ApiPostLogin) => void,
  handleError: () => void
) => async (dispatch: Dispatch) => {
  dispatch(setForgotPasswordNewPasswordLoading(true))
  try {
    const response = await recoverPassword(data)
    dispatch(setForgotPasswordNewPasswordLoading(false))
    handleSuccess(response)
  } catch {
    dispatch(setForgotPasswordNewPasswordLoading(false))
    handleError()
  }
}