import React, { useCallback, useEffect, useState } from 'react';
import LabelWitchCheckbox from '@components/Shared/LabelWithCheckbox/labelWithCheckbox'
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import InvitationsSetPasswordForm from '../InvitationsSetPasswordForm/InvitationsSetPasswordForm';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import Spinner from '@components/Shared/Spinner/Spinner';

interface InvitationsOnboardingSetPasswordProps {
  backToBasicInfo: () => void
  submitInfo: (password: string) => void
}

const InvitationsOnboardingSetPassword = (props: InvitationsOnboardingSetPasswordProps) => {
  const { backToBasicInfo, submitInfo } = props;
  const [validateCapital, setValidateCapital] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [validateLength, setValidateLength] = useState(false);
  const [validateSpecial, setValidateSpecial] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(false);
  const [values, setValues] = useState<undefined | string>(undefined);
  const isLoading = useSelector((state: RootState) => state.Invitations.submitOnboardingInfo.isLoading);

  const getValidationsValues = useCallback((valuesPass: any) => {
    setValues(valuesPass.password)
    if(valuesPass.password === valuesPass.confirmPassword){
      setIsSamePassword(true)
    }else {
      setIsSamePassword(false)
    }    
  }, [])

  useEffect(() => {
    const validateCapitalLetter = /[A-Z]/;
    const validateNumber = /\d/;
    
    const validateSpecialChars = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
    if (values !== undefined) {
      if (validateCapitalLetter.test(values)) {
        setValidateCapital(true)
      } else {
        setValidateCapital(false)
      }
      if (validateNumber.test(values)) {
        setValidateNumber(true)
      } else {
        setValidateNumber(false)
      }
      if (validateSpecialChars.test(values)) {
        setValidateSpecial(true)
      } else {
        setValidateSpecial(false)
      }
      if (values.length > 8) {
        setValidateLength(true)
      } else {
        setValidateLength(false)
      }

    }
  }, [values])

  const sumitSignUpButton = useCallback(() => {
    if(values !== undefined){
      submitInfo(values)
    }
  }, [submitInfo, values])

  return(
    <div className='onboarding-set-password'>
      <div className='password-title'>
        <FormattedMessage
          id={"resetPassword.createPassword.instrucctions"} 
        />
      </div>
      <div className='password-rules'>
        <div className='left'>
          <LabelWitchCheckbox
            checked={validateLength}
            label={'resetPassword.passwordRequired.8characters'}
            thin={true}
            checkboxClassName='thin'
          />
          <LabelWitchCheckbox
            checked={validateSpecial}
            label={'resetPassword.passwordRequired.1specialCharacter'}
            thin={true}
            checkboxClassName='thin'
          />
        </div>

        <div className='right'>
          <LabelWitchCheckbox
            checked={validateCapital}
            label={'resetPassword.passwordRequired.1capitalletter'}
            thin={true}
            checkboxClassName='thin'
          />
          
          <LabelWitchCheckbox
            checked={validateNumber}
            label={'resetPassword.passwordRequired.1number'}
            thin={true}
            checkboxClassName='thin'
          />
        </div>       
      </div>

      <div className='password-form'>
        <InvitationsSetPasswordForm 
          getValidations={getValidationsValues}
        />

        <div className='button-container'>
          <Button onClick={backToBasicInfo} className="secondary" disabled={isLoading}>
            Back
          </Button>
          <Button 
            disabled={
              !(validateCapital && validateNumber && validateLength && validateSpecial && isSamePassword) || isLoading
            } 
            onClick={isSamePassword === false ? () => null : sumitSignUpButton}
          >
            {
              isLoading ? 
                <Spinner color='white' size={15} thickness={5} />
              : 
                <>Sign up</>
            }
            
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InvitationsOnboardingSetPassword;