import { useEffect, useState } from 'react';
import { PALETTE_COLOR, PALETTE_DEFAULT_COLOR } from '@utils/theming';
import Radio from '@material-ui/core/Radio';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './styles.scss';

interface ColorPickerProps {
  defaultColor: string;
  disabled?: boolean;
  onChange: (color: string) => void;
}

const ColorPicker = ({ defaultColor, disabled, onChange }: ColorPickerProps) => {
  const [selectedColor, setSelectedColor] = useState<string>(defaultColor || PALETTE_DEFAULT_COLOR);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => setSelectedColor(defaultColor), [defaultColor]);

  return (
    <div className='component-colorpicker'>
      {Object.values(PALETTE_COLOR).map((color) => (
        <Radio
          className={`colorpicker-${color !== 'green' ? color : 'default'}`}
          key={`colorpicker-${color}`}
          color={'default'}
          checkedIcon={<CheckCircleIcon id="check-icon-colors"/>}
          disabled={disabled}
          checked={selectedColor === color}
          icon={<FiberManualRecordIcon  id="icon-colors-before" />}
          onChange={handleChange}
          value={color}
          inputProps={{ 'aria-label': color }}
        />
      ))}
    </div>
  );
};

export default ColorPicker;
