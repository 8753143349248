export enum RING_KEY_NOTIFICATIONS {
  memberAdded = 'new-member',
  asignedAsCoach = 'coaching-assignment',
  leadershipTransferred = 'leadership-transfer',
  okrOwnerShipTransferred = 'owner-transfer',
  objectiveDue = 'due',
  addProgressReminder = 'add-progress',
  culturalAttributeExpiring = 'expiring',
  okrRequestApproval = "request"
}

export enum RING_KEY_ENTITIES {
  teams = "teams",
  objectives = "objectives",
  cultural_attributes = "cultural_attributes",
  request_comment_approval = "request"
}

export const RING_NOTIFICATION_ICON = {
  [RING_KEY_NOTIFICATIONS.memberAdded]: "add",
  [RING_KEY_NOTIFICATIONS.asignedAsCoach]: "add",
  [RING_KEY_NOTIFICATIONS.leadershipTransferred]: "edit",
  [RING_KEY_NOTIFICATIONS.okrOwnerShipTransferred]: "edit",
  [RING_KEY_NOTIFICATIONS.objectiveDue]: "update",
  [RING_KEY_NOTIFICATIONS.addProgressReminder]: "update",
  [RING_KEY_NOTIFICATIONS.culturalAttributeExpiring]: "update",
  [RING_KEY_NOTIFICATIONS.okrRequestApproval]: "comment",
}

export enum NOTIFIACTIONS_HANDLERS_ID {
  isNewTeam = 'isNewTeam',
  isNewOKRCoach = 'isNewOKRCoach',
  isNewLeadership = 'isNewLeadership',
  isNewOKRTransferred = 'isNewOKRTransferred',
  isObjectiveDueDate = 'isObjectiveDueDate',
  isAddProgressReminder = 'isAddProgressReminder',
  isCulturalAttributeExp = 'isCulturalAttributeExp',
  isStatementExp = 'isStatementExp',
}

export enum NOTIFICATIONS_STATUS {
  read = 'read',
  unread = 'unread',
}

export enum EXPIRATION_VALUES {
  atExpirationDay = '1',
  beforeExpiration = '2',
}

export enum PERIODICITY_VALUES {
  days = '1',
  weeks = '2',
}
