import LettersCircle from '@components/Shared/LettersCircle/LettersCircle';
import './styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { ArrowDropDown } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { ROUTE } from '@utils/routes';
import DehazeIcon from '@material-ui/icons/Dehaze';
import PersonIcon from '@material-ui/icons/Person';
import { FormattedMessage } from 'react-intl';
import NotificationsComponent from './Notifications/NotificationsComponent/NotificationsComponent';
interface HeaderProps {
  onClickReponsiveIcon: () => void;
  isProfile?: boolean;
  nameSection?: string;
}

const Header = (props: HeaderProps) => {
  const { onClickReponsiveIcon } = props;
  const organizationName = useSelector(
    (state: RootState) => state.Organization.Info.data.publicName
  );
  const { first_name, last_name } = useSelector((state: RootState) => state.User.Info.user);
  const history = useHistory();
  return (
    <div className='header'>
      <div className='header__container'>
        <div className='company__info'>
          <div className='logo__container'>
            <img
              className='header__logo'
              src=''
              alt=''
              // onClick={'redirect'}
            />
          </div>
          <div className='company__name'>
            <h1>
              {!props.nameSection ? organizationName : <FormattedMessage id={props.nameSection} />}
            </h1>
          </div>
        </div>
        <div className='header__nav'>
          {/* <div className='header__search__container'>
            <SearchComponent />
          </div> */}
          <NotificationsComponent />
          <div className='vertical__divider'></div>

          <div id='heading' className='user-info__container'>
            <div className='user-info'>
              <LettersCircle name={`${first_name} ${last_name}`} size='sm' />
              <h4>{`${first_name} ${last_name}`}</h4>
              <ArrowDropDown fontSize='small' />
            </div>

            <div className='statusDrop__container'>
              <div className='statusDrop'>
                <p onClick={() => history.push(ROUTE.account.path)}>My account</p>
              </div>
            </div>
          </div>
        </div>
        <div className='responsive-menu-icon'>
          {props.isProfile ? (
            <PersonIcon fontSize='large' onClick={onClickReponsiveIcon} />
          ) : (
            <DehazeIcon onClick={onClickReponsiveIcon} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
