import ToastNotification from '@components/Shared/ToastNotification/ToastNotification';
import { useSnackbar } from "notistack";
import { useCallback } from "react";


export const useToastNotification = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showToastNotification = useCallback((labelId: string, isError: boolean, isMobile?: boolean) => {
        const key = enqueueSnackbar(
            <ToastNotification labelId={labelId} isError={isError} />,
            isMobile === true && isMobile !== undefined ?
                {
                    persist: true,
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom",
                    }
                }
                :
                {
                    persist: true,
                }
        );

        setTimeout(() => {
            closeSnackbar(key);
        }, 4000)

    }, [enqueueSnackbar, closeSnackbar])

    return showToastNotification;
}

export default useToastNotification; 