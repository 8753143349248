import { CreateSkillsAptitudesPost } from "@store/HRTeam/HRTeamTabs/hrteam.model";
import { CreateSkillsAptitudesFormModel } from "lib/TegduForms/SkillsAptitudesFormUtils";


export const createSkillsAptitudesRequest = (
    data: CreateSkillsAptitudesFormModel
): CreateSkillsAptitudesPost => {
    const skillsAptitudesPost: CreateSkillsAptitudesPost = {
        name: data.name,
        description: data.description,
        type: data.type   
    };
    return skillsAptitudesPost;
}