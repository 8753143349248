import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import './styles.scss';

interface TegduSearchBarProps {
  isDisabled: boolean
  placeholderId: string
  onSearch: (search: string) => void
}

const TegduSearchBar = (props: TegduSearchBarProps) => {
  const intl = useIntl();
  const { placeholderId, onSearch, isDisabled } = props;
  const [inputValue, setInputValue] = useState<string>("");

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onSearch(e.target.value);
  }, [onSearch])

  const cleanSearchBar = useCallback(() => {
    setInputValue("");
    onSearch("");
  }, [onSearch])

  return(
    <TextField
      className={"tegdu-search-bar"}
      name='search'
      variant='outlined'
      placeholder={intl.formatMessage({ id: placeholderId })}
      disabled={isDisabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            {inputValue === "" ? <SearchIcon /> : <CloseIcon onClick={cleanSearchBar}/> }
          </InputAdornment>
        ),
      }}
      value={inputValue}
      onChange={handleChange}
    />
    )
}

export default TegduSearchBar;