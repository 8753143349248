import TgdClient from '@helpers/HttpClients/TgdClient';
import {
  ApiBodyTeam,
  ApiGetLeaderTeam,
  ApiTeamUser,
  FormTeamValues,
  GetAllTeamsResponse,
} from '@models/teams.model';
import _ from 'lodash';

export const postAddTeam = async (team: FormTeamValues) => {
  const users = [];
  // Leader
  if (!_.isEmpty(team.leads)) {
    const leads = team.leads.map((lead) => ({
      tgd_role: 4,
      tgd_user: lead,
    }));
    users.push(...leads);
  }

  if (team.members.length) {
    const members = team.members.map((member) => ({
      tgd_role: 3,
      tgd_user: member,
    }));
    users.push(...members);
  }
  const bodyTeam: ApiBodyTeam = {
    name: team.name,
    tgd_team_role_users: users,
  };
  await TgdClient.post('/custom-teams', bodyTeam);
};

export const putUpdateTeam = async (
  organizationId: number,
  teamId: string,
  name: string,
  users: ApiTeamUser[]
): Promise<any> => {
  const bodyTeam: ApiBodyTeam = {
    id: teamId,
    name: name,
    tgd_team_role_users: users,
  };
  return await TgdClient.put(`/custom-teams`, bodyTeam);
};

export const getLeaderTeams = async (
  organizationId: number,
  userId?: string
): Promise<ApiGetLeaderTeam[]> => {
  const user = userId ? `&user=${userId}` : '';
  return await TgdClient.get<ApiGetLeaderTeam[]>(
    `/custom-teams/leader-teams?organization=${organizationId}${user}`
  );
};

export const getAllTeams = async (organizationId: number): Promise<GetAllTeamsResponse[]> => {
  return TgdClient.get(`/tgd-teams?_limit=-1&tgd_organization.id=${organizationId}`);
};
