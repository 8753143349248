import React from 'react';
import './styles.scss';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { FormattedMessage } from 'react-intl';

interface ToastNotificationInterface{
    isError: boolean
    labelId: string
}

const ToastNotification = (props: ToastNotificationInterface) => {
    const { isError } = props
    return(
        <Grid container spacing={1} wrap='nowrap' alignItems={'flex-start'} alignContent='flex-start' 
            className={`toast-notification-container ${isError ? 'error-toast' : 'success-toast'}`} 
        >
            <Grid item className='icon'>
                {props.isError ? 
                    <ErrorIcon />
                :                    
                    <CheckCircleIcon />
                }
                
            </Grid>
            <Grid item className='label'>
                <FormattedMessage id={props.labelId}/> 
            </Grid>
        </Grid>
    )
}

export default ToastNotification;