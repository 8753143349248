import { ReviewsModelI } from '@store/Reviews/reviews.model';

export const TOUR_REVIEWS_TABLE: ReviewsModelI[] = [
  {
    id: 0,
    score: 1,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 282,
    general_review: false,
    comment: 'Review is incorrect',
    tgd_objective: {
      id: 0,
      name: 'Review is incorrect',
    },
    reviewer: {
      id: 0,
      first_name: 'John',
      last_name: 'Doe',
    },
    reviewed: {
      id: 0,
      first_name: 'John',
      last_name: 'Doe',
    },
  },
  {
    id: 0,
    score: 2,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 150,
    general_review: false,
    comment: 'Needs improvement',
    tgd_objective: {
      id: 0,
      name: 'Needs improvement',
    },
    reviewer: {
      id: 0,
      first_name: 'Jane',
      last_name: 'Smith',
    },
    reviewed: {
      id: 0,
      first_name: 'Jane',
      last_name: 'Smith',
    },
  },
  {
    id: 0,
    score: 3,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 400,
    general_review: false,
    comment: 'Average review',
    tgd_objective: {
      id: 0,
      name: 'Average review',
    },
    reviewer: {
      id: 0,
      first_name: 'David',
      last_name: 'Johnson',
    },
    reviewed: {
      id: 0,
      first_name: 'David',
      last_name: 'Johnson',
    },
  },
  {
    id: 0,
    score: 4,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 500,
    general_review: false,
    comment: 'Good review',
    tgd_objective: {
      id: 0,
      name: 'Good review',
    },
    reviewer: {
      id: 0,
      first_name: 'Sarah',
      last_name: 'Williams',
    },
    reviewed: {
      id: 0,
      first_name: 'Sarah',
      last_name: 'Williams',
    },
  },
  {
    id: 0,
    score: 5,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 600,
    general_review: false,
    comment: 'Excellent review',
    tgd_objective: {
      id: 0,
      name: 'Excellent review',
    },
    reviewer: {
      id: 0,
      first_name: 'Michael',
      last_name: 'Brown',
    },
    reviewed: {
      id: 0,
      first_name: 'Michael',
      last_name: 'Brown',
    },
  },
  {
    id: 0,
    score: 1,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 120,
    general_review: false,
    comment: 'Incomplete review',
    tgd_objective: {
      id: 0,
      name: 'Incomplete review',
    },
    reviewer: {
      id: 0,
      first_name: 'Emily',
      last_name: 'Davis',
    },
    reviewed: {
      id: 0,
      first_name: 'Emily',
      last_name: 'Davis',
    },
  },
  {
    id: 0,
    score: 3,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 340,
    general_review: false,
    comment: 'Decent review',
    tgd_objective: {
      id: 0,
      name: 'Decent review',
    },
    reviewer: {
      id: 0,
      first_name: 'Robert',
      last_name: 'Wilson',
    },
    reviewed: {
      id: 0,
      first_name: 'Robert',
      last_name: 'Wilson',
    },
  },
  {
    id: 0,
    score: 5,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 800,
    general_review: false,
    comment: 'Highly recommended',
    tgd_objective: {
      id: 0,
      name: 'Highly recommended',
    },
    reviewer: {
      id: 0,
      first_name: 'Jessica',
      last_name: 'Anderson',
    },
    reviewed: {
      id: 0,
      first_name: 'Jessica',
      last_name: 'Anderson',
    },
  },
  {
    id: 0,
    score: 2,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 180,
    general_review: false,
    comment: 'Poor review',
    tgd_objective: {
      id: 0,
      name: 'Poor review',
    },
    reviewer: {
      id: 0,
      first_name: 'Christopher',
      last_name: 'Taylor',
    },
    reviewed: {
      id: 0,
      first_name: 'Christopher',
      last_name: 'Taylor',
    },
  },
  {
    id: 0,
    score: 4,
    created_at: '2023-05-08T16:07:36.000Z',
    tgd_objective_score: 560,
    general_review: false,
    comment: 'Great review',
    tgd_objective: {
      id: 0,
      name: 'Great review',
    },
    reviewer: {
      id: 0,
      first_name: 'Olivia',
      last_name: 'Martin',
    },
    reviewed: {
      id: 0,
      first_name: 'Olivia',
      last_name: 'Martin',
    },
  },
];
