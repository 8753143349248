import { combineReducers } from 'redux';
import companySizeReducer from './CompanySize/companySize.reducer';
import frequencyGoalsReducer from './FrequencyGoals/frequencyGoals.reducer';
import { rolesReducer } from './Roles/roles.reducer';
import timeZoneReducer from './TimeZone/timeZone.reducer';

export default combineReducers({
  TimeZone: timeZoneReducer,
  FrequencyGoals: frequencyGoalsReducer,
  CompanySize: companySizeReducer,
  Roles: rolesReducer
});
