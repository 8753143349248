import { SET_ROLES_CATALOG, SET_ROLES_LOADING } from "./roles.action";
import { RolesActions, RolesState } from "./roles.model";

export const initialState: RolesState = {
  data: [],
  isLoading: false
}

export const rolesReducer = (
  state: RolesState = initialState, 
  actions: RolesActions): RolesState => {
  switch(actions.type) {
    case SET_ROLES_LOADING:
      return {
        ...state,
        isLoading: actions.payload
      }
    case SET_ROLES_CATALOG:
      return {
        ...state, 
        data: actions.payload
      }
    default:
      return state
  }
}
