import { TegduSelectOptionsModel } from "@models/global.model"
import * as Yup from 'yup';
export interface ObjectivesKeyResultsFormModel {
  id?: string
  name: string
  description?: string
  init_value: string
  target_value: string
  unit: TegduSelectOptionsModel | null
}

export interface CreateObjectiveMainFormModel {
  name: string
  description: string
  timeframe: TegduSelectOptionsModel | null
  team: TegduSelectOptionsModel[] | null
  owner: TegduSelectOptionsModel | null
  coach: TegduSelectOptionsModel[] | null
  tags: TegduSelectOptionsModel[] | null
  cultural_attributes: TegduSelectOptionsModel [] | null
  key_results: ObjectivesKeyResultsFormModel []
  newTags: string []
}


export const createObjectiveMainFormInitialValues: CreateObjectiveMainFormModel = {
  name: "",
  description: "",
  timeframe: null,
  team: null,
  owner: null,
  coach: null,
  tags: null,
  cultural_attributes: null,
  key_results: [{name: "", description: "", init_value: "", target_value: "", unit: {value: "0", label: ""}}],
  newTags: []
}

export const createObjectiveMainFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  timeframe: Yup.object().shape({
    value: Yup.string().required()
  }),
  owner: Yup.object().shape({
    value: Yup.string().required()
  }),
  key_results: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      init_value: Yup.string().required(),
      target_value: Yup.string().required().notOneOf([Yup.ref('init_value'), null]),
      unit: Yup.object().shape({
        value: Yup.string().required()
      }),
    })
  )

})