import { SET_HR_TEAM_TAB_STATE, SET_LOADING_SKILLS_APTITUDES, SET_SKILLS_APTITUDES_TAB } from "./hrteam.action";
import { HRTeamActions, HRTeamState, HRTeamTabState, SkillsAptitudesTabState } from "./hrteam.model";

const initialState : HRTeamState = {
	hrTeamActiveTabState : HRTeamTabState.skillsAptitudes,
	skillsAptitudesTabState : SkillsAptitudesTabState.all,
	isCreateSkillsAptitudesLoading: false,
}

export const hrTeamReducer = (
	state: HRTeamState = initialState,
	actions: HRTeamActions
): HRTeamState => {
	switch (actions.type) {
		case SET_HR_TEAM_TAB_STATE:
			return {
				...state,
				hrTeamActiveTabState: actions.payload
			}
		case SET_SKILLS_APTITUDES_TAB:
			return {
					...state,
					skillsAptitudesTabState: actions.payload
			}
		case SET_LOADING_SKILLS_APTITUDES:
			return{
				...state,
				isCreateSkillsAptitudesLoading: actions.payload,
			}
		default:
			return state
	}
}