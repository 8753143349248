import { OrganizationData } from '@models/organizations.model';
import { OptionsModel } from '@models/helpful.model';
import { PALETTE_DEFAULT_COLOR } from '@utils/theming';

export const OrganizationInfoSample: OrganizationData = {
  id: 0,
  public_name: 'Great Company',
  legal_name: 'Great Company Legal Name',
  company_website: 'www.company.com',
  color: PALETTE_DEFAULT_COLOR,
  tgd_company_size_catalog: {
    value: '0',
    label: 'a',
  },
  tgd_frequency_goals_catalog: {
    value: '1',
    label: 'a',
  },
  tgd_timezone_catalog: {
    value: '1',
    label: 'a',
  },
};

export const CompanySizeCatalogue: OptionsModel[] = [
  {
    value: '0',
    label: 'Less than 50',
  },
  {
    value: '1',
    label: 'More than 50',
  },
];

export const FrequencyGoalsCatalogue: OptionsModel[] = [
  {
    value: '0',
    label: 'Daily',
  },
  {
    value: '1',
    label: 'Weekly',
  },
];

export const TimeZoneCatalogue: OptionsModel[] = [
  {
    value: '0',
    label: 'GMT',
  },
  {
    value: '1',
    label: 'UTC',
  },
];

// export const PillarsSample: PillarModel[] = [
//   {
//     id: 12,
//     name: 'Talent',
//     valid_until: '10/12/2021',
//     tags: [],
//     user_coach: {
//       id: '1582',
//       firstName: 'Tony',
//       lastName: 'Stark',
//       role: { idRoles: 0, name: 'Admin' },
//       jobs: { idJobs: 1, title: 'Iron-Man' },
//       createTime: '10/12/2020',
//       status: true,
//       teams: [
//         {
//           id: '0',
//           name: 'Avengers',
//           lead: {
//             teamUserId: '89',
//             id: '2',
//             first_name: 'Sergio',
//             last_name: 'Gómez',
//           },
//           create_time: '',
//           members: [],
//         },
//       ],
//     },
//   },
// ];
