import TgdClient from '@helpers/HttpClients/TgdClient';
import {
  ApiGetObjective /*, ObjectiveGetResponse, */,
  ApiGetObjectiveUser,
  ApiUpdateKeyResults,
  FormikUpdateKeyResultsObject,
  KeyResultsUpdate,
  ObjectivePost,
  ObjectivePut,
  ObjectiveStatusPut,
} from '@models/objetives.model';
import { GET_OBJECTIVE } from '@graphql/objectives.queries';
import { getGraphqlData } from './graphql.service';
import { AddSimpleCommentRequest, ApiUpdateObjRoleUserResponse, CreateObjectivePost, EditObjectivePut, GetObjectivesCommentsResponse, RequestObjectiveApproval } from '@models/objectives.models';

export const getObjectives = async (roleId?: number): Promise<ApiGetObjective[]> => {
  return await TgdClient.get<ApiGetObjective[]>(
    `/tgd-objectives${roleId ? `?role=${roleId}` : ''}`
  );
};

export const updateObjectiveStatus = async (statusBody: ObjectiveStatusPut): Promise<any> => {
  return TgdClient.put('/custom-objectives/update-status', statusBody);
};

export const updateObjective = async (objectiveBody: ObjectivePut): Promise<any> => {
  return await TgdClient.put<any[]>('/custom-objectives', objectiveBody);
};

export const readObjective = async (objUserId: number): Promise<ApiUpdateObjRoleUserResponse> =>
  TgdClient.put<ApiUpdateObjRoleUserResponse>(`/tgd-obj-role-users/${objUserId}/read`);

export const createObjectiveService = async (data: CreateObjectivePost): Promise<any> => {
  return TgdClient.post(`/tgd-objectives`, data);
};

export const updateObjectiveService = async (
  data: EditObjectivePut,
  objectiveId: number | string
): Promise<any> => {
  return TgdClient.put(`/tgd-objectives/${objectiveId}`, data);
};

export const postAddObjective = async (objectiveBody: ObjectivePost): Promise<any> => {
  return await TgdClient.post<any[]>('/custom-objectives', objectiveBody);
};

export const getObjectiveById = async (objectiveId: number): Promise<any> => {
  const data = await getGraphqlData(GET_OBJECTIVE, { objectiveId });
  return data;
};

export const updateKeyResults = async (
  newValues: FormikUpdateKeyResultsObject[],
  keyResults: KeyResultsUpdate[]
): Promise<any> => {
  let apiBody: ApiUpdateKeyResults[] = [];

  for (let k = 0; k < newValues.length; k++) {
    if (newValues[k] !== undefined && newValues[k].new_progress !== '') {
      apiBody.push({
        id: parseInt(keyResults[k].id),
        name: keyResults[k].name,
        description: keyResults[k].description,
        init_value: keyResults[k].init_value,
        target_value: keyResults[k].target_value,
        current_value: parseFloat(newValues[k].new_progress),
      });
    }
  }

  return await TgdClient.put(`/custom-key-results`, apiBody);
};

export const getObjectiveTags = async (objectiveId: number): Promise<any> => {
  const data = await getGraphqlData(
    `query getTagsByObjective($objectiveId:ID!) {
    tgdObjective(id: $objectiveId) {
      id
      tgd_tag_objectives {
        tag:tgd_tag {
          id
          name
        }
      }
    }
  }`,
    { objectiveId }
  );
  const tags = data.tgdObjective.tgd_tag_objectives.map((tag: any) => ({
    id: tag.tag.id,
    name: tag.tag.name,
  }));
  return { id: data.tgdObjective.id, tags };
};

export const getObjectiveUsers = async (
  objectiveId: string | number
): Promise<ApiGetObjectiveUser[]> =>
  TgdClient.get(`/tgd-obj-role-users?objectiveId=${objectiveId}`);

export const getObjectiveOKRReviews = async (objectiveId: number): Promise<any> => {
  return await TgdClient.get<any[]>(`/tgd-objective-scores/latest/${objectiveId}`);
};
export const getObjectiveLogs = async (objectiveId: number): Promise<any> => {
  return await TgdClient.get<any[]>(`/tgd-obj-logs?objectiveId=${objectiveId}`);
};

export const getAvargeObjectives = async (): Promise<any> => {
  return await TgdClient.get<any[]>('/tgd-obj-user-scores/average');
};

export const getObjectiveReportService = async (
  status: number | string,
  role: number | string
): Promise<any> => {
  return TgdClient.get(`/tgd-objectives/download?status=${status}&role=${role}`);
};

export const  getObjectivesRecentService = ():Promise<any>=>{
    return TgdClient.get('/tgd-objectives/recent');
} 

export const getObjectiveCommentsService = async (objectiveId: string | number): Promise<GetObjectivesCommentsResponse []> => {
  return TgdClient.get(`/tgd-comments?objective_id=${objectiveId}`)
}

export const requestOjectiveApproval = async (data: RequestObjectiveApproval): Promise<any> => {
  return TgdClient.post(`/tgd-comments/request`, data)
}

export const addSimpleObjectiveComment = async (data: AddSimpleCommentRequest) => {
  return TgdClient.post(`/tgd-comments`, data)
}

export const approveDeclineCommentRequest = async (data: AddSimpleCommentRequest) => {
  return TgdClient.post(`/tgd-comments/approval`, data)
}