import Spinner from '@components/Shared/Spinner/Spinner'
import { Button } from '@material-ui/core'
import { ReviewsModelI } from '@store/Reviews/reviews.model'
import TegduSearchBar from 'pwa/components/Shared/TegduSearchbar/TegduSearchBar'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getReviewsBy } from "@services/reviews.service";
import "./style.scss"
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { ApiGetObjective } from '@models/objetives.model'

interface SearchReviewContainerProps {
  onClose: () => void
  onRowClicked: (tableData: ApiGetObjective) => void;
}

const SearchReviewContainer = (props: SearchReviewContainerProps) => {
  const { onClose, onRowClicked } = props

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();
  const [data, setData] = useState<ReviewsModelI | any>([]);
  const [searchData, setSearchData] = useState<string>("");

  const handleSearchBar = useCallback((searchElement: string) => {
    setSearchData(searchElement)
  }, [])

  const getReviewsCallback = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getReviewsBy("given");
      setData(response);
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    getReviewsCallback();
  }, [getReviewsCallback])

  const filteredData = useMemo(() => {
    if (searchData === "") {
      return []
    } else {
      return data.filter((review: ApiGetObjective) =>
        review.tgd_objective!.name.toLowerCase().includes(searchData.toLowerCase()) ||
        review.id.toString().includes(searchData)
      )
    }
  }, [searchData, data])

  return (
    <div className="search-review-container">
      <div className="search-area">
        <TegduSearchBar
          placeholderId="objectives.searchbar.placeholder"
          onSearch={handleSearchBar}
          isDisabled={loading}
        />
        <span onClick={onClose}>
          <FormattedMessage
            id="general.cancel"
          />
        </span>
      </div>

      <div className="review-info-area">
        {loading === true &&
          <div className="info-loading">
            <Spinner color='primary' size={40} thickness={5} />
          </div>
        }

        {(loading === false && error === true) &&
          <div className="info-error">
            <FormattedMessage
              id="generic.error.label"
              values={{ table: "Reviews" }}
            />
            <Button onClick={getReviewsCallback}>
              <FormattedMessage
                id="general.button.try.again"
              />
            </Button>
          </div>
        }

        {(loading === false && error === false) &&
          <div className="info-success">
            {filteredData.map((review: ApiGetObjective) => (
              <div className="info-row" onClick={() => onRowClicked(review)}>
                <div className="review-objective-name">
                  {`${review.tgd_objective!.name.slice(0, 15)}...`}
                </div>
                <div className="reviewer-name">
                  {`${review.reviewed!.first_name}  ${review.reviewed!.last_name}`}
                </div>

                <div className="review-rate">
                  <div className="mobile-rate-icon">
                    <StarRoundedIcon style={{ width: "30px", height: "30px" }} />
                    <span>{review.score}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export default SearchReviewContainer