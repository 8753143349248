import { ApiGetMembersCatalogue, GqlGetMemberOption } from '@models/members.model';
import { OptionsModel } from '@models/helpful.model';
import { TeamMember } from '@models/teams.model';

import { alphabeticalOrderArray } from './filters';

const instanceOfTeamMember = (data: any): data is TeamMember => {
  return 'idusers' in data;
};

const instanceOfTeamMembers = (data: any[]): data is TeamMember[] => {
  return Array.isArray(data) && data.every((value) => 'id' in value && 'first_name' in value);
};

export const buildMemberOption = (member: TeamMember | GqlGetMemberOption): OptionsModel => ({
  value: instanceOfTeamMember(member) ? member.id : member.value,
  label: `${member.first_name} ${member.last_name}`,
});

export const buildMemberOptions = (members: TeamMember[] | ApiGetMembersCatalogue[]): OptionsModel[] => {
  if (instanceOfTeamMembers(members)) {
    const membersTeam = members.map((member) => ({
      value: parseInt(member.id),
      label: `${member.first_name} ${member.last_name}`,
    }));

    return alphabeticalOrderArray(membersTeam);
  } else {
    const membersTeam = members.map((member) => ({
      value: member.id,
      label: member.full_name,
    }));
    return alphabeticalOrderArray(membersTeam);
  }
};

