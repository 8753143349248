import * as Yup from 'yup';
export interface TimeFramesFormObjectModel {
  id?: number
  name: string
  date_initial: null | Date
  date_until: null | Date
  newElement?: boolean
  automatic?: boolean
}

export interface TimeFramesFormModel {
  timeframe: TimeFramesFormObjectModel []
}

export const timeFramesFormInitialValue: TimeFramesFormModel = {
  timeframe: [{ 
    id: -1,  
    name: "", 
    date_initial: null, 
    date_until: null, 
    newElement: true, 
  }]
} 

export const TimeFramesFormSchema = Yup.object().shape({  
  timeframe: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      date_initial: Yup.string().required(),
      date_until: Yup.string().required()
    })
  )  
})