import { TegduTextAreaProps } from "@components/TegduFormComponents/TegduTextArea/TegduTextArea";
import { Controller } from "react-hook-form";

interface TextAreaControllerPropsHOC extends Omit<TegduTextAreaProps, 'value' | 'onChange' | 'onBlur'> {
  name: string
  control: any
}

const withTextAreaController = (WrappedComponent: (props: TegduTextAreaProps) => JSX.Element) => {
  const WithTextAreaController = (props: TextAreaControllerPropsHOC) => {
    const { name, control } = props;
    return (
      <Controller 
        control={control}
        name={name}
        render = { ( { field } ) => (
          <WrappedComponent 
            {...props}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
          />
        )}
      />
    )
  }

  return WithTextAreaController;
}

export default withTextAreaController;