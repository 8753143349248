import TabPanel from '@components/Shared/TabPanel/TabPanel';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import OrganizationInfoContainer from './OrganizationInfoContainer/OrganizationInfoContainer';
//import PillarsContainer from './PillarsContainer/PillarsContainer';
import StatementContainer from './StatementsContainer/StatementsContainer';
import { RootState } from '@store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTabAction } from '@store/Organization/selectedTab/selectedTab.action';
import TimeFramesContainer from './TimeFramesContainer/TimeFramesContainer';
import CulturalAttributesContainer from './CulturalAttributesContainer/CulturalAttributesContainer';

function OrganizationContainer() {
  const { selectedTab } = useSelector((state: RootState) => state.Organization.selectedTab);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      dispatch(setSelectedTabAction(newValue));
    },
    [dispatch]
  );

  return (
    <AppBar position='static'>
      <Tabs value={selectedTab} onChange={handleChange} className='all-tabs'>
        <Tab label={<FormattedMessage id={'organization.info'} />} id={'info'} />
        <Tab label={<FormattedMessage id={'organization.statements'} />} id={'statements'} />
        <Tab label={<FormattedMessage id={'organization.tab.culturalAttributes'} />} id={'culturalAttributes'} />
        <Tab label={<FormattedMessage id={'organization.timeframes'} />} id={'timeframes'} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <OrganizationInfoContainer />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <StatementContainer />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <CulturalAttributesContainer />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <TimeFramesContainer />
      </TabPanel>
    </AppBar>
  );
}

export default OrganizationContainer;
