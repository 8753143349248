import { Button, withStyles } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PeopleIcon from '@material-ui/icons/People';
import Rating from '@material-ui/lab/Rating';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import './styles.scss';
import { useCallback, useEffect, useState } from 'react';
import { ApiGetObjective } from '@models/objetives.model';
import { sendIndividualScore } from "@services/score.service";
import useToastNotification from 'hooks/useToastNotification';
import Spinner from '@components/Shared/Spinner/Spinner';

const StyledRating = withStyles({
  icon: {
    padding: '0px',
  },
  iconEmpty: {
    color: '#F0F0F0',
  },
  iconFilled: {
    color: '#FFD460',
  },
})(Rating);

interface ReviewAgainIndividualContainerProps {
  data: ApiGetObjective | null
  handleCloseModal: () => void
  handleCloseModalSuccess: () => void
}

const ReviewAgainIndividualContainer = (props: ReviewAgainIndividualContainerProps) => {
  const { data, handleCloseModal, handleCloseModalSuccess } = props;
  const [rateValue, setRateValue] = useState<number>((data !== null && data.score !== undefined) ? data.score : 0);
  const [loading, setLoading] = useState<boolean>(false);
  const toastNotification = useToastNotification();

  const handleChangeRateValue = useCallback((event: any, newValue: number | null) => {
    if(newValue !== null){
      setRateValue(newValue)
    }
  }, [])

  useEffect(() => {
    if(data === null){
      handleCloseModal()
    }
  }, [data, handleCloseModal])

  const handleUpdateScore = useCallback(async () => {
    if(data !== null && data.tgd_objective !== undefined && data.reviewed?.id !== undefined){
      setLoading(true)
      try {
        await sendIndividualScore({
          objective_id: data.tgd_objective.id, 
          scores: [{
            tgd_obj_role_user: data.reviewed.id,
            score: rateValue
          }]
        })
        setLoading(false)
        handleCloseModalSuccess()
        toastNotification("mobile.review.success.score", false, true)
      }catch {
        setLoading(false)
        toastNotification("mobile.review.error.score", true, true)
      }
    }else {
      handleCloseModal()
      toastNotification("mobile.review.error.score", true, true)
    }
   
  }, [toastNotification, handleCloseModal, handleCloseModalSuccess, rateValue, data])


  return(
    <div className="review-again-individual-container">
      {data !== null &&
        <>
          <div className="title">
            Review teammates contribution
          </div>

          <div className="info">
            <div className='row'>
              <ListAltOutlinedIcon />
              <span>{data.tgd_objective?.name}</span>
            </div>

            <div className='row'>
              <PeopleIcon />
              <div className='sub-row'>
                <span>{data.reviewer?.first_name + " " + data.reviewer?.last_name}</span>
                <span id='role'>{"Owner"}</span>
              </div>
            </div>

          </div>

          <div className="rate-area">
            <StyledRating
              name='general-rate'
              icon={<StarRoundedIcon style={{ width: '56px', height: '56px' }} />}
              value={rateValue}
              onChange={handleChangeRateValue}
              disabled={false}
            />
          </div>

          <div className="button-area">
            <Button       
              onClick={handleUpdateScore}
              type="button"       
              style={{display: "flex", justifyContent: "center", width: "234px"}}
              disabled={loading}
            >
              {loading ?
                <Spinner color='white' size={15} thickness={5} />   
              : 
                <>Save Score</>
              }              
            </Button>
          </div>
        </>
      }
      
    </div>
  )
}

export default ReviewAgainIndividualContainer;