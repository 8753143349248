import { SET_INITIALIZING_OBJECTIVES_DATA, SET_IS_CREATE_OBJECTIVE_LOADING, SET_IS_EDIT_OBJECTIVE_LOADING } from "./objectivesMainActions.actions";
import { ObjectivesMainActions, ObjectivesMainActionsModel } from "./objectivesMainActions.model";

const initalState: ObjectivesMainActionsModel = {
  isInitializingObjectivesData: true,
  isCreateObjectiveLoading: false,
  isUpdatingObjectiveLoading: false
}

const objectivesMainActionsReducer = ( 
  state: ObjectivesMainActionsModel = initalState ,
  actions: ObjectivesMainActions
  ):ObjectivesMainActionsModel  => {
  switch(actions.type){
    case SET_INITIALIZING_OBJECTIVES_DATA:
      return {
        ...state,
        isInitializingObjectivesData: actions.payload
      }
    case SET_IS_CREATE_OBJECTIVE_LOADING:
      return{
        ...state,
        isCreateObjectiveLoading: actions.payload
      }
    case SET_IS_EDIT_OBJECTIVE_LOADING:
      return{
        ...state,
        isUpdatingObjectiveLoading: actions.payload
      }
    default:
      return state
  }
}

export default objectivesMainActionsReducer;