import { Dialog } from "@material-ui/core";
import CircularProgress from "@components/Shared/CircularProgress/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FormattedMessage } from "react-intl";
import "./styles.scss";
import ObjectivesBottomSheetDescription from "../ObjectivesBottomSheetDescription/ObjectivesBottomSheetDescription";
import { useState } from "react";

interface ObjectivesModalAllKeyResultsProps {
  keyResultsData: any;
  open: boolean;
  onClose: () => void;
}
const ObjectivesModalAllKeyResults = ({
  keyResultsData,
  open,
  onClose,
}: ObjectivesModalAllKeyResultsProps) => {
  const [openSheet, setOpenSheet] = useState<boolean>(false);
  const [dataKeyResults, setDataKeyResults] = useState<any>({});

  const selectedKeyResult = (
    descrip: string,
    title: string,
    progress: number
  ) => {
    setDataKeyResults({
      description: descrip,
      titleKr: title,
      current: progress,
    });
    setOpenSheet(true);
  };
  return (
    <Dialog
      fullScreen
      scroll="body"
      open={open}
      onClose={onClose}
      className="modal-objective-key-results-mobile"
    >
      <div className="container-modal-results">
        {/* HEADER */}
        <div className="container-header">
          <div className="header-back-arrow" onClick={onClose}>
            <ArrowBackIcon />
          </div>
          <span>
            <FormattedMessage id={"objectives.header.mobile.key.results"} />
          </span>
        </div>
        {/* CONTENT */}
        {keyResultsData?.map((e: any, index: any) => (
          <div className="content-info-kr" key={e.name}>
            <div className="progress-circular">
              <CircularProgress
                value={e?.current_progress > 1 ? 100 : e?.current_progress}
              />
            </div>
            <div className="description-target-initial">
              <div className="description">
                <p>
                  <b>{e?.name}</b>&nbsp; {e?.description}
                </p>
              </div>
              <div className="values-description">
                {e?.description && (
                  <span
                    onClick={() =>
                      selectedKeyResult(
                        e?.description,
                        e?.name,
                        e?.current_progress
                      )
                    }
                  >
                    <FormattedMessage
                      id={"objectives.modal.title.mobile.description"}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
        <ObjectivesBottomSheetDescription
          dataKey={dataKeyResults}
          onCloseSheet={() => setOpenSheet(false)}
          openSheet={openSheet}
        />
      </div>
    </Dialog>
  );
};

export default ObjectivesModalAllKeyResults;
