import { TegduSelectOptionsModel } from '@models/global.model';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './styles.scss';
import TegduSelectTimeframesOptions from '../../Shared/TegduSelectTimeframesOptions/TegduSelectTimeframesOptions';
import TegduSelectCulturalAttributesOptions from '../../Shared/TegduSelectCulturalAttributesOptions/TegduSelectCulturalAttributesOptions';
import StarRoundedIcon from "@material-ui/icons/StarRounded";

export interface TegduSelectProps {
  defaultValue?: TegduSelectOptionsModel
  disabled?: boolean
  formatOptions?: 'timeframes' | 'culturalAttributes' | 'circleName' | 'reviews'
  isSearchable: boolean
  isClearable: boolean
  isLoading?: boolean
  isMulti: boolean
  labelMessageId?: string
  options: TegduSelectOptionsModel[]
  placeholderId?: string
  value: TegduSelectOptionsModel | TegduSelectOptionsModel[] | undefined
  viewMode?: boolean
  onBlur?: () => void
  onChange: (data: any) => void
}


const TegduSelect = (props: TegduSelectProps) => {
  const intl = useIntl();
  const {
    defaultValue,
    disabled,
    formatOptions,
    isClearable,
    isLoading,
    isSearchable,
    options,
    placeholderId,
    value,
    labelMessageId,
    isMulti,
    viewMode
  } = props;
  const { onBlur, onChange } = props;

  return (
    <div className="tegdu-select">
      {labelMessageId &&
        <div className='label-message'>
          <span>
            <FormattedMessage id={labelMessageId} />
          </span>
        </div>
      }
      <Select
        isDisabled={disabled || isLoading}
        isLoading={isLoading}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        className={`tegdu-select-container ${disabled ? 'disabled' : ''} ${(viewMode && viewMode === true) ? 'viewMode' : ''}`}
        classNamePrefix={`tegdu-select-prefix`}
        defaultValue={defaultValue}
        options={options}
        placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        components={formatOptions ?
          formatOptions === "timeframes" ? {
            DropdownIndicator: () => <ArrowDropDownIcon />,
            SingleValue: (props: any) => (
              <components.SingleValue {...props}>
                <TegduSelectTimeframesOptions
                  timeframes={props.data.complementaryData !== undefined ? props.data.complementaryData : undefined}
                  label={props.data.label}
                />
              </components.SingleValue>
            ),
            Option: (props: any) => (
              <components.Option {...props}>
                <TegduSelectTimeframesOptions
                  timeframes={props.data.complementaryData}
                  label={props.data.label}
                />
              </components.Option>
            )
          } : formatOptions === "culturalAttributes" ? {
            DropdownIndicator: () => <ArrowDropDownIcon />,
            SingleValue: (props: any) => (
              <components.SingleValue {...props}>
                <TegduSelectCulturalAttributesOptions
                  culturalAttributeType={props.data.complementaryData}
                  label={props.data.label}
                />
              </components.SingleValue>
            ),
            Option: (props: any) => (
              <components.Option {...props}>
                <TegduSelectCulturalAttributesOptions
                  culturalAttributeType={props.data.complementaryData}
                  label={props.data.label}
                />
              </components.Option>
            )
          } : formatOptions === "circleName" ? {
            DropdownIndicator: () => <ArrowDropDownIcon />,
            SingleValue: (props: any) => (
              <components.SingleValue {...props}>
                {/* TEGDU SELECT CIRCLE NAME OPTIONS */}
              </components.SingleValue>
            ),
            Option: (props: any) => (
              <components.Option {...props}>
                {/* TEGDU SELECT CIRCLE NAME OPTIONS */}
              </components.Option>
            )
          } : formatOptions === "reviews" ? {
            DropdownIndicator: () => <><StarRoundedIcon /><span /></>,
            SingleValue: (props: any) => (
              <components.SingleValue {...props}>
                {/* TEGDU SELECT REVIEWS  OPTIONS */}
              </components.SingleValue>
            ),
            Option: (props: any) => (
              <components.Option {...props}>
                {/* TEGDU SELECT REVIEWS  OPTIONS */}
              </components.Option>
            )
          } :
            {
              // DEFAULT 
              DropdownIndicator: () => <ArrowDropDownIcon />,
            }
          : {
            // DEFAULT 
            DropdownIndicator: () => <ArrowDropDownIcon />,
          }

        }
      />
    </div>
  )
}

export default TegduSelect;