import './styles.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LabelWitchCheckbox from '@components/Shared/LabelWithCheckbox/labelWithCheckbox'
import UpdatePasswordForm, { UpdatePasswordStructure } from '@components/Account/UpdatePasswordForm/UpdatePasswordForm';

interface UpdatePasswordContainerProps {
  isValid: (value: boolean) => void
  onValues: (values: UpdatePasswordStructure) => void
}

const UpdatePasswordContainer = (props: UpdatePasswordContainerProps) => {
  const { isValid, onValues } = props;
  const [values, setValues] = useState<undefined | string>(undefined);

  const [validateCapital, setValidateCapital] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [validateLength, setValidateLength] = useState(false);
  const [validateSpecial, setValidateSpecial] = useState(false);

  const getIsValid = useCallback((value: boolean) => {
    isValid(value)
  }, [isValid])

  const getValidationsValues = useCallback((valuesPass: UpdatePasswordStructure) => {
    setValues(valuesPass.newPassword)
    onValues(valuesPass)
  }, [onValues])

  useEffect(() => {
    const validateCapitalLetter = /[A-Z]/;
    const validateNumber = /\d/;
    // eslint-disable-next-line
    const validateSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (values !== undefined) {
      if (validateCapitalLetter.test(values)) {
        setValidateCapital(true)
      } else {
        setValidateCapital(false)
      }
      if (validateNumber.test(values)) {
        setValidateNumber(true)
      } else {
        setValidateNumber(false)
      }
      if (validateSpecialChars.test(values)) {
        setValidateSpecial(true)
      } else {
        setValidateSpecial(false)
      }
      if (values.length > 8) {
        setValidateLength(true)
      } else {
        setValidateLength(false)
      }

    }
  }, [values])

  return (
    <Grid container className='update-password-container'>
      <Grid item xs={12} sm={6} md={6} className='form-item'>
        <UpdatePasswordForm
          isValidForm={getIsValid}
          getValidations={getValidationsValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className='dots-item'>
        <div className='dots-title'>
          <p>
            <FormattedMessage id={"resetPassword.createPassword.instrucctions"} />
          </p>
        </div>
        <Grid container alignItems={'center'} >
          <Grid item xs={12} sm={12} md={6}>
            <LabelWitchCheckbox
              checked={validateLength}
              label={'resetPassword.passwordRequired.8characters'}
              thin={true}
              checkboxClassName='thin'
            />
            <LabelWitchCheckbox
              checked={validateCapital}
              label={'resetPassword.passwordRequired.1capitalletter'}
              thin={true}
              checkboxClassName='thin'
            />
            <LabelWitchCheckbox
              checked={validateSpecial}
              label={'resetPassword.passwordRequired.1specialCharacter'}
              thin={true}
              checkboxClassName='thin'
            />
            <LabelWitchCheckbox
              checked={validateNumber}
              label={'resetPassword.passwordRequired.1number'}
              thin={true}
              checkboxClassName='thin'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UpdatePasswordContainer;