import { OptionsModel } from "@models/helpful.model"
import { AddMembersEmailsModel } from "@models/invitations.model"
import { EmailsFromXLSX } from "@models/members.model"
import { Accept } from "react-dropzone"

/**
 * MEMBER_ROLE
 * Description: Enum about tgd roles (owner, coach, collaborator)
 * Author: Humberto Santana
 */
export enum MEMBER_ROLE{
  owner = 1,
  coach = 2,
  collaborator = 3,
  leader = 4,
}

export const MEMBER_ROLE_LABEL = {
  [MEMBER_ROLE.owner]: "Owner",
  [MEMBER_ROLE.coach]: "Coach",
  [MEMBER_ROLE.collaborator]: "Collaborator",
  [MEMBER_ROLE.leader]: "Leader"
}

/**
 * steRoleCatalog
 * Description: Function to return tgdroleTatalogue array according to the user and the user whow is being requested
 * Author: Humberto Santana
 */
export const setRoleCatalog = (userRole: number, userRoleToEdit: number, roleCatalogue: OptionsModel []) => {
  switch(userRole){
    case 7:
      if(userRoleToEdit === userRole){
        return roleCatalogue.filter((role) => role.value === "7")
      }else{
        return roleCatalogue.filter((role) => role.value !== "7")
      }
    
    case 6:
      if(userRoleToEdit !== 6 && userRoleToEdit !== 7){
        return roleCatalogue.filter((role) => role.value !== "7")
      }else{
        return roleCatalogue.filter((role) => role.value === userRoleToEdit.toString())
      }
    
    case 3:
      return roleCatalogue.filter((role) => role.value === userRoleToEdit.toString())
    
    case 5:
      return roleCatalogue.filter((role) => role.value === userRoleToEdit.toString())
    
    default:
      return roleCatalogue.filter((role) => role.value === userRole.toString())
  }
}

export const EXCEL_ACCEPTED_FILES: Accept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
}

export const parseEmailsFromFileToSelectInput = (emails: EmailsFromXLSX []): AddMembersEmailsModel [] => {

  let newEmailsShape: AddMembersEmailsModel [] = [];

  emails.map((email: EmailsFromXLSX, index: number) => {
    newEmailsShape.push({
      value: index + 90000,
      label: email.Emails
    })
    return null
  })

  return newEmailsShape;
}

export const validateEmailFormat = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}