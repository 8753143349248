import React, { useCallback } from 'react';
import StyledDropdown, { StyledDropdownProps } from '@components/Shared/StyledDropdown/StyledDropdown';
import { Grid } from '@material-ui/core';
import FormikStyledError from '../FormikStyledError/FormikStyledError';
import './styles.scss'
import { ValueType } from '@models/notifications.models';

export interface FormikDropdownProps extends Omit<StyledDropdownProps, 'onSelectOption'> {
    measure?: number
    meta?: any
    name: string;
    validateOnClick?: boolean;
    withComplexShouldUpdate?: boolean;
    onChange?: (value: ValueType) => void;
    setFieldValue: (field: string, value: any) => void
}

const FormikDropdown = (props: FormikDropdownProps) => {
    const { name, meta, onChange, setFieldValue } = props;

    const handleChange = useCallback((value: ValueType) => {
        setFieldValue(name, value);
        if (onChange) {
            onChange(value);
        }

    }, [onChange, setFieldValue, name])

    return (
        <div className='formik-dropdown-refactor'>
            <StyledDropdown
                {...props}
                error={
                    props.validateOnClick
                        ? props.meta.error && props.meta.touched
                            ? true
                            : false
                        : props.meta.error
                            ? true
                            : false
                }
                onSelectOption={handleChange}
            />

            {meta !== undefined &&
                <Grid container className={'extra-info'}>
                    <Grid item className={'error'} md={12}>
                        {props.validateOnClick ? (
                            <>{meta.error && meta.touched && <FormikStyledError message={meta.error} />}</>
                        ) : (
                            <>{meta.error !== undefined && <FormikStyledError message={meta.error} />}</>
                        )}
                    </Grid>
                </Grid>
            }

        </div>
    )
}

export default FormikDropdown;