import { Button } from "@material-ui/core";
import { setSkillsAptitudesTab } from "@store/HRTeam/HRTeamTabs/hrteam.action";
import { SkillsAptitudesTabState } from "@store/HRTeam/HRTeamTabs/hrteam.model";
import { RootState } from "@store/rootReducer";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import './styles.scss';

interface SkillsAptitudesButtonsInterface {
	getAllMethod: () => Promise<void>
	getAptitudesMethod: () => Promise<void>
	getSkillsMethod: () => Promise<void>
}

const SkillsAptitudesButtons = ({ getAllMethod, getAptitudesMethod, getSkillsMethod}: SkillsAptitudesButtonsInterface) => {
	const skillsAptitudesTabState = useSelector((state: RootState) => state.HRTeam.skillsAptitudesTabState);

	const dispatch = useDispatch();

	const onClick = (value: number) => {
		dispatch(setSkillsAptitudesTab(value));
	}

	useEffect(() => {
		if (skillsAptitudesTabState === SkillsAptitudesTabState.all){
			getAllMethod();
		} else if (skillsAptitudesTabState === SkillsAptitudesTabState.skills){
			getSkillsMethod();
		} else if (skillsAptitudesTabState === SkillsAptitudesTabState.aptitudes){
			getAptitudesMethod();
		}
		console.log(skillsAptitudesTabState)
	}, [getAllMethod, getAptitudesMethod, getSkillsMethod, skillsAptitudesTabState])

	return (
		<div className="buttons-container">
			<Button
				className={`${skillsAptitudesTabState === SkillsAptitudesTabState.all ? 'button-selected' : 'button-not-selected'}`}
        onClick={() => onClick(SkillsAptitudesTabState.all)}>
				<FormattedMessage id="hr.skills.aptitudes.all"/>
			</Button>

			<Button
        className={`${skillsAptitudesTabState === SkillsAptitudesTabState.skills ? 'button-selected' : 'button-not-selected'}`}
        onClick={() => onClick(SkillsAptitudesTabState.skills)}
      >
				<FormattedMessage id="hr.skills.aptitudes.skills"/>
			</Button>

			<Button
        className={`${skillsAptitudesTabState === SkillsAptitudesTabState.aptitudes ? 'button-selected' : ' button-not-selected' }`}
        onClick={() => onClick(SkillsAptitudesTabState.aptitudes)}
      >
				<FormattedMessage id="hr.skills.aptitudes.aptitudes"/>
			</Button>
		</div>
	)
}

export default SkillsAptitudesButtons;