export const ROUTE = {
  access: {
    title: 'Access',
    path: '/access',
  },
  onboarding: {
    title: 'Onboarding',
    path: '/onboarding',
  },
  dashboard: {
    title: 'Dashboard',
    path: '/dashboard',
  },
  addObjective: {
    title: 'Add Objective',
    path: '/objectives/add',
  },
  reviews: {
    title: 'Reviews',
    path: '/reviews',
  },
  objetives: {
    title: 'Objetives',
    path: '/objectives',
  },
  objectivesDetail: {
    title: 'Objetives Detail',
    path: '/objectives/:id',
  },
  objectivesDrafts: {
    title: 'Objetives Drafts',
    path: '/objectives/drafts',
  },
  organization: {
    title: 'Organization',
    path: '/organization',
  },
  hr: {
    title: 'HR Team',
    path: '/hr',
  },
  archiveStatements: {
    title: 'Archive Statements',
    path: '/organization/archivestatements',
  },
  archivePillars: {
    title: 'Archive Pillars',
    path: '/organization/archivepillars',
  },
  members: {
    title: 'Members',
    path: '/members',
  },
  membersDetail: {
    title: 'Members Detail',
    path: '/members/:id',
  },
  teams: {
    title: 'Teams',
    path: '/teams',
  },
  addTeam: {
    title: 'Add new team',
    path: '/teams/new',
  },
  teamDetails: {
    title: 'Team details',
    path: '/teams/:id',
  },
  invitations: {
    title: 'Invitations',
    path: '/invitations',
  },
  invitationsOnboarding: {
    title: 'Invitations onboarding',
    path: '/signup',
  },
  settings: {
    title: 'Settings',
    path: '/settings',
  },
  archiveTags: {
    title: 'Archive Tags',
    path: '/settings/archivetags',
  },
  faqs: {
    title: 'FAQs',
    path: '/faqs',
  },
  login: {
    title: 'Login',
    path: '/',
  },
  recoverPassword: {
    title: 'reset Password',
    path: '/reset-password',
  },
  reactivate: {
    title: 'Reactivate',
    path: '/reactivate',
  },
  error: {
    title: 'Error',
    path: '/error',
  },
  account: {
    title: 'My Account',
    path: '/account',
  },
};
