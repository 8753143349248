import { useCallback, useEffect, useMemo, useState } from 'react';
import EmailNotificationsComponent from '@components/Settings/Notifications/EmailNotificationsComponent/EmailNotificationsComponent';
import { OptionsModel } from '@models/helpful.model';
import useCatalogue from 'hooks/useCatalogue';
import {
  getNotificationPeriodicityCatalogue,
  getNotificationExpirationCatalogue,
} from '@services/notifications.service';
import { getNotificationsPreferences } from '@services/notifications.service';
import useToastNotification from 'hooks/useToastNotification';
import { ApiCatalogueLabel } from '@models/catalogs.model';
import Spinner from '@components/Shared/Spinner/Spinner';

import './style.scss';
import { buildWeeksCatalogue } from '@utils/notifications';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

interface Preference {
  created_at: string;
  email: boolean;
  id: number;
  tgd_notification_template: number;
  updated_at: string;
  user: number;
  webapp: boolean;
}

interface ExpirationPreference {
  id: number;
  tgd_notification_expiration_catalog: ApiCatalogueLabel;
  created_at: string;
  updated_at: string;
  interval?: number;
  tgd_notification_periodicity_catalog?: ApiCatalogueLabel
}
interface PeriodicPreference {
  id: number;
  interval: number;
  tgd_notification_periodicity_catalog: ApiCatalogueLabel;
  created_at: string;
  updated_at: string;
}
export interface EmailNotificationsI {
  description: string;
  entity: string;
  key: string;
  type: string;
  preference: Preference;
  expiration_preference?: ExpirationPreference;
  periodic_preference?: PeriodicPreference;
}

const NotificationsContainer = () => {
  const [dataPeriodic, setDataPeriodic] = useState<OptionsModel[]>([]);
  const [dataExpiration, setDataExpiration] = useState<OptionsModel[]>([]);
  const [loadingPeriodic, setLoadingPeriodic] = useState<boolean>(true);
  const [loadingExpiration, setLoadingExpiration] = useState<boolean>(true);
  const [errorPeriodic, setErrorPeriodic] = useState<any | null>(null);
  const [errorExpiration, setErrorExpiration] = useState<any | null>(null);
  const [emailNotifications, setEmailNotifications] = useState<EmailNotificationsI[]>();
  const tegduRole = useSelector((state: RootState) => state.User.Info.user.role.id);

  const { getCatalogue } = useCatalogue();
  const toastNotification = useToastNotification();

  const daysAndWeeks: OptionsModel[] = useMemo(() => buildWeeksCatalogue(), [])

  const getEmailNotificationsPreferences = useCallback(async () => {
    try {
      const notifications = await getNotificationsPreferences();
      setEmailNotifications(notifications);
    } catch {
      toastNotification('notifications.catalogue.error', true);
    }
  }, [toastNotification]);

  const handleGetPeriodicError = useCallback(
    (error) => {
      setErrorPeriodic(error);
      toastNotification('notifications.catalogue.error', true);
    },
    [toastNotification]
  );

  const handleGetExpirationError = useCallback(
    (error) => {
      setErrorExpiration(error);
      toastNotification('notifications.catalogue.error', true);
    },
    [toastNotification]
  );

  const getPeriodicCatalogue = useCallback(async () => {
    setLoadingPeriodic(true);
    await getCatalogue(
      getNotificationPeriodicityCatalogue,
      (options) => setDataPeriodic(options),
      handleGetPeriodicError
    );
    setLoadingPeriodic(false);
  }, [getCatalogue, handleGetPeriodicError]);

  const getExpirationCatalogue = useCallback(async () => {
    setLoadingExpiration(true);
    await getCatalogue(
      getNotificationExpirationCatalogue,
      (options) => setDataExpiration(options),
      handleGetExpirationError
    );
    setLoadingExpiration(false);
  }, [getCatalogue, handleGetExpirationError]);

  // Get data only on mount
  useEffect(() => {
    getPeriodicCatalogue();
    getExpirationCatalogue();
    getEmailNotificationsPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {emailNotifications && emailNotifications.length > 0 ? (
        <div className='notifications-container'>
          <EmailNotificationsComponent
            data={emailNotifications}
            periodicOptions={dataPeriodic}
            expirationOptions={dataExpiration}
            loadingPeriodic={loadingPeriodic}
            loadingExpiration={loadingExpiration}
            errorPeriodic={errorPeriodic}
            errorExpiration={errorExpiration}
            daysAndWeeksOptions={daysAndWeeks}
            role={tegduRole}
          />
        </div>
      ) :
        (<div className='spinner-and-error-wrapper'>
          {emailNotifications?.length === 0 ?
            <>
              <FormattedMessage id="general.notifications.error.label" />
              <Button onClick={getEmailNotificationsPreferences}>
                <FormattedMessage id="global.label.tryAgain" />
              </Button>
            </> :
            <Spinner size={40} color='primary' thickness={4} />
          }
        </div>)
      }
    </>
  );
};

export default NotificationsContainer;
