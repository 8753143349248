import './styles.scss';
import TabPanel from '@components/Shared/TabPanel/TabPanel';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PersonalInfoContainer from '../PersonalInfoContainer/PersonalInfoContainer';
import SecurityContainer from '../SecurityContainer/SecurityContainer';

const AccountContainer = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }, []);
  return (
    <div id='account' className='account-container'>
      <h1 id='title'>
        <FormattedMessage id='account.screen.title' />
      </h1>

      <h3 id='subtitle'>
        <FormattedMessage id='account.screen.subtitle' />
      </h3>

      <div className='appbar-container'>
        <AppBar position='static'>
          <Tabs value={tabValue} onChange={handleChange} className='all-tabs'>
            <Tab
              label={
                <>
                  {' '}
                  <FormattedMessage id={'account.screen.tab.personalInfo'} />{' '}
                </>
              }
              id={'personalInfo'}
            />
            <Tab
              label={
                <>
                  {' '}
                  <FormattedMessage id={'account.screen.tab.security'} />{' '}
                </>
              }
              id={'security'}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0}>
          <PersonalInfoContainer />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SecurityContainer />
        </TabPanel>
      </div>
    </div>
  );
};

export default AccountContainer;
