import { getIn } from "formik";
import { useCallback } from "react";  

export const useFormikShouldUpdate = (viewMode: boolean, measure?: number) => {

   const shouldUpdate = useCallback((nextProps: any, currentProps: any) => {
      return (
         nextProps.name !== currentProps.name ||
         nextProps.required !== currentProps.required ||
         nextProps.disabled !== currentProps.disabled ||
         nextProps.readOnly !== currentProps.readOnly ||
         nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
         Object.keys(nextProps).length !== Object.keys(currentProps).length ||
         getIn(nextProps.formik.values, currentProps.name) !==
         getIn(currentProps.formik.values, currentProps.name) ||
         getIn(nextProps.formik.errors, currentProps.name) !==
         getIn(currentProps.formik.errors, currentProps.name) ||
         getIn(nextProps.formik.touched, currentProps.name) !==
         getIn(currentProps.formik.touched, currentProps.name) ||
         viewMode
      );
   },[viewMode]);

   const shouldUpdateWithMeasure = useCallback((nextProps: any, currentProps: any) => {
      return (
         nextProps.name !== currentProps.name ||
         nextProps.required !== currentProps.required ||
         nextProps.disabled !== currentProps.disabled ||
         nextProps.readOnly !== currentProps.readOnly ||
         nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
         Object.keys(nextProps).length !== Object.keys(currentProps).length ||
         getIn(nextProps.formik.values, currentProps.name) !==
         getIn(currentProps.formik.values, currentProps.name) ||
         getIn(nextProps.formik.errors, currentProps.name) !==
         getIn(currentProps.formik.errors, currentProps.name) ||
         getIn(nextProps.formik.touched, currentProps.name) !==
         getIn(currentProps.formik.touched, currentProps.name) ||
         measure
      );
   },[measure]);

   return measure !== undefined ? shouldUpdateWithMeasure : shouldUpdate;
}

export default useFormikShouldUpdate;