import { TegduCheckboxProps } from "@components/TegduFormComponents/TegduCheckbox/TegduCheckbox"
import { FastField } from "formik"
import useFormikShouldUpdate from "hooks/useFormikShouldUpdate"

type FormikCheckboxPropsHOC = Omit<TegduCheckboxProps, 'field' | 'meta'>

const withCheckboxFastField = (WrappedComponent: (props: TegduCheckboxProps) => JSX.Element) => {
   const WithCheckboxFastField = (props: FormikCheckboxPropsHOC) => {
      const { viewMode =  false, name  } = props
      const shouldUpdate = useFormikShouldUpdate(viewMode)
      return(
         <FastField name={name} shouldUpdate={shouldUpdate}>
            {({field, meta} : any) => (
               <WrappedComponent 
                  {...props}
                    onChangeForm={field.onChange}
                    value={field.value}
                    onBlur={field.onBlur}
               />
            )}
         </FastField>
      )
   }
   return WithCheckboxFastField;
}

export default withCheckboxFastField;
