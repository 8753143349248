import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { injectIntl, IntlShape } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import "./style.scss"

interface SearchbarProps {
  intl: IntlShape;
  intlId: string;
  onSearch: (term: string) => void;
  className?: string;
  valueInput?: string;
  disabled?: boolean;
}

const Searchbar = ({ intl, intlId, onSearch, className, valueInput, disabled }: SearchbarProps) => {
  const placeholder = intl.formatMessage({ id: intlId });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const valueChange = e.target.value;
    onSearch(valueChange);
  }, [onSearch])

  const handleClearSearchbar = useCallback(() => {
    onSearch("")
  }, [onSearch])

  return (
    <TextField
      className={className ? className : ''}
      name='search'
      variant='outlined'
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            {valueInput === undefined || valueInput === "" ? <SearchIcon /> : <CloseIcon onClick={handleClearSearchbar} className="close-icon" />}
          </InputAdornment>
        ),
      }}
      value={valueInput !== undefined ? valueInput : ""}
      inputProps={{ maxLength: 50 }}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default injectIntl(Searchbar);
