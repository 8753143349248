import Spinner from "@components/Shared/Spinner/Spinner";
import TegduCodeVerificationComponent from "@components/Shared/TegduCodeVerificationComponent/TegduCodeVerificationComponent";
import { Button } from "@material-ui/core";
import { verifyForgotPasswordCode } from "@store/Login/ForgotPassword/forgotPassword.actions";
import { RootState } from "@store/rootReducer";
import useToastNotification from "hooks/useToastNotification";
import TegduMobileSuccess from "pwa/components/Shared/TegduMobileSuccess/TegduMobileSuccess";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './styles.scss';

interface ForgotPaswwordCodeContainerProps {
  email: string
  handleContinue: (token: string) => void
}

const ForgotPaswwordCodeContainer = (props: ForgotPaswwordCodeContainerProps) => {
  const { email, handleContinue } = props;
  const [code, setCode] = useState<string>("");
  const dispatch = useDispatch();
  const toastNotification = useToastNotification();
  const isVerifyCodeLoading = useSelector((state: RootState) => state.Login.ForgotPassword.isVerifyCodeLoading);

  const handleSuccessCodeVerification = useCallback((token: string) => {
    handleContinue(token)
  }, [handleContinue])

  const handleWrongSuccessCodeVerification = useCallback(() => {
    toastNotification("general.error.verifyCode", true, true)
  }, [toastNotification])

  const handleCodeChange = useCallback((code: string) => {
    setCode(code)
  }, [])

  const handleCodeVerificationCode = useCallback(() => {
    dispatch(verifyForgotPasswordCode(
      email, 
      code, 
      handleSuccessCodeVerification, 
      handleWrongSuccessCodeVerification
    ))
    
  }, [
    handleSuccessCodeVerification, 
    handleWrongSuccessCodeVerification,
    dispatch,
    email,
    code
  ])

  return(
    <div className="forgot-password-code-container">
      <TegduMobileSuccess />

      <div className="code-area">
        <span>Email code:</span>
        <TegduCodeVerificationComponent 
          length={6}
          loading={isVerifyCodeLoading}
          handleOnChange={handleCodeChange}         
        />
      </div>

      <div className="button-area">
        <Button
          onClick={handleCodeVerificationCode}
          disabled={(code.length === 6 && isVerifyCodeLoading === false) ? false : true}
          style={{display: "flex", justifyContent: "center", width: "234px"}}
        >
          {isVerifyCodeLoading ?
            <Spinner color='white' size={15} thickness={5} />            
          :
            <>Continue</>
          }          
        </Button>
      </div>
     
    </div>
  )
}

export default ForgotPaswwordCodeContainer;