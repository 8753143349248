import CircularProgress from '@components/Shared/CircularProgress/CircularProgress';
import { currentValueWithUnits } from '@utils/objectives';
import { FormattedMessage } from 'react-intl';
import KrDescription from '../KrDescription/KrDescription';

import './styles.scss';

interface InlineKeyResultProps {
  index: number; // index starting in 0
  name: string;
  description: string;
  progress: number;
  targetValue?: string;
  currentValue?: string;
  unitId?: string;
  showDescription?: boolean;
}

const InlineKeyResult = ({
  index,
  name,
  description,
  progress,
  currentValue,
  targetValue,
  unitId,
  showDescription,
}: InlineKeyResultProps) => {
  return (
    <div className='component-inlinekeyresult'>
      <CircularProgress value={progress > 1 ? 100 : progress} />
      <div className='inlinekeyresult-details'>
        <h4>
          <FormattedMessage
            id={'objectives.modal.detail.keyresult.id'}
            values={{ id: index + 1 }}
          />
          <span className='inlinekeyresult-name'>
            {name !== '' ? (
              name
            ) : (
              <FormattedMessage id={'objectives.modal.detail.keyresult.notitle'} />
            )}
          </span>
        </h4>
        {
          currentValue ? 
            <p>
              <span>Current value: </span>
              {currentValue !== null
                ? currentValueWithUnits(parseFloat(currentValue), parseInt(unitId || '0'))
                : '0.0'}
              <br />
              {
                targetValue !== null && targetValue !== undefined ? 
                  <span>Target value: {currentValueWithUnits(parseFloat(targetValue), parseInt(unitId || '0'))}</span>
                  : <></>
              }
            </p> : <></>
        }
        {showDescription && description ? <KrDescription description={description} /> : <></>}
      </div>
    </div>
  );
};

export default InlineKeyResult;
