import axios, { AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';
import { store } from '@store/store';
import { setInvalidTokenError } from '@store/Errors/errors.actions';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

const TgdClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
TgdClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const state = store.getState();
    const jwt = state.User.Info.jwt;

    if (jwt) {
      config.headers['Authorization'] = `Bearer ${jwt}`;
    }

    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
TgdClient.interceptors.response.use(
  function ({ data }: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return data;
  },
  function (error: any) {
    const errorMessage: string = error.response.data.error;
    
    if(errorMessage === "Unauthorized"){
      store.dispatch(setInvalidTokenError(true))
    }
    
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error(error);
    return Promise.reject(error);
  }
);

export default TgdClient;
