import Spinner from '@components/Shared/Spinner/Spinner';
import { Button, Icon, Dialog } from '@material-ui/core';
import { RingNotificationsModel } from '@models/notifications.models';
import { RootState } from '@store/rootReducer';
import { NOTIFICATIONS_STATUS } from 'lib/enums/notifications/notifications.enums';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import IndividualNotification from '../IndividualNotification/IndividualNotification';
import './style.scss';
import { setNewNotification } from '@store/Notifications/notifications.actions';

import { requestRingNotifications } from '@store/Notifications/notifications.actions';
import { updateNotificationStatus } from '@services/notifications.service';

const NotificationsComponent = () => {
  const [showNotificationsList, setShowNotificationsList] = useState<boolean>(false);
  const isNewNotification = useSelector(
    (state: RootState) => state.Notifications.isNewNotification
  );
  const ringNotificationsLoading = useSelector(
    (state: RootState) => state.Notifications.ringNotificationsLoading
  );
  const ringNotificationsData = useSelector(
    (state: RootState) => state.Notifications.ringNotificationsData
  );
  const ringNotificationsError = useSelector(
    (state: RootState) => state.Notifications.ringNotificationsError
  );
  const dispatch = useDispatch();

  const handleRingClick = useCallback(() => {
    setShowNotificationsList((isNotification: boolean) => !isNotification);
    if (!showNotificationsList) {
      dispatch(requestRingNotifications());
    }
  }, [dispatch, showNotificationsList]);

  const handleUpdateNotificationStatus = useCallback(
    async (status: boolean, notificationId: number) => {
      try {
        if (!status) {
          await updateNotificationStatus(notificationId, NOTIFICATIONS_STATUS.read);
        }
        handleRingClick();
      } catch {}
    },
    [handleRingClick]
  );

  const handleRetryGetNotificationsData = useCallback(() => {
    dispatch(requestRingNotifications());
  }, [dispatch]);

  const closeNotificationsMenu = useCallback(() => {
    setShowNotificationsList(false);
  }, []);

  useEffect(() => {
    if (
      ringNotificationsData?.data?.length > 0 &&
      ringNotificationsData?.data?.find(
        (newNotification) => newNotification.status === NOTIFICATIONS_STATUS.unread
      )
    ) {
      dispatch(setNewNotification(true));
    } else {
      dispatch(setNewNotification(false));
    }
  }, [dispatch, ringNotificationsData]);

  return (
    <div className='notifications-component'>
      <div
        className={`icon-wrapper ${showNotificationsList ? 'is-active' : ''}`}
        onClick={handleRingClick}
      >
        <Icon className='notification-icon'>
          notifications<span className={isNewNotification ? 'red-dot' : ''}></span>
        </Icon>
      </div>

      {showNotificationsList && (
        <div className='notifications-wrapper'>
          {ringNotificationsLoading === true && (
            <Dialog
              open={showNotificationsList}
              onClose={closeNotificationsMenu}
              maxWidth='xs'
              className='empty-notifications-menu'
              hideBackdrop
            >
              <div className='notifications-loading'>
                <Spinner size={40} color='primary' thickness={4} />
              </div>
            </Dialog>
          )}
          {ringNotificationsLoading === false && ringNotificationsData?.data.length === 0 && (
            <Dialog
              open={showNotificationsList}
              onClose={closeNotificationsMenu}
              maxWidth='xs'
              className='empty-notifications-menu'
              hideBackdrop
            >
              <span className='notifications-empty'>
                <FormattedMessage id='general.notifications.empty.label' />
              </span>
            </Dialog>
          )}
          {ringNotificationsLoading === false && ringNotificationsError && (
            <Dialog
              open={showNotificationsList}
              onClose={closeNotificationsMenu}
              maxWidth='xs'
              className='error-notifications-menu'
              hideBackdrop
            >
              <span className='notifications-error'>
                <FormattedMessage id='general.notifications.error.label' />
                <Button onClick={handleRetryGetNotificationsData}>
                  <FormattedMessage id='global.label.tryAgain' />
                </Button>
              </span>
            </Dialog>
          )}
          {ringNotificationsLoading === false &&
            ringNotificationsError === false &&
            ringNotificationsData?.data?.length > 0 && (
              <Dialog
                open={showNotificationsList}
                onClose={closeNotificationsMenu}
                maxWidth='xs'
                className='notifications-menu'
                hideBackdrop
              >
                {ringNotificationsData?.data?.map(
                  (notification: RingNotificationsModel, index: number) => (
                    <IndividualNotification
                      user={`
                    ${notification.author !== null ? notification.author.first_name : ''}
                    ${notification.author !== null ? notification.author.last_name : ''}
                  `}
                      dispatch={dispatch}
                      keyDescription={notification.tgd_notification_template.key}
                      date={moment(notification.created_at).format('MM/DD/YYYY')}
                      entity={notification.tgd_notification_template.entity}
                      key={index}
                      status={notification.status === NOTIFICATIONS_STATUS.read ? true : false}
                      handleUpdateStatus={handleUpdateNotificationStatus}
                      notificationId={notification.id}
                      // Only show 56 characters of the total length of the OKR name
                      OKR_name={
                        notification.detail && notification.detail.tgd_objective
                          ? notification.detail.tgd_objective.name.slice(0, 56)
                          : ''
                      }
                      // Only show 56 characters of the total length of the cultural attribute name
                      culturalAttribute_name={
                        notification.detail && notification.detail.name
                          ? notification.detail.name.slice(0, 56)
                          : ''
                      }
                      details={notification.detail}
                    />
                  )
                )}
              </Dialog>
            )}
        </div>
      )}
    </div>
  );
};

export default NotificationsComponent;
