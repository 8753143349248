import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useState } from 'react';
import './styles.scss';

export interface menuDotsOptions {
  messageId: string;
  icon?: React.ReactNode;
  keyName: string;
}

interface MenuDotsProps {
  menuOptions: menuDotsOptions[];
  id: string;
  onOptionSelected: (keyname: string) => void;
}

const styleForMenu = {
  border: '1px solid #d3d4d575',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
}

const StyledMenu = withStyles({ paper: styleForMenu })(({ id, open, ...props }: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={open}
    id={id}
    {...props}
  />
));

const MenuDots = (props: MenuDotsProps) => {
  const {
    id,
    menuOptions,
    onOptionSelected
  } = props;
  const [openMenu, setOpenMenu] = useState(null);

  const handleClick = (event: any) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleSelection = useCallback((keyName: string) => {
    onOptionSelected(keyName)
    handleClose()
  }, [onOptionSelected])

  return (
    <div className='component-MenuDots'>
      {menuOptions && menuOptions.length > 0 &&
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className="menuDotsIcon"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id={id}
            anchorEl={openMenu}
            keepMounted
            open={Boolean(openMenu)}
            onClose={handleClose}
          >
            {menuOptions && menuOptions.map((option) =>
            (
              <MenuItem key={option.messageId} onClick={() => handleSelection(option.keyName)}>
                <ListItemIcon children={option.icon} />
                <ListItemText secondary={<FormattedMessage id={option.messageId} />} />
              </MenuItem>
            )
            )
            }
          </StyledMenu>
        </>}
    </div >
  );
};

export default MenuDots;