import LoginContainer from 'pwa/containers/Login/LoginContainer/LoginContainer';
import './styles.scss';

const LoginScreen = ()=> {

  return (
    <div className='login-mobile-screen'>
      <LoginContainer/>
    </div>
  );
}

export {LoginScreen as MobileLoginScreen};
