import ObjectivesMainActionsContainer from "@containers/Objetives/ObjectivesMainActionsContainer/ObjectivesMainActionsContainer"
import { Dialog } from "@material-ui/core"
import { CREATE_OBJECTIVES_ACTIONS } from "lib/enums/objectives.enum"

interface ObjectivesMainActionsModalProps {
  action: CREATE_OBJECTIVES_ACTIONS
  isOpen: boolean
  objectiveId?: number
  getObjectives: () => void
  handleClose: () => void
}


const ObjectivesMainActionsModal = (props: ObjectivesMainActionsModalProps) => {
  const { action, isOpen, objectiveId } = props;
  const { handleClose, getObjectives } = props;

  return(
    <Dialog
			open={isOpen}
			className="objectives-main-actions-modal"
			onClose={handleClose}
			disableBackdropClick
			fullScreen
		>
      <ObjectivesMainActionsContainer 
        action={action}
        handleClose={handleClose}
        objectiveId={objectiveId}
        getObjectives={getObjectives}
      />
    </Dialog>
  )
}

export default ObjectivesMainActionsModal;