import { FormattedMessage } from "react-intl"

const AcceptedInvitationsColumns = () => {
  const columns = [
    {
      name: <FormattedMessage id="invitations.accepted.table.header.name" />,
      selector: 'name',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)',
    },
    {
      name: <FormattedMessage id="invitations.accepted.table.header.role" />,
      selector: 'role',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)'
    },
    {
      name: <FormattedMessage id="invitations.accepted.table.header.jobtitle" />,
      selector: 'job_title',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)',
      hide: 'sm'
    },
    {
      name: <FormattedMessage id="invitations.accepted.table.header.invitedby" />,
      selector: 'invited_by',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)',
      hide: 'sm'
    },
    {
      name: <FormattedMessage id="invitations.accepted.table.header.email" />,
      selector: 'email',
      sortable: true,
      width: '300px',
      hide: 'sm'
    },
    {
      name: <FormattedMessage id="invitations.accepted.table.header.membersince" />,
      selector: 'member_since',
      sortable: false,
      maxWidth: 'calc(100% - 300px / 5)',

    },
  ];

  return columns
}

export default AcceptedInvitationsColumns