import FormikDropdown from "@components/FormikComponents/FormikDropdown/FormikDropdown";
import { ChangeLeaderSchema } from "@formsValidations/formsValidations";
import withDropFastField from "@hocs/withDropFastField";
import { Button, Grid } from "@material-ui/core";
import { OptionsModel } from "@models/helpful.model";
import { ChangeLeaderComponentValuesI, MembersModel, TeamLeadersI } from "@models/members.model";
import { changeLeader, toggleActiveSuspenseLeader, updateMember } from "@services/members.service";
import { ROUTE } from "@utils/routes";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import "./style.scss";
import FormikListener from "@components/FormikComponents/FormikListener/ForrmikListener";

interface ChangeLeaderModalProps {
	data: MembersModel | undefined;
	closeModal: () => void;
	dataToSubmit: any;
	setLoadingOff: () => void;
	setLoadingOn: () => void;
	memberOptions: OptionsModel[];
	loadingMembers: boolean;
	teamLeaders: TeamLeadersI[];
	teamsWhereIsLeader?: { name: string[] }
}

const FormikDropdownFastField = withDropFastField(FormikDropdown);

const ChangeLeaderComponent = (props: ChangeLeaderModalProps) => {
	const { data, closeModal, dataToSubmit, setLoadingOff, setLoadingOn, memberOptions, teamLeaders, teamsWhereIsLeader } =
		props;
	const history = useHistory();
	const idMember: { id: string } = useParams();
	const [currentLeader, setCurrentLeader] = useState({
		first_name: "",
		last_name: "",
	});
	const [isSeeMore, setIsSeeMore] = useState(false);
	const [newLeaderName, setNewLeaderName] = useState({ newLead: "" });
	const [hasMoreThanNineTeams, setHasMoreThanNineTeams] = useState(false);

	const initialValues = useMemo(() => {
		return {
			newLeader: "",
			teams: teamsWhereIsLeader,
		};
	}, [teamsWhereIsLeader]);

	const handleSubmit = useCallback(async () => {
		setLoadingOn();
		if (dataToSubmit.status === "false") {
			await updateMember(data!.id, dataToSubmit);
			await changeLeader({ current: Number(idMember.id), new: Number(newLeaderName.newLead) });
			await toggleActiveSuspenseLeader(idMember.id, dataToSubmit.staus === "false" ? false : true);
			setLoadingOff();
			closeModal();
			history.push(ROUTE.members.path);
		}
	}, [
		closeModal,
		data,
		dataToSubmit,
		history,
		idMember.id,
		newLeaderName,
		setLoadingOff,
		setLoadingOn,
	]);

	const getValues = useCallback((values: ChangeLeaderComponentValuesI) => {
		const { newLeader } = values;
		setNewLeaderName({ newLead: newLeader });
	}, []);

	const toggleView = useCallback(() => {
		setIsSeeMore(!isSeeMore);
	}, [isSeeMore]);

	useEffect(() => {
		teamLeaders.map((team) =>
			team.leaders.filter((leader) =>
				leader.id === idMember.id
					? setCurrentLeader({
						first_name: leader.first_name,
						last_name: leader.last_Name,
					})
					: {}
			))
	}, [idMember.id, teamLeaders]);


	useEffect(() => {
		if (teamsWhereIsLeader?.name.length! > 10) {
			setHasMoreThanNineTeams(true);
		}
	}, [teamsWhereIsLeader?.name.length]);

	return (
		<Grid container className="change-leader-container">
			<Grid item xs={12} className="icon">
				<h1 className="modal-header">
					<FormattedMessage id="modal.change.leader.header" />
				</h1>
			</Grid>
			<Grid item xs={12} className="message">
				<p>
					<FormattedMessage id="modal.change.leader.message" />
				</p>
			</Grid>

			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={ChangeLeaderSchema}
				validateOnMount
			>

				<Grid item xs={12}>
					<Form>
						<FormikListener getValues={getValues} />
						<Grid container className="change-leader-form">
							<Grid item xs={6}>
								<p className="actual-leader">
									<FormattedMessage id="modal.change.leader.actual.team.label" />
								</p>
								<label>{`${currentLeader.first_name}  ${currentLeader.last_name}`}</label>
							</Grid>
							<Grid item xs={6}>
								<p>
									<FormattedMessage id="modal.change.leader.new.leader.label" />
								</p>
								<Grid item xs={12}>
									<FormikDropdownFastField
										name="newLeader"
										options={memberOptions.filter(
											(member) => member.value.toString() !== idMember.id
										)}
										cleanFilter={false}
										isSearchable={false}
										viewMode={false}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Form>
					<Grid>
						<Grid container>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								className={`teams-wrapper ${isSeeMore ? "see-more-teams" : ""}  ${hasMoreThanNineTeams ? "has-more-than-nine-teams" : ""
									}`}
							>
								{teamsWhereIsLeader !== undefined && teamsWhereIsLeader.name!.map((team, index) => (
									<span key={`${team}-${index}`}>{team}</span>
								))}
								{teamsWhereIsLeader!.name.length > 10 ? (
									<button onClick={toggleView}>
										<svg
											width="15"
											height="8"
											viewBox="0 0 18 11"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className={`${isSeeMore ? "toggle-arrow" : ""}`}
										>
											<path
												d="M1.5 9.32812L9 1.82812L16.5 9.32812"
												stroke=""
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										{`${isSeeMore ? " See less" : " See more"}`}
									</button>
								) : (
									""
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} className="bottom">
						<div className="footer">
							<Button
								className={`modal-button ${newLeaderName.newLead === "" ? "disabled" : ""}`}
								type="submit"
								onClick={handleSubmit}
								disabled={
									newLeaderName.newLead === "" || newLeaderName.newLead === undefined ? true : false
								}
							>
								<FormattedMessage id="modal.change.leader.button.label" />
							</Button>
						</div>
					</Grid>
				</Grid>
			</Formik>
		</Grid>
	);
};

export default ChangeLeaderComponent;
