import { GqlGetTgdObjRoleUsers, ObjectiveGet } from '@models/objetives.model';

// Return true if given date is expired
export const checkOutOfDate = (date: string) => (new Date() > new Date(date) ? true : false);

export const getOwnerNameObjective = (rolesUsers?: GqlGetTgdObjRoleUsers[]) => {
  if (rolesUsers) {
    const owner = rolesUsers.find((role: GqlGetTgdObjRoleUsers) => role.tgd_role.name === 'Owner');
    return `${owner?.user.first_name} ${owner?.user.last_name}`;
  }
  return '';
};

export enum OBJECTIVE_ROLE {
  owner = 1,
  coach = 2,
  collaborator = 3,
  leader = 4,
}

export enum OBJECTIVE_STATUS {
  draft = 1,
  active = 2,
  closed = 3,
  archived = 4,
  deleted = 5,
}

export enum OBJECTIVE_LOG_TYPE {
  creation = 'obj-creation',
  assignment = 'obj-assignment',
  status = 'obj-status',
  progress = 'obj-progress',
  edit = 'obj-edit',
}

export enum OBJECTIVE_LOG_STATUS {
  active = 'Active',
  closed = 'Closed',
}

export const OBJECTIVE_STATUS_DESCRIPTION = {
  [`${OBJECTIVE_STATUS.active}`]: 'Active',
  [`${OBJECTIVE_STATUS.closed}`]: 'Closed',
  [`${OBJECTIVE_STATUS.draft}`]: 'Draft',
  [`${OBJECTIVE_STATUS.archived}`]: 'Archived',
  [`${OBJECTIVE_STATUS.deleted}`]: 'Deleted',
};

export enum OKR_TYPES {
  individual = 3,
  team = 2,
  organization = 1,
}

export enum OKR_ALIGNED {
  simple = 0,
  pillar = 1,
  another = 2,
}

export enum ACTIONS_CATALOGS {
  ADD = 'add',
  ADD_ORGANIZATION_OKR = 'add_organization_okr',
  EDIT = 'edit',
  EDIT_OWNER = 'edit_owner',
  EDIT_STATUS = 'edit_status',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  SHOW_ARCHIVED = 'show_archived',
}

export const Tags = {
  Tags_xs: 1,
  Tags_md: 2,
  Tags_lg: 3,
};

export enum Windows_Size {
  small = 768,
  medium = 992,
  large = 1340,
}

/**
 * currentValueWithUnits
 * Description: Function created to show current value with measure unit as a string
 * Author: Humberto Santana
 */
export const currentValueWithUnits = (currentValue: number, unit: number) => {
  switch (unit) {
    case 1:
      return currentValue + ' units';
    case 2:
      return currentValue + '%';
    case 3:
      return '$ ' + currentValue;

    default:
      return currentValue;
  }
};

/**
 * validateAbleToSeeObjective
 * Description:
 * Author: Humberto Santana
 */
export const validateAbleToSeeObjective = (
  objectiveInfo: ObjectiveGet,
  userId: string,
  status: string
) => {
  let isValid = false;
  if (objectiveInfo !== null && userId !== null && status !== null) {
    objectiveInfo.tgd_obj_role_users.forEach((element) => {
      if (element.user.id === userId) {
        if (parseInt(element.tgd_role.id) === OBJECTIVE_ROLE.owner) {
          isValid = true;
        } else {
          if (
            parseInt(element.tgd_role.id) === OBJECTIVE_ROLE.coach ||
            parseInt(element.tgd_role.id) === OBJECTIVE_ROLE.collaborator
          ) {
            if (parseInt(status) === OBJECTIVE_STATUS.archived) {
              isValid = false;
            } else {
              isValid = true;
            }
          }
        }
      }
    });
  }

  return isValid;
};

export const validateAbleToCommentObjective = (
  objectiveInfo: ObjectiveGet,
  userId: string,
  status: string
) => {
  let isValid = false;
  if (objectiveInfo !== null && userId !== null && status !== null) {
    objectiveInfo.tgd_obj_role_users.forEach((element) => {
      if (element.user.id === userId) {
        if (parseInt(element.tgd_role.id) === OBJECTIVE_ROLE.owner && parseInt(status) === OBJECTIVE_STATUS.active) {
          isValid = true;
        } else {
          if (
            parseInt(element.tgd_role.id) === OBJECTIVE_ROLE.coach ||
            parseInt(element.tgd_role.id) === OBJECTIVE_ROLE.collaborator
          ) {
            if (parseInt(status) !== OBJECTIVE_STATUS.active) {
              isValid = false;
            } else {
              isValid = true;
            }
          }
        }
      }
    });
  }
  return isValid;
};