import { createSkillsAptitudes } from "@services/hrteam.service"
import { Dispatch } from "redux"
import { CreateSkillsAptitudesPost, HRTeamTabState, SkillsAptitudesTabState } from "./hrteam.model"

export const SET_HR_TEAM_TAB_STATE =  "SET_HR_TEAM_TAB_STATE"
export const SET_SKILLS_APTITUDES_TAB = "SET_SKILLS_APTITUDES_TAB"
export const SET_LOADING_SKILLS_APTITUDES = "SET_LOADING_SKILLS_APTITUDES"
export const SET_SKILLS_APTITUDES_INFO = "SET_SKILLS_APTITUDES_INFO"

export const setHRTeamTabState = (autoState: HRTeamTabState) => {
    return {
        type: SET_HR_TEAM_TAB_STATE,
        payload: autoState,
    }
}

export const setSkillsAptitudesTab = (autoState : SkillsAptitudesTabState) => {
    return {
        type: SET_SKILLS_APTITUDES_TAB,
        payload: autoState
    }
}

export const setIsSKillsAptitudesCreateLoading = (isLoading: boolean) => {
    return {
      type: SET_LOADING_SKILLS_APTITUDES,
      payload: isLoading
    }
  }

export const createSkillsAptitudesAction = (
    data: CreateSkillsAptitudesPost,
    handleSuccess: () => void
) => async(dispatch: Dispatch) => {
    dispatch(setIsSKillsAptitudesCreateLoading(true))
    try{
        await createSkillsAptitudes(data)
        handleSuccess()
        dispatch(setIsSKillsAptitudesCreateLoading(false))
    } catch{
        dispatch(setIsSKillsAptitudesCreateLoading(false))
    }
}

