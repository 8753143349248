import { Dialog, DialogContent, Grid } from "@material-ui/core";
import "./styles.scss";

interface TegduModalProps {
	isOpen: boolean;
	handleClose: () => void;
	component: JSX.Element;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

const TegduModal = (props: TegduModalProps) => {
	const { isOpen, handleClose, component, maxWidth = "sm" } = props;

	return (
		<Dialog
			open={isOpen}
			className="tegdu-modal"
			onClose={handleClose}
			disableBackdropClick
			maxWidth={maxWidth}
		>
			<DialogContent className="tegdu-modal-content">
				<Grid container className="tegdu-modal-container">
					<button
						onClick={() => {
							handleClose();
						}}
					>
						X
					</button>
					{component}
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default TegduModal;
