import { SET_NEW_NOTIFICATION, SET_RING_NOTIFICATIONS_DATA, SET_RING_NOTIFICATIONS_ERROR, SET_RING_NOTIFICATIONS_LOADING, SET_SOCKET_DATA } from "./notifications.actions";
import { NotificaionsActions, NotificationsState } from "./notifications.model";

const initialState: NotificationsState = {
  isNewNotification: false,
  ringNotificationsLoading: false,
  ringNotificationsError: false,
  ringNotificationsData: { data: [] },
  socket: {}
}


const notificationsReducer = (state: NotificationsState = initialState, actions: NotificaionsActions): NotificationsState => {
  switch (actions.type) {
    case SET_NEW_NOTIFICATION:
      return {
        ...state,
        isNewNotification: actions.payload
      }
    case SET_RING_NOTIFICATIONS_LOADING:
      return {
        ...state,
        ringNotificationsLoading: actions.payload
      }
    case SET_RING_NOTIFICATIONS_ERROR:
      return {
        ...state,
        ringNotificationsError: actions.payload
      }
    case SET_RING_NOTIFICATIONS_DATA:
      return {
        ...state,
        ringNotificationsData: actions.payload
      }
    case SET_SOCKET_DATA:
      return {
        ...state,
        socket: actions.payload
      }
    default:
      return state
  }
}

export default notificationsReducer;