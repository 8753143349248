import { SET_ADD_MEMBERS_LOADING, SET_INVITATIONS_INFO, SET_INVITATIONS_TAB_STATE, SET_LOADING_INVITATIONS, SET_ONBOARDING_INVITATION_INFO, SET_ONBOARDING_INVITATION_INFO_LOADING, SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING } from "./invitations.action";
import { InvitationsActions, InvitationsState, InvitationsTabStates } from "./invitations.model";

const initialState: InvitationsState = {
  isAddMembersLoading: false,
  validatingLandingCode: {
    isLoading: true,
    validatedData: null
  },
  submitOnboardingInfo: {
    isLoading: false
  },
  invitationsActiveTabState: InvitationsTabStates.requests,
  invitations: {
    data: null
  },
  isInvitationsLoading: {
    isLoading: false,
  }
}

export const invitationsReducer = (
  state: InvitationsState = initialState,
  actions: InvitationsActions
): InvitationsState => {
  switch (actions.type) {
    case SET_ADD_MEMBERS_LOADING:
      return {
        ...state,
        isAddMembersLoading: actions.payload
      }
    case SET_ONBOARDING_INVITATION_INFO_LOADING:
      return {
        ...state,
        validatingLandingCode: {
          ...state.validatingLandingCode,
          isLoading: actions.payload
        }
      }
    case SET_ONBOARDING_INVITATION_INFO:
      return {
        ...state,
        validatingLandingCode: {
          ...state.validatingLandingCode,
          validatedData: actions.payload
        }
      }
    case SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING:
      return {
        ...state,
        submitOnboardingInfo: {
          ...state.submitOnboardingInfo,
          isLoading: actions.payload
        }
      }
    case SET_INVITATIONS_TAB_STATE:
      return {
        ...state,
        invitationsActiveTabState: actions.payload
      }
    case SET_LOADING_INVITATIONS:
      return {
        ...state,
        isInvitationsLoading: {
          ...state.isInvitationsLoading,
          isLoading: actions.payload
        }
      }
    case SET_INVITATIONS_INFO:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          data: actions.payload
        }
      }
    default:
      return state
  }
}