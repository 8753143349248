import React, { FunctionComponent, useCallback, useState } from 'react';
import clsx from 'clsx';
import CustomToolTip from './CustomToolTip';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { injectIntl, IntlShape } from 'react-intl';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { menuOptions } from './menuOptions';
import { menuOptionsFooter } from './menuOptionsFooter';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { setSelectedTabAction } from '@store/Organization/selectedTab/selectedTab.action';
import { logout } from '@services/user.service';
import { userLogout } from '@store/User/Info/info.actions';
import { ROUTE } from '@utils/routes';
import { clearLoggedStatus } from '@utils/localStorageAux';
import LettersCircle from '@components/Shared/LettersCircle/LettersCircle';
import TourSideBar from './TourSideBar';

interface SidebarProps {
  screen: string;
  intl: IntlShape;
  isResponsiveMenuOpen: boolean;
  closeResponsiveMenu: () => void;
}

const Sidebar: FunctionComponent<SidebarProps> = ({
  children,
  screen,
  intl,
  isResponsiveMenuOpen,
  closeResponsiveMenu,
}) => {
  const menuOptionsRole = useSelector((state: RootState) => state.User.Info.menuOptions);
  const { first_name, last_name } = useSelector((state: RootState) => state.User.Info.user);
  const userId = useSelector((state: RootState) => state.User.Info.user.id);
  const socket = useSelector((state: RootState) => state.Notifications.socket);
  const tourIsOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const [open, setOpen] = useState(true);

  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const history = useHistory();
  const dispatch = useDispatch();

  const redirect = useCallback(
    (href: string) => {
      if (href === ROUTE.organization.path) {
        dispatch(setSelectedTabAction(0));
      }
      history.push(href);
    },
    [dispatch, history]
  );

  const redirectResponsiveMenu = useCallback(
    (href: string) => {
      if (href === ROUTE.organization.path) {
        dispatch(setSelectedTabAction(0));
      }
      history.push(href);
      closeResponsiveMenu();
    },
    [dispatch, history, closeResponsiveMenu]
  );

  const onLogout = useCallback(async () => {
    try {
      await logout();
      clearLoggedStatus();
      dispatch(userLogout());
      if (socket !== undefined && userId) {
        socket.emit('unsubscribe-notifications', userId);
      }
    } catch {}
  }, [dispatch, socket, userId]);

  return (
    <div className={'sidebar__content'}>
      {/* This component should appear when the Tour is Open */}
      {tourIsOpen && (
        <TourSideBar
          open={open}
          menuOptionsRole={menuOptionsRole}
          pathname={location.pathname}
          screen={screen}
          intl={intl}
        />
      )}
      {/* HERE BEGINS SIDEBAR SECTION */}
      <Drawer
        onClick={handleDrawerOpen}
        variant='permanent'
        classes={{
          paper: clsx(`sidebar__drawer ${open ? 'open' : 'close'}`),
        }}
      >
        <List>
          {menuOptions.map(
            (element, index) =>
              menuOptionsRole.find((option) => option.key_name === element.href.slice(1)) && (
                <CustomToolTip
                  title={intl.formatMessage({ id: element.title })}
                  placement='right'
                  disableHoverListener={open ? true : false}
                  key={index}
                >
                  <div
                    className={`menu__options ${
                      location.pathname === element.href || location.pathname.slice(0, 11) === element.href.slice(0, 11)
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <ListItem
                      button
                      divider
                      className={`list__item ${screen === element.title && 'selected'}`}
                      key={element.title}
                      onClick={() => redirect(element.href)}
                    >
                      <Icon className='icon'>{element.icon}</Icon>
                      <ListItemText
                        className={`sidebar__item__text ${!open ? 'opacity' : ''}`}
                        primary={intl.formatMessage({ id: element.title })}
                      />
                    </ListItem>
                  </div>
                </CustomToolTip>
              )
          )}
        </List>
        <div className='sidbar__footer'>
          <List>
            {menuOptionsFooter.map(
              (element, index) =>
                menuOptionsRole.find(
                  (option) => option.key_name === element.href.slice(1) || element.href === ROUTE.login.path
                ) && (
                  <CustomToolTip
                    title={intl.formatMessage({ id: element.title })}
                    placement='right'
                    disableHoverListener={open ? true : false}
                    key={index}
                  >
                    <div className={`menu__options ${location.pathname === element.href ? 'selected' : ''}`}>
                      <ListItem
                        button
                        divider
                        className={`list__item ${screen === element.title && 'selected'}`}
                        key={element.title}
                        onClick={element.title === 'sidebar.logout' ? () => onLogout() : () => redirect(element.href)}
                      >
                        <Icon className='icon'>{element.icon}</Icon>
                        <ListItemText
                          className={`sidebar__item__text ${!open ? 'opacity' : ''}`}
                          primary={intl.formatMessage({ id: element.title })}
                        />
                      </ListItem>
                    </div>
                  </CustomToolTip>
                )
            )}
          </List>
          <div className='logo__container'>
            <img src='/images/tegdu_gray.png' alt='logo' />
          </div>
        </div>
      </Drawer>

      <Drawer open={isResponsiveMenuOpen} anchor='right' onClose={closeResponsiveMenu} className='sidebar__responsive'>
        <div className='sidebar-resp-content'>
          <div className='username-container' onClick={() => redirectResponsiveMenu('account')}>
            <div className='circle'>
              <LettersCircle name={`${first_name} ${last_name}`} size='md' />
            </div>
            <div className='name'>
              <h4>{`${first_name} ${last_name}`}</h4>
            </div>
          </div>

          <div className='options'>
            <List>
              {menuOptions.map(
                (element, index) =>
                  menuOptionsRole.find((option) => option.key_name === element.href.slice(1)) && (
                    <CustomToolTip
                      title={intl.formatMessage({ id: element.title })}
                      placement='right'
                      disableHoverListener={open ? true : false}
                      key={index}
                    >
                      <div className={`menu__options ${location.pathname === element.href ? 'selected' : ''}`}>
                        <ListItem
                          button
                          divider
                          className={`list__item ${screen === element.title && 'selected'}`}
                          key={element.title}
                          onClick={() => redirectResponsiveMenu(element.href)}
                        >
                          {/* <FontAwesomeIcon className="icon" icon={faCoffee} /> */}
                          <Icon className='icon'>{element.icon}</Icon>
                          <ListItemText
                            className={`sidebar__item__text `}
                            primary={intl.formatMessage({ id: element.title })}
                          />
                        </ListItem>
                      </div>
                    </CustomToolTip>
                  )
              )}
            </List>
          </div>

          <div className='footer'>
            <List>
              {menuOptionsFooter.map(
                (element, index) =>
                  menuOptionsRole.find(
                    (option) => option.key_name === element.href.slice(1) || element.href === ROUTE.login.path
                  ) && (
                    <CustomToolTip
                      title={intl.formatMessage({ id: element.title })}
                      placement='right'
                      disableHoverListener={open ? true : false}
                      key={index}
                    >
                      <div className={`menu__options ${location.pathname === element.href ? 'selected' : ''}`}>
                        <ListItem
                          button
                          divider
                          className={`list__item ${screen === element.title && 'selected'}`}
                          key={element.title}
                          onClick={
                            element.title === 'sidebar.logout'
                              ? () => onLogout()
                              : () => redirectResponsiveMenu(element.href)
                          }
                        >
                          <Icon className='icon'>{element.icon}</Icon>
                          <ListItemText
                            className={`sidebar__item__text ${!open ? 'opacity' : ''}`}
                            primary={intl.formatMessage({ id: element.title })}
                          />
                        </ListItem>
                      </div>
                    </CustomToolTip>
                  )
              )}
            </List>
            <div className='logo__container'>
              <img src='/images/tegdu_gray.png' alt='logo' />
            </div>
          </div>
        </div>
      </Drawer>
      {/* THIS IS THA PLACE FOR RIGHT SIDE OF THE SIDEBAR */}
      <main className={`content ${open ? 'open' : 'close'}`}>
        <div className='main-container'>{children}</div>
      </main>
    </div>
  );
};

export default injectIntl(Sidebar);
