import { DotProps } from "react-multi-carousel";
import "./styles.scss"

interface CustomDotProps extends DotProps {
  onClick?: () => void;
}

const CustomDot = ({ onClick, ...rest }: CustomDotProps) => {
  return (
    <div
      className={rest?.active ? "active-dot" : "inactive-dot"}
      onClick={() => onClick && onClick()}
    />
  );
};

export default CustomDot;
