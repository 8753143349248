import { FormikDropdownProps } from "@components/FormikComponents/FormikDropdown/FormikDropdown";
import { FastField } from "formik";
import useFormikDropdownShouldUpdate from "hooks/useFormikDropDownShouldUpdate";
import useFormikShouldUpdate from "hooks/useFormikShouldUpdate";

type FormikDropdownPropsHOC = Omit<FormikDropdownProps, 'meta' | 'setFieldValue'>

const withDropFastField = (WrappedComponent: (props: FormikDropdownProps) => JSX.Element) => {
   const WithDropFastField = (props: FormikDropdownPropsHOC) => {
      const { name, viewMode, onChange, measure, withComplexShouldUpdate, isLoading, isDisabled, options } = props
      const shouldUpdate = useFormikShouldUpdate(viewMode, measure) 
      const specialUpdate = useFormikDropdownShouldUpdate(viewMode, 
         isLoading ? isLoading : false , 
         isDisabled ? isDisabled :  false, options)

      return(
         <FastField name={name} shouldUpdate={withComplexShouldUpdate ? specialUpdate : shouldUpdate}>
            {({field, meta, form} : any) => (
               <WrappedComponent 
                  {...props}
                  meta={meta}
                  value={field.value}
                  name={field.name}
                  onChange={onChange ? onChange : undefined}
                  setFieldValue={form.setFieldValue}
               />
            )}
         </FastField>
      )
   }
   return WithDropFastField;
}

export default withDropFastField;