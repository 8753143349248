import { ObjectivesMenuDotOptions, ObjectivesMenuDotOptionsResponse } from "@components/Objetives/ObjectivesMenuDotOptions/ObjectivesMenuDotOptions";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import { OBJECTIVES_ACTIONS, OBJECTIVES_STATUS_STRING } from "lib/enums/objectives.enum";
import { FormattedMessage } from "react-intl";
import './styles.scss';

interface ObjectivesActionsBottomContainerProps {
  actionsInTable?: boolean
  objectiveStatus: OBJECTIVES_STATUS_STRING
  specialCoach?: boolean
  handleMenuSelection: (keyName: OBJECTIVES_ACTIONS) => void
}

const ObjectivesActionsBottomContainer = (props: ObjectivesActionsBottomContainerProps) => {
  const { objectiveStatus, specialCoach, actionsInTable, handleMenuSelection } = props;

  return(
    <div className="objectives-actions-bottom-container">
      {ObjectivesMenuDotOptions(parseInt(objectiveStatus), specialCoach, actionsInTable).map((option: ObjectivesMenuDotOptionsResponse, index) => (
        <div className="objectives-actions-bottom-row"  key={index} onClick={() => handleMenuSelection(option.keyName)}>
          <ListItemIcon children={option.icon} />
          <ListItemText secondary={<FormattedMessage id={option.messageId} />} />
        </div>
      ))}
    </div>
  )
}

export default ObjectivesActionsBottomContainer;