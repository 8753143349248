import { OptionsModel } from '@models/helpful.model';
import { MembersModel } from '@models/members.model';
export interface MembersFilters {
  searchValue: string;
  roleValue: string;
  teamValue: string;
  statusValue: string;
}

const searchFilter = (data: MembersModel[], searchFilter: string) => {
  return data.filter(
    (items) =>
      items.first_name.toLowerCase().includes(searchFilter.toLowerCase()) ||
      items.last_name.toLowerCase().includes(searchFilter.toLowerCase())
  );
};

const roleFilter = (data: MembersModel[], roleFilter: string) => {
  return data.filter((items) => items.role?.id.toString().includes(roleFilter));
};

const teamsFilter = (data: MembersModel[], teamsFilter: string) => {
  let newInfo: MembersModel[] = [];
  for (let k = 0; k < data.length; k++) {
    for (let j = 0; j < data[k].tgd_team_role_users.length; j++) {
      if (data[k].tgd_team_role_users[j].id !== undefined && data[k].tgd_team_role_users[j].tgd_team !== null && data[k].tgd_team_role_users[j].active === true) {
        if (data[k].tgd_team_role_users[j].tgd_team.toString() === teamsFilter) {
          newInfo.push(data[k]);
        }
      }
    }
  }
  return newInfo;
};

const statusFilter = (data: MembersModel[], statusFilter: string) => {
  return data.filter((items) => items.active.toString().includes(statusFilter));
};

export const filterMembersData = (data: MembersModel[], filters: MembersFilters) => {
  let finalData: MembersModel[] = data;
  if (filters.searchValue === '' && filters.roleValue === '' && filters.teamValue === '' && filters.statusValue === '') {
    return data;
  } else {
    for (let k = 0; k < 4; k++) {
      if (k === 0 && filters.searchValue !== '') {
        finalData = searchFilter(finalData, filters.searchValue);
      }
      if (k === 1 && filters.roleValue !== '') {
        finalData = roleFilter(finalData, filters.roleValue);
      }
      if (k === 2 && filters.teamValue !== '') {
        finalData = teamsFilter(finalData, filters.teamValue);
      }
      if (k === 3 && filters.statusValue !== '') {
        finalData = statusFilter(finalData, filters.statusValue);
      }

    }
    return finalData;
  }
};

export const alphabeticalOrderArray = (data: OptionsModel[]) => {
  if (data) {
    const arraySorted = data.sort((a, b) => {
      return a.label.localeCompare(b.label, 'es', { sensitivity: 'base' });
    })
    return arraySorted
  }
  return data
};
