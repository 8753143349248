import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  FormikUpdateKeyResultsObject,
  KeyResultsUpdate,
  ParseUpdateKeyResult,
  UpdateKeyResults,
} from "@models/objetives.model";
import {
  getObjectiveById, updateKeyResults,
} from "@services/objectives.service";
import useCheckScroll from "hooks/useCheckScroll";
import useToastNotification from "hooks/useToastNotification";
import Spinner from "@components/Shared/Spinner/Spinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import ObjectivesKRSForm from "../ObjectivesKRSForm/ObjectivesKRSForm";
import { calculateGeneralProgress } from "@utils/krProgress";
import "./styles.scss";
import moment from "moment";
interface ObjectivesModalEditProps {
  open: boolean;
  onClose?: () => void;
  nameObjective?: string;
  id?: number;
  due?: string;
  progress?: number;
  refetchObjectives:()=>void;
}

const ObjectivesModalEdit = ({
  open,
  onClose,
  nameObjective,
  id,
  due,
  progress,
  refetchObjectives
}: ObjectivesModalEditProps) => {

  const { isTopScroll, checkIfIsTopAnyElement } = useCheckScroll();
  const [loading, setLoading] = useState(false);
  const [objective, setObjective] = useState<UpdateKeyResults | null>(null);
  const [, /*error*/ setError] = useState(false);
  const [, /*savingData*/ setSavingData] = useState(false);
  const [/*sameValues, */, setSameValues] = useState(false);
  const [initialKrValues, setInitialKrValues] = useState<number[]>([]);
  const showToastNotification = useToastNotification();
  const [formValues, setFormValues] = useState<any[]>([]);
  const [isValidForm, setIsValidForm] = useState(false);
  const [, /*generalProgress*/ setGeneralProgress] = useState<number>(0.0);

  const getObjectiveData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getObjectiveById(id || 0);
      setObjective(data.tgdObjective);
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  }, [id]);

  const keyResults: ParseUpdateKeyResult = useMemo(() => {
    if (objective !== null) {
      const parsed_key_results: ParseUpdateKeyResult = { key_results: [] };
      for (let k = 0; k < objective?.tgd_key_results.length; k++) {
        parsed_key_results.key_results.push({
          id: objective?.tgd_key_results[k].id,
          name: objective?.tgd_key_results[k].name,
          description: objective?.tgd_key_results[k].description,
          init_value: objective?.tgd_key_results[k].init_value,
          target_value: objective?.tgd_key_results[k].target_value,
          current_progress: objective?.tgd_key_results[k].current_progress,
          current_value: objective?.tgd_key_results[k].current_value,
          unit: objective?.tgd_key_results[k].unit
            ? parseInt(objective?.tgd_key_results[k].unit.id)
            : 1,
          new_progress: "0.0",
        });
      }
      return parsed_key_results;
    } else {
      return { key_results: [] };
    }
  }, [objective]);

  const fixedKeyResultValues = useMemo(() => {
    const values = [];
    const onlyValues = [];
    if (keyResults !== undefined && objective !== null) {
      if (keyResults.key_results.length !== 0) {
        for (let k = 0; k < keyResults.key_results.length; k++) {
          values.push({
            init_value: objective.tgd_key_results[k].init_value,
            target_value: objective.tgd_key_results[k].target_value,
            current_progress: objective.tgd_key_results[k].current_progress,
            current_value:
              objective.tgd_key_results[k].current_value === null
                ? objective.tgd_key_results[k].init_value
                : objective.tgd_key_results[k].current_value,
          });

          onlyValues.push(
            objective.tgd_key_results[k].current_value === null
              ? objective.tgd_key_results[k].init_value
              : objective.tgd_key_results[k].current_value
          );
        }
      }
      setInitialKrValues(onlyValues);
      return values;
    } else {
      return [];
    }
  }, [keyResults, objective]);

  const onSubmitForm = useCallback(
    async (info: any) => {
      const new_values: FormikUpdateKeyResultsObject[] = info.keyResults;
      const key_results: KeyResultsUpdate[] = info.key_results;
      if (info.keyResults !== undefined) {
        setSavingData(true);
        try {
          await updateKeyResults(new_values, key_results);
          // onClose();
          refetchObjectives();
          showToastNotification("general.modal.success.changes", false);
          setSavingData(false);
        } catch {
          // onClose();
          showToastNotification("general.modal.error.label", true);
          setSavingData(false);
        }
      }
    },
    [refetchObjectives, showToastNotification]
  );

  useEffect(() => {
    if (id !== null) {
      getObjectiveData();
    }
  }, [getObjectiveData, id]);

  const getFormValues = useCallback((info: any) => {
    setFormValues([...info]);
  }, []);

  useEffect(() => {
    if (initialKrValues.length !== 0 && formValues.length !== 0) {
      if (initialKrValues.every((element, index) => element === formValues[index].current_val)) {
        setSameValues(true);
      } else {
        setSameValues(false);
      }
    } else {
      setSameValues(true);
    }
  }, [initialKrValues, formValues]);

  const initialProgress = useMemo(() => {
    if (keyResults !== undefined && keyResults.key_results !== undefined) {
      let progress = 0.0;
      for (let k = 0; k < keyResults.key_results.length; k++) {
        progress = progress + keyResults.key_results[k].current_progress;
      }

      progress = progress / keyResults.key_results.length;

      return progress;
    } else {
      return 0.0;
    }
  }, [keyResults]);

  const updateProgress = useMemo(() => {
    if (formValues.length !== 0) {
      let updatedValues = [];

      for (let k = 0; k < keyResults.key_results.length; k++) {
        updatedValues.push({
          init_value: keyResults.key_results[k].init_value,
          target_value: keyResults.key_results[k].target_value,
          current_value: formValues[k].current_val,
        });
      }

      return calculateGeneralProgress(updatedValues);
    } else {
      return 0.0;
    }
  }, [keyResults, formValues]);

  const getIsValidForm = useCallback((info: boolean) => {
    setIsValidForm(info);
  }, []);

  useEffect(() => {
    if (formValues.length !== 0) {
      setGeneralProgress(updateProgress);
    } else {
      setGeneralProgress(initialProgress);
    }
  }, [formValues, initialProgress, updateProgress]);

  const disabledBtn = useMemo(() => {
    // TODO: Commented for TEGWEB-172
    //if (sameValues === false && isValidForm === true) {
    if (isValidForm === true) {
      return false;
    } else {
      return true;
    }
  }, [isValidForm]);

  return (
    <Dialog
      fullScreen
      scroll="body"
      onScrollCapture={(e: any) => checkIfIsTopAnyElement(e.target?.scrollTop)}
      open={open}
      onClose={onClose}
      className="modal-objective-edit-mobile"
    >
      {loading ? 
        <div className='screen-loader-modal'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>: 
      <>
        {/*HEADER */}
        <div
        className={
          isTopScroll ? "header-modal-mobile" : "header-modal-mobile-no-top"
        }
      >
        <div onClick={onClose} className="close-header">
          <CloseIcon />
        </div>
        <div className="title-objective-header">
          <span>
            <FormattedMessage
              id={"objectives.modal.title.mobile.add.progress"}
            />
          </span>
        </div>
      </div>
      {/* NAME, DESCRIPTION, ID AND PROGRESS */}
      <div className="container-information-objective">
        <div className="container-icon-title-objective">
          <div>
            <img src="/images/maletin.svg" alt="e" />
          </div>
          <span>{nameObjective}</span>
        </div>
        <div className="container-descr-date-progress">
          <div className="content-in">
            <b>
              <FormattedMessage id={"objectives.modal.title.mobile.id"} />
            </b>
            <span>&nbsp;{id}</span>
          </div>
          <div className="content-in">
            <b>
              <FormattedMessage id={"objectives.modal.title.mobile.due"} />
            </b>
            <span>&nbsp;{moment(due).format("MMMM DD")}</span>
          </div>
          <div className="content-in">
            <b>
              <FormattedMessage id={"objectives.modal.title.mobile.progress"} />
            </b>
            <span>&nbsp;{progress} / 1</span>
          </div>
        </div>
      </div>
      {/* KRS FORMS */}
      <div className="container-krs-form">
        <ObjectivesKRSForm
          initialValues={fixedKeyResultValues}
          keyResults={keyResults}
          getFormValues={getFormValues}
          onSubmitForm={onSubmitForm}
          getIsValidForm={getIsValidForm}
          isDisableButton={disabledBtn}
        />
      </div>
      </>
      }
    </Dialog>
  );
};

export default ObjectivesModalEdit;
