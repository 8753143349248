import { OptionsModel } from '@models/helpful.model';
import { PillarFormValues } from '@models/pillars.model';

export const createOption = (label: string, value: any) => ({
  label,
  value: value,
});

export const formatCreatableTags = (values: OptionsModel[]) => {
  let idTags = [];
  for (let k = 0; k < values.length; k++) {
    idTags.push(values[k].value.toString());
  }
  return idTags;
};

export const formatCreatablePillarTags = (values: PillarFormValues[]) => {
  let pillars = [];
  for (let k = 0; k < values.length; k++) {
    pillars.push({
      id: values[k].id,
      pillar: values[k].pillar,
      date: values[k].date,
      owner: values[k].owner,
      coach: values[k].coach,
      tags: formatCreatableTags(values[k].tags),
      description: values[k].description,
    });
  }

  return { pillars: pillars };
};
