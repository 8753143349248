import {SelectedTabState, SelectedTabActionTypes } from './selectedTab.model';
import { SET_SELECTED_TAB } from './selectedTab.action';

const initialState: SelectedTabState = {
  selectedTab: 0
};

export default function aReducer(
  state: SelectedTabState = initialState,
  action: SelectedTabActionTypes
): SelectedTabState {
  switch (action.type) {
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload.selectedTab,
      };
    default:
      return state;
  }
}