import { CulturalAttributesObjectModel } from "@models/culturalAttributes.models"
import { TegduSelectOptionsModel } from "@models/global.model"
import { TimeFramesObjectModel } from "@models/timeframes.models"
import { CulturalAttributesFormObjectModel } from "lib/TegduForms/CulturalAttributesFormUtils"
import { TimeFramesFormObjectModel } from "lib/TegduForms/TimeFramesFormUtils"

export enum PILLARS_TYPES {
  pillar = "pillar",
  principle = "principle",
  value = "value"
}

export const PILLAR_TYPES_DESCRIPTION = {
  [`${PILLARS_TYPES.pillar}`]: "organization.pillar.pillar",
  [`${PILLARS_TYPES.principle}`]: "organization.pillar.principle",
  [`${PILLARS_TYPES.value}`]: "organization.pillar.value",
}

export const translateGetOrganziationsTimeframes = (data: TimeFramesObjectModel []):TimeFramesFormObjectModel []  => {
  let parseArray: TimeFramesFormObjectModel [] = []
  if(data.length === 0){
    return parseArray
  }else {
    for(let k = 0; k < data.length; k++){
      parseArray.push({
        id: data[k].id,
        name: data[k].name,
        date_initial: new Date(data[k].date_initial),
        date_until: new Date(data[k].date_until),
        automatic: data[k].automatic
      })
    }

    return parseArray
  }
}

export const getTimeframesToUpdate = (data: TimeFramesFormObjectModel [], request: 'updated' | 'new'):TimeFramesFormObjectModel []  => {
  let timeframesRequested: TimeFramesFormObjectModel [] = []
  if(request === "updated"){
    for (let k = 0; k < data.length; k++) {
      if(data[k].id !== -1){
        timeframesRequested.push({
          id: data[k].id,
          name: data[k].name,
          date_initial: data[k].date_initial,
          date_until: data[k].date_until,
          automatic: data[k].automatic
        })
      }
    }

    return timeframesRequested;
  }else {
    for (let k = 0; k < data.length; k++) {
      if(data[k].id === -1){
        timeframesRequested.push({
          name: data[k].name,
          date_initial: data[k].date_initial,
          date_until: data[k].date_until,
        })
      }
    }
    return timeframesRequested;
  }
}

export const translateGetCulturalAttributes = (
  data: CulturalAttributesObjectModel []
): CulturalAttributesFormObjectModel [] => {
  let parsedArray: CulturalAttributesFormObjectModel [] = []


  if(data.length === 0){
    return parsedArray
  }else {
    for(let k = 0; k < data.length; k++){
      let coaches: TegduSelectOptionsModel [] = [];
      let tags: TegduSelectOptionsModel [] = [];

      for(let j = 0; j < data[k].coach.length; j++){
        coaches.push({
          value: parseInt(data[k].coach[j].user.id),
          label: data[k].coach[j].user.first_name + " " + data[k].coach[j].user.last_name,
        })
      }

      for(let j = 0; j < data[k].tgd_pilar_tags.length; j++){
        if(data[k].tgd_pilar_tags[j].tgd_tag !== null){
          tags.push({
            value: data[k].tgd_pilar_tags[j].tgd_tag.id,
            label: data[k].tgd_pilar_tags[j].tgd_tag.name,
          })
        }        
      }

      parsedArray.push({
        id: parseInt(data[k].id),
        name: data[k].name,
        valid_until: new Date(data[k].valid_until as string),
        description: data[k].description,
        type: data[k].type,
        owner: { 
          value: parseInt(data[k].owner[0].user.id), 
          label: data[k].owner[0].user.first_name + " " + data[k].owner[0].user.last_name
        },
        coach: coaches,
        tgd_pilar_tags: tags
      })
    }
  }

  return parsedArray;
}