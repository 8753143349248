import LoginLoader from "@components/Login/LoginLoader/LoginLoader";
import LoginForm from "pwa/components/login/LoginForm/LoginForm";
import { useCallback, useEffect, useState } from "react";
import WarningAlert from "@components/Shared/WarningAlert/WarningAlert";
import { LoginParams } from "@models/user.model";
import { login } from "@services/user.service";
import { setUserLogin } from "@store/User/Info/info.actions";
import TegduMobileModal from "pwa/components/Shared/TegduMobileModal/TegduMobileModal";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { validateDomain } from "pwa/services/login.service";
import ForgotPasswordContainer from "../ForgotPasswordContainer/ForgotPasswordContainer";
import "./styles.scss";

const LoginContainer = () => {
  const [validating, setValidating] = useState<boolean>(true);
  const [companyName, setCompanyName] = useState<string>("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState<any>(null);
  const [forgotPasswordModal, setForgotPasswordModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onClickForgotPassword = useCallback(() => {
    setForgotPasswordModal(true)
  }, [])

  const getValidateDomain = useCallback(async () => {
    try {
      const validationResponse = await validateDomain();
      setCompanyName(validationResponse.public_name);
    } catch (e: any) {
      setCompanyName(" ");
    } finally {
      setValidating(false);
    }
  }, []);

  

  const loginUser = useCallback(
    async (values: LoginParams) => {
      setLoadingLogin(true);
      try {
        const data = await login(values);
        if (data) {
          dispatch(setUserLogin(data));
        } else {
          throw new Error("Login Error!");
        }
      } catch (err) {
        setErrorLogin(err);
      } finally {
        setLoadingLogin(false);
      }
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values) => {
      loginUser(values);
    },
    [loginUser]
  );

  const handleChange = useCallback(() => {
    if (errorLogin !== null) {
      setErrorLogin(null);
    }
  }, [errorLogin]);

  useEffect(() => {
    getValidateDomain();
  }, [getValidateDomain]);

  return (
    <>
      {validating ? (
        <div className="loader-container">
          <LoginLoader />
        </div>
      ) : (
        <div className={"container-login-container"}>
          {/* LOGO */}
          <div className="logo-container">
            <img src="/logoT.svg" alt="logo" />
          </div>
          {/* TITLE WORKSPACE */}
          <div className="title-workspace">
            <span>Sign in to {companyName}</span>
          </div>
          {/* TITLE ORGANIZATION */}
          <div className="title-organization">
            <span>{window.location.hostname}</span>
          </div>
          {/* FORM */}
          <div className="form-email-password">
            <LoginForm
              loading={loadingLogin}
              actionSubmit={onSubmit}
              onHandleChange={handleChange}
              onClickForgotPassword={onClickForgotPassword}
            />
          </div>
          <div className="dont-have-account">
            <span>
              <FormattedMessage id={"mobile.login.dont.account"} />
              &nbsp;
              <b>
                <FormattedMessage id={"mobile.login.create.account"} />
              </b>
            </span>
          </div>
        </div>
      )}
        {/* ERROR */}
        <WarningAlert
          className="alert"
          show={errorLogin !== null}
          textId="login.form.error.credentials"
        />

        <TegduMobileModal 
          type="close"
          isOpen={forgotPasswordModal}
          onClose={() => setForgotPasswordModal(false)}
          container={<ForgotPasswordContainer 
            handleCloseModal={() => setForgotPasswordModal(false)}
          />}
        />
    </>
  );
};

export default LoginContainer;
