import React, { useCallback, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

import './styles.scss';

const StyledRating = withStyles({
  icon: {
    padding: '0px',
  },
  iconEmpty: {
    color: '#F0F0F0',
  },
  iconFilled: {
    color: '#FFD460',
  },
})(Rating);

interface ItemIndividualRateProps {
  objUserId: number;
  user: string;
  role: string;
  onChange: (objUserId: number, score: number) => void;
}

const ItemIndividualRate = ({ objUserId, user, role, onChange }: ItemIndividualRateProps) => {
  const [score, setScore] = useState<number | null>(null);

  const handleChange = useCallback(
    (value: number | null) => {
      setScore(value);
      onChange(objUserId, value ? value : 0);
    },
    [objUserId, onChange]
  );

  return (
    <div className='component-mobile-item-individual-rate'>
      <div className='item-individualrate-user'>
        <PersonIcon className='icon' />
        <div className='user-container'>
          <p className='user'>{user}</p>
          <p className='role'>{role}</p>
        </div>
      </div>
      <div className='wrapper-individual-rating'>
        <StyledRating
          name={`score-${objUserId}`}
          icon={<StarRoundedIcon style={{ width: '30px', height: '30px' }} />}
          value={score}
          onChange={(event, newValue) => handleChange(newValue)}
        />
      </div>
    </div>
  );
};

export default ItemIndividualRate;
