import TgdClient from '@helpers/HttpClients/TgdClient';
import { ApiCatalogue } from '@models/catalogs.model';

export const getCompanySizeCatalogue = async (): Promise<ApiCatalogue[]> =>
  await TgdClient.get<ApiCatalogue[]>('/custom-catalogs/company-size');

export const getTimeZoneCatalogue = async (): Promise<ApiCatalogue[]> =>
 await TgdClient.get<ApiCatalogue[]>('/custom-catalogs/timezone');
//await TgdClient.get<ApiCatalogue[]>('/tgd-timezone-catalogs');
 

export const getFrequencyGoalsCatalogue = async (): Promise<ApiCatalogue[]> =>
  await TgdClient.get<ApiCatalogue[]>('/custom-catalogs/company-frequency-goals');
 // await TgdClient.get<ApiCatalogue[]>('/company-frequency-goals');
