import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import ForrmikListener from '@components/FormikComponents/FormikListener/ForrmikListener';
import withTextFastField from '@hocs/withTextFastField';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';

const FormikTextInputFastField = withTextFastField(FormikTextInput);

interface InvitationsSetPasswordFormProps {
  getValidations: (value: any) => void
}

const InvitationsSetPasswordForm = (props: InvitationsSetPasswordFormProps) => {
  const { getValidations } = props;

  const getValues = useCallback((values: any) => {
    const { password, confirm_password } = values
    getValidations({ password: password, confirmPassword: confirm_password })
  }, [getValidations])


  const initialValues = useMemo(() => {
    return {
      password: '',
      confirm_password: ''
    }
  }, [])

  return(
    <Formik
      initialValues={initialValues}
      onSubmit={() => undefined}
      validateOnMount
    >
      <Form>
        <ForrmikListener getValues={getValues} />        
        <FormikTextInputFastField 
          name='password'
          viewMode={false}
          labelId='resetPassword.inputLabel.newPassword'
          type='password'
          validateOnClick
        />
        
        <FormikTextInputFastField 
          name='confirm_password'
          labelId='resetPassword.inputLabel.confirmPassword'
          type='password'
          viewMode={false}
          validateOnClick
        />
        
      </Form>
    </Formik>
  )
}

export default InvitationsSetPasswordForm;