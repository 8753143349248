import TabPanel from '@components/Shared/TabPanel/TabPanel';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { RootState } from '@store/rootReducer';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import NotificationsContainer from '../NotificationsContainer/NotificationsContainer';
import OkrsContainer from '../OkrsContainer/OkrsContainer';
import TagsContainer from '../TagsContainer/TagsContainer';
import './styles.scss';

const SettingsContainer = () => {
  const [tabValue, setTabValue] = useState(0);
  const menuOptions = useSelector((state: RootState) => state.User.Info.menuOptions);

  const showOkrsTab = useMemo(
    () => menuOptions.some((menu) => menu.key_name === 'settings/okrs'),
    [menuOptions]
  );

  const tabs = useMemo<JSX.Element[]>(() => {
    let tabsData = [
      { label: 'settings.tab.tags', id: 'tags' },
      { label: 'settings.tab.notifications', id: 'notifications' },
    ];
    let tabsComponent: JSX.Element[] = [];

    if (showOkrsTab) {
      tabsData.push({
        label: 'settings.tab.okrs',
        id: 'okrs',
      });
    }
    tabsData.forEach((tab) =>
      tabsComponent.push(
        <Tab key={tab.id} label={<FormattedMessage id={tab.label} />} id={tab.id} />
      )
    );

    return tabsComponent;
  }, [showOkrsTab]);

  const handleChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }, []);

  return (
    <div className='settings-container'>
      <h1 className='screen-title'>
        <FormattedMessage id='settings.screen.title' />
      </h1>
      <h3>
        <FormattedMessage id='settings.screen.subtitle' />
      </h3>
      <div className='appbar-container'>
        <AppBar position='static'>
          <Tabs value={tabValue} onChange={handleChange} className='all-tabs'>
            {tabs}
            {/* <Tab label={<FormattedMessage id={'settings.tab.timeframes'} />} id={'timeframes'} />  */}
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <TagsContainer />
          </TabPanel>
          <TabPanel
            // label={<FormattedMessage id={'settings.tab.notifications'} />}
            // id={'notifications'}
            value={tabValue}
            index={1}
          >
            <NotificationsContainer />
          </TabPanel>
          {showOkrsTab ? (
            <TabPanel value={tabValue} index={2}>
              <OkrsContainer />
            </TabPanel>
          ) : (
            <></>
          )}
          {/* <TabPanel value={tabValue} index={1}>
                        <p>Notifications Tab</p>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <p>TimeframesTab</p>
                    </TabPanel> */}
        </AppBar>
      </div>
    </div>
  );
};
export default SettingsContainer;
