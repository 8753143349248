import CreatableDropdown from '@components/Shared/CreatableDropdown/CreatableDropdown';
import { Grid, InputLabel } from '@material-ui/core';
import { FormikFieldModel } from '@models/formik.model';
import { OptionsModel } from '@models/helpful.model';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import FormikStyledError from '../FormikStyledError/FormikStyledError';

export interface FormikCreatableDropdownProps{
   disableCreatableOptions?: boolean
   field: FormikFieldModel
   options: OptionsModel[];
   label: string
   name: string
   placeholder?: string
   meta: any
   validateOnClick?: boolean
   viewMode: boolean   
   blockCallback?: boolean
   validateEmails?: boolean
   setFieldValue: (field: string, value: any) => void
}

const FormikCreatableDropdown = (props: FormikCreatableDropdownProps) => {
    const {setFieldValue, name, meta, field, blockCallback, validateEmails} = props;

    const handleChange = useCallback((value: OptionsModel []) => {
      setFieldValue(name, value);
    },[setFieldValue, name]);
    return(
        <div className="formik-creatable-dropdown">
         {props.label !== undefined && (
            <InputLabel htmlFor={`drop-${props.label}`}>
               <FormattedMessage id={props.label} />
            </InputLabel>
         )}

         <CreatableDropdown 
            value={field.value}
            options={props.options}
            placeholder={props.placeholder}
            viewMode={props.viewMode}
            onChangeValues={handleChange}
            disableCreatableOptions={props.disableCreatableOptions}
            blockCallback={blockCallback}
            validateEmails={validateEmails}
         />

         <Grid container className={'extra-info'}>
            <Grid item className={'error'} md={12}>
               {props.validateOnClick ? (
                  <>{meta.error && meta.touched && <FormikStyledError message={meta.error} />}</>
               ) : (
                  <>{meta.error !== undefined && <FormikStyledError message={meta.error} />}</>
               )}
            </Grid>
         </Grid>
      </div>
    )
}

export default FormikCreatableDropdown;