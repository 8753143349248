import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { setLocalState, setUserLogin, setUserState } from '@store/User/Info/info.actions';
import { LoginSchema } from '@formsValidations/formsValidations';
import WarningAlert from '@components/Shared/WarningAlert/WarningAlert';
import { LoginParams } from '@models/user.model';
import { setOrganizationId } from '@store/Organization/Info/info.action';
import Spinner from '@components/Shared/Spinner/Spinner';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import { ROUTE } from '@utils/routes';
import withTextFastField from '@hocs/withTextFastField';
import { login } from '@services/user.service';

import './styles.scss';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);

interface LoginFormProps {
  companyName: string;
}

function LoginForm(props: LoginFormProps) {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState<any>(null);
  const userLoggedStatus = window.localStorage.getItem('tegdu_LoggedStatus');
  const userLoggedInfo = JSON.parse(window.localStorage.getItem('tegdu_userLoggedInfo')!);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    []
  );

  const loginUser = useCallback(
    async (values: LoginParams) => {
      setLoadingLogin(true);
      try {
        const data = await login(values);
        if (data) {
          dispatch(setUserLogin(data));
        } else {
          throw new Error('Login Error!');
        }
      } catch (err) {
        setErrorLogin(err);
      } finally {
        setLoadingLogin(false);
      }
    },
    [dispatch]
  );

  const automaticLogin = useCallback(() => {
    dispatch(setUserState(userLoggedInfo.userjwt));
    dispatch(setOrganizationId(userLoggedInfo.organizationId));
    dispatch(setLocalState('accessState'));
  }, [dispatch, userLoggedInfo]);

  const handleChange = useCallback(() => {
    if (errorLogin !== null) {
      setErrorLogin(null);
    }
  }, [errorLogin]);

  const onSubmit = useCallback(
    (values) => {
      loginUser(values);
    },
    [loginUser]
  );

  useEffect(() => {
    if (userLoggedStatus && userLoggedInfo) {
      automaticLogin();
    }
  }, [automaticLogin, userLoggedStatus, userLoggedInfo]);

  return (
    <>
      {userLoggedStatus && userLoggedInfo && props.companyName === '' ? (
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      ) : (
        <></>
      )}
      {!userLoggedInfo && props.companyName !== '' ? (
        <div className='card__container'>
          <WarningAlert
            className='alert'
            show={errorLogin !== null}
            textId='login.form.error.credentials'
          />
          <div className='logo__container'>
            <img src='images/tegdu.png' alt='logo' />
          </div>
          <div className='domain__info'>
            <p id='company'>Sign in {`${props.companyName}`}</p>
            <p id='domain'>{window.location.hostname}</p>
          </div>
          <div className='form__container'>
            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={(values) => onSubmit(values)}
              validateOnMount
            >
              {({ isValid }) => (
                <Form className='form'>
                  <div className='inputs-section'>
                    <FormikTextInputFastField
                      name='email'
                      cssId='email-input'
                      type='email'
                      labelId='login.label.email'
                      viewMode={false}
                      onTouchedInput={handleChange}
                      validateOnClick
                    />
                    <FormikTextInputFastField
                      name='password'
                      cssId='pssw-input'
                      type='password'
                      labelId='login.label.password'
                      viewMode={false}
                      onTouchedInput={handleChange}
                      validateOnClick
                    />
                  </div>
                  <div className='footer'>
                    <a href={ROUTE.recoverPassword.path}>
                      <FormattedMessage id='login.label.forgotpw' />
                    </a>
                    <Button
                      className='primary footer-btn'
                      type='submit'
                      disabled={!isValid || loadingLogin}
                    >
                      {loadingLogin ? <Spinner /> : <></>}
                      <FormattedMessage id='login.button.login' />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default LoginForm;
