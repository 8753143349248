import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { TableColumn } from "react-data-table-component";
import "./styles.scss";
import DoubleCircularProgress from "pwa/components/Shared/DoubleCircularProgress/DoubleCircularProgress";


export const ObjectivesTableColumns = () => {
  /* Redux data */
  // const userId = useSelector((state: RootState) => state.User.Info.user.id);

  const columns = useMemo(() => {
    const okrName: TableColumn = {
      id: "okrName-mobile",
      minWidth: "140px",
      maxWidth: "200px",
      wrap: true,
      name: (
        <div className="column-title">
          <FormattedMessage id="objectives.table.mobile.okrname" />
        </div>
      ),
      cell: (row: any) => (
        <div className="name-okr-mobile">
          <span>{row?.name}</span>
        </div>
      ),
    };
    const dueDate: TableColumn = {
      id: "dueDate-mobile",
      selector: "creationDate",
      compact: true,
      width: "90px",
      name: (
        <div className="column-title">
          <FormattedMessage id="objectives.table.mobile.duedate" />
        </div>
      ),
      cell: (row: any) => {
        return (
          <span data-tag="allowRowEvents" className={`row-name-due-date`}>
            {moment(row?.due_date).format("MM/DD/YYYY")}
          </span>
        );
      },
    };
    const progress: TableColumn = {
      id: "progress-mobile",
      selector: "creationDatess",
      right: true,
      width: "90px",
      name: (
        <div className="column-title">
          <FormattedMessage id="objectives.table.mobile.progress" />
        </div>
      ),
      cell: (row: any) => {
        return (
          <div className="container-progress">
            <DoubleCircularProgress
              isTable
              sizeCirular={36}
              value={row?.progress * 100}
              valueToShow={Number.parseFloat(row?.progress).toFixed(1)}
            />
          </div>
        );
      },
    };
    return [okrName, dueDate, progress];
  }, []);
  return columns;
};
