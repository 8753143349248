import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import './styles.scss';
import { ResetPassEmailSchema } from '@formsValidations/formsValidations';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';
import Spinner from '@components/Shared/Spinner/Spinner';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);

interface RecoverPassEmailFormProps {
  loading: boolean;
  onCancel?: () => void;
  onSubmit: (email: string) => void;
}

const RecoverPassEmailForm = ({ onCancel, loading, onSubmit }: RecoverPassEmailFormProps) => {
  const initialValues = useMemo(() => {
    return {
      email: '',
    };
  }, []);

  const handleSubmit = useCallback(
    (email: any) => {
      onSubmit(email);
    },
    [onSubmit]
  );

  return (
    <>
      <div className='titleEmail__container'>
        <h2>
          <FormattedMessage id={'resetPassword.subtitle.forgotPassword'} />
        </h2>
      </div>
      <div className='instructionsEmail__container'>
        <label>
          <FormattedMessage id={'resetPassword.emailInstructions'} />
        </label>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ResetPassEmailSchema}
      >
        {(formik) => {
          let areEqual = false;
          let validForm = formik.isValid;

          if (JSON.stringify(formik.values) === JSON.stringify(initialValues)) {
            areEqual = true;
          } else {
            areEqual = false;
          }

          const resetForm = () => {
            formik.resetForm({ values: initialValues });
            // eslint-disable-next-line
            formik.setTouched({ ...formik.touched });
            if (onCancel) {
              onCancel();
            }
          };

          return (
            <Form>
              <div className='inputs-section'>
                <FormikTextInputFastField
                  name='email'
                  labelId='resetPassword.inputLabel.email'
                  viewMode={false}
                />
              </div>
              <div className='footer'>
                <div className='cancel__btn'>
                  <Button className='secondary cancel__btn' disabled={loading} onClick={resetForm}>
                    <FormattedMessage id={'resetPassword.button.cancel'} />
                  </Button>
                </div>
                <div>
                  <Button
                    className='primary'
                    type='submit'
                    disabled={areEqual || !validForm || loading}
                  >
                    {loading === true && <Spinner />}
                    <FormattedMessage id={'resetPassword.button.continue'} />
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default RecoverPassEmailForm;
