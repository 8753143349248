import TegduSelect from "@components/TegduFormComponents/TegduSelect/TegduSelect"
import withSelectController from "@hocs/withSelectController"
import { TegduSelectOptionsModel } from "@models/global.model"
import { GetObjectivesInitialData } from "@store/Objectives/ObjectivesMainActions/objectivesMainActions.model"
import { RootState } from "@store/rootReducer"
import { USER_ROLE } from "@utils/user"
import useGetTeamsFromLeaderSelected from "hooks/useGetTeamsFromLeaderSelected"
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from "lib/enums/objectives.enum"
import { useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import ObjectivesKeyResults from "../ObjectivesKeyResults/ObjectivesKeyResults"
import './styles.scss';

const TegduSelectInputForm = withSelectController(TegduSelect);

interface BasicObjectiveFormProps {
	action: CREATE_OBJECTIVES_ACTIONS
	objectiveType: OBJECTIVE_TYPES
	requiredData: GetObjectivesInitialData
}

const BasicObjectiveForm = (props: BasicObjectiveFormProps) => {
	const { objectiveType, requiredData, action } = props;
	const methods = useFormContext();
	const[ownerIdSelected, setOwnerIdSelected] = useState<number | null>(null);
	const ownerSelected: TegduSelectOptionsModel = methods.watch("owner");
	const coachesSelected: TegduSelectOptionsModel [] = methods.watch("coach");
	const userRole = useSelector((state: RootState) => state.User.Info.user.role.id);
	const { loading, teams, teamsFromLeaderCallback} = useGetTeamsFromLeaderSelected();

	useEffect(() => {
		if(ownerSelected.value !== ownerIdSelected){
			teamsFromLeaderCallback(ownerSelected.value as number)
			setOwnerIdSelected(ownerSelected.value as number)
			if(ownerIdSelected !== ownerSelected.value && ownerIdSelected !== null){
				methods.resetField('team')
			}
		}
	}, [ownerSelected, teamsFromLeaderCallback, methods, ownerIdSelected])

	const coachOptions = useMemo(() => {
		let availableCoaches: TegduSelectOptionsModel [] = [];

		if(ownerSelected != null){
			requiredData.members.forEach((element) => {
				if(element.value !== ownerSelected.value) {
					availableCoaches.push(element)
				}
			})
			return availableCoaches
		}else{
			return requiredData.members
		}
	}, [ownerSelected, requiredData])

	const ownerOptions = useMemo(() => {
    let availableOwners: TegduSelectOptionsModel [] = [];

    if(coachesSelected !== null && coachesSelected.length > 0){
      if(objectiveType === OBJECTIVE_TYPES.team){
        requiredData.membersLeaders.forEach((member) => {
          availableOwners.push(member)
        })
      }else {
        requiredData.members.forEach((member) => {
          availableOwners.push(member)
        })
      }      

      coachesSelected.forEach((coach) => {
        availableOwners.forEach((owner, index) => {
          if(owner.value === coach.value){
            availableOwners.splice(index, 1)
          }
        })
      })

      return availableOwners

    }else {
      return (objectiveType === OBJECTIVE_TYPES.team) ? requiredData.membersLeaders : requiredData.members
    }

  }, [requiredData, coachesSelected, objectiveType])

	return (
		<div className="basic-objective-form">
			<TegduSelectInputForm 
        labelMessageId="createobjectivemainform.label.timeframe"   
        placeholderId="createobjectivemainform.placeholder.timeframe"     
        name="timeframe"
        control={methods.control}
        options={requiredData.timeframes}
        isSearchable={false}
        isClearable={false}
        isMulti={false}
        formatOptions="timeframes"
      /> 

			<div className="owner-coach">
        <div className="owner">
          <TegduSelectInputForm 
            labelMessageId="createobjectivemainform.label.owner"        
            name="owner"
            control={methods.control}
            options={ownerOptions}
            isSearchable={false}
            isClearable={false}
            isMulti={false}
            disabled={
              (userRole !== USER_ROLE.owner && userRole !== USER_ROLE.officer) ||
              (objectiveType === OBJECTIVE_TYPES.individual) || (action === CREATE_OBJECTIVES_ACTIONS.edit) 
              ? true : false
            }
          />
        </div>

        <div className="coach">
          <TegduSelectInputForm 
            labelMessageId="createobjectivemainform.label.coach"     
            placeholderId="createobjectivemainform.placeholder.coach"   
            name="coach"
            control={methods.control}
            options={coachOptions}
            isSearchable={false}
            isClearable={false}
            isMulti={true}
          />
        </div>
      </div>

			{objectiveType === OBJECTIVE_TYPES.team &&
        <TegduSelectInputForm 
          labelMessageId="createobjectivemainform.label.team"    
          placeholderId="createobjectivemainform.placeholder.teams"    
          name="team"
          control={methods.control}
          options={(userRole === USER_ROLE.owner || userRole ===  USER_ROLE.officer) ? teams : requiredData.teams}
          isSearchable={false}
          isClearable={false}
          isMulti={true}
          disabled={loading}
        />
      }   

			<TegduSelectInputForm 
        labelMessageId="createobjectivemainform.label.tags"     
        placeholderId="createobjectivemainform.placeholder.tags"   
        name="tags"
        control={methods.control}
        options={requiredData.tags}
        isSearchable={false}
        isClearable={false}
        isMulti={true}
      />

			<div className="instructions-container">
        <span className="instructions-title">
          <FormattedMessage
            id="createobjectivemainform.label.aim.title"
          />
        </span> <br />
        <span className="instructions-description">
          <FormattedMessage 
            id="createobjectivemainform.label.aim.description" 
          />
        </span>
      </div>

			<TegduSelectInputForm 
        labelMessageId="createobjectivemainform.label.culturalAttribute"     
        placeholderId="createobjectivemainform.placeholder.culturalAttributes"   
        name="cultural_attributes"
        control={methods.control}
        options={requiredData.culturalAttributes}
        isSearchable={false}
        isClearable={false}
        isMulti={true}
        formatOptions="culturalAttributes"
      />

		<ObjectivesKeyResults 
        okrUnitsCatalogue={requiredData.okrUnits}
      />

		</div>
	)
}

export default BasicObjectiveForm;