import { useCallback, useEffect, useState } from 'react';
import { getAccess } from '@services/login.service';
import {
  setLocalState,
  setMenuAndActions,
  setUserInfo,
  setUserState,
} from '@store/User/Info/info.actions';
import { ROUTE } from '@utils/routes';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SplashScreen from '@components/Shared/SplashScreen/SplashScreen';
import { setOrganizationColor, setOrganizationId } from '@store/Organization/Info/info.action';
import { RootState } from '@store/rootReducer';

const AccessScreen = () => {
  const firstName = useSelector((state: RootState) => state.User.Info.user.first_name);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const menuOptions = useSelector((state: RootState) => state.User.Info.menuOptions);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const getAccessDataCallback = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getAccess();
      if (data) {
        batch(() => {
          dispatch(setOrganizationColor(data.user.tgd_organization.color));
          dispatch(setUserInfo(data.user));
          dispatch(setMenuAndActions(data.tgd_menu_role.tgd_menus));
          dispatch(setOrganizationId(data.user.tgd_organization.id));
        });
      } else {
        throw new Error('Login Error!');
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    const jwt = search ? new URLSearchParams(search).get('jwt') || null : null;
    if (jwt) {
      dispatch(setUserState(jwt));
      getAccessDataCallback();
    } else {
      history.push(ROUTE.login.path);
    }
  }, [dispatch, getAccessDataCallback, history, search]);

  useEffect(() => {
    if (firstName && organizationId && menuOptions.length > 1) {
      dispatch(setLocalState('onboarding'));
    }
  }, [dispatch, firstName, organizationId, menuOptions]);

  return loading ? <SplashScreen /> : !!error ? <p>Error</p> : <></>;
};

export default AccessScreen;
