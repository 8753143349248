import GenericConfirmModal from "@components/Shared/GenericConfirmModal/GenericConfirmModal";
import { RootState } from "@store/rootReducer";
import { setUserLogout } from "@store/User/Info/info.actions";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const TegduModals = () => {
  const [invalidToken, setInvalidToken] = useState<boolean>(false);
  const accessState = useSelector((state: RootState) => state.User.Info.localState);
  const invalidTokenError = useSelector((state: RootState) => state.Errors.invalidToken);
  const dispatch = useDispatch();


  const handleInvalidTokenMessage = useCallback(() => {
    setInvalidToken(false)
    dispatch(setUserLogout())    
  }, [dispatch])

  useEffect(() => {
    if(accessState === "accessState" && invalidTokenError === true){
      setInvalidToken(true)
    }
  }, [accessState, invalidTokenError])


  
  return (
    <GenericConfirmModal 
      loading={false}
      confirmButtonLabel={"tegdu.modals.expired.token.button.label"}
      isOpen={invalidToken}
      messageParagraphId={"tegdu.modals.expired.token.paragraph"}
      messageTitleId={"tegdu.modals.expired.token.title"}
      handleConfirm={handleInvalidTokenMessage}
    />
  )
}

export default TegduModals;