import React from 'react';
import { Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

interface AddStatementProps {
  onClick: () => void;
  disabled: boolean;
}

function AddStatement(props: AddStatementProps) {
  // console.log('props.disabled', props.disabled)
  return (
    <div className='add-statement-component'>
      <Button className='outline' onClick={props.onClick} disabled={props.disabled}>
        <Icon>add</Icon>
        <p>
          <FormattedMessage id={'statement.addnew'} />
        </p>
      </Button>
    </div>
  );
}

export default AddStatement;
