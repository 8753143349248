import { gql } from '@apollo/client';

/* GET members collection */
export const GET_MEMBERS = gql`
  query($id: Int, $start: Int, $limit: Int){
    users(start: $start, limit: $limit, where: { tgd_organization: { id: $id } } ){
      id
      first_name
      last_name
      role {
        id
        name
      }
      created_at
      active
      tgd_team_role_users {
        active
        tgd_team {
          id
          name
          tgd_organization{
            id
          }
        }
      }
      job_title,
      tgd_organization{
        id
      }
    }
  }
`;

/* GET members as a catalog - ask like a service */
export const GET_MEMBERS_CATALOGUE = `
query GetMembersCatalogue($organizationId: ID!) {
    users(where: { tgd_organization: { id: $organizationId } }) {
      value: id
      first_name
      last_name
  }
}
`;

/* GET members as a catalog - ask with useQuery */
export const GET_MEMBERS_CATALOGUE_QUERY = gql`
query GetMembersCatalogue($organizationId: ID!) {
    users(where: { tgd_organization: { id: $organizationId } }) {
      value: id
      first_name
      last_name
  }
}
`;

/* GET member by ID */
export const GET_MEMBER = gql`
query GetMember($id: ID!){
  user(id: $id){
    id,
    first_name,
    last_name,
    hr,
    role{
      id,
      name
    }
    created_at,
    active,
    tgd_team_role_users(where: {active: true}){
      tgd_team{
        id,
        name
      }
    },
    job_title,
  }
}
`
/* GET app roles */
export const GET_ROLES = gql`
  {
    roles(where: {visible: true}){
      value: id,
      label: name
      visible
    }
  }
`

export const GET_ROLES_PLAIN = `
  {
    roles(where: {visible: true}){
      value: id,
      label: name
      visible
    }
  }
`

export const GET_MEMBER_ROLE = gql`
subscription OnRoleChange($id: ID!) {
  user(id: $id) {
    id,
    first_name,
    last_name,
    role{
      id,
      name
    }
  }
}
`

export const GET_MEMBERS_PLAIN = `
  
    
      id
      first_name
      last_name
      role {
        id
        name
      }
      created_at
      active
      tgd_team_role_users {
        active
        tgd_team {
          id
          name
          tgd_organization{
            id
          }
        }
      }
      job_title,
      tgd_organization{
        id
      }
    
  
`;