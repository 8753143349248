import { FormikCreatableDropdownProps } from "@components/FormikComponents/FormikCreatableDropdown/FormikCreatableDropdown";
import { FastField } from "formik";
import useFormikShouldUpdate from "hooks/useFormikShouldUpdate";

type FormikCreatableDropdownPropsHOC = Omit<FormikCreatableDropdownProps, 'field' | 'meta' | 'setFieldValue'>

const withCreatableDropFastField = (WrappedComponent: (props: FormikCreatableDropdownProps) => JSX.Element) => {
   const WithCreatableDropFastField = (props: FormikCreatableDropdownPropsHOC) => {
      const { name, viewMode } = props
      const shouldUpdate = useFormikShouldUpdate(viewMode)

      return(
         <FastField name={name} shouldUpdate={shouldUpdate}>
            {({field, meta, form}: any) => (
               <WrappedComponent 
                  {...props}
                  field={field}
                  meta={meta}                  
                  setFieldValue={form.setFieldValue}
               />
            )}
         </FastField>         
      )
   }
   return WithCreatableDropFastField;
}

export default withCreatableDropFastField;