import React, { useCallback, useState } from 'react';
import AddMembersForm from '@components/Members/AddMembersForm/AddMembersForm';
import { AddMembersEmailsModel, AddMembersFormModel, ApiSendInvitationResponse, INVITATIONS_STATUS } from '@models/invitations.model';
import { useDispatch } from 'react-redux';
import { addNewMembers, getInvitationsData, setInvitationTabState } from '@store/Invitations/invitations.action';
import useToastNotification from 'hooks/useToastNotification';
import AddMembersFromFileContainer from '../AddMembersFromFileContainer/AddMembersFromFileContainer';
import { EmailsFromXLSX } from '@models/members.model';
import { parseEmailsFromFileToSelectInput } from '@utils/members';

interface AddMembersContainerProps {
  onClose: () => void
}

const AddMembersContainer = (props: AddMembersContainerProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const toastNotification = useToastNotification();
  const [showUploadFromFile, setShowUploadFromFile] = useState<boolean>(false);
  const [emailsFromFile, setEmailsFromFile] = useState<AddMembersEmailsModel[] | null>(null);

  const handleErrorAddMembersForm = useCallback((error: any) => {
    toastNotification('general.error.members.invitations', true)
  }, [toastNotification])


  const handleSuccessAddMembersForm = useCallback((res: ApiSendInvitationResponse) => {
    onClose()
    if (res.new.length) {
      toastNotification('general.success.members.invitations', false)
    }
    if (res.existing.length) {
      toastNotification('invitations.invite.existing.successful.toast', false)
    }
    dispatch(getInvitationsData(INVITATIONS_STATUS.pending))
    dispatch(setInvitationTabState(0));
  }, [dispatch, onClose, toastNotification])

  const handleAddMembersForm = useCallback((data: AddMembersFormModel) => {
    if (data.emails.length > 0 && data.role !== "0") {
      dispatch(addNewMembers(data, handleSuccessAddMembersForm, handleErrorAddMembersForm))
    }
  }, [dispatch, handleSuccessAddMembersForm, handleErrorAddMembersForm])

  const handleBackFromUploadContainer = useCallback(() => {
    setShowUploadFromFile(false)
  }, [])

  const handleGoToUploadContainer = useCallback(() => {
    setShowUploadFromFile(true)
  }, [])


  const handleAddMembersFromFile = useCallback((emails: EmailsFromXLSX[]) => {
    setEmailsFromFile(parseEmailsFromFileToSelectInput(emails))
  }, [])


  return (
    <div className='add-members-container'>
      {showUploadFromFile === false ?
        <AddMembersForm
          onSubmit={handleAddMembersForm}
          onUploadFile={handleGoToUploadContainer}
          emailsFromFile={emailsFromFile}
        />
        :
        <AddMembersFromFileContainer
          handleBack={handleBackFromUploadContainer}
          handleUpload={handleAddMembersFromFile}
        />
      }
    </div>
  )
}

export default AddMembersContainer;