import Spinner from "@components/Shared/Spinner/Spinner";
import TegduPasswordShape from "@components/Shared/TegduPasswordShape/TegduPasswordShape";
import TegduTextInputComponent from "@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent";
import withTextInputController from "@hocs/withTextInputController";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@material-ui/core";
import { RootState } from "@store/rootReducer";
import { 
  createPasswordFormInitialValue, 
  CreatePasswordFormModel, 
  CreatePasswordFormSchema 
} from "lib/TegduForms/CreatePasswordFormUtils";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux";
import './styles.scss';

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);

interface CreatePasswordFormProps {
  handleSubmit: (data: CreatePasswordFormModel) => void
}


const CreatePasswordForm = (props: CreatePasswordFormProps) => {
  const { handleSubmit } = props;
  const isCreatingPasswordLoading = useSelector((state: RootState) => state.Login.ForgotPassword.isCreatingNewPasswordLoading);

  const [validPassword, setValidPassword] = useState<boolean>(false);

  const methods = useForm<CreatePasswordFormModel>({
    defaultValues: createPasswordFormInitialValue,
    resolver: yupResolver(CreatePasswordFormSchema)
  });

  const passwordValue: string = methods.watch("password");

  const handlePasswordValidValue = useCallback((isValid: boolean) => {
    setValidPassword(isValid)
  }, [])

  return(
    <FormProvider {...methods}>
      <form className="create-password-form" onSubmit={methods.handleSubmit(handleSubmit)}>
        <TegduTextInputForm 
          labelId="account.screen.tab.security.update.new"
          name="password"
          control={methods.control}
          errorMessage={methods.formState.errors.password?.message}
          isEditMode={true}
          maxLength={50}       
          isPassword={true}   
        />
        <TegduTextInputForm 
          labelId="account.screen.tab.security.update.confirm"
          name="confirmPassword"
          control={methods.control}
          errorMessage={methods.formState.errors.confirmPassword?.message}
          isEditMode={true}
          maxLength={50}
          isPassword={true}   
        />

        <div className="visual-validations">
          <TegduPasswordShape 
            passwordValue={passwordValue}
            handlePasswordStatus={handlePasswordValidValue}
          />
        </div>

       <div className="create-button">
          <Button 
            disabled={(
              (methods.formState.isValid === true) && 
              (validPassword === true) && 
              (isCreatingPasswordLoading === false)) ? false : true
            }
            style={{display: "flex", justifyContent: "center", width: "234px"}}
            type="submit"
          >
            {isCreatingPasswordLoading ? 
              <Spinner color='white' size={15} thickness={5} />
            :
              <>Create a new password</>
            }
          </Button>
       </div>
      </form>
    </FormProvider>
  )
}

export default CreatePasswordForm;