
import AddMemberModal from '@components/Members/AddMemberModal/AddMemberModal';
import { AppBar, Button, Grid, Icon } from '@material-ui/core';
import { RootState } from '@store/rootReducer';
import { setAddMemberModal } from '@store/Shared/shared.action';
import { USER_ROLE } from '@utils/user';
import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss'
import InvitationsTabNavigation from '../../../components/Invitations/InvitationsTabNavigation/InvitationsTabNavigation';
import ActiveContainer from '../ActiveInvitationsContainer/ActiveInvitationsContainer';

const InvitationsContainer = () => {
    const tegduRole = useSelector((state: RootState) => state.User.Info.user.role.id);
    const addMemberModal = useSelector((state: RootState) => state.Shared.modals.addMemberModal);

    const dispatch = useDispatch()

    const onCloseAddModal = useCallback(() => {
        dispatch(setAddMemberModal(false))
    }, [dispatch])

    const onOpenAddModal = useCallback(() => {
        dispatch(setAddMemberModal(true))
    }, [dispatch])


    return (
        <div className='invitations-container'>
            <Grid container className='grid-title'>
                <Grid item md={10} className='title'>
                    <h1 className='screen-title'>
                        <FormattedMessage id='invitations.screen.title' />
                    </h1>
                    {(tegduRole === USER_ROLE.officer || tegduRole === USER_ROLE.owner) &&
                        <Button
                            className='primary'
                            onClick={onOpenAddModal}
                        >
                            <Icon>add</Icon>
                            <FormattedMessage id='objectives.button.addMember' />
                        </Button>
                    }
                </Grid>
            </Grid>

            <div className="appbar-container">
                <AppBar position="static">
                    <InvitationsTabNavigation />
                </AppBar>
                <ActiveContainer />
            </div>
            <AddMemberModal
                isOpen={addMemberModal}
                onClose={onCloseAddModal}
            />
        </div>
    )
}

export default InvitationsContainer;