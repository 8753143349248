import { ApiGetObjective } from '@models/objetives.model';

let first_name = 'User';
let last_name = 'Name';

export const TOUR_OBJECTIVES_TABLE: ApiGetObjective[] = [
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Increase User Engagement',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.7,
    status: 2,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Expand Market Reach',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.5,
    status: 2,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Improve Customer Support',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.4,
    status: 2,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Improve Data Analytics',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.8,
    status: 3,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Streamline Development Processes',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.5,
    status: 2,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: false,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Improve Conversion Rates',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.6,
    status: 3,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: true,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Enhance Product Security',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.8,
    status: 1,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: true,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Optimize Infrastructure',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.9,
    status: 3,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: true,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Increase Productivity',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.6,
    status: 2,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: true,
  },
  {
    id: 0,
    owner: {
      id: 0,
      first_name: first_name,
      last_name: last_name,
    },
    name: 'Enhance User Experience',
    role: 1,
    due_date: '2023-07-12T06:00:00.000Z',
    progress: 0.7,
    status: 1,
    created_at: '2023-01-24T02:02:11.000Z',
    updated_at: '2023-06-23T00:12:45.000Z',
    completed_at: null,
    tgd_obj_role_user: 0,
    read: true,
  },
];
