import { RING_KEY_NOTIFICATIONS } from 'lib/enums/notifications/notifications.enums';
import { ROUTE } from '@utils/routes';
import { setSelectedTabAction } from '@store/Organization/selectedTab/selectedTab.action';
import { Dispatch } from 'redux';
import { generatePath } from 'react-router-dom';
import { History } from 'history';

export const buildRingNotificationsMessage = (keyNotification: RING_KEY_NOTIFICATIONS) => {
  switch (keyNotification) {
    case RING_KEY_NOTIFICATIONS.memberAdded:
      return 'ring.notification.addedToTeam';
    case RING_KEY_NOTIFICATIONS.asignedAsCoach:
      return 'ring.notification.addedAsCoach';
    case RING_KEY_NOTIFICATIONS.leadershipTransferred:
      return 'ring.notification.leadershipTransferred';
    case RING_KEY_NOTIFICATIONS.okrOwnerShipTransferred:
      return 'ring.notification.ownershipTransferred';
    case RING_KEY_NOTIFICATIONS.objectiveDue:
      return 'ring.notification.objectiveDue';
    case RING_KEY_NOTIFICATIONS.addProgressReminder:
      return 'ring.notification.addProgressReminder';
    case RING_KEY_NOTIFICATIONS.culturalAttributeExpiring:
      return "ring.notification.culturalAttributeExpiring"
    case RING_KEY_NOTIFICATIONS.okrRequestApproval:
      return "ring.notification.OKRcommentApproval"
  }
};

export const buildWeeksCatalogue = () => {
  // number of weeks in a year
  const weeksInAYear = 53;

  const arr: any = [];
  for (let i = 1; i < weeksInAYear; i++) {
    arr.push({ label: `${i}`, value: `${i}` });
  }
  return arr;
};

export const redirectToCorrespondingScreen = (key: string, dispatch: Dispatch<any>, history: History<unknown>, objective_id?: string) => {
  switch (key) {
    case RING_KEY_NOTIFICATIONS.memberAdded:
      history.push(ROUTE.teams.path);
      return;
    case RING_KEY_NOTIFICATIONS.asignedAsCoach:
      if (objective_id) {
        history.push(generatePath(ROUTE.objectivesDetail.path, { id: objective_id }));
      } else {
        history.push(ROUTE.objetives.path);
      }
      return;
    case RING_KEY_NOTIFICATIONS.leadershipTransferred:
      history.push(ROUTE.teams.path);
      return;
    case RING_KEY_NOTIFICATIONS.okrOwnerShipTransferred:
      if (objective_id) {
        history.push(generatePath(ROUTE.objectivesDetail.path, { id: objective_id }));
      } else {
        history.push(ROUTE.objetives.path);
      }
      return;
    case RING_KEY_NOTIFICATIONS.objectiveDue:
      if (objective_id) {
        history.push(generatePath(ROUTE.objectivesDetail.path, { id: objective_id }));
      } else {
        history.push(ROUTE.objetives.path);
      }
      return;
    case RING_KEY_NOTIFICATIONS.addProgressReminder:
      if (objective_id) {
        history.push(generatePath(ROUTE.objectivesDetail.path, { id: objective_id }));
      } else {
        history.push(ROUTE.objetives.path);
      }
      return;
    case RING_KEY_NOTIFICATIONS.okrRequestApproval:
      if (objective_id) {
        history.push(generatePath(ROUTE.objectivesDetail.path, { id: objective_id }));
      } else {
        history.push(ROUTE.objetives.path);
      }
      return;
    case RING_KEY_NOTIFICATIONS.culturalAttributeExpiring:
      dispatch(setSelectedTabAction(2));
      history.push(ROUTE.organization.path);
      return;
    default:
      return;
  }
};
