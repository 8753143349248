import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import './styles.scss';

interface ExpandableProps {
  data: string | string[];
}

const Expandable = (props: ExpandableProps) => {
  const text = useMemo(() => (props.data instanceof Array ? props.data.join(', ') : props.data), [
    props.data,
  ]);
  const [expand, setExpand] = useState<boolean>(false);
  const [showExpandIcon, setShowExpandIcon] = useState<boolean>(false);
  const textRef = useRef<HTMLInputElement>(null);

  // Detect window resize
  useEffect(() => {
    const handleResize = () => {
      let scrollWidth = 0;
      let offsetWidth = 0;
      let height = 0;

      if (textRef && textRef.current) {
        scrollWidth = textRef.current.scrollWidth;
        offsetWidth = textRef.current.offsetWidth;
        height = textRef.current.clientHeight;
      }

      if (scrollWidth > offsetWidth) {
        if (!showExpandIcon) {
          setShowExpandIcon(true);
        }
      } else {
        if (showExpandIcon && height < 20) {
          setShowExpandIcon(false);
          if (expand) {
            setExpand(false);
          }
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    // On unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [showExpandIcon, expand, text]);

  // If data change
  useEffect(() => {
    return () => setExpand(false);
  }, [text]);

  const handleExpandRow = useCallback(() => {
    if (showExpandIcon) {
      setExpand(!expand);
    }
  }, [showExpandIcon, expand]);

  return (
    <div className='component-expandablecell'>
      <span data-tag='allowRowEvents' ref={textRef} className={!expand ? 'collapsed' : 'expanded'}>
        {text}
      </span>
      {showExpandIcon ? (
        <div className='expand-icon' onClick={handleExpandRow}>
          {!expand ? <ExpandMoreIcon color='primary' /> : <ExpandLessIcon color='primary' />}
        </div>
      ) : (
        <div className='blank'></div>
      )}
    </div>
  );
};

export default Expandable;
