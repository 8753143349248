import React from 'react';
import { FormattedMessage } from 'react-intl';

const TeamDetailsScreen = () => {
  return (
    <div className='narrow-container'>
      <h1 className='screen-title'>
        <FormattedMessage id='teams.details.edit.screen.title' />
      </h1>
      {/* <TeamDetailsContainer /> */}
    </div>
  );
};

export default TeamDetailsScreen;
