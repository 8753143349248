import React from 'react';
import './StepContent.scss';

interface StepContentProps {
  title?: string;
  text: string;
}

const StepContent: React.FC<StepContentProps> = ({ title, text }) => {
  return (
    <div className='c-stepcontent'>
      {title && <h2>{title}</h2>}
      <p className='step-content-text'>{text}</p>
    </div>
  );
};

export default StepContent;
