import React from 'react';
import { Button } from '@material-ui/core';

import './styles.scss';

interface WelcomeStepProps {
  onStart: () => void;
  onSkip: () => void;
  loading: boolean;
}
const WelcomeStep = ({ onStart, onSkip, loading }: WelcomeStepProps) => {
  const handleStartTour = () => {
    onStart();
  };

  const handleSkipTour = () => {
    onSkip();
  };

  return (
    <div className='begintour-container'>
      <h2>Welcome to tegdu!</h2>
      <p className='step-content-text'>Here you will be able to create and manage OKRs in an easy and intuitive way.</p>
      <div className='begintour-container-buttons'>
        <Button disabled={loading} onClick={handleStartTour}>
          Start Tour
        </Button>
        <Button className='secondary' disabled={loading} onClick={handleSkipTour}>
          Skip Tour
        </Button>
      </div>
    </div>
  );
};

export default WelcomeStep;
