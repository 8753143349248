import React, { useMemo, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import withTextAreaFastField from '@hocs/withTextAreaFastField';
import FormikTextArea from '@components/FormikComponents/FormikTextArea/FormikTextArea';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { RateGeneralReviewSchema } from '@formsValidations/formsValidations';
import Button from '@material-ui/core/Button';
import { FormRateGeneralReviewValues } from '@models/score.model';

import './styles.scss';
import Spinner from '@components/Shared/Spinner/Spinner';

const FormikTextAreaFastField = withTextAreaFastField(FormikTextArea);

const StyledRating = withStyles({
  icon: {
    padding: '0px',
  },
  iconEmpty: {
    color: '#F0F0F0',
  },
  iconFilled: {
    color: '#FFD460',
  },
})(Rating);

interface RateGeneralReviewProps {
  onCancel: () => void;
  onSubmit: (comments: string, score: number) => void;
  loading: boolean;
}

const RateGeneralReview = ({ loading, onCancel, onSubmit }: RateGeneralReviewProps) => {
  const [score, setScore] = useState<number | null>(null);
  const intl = useIntl();

  const placeholder = useMemo(() => intl.formatMessage({ id: 'score.rate.form.comments' }), [intl]);

  const initialValues: FormRateGeneralReviewValues = useMemo(
    () => ({
      comments: '',
    }),
    []
  );

  const handleSubmit = (values: FormRateGeneralReviewValues) => {
    onSubmit(values.comments, score ? score : 0);
  };

  return (
    <div className='component-rategeneralreview'>
      <StyledRating
        className='general-rate'
        name='general-rate'
        icon={<StarRoundedIcon style={{ width: '80px', height: '80px' }} />}
        value={score}
        onChange={(event, newValue) => setScore(newValue)}
        disabled={loading}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(val) => handleSubmit(val)}
        validationSchema={RateGeneralReviewSchema}
      >
        {(formik) => {
          return (
            <Form>
              <FormikTextAreaFastField
                name='comments'
                placeholder={placeholder}
                height='88px'
                charsLimit={200}
                viewMode={false}
                validateOnClick
                disabled={loading}
              />
              <div className='score-rate-buttons'>
                <Button className='secondary' onClick={onCancel} disabled={loading}>
                  <FormattedMessage id='score.rate.form.cancel' />
                </Button>
                <Button type='submit' className='primary' disabled={loading}>
                  {loading ? <Spinner /> : <></>}
                  <FormattedMessage id='score.rate.form.save' />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RateGeneralReview;
