import { OBJECTIVE_STATUS } from '@utils/objectives';
import { useLogger } from '@utils/useLogger';
import moment from 'moment';
import ObjectiveStatusComponent from '../ObjectiveStatusComponent/ObjectiveStatusComponent';

import './styles.scss';

interface ObjectiveLogProps {
  nameUser?: string;
  statusTarget?: OBJECTIVE_STATUS;
  date?: string;
  logType: string;
  assignee?: string;
}

const ObjectiveLog = ({
  date,
  nameUser,
  statusTarget = 1,
  logType,
  assignee,
}: ObjectiveLogProps) => {
  const { translateObjLogTypes } = useLogger();
  return (
    <div className='container-log'>
      <ObjectiveStatusComponent status={statusTarget} size='small' />
      <div className='content-log'>
        <span>
          <b>{nameUser}&nbsp;</b>
          {translateObjLogTypes(logType, statusTarget)}
          {assignee ? <b>&nbsp;{assignee}</b> : <></>}
        </span>
      </div>
      <div className='date-log'>{moment(date).format('MM/DD/YYYY - HH:mm')}hrs</div>
    </div>
  );
};

export default ObjectiveLog;
