import React, { useCallback } from 'react';
import ObjectivesFilters from '@components/Objetives/ObjectivesFilters/ObjectivesFilters';
import { ObjectivesTableColumns } from '@components/Objetives/ObjectivesTableColumns/ObjectivesTableColumns';
import GenericTable from '@components/Shared/GenericTable/GenericTable';
import { ApiGetObjective } from '@models/objetives.model';
import { generatePath, useHistory } from 'react-router';
import { ROUTE } from '@utils/routes';
import Card from '@material-ui/core/Card';
import { readObjective } from '@services/objectives.service';

import './styles.scss';

interface ObjectivesTableContainerProps {
  loading: boolean;
  objectivesTableInfo: ApiGetObjective[];
  withRoleColumn: boolean;
  getObjectiveActionSelected: (keyName: string, objectiveId: number, ownerName: string) => void;
  getSelectedOption: (selectedOption: number) => void;
  getStatusSelected: (statusId: number) => void;
}

const ObjectivesTableContainer = (props: ObjectivesTableContainerProps) => {
  const { getObjectiveActionSelected, getSelectedOption, getStatusSelected } = props;
  const { loading, objectivesTableInfo, withRoleColumn } = props;
  const history = useHistory();

  const getActionSelected = useCallback(
    (keyName: string, objectiveId: number, ownerName: string) => {
      getObjectiveActionSelected(keyName, objectiveId, ownerName);
    },
    [getObjectiveActionSelected]
  );

  const onRowClicked = useCallback(
    (tableData: ApiGetObjective) => {
      if (tableData.read === false && tableData.tgd_obj_role_user) {
        readObjective(tableData.tgd_obj_role_user);
      }
      history.push(generatePath(ROUTE.objectivesDetail.path, { id: tableData.id }));
    },
    [history]
  );

  return (
    <div className='objecives_table_container'>
      <ObjectivesFilters
        getSelectedOption={getSelectedOption}
        getStatusSelected={getStatusSelected}
      />
      <Card className='card-table-general-objectives'>
        <GenericTable
          label={'objetives'}
          columns={ObjectivesTableColumns(withRoleColumn, getActionSelected)}
          data={objectivesTableInfo}
          pagination={true}
          pointerOnHover={true}
          loading={loading}
          error={undefined}
          onRowClicked={onRowClicked}
        />
      </Card>
    </div>
  );
};

export default ObjectivesTableContainer;
