import { CircularProgress } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

interface DoubleCircularProgressProps {
  value: number; // 1 - 100
  valueToShow?: number | string;
  sizeCirular?: number;
  typeOfRole?: "Owner" | "Coach" | "Collaborator";
  isTable?: boolean;
}

const DoubleCircularProgress = ({
  value,
  sizeCirular = 72,
  typeOfRole,
  valueToShow,
  isTable,
}: DoubleCircularProgressProps) => {
  return (
    <div className="container-circularprogress">
      <div className="component-circularprogress">
        <CircularProgress
          variant="determinate"
          className={"bottom-circle"}
          size={sizeCirular}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          className={"top-circle"}
          value={value}
          size={sizeCirular}
          thickness={4}
        />
        <span id={isTable ? "table-span" : "span-without-table"}>
          {valueToShow ? valueToShow : value}
        </span>
      </div>
      <div className="name-circular">
        {typeOfRole && (
          <span>
            <FormattedMessage id={"dashboard.objectives.as.roles"} />
            {typeOfRole}
          </span>
        )}
      </div>
    </div>
  );
};

export default DoubleCircularProgress;
