import React, { useCallback } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { menuOptions } from './menuOptions';
import { menuOptionsFooter } from './menuOptionsFooter';
import { ROUTE } from '@utils/routes';
import { MenuRole } from '@models/permissions.model';
import { IntlShape } from 'react-intl';

import './styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

interface TourSideBarProps {
  open: boolean;
  menuOptionsRole: MenuRole[];
  pathname: string;
  screen: string;
  intl: IntlShape;
}

const TourSideBar = ({ open, menuOptionsRole, pathname, screen, intl }: TourSideBarProps) => {
  const highlightMenuOption = useSelector((state: RootState) => state.Shared.tour.highlightMenuOption);

  console.log('highlightMenuOption', highlightMenuOption)

  const showMenuOption = useCallback((href: string) => pathname === href && highlightMenuOption, [pathname, highlightMenuOption]);

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(`sidebar__drawer toursidebar ${open ? 'open' : 'close'}`),
      }}
    >
      <List className='listExample'>
        {menuOptions.map(
          (element, index) =>
            menuOptionsRole.find((option) => option.key_name === element.href.slice(1)) && (
              <div
                key={index}
                className={`menu__options ${
                  showMenuOption(element.href) && pathname.slice(0, 11) === element.href.slice(0, 11)
                    ? 'selected'
                    : 'hidemenu'
                }`}
              >
                <ListItem
                  button
                  divider
                  className={`list__item ${screen === element.title && 'selected'}`}
                  key={element.title}
                >
                  <Icon className='icon'>{element.icon}</Icon>
                  <ListItemText
                    className={`sidebar__item__text ${!open ? 'opacity' : ''}`}
                    primary={intl.formatMessage({ id: element.title })}
                  />
                </ListItem>
              </div>
            )
        )}
      </List>
      <div className='sidbar__footer'>
        <List>
          {menuOptionsFooter.map(
            (element, index) =>
              menuOptionsRole.find(
                (option) => option.key_name === element.href.slice(1) || element.href === ROUTE.login.path
              ) && (
                <div className={`menu__options ${showMenuOption(element.href) ? 'selected' : 'hidemenu'}`}>
                  <ListItem
                    button
                    divider
                    className={`list__item ${screen === element.title && 'selected'}`}
                    key={element.title}
                  >
                    <Icon className='icon'>{element.icon}</Icon>
                    <ListItemText
                      className={`sidebar__item__text ${!open ? 'opacity' : ''}`}
                      primary={intl.formatMessage({ id: element.title })}
                    />
                  </ListItem>
                </div>
              )
          )}
        </List>
        <div className='logo__container hidemenu'>
          <img src='/images/tegdu_gray.png' alt='logo' />
        </div>
      </div>
    </Drawer>
  );
};

export default TourSideBar;
