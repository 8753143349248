import React from 'react';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import Spinner from '../Spinner/Spinner';

interface GenericConfirmModalProps {
  loading?: boolean
  confirmButtonLabel: string;
  isOpen: boolean;
  messageParagraphId: string; 
  messageTitleId: string;
  titleLabelValues?: any
  handleCancel?: () => void;
  handleConfirm: () => void;  
}

const GenericConfirmModal = (props: GenericConfirmModalProps) => {
  return (
    <Dialog
      open={props.isOpen}
      className={'generic-confirm-modal'}
      onClose={props.handleCancel}
      disableBackdropClick
    >
      <DialogContent className={'confirm-modal-content'}>
        <Grid container className={'confirm-modal-container'}>
          <Grid item md={12} className={'icon'}>
            <div className={'div-icon'}>
              <ErrorIcon />
            </div>
          </Grid>
          <div className={'text-message'}>
            <Grid item md={12} className={'message'}>
              <h3 className="title">{props.messageTitleId && <FormattedMessage id={props.messageTitleId} values={props.titleLabelValues}/>}</h3>
            </Grid>
            <Grid item md={12} className={'paragraph'}>
              <p>{props.messageParagraphId && <FormattedMessage id={props.messageParagraphId} />}</p>
            </Grid>
          </div>
          <Grid item md={12} className='bottom'>
            
            <div className='footer'>
              <Grid item md={12}>
                <Button className='modal-button' onClick={props.handleConfirm}>
                  {props.loading !== undefined && props.loading === true ? 
                    <Spinner color='white' size={15} thickness={5} />
                  :
                    <FormattedMessage id={props.confirmButtonLabel} />
                  }                  
                </Button>
              </Grid>
              {props.handleCancel !== undefined && 
                <Grid item md={12}>
                  <Button className='secondary' onClick={props.handleCancel} disabled={props.loading}>
                    <FormattedMessage id={'generic.confirm.modal.cancel.button'} />
                  </Button>
                </Grid>
              }
             
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GenericConfirmModal;
