import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

interface ReviewsFiltersProps {
  optionSelectedButton?: 0 | 1;
  onActionButtons: (selected: boolean)=>void;
}

const ReviewsFilters = ({ optionSelectedButton, onActionButtons }: ReviewsFiltersProps) => {
  return (
    <div className="container-review-component">
      <div className="container-buttons">
        <Button
          onClick={()=>onActionButtons(true)}
          className={
            optionSelectedButton === 0
              ? "button-filter-selected"
              : "button-filter"
          }
        >
          <FormattedMessage id={"review.component.filter.button.given"} />
        </Button>
        <Button
          onClick={()=>onActionButtons(false)}
          className={
            optionSelectedButton === 1
              ? "button-filter-selected"
              : "button-filter"
          }
        >
          <FormattedMessage id={"review.component.filter.button.received"} />
        </Button>
      </div>
      <div className="container-drop-downs">
      </div>
    </div>
  );
};

export default ReviewsFilters;
