import { TegduTextInputComponentProps } from "@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent";
import { floatNumberString } from "@utils/strings";
import { Controller } from "react-hook-form";

interface TexInputControllerPropsHOC extends TegduTextInputComponentProps {
  name: string
  control: any
  stringAsFloat?: boolean
}

const withTextInputController = (WrappedComponent: (props: TegduTextInputComponentProps) => JSX.Element) => {
  const WithTextInputController = (props: TexInputControllerPropsHOC) => {
    const { name, stringAsFloat, control } = props;
    
    return (
      <Controller 
        control={control}
        name={name}
        render={ ( { field } ) => (
          <WrappedComponent 
            {...props}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={stringAsFloat ? floatNumberString(field.value) : field.value }
          />
        )}
      />
    )
  }
  return WithTextInputController;
}

export default withTextInputController;