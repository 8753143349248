import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormTeam from '@components/Teams/FormTeam/FormTeam';
import { FormTeamValues, TeamsData } from '@models/teams.model';
import GenericSuccessModal from '@components/Shared/GenericSuccessModal/GenericSuccessModal';
import { postAddTeam } from '@services/teams.service';
import { OptionsModel } from '@models/helpful.model';
import { getMembersCatalogue } from '@services/members.service';
import CompleteScreenModal from '@components/Shared/CompleteScreenModal/CompleteScreenModal';
import { Button } from '@material-ui/core';
import Spinner from '@components/Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { handleTeamsErrorResponse } from 'lib/responses/teams';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';

interface AddTeamContainerProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  teamName?: string;
}

const AddTeamContainer = ({ teamName, ...props }: AddTeamContainerProps) => {
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [memberOptions, setMemberOptions] = useState<OptionsModel[]>([]);
  const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const tempTeam: TeamsData = useMemo(() => {
    return {
      id: '',
      name: teamName ? teamName : '',
      leads: [],
      members: [],
      create_time: '',
    };
  }, [teamName]);

  const getMembers = useCallback(async () => {
    if (organizationId !== null) {
      setLoadingMembers(true);
      try {
        const members = await getMembersCatalogue(organizationId);
        setMemberOptions(members);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMembers(false);
      }
    }
  }, [organizationId]);

  const handleSubmit = useCallback(
    async (team: FormTeamValues, setErrors: any) => {
      setLoadingSubmit(true);
      try {
        await postAddTeam(team);
        props.refetch();
        props.onClose();
        setOpenSuccessModal(true);
      } catch (err) {
        const error = handleTeamsErrorResponse(err);
        if (error.type === 'schema') {
          // Set error in Form input
          setErrors(error.schema);
        } else {
          setOpenErrorModal(true);
        }
      } finally {
        setLoadingSubmit(false);
      }
    },
    [props]
  );

  const handleCloseSuccessModal = useCallback(() => {
    setOpenSuccessModal(false);
  }, []);

  useEffect(() => {
    if (props.open) {
      getMembers();
    }
  }, [props.open, getMembers]);

  return (
    <>
      <CompleteScreenModal
        open={props.open}
        onClose={props.onClose}
        title={'Add a new team'}
        actionsButton={
          <>
            <Button className='secondary' onClick={props.onClose}>
              Cancel
            </Button>
            <Button type='submit' disabled={disabled} form='form-addteam'>
              {loadingSubmit && <Spinner />} Save team
            </Button>
          </>
        }
      >
        <FormTeam
          formId='form-addteam'
          members={memberOptions}
          onSubmit={handleSubmit}
          loadingSubmit={loadingSubmit}
          loadingMembers={loadingMembers}
          onValidate={(isValid) => setDisabled(isValid)}
          team={tempTeam}
        />
      </CompleteScreenModal>
      <GenericSuccessModal
        isOpen={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        messageId='teams.add.modal.success'
      />
      <GenericErrorModal
        isOpen={openErrorModal}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: 'adding', subject: 'team' },
        }}
        handleClose={() => setOpenErrorModal(false)}
      />
    </>
  );
};

export default AddTeamContainer;
