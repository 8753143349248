import React from 'react';
import ObjectivesContainer from '@containers/Objetives/ObjectivesContainer/ObjectivesContainer';

const ObjetivesScreen = () => {
  return (
    <div id='tourstep-objectives' className='screen-objetive'>
      <ObjectivesContainer />
    </div>
  );
};

export default ObjetivesScreen;
