import MembersContainer from '@containers/Members/MembersContainer/MembersContainer';
import React from 'react';

const MemberScreen = () => {
  return (
    <div id='tourstep-members'>
      <MembersContainer />
    </div>
  );
};

export default MemberScreen;
