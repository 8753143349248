import { setNewNotification, setSocketData } from '@store/Notifications/notifications.actions';
import { RootState } from '@store/rootReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io, Socket } from 'socket.io-client';

export interface ServerToClientEvents {
  noArg: () => void;
  basicEmit: (a: number, b: string, c: Buffer) => void;
  withAck: (d: string, callback: (e: number) => void) => void;
}

export interface ClientToServerEvents {
  hello: () => void;
}

const NotificationsListener = () => {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState<Socket | ServerToClientEvents | ClientToServerEvents | any>();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const localState = useSelector((state: RootState) => state.User.Info.localState);
  const jwt = useSelector((state: RootState) => state.User.Info.jwt);

  useEffect(() => {
    if (localState === 'accessState' && jwt) {
      setSocket(
        io(`${process.env.REACT_APP_WEBSOCKET_URL ? process.env.REACT_APP_WEBSOCKET_URL : ''}/notifications`, {
          transports: ['websocket'],
          auth: {
            token: jwt,
          },
        })
      );
    }
  }, [jwt, localState]);

  useEffect(() => {
    if (socket !== undefined) {
      socket.emit('subscribe-notifications');
      setIsSubscribed(true);
      dispatch(setSocketData(socket));
    }
  }, [dispatch, socket]);

  useEffect(() => {
    if (socket !== undefined && isSubscribed === true) {
      socket.on('new-notification', (data: any) => {
        if (data) {
          dispatch(setNewNotification(true));
        }
      });
    }
  }, [socket, dispatch, isSubscribed]);

  return null;
};

export default NotificationsListener;
