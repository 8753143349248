import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setSelectedTabAction } from '@store/Organization/selectedTab/selectedTab.action';

import './styles.scss';

interface BackButtonProps {
  id?: 'statements' | 'pillars' | 'tags';
  labelId: string;
}

const BackButton = ({ id, labelId }: BackButtonProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    switch (id) {
      case 'statements':
        dispatch(setSelectedTabAction(1));
        break;
      case 'pillars':
        dispatch(setSelectedTabAction(2));
        break;
      default:
        break;
    }
    history.goBack();
  }, [id, history, dispatch]);

  return (
    <div className='component-backbutton'>
      <div className='container-button' onClick={handleGoBack}>
        <ArrowBackIcon />
        <p>
          <FormattedMessage id={labelId} />
        </p>
      </div>
    </div>
  );
};

export default BackButton;
