import { SET_INVALID_TOKEN_ERROR } from "./errors.actions";
import { ErrorsActions, ErrorsStateModel } from "./errors.models";

const initialState: ErrorsStateModel = {
  invalidToken: false
}

const errorsReducer = (
  state: ErrorsStateModel = initialState, 
  action: ErrorsActions
) => {
  switch(action.type){
    case SET_INVALID_TOKEN_ERROR:
      return {
        ...state,
        invalidToken: action.payload
      }
    default:
      return state
  }
}

export default errorsReducer; 