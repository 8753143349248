import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

interface ButtonTapBottomProps {
  onAction?: () => void;
  title: string;
  iconRight?: JSX.Element;
  iconLeft?: JSX.Element;
  disable?: boolean;
  isSubmit?: boolean;
  clasNamecontainerStyles?: string; 
}

const ButtonTapBottom = ({
  onAction,
  iconLeft,
  iconRight,
  title,
  disable = false,
  isSubmit = false,
  clasNamecontainerStyles
}: ButtonTapBottomProps) => {
  return (
    <div className={clasNamecontainerStyles ? clasNamecontainerStyles :`container-btn-tap-bottom`}>
      <Button type={isSubmit ? "submit": "button"} disabled={disable} onClick={onAction}>
        {iconLeft && iconLeft}
        <span id="txt-title">
          <FormattedMessage id={title} />
        </span>
        {iconRight && iconRight}
      </Button>
    </div>
  );
};

export default ButtonTapBottom;
