import React from 'react';
import { useIntl } from 'react-intl';
import SimpleTag from '../SimpleTag/SimpleTag';
import './style.scss';

interface StyledDropdownOptionTagProps {
  label: string
  type: string
}

const StyledDropdownOptionTag = (props: StyledDropdownOptionTagProps) => {
  const { label, type } = props;
  const intl = useIntl();

  return(
    <div className='styled-dropdown-option-tag'>
      <span>
        {label}
      </span>
      <SimpleTag 
        tag={intl.formatMessage({id: `${type}`})} 
        color="soft" 
        smallText
      />
    </div>
  )
}

export default StyledDropdownOptionTag;