import React from 'react';
import { CircularProgress } from '@material-ui/core';

import './styles.scss';

interface SpinnerProps {
  size?: number;
  thickness?: number;
  color?:
    | 'primary'
    | 'secondary'
    | 'complementary'
    | 'white'
    | 'error'
    | 'success'
    | 'warning'
    | 'gray';
}

const Spinner = (props: SpinnerProps) => {
  return (
    <CircularProgress
      className={`spinner${props.color !== 'primary' ? ' spinner-' + props.color : ''}`}
      size={props.size ? props.size : 15}
      color={props.color !== 'primary' ? 'inherit' : 'primary'}
      thickness={props.thickness ? props.thickness : 6}
    />
  );
};

export default Spinner;
