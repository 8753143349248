import { Grid, InputAdornment, InputLabel, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormikFieldModel } from '@models/formik.model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FormikStyledError from '../FormikStyledError/FormikStyledError';

import './styles.scss';

export interface FormikTextInputProps {
  adornment?: number
  autocomplete?: boolean;
  charsLimit?: number;
  cssId?: string
  field: FormikFieldModel
  hideCharsLimitDescription?: boolean
  labelId?: string;
  labelValues?: any;
  maxWidth?: string;
  meta: any;
  name: string
  placeholder?: string;
  placeholderValues?: any;
  sublabel?: string;
  type?: string;
  validateOnClick?: boolean;
  viewMode: boolean;
  onChange?: () => void;
  onError?: (error: any) => void;
  onTouchedInput?: () => void;
}

const FormikTextInput = (props: FormikTextInputProps) => {
  const { 
    viewMode, 
    meta,
    maxWidth,
    onError,
    onTouchedInput,
    type,
    adornment,
    field
  } = props

  const intl = useIntl();

  const placeholder = useMemo(
    () => (props.placeholder ? intl.formatMessage({ id: props.placeholder }, props.placeholderValues) : ''),
    [props.placeholder, intl, props.placeholderValues]
  );
  const [showPassword, setShowPassword] = useState('password')

  const handleClickShowPassword = useCallback(() => {
    setShowPassword('text');
  }, [],
  )

  const handleMouseDownPassword = useCallback((event: any) => {
    event.preventDefault();
    setShowPassword('password');
  }, [],
  )

  useEffect(() => {
    if ((meta.error && meta.touched) && onError) {
      onError(meta.error)
    } else if (meta.touched && onTouchedInput) {
      onTouchedInput()
    }
  }, [meta.error, meta.touched, onError, onTouchedInput])

  return (
    <div className={`formik-text-input`} style={{ maxWidth }}>
      {/* LABEL */}
      {props.labelId && (
        <InputLabel htmlFor={`input-${props.labelId}`}>
          <FormattedMessage id={props.labelId} values={props.labelValues} />
          {props.sublabel}
        </InputLabel>
      )}

      {/* INPUT / VIEWMODE */}
      {viewMode === true ? (
        <p className='view-mode' title={field.value}>
          {field.value}
        </p>
      ) : (
        <TextField
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          id={props.cssId}          
          variant='outlined'
          disabled={viewMode}
          placeholder={placeholder}
          inputProps={{
            maxLength: props.charsLimit ? props.charsLimit : 100,
            autoComplete: props.autocomplete,
          }}
          type={type === 'password' ? showPassword : type}
          InputProps={type === 'password' ? {
            endAdornment:
              <InputAdornment position="end" className="icon-password">
                <div
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={'iconContainer'}
                >
                  {showPassword === 'text' ? <Visibility /> : <VisibilityOff />}
                </div>
              </InputAdornment>
          } : adornment === 1 ? {
            endAdornment: <div className={`unit-style-${(field.value === "" || field.value === undefined) ? "light" : "strong"}`}></div>
          } : adornment === 2 ? {
            endAdornment: <div className={`unit-style-${(field.value === "" || field.value === undefined) ? "light" : "strong"}`}>%</div>
          } : adornment === 3 ? {
            startAdornment: <div className={`unit-style-${(field.value === "" || field.value === undefined) ? "light" : "strong"}`}>$</div>
          } : {}
          }
          error={
            props.validateOnClick
              ? meta.error && meta.touched
                ? true
                : false
              : meta.error
                ? true
                : false
          }
        />
      )}

      {/* EXTRA INFO */}
      <Grid container className={'extra-info'}>
        {/* ERROR MESSAGE */}
        <Grid item className={'error'} md={props.hideCharsLimitDescription ? 12 : 9}>
          {props.validateOnClick ? (
            <>{meta.error && meta.touched && <FormikStyledError message={meta.error} />}</>
          ) : (
            <>{meta.error !== undefined && <FormikStyledError message={meta.error} />}</>
          )}
        </Grid>

        {/* CHARSLIMIT MESSAGE */}
        {!props.hideCharsLimitDescription ?
          <Grid item className={'chars-info'} md={3}>
            {!viewMode && (
              <div className='char-area'>
                {props.charsLimit && (
                  <p className='chars-limit'>
                    {props.charsLimit ? `${field.value.length}/${props.charsLimit}` : ''}
                  </p>
                )}
              </div>
            )}
          </Grid>
          :
          <> </>
        }

      </Grid>
    </div>
  );
};

export default FormikTextInput;
