import { FormattedMessage } from "react-intl"

const PendingInvitationsColumns = () => {
  const columns = [
    /* {
      name: <FormattedMessage id="invitations.pending.table.header.name" />,
      selector: 'name',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)',
    }, */
    {
      name: <FormattedMessage id="invitations.pending.table.header.role" />,
      selector: 'role',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)',
    },
    /* {
      name: <FormattedMessage id="invitations.pending.table.header.jobtitle" />,
      selector: 'job_title',
      sortable: true,
      maxWidth: '400px',
      hide: 'sm'
    }, */
    {
      name: <FormattedMessage id="invitations.pending.table.header.invitedby" />,
      selector: 'invited_by',
      sortable: true,
      maxWidth: 'calc(100% - 300px / 5)',
      hide: 'sm'
    },
    {
      name: <FormattedMessage id="invitations.pending.table.header.email" />,
      selector: 'email',
      sortable: true,
      width: '300px',
      hide: 'sm'
    },
    {
      name: <FormattedMessage id="invitations.pending.table.header.invitationdate" />,
      selector: 'invitation_date',
      maxWidth: 'calc(100% - 300px / 5)',
      sortable: true
    },
  ];

  return columns
}

export default PendingInvitationsColumns