import './styles.scss'
import React from 'react'

interface OnboardingHeaderProps{
   publicName: string
   organizationsImage: string | null
}

const OnboardingHeader = (props: OnboardingHeaderProps) => {
   const { publicName, organizationsImage } = props
   return(
      <div className='onboarding-header'>
         
         <label htmlFor="" className='onboarding-image'>
            {organizationsImage !== null &&
               <img src={organizationsImage} className='image' alt='tegdu-org'/>
            }
         </label>
         
         <div className='onboarding-name'>
            <p>{publicName}</p>
         </div>
      </div>
   )
}

export default OnboardingHeader;