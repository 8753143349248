import { Pillar, PillarTags } from '@models/pillars.model';
import { NumberTag, PutPillarUpdateTags } from '@models/tags.model';
import { GraphTeamUsers } from '@models/teams.model';

/* TRANSFORM TEAMS TO idArray [] */
export const transformTeams = (teams: GraphTeamUsers[]) => {
  if (teams.length !== 0) {
    let aux: string[] = [];
    for (let k = 0; k < teams.length; k++) {
      if (teams[k].tgd_team === null) {
        break;
      } else {
        aux.push(teams[k].tgd_team.id);
      }
    }
    return aux;
  } else {
    return [];
  }
};

/* Pillars tags to object array */
export const transformPillarTags = (teams: PillarTags []) => {
  if(teams.length !== 0){
    let newTags: any [] = []
    for(let k = 0; k < teams.length; k++){
      if(teams[k].active === true){
        newTags.push(
          { 
            value: teams[k].tgd_tag.id ,
            label: teams[k].tgd_tag.name
          }
        )
      }
    }
    return newTags
  }else {
    return []
  }
}

/* Pillars tags to { id } object */
export const transformPillarTagsApiBody = (tags: string[]) => {
  if (tags.length !== 0) {
    let tagsTransformed: NumberTag[] = [];
    for (let k = 0; k < tags.length; k++) {
      tagsTransformed.push({
        id: parseInt(tags[k]),
      });
    }
    return tagsTransformed;
  } else {
    return [];
  }
};

/* Transform Pillar [] to number [] */
export const transformIdPillar = (pillar: Pillar[]) => {
  if (pillar.length !== 0) {
    const idsTransformed: number[] = [];
    for (let k = 0; k < pillar.length; k++) {
      idsTransformed.push(parseInt(pillar[k].id));
    }
    return idsTransformed;
  } else {
    return [];
  }
};

/* PUT PILLARS - PUT TAGS */

/* Pillars tags to { id } object */
export const transformPillarTagsApiBodyPut = (tags: string[], initialTags: PillarTags[]) => {
  let newTags: PutPillarUpdateTags[] = [];
  let removedTagsCounter = 0;

  /* INSERT NEW TAGS */
  for (let k = 0; k < tags.length; k++) {
    const alreadyExist = initialTags.find(
      (tag) => tag.tgd_tag.id === tags[k] && tag.active === true
    );
    if (alreadyExist === undefined) {
      newTags.push({
        tgd_tag: parseInt(tags[k]),
      });
    }
  }

  /* UPDATE REMOVED TAGS */

  for (let k = 0; k < initialTags.length; k++) {
    removedTagsCounter = 0;
    for (let j = 0; j < tags.length; j++) {
      if (initialTags[k].tgd_tag.id === tags[j]) {
        removedTagsCounter = removedTagsCounter + 1;
      }
    }

    if (removedTagsCounter === 0) {
      newTags.push({
        id: parseInt(initialTags[k].id),
        active: false,
      });
    }
  }

  return newTags;
};
