import { GET_ORGANIZATION_INFO } from '@graphql/organization.queries';
import { GqlGetOrganizationInfo, OrganizationInfo } from '@models/organizations.model';
import { getGraphqlData } from '@services/graphql.service';
import { RootState } from '@store/rootReducer';
import { PALETTE_DEFAULT_COLOR } from '@utils/theming';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  UpdateInfoAction,
  SetOrganizationBeginAction,
  SetOrganizationSuccessAction,
  SetOrganizationErrorAction,
  SetOrganizationIdAction,
  SetOrganizationLoadingAction,
  SetOrganizationColorAction
} from './info.model';

export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID';
export const SET_ORGANIZATION_INFO_BEGIN = 'SET_ORGANIZATION_INFO_BEGIN';
export const SET_ORGANIZATION_INFO_SUCCESS = 'SET_ORGANIZATION_INFO_SUCCESS';
export const SET_ORGANIZATION_INFO_ERROR = 'SET_ORGANIZATION_INFO_ERROR';
export const UPDATE_ORGANIZATION_INFO = 'UPDATE_ORGANIZATION_INFO';
export const SET_ORGANIZATION_LOADING = 'SET_ORGANIZATION_LOADING';
export const SET_ORGANIZATION_COLOR = 'SET_ORGANIZATION_COLOR';

export const setOrganizationLoading = (state: string): SetOrganizationLoadingAction => ({
  type: SET_ORGANIZATION_LOADING,
  payload: state,
});

export const setOrganizationId = (organizationId: number): SetOrganizationIdAction => ({
  type: SET_ORGANIZATION_ID,
  payload: organizationId,
});

export const setOrganizationColor = (color: string): SetOrganizationColorAction => ({
  type: SET_ORGANIZATION_COLOR,
  payload: color,
});

export const setOrganizationInfoBegin = (): SetOrganizationBeginAction => ({
  type: SET_ORGANIZATION_INFO_BEGIN,
});

export const setOrganizationInfoSuccess = (
  organizationInfo: OrganizationInfo
): SetOrganizationSuccessAction => ({
  type: SET_ORGANIZATION_INFO_SUCCESS,
  payload: organizationInfo,
});

export const setOrganizationInfoError = (error: any): SetOrganizationErrorAction => ({
  type: SET_ORGANIZATION_INFO_ERROR,
  payload: error,
});

export const getOrganizationData = (
  organizationId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  dispatch(setOrganizationInfoBegin());
  return await (getGraphqlData(GET_ORGANIZATION_INFO, {
    organizationId,
  }) as Promise<GqlGetOrganizationInfo>)
    .then((data) => {
      const info = data.tgdOrganization;
      const organization: OrganizationInfo = {
        publicName: info.public_name,
        legalName: info.legal_name,
        companyWebsite: info.company_website,
        color: info.color ? info.color : PALETTE_DEFAULT_COLOR,
        companySize: info.tgd_company_size_catalog ? info.tgd_company_size_catalog : null,
        frequencyGoals: info.tgd_frequency_goals_catalog ? info.tgd_frequency_goals_catalog : null,
        timeZone: info.tgd_timezone_catalog ? info.tgd_timezone_catalog : null,
      };
      dispatch(setOrganizationInfoSuccess(organization));
    })
    .catch((error) => {
      dispatch(setOrganizationInfoError(error));
    });
};

export const updateOrganizationInfo = (organizationInfo: OrganizationInfo): UpdateInfoAction => ({
  type: UPDATE_ORGANIZATION_INFO,
  payload: organizationInfo,
});
