import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import './styles.scss';

interface StatusComponentProps {
  status: boolean;
  size: 'table' | 'component';
}

const StatusComponent = (props: StatusComponentProps) => {
  return (
    <div className={'status-component'}>
      <Grid container className={'status-container'}>
        <Grid
          item
          md={3}
          className={`status-icon ${props.size} ${props.status === true ? 'Active' : 'Suspended'}`}
          data-tag='allowRowEvents'
        >
          {props.status === true && (
            <>
              {props.size === 'table' && <CheckIcon />}
              {props.size === 'component' && <CheckCircleIcon />}
            </>
          )}
          {props.status === false && <RemoveCircleIcon />}
        </Grid>
        <Grid
          item
          md={9}
          className={`status-text ${props.size} ${props.status === true ? 'Active' : 'Suspended'}`}
          data-tag='allowRowEvents'
        >
          {props.status === true && <FormattedMessage id={'status.active'} />}
          {props.status === false && <FormattedMessage id={'status.suspended'} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default StatusComponent;
