import { useQuery } from '@apollo/client';
import ModalUnarchiveTag from '@components/Settings/Tags/ModalUnarchiveTag/ModalUnarchiveTag';
import TagsTable from '@components/Settings/Tags/TagsTable/TagsTable'
import BackButton from '@components/Shared/BackButton/BackButton';
import { GET_TAGS } from '@graphql/settings.queries';
import { Grid } from '@material-ui/core';
import { GraphTagsModel, TagsModel } from '@models/helpful.model';
import { RootState } from '@store/rootReducer';
import React, { useState } from 'react'
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import './styles.scss'

const ArchivedTagsContainer = () => {
    const [showUnarchive, setShowUnarchive] = useState(false);
    const [infoTag, setInfoTag] = useState<TagsModel>({ id: '', name: '', tgd_tag_objectives: [] });
    const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
    const { data, loading, error, refetch } = useQuery<GraphTagsModel>(GET_TAGS, {
        variables: { idOrganization: organizationId, isArchived: true },
        fetchPolicy: "no-cache"
    })

    const onRowClicked = useCallback((data: TagsModel) => {
        setInfoTag(data);
        setShowUnarchive(true);
    }, [])

    return(
        <div className='archived-tags-container '>
            <Grid container className='archived-tags-sub-container'>
                <Grid item xs={12} className='top-info'>
                    <BackButton id='tags' labelId='settings.tab.tags.back.btn' />
                    <h1 className='archive-title'>
                        <FormattedMessage id='settings.tag.tags.archive.title' />
                    </h1>
                    <h3 className='archive-description'>
                        <FormattedMessage id='settings.tag.tags.archive.subtitle' />
                    </h3>
                </Grid>

                <Grid item xs={12} className='table-info'>
                    <TagsTable 
                        data={data?.tgdTags}
                        error={error}
                        loading={loading}
                        rowClicked={onRowClicked}
                    />
                </Grid>

                <ModalUnarchiveTag 
                    isOpen={showUnarchive}
                    onClose={() => setShowUnarchive(false)}
                    tagInfo={infoTag}
                    refetch={refetch}
                />
            </Grid>

            
        </div>
    )
}

export default ArchivedTagsContainer;