import TegduMenu from '../../Shared/TegduMenu/TegduMenu';
import { TegduSelectOptionsModel } from '@models/global.model';
import { useCallback, useMemo } from 'react';
import './styles.scss'; 

interface TegduDatePickerHeaderProps {
  date: Date
  months: string []
  nextMonthButtonDisabled: boolean
  prevMonthButtonDisabled: boolean
  years: number []
  changeYear: (year: number) => void
  changeMonth: (month: number) => void
  decreaseMonth: () => void
  increaseMonth: () => void
}

const TegduDatePickerHeader = (props: TegduDatePickerHeaderProps) => {
  const { 
    date, 
    months,
    //nextMonthButtonDisabled, 
    //prevMonthButtonDisabled,
    years,
    changeYear,
    changeMonth,
    //decreaseMonth,
    //increaseMonth
  } = props;

  const monthsArray = useMemo(() => {
    let tegduMonthsArray: TegduSelectOptionsModel [] = []
    
    months.forEach((month, index) => {
      tegduMonthsArray.push({
        value: index,
        label: month
      })
    })
    return tegduMonthsArray
  }, [months])

  const monthSelected: TegduSelectOptionsModel = useMemo(() => {
    return {
      value: date.getMonth(),
      label:  monthsArray[date.getMonth()].label
    }
  }, [date, monthsArray])

  const handleMonthValue = useCallback((value: number | string) => {
    changeMonth(value as number)
  }, [changeMonth])

  const yearsArray = useMemo(() => {
    let tegduYearsArray: TegduSelectOptionsModel [] = []

    years.forEach((year) => {
      tegduYearsArray.push({
        value: year,
        label: year.toString()
      })
    })

    return tegduYearsArray
  }, [years])

  const yearSelected: TegduSelectOptionsModel = useMemo(() => {
    return {
      value: date.getFullYear(),
      label: date.getFullYear().toString()
    }
  }, [date])

  const handleYearValue = useCallback((value: any) => {
    changeYear(value)
  }, [changeYear])

  return(
    <div className="tegdu-datepicker-header">
      {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {"<"}
      </button> */}
      <div className='datepicker-months'>
        <TegduMenu 
          defaultValue={monthSelected}
          type="datepicker"
          handleSelectedValue={handleMonthValue}
          options={monthsArray}
        />
      </div>

      <div className='datepicker-years'>
        <TegduMenu 
          defaultValue={yearSelected}
          type="datepicker"
          handleSelectedValue={handleYearValue}
          options={yearsArray}
        />
      </div>      

      {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {">"}
      </button> */}
    </div>
  )
}

export default TegduDatePickerHeader;