import IndividualMemberContainer from '@containers/Members/IndividualMemberContainer/IndividualMemberContainer';
import React from 'react';

const IndividualMember = () => {
  
  return <IndividualMemberContainer />;
  
};

export default IndividualMember;
