import { GET_ROLES_PLAIN } from "@graphql/members.queries";
import { getGraphqlData } from "@services/graphql.service";
import { Dispatch } from "redux";
import { OptionsModel } from '@models/helpful.model';

export const SET_ROLES_LOADING = "SET_ROLES_LOADING";
export const SET_ROLES_CATALOG = "SET_ROLES_CATALOG";

export const setRolesLoading = (isLoading: boolean) => {
  return {
    type: SET_ROLES_LOADING,
    payload: isLoading
  }
}

export const setRolesCatalog = (roles: OptionsModel []) => {
  return {
    type: SET_ROLES_CATALOG,
    payload: roles
  }
}

export const getRolesCatalog = () => async (dispatch: Dispatch) => {
  dispatch(setRolesLoading(true))
  try {
    const data = await getGraphqlData(GET_ROLES_PLAIN);
    dispatch(setRolesCatalog(data.roles.filter((element: any) => element.visible === true)))
    dispatch(setRolesLoading(false))
  } catch {
    dispatch(setRolesLoading(false))
  }
}