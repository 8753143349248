import { SetTourHighlightMenuAction, SetTourIsOpenAction } from './shared.model';

export const SET_ADD_MEMBER_MODAL = "SET_ADD_MEMBER_MODAL";
export const SET_RESET_PASSWORD_REQUEST = "SET_RESET_PASSWORD_REQUEST";
export const SET_RESET_PASSWORD_REQUEST_RESTART = "SET_RESET_PASSWORD_REQUEST_RESTART";
export const SET_TOUR_IS_OPEN = "SET_TOUR_IS_OPEN";
export const SET_HIGHLIGHT_MENU_OPTION = "SET_HIGHLIGHT_MENU_OPTION";

export const setAddMemberModal = (isOpen: boolean) => {
  return{
    type: SET_ADD_MEMBER_MODAL,
    payload: isOpen
  }
}

export const setResetPasswordRequest = () => {
  return {
    type: SET_RESET_PASSWORD_REQUEST
  }
}

export const setResetPasswordRequestRestart = () => {
  return {
    type: SET_RESET_PASSWORD_REQUEST_RESTART
  }
}

export const setTourIsOpen = (isOpen: boolean): SetTourIsOpenAction => {
  return {
    type: SET_TOUR_IS_OPEN,
    payload: { isOpen }
  }
}

export const setTourHighlightMenu = (highlight: boolean): SetTourHighlightMenuAction => {
  return {
    type: SET_HIGHLIGHT_MENU_OPTION,
    payload: { highlight }
  }
}
