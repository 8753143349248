import { useEffect, useState } from "react";

declare global {
	interface Window {
		opera: any;
		MSStream: any;
	}
}
export const useIsMobile = ()=>{
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const getMobileOperatingSystem = () => {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if (/android/i.test(userAgent)) {
			setIsMobile(true)
		}
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			setIsMobile(true)
		}
	};
	useEffect(() => {
		getMobileOperatingSystem()
	}, [])
	
	return{
		isMobile
	}
}