import {
  GET_CULTURAL_ATTRIBUTES_AS_CATALOGUE,
  GET_OKR_UNITS_AS_CATALOGUE,
  GET_TAGS_AS_CATALOGUE,
  GET_TIMEFRAMES_AS_CATALOGUE
} from "@graphql/catalogues.queries";
import { GET_OBJECTIVE_DATA } from "@graphql/objectives.queries";
import {
  CulturalAttrobutesAsCatalogue,
  OkrUnitsAsCatalogue,
  TagsAsCatalogue,
  TimeframesAsCatalogue
} from "@models/catalogs.models";
import { GraphqlQueryResponse, TegduSelectOptionsModel } from "@models/global.model";
import { CreateObjectivePost, EditObjectivePut, GetObjectiveDataGraphResponse } from "@models/objectives.models";
import { GetAllTeamsResponse } from "@models/teams.model";
import { graphQlQuery } from "@services/graphql.service";
import { getMembersCatalogue } from "@services/members.service";
import { createObjectiveService, updateObjectiveService, updateObjectiveStatus } from "@services/objectives.service";
import { getAllTeams } from "@services/teams.service";
import { store } from "@store/store";
import { OBJECTIVE_STATUS } from "@utils/objectives";
import {
  isTeamLeader,
  obtainMembersLeaders,
  teamsToOptionsModel,
  timeframesToOptionsModel
} from "@utils/objectives/requiredCatalogs.objectives.utils";
import { USER_ROLE } from "@utils/user";

import { Dispatch } from "redux";
import { GetObjectivesInitialData } from "./objectivesMainActions.model";

export const SET_INITIALIZING_OBJECTIVES_DATA = "SET_INITIALIZING_OBJECTIVES_DATA";
export const SET_IS_CREATE_OBJECTIVE_LOADING = "SET_IS_CREATE_OBJECTIVE_LOADING";
export const SET_IS_EDIT_OBJECTIVE_LOADING = "SET_IS_EDIT_OBJECTIVE_LOADING";

export const setIsEditingObjectiveLoading = (isLoading: boolean) => {
  return {
    type: SET_IS_EDIT_OBJECTIVE_LOADING,
    payload: isLoading
  }
}

export const setIsCreateObjectiveLoading = (isLoading: boolean) => {
  return {
    type: SET_IS_CREATE_OBJECTIVE_LOADING,
    payload: isLoading
  }
}

export const setIntializingObjectivesData = (isLoading: boolean) => {
  return {
    type: SET_INITIALIZING_OBJECTIVES_DATA,
    payload: isLoading
  }
}



export const getObjectivesInitialData = (
  organizationId: number,
  handleData: (data: GetObjectivesInitialData) => void,
  objectiveId?: number
  ) => async (dispatch: Dispatch) => {
  
  let teamsObtained: GetAllTeamsResponse [] = [];
  let obtainedData: GetObjectivesInitialData = { 
    teams: [], 
    timeframes: [], 
    members: [], 
    culturalAttributes: [], 
    tags: [],
    okrUnits: [],
    objectiveCurrentValue: null,
    membersLeaders: [],
    isLeader: false
  };
  dispatch(setIntializingObjectivesData(true))
  
  try {
    const userRole = store.getState().User.Info.user.role.id;
    const userId = store.getState().User.Info.user.id;

    await Promise.allSettled([
      graphQlQuery<TimeframesAsCatalogue>(GET_TIMEFRAMES_AS_CATALOGUE, { organizationId: organizationId}),
      getAllTeams(organizationId),
      graphQlQuery<CulturalAttrobutesAsCatalogue>(GET_CULTURAL_ATTRIBUTES_AS_CATALOGUE, { organizationId: organizationId}),
      getMembersCatalogue(organizationId),
      graphQlQuery<TagsAsCatalogue>(GET_TAGS_AS_CATALOGUE, {organizationId: organizationId, isArchived: false}),
      graphQlQuery<OkrUnitsAsCatalogue>(GET_OKR_UNITS_AS_CATALOGUE),
      (objectiveId !== undefined) ? graphQlQuery<GetObjectiveDataGraphResponse>(GET_OBJECTIVE_DATA, { objectiveId:  objectiveId }) : null
    ]).then((results: any) => {      
      for(let k = 0; k < results.length; k++){
        if(results[k].status === "fulfilled"){
          switch(k){
            case 0:
              const timeframes = results[k].value as GraphqlQueryResponse<TimeframesAsCatalogue>
              obtainedData.timeframes = timeframesToOptionsModel(timeframes.data.tgdOrganization.tgd_timeframes);
              break;
            case 1:
              const teams = results[k].value as GetAllTeamsResponse []
              teamsObtained = teams;
              if(userRole === USER_ROLE.member || userRole === USER_ROLE.manager){
                obtainedData.teams = isTeamLeader(teams, userId)
                obtainedData.isLeader = obtainedData.teams.length > 0 ? true : false
              }else {
                obtainedData.teams = teamsToOptionsModel(teams)
                obtainedData.isLeader = isTeamLeader(teams, userId).length > 0 ? true : false
              }              
              break;
            case 2:
              const culturalAttributes = results[k].value as GraphqlQueryResponse<CulturalAttrobutesAsCatalogue>
              obtainedData.culturalAttributes = culturalAttributes.data.tgdPilars
              break;
            case 3:
              const members = results[k].value as TegduSelectOptionsModel []
              obtainedData.members = members
              break;
            case 4:
              const tags = results[k].value as GraphqlQueryResponse<TagsAsCatalogue>
              obtainedData.tags = tags.data.tgdTags
              break
            case 5:
              const okrUnits = results[k].value as GraphqlQueryResponse<OkrUnitsAsCatalogue>
              obtainedData.okrUnits = okrUnits.data.tgdKrUnitsCatalogs
              break;
            case 6:
              const objectiveCurrentValues = results[k].value as GraphqlQueryResponse<GetObjectiveDataGraphResponse> | null
              obtainedData.objectiveCurrentValue = (objectiveCurrentValues === null) ? null : objectiveCurrentValues.data.tgdObjective;
              break
            default:
              break;
          } 
        }
      }
    })

    if(teamsObtained.length > 0 && obtainedData.members.length > 0){
      obtainedData.membersLeaders = obtainMembersLeaders(teamsObtained, obtainedData.members)
    } 
    
    //console.log("OBTAINED DATA: ", obtainedData)
    handleData(obtainedData)
    dispatch(setIntializingObjectivesData(false))
  } catch {
    dispatch(setIntializingObjectivesData(false))
  }
}

export const createObjectiveAction = (
  data: CreateObjectivePost,
  handleSuccess: () => void
) => async(dispatch: Dispatch) => {
  dispatch(setIsCreateObjectiveLoading(true))
  try {
    await createObjectiveService(data)
    handleSuccess()
    dispatch(setIsCreateObjectiveLoading(false))
  } catch {
    dispatch(setIsCreateObjectiveLoading(false))
  }
}

export const editObjectiveAction = (
  data: EditObjectivePut,
  objectiveId: string,
  handleSuccess: () => void,
  objectiveStatus?: OBJECTIVE_STATUS | null,
  ) => async (dispatch: Dispatch) => {
  dispatch(setIsEditingObjectiveLoading(true))
  try {        
    await updateObjectiveService(data, objectiveId)
    if(objectiveStatus === OBJECTIVE_STATUS.draft){
      try {
        await updateObjectiveStatus({id: objectiveId, status: OBJECTIVE_STATUS.active})
        handleSuccess()
        dispatch(setIsEditingObjectiveLoading(false))
      } catch {
        handleSuccess()
        dispatch(setIsEditingObjectiveLoading(false))
      }
    }else {
      handleSuccess()
      dispatch(setIsEditingObjectiveLoading(false))
    }
    
  } catch {
    dispatch(setIsEditingObjectiveLoading(false))
  }
}