import React from 'react'
import ReviewContainer from "../../containers/Reviews/ReviewsContainer"


const ReviewsScreen = () => {
  return (
    <ReviewContainer />
  )
}

export { ReviewsScreen as ReviewsScreenMobile }