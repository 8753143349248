import { FormikTextAreaProps } from "@components/FormikComponents/FormikTextArea/FormikTextArea";
import { FastField } from "formik";
import useFormikShouldUpdate from "hooks/useFormikShouldUpdate";

type FormikTextAreaPropsHOC = Omit<FormikTextAreaProps, 'field' | 'meta'>

const withTextAreaFastField = (WrappedComponent: (props: FormikTextAreaProps) => JSX.Element) => {
   const WithTextAreaFastField = (props: FormikTextAreaPropsHOC) => {
      const { viewMode, name } = props
      const shouldUpdate = useFormikShouldUpdate(viewMode)
      return(
         <FastField name={name} shouldUpdate={shouldUpdate}>
            {({field, meta} : any) => (
               <WrappedComponent 
                  {...props}
                  field={field}
                  name={field.name}
                  meta={meta}
               />
            )}
         </FastField>
      )
   }
   return WithTextAreaFastField;
}

export default withTextAreaFastField;