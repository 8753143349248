import { OBJECTIVES_ROLE_FILTER } from "lib/enums/objectives.enum";
import {getObjectiveReportService} from "@services/objectives.service";
import {store} from "@store/store";
import {Dispatch} from "redux";

export const SET_OBJECTIVES_SECTION_ROLE_FILTER = "SET_OBJECTIVES_SECTION_ROLE_FILTER";
export const SET_OBJECTIVES_SECTION_STATUS_FILTER = "SET_OBJECTIVES_SECTION_STATUS_FILTER";
export const SET_OBJECTIVE_REPORT_LOADING = "SET OBJECTIVE REPORT LOADING";

export const setObjectiveReportLoading = (isLoading: boolean) => {
  return {
    type: SET_OBJECTIVE_REPORT_LOADING,
    payload: isLoading
  }
}

export const setObjectivesRoleFilter = (filterSelected: OBJECTIVES_ROLE_FILTER) => {
  return {
    type: SET_OBJECTIVES_SECTION_ROLE_FILTER,
    payload: filterSelected
  }
}

export const setObjectivesStatusFilter = (statusFilterSelected: number) => {
  return {
    type: SET_OBJECTIVES_SECTION_STATUS_FILTER,
    payload: statusFilterSelected
  }
}
export const getObjectivesReport = ( handleError: () => void ) => async (dispatch:Dispatch ) =>{
  dispatch(setObjectiveReportLoading(true))
  try{
    const status = store.getState().Objectives.ObjectiveSection.statusFilterSelected
    const role = store.getState().Objectives.ObjectiveSection.okrFilterRoleSelected
    const data = await getObjectiveReportService(
        (status === null) ? "all":status,
        (role === 0) ? "all": role
    )
    const csvContent = `data:text/csv;charset=utf-8, ${data.data}`;
    const encodedUri = encodeURI(csvContent);
    const downloadLink = document.createElement('a');
    const fileName = `Tegdu_objectives.csv`;

    downloadLink.href = encodedUri;
    downloadLink.download = fileName;
    downloadLink.click();
    dispatch(setObjectiveReportLoading(false))
  }catch{
    dispatch(setObjectiveReportLoading(false))
    handleError()
  }
}
