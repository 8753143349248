import { Tooltip } from '@material-ui/core';
import React from 'react'
import './styles.scss'

export interface SimpleTagProps {
    color: 'primary' | 'secondary' | 'archived' | 'soft',
    tag: string
    width?: string
    smallText?: boolean
}

const SimpleTag = (props: SimpleTagProps) => {
    return (
        <div className={`simple-tag ${props.color}`} style={{ width: `${props.width ? props.width : ''}` }}>
            <Tooltip title={props.tag} placement="top">
                <div className={`text ${props.smallText ? 'small' : ''}`}>
                    {props.tag}
                </div>
            </Tooltip>
        </div>
    )
}

export default SimpleTag;