import { Button } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import useToastNotification from 'hooks/useToastNotification';

import './styles.scss';

interface CopyToClipboardProps {
  value: string;
}

const CopyToClipboard = ({ value }: CopyToClipboardProps) => {
  const toastNotification = useToastNotification();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    navigator.clipboard.writeText(value);
    toastNotification('objective.idcopied', false);
  };

  return (
    <>
      <Button
        className='flat button-copy'
        disabled={!value}
        onClick={handleClick}
        endIcon={<FileCopyOutlinedIcon />}
      >
        <span>{value}</span>
      </Button>
    </>
  );
};

export default CopyToClipboard;
