import { setCloseAdd, setOpenAdd } from '@store/Objectives/ObjectivesOpenAdd/ObjectivesDetails/ObjectivesOpenAdd.action';
import { RootState } from '@store/rootReducer';
import { useDispatch, useSelector } from 'react-redux'

const useOpenAddObjective = () => {
  const { isOpenAdd } = useSelector((state: RootState) => state.Objectives.ObjectiveAddShow);
  const dispatch = useDispatch();

  const onOpen = ()=>{
    dispatch(setOpenAdd())
  }
  const onClose = ()=>{
    dispatch(setCloseAdd())
  }
  return {
    onOpen,
    onClose,
    isOpenAdd
  }
}

export default useOpenAddObjective