import LettersCircle from '@components/Shared/LettersCircle/LettersCircle'
import { Drawer } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { RootState } from '@store/rootReducer';
import { logout } from '@services/user.service';
import { clearLoggedStatus } from '@utils/localStorageAux';
import { userLogout } from '@store/User/Info/info.actions';
import { FormattedMessage } from 'react-intl';
import "./styles.scss"

interface SlideBarProps{
  closeResponsiveMenu: () => void;
  isResponsiveMenuOpen: boolean;
}

const SlideBar = ({closeResponsiveMenu, isResponsiveMenuOpen}: SlideBarProps) => {
  const { first_name, last_name } = useSelector((state: RootState) => state.User.Info.user);
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();
  const onLogout = useCallback(async () => {
    try {
      await logout();
      clearLoggedStatus()
      dispatch(userLogout());
    } catch{
      
    }
  }, [dispatch]);

  return (
    <div className={'sidebar__content'}>
    <Drawer
      onClick={handleDrawerOpen}
      variant='permanent'

      classes={{
        paper: clsx(`sidebar__drawer ${open ? 'open' : 'close'}`),
      }}
    >

      <div className='sidbar__footer'>
          <div>
            <h1>Log out</h1>
          </div>
        <div className='logo__container'>
          <img src='/images/tegdu_gray.png' alt='logo' />
        </div>
      </div>
    </Drawer>

    <Drawer 
      open={isResponsiveMenuOpen} 
      anchor='right' 
      onBackdropClick={closeResponsiveMenu}
      onClose={closeResponsiveMenu}
      className='sidebar__responsive'
    >
      <div className='sidebar-resp-content'>
        <div className='username-container' onClick={() => console.log("")}>
          <div className='circle'>
            <LettersCircle name={`${first_name} ${last_name}`} size='md' />
          </div>
          <div className='name'>
            <h4>{`${first_name} ${last_name}`}</h4>
          </div>            
        </div>

        <div className='options'>

        </div>
        <div className='footer'>
          <div onClick={onLogout} className='container-logout'>
            <p><FormattedMessage id={"sidebar.logout"}/></p>
          </div>
          <div className='logo__container'>
            <img src='/images/tegdu_gray.png' alt='logo' />
          </div>
        </div>
      </div>
    </Drawer>

  </div>
  )
}

export  {SlideBar as SlideBarMobile }