import React, { useMemo } from 'react';
import { CircularProgress as Progress } from '@material-ui/core';
import { truncNumber } from '@utils/numbers';

import './styles.scss';

interface CircularProgressProps {
  value: number; // 1 - 100
}

const CircularProgress = ({ value }: CircularProgressProps) => {
  const label: string = useMemo(() => (value > 1 ? '1.0' : truncNumber(value)), [value]);

  return (
    <div className='component-circularprogress'>
      <Progress
        variant='determinate'
        color='secondary'
        value={value > 1 ? 100 : value * 100}
        thickness={5}
        size={32}
      />
      <span>{label}</span>
    </div>
  );
};

export default CircularProgress;
