import './styles.scss';

export interface TegduTagComponentProps {
  type: 'timeframe-options'
  element: JSX.Element
}

const TegduTagComponent = (props: TegduTagComponentProps) => {
  const { type, element } = props;

  return(
    <div className={`tegdu-tag-component ${type}`}>
      {element}
    </div>
  )
}

export default TegduTagComponent;