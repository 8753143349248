import { useEffect, useState } from "react";

const useCheckScroll = () => {
  const [isTopScroll, setIsTopScroll] = useState<boolean>(true);

  const isTop = () => {
    if (window.scrollY === 0) {
      setIsTopScroll(true);
    } else {
      setIsTopScroll(false);
    }
  };

  const checkIfIsTopAnyElement = (scrollTop: number) => {
    if (scrollTop === 0) {
      setIsTopScroll(true);
    } else {
      setIsTopScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isTop);
    return () => {
      window.removeEventListener("scroll", isTop);
    };
  }, []);

  return {
    isTopScroll,
    checkIfIsTopAnyElement,
  };
};

export default useCheckScroll;
