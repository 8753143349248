import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ItemIndividualRate from '../ItemIndividualRate/ItemIndividualRate';
import { ApiGetObjectiveUser } from '@models/objetives.model';
import Spinner from '@components/Shared/Spinner/Spinner';
import { ObjectiveScore } from '@models/score.model';

import './styles.scss';

interface FormIndividualRateProps {
  loadingUsers: boolean;
  objectiveUsers: ApiGetObjectiveUser[];
  onCancel: () => void;
  onSubmit: (scores: ObjectiveScore[]) => void;
  loading: boolean;
}

const FormIndividualRate = ({
  loadingUsers,
  objectiveUsers,
  onCancel,
  onSubmit,
  loading,
}: FormIndividualRateProps) => {
  const [scores, setScores] = useState<ObjectiveScore[]>([]);

  const handleSubmit = () => {
    onSubmit(scores);
  };

  const updateScores = useCallback((objUserId: number, score: number) => {
    setScores((prevState) => {
      const index = prevState.findIndex((state) => state.tgd_obj_role_user === objUserId);
      prevState[index] = {
        tgd_obj_role_user: objUserId,
        score,
      };
      return prevState;
    });
  }, []);

  useEffect(() => {
    const objUsersScores: ObjectiveScore[] = objectiveUsers.map((objUser) => ({
      tgd_obj_role_user: objUser.id,
      score: 0,
    }));
    setScores(objUsersScores);
  }, [objectiveUsers]);

  return (
    <div className='component-form-individualrate'>
      {loadingUsers ? (
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      ) : (
        <>
          {objectiveUsers.map((objUser, index) => (
            <Fragment key={index}>
              <ItemIndividualRate
                objUserId={objUser.id}
                user={`${objUser.user.first_name} ${
                  objUser.user.last_name ? objUser.user.last_name : ''
                }`}
                role={objUser.tgd_role.name}
                onChange={updateScores}
              />
            </Fragment>
          ))}
        </>
      )}
      <div className='score-rate-buttons'>
        <Button className='secondary' onClick={onCancel} disabled={loading}>
          <FormattedMessage id='score.rate.form.cancel' />
        </Button>
        <Button type='submit' className='primary' onClick={handleSubmit} disabled={loading}>
          {loading ? <Spinner /> : <></>}
          <FormattedMessage id='score.rate.form.save' />
        </Button>
      </div>
    </div>
  );
};

export default FormIndividualRate;
