import RoundButton from "@components/Shared/RoundButton/RoundButton";
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from "lib/enums/objectives.enum";
import { useCallback } from "react";

interface ObjectivesTypeSelectorProps {
  value: OBJECTIVE_TYPES | null
  isTeamOptionAvailable: boolean
  action: CREATE_OBJECTIVES_ACTIONS
  isOrganizationOptionAvailable: boolean  
  handleObjectiveType: (type: OBJECTIVE_TYPES) => void
}


const ObjectivesTypeSelector = (props: ObjectivesTypeSelectorProps) => {
  const { 
    value, 
    isTeamOptionAvailable, 
    isOrganizationOptionAvailable, 
    action, 
    handleObjectiveType 
  } = props;

  const getObjectiveType = useCallback((type: number) => {
    handleObjectiveType(type)
  }, [handleObjectiveType])

  return(
    <div className="objectives-type-selector">
      <RoundButton 
        selected={value === OBJECTIVE_TYPES.individual}
        disabled={action === CREATE_OBJECTIVES_ACTIONS.create ? false : true}
        text={'objectiveform.type.individual'}
        onButtonClick={getObjectiveType}
        type={OBJECTIVE_TYPES.individual}
      />

      <RoundButton 
        selected={value === OBJECTIVE_TYPES.team}
        disabled={action === CREATE_OBJECTIVES_ACTIONS.create ? !isTeamOptionAvailable : true}
        onButtonClick={getObjectiveType}
        type={OBJECTIVE_TYPES.team}
        text={'objectiveform.type.team'}
      />

      <RoundButton 
        selected={value === OBJECTIVE_TYPES.organization}
        disabled={action === CREATE_OBJECTIVES_ACTIONS.create ? !isOrganizationOptionAvailable : true }
        onButtonClick={getObjectiveType}
        type={OBJECTIVE_TYPES.organization}
        text={'objectiveform.type.organization'}
      />
    </div>
  )
}

export default ObjectivesTypeSelector;