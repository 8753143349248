import { useIntl } from 'react-intl';
import { OBJECTIVE_LOG_TYPE, OBJECTIVE_STATUS, OBJECTIVE_STATUS_DESCRIPTION } from './objectives';

export const useLogger = () => {
  const { formatMessage } = useIntl();
  const translateObjLogTypes = (type: string, status: number): string => {
    switch (type) {
      case OBJECTIVE_LOG_TYPE.creation:
        switch (status) {
          case OBJECTIVE_STATUS.draft:
            return formatMessage({ id: 'objectives.option.logs.status.saved' });
          default:
            return formatMessage({ id: 'objectives.option.logs.status.objective' });
        }
      case OBJECTIVE_LOG_TYPE.assignment:
        return formatMessage({ id: 'objectives.option.logs.type.assignment' });
      case OBJECTIVE_LOG_TYPE.edit:
        return formatMessage({ id: 'objectives.option.logs.type.objective' });
      case OBJECTIVE_LOG_TYPE.progress:
        return formatMessage({ id: 'added progress to this objective' });
      case OBJECTIVE_LOG_TYPE.status:
        switch (status) {
          case OBJECTIVE_STATUS.closed:
            return formatMessage({ id: 'objectives.option.logs.type.closed' });
          default:
            return formatMessage(
              { id: 'objectives.option.logs.type.update' },
              { status_des: OBJECTIVE_STATUS_DESCRIPTION[status] }
            );
        }
      default:
        return `edited this objective`;
    }
  };
  return {
    translateObjLogTypes,
  };
};
