import { TegduSelectOptionsModel } from '@models/global.model';
import { CULTURAL_ATTRIBUTES_TYPES } from 'lib/enums/organizations.enums';
import * as Yup from 'yup';

export interface CulturalAttributesFormObjectModel {
  id: number
  name: string;
  valid_until: Date;
  description: string;
  type: CULTURAL_ATTRIBUTES_TYPES
  owner: TegduSelectOptionsModel | null
  coach: TegduSelectOptionsModel []
  tgd_pilar_tags: TegduSelectOptionsModel [] 
}

export interface CulturalAttributesFormModel {
  culturalAttribute: CulturalAttributesFormObjectModel []
}

export const culturalAttributesFormInitialValue: CulturalAttributesFormModel = {
  culturalAttribute: [{
    id: -1,
    name: "",
    valid_until: new Date(),
    description: "",
    type: CULTURAL_ATTRIBUTES_TYPES.pillar,
    owner: null,
    coach: [],
    tgd_pilar_tags: []
  }]
}

export const culturalAttributesFormSchema = Yup.object().shape({
  culturalAttribute: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      description: Yup.string().required(),
      owner: Yup.object().shape({
        value: Yup.number().required()  
      }),
    })
  )  
})