import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomDot from "../CustomDot/CustomDot";
import "./styles.scss";

const responsive = {
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
interface CarouselPointsProps {
  pointsItems?: any;
}

const CarouselPoints = ({ pointsItems }: CarouselPointsProps) => {
  return (
    <Carousel
      arrows={false}
      className="container-carousel"
      responsive={responsive}
      showDots
      customDot={<CustomDot />}
      dotListClass={"list"}
    >
      {pointsItems !== undefined ? (
        pointsItems?.map((e: any, index: any) => (
          <div key={index}>
            <div className="container-subtitle">
              <span>{e?.name}</span>
            </div>
            <div>{e?.description}</div>
          </div>
        ))
      ) : (
        <div></div>
      )}
    </Carousel>
  );
};

export default CarouselPoints;
