import { ClientToServerEvents, ServerToClientEvents } from "@hubs/NotificationsListener";
import { RingNotificationsResponse } from "@models/notifications.models";
import { getNotificationsService } from "@services/notifications.service";
import { Dispatch } from "redux";
import { Socket } from "socket.io-client";


export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const SET_RING_NOTIFICATIONS_LOADING = "SET_RING_NOTIFICATIONS_LOADING";
export const SET_RING_NOTIFICATIONS_DATA = "SET_RING_NOTIFICATIONS_DATA";
export const SET_SOCKET_DATA = "SET_SOCKET_DATA"
export const SET_RING_NOTIFICATIONS_ERROR = "SET_RING_NOTIFICATIONS_ERROR"

export const setRingNotificationsData = (data: RingNotificationsResponse) => {
  return {
    type: SET_RING_NOTIFICATIONS_DATA,
    payload: data
  }
}

export const setNewNotification = (isNewNotification: boolean) => {
  return {
    type: SET_NEW_NOTIFICATION,
    payload: isNewNotification
  }
}

export const setRingNotificationsLoading = (isLoading: boolean) => {
  return {
    type: SET_RING_NOTIFICATIONS_LOADING,
    payload: isLoading
  }
}

export const setRingNotificationsError = (isError: boolean) => {
  return {
    type: SET_RING_NOTIFICATIONS_ERROR,
    payload: isError
  }
}

export const setSocketData = (data: Socket | ServerToClientEvents | ClientToServerEvents | any) => {
  return {
    type: SET_SOCKET_DATA,
    payload: data
  }
}

export const requestRingNotifications = () => async (dispatch: Dispatch) => {
  dispatch(setRingNotificationsLoading(true))
  dispatch(setRingNotificationsError(false))
  try {
    const notifications = await getNotificationsService();
    dispatch(setRingNotificationsData(notifications))
    dispatch(setRingNotificationsLoading(false))
  } catch {
    dispatch(setRingNotificationsLoading(false))
    dispatch(setRingNotificationsError(true))
  }
}