import PendingTable from '@components/Invitations/PendingTable/PendingTable';
import Searchbar from '@components/Shared/Searchbar/Searchbar';
import { Grid } from '@material-ui/core'
import { INVITATIONS_STATUS } from '@models/invitations.model';
import { getInvitationsData } from '@store/Invitations/invitations.action';
import { RootState } from '@store/rootReducer';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

export interface TableData {
  name: string;
  role: string;
  job_title: string | undefined;
  invited_by: string;
  email: string;
}

const PendingInvitationsContainer = () => {
  const pendingInvitationsData = useSelector((state: RootState) => state.Invitations.invitations);
  const loadingInvitations = useSelector((state: RootState) => state.Invitations.isInvitationsLoading);
  const [searchText, setSearchText] = useState<string>('');
  const [error, setError] = useState<any>(undefined);
  const dispatch = useDispatch()

  const onSearchTerm = useCallback((term) => {
    setSearchText(term);
  }, []);

  const retryGetInvitationsData = useCallback(() => {
    if (pendingInvitationsData.data?.length! > 0) {
      try {
        dispatch(getInvitationsData(INVITATIONS_STATUS.pending))
      } catch {
        setError(true)
      }
    }
  }, [dispatch, pendingInvitationsData.data?.length])

  const formattedData: TableData[] | null = pendingInvitationsData.data && pendingInvitationsData.data!.map((values) => ({
    name:
      values.user_created?.first_name! === undefined
        ? "- - - - -" : values.user_created?.first_name! + " " + values.user_created?.last_name!,
    role: values.role.role === undefined ? "- - - - -" : values.role?.role,
    job_title: values.user_created?.job_title === undefined ? "- - - - -" : values.user_created?.job_title,
    invited_by: values.invited_by?.first_name === undefined ? "- - - - -" : values.invited_by?.first_name + " " + values.invited_by?.last_name,
    email: values?.email === undefined ? "- - - - -" : values?.email,
    invitation_date: values?.invitation_date === undefined ? "- - - - -" : moment(values?.invitation_date).format('MM/DD/YYYY'),
    id: values.id
  }))

  const data: TableData[] | null = useMemo(() =>
    formattedData ? formattedData?.filter(
      (item) =>
        item.email.toLowerCase().includes(searchText.toLowerCase()) ||
        item.role.toLowerCase().includes(searchText.toLowerCase())
    )
      : [],
    [formattedData, searchText]
  );

  useEffect(() => {
    dispatch(getInvitationsData(INVITATIONS_STATUS.pending))
  }, [dispatch])

  return (
    <div className='pending-container'>
      <Grid className='container-filters'>
        <Searchbar
          intlId='invitations.pending.searchbar.placeholder'
          onSearch={(term) => onSearchTerm(term)}
          className='teams-searchbar'
          valueInput={searchText}
        />
      </Grid>
      <PendingTable
        data={data}
        loading={loadingInvitations.isLoading}
        error={error}
        retryCallback={retryGetInvitationsData} />
    </div>
  )
}

export default PendingInvitationsContainer