import { TeamTableData } from '@models/teams.model';

export const TOUR_TEAMS_DATA: TeamTableData[] =[
  {
    id: '0',
    name: 'Hr Department',
    create_time: '2023-07-13T10:30:00.000Z',
    leads: 'Emily Smith',
    members: 'Daniel Johnson, Emily Smith',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Sales Team',
    create_time: '2023-07-13T10:45:00.000Z',
    leads: 'Michael Davis',
    members: 'Sophia Anderson, Michael Davis',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Research and Development',
    create_time: '2023-07-13T11:00:00.000Z',
    leads: 'Robert Thompson',
    members: 'Olivia Walker, Robert Thompson',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Customer Support',
    create_time: '2023-07-13T11:15:00.000Z',
    leads: 'William Harris',
    members: 'Ava Turner, William Harris',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Finance Team',
    create_time: '2023-07-13T11:30:00.000Z',
    leads: 'Liam Martinez',
    members: 'Mia Roberts, Liam Martinez',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Operations Department',
    create_time: '2023-07-13T11:45:00.000Z',
    leads: 'Ethan Wilson',
    members: 'Isabella Clark, Ethan Wilson',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'IT Support',
    create_time: '2023-07-13T12:00:00.000Z',
    leads: 'James Turner',
    members: 'Charlotte Lewis, James Turner',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Product Management',
    create_time: '2023-07-13T12:15:00.000Z',
    leads: 'Benjamin Rodriguez',
    members: 'Amelia Green, Benjamin Rodriguez',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Quality Assurance',
    create_time: '2023-07-13T12:30:00.000Z',
    leads: 'Alexander Walker',
    members: 'Harper Martin, Alexander Walker',
    fullMembers: ''
  },
  {
    id: '0',
    name: 'Public Relations',
    create_time: '2023-07-13T12:45:00.000Z',
    leads: 'Daniel Wilson',
    members: 'Abigail Anderson, Daniel Wilson',
    fullMembers: ''
  }
];
