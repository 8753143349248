import GenericTable from "@components/Shared/GenericTable/GenericTable";
import { ApiGetObjective } from "@models/objetives.model";
import { ReviewsModelI } from "@store/Reviews/reviews.model";
import ReviewsTableColumns from "pwa/components/Reviews/ReviewsTableColumns/ReviewsTableColumns";
import "./styles.scss"

interface ReviewsTableProps {
  loading?: boolean;
  dataReviews?: ReviewsModelI | any;
  isGiven?: boolean;
  error: boolean;
  retryCallback: () => void
  onRowClicked?: (tableData: ApiGetObjective) => void;
}

const ReviewsTable = ({
  loading = false,
  dataReviews,
  isGiven = true,
  error,
  retryCallback,
  onRowClicked
}: ReviewsTableProps) => {
  return (
    <div className="container-table-mobile">
      <GenericTable
        label="reviews"
        columns={ReviewsTableColumns(isGiven)}
        data={dataReviews}
        pagination
        pointerOnHover={false}
        loading={loading}
        error={error}
        paginationPerPage={15}
        onRowClicked={onRowClicked}
        retryCallback={retryCallback}
      />
    </div>
  );
};

export { ReviewsTable as ReviewsTableMobile };
