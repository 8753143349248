import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
interface FormikStyledErrorProps{
    message: string
}

const FormikStyledError = (props: FormikStyledErrorProps)=> {
    return(
        <div className={'formik-styled-error'}>
            <FormattedMessage id={`${props.message}`}/>
        </div>
    )
}

export default FormikStyledError;