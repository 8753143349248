import React, { useCallback, useState } from 'react';
import FormOrganizationInfo from '@components/Organizations/FormOrganizationInfo/FormOrganizationInfo';
import { FormOrganizationValues, OrganizationInfo } from '@models/organizations.model';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationData, updateOrganizationInfo } from '@store/Organization/Info/info.action';
import { RootState } from '@store/rootReducer';
import { updateOrganization } from '@services/organization.service';
import GenericSuccessModal from '@components/Shared/GenericSuccessModal/GenericSuccessModal';
import Spinner from '@components/Shared/Spinner/Spinner';
import GenericErrorComponent from '@components/Shared/GenericErrorComponent/GenericErrorComponent';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';

// Catalogo:
// - Company Size
// - Frequency to upgrade goals
// - Time zone
// - Status
// - Role

const OrganizationInfoContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const organizationInfo = useSelector((state: RootState) => state.Organization.Info.data);
  const organizationInfoLoading = useSelector(
    (state: RootState) => state.Organization.Info.loading
  );
  const organizationInfoError = useSelector((state: RootState) => state.Organization.Info.error);
  const timeZoneCatalogue = useSelector((state: RootState) => state.Catalogs.TimeZone.data);
  const CompanySize = useSelector((state: RootState) => state.Catalogs.CompanySize.data);
  const FrequencyGoals = useSelector((state: RootState) => state.Catalogs.FrequencyGoals.data);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (data: FormOrganizationValues, color: string) => {
      setLoading(true);
      try {
        if (organizationId !== null) {
          const res = await updateOrganization(organizationId, data, color);
          console.log('TeamDetailsContainer | handleSubmit', data, res);
          const organizationUpdated: OrganizationInfo = {
            publicName: res.public_name,
            legalName: res.legal_name,
            companyWebsite: res.company_website,
            companySize: {
              value: res.tgd_company_size_catalog.id,
              label: res.tgd_company_size_catalog.description,
            },
            frequencyGoals: {
              value: res.tgd_frequency_goals_catalog.id,
              label: res.tgd_frequency_goals_catalog.description,
            },
            timeZone: {
              value: res.tgd_timezone_catalog.id,
              label: res.tgd_timezone_catalog.description,
            },
            color: res.color,
          };
          dispatch(updateOrganizationInfo(organizationUpdated));
          setOpenSuccessModal(true);
        }
      } catch (error) {
        setOpenErrorModal(true);
      } finally {
        setLoading(false);
      }
    },
    [organizationId, dispatch]
  );

  const getOrganizationDataCallback = useCallback(() => {
    if(organizationId){
      dispatch(getOrganizationData(organizationId))
    }
  }, [organizationId, dispatch])

  return (
    <>
      {organizationInfoLoading === 'PENDING' && 
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      }

      {(organizationInfoLoading === 'FAILED' && organizationInfoError !== null ) && 
        <div className='screen-loader'>
          <GenericErrorComponent 
            label={'organization info'} 
            retryCallback={getOrganizationDataCallback} 
          />
        </div>
      }
      
      {organizationInfoLoading === 'SUCCESS' &&      
        <FormOrganizationInfo
          organization={organizationInfo}
          onSubmit={handleSubmit}
          companySizeOptions={CompanySize}
          frequencyGoalsOptions={FrequencyGoals}
          timeZoneOptions={timeZoneCatalogue}
          loading={loading}
        />
      }

      <GenericSuccessModal
        isOpen={openSuccessModal}
        handleClose={() => setOpenSuccessModal(false)}
        messageId='general.modal.success.changes'
      />
      <GenericErrorModal
        isOpen={openErrorModal}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: 'updating', subject: 'organization' },
        }}
        handleClose={() => setOpenErrorModal(false)}
      />
    </>
  );
};

export default OrganizationInfoContainer;
