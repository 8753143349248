import { GET_TAGS_AS_CATALOGUE } from "@graphql/catalogues.queries";
import { GET_CULTURAL_ATTRIBUTES } from "@graphql/organization.queries";
import { TagsAsCatalogue } from "@models/catalogs.models";
import { CulturalAttributesObjectModel, GetCulturalAttributesInfoResponse, GetCulturalAttributesInitialData } from "@models/culturalAttributes.models";
import { GraphqlQueryResponse, TegduSelectOptionsModel } from "@models/global.model";
import { graphQlQuery } from "@services/graphql.service";
import { getMembersCatalogue } from "@services/members.service";
import { addPillars, putPillars, updatePillarArchive } from "@services/organization.service";
import { store } from "@store/store";
import { translateGetCulturalAttributes } from "@utils/organization";
import { buildAddNewCulturalAttributes } from "@utils/organizations/add.culturalAttributes.utils";
import { buildEditCulturalAttributesToEdit } from "@utils/organizations/edit.culturalAttributes.utils";
import { CulturalAttributesFormObjectModel } from "lib/TegduForms/CulturalAttributesFormUtils";
import { Dispatch } from "redux";


export const SET_CULTURAL_ATTRIBUTES_LOADING = "SET_CULTURAL_ATTRIBUTES_LOADING";
export const SET_CULTURAL_ATTRIBUTES_ERROR = "SET_CULTURAL_ATTRIBUTES_ERROR";
export const SET_CULTURAL_ATTRIBUTES_UPDATE_LOADING = "SET_CULTURAL_ATTRIBUTES_UPDATE_LOADING";
export const SET_CULTURAL_ATTRIBUTES_UPDATE_ERROR = "SET_CULTURAL_ATTRIBUTES_UPDATE_ERROR";
export const SET_CULTURAL_ATTRIBUTES_ARCHIVE_LOADING = "SET_CULTURAL_ATTRIBUTES_ARCHIVE_LOADING";

export const setCulturalAttributesArchiveLoading = (isLoading: boolean) => {
  return {
    type: SET_CULTURAL_ATTRIBUTES_ARCHIVE_LOADING,
    payload: isLoading
  }
}

export const setCulturalAttributesRequestLoading = (isLoading: boolean) => {
  return {
    type: SET_CULTURAL_ATTRIBUTES_LOADING,
    payload: isLoading
  }
}

export const setCulturalAttributesRequestError = (isError: boolean) => {
  return {
    type: SET_CULTURAL_ATTRIBUTES_ERROR,
    payload: isError
  }
}

export const setCulturalAttributesUpdateLoading = (isLoading: boolean) => {
  return {
    type: SET_CULTURAL_ATTRIBUTES_UPDATE_LOADING,
    payload: isLoading
  }
}

export const setCulturalAttributesUpdateError = (isLoading: boolean) => {
  return {
    type: SET_CULTURAL_ATTRIBUTES_UPDATE_ERROR,
    payload: isLoading
  }
}

export const getCulturalAttributesInfo = (
  handleSuccess: (response: GetCulturalAttributesInitialData) => void
) => async (dispatch: Dispatch) => {
  const organizationId = store.getState().User.Info.user.tgd_organization.id;

  let obtainedData: GetCulturalAttributesInitialData = {
    existingData: [],
    culturalAttributes: { culturalAttribute: [] },
    members: [],
    tags: []
  }

  dispatch(setCulturalAttributesRequestLoading(true))
  dispatch(setCulturalAttributesRequestError(false))

  try {
    await Promise.allSettled([
      graphQlQuery<GetCulturalAttributesInfoResponse>(GET_CULTURAL_ATTRIBUTES, {
        organizationId: organizationId,
        archived: false
      }),
      getMembersCatalogue(organizationId),
      graphQlQuery<TagsAsCatalogue>(GET_TAGS_AS_CATALOGUE, {organizationId: organizationId, isArchived: false}),
    ]).then((results: any) => {
      for(let k = 0; k < results.length; k++){
        if(results[k].status === "fulfilled"){
          switch(k){
            case 0:
              const response = results[k].value as GraphqlQueryResponse<GetCulturalAttributesInfoResponse>
              obtainedData.existingData = response.data.tgdPilars
              obtainedData.culturalAttributes.culturalAttribute = translateGetCulturalAttributes(response.data.tgdPilars)
            break;
            case 1:
              const members = results[k].value as TegduSelectOptionsModel []
              obtainedData.members = members
            break;
            case 2:
              const tags = results[k].value as GraphqlQueryResponse<TagsAsCatalogue>
              obtainedData.tags = tags.data.tgdTags
            break;
            default:
              break;
          }
        }
      }
    })
    handleSuccess(obtainedData) 
    dispatch(setCulturalAttributesRequestLoading(false))
  } catch {
    dispatch(setCulturalAttributesRequestLoading(false))
    dispatch(setCulturalAttributesRequestError(true))
  }
}

export const updateCulturalAttributesInfo = (
  formData: CulturalAttributesFormObjectModel [],
  initialData: CulturalAttributesObjectModel [],
  organizationId: number,
  handleSuccess: () => void
) => async (dispatch: Dispatch) => {
  dispatch(setCulturalAttributesUpdateLoading(true))
  dispatch(setCulturalAttributesUpdateError(false))

  const newAttributes = formData.filter((data) => data.id === -1);
  const existingAttributes = formData.filter((data) => data.id !== -1);

  if(newAttributes.length === 0){
    try {
      const body = buildEditCulturalAttributesToEdit(formData, initialData, organizationId)
      await putPillars(body)
      
      
      dispatch(setCulturalAttributesUpdateLoading(false))
      dispatch(setCulturalAttributesUpdateError(false))
  
      handleSuccess()
    } catch {
      dispatch(setCulturalAttributesUpdateLoading(false))
      dispatch(setCulturalAttributesUpdateError(true))
    }
  }else {
    const bodyExistingAttributes = buildEditCulturalAttributesToEdit(existingAttributes, initialData, organizationId)
    const bodyNewAttributes = buildAddNewCulturalAttributes(newAttributes, organizationId)
    await Promise.allSettled([
      putPillars(bodyExistingAttributes),
      addPillars(bodyNewAttributes)
    ])
    dispatch(setCulturalAttributesUpdateLoading(false))
    dispatch(setCulturalAttributesUpdateError(false))
    handleSuccess()
  }
}

export const archiveCulturalAttribute = (
  id: string,
  handleSuccess: () => void,
  handleError: () => void,
  archive: boolean
) => async (dispatch: Dispatch) => {
  dispatch(setCulturalAttributesArchiveLoading(true))
  try {
    await updatePillarArchive(id, archive)
    dispatch(setCulturalAttributesArchiveLoading(false))
    handleSuccess()
  } catch {
    dispatch(setCulturalAttributesArchiveLoading(false))
    handleError()
  }
}