import { Button } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import './styles.scss';

interface TegduErrorCallbackProps {
  intlObjectId: string
  retryCallback: () => void
}

const TegduErrorCallback = (props: TegduErrorCallbackProps) => {
  const { intlObjectId, retryCallback } = props;
  const intl =useIntl();

  return(
    <div className="tegdu-error-callback">
      <FormattedMessage 
        id="global.error.callback" 
        values={{
          object: intl.formatMessage({id: `${intlObjectId}`})
        }}
      />

      <Button onClick={retryCallback}>
        <FormattedMessage id="global.label.tryAgain"/>
      </Button>
    </div>
  )
}

export default TegduErrorCallback;