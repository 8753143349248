import { EmailNotificationsI } from '@containers/Settings/NotificationsContainer/NotificationsContainer';
import { useCallback, useState } from 'react';

export interface ExpirationDataProps {
  interval?: number | undefined;
  expiration_id?: number;
  periodicity_id?: number | undefined;
}
type NumberOrUndefined = number | undefined

// Outside the hook because there are only functions
const findNotification = (data: EmailNotificationsI[], entity: string, key: string, type: string) =>
  data.find((pref) => pref.entity === entity && pref.key === key && pref.type === type);

export const getInputData = (data: EmailNotificationsI[]) => {
  // Reminds you when an objective is due.
  const objectiveIsDue = findNotification(data, 'objectives', 'due', 'expiration');
  // Reminds you to periodically add progress to Key Results in an OKR.
  const addProgress = findNotification(data, 'objectives', 'add-progress', 'periodic');
  // Reminds you that a cultural attribute that you own is expiring.
  const culturalExpiring = findNotification(data, 'cultural_attributes', 'expiring', 'expiration');
  // Reminds you that a cultural attribute that you own is expiring.
  const statementExpiring = findNotification(data, 'statements', 'expiring', 'expiration');
  // Find addedToNewTeam switch notification preference
  const addedToNewTeam = findNotification(data, 'teams', 'new-member', 'event')
  // Find assignedAsCoach switch notification preference
  const assignedAsCoach = findNotification(data, 'objectives', 'coaching-assignment', 'event')
  // Find leadershipTransferred switch notification preference
  const leadershipTransferred = findNotification(data, 'teams', 'leadership-transfer', 'event')
  // Find OKRTransferred switch notification preference
  const OKRTransferred = findNotification(data, 'objectives', 'owner-transfer', 'event')

  return {
    objectiveIsDue,
    addProgress,
    culturalExpiring,
    statementExpiring,
    addedToNewTeam,
    assignedAsCoach,
    leadershipTransferred,
    OKRTransferred
  };
};

export const useNotificationsSettings = () => {
  const [error, setError] = useState({ error: false, service: Promise })

  const handleSwitchChange = useCallback(async (isActive, service, data) => {
    try {
      await service(data.id, isActive);
    } catch {
      setError({ error: true, service });
    }
  }, []);

  const handleExpirationSelection = useCallback(async (
    id: NumberOrUndefined,
    data: ExpirationDataProps,
    service) => {
    try {
      if (data.expiration_id) {
        await service(id, data)
      }
      if (data.interval) {
        await service(id, data)
      }
      if (data.periodicity_id) {
        await service(id, data)
      }
    } catch (error) {
      setError({ error: true, service });
    }
  }, []);

  const handlePeriodicityChange = useCallback(async (id, data, service) => {
    try {
      await service(id, data.interval, data.periodicity_id)
    } catch (error) {
      setError({ error: true, service });
    }
  }, [])

  return { handleSwitchChange, handleExpirationSelection, handlePeriodicityChange, error };
};
