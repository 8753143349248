import TegduTextArea from '@components/TegduFormComponents/TegduTextArea/TegduTextArea'
import TegduTextInputComponent from '@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent'
import withTextAreaController from '@hocs/withTextAreaController'
import withTextInputController from '@hocs/withTextInputController'
import { yupResolver } from '@hookform/resolvers/yup'
import { GetObjectivesInitialData } from '@store/Objectives/ObjectivesMainActions/objectivesMainActions.model'
import { RootState } from '@store/rootReducer'
import { findObjectiveStatus } from '@utils/objectives/restore.objectives.utils'
import { USER_ROLE } from '@utils/user'
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from 'lib/enums/objectives.enum'
import { CreateObjectiveMainFormModel, createObjectiveMainFormSchema } from 'lib/TegduForms/CreateObjectiveMainFormUtils'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from "react-hook-form"
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import ObjectivesActionsHeader from '../ObjectivesActionsHeader/ObjectivesActionsHeader'
import BasicObjectiveForm from '../BasicObjectiveForm/BasicObjectiveForm'
import './styles.scss';
import ObjectivesTypeSelector from '../ObjectivesTypeSelector/ObjectivesTypeSelector'

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);
const TegduTextAreaInputForm = withTextAreaController(TegduTextArea);

interface CreateObjectiveMainFormProps {
	action: CREATE_OBJECTIVES_ACTIONS
	initialData: CreateObjectiveMainFormModel
	objectiveType: OBJECTIVE_TYPES | null
	requiredData: GetObjectivesInitialData
	handleClose: () => void
	handleSubmit: (data: CreateObjectiveMainFormModel) => void
	handleSaveAsDraft: (data: CreateObjectiveMainFormModel) => void
	setObjectiveType: (type: OBJECTIVE_TYPES) => void
}

const CreateObjectiveMainForm = (props: CreateObjectiveMainFormProps) => {
	const { action, objectiveType, requiredData, initialData } = props;
	const { handleSubmit, handleClose, handleSaveAsDraft, setObjectiveType } = props;
	const userRole = useSelector((state: RootState) => state.User.Info.user.role.id);
	const { id, first_name, last_name } = useSelector((state: RootState) => state.User.Info.user)

	const methods = useForm<CreateObjectiveMainFormModel>({
		defaultValues: initialData,
		mode: "onChange",
		resolver: yupResolver(createObjectiveMainFormSchema)
	});

	const handleObjectiveType = useCallback((type: OBJECTIVE_TYPES) => {
		setObjectiveType(type)
	}, [setObjectiveType])

	const handleSaveDraft = useCallback(() => {
		handleSaveAsDraft(methods.getValues())
	}, [handleSaveAsDraft, methods])

	useEffect(() => {
		if(objectiveType === OBJECTIVE_TYPES.individual && action === CREATE_OBJECTIVES_ACTIONS.create){
			methods.setValue('owner', {value: id, label: first_name + " " + last_name})
		}
	}, [objectiveType, methods, id, first_name, last_name, action])

  return (
		<FormProvider {...methods}>
			<div className="create-objectives-form">
				<div className="create-objectives-form-header">
					<ObjectivesActionsHeader 
						 action={action}
						 isValidForm={methods.formState.isValid}
						 objectiveStatus={requiredData.objectiveCurrentValue !== null ? 
							 findObjectiveStatus(requiredData.objectiveCurrentValue.status.id) : undefined
						 }
						 objectiveType={objectiveType}
						 handleSaveAsDraft={handleSaveDraft}
						 handleClose={handleClose}
					/>
				</div>

				<div className="create-objectives-main-form-content">
					<div className="instructions-section">
						<span className='instructions-title'>
							<FormattedMessage 
								id="createobjectivemainform.title"
							/>
						</span>
					</div>

					<form
						id="create-objectives-form" 
            className="create-objectives-main-form-inputs" 
            onSubmit={methods.handleSubmit(handleSubmit)}
					>
						<TegduTextInputForm 
							name='name'
							labelId="createobjectivemainform.label.name"
              control={methods.control}
              errorMessage={methods.formState.errors.name?.message}
              maxLength={150}
              withErrorArea={true}
              isEditMode={true}
						/>

						<TegduTextAreaInputForm 
							name="description"
              labelMessageId="createobjectivemainform.label.description"
              control={methods.control}
              isEditMode={true}
              withMaxLength={true}
              maxLength={200}
						/>

						<div className="instructions-section">
							<span className="instructions-title">
								<FormattedMessage 
									id="createobjectivemainform.label.okrType.title"
								/>
							</span> <br />
							<span className="instructions-description">
                <FormattedMessage 
                  id="createobjectivemainform.label.okrType.description" 
                />
              </span>
						</div>

						<div className="objectives-type-selector">
							<ObjectivesTypeSelector              
								value={objectiveType}
								action={action}              
								isTeamOptionAvailable={requiredData.teams.length > 0 ? true : false}
								isOrganizationOptionAvailable={
									(userRole === USER_ROLE.owner || userRole === USER_ROLE.officer) ? true :  false
								}
								handleObjectiveType={handleObjectiveType}
								/>
						</div>

						{objectiveType !== null &&
              <BasicObjectiveForm 
                objectiveType={objectiveType}
                requiredData={requiredData}
                action={action}
              />        
            }    

					</form>
				</div>
			</div>

		</FormProvider>
	)
}

export default CreateObjectiveMainForm