import React from 'react'
import './styles.scss'
import { postTag } from '@services/settings.service';
import { RootState } from '@store/rootReducer';
import { createOption } from '@utils/tags';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OptionsModel } from '@models/helpful.model';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useCallback } from 'react';
import { validateEmailFormat } from '@utils/members';

interface CreatableDropdownProps{
   disableCreatableOptions?: boolean
   options: OptionsModel [];
   placeholder?: string
   value?: OptionsModel [];
   viewMode: boolean;
   blockCallback?: boolean
   validateEmails?: boolean
   onChangeValues: (values: OptionsModel []) => void    
}


const CreatableDropdown = (props: CreatableDropdownProps) => {
   const { onChangeValues, blockCallback, validateEmails } = props;
   const intl = useIntl();
   const [loading, setLoading] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const [value, setValue] = useState<OptionsModel []>([]);
   const [options, setOptions] = useState<OptionsModel []>([]);
   const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
   const [error, setError] = useState(false);

   const placeholder = useMemo(() => 
      props.viewMode 
         ? "- - - "
         : props.placeholder 
            ? intl.formatMessage({ id: props.placeholder })
            : intl.formatMessage({ id: 'general.placeholder.dropdown' })
   , [props.placeholder, props.viewMode, intl])

   const handleChange = (newValue: any) => {
      setValue(newValue)
      onChangeValues(newValue)
   };

   const handleCreate = async (inputValue: any) => {  
      if(validateEmails){
         if(validateEmailFormat(inputValue) !== null){
            const newOption = {label: inputValue, value: inputValue.toLowerCase().replace(/\W/g, '')};    
            setLoading(true);
            setDisabled(true);            
            setValue([...value, newOption]);   
            try{
               if(blockCallback === undefined){
                  const response = await postTag(inputValue, organizationId);
                  const newOption = createOption(inputValue, response.id); 
                  setOptions([...options, newOption]);
                  setValue([...value, newOption]);
                  onChangeValues([...value, newOption])
               }else {
                  const newOption = createOption(inputValue, 1); 
                  setOptions([...options, newOption]);
                  setValue([...value, newOption]);
                  onChangeValues([...value, newOption])
               }                  
            }catch{
               setValue([...value]);
               setError(true);
            }finally{
               setLoading(false);
               setDisabled(false);
            } 
         }
      }else {
         const newOption = {label: inputValue, value: inputValue.toLowerCase().replace(/\W/g, '')};  
         setLoading(true);
         setDisabled(true);            
         setValue([...value, newOption]);   
         try{
            if(blockCallback === undefined){
               const response = await postTag(inputValue, organizationId);
               const newOption = createOption(inputValue, response.id); 
               setOptions([...options, newOption]);
               setValue([...value, newOption]);
               onChangeValues([...value, newOption])
            }else {
               const newOption = createOption(inputValue, 1); 
               setOptions([...options, newOption]);
               setValue([...value, newOption]);
               onChangeValues([...value, newOption])
            }                  
         }catch{
            setValue([...value]);
            setError(true);
         }finally{
            setLoading(false);
            setDisabled(false);
         } 
      }             
   };

   useEffect(() => {
      if(props.value !== undefined){
         setValue(props.value)
      }
   }, [props.value])

   useEffect(() => {
      if(props.options !== undefined)
         setOptions(props.options)
   }, [props.options])

   useEffect(() => {
      if(error === true){
         setTimeout(() => {
            setError(false)
         }, 5000)
      }
   }, [error])

   const isCreatable = useMemo(() => {
      if(props.disableCreatableOptions){
         return false
      }else{
         return true
      }
   }, [props.disableCreatableOptions])

   const isValidNewOption = useCallback((inputValue, selectValue, selectOptions) => {
      if(isCreatable === true){
         const exactValueExists = selectOptions.find((el: any) => el.label === inputValue);
         const valueIsNotEmpty = inputValue.trim().length;
      
         return !exactValueExists && valueIsNotEmpty;
      }else{
         return isCreatable
      }
      
   }, [isCreatable])


   return(
      <div className="creatable__select__component">
         <CreatableSelect 
            components={
               {
                  DropdownIndicator: () => <ArrowDropDownIcon />,
               }
            }
            className={`creatable__drop__container${props.viewMode ? ' view-mode' : ''}`}
            classNamePrefix="creatable__drop"
            isMulti
            isClearable={false}
            isSearchable={true}
            isDisabled={disabled || props.viewMode}
            isLoading={loading}         
            onChange={handleChange}   
            onCreateOption={handleCreate}      
            formatCreateLabel={(inputValue: any) => 
               <FormattedMessage id={'general.label.create'} values={{value: inputValue}}/>
            }
            options={options}    
            placeholder={placeholder}     
            loadingMessage={() => 'searching'}
            value={value}      
            isValidNewOption={isValidNewOption}
         />

         {/* Creatable error */}
         {error === true &&
            <div className="creatable__error">
               <p>
                  <FormattedMessage 
                     id={'general.creatable.error'}
                  />
               </p>
            </div>
         }
      </div>
   )
}

export default CreatableDropdown;