import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {FormattedMessage} from "react-intl";
import './styles.scss';

interface TegduMobileSuccessProps {
  messageId?: string
}

const TegduMobileSuccess = (props: TegduMobileSuccessProps) => {
  const  {
    messageId = "tegdu-mobile-success-message"
  } = props;

  return (
    <div className="tegdu-mobile-success">
      <div className="icon-tgd-mobile-success">
        <CheckCircleIcon id="circleIcon"/>
      </div>
      <div className="dinamic-text">
        <FormattedMessage id={messageId}/>
      </div>
    </div>

  )
}

export default TegduMobileSuccess


