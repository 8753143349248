import { GetCulturalAttributesInitialData } from "@models/culturalAttributes.models";
import { archiveCulturalAttribute, getCulturalAttributesInfo, updateCulturalAttributesInfo } from "@store/Organization/CulturalAttributes/culturalAttributes.actions";
import { RootState } from "@store/rootReducer";
import useToastNotification from "hooks/useToastNotification";
import { CulturalAttributesFormModel } from "lib/TegduForms/CulturalAttributesFormUtils";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CulturalAttributesForm from "../../../components/Organizations/CulturalAttributes/CulturalAttributesForm/CulturalAttributesForm";
import CulturalAttributesContainerLoading from "./CulturalAttributesContainerLoading";
import { getCulturalAttributesCount } from '@services/organization.service';

const CulturalAttributesContainer = () => {
  const dispatch = useDispatch();
  const isRequestLoading = useSelector((state: RootState) => state.Organization.CulturalAttributes.isRequestLoading);
  const isArchiveLoading = useSelector((state: RootState) => state.Organization.CulturalAttributes.isArchiveLoading);
  const organizationId = useSelector((state: RootState) => state.User.Info.user.tgd_organization.id);
  const [initialData, setInitialData] = useState<GetCulturalAttributesInitialData | null>(null);
  const [archivedCulturalAttCount, setArchivedCulturalAttCount] = useState<number>(0)
  const toastNotification = useToastNotification();

  const handleGetSuccessData =  useCallback((data: GetCulturalAttributesInitialData) => {
    setInitialData(data)
  }, [])

  const getCulturalAttributes = useCallback(() => {
    dispatch(getCulturalAttributesInfo(
      handleGetSuccessData
    ))
  }, [dispatch, handleGetSuccessData])

  const handleSuccessUpdate = useCallback(() => {
    getCulturalAttributes()
    toastNotification("pillars.success.update", false)
    setArchivedCulturalAttCount(archivedCulturalAttCount + 1)
  }, [getCulturalAttributes, toastNotification, archivedCulturalAttCount])

  const handleSubmitData = useCallback((data: CulturalAttributesFormModel) => {
    if(initialData !== null){
      dispatch(
        updateCulturalAttributesInfo(
          data.culturalAttribute, 
          initialData.existingData,
          organizationId,
          handleSuccessUpdate
        )
      )
    }
  }, [dispatch, initialData, organizationId, handleSuccessUpdate])

  const handleArchiveAttribute = useCallback((id: string) => {
    dispatch(archiveCulturalAttribute(
      id,
      handleSuccessUpdate,
      () => null,
      true
    ))
  }, [dispatch, handleSuccessUpdate])

  const getArchivedCulturalAttCount = useCallback(async () => {
    try {
      const count = await getCulturalAttributesCount(true, organizationId);
      setArchivedCulturalAttCount(count)
    } catch (error) {
      console.error(error);
    }
  },[organizationId])

  useEffect(() => {
    getCulturalAttributes()
    getArchivedCulturalAttCount()
  }, [getCulturalAttributes, getArchivedCulturalAttCount])

  return(
    <>
      {isRequestLoading === false && initialData !== null  &&
        <CulturalAttributesForm 
          intialData={initialData}
          onSubmitForm={handleSubmitData}
          handleArchiveAttribute={handleArchiveAttribute}
          isArchiveLoading={isArchiveLoading}
          archivedCulturalAttCount={archivedCulturalAttCount}
        />
      }

      {isRequestLoading === true &&
        <CulturalAttributesContainerLoading />
      }
    </>
    
  )
}

export default CulturalAttributesContainer;