import {
  SET_ADD_MEMBER_MODAL,
  SET_HIGHLIGHT_MENU_OPTION,
  SET_RESET_PASSWORD_REQUEST,
  SET_RESET_PASSWORD_REQUEST_RESTART,
  SET_TOUR_IS_OPEN,
} from './shared.action';
import { SharedActions, SharedState } from './shared.model';

const initialState: SharedState = {
  modals: {
    addMemberModal: false,
  },
  login: {
    resetPasswordRequests: 0,
  },
  tour: {
    isOpen: false,
    highlightMenuOption: false
  },
};

const sharedReducer = (state: SharedState = initialState, actions: SharedActions): SharedState => {
  switch (actions.type) {
    case SET_ADD_MEMBER_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          addMemberModal: actions.payload,
        },
      };
    case SET_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        login: {
          ...state.login,
          resetPasswordRequests: state.login.resetPasswordRequests + 1,
        },
      };
    case SET_RESET_PASSWORD_REQUEST_RESTART:
      return {
        ...state,
        login: {
          ...state.login,
          resetPasswordRequests: 0,
        },
      };
    case SET_TOUR_IS_OPEN:
      return {
        ...state,
        tour: {
          ...state.tour,
          isOpen: actions.payload.isOpen,
        },
      };
    case SET_HIGHLIGHT_MENU_OPTION:
      return {
        ...state,
        tour: {
          ...state.tour,
          highlightMenuOption: actions.payload.highlight,
        },
      };
    default:
      return state;
  }
};

export default sharedReducer;
