import { components, OptionProps } from 'react-select';
import CircleWithName from '@components/Shared/CircleWithName/CircleWithName';

const CircleWithNameOption = (props: OptionProps<any, any>) => {
  const { label } = props;

  return (
    <components.Option {...props}>
      <CircleWithName name={label} size='xs' />
    </components.Option>
  );
};

export default CircleWithNameOption;
