import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormOrganizationValues, OrganizationInfo } from '@models/organizations.model';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import FormikDropdown from '@components/FormikComponents/FormikDropdown/FormikDropdown';
import { Button, InputLabel } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { OrganizationInfoSchema } from '@formsValidations/formsValidations';
import { OptionsModel } from '@models/helpful.model';
import Spinner from '@components/Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';
import { FormattedMessage } from 'react-intl';
import withDropFastField from '@hocs/withDropFastField';
import ApartmentIcon from '@material-ui/icons/Apartment';
import './styles.scss';
import ColorPicker from '../ColorPicker/ColorPicker';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikDropdownFastField = withDropFastField(FormikDropdown);

interface FormOrganizationInfoProps {
  organization: OrganizationInfo;
  companySizeOptions: OptionsModel[];
  frequencyGoalsOptions: OptionsModel[];
  timeZoneOptions: OptionsModel[];
  loading: boolean;
  onSubmit: (data: FormOrganizationValues, color: string) => void;
}

const FormOrganizationInfo = ({
  organization,
  onSubmit,
  companySizeOptions,
  frequencyGoalsOptions,
  timeZoneOptions,
  loading,
}: FormOrganizationInfoProps) => {
  const actions = useSelector((state: RootState) => state.User.Info.actions.organization);
  const [color, setColor] = useState<string>(organization.color);
  const [viewMode, setViewMode] = useState<boolean>(true);
  const [image, setImage] = useState<any>(null);
  const [errorImagePixels, setErrorImagePixels] = useState<boolean>(false);
  const initialValues: FormOrganizationValues = useMemo(
    () => ({
      publicName: organization.publicName,
      legalName: organization.legalName,
      companyWebsite: organization.companyWebsite,
      companySize: organization.companySize ? organization.companySize.value.toString() : '',
      frequencyGoals: organization.frequencyGoals
        ? organization.frequencyGoals.value.toString()
        : '',
      timeZone: organization.timeZone ? organization.timeZone.value.toString() : '',
    }),
    [organization]
  );

  const allowEdit = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.EDIT)) return true;
    return false;
  }, [actions]);

  // Check if organization have been updated
  useEffect(() => {
    if (!viewMode) {
      setViewMode(true);
    }
    // eslint-disable-next-line
  }, [initialValues]);

  const handleSubmit = useCallback(
    (data: FormOrganizationValues) => {
      onSubmit(data, color);
    },
    [color, onSubmit]
  );

  const handleImage = useCallback(async (element: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(element.target.files[0]);
    reader.onload = (e) => {
      if (reader.readyState === 2) {
        let imagePrepare: any = new Image();
        imagePrepare.src = e.target?.result;
        imagePrepare.onload = () => {
          if (imagePrepare.width === 200 && imagePrepare.height) {
            setErrorImagePixels(false);
            setImage(reader.result);
          } else {
            setErrorImagePixels(true);
          }
        };
      }
    };
  }, []);
  return (
    <div className='form-organizationinfo'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={OrganizationInfoSchema}
      >
        {(formik) => {
          let areEqual =
            JSON.stringify(formik.values) === JSON.stringify(initialValues) &&
            organization.color === color
              ? true
              : false;

          const resetForm = () => {
            formik.resetForm({ values: initialValues });
            setViewMode(true);
            setColor(organization.color);
          };

          const updateEditMode = () => {
            setViewMode(false);
            /* eslint-disable */
            formik.setTouched({
              ...formik.touched,
              ['publicName']: false,
              [`legalName`]: false,
              [`companyWebsite`]: false,
              [`companySize`]: false,
              [`frequencyGoals`]: false,
              [`timeZone`]: false,
            });
            /* eslint-enable */
          };

          return (
            <>
              <div className='description-container'>
                <Grid container className='grid-container-form' spacing={4}>
                  <Grid item xs container alignItems='center'>
                    <p>This is your company's general information.</p>
                  </Grid>
                  <Grid item>
                    <div className='edit-div'>
                      {viewMode ? (
                        allowEdit && (
                          <Button className='secondary' onClick={updateEditMode}>
                            <Icon>edit</Icon>
                            <FormattedMessage id='general.edit' />
                          </Button>
                        )
                      ) : (
                        <>
                          <Button className='secondary' onClick={resetForm} disabled={loading}>
                            <FormattedMessage id='general.cancel' />
                          </Button>
                          <Button
                            form='form-organizationinfo'
                            type='submit'
                            disabled={!formik.isValid || areEqual === formik.isValid || loading}
                          >
                            {loading && <Spinner />} Save
                          </Button>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Form id='form-organizationinfo' style={{ marginTop: 24 }}>
                <Grid container spacing={3}>
                  {/* UPDATE IMAGE  */}
                  {/* <Grid item xs={10} sm={4} className={"container-image-organization"}>
                      <div className='container-info-image'>
                        <span><FormattedMessage id={"organization.icon.image.title"}/></span>
                        <span><FormattedMessage id={"organization.icon.image.max"}/></span>
                      </div>
                      <div className='container-upload-image'>
                      <div className='image-container'>
                        <label className='image-area' htmlFor='input'>
                          {image == null ?
                            <ApartmentIcon/>
                            :<img id='img-to-show' src={image} alt="organization"/>}
                          {errorImagePixels && <span id='error-text-pixels'><FormattedMessage id={"organization.error.image"}/></span>}
                        </label>
                          <input 
                            name={"image"}    
                            onChange={handleImage}
                            disabled={viewMode}
                            type="file" 
                            id='input'
                            accept='.jpg, .png, .JPG, .PNG'
                          />
                    </div>
                   </div>
                  </Grid> */}
                  <Grid item xs={6} sm={12}>
                    <InputLabel htmlFor={`input-colorpicker`}>
                      <FormattedMessage id={'organization.info.label.brandcolor'} />
                    </InputLabel>
                    <ColorPicker onChange={setColor} defaultColor={color} disabled={viewMode} />
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormikTextInputFastField
                      name='publicName'
                      labelId='organization.info.label.publicname'
                      viewMode={viewMode}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormikTextInputFastField
                      name='legalName'
                      labelId='organization.info.label.legalname'
                      viewMode={viewMode}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormikDropdownFastField
                      name='companySize'
                      label='organization.info.label.companysize'
                      placeholder='general.placeholder.dropdown'
                      defaultValue={organization.companySize}
                      options={companySizeOptions}
                      isSearchable={false}
                      cleanFilter={false}
                      viewMode={viewMode}
                      validateOnClick
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormikTextInputFastField
                      name='companyWebsite'
                      labelId='organization.info.label.companywebsite'
                      viewMode={viewMode}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormikDropdownFastField
                      name='frequencyGoals'
                      label='organization.info.label.frequency'
                      placeholder='general.placeholder.dropdown'
                      defaultValue={organization.frequencyGoals}
                      options={frequencyGoalsOptions}
                      isSearchable={false}
                      cleanFilter={false}
                      viewMode={viewMode}
                      validateOnClick
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormikDropdownFastField
                      name='timeZone'
                      label='organization.info.label.timezone'
                      placeholder='general.placeholder.dropdown'
                      defaultValue={organization.timeZone}
                      options={timeZoneOptions}
                      isSearchable={false}
                      cleanFilter={false}
                      viewMode={viewMode}
                      validateOnClick
                    />
                  </Grid>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormOrganizationInfo;
