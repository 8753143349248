import React from 'react'
import { Dialog } from "@material-ui/core"
import { CREATE_OBJECTIVES_ACTIONS } from "lib/enums/objectives.enum"
import ObjectivesMainActionsContainer from 'pwa/containers/Objectives/ObjectivesMainActionsContainer/ObjectivesMainActionsContainer'

interface ObjectivesMainActionsModalProps {
  action: CREATE_OBJECTIVES_ACTIONS
  isOpen: boolean
  objectiveId?: number
  getObjectives: () => void
  handleClose: () => void
}

const ObjectivesActionsModal = (props: ObjectivesMainActionsModalProps) => {
  const { action, isOpen, objectiveId } = props;
  const { handleClose, getObjectives } = props;

  return (
    <Dialog 
      open={isOpen} 
      className="objectives-main-actions-modal"
      onClose={handleClose}
      disableBackdropClick
      fullScreen
    >
      <ObjectivesMainActionsContainer 
        action={action}
        handleClose={handleClose}
        objectiveId={objectiveId}
        getObjectives={getObjectives}
      />
    </Dialog>
  )
}

export default ObjectivesActionsModal
