import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { ReviewsModelI } from '@store/Reviews/reviews.model';
import ExpandableCell from '@components/Teams/ExpandableCell/ExpandableCell';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import './styles.scss';

const ReviewsTableColumns = (isGiven: boolean) => {
  const columns = useMemo(() => {
    const okrreview = {
      id: 'okrreview',
      name: (
        <div className='title-okrname'>
          <FormattedMessage id='review.table.column.title.OKR' />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <Tooltip
          title={row?.tgd_objective?.name}
          arrow
          placement='top'
          classes={{ tooltip: 'custom-tooltip' }}
        >
          <span id='span-text-review'>{row?.tgd_objective?.name}</span>
        </Tooltip>
      ),

      hide: 'sm',
    };
    const name = {
      id: 'name',
      maxWidth: '200px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='review.table.column.title.name' />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <span>{row?.reviewed?.first_name + ' ' + row?.reviewed?.last_name}</span>
      ),
      hide: 'sm',
    };
    const comment = {
      id: 'comment',
      minWidth: '250px',
      maxWidth: '600px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='review.table.column.title.comment' />
        </div>
      ),
      cell: (row: ReviewsModelI) =>
        row.comment ? <ExpandableCell data={row.comment} /> : <span>- - -</span>,
      hide: 'sm',
    };
    const reviewby = {
      id: 'reviewby',
      maxWidth: '200px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='review.table.column.title.reviewby' />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <span className='container-review-by'>
          {row?.reviewer?.first_name + ' ' + row?.reviewer?.last_name}
        </span>
      ),
      hide: 'sm',
    };
    const type = {
      id: 'type',
      width: '200px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='review.table.column.title.type' />
        </div>
      ),
      cell: (row: ReviewsModelI) => <span>{row?.general_review ? 'General' : 'Individual'}</span>,
      hide: 'sm',
    };
    const date = {
      id: 'date',
      width: '200px',
      name: (
        <div className='column-title'>
          <FormattedMessage id='review.table.column.title.date' />
        </div>
      ),
      cell: (row: ReviewsModelI) => <span>{moment(row?.created_at).format('DD/MM/YYYY')}</span>,
      hide: 'sm',
    };
    const review = {
      id: 'review',
      width: '100px',
      name: (
        <div className='column-title-review'>
          <FormattedMessage id='review.table.column.title.review' />
        </div>
      ),
      cell: (row: ReviewsModelI) => (
        <div className='container-rate-icon'>
          <StarRoundedIcon style={{ width: '30px', height: '30px' }} />
          <span>{row?.score}</span>
        </div>
      ),
      hide: 'sm',
    };
    if (isGiven) {
      return [okrreview, name, date, type, review, comment];
    } else {
      return [okrreview, reviewby, date, type, review, comment];
    }
  }, [isGiven]);
  return columns;
};

export default ReviewsTableColumns;
