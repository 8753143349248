import { Tab, Tabs } from "@material-ui/core"
import { setInvitationTabState } from "@store/Invitations/invitations.action";
import { RootState } from "@store/rootReducer";
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux";

const InvitationsTabNavigation = () => {
  const invitationsTabState = useSelector((state: RootState) => state.Invitations.invitationsActiveTabState);

  const dispatch = useDispatch();

  const onChange = (e: any, newValue: number) => {
    dispatch(setInvitationTabState(newValue));
  };

  return (
    <Tabs value={invitationsTabState} onChange={onChange} className="all-tabs">
      {/* <Tab label={<FormattedMessage id='invitations.tab.requests' />} id='requests' /> */}
      <Tab label={<FormattedMessage id='invitations.tab.pending' />} id='pending' />
      <Tab label={<FormattedMessage id='invitations.tab.accepted' />} id='accepted' />
    </Tabs>
  )
}

export default InvitationsTabNavigation