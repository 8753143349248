import { ApiPostLogin, User } from '@models/user.model';
import { MenuRole, ActionsRoleStore } from '@models/permissions.model';
import { RootState } from '@store/rootReducer';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  LocalState,
  SetUserState,
  SetLocalStateAction,
  SetUserInfoAction,
  SetUserLogoutAction,
  SetMenuOptions,
  SetActions,
  SetUserTour,
} from './info.model';
import { setOrganizationColor, setOrganizationId } from '@store/Organization/Info/info.action';
import { batch } from 'react-redux';
import { setResetPasswordRequestRestart } from '@store/Shared/shared.action';
import { updateTour } from '@services/user.service';

export const SET_USER_STATE = 'SET_USER_STATE';
export const SET_LOCAL_STATE = 'SET_LOCAL_STATE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_MENU_OPTIONS = 'SET_MENU_OPTIONS';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_USER_TOUR = 'SET_USER_TOUR';

export const setUserState = (jwt: string): SetUserState => ({
  type: SET_USER_STATE,
  payload: { jwt },
});

export const setUserInfo = (user: User): SetUserInfoAction => ({
  type: SET_USER_INFO,
  payload: { user },
});

export const setLocalState = (localState: LocalState): SetLocalStateAction => ({
  type: SET_LOCAL_STATE,
  payload: { localState },
});

export const setUserLogout = (): SetUserLogoutAction => ({
  type: USER_LOGOUT,
});

export const setMenuOptions = (menuOptions: MenuRole[]): SetMenuOptions => ({
  type: SET_MENU_OPTIONS,
  payload: { menuOptions },
});

export const setActions = (actions: ActionsRoleStore): SetActions => ({
  type: SET_ACTIONS,
  payload: { actions },
});

export const setUserTour = (tour: boolean): SetUserTour => ({
  type: SET_USER_TOUR,
  payload: { tour },
});

export const setMenuAndActions =
  (menuOptions: MenuRole[]): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(setMenuOptions(menuOptions));
    const actionsObject = storeActionByMenu(menuOptions);
    dispatch(setActions(actionsObject));
  };

export const storeActionByMenu = (menuOptions: MenuRole[]): ActionsRoleStore => {
  let obj = {};
  menuOptions.forEach((menu) => {
    obj = { ...obj, [menu.key_name]: menu.actions };
  });
  return obj;
};

export const setUserLogin =
  (data: ApiPostLogin): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch) => {
    if (data) {
      data.user.id = data.user.id.toString();
      batch(() => {
        dispatch(setOrganizationColor(data.user.tgd_organization.color));
        dispatch(setUserState(data.jwt));
        dispatch(setUserInfo(data.user));
        dispatch(setMenuAndActions(data.tgd_menu_role.tgd_menus));
        dispatch(setOrganizationId(data.user.tgd_organization.id));
        dispatch(setResetPasswordRequestRestart());
        dispatch(setLocalState('accessState'));
      });
    }
  };

export const userLogout = (): ThunkAction<void, RootState, unknown, Action<string>> => (dispatch) => {
  // Ref: https://stackoverflow.com/questions/52296953/how-to-reset-value-of-state-in-a-redux-store
  dispatch(setUserLogout());
};

export const updateUserTour =
  (tour: boolean): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    return await updateTour(tour)
      .then((data) => {
        dispatch(setUserTour(data.tour));
      })
      .catch((error) => {
        console.error(error);
      });
  };
