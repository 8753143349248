import TegduSkeletonLoading from "@components/Shared/TegduSkeletonLoading/TegduSkeletonLoading"

const OrganziationsTimeFramesContainerLoading = () => {
  return(
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <TegduSkeletonLoading 
          type="row"
          width='269px'
        />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <TegduSkeletonLoading 
            type="row"
            width='81px'
          />
          <TegduSkeletonLoading 
            type="row"
            width='81px'
          />
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', marginTop: "19px"}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <TegduSkeletonLoading 
              type="row"
              width='122px'
            />
            <TegduSkeletonLoading 
              type="form"
              width='280px'
              marginTop="4px"
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'column', marginLeft: "40px"}}>
            <TegduSkeletonLoading 
              type="row"
              width='122px'
            />
            <TegduSkeletonLoading 
              type="form"
              width='280px'
              marginTop="4px"
            />
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: "40px"}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <TegduSkeletonLoading 
              type="row"
              width='122px'
            />
            <TegduSkeletonLoading 
              type="form"
              width='280px'
              marginTop="4px"
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'column', marginLeft: "40px"}}>
            <TegduSkeletonLoading 
              type="row"
              width='122px'
            />
            <TegduSkeletonLoading 
              type="form"
              width='280px'
              marginTop="4px"
            />
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: "40px"}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <TegduSkeletonLoading 
              type="row"
              width='122px'
            />
            <TegduSkeletonLoading 
              type="form"
              width='280px'
              marginTop="4px"
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'column', marginLeft: "40px"}}>
            <TegduSkeletonLoading 
              type="row"
              width='122px'
            />
            <TegduSkeletonLoading 
              type="form"
              width='280px'
              marginTop="4px"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganziationsTimeFramesContainerLoading;