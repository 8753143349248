import Spinner from "@components/Shared/Spinner/Spinner";
import TegduDatePicker from "@components/TegduFormComponents/TegduDatePicker/TegduDatePicker";
import TegduSelect from "@components/TegduFormComponents/TegduSelect/TegduSelect";
import withDatePickerController from "@hocs/withDatePickerController";
import withSelectController from "@hocs/withSelectController";
import { Button } from "@material-ui/core";
import { AutomaticTimeframesCatalogue, AutomaticTimeframesFormModel } from "lib/TegduForms/AutomaticTimeframesFormUtils";
import { FormProvider, useForm } from "react-hook-form";
import './styles.scss';

const TegduSelectInputForm = withSelectController(TegduSelect);
const TegduDatePickerForm = withDatePickerController(TegduDatePicker);

interface AutomaticTimeframesFormProps {
  isLoading: boolean
  handleFormSubmit: (data: AutomaticTimeframesFormModel) => void
}


const AutomaticTimeframesForm = (props: AutomaticTimeframesFormProps) => {
  const { isLoading, handleFormSubmit } = props;
  const methods = useForm<AutomaticTimeframesFormModel>();


  return(
    <FormProvider {...methods}>
      <form 
        className="automatic-timeframes-form-component" 
        onSubmit={methods.handleSubmit(handleFormSubmit)}        
      >
      
      <div className="timeframe">
        <span>Timeframe</span>
        <TegduSelectInputForm 
          name="timeframe"
          control={methods.control}
          isSearchable={false}
          isClearable={false}
          isMulti={false}
          options={AutomaticTimeframesCatalogue}
        />
      </div>

      <div className="start-date">
        <span>Start date</span>
        <TegduDatePickerForm 
          name="start_date"
          control={methods.control}
          editMode={true}
        />
      </div>

      <Button type="submit">
        {(isLoading) ? 
          <Spinner color='primary' size={15} thickness={7}/>
        :
          <>Save</>
        }
      </Button>

      </form>
    </FormProvider>
  )
}

export default AutomaticTimeframesForm;
