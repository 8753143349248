import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { LocalState } from '@store/User/Info/info.model';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import Layout from '@components/Template/Layout/Layout';
import { ROUTE } from '@utils/routes';

interface SimpleRouteHOCStateProps {
  localState: LocalState;
}

interface SimpleRouteProps extends RouteProps, SimpleRouteHOCStateProps {
  authState: LocalState;
  layout?: boolean;
}

export const SimpleRoute: React.FC<SimpleRouteProps> = ({
  localState,
  authState,
  layout,
  component: Component,
  path,
  ...rest
}: SimpleRouteProps) => {
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          localState === authState ? (
            Component && (
              <>
                {layout ? (
                  <Layout>
                    <Component {...{ ...props }} />
                  </Layout>
                ) : (
                  <Component {...props} />
                )}
              </>
            )
          ) : (
            <Redirect
              to={{
                pathname:
                  localState === 'initial'
                    ? ROUTE.login.path
                    : 'accessState'
                    ? ROUTE.dashboard.path
                    : ROUTE.error.path,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </>
  );
};

const mapStateToProps = (state: RootState): SimpleRouteHOCStateProps => {
  return {
    localState: state.User.Info.localState,
  };
};

export default connect(mapStateToProps)(SimpleRoute);
