import { gql } from '@apollo/client';
import { MEMBER_ROLE } from '@utils/members';

export const GET_ORGANIZATION_INFO = `
query GetOrganizationInfo($organizationId: ID!) {
  tgdOrganization(id: $organizationId) {
    public_name
    legal_name
    tgd_company_size_catalog {
      value: id
      label: description
    }
    company_website
    color
    tgd_frequency_goals_catalog {
      value: id
      label: description
    }
    tgd_timezone_catalog {
      value: id
      label: description
    }
  }
}
`;

export const GET_STATEMENTS = gql`
  query GetStatements($organizationId: ID!, $isArchived: Boolean) {
    tgdStatements(where: { tgd_organization: { id: $organizationId }, is_archived: $isArchived }) {
      id
      name
      valid_until
      description
    }
  }
`;

export const ARCHIVE_STATEMENT = gql`
  mutation ArchiveStatement($id: ID!, $isArchived: Boolean) {
    updateTgdStatement(input: { where: { id: $id }, data: { is_archived: $isArchived } }) {
      tgdStatement {
        id
        is_archived
      }
    }
  }
`;

export const GET_PILLARS = gql`
  query GetPilars($organizationId: ID!, $archived: Boolean) {
    tgdPilars(where: { tgd_organization: { id: $organizationId }, is_archived: $archived }) {
      id
      name
      valid_until
      description
      owner: tgd_pilar_role_users(where: { tgd_role: { id: ${MEMBER_ROLE.owner} }, active: true }) {
        pillarUserId: id
        user {
          id
          first_name
          last_name
        }
      }
      coach: tgd_pilar_role_users(where: { tgd_role: { id: ${MEMBER_ROLE.coach} }, active: true }) {
        pillarUserId: id
        user {
          id
          first_name
          last_name
        }
      }
      tgd_pilar_tags(where: { active: true }) {
        id
        active
        tgd_tag {
          id
          name
        }
      }
    }
  }
`;

export const GET_CULTURAL_ATTRIBUTES = `
  query GetPilars($organizationId: ID!, $archived: Boolean) {
    tgdPilars(where: { tgd_organization: { id: $organizationId }, is_archived: $archived }) {
      id
      type
      name
      valid_until
      description
      owner: tgd_pilar_role_users(where: { tgd_role: { id: ${MEMBER_ROLE.owner} }, active: true }) {
        pillarUserId: id
        user {
          id
          first_name
          last_name
        }
      }
      coach: tgd_pilar_role_users(where: { tgd_role: { id: ${MEMBER_ROLE.coach} }, active: true }) {
        pillarUserId: id
        user {
          id
          first_name
          last_name
        }
      }
      tgd_pilar_tags(where: { active: true }) {
        id
        active
        tgd_tag {
          id
          name
        }
      }
    }
  }
`

export const GET_PILLARS_CATALOG = gql`
  query GetPilars($organizationId: ID!, $archived: Boolean) {
    tgdPilars(where: { tgd_organization: { id: $organizationId }, is_archived: $archived }) {
      value: id
      label: name
      type
    }
  }
`;

export const UPDATE_STATEMENT = `
mutation UpdateStatement($id:ID!, $name: String!, $description: String!, $date:Date) {
  updateTgdStatement(input:{where:{id:$id}, data:{name:$name, description:$description, valid_until:$date}}) {
    tgdStatement {
      id
    }
  }
}
`;

export const GET_PILLARS_ROLE_IDS = `
  query GetPillarsRoleIds($organizationId: Int, $rolesId: [Int]){
    tgdPilars(where: { tgd_organization: { id: $organizationId }, id: $rolesId}){
      tgd_pilar_role_users(where: { active: true }){
        id
      }
    }
  }

`;

export const GET_IS_OBJECTIVES_PUBLIC = `
  query GetIsPublicObjectives($organizationId: ID!){
    tgdOrganization(id: $organizationId){
      response: is_public_objectives
    }
  }
` 
export const GET_PUBLIC_OBJ = gql`
  query GetPublicObjectives($organizationId: ID!) {
    tgdOrganization(id: $organizationId) {
      is_public_objectives
    }
  }
`;

export const UPDATE_PUBLIC_OBJ = gql`
  mutation updatePublicObj($organizationId: ID!, $isPublic: Boolean) {
    updateTgdOrganization(
      input: { where: { id: $organizationId }, data: { is_public_objectives: $isPublic } }
    ) {
      tgdOrganization {
        is_public_objectives
      }
    }
  }
`;

export const GET_ORGANZIATION_TIMEFRAMES = `
query GetOrganizationTimeframes($organizationId: ID!){
  tgdOrganization(id: $organizationId){
    tgd_timeframes{
      id
      name
      date_initial
      date_until
      automatic
    }
  }
}
`