import React from 'react';
import { Card } from '@material-ui/core';
import ObjectiveByRoleProgress from '../ObjectiveByRoleProgress/ObjectiveByRoleProgress';
import ObjectiveProgressResume from '../ObjectiveProgress/ObjectiveProgress';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import './styles.scss';
import { ApiGetObjective, DashboardActiveObjective } from '@models/objetives.model';
import Spinner from '@components/Shared/Spinner/Spinner';
import { ROUTE } from '@utils/routes';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface UserInfoCardProps {
  activeObjectives: DashboardActiveObjective | null;
  lastObjectives: ApiGetObjective[];
  loading: boolean;
  error: any; // TODO: Agregar validación
}

// Sum all progress of active objectives and get the average
const calcActiveProgress = (totals: number[]) => {
  if (totals.length === 0) {
    return 0;
  } else {
    const sum = totals.reduce((a, b) => a + b, 0);
    return sum / totals.length;
  }
};

const UserInfoCard = ({ activeObjectives, lastObjectives, loading }: UserInfoCardProps) => {
  const { user } = useSelector((state: RootState) => state.User.Info);
  const history = useHistory();

  return (
    <Card className='user-dashboard-card'>
      <div className='user_info_content'>
        <div className='avatar_container'>
          <div className='image-content'></div>
        </div>
        <div className='user_info'>
          <h2>Hi, {`${user.first_name} ${user.last_name}`}</h2>
          <p>
            Start by doing what's necessary; then do what's possible; and suddenly you are doing the
            impossible.
          </p>
        </div>
      </div>
      <div className='horizontal_separator'></div>
      {loading ? (
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      ) : (
        activeObjectives && (
          <>
            <div className='objectives_general_info'>
              <h4>
                <FormattedMessage id='dashboard.objectives.active' />
              </h4>
              <div className='objectives_counts'>
                <ObjectiveByRoleProgress
                  progress={calcActiveProgress(activeObjectives.owner.totals)}
                  objectivesCount={activeObjectives.owner.totals.length}
                  role={'Owner'}
                />
                <div className='separator'></div>
                <ObjectiveByRoleProgress
                  progress={calcActiveProgress(activeObjectives.coach.totals)}
                  objectivesCount={activeObjectives.coach.totals.length}
                  role={'Coach'}
                />
                <div className='separator'></div>
                <ObjectiveByRoleProgress
                  progress={calcActiveProgress(activeObjectives.collaborator.totals)}
                  objectivesCount={activeObjectives.collaborator.totals.length}
                  role={'Collaborator'}
                />
              </div>
            </div>
            {!lastObjectives.length ? (
              <>
                <div className='horizontal_separator'></div>
                <h3 className='noobjectives'>
                  <FormattedMessage id='dashboard.objectives.empty' />
                </h3>
              </>
            ) : (
              lastObjectives.map((objective, index) => {
                return (
                  <div key={`objective-resume-${index}`}>
                    <div className='horizontal_separator'></div>
                    <ObjectiveProgressResume
                      id={objective.id}
                      date={objective.due_date}
                      name={objective.name}
                      progress={objective.progress}
                    />
                  </div>
                );
              })
            )}
          </>
        )
      )}
      {!loading && (
        <div className='footer' onClick={() => history.push(ROUTE.objetives.path)}>
          <p>
            {lastObjectives.length ? (
              <FormattedMessage id='dashboard.objectives.more' />
            ) : (
              <FormattedMessage id='dashboard.objectives.add' />
            )}
          </p>
        </div>
      )}
    </Card>
  );
};

export default UserInfoCard;
