import { useCallback, useState } from "react";

const useOpenAccordion = (initialState: boolean) => {
  const [isOpen, setOpen] = useState(initialState);

  const toggle = useCallback(() => {
  	setOpen((state) => !state);
  }, [setOpen]);

	return { isOpen, toggle };
}

export default useOpenAccordion;