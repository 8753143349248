import './styles.scss'
import { Icon } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface ObjectiveButtonSelectOptionProps{
  iconName: string;
  onAction: ()=>void;
  title: string
}

const ObjectiveButtonSelectOption = ({iconName, onAction, title}:ObjectiveButtonSelectOptionProps) => {
  const { formatMessage: i } =useIntl();
  return (
    <div onClick={onAction} className='container-button-to-select'>
      <Icon id='icon-select'>{iconName}</Icon>
      <span id='txt-select'>{i({id: title})}</span>
    </div>
  )
}

export default ObjectiveButtonSelectOption