import React, { useCallback, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import Spinner from '../Spinner/Spinner';
import './styles.scss';

interface TegduCodeVerificationComponentProps {
  length: number
  loading: boolean
  handleCompleteCode?: (value: string) => void
  handleOnChange?: (value: string) => void
}

const TegduCodeVerificationComponent = (props: TegduCodeVerificationComponentProps) => {
  const { length, handleCompleteCode, loading, handleOnChange } = props;
  const [value, setValue] = useState<string>("");
  
  const handleChange = useCallback((value: string) => {
    setValue(value)
    if(handleOnChange){
      handleOnChange(value)
    }
  }, [handleOnChange])

  return(
    <div className='tegdu-code-verification-component'>
      {loading === true  ?
        <div className='verification-loading'>
          <Spinner size={30} color="primary" thickness={4}/>
        </div>
      :        
        <ReactInputVerificationCode 
          length={length}
          type="text"
          placeholder=''
          onChange={handleChange}
          onCompleted={handleCompleteCode}
          value={value}
        />
      }
      
    </div>
  )
}

export default TegduCodeVerificationComponent;