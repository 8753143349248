import React, { useMemo } from 'react';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';
import { Form, Formik } from 'formik';
import { Button } from '@material-ui/core';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import { InvitationBasicInfo } from '@models/invitations.model';
import { InvitationsOnboardingBasicInfoSchema } from '@formsValidations/formsValidations';

const FormikTextInputFastField = withTextFastField(FormikTextInput);

interface InvitationsOnboardingBasicInfoFormProps {
  defaultValues?: InvitationBasicInfo | null
  onSubmit: (info: any) => void
}

const InvitationsOnboardingBasicInfoForm = (props: InvitationsOnboardingBasicInfoFormProps) => {
  const { onSubmit, defaultValues } = props;

  const initialValues = useMemo(() => {
    if(defaultValues !== null && defaultValues !== undefined){
      return defaultValues
    }else {
      return {
        first_name: "",
        last_name: "",
        job_title: ""
      }
    }
  }, [defaultValues])


  return(
    <div className='onboarding-basicinfo-form'>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={InvitationsOnboardingBasicInfoSchema}    
        validateOnChange    
      >
        {formik => (
          <Form>
            <FormikTextInputFastField 
              name='first_name'
              labelId='general.form.label.firstName'
              viewMode={false}
            />

            <FormikTextInputFastField 
              name='last_name'
              labelId='general.form.label.lastName'
              viewMode={false}
            />

            <FormikTextInputFastField 
              name='job_title'
              labelId='general.form.label.jobTitle'
              viewMode={false}
            />

            <div className='button-container'>
              <Button type='submit' disabled={!formik.isValid}>
                <FormattedMessage 
                  id='resetPassword.button.continue' 
                />
              </Button>
            </div>
          </Form>
        )}        
      </Formik>
    </div>
  )
}

export default InvitationsOnboardingBasicInfoForm;