import useOpenAccordion from 'hooks/useOpenAccordion';
import './styles.scss';
import TegduRichText from '../TegduRichText/TegduRichText';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

interface TegduAccordionProps{
	title: string;
	description: string;
}

const TegduAccordion = (props: TegduAccordionProps) => {
	const { title, description } = props;
	const { isOpen, toggle } = useOpenAccordion(false);
	return (
		<div className="accordion-container">
			<div className={isOpen ? "column-container-selected" : "column-container"} onClick={toggle}>
				<label className="column-text">
					{title}
				</label>
				<button className="expendable-button">
					{isOpen? <RemoveIcon />: <AddIcon /> }
				</button>
			</div>
			{isOpen && 
				<div className="text-container">
					<TegduRichText text={description} className="text"/>
				</div>
			}
		</div>
  )
}

export default TegduAccordion;