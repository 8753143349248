import Searchbar from '@components/Shared/Searchbar/Searchbar';
import { getFAQS } from '@services/support.service';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import './styles.scss';
import SupportContainerLoading from './SupportContainerLoading';
import TegduAccordion from '@components/Shared/TegduAccordion/TegduAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { Button } from '@material-ui/core';
import { setTourIsOpen } from '@store/Shared/shared.action';
import { ROUTE } from '@utils/routes';
import { useHistory } from 'react-router-dom';

const SupportContainer = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [questions, setQuestions] = useState<any>([]);
  const [searchQuestions, setSearchQuestions] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const contactEmail = process.env.REACT_APP_MAIL_DIRECTION;
  const user = useSelector((state: RootState) => state.User.Info.user);
  const userName = user.first_name + ' ' + user.last_name;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleTourButtonClick = async () => {
    dispatch(setTourIsOpen(true));
    history.push(ROUTE.dashboard.path);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    try {
      const response = await getFAQS();
      setQuestions(response);
      setSearchQuestions(response);
      setLoading(false);
      return response;
    } catch {
      setQuestions([]);
      setSearchQuestions([]);
    }
  };

  const onSearchTerm = (term: string) => {
    var reg = new RegExp('^[a-zA-Z0-9 ]+$');
    if (term === '' || reg.test(term)) {
      filterQuestions(term);
      setSearchText(term);
    }
  };

  const filterQuestions = (term: string) => {
    const search = questions.filter((question: any) => {
      if (question.title.toString().toLowerCase().includes(term.toString().toLowerCase())) {
        return question;
      } else {
        return undefined;
      }
    });
    setSearchQuestions(search);
    console.log(searchQuestions);
  };

  return (
    <>
      {isLoading && <SupportContainerLoading />}

      {isLoading === false && (
        <div className='support-container'>
          <div className='support-column-left'>
            <h1 className='screen-title'>
              <FormattedMessage id='faqs.screen.title' />
            </h1>
            <h3>
              <FormattedMessage id='faqs.screen.subtitle' />
            </h3>
            <div className='support-searchbar-container'>
              <Searchbar
                intlId='faqs.searchbar.placeholder'
                onSearch={onSearchTerm}
                valueInput={searchText}
                className='support-searchbar'
              />
            </div>
            {searchQuestions &&
              searchQuestions.map((section: any, index: number) => (
                <TegduAccordion
                  key={index}
                  title={section.title}
                  description={section.description}
                />
              ))}
            {searchQuestions.length === 0 && (
              <div className='support-faq-not-found'>
                <h3>FAQ not found</h3>
              </div>
            )}
          </div>
          <div className='support-column-right'>
            <div className='support-contact-container'>
              <h3>
                <FormattedMessage id='faqs.screen.contact.title' />
              </h3>
              <h3>
                <FormattedMessage id='faqs.screen.contact.subtitle' />
              </h3>
              <div className='information-container'>
                <EmailOutlinedIcon color='primary' />
                <a
                  href={`mailto:${contactEmail}?subject=${
                    encodeURIComponent('Support') || ''
                  }&body=${encodeURIComponent(
                    `Hello, I am ${userName}, I need help with Tegdu.`
                  )}&html-body=${encodeURIComponent(
                    `Hello, I am ${userName}, I need help with Tegdu.`
                  )}`}
                >
                  {contactEmail}
                </a>
              </div>
              <h3>
                <FormattedMessage id='faqs.screen.tour.title' />
              </h3>
              <div className='tour-button'>
                <Button className='primary' onClick={handleTourButtonClick}>
                  <FormattedMessage id='faqs.screen.tour.button' />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportContainer;
