import React from 'react'
import LettersCircle from '@components/Shared/LettersCircle/LettersCircle'
import StatusComponent from '@components/Shared/StatusComponent/StatusComponent'
import { Grid } from '@material-ui/core'
import './styles.scss'

interface MemberCircleInfoProps{
    name: string
    jobTitle: string | null
    status: boolean
}

const MemberCircleInfo = (props: MemberCircleInfoProps) => {
    return(
        <div className='member-circle-info'>
            <Grid className={'grid-circle'} container>
                <div className="circle">
                    <LettersCircle name={props.name} size={'xl'}/>
                </div>
            </Grid>
            <Grid className={'grid-info'} container>
                <Grid item className={'user'} md={5} sm={5} xs={12}>
                    <p id="top">{props.name}</p>
                    {props.jobTitle !== null && (
                        <p id="bottom">{props.jobTitle}</p>
                    )}
                    
                </Grid>
                <Grid item className={'line'} md={2} sm={2} xs={12}>
                    <div className="vl"></div>
                </Grid>
                <Grid item className={'status'} md={4} sm={4} xs={12}>
                    <StatusComponent status={props.status} size={'component'}/>
                </Grid>
            </Grid>
        </div>
    )
}
export default MemberCircleInfo;