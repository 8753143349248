import TegduDropableArea from '@components/Shared/TegduDropableArea/TegduDropableArea';
import { Button } from '@material-ui/core';
import { EmailsFromXLSX } from '@models/members.model';
import { EXCEL_ACCEPTED_FILES } from '@utils/members';
import React, { useCallback, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './style.scss';

interface AddMembersFromFileContainerProps {
  handleBack: () => void
  handleUpload: (emails: EmailsFromXLSX []) => void
}

const AddMembersFromFileContainer = (props: AddMembersFromFileContainerProps) => {
  const { handleBack, handleUpload } = props;
  const [emails, setEmails] = useState<EmailsFromXLSX [] | null>(null);
  
  const onDrop = useCallback((acceptedFiles: EmailsFromXLSX [] | null) => {
   setEmails(acceptedFiles)
  }, [])

  const onHandleUpload = useCallback(() => {
    if(emails !== null){
      handleUpload(emails)
      handleBack()
    }
  }, [handleUpload, emails, handleBack])

  return(
    <div className='add-members-from-file'>
      <div className='back-btn'>
        <div onClick={handleBack}>
          <ArrowBackIcon />
        </div>
      </div>

      <div className='upload-from-file-titles'>
        <span id='title'>Upload file to invite members</span>
        <span id='text'>Drag or select an .xlsx file with all the emails you want to invite</span>
      </div>

      <TegduDropableArea 
        handleAcceptedFiles={onDrop}
        acceptedFiles={EXCEL_ACCEPTED_FILES}
        dragDescriptionMessage={"objectives.modal.invite.label.dropfile"}
        maxFiles={1}
      />

      <div className='upload-from-file'>
        <Button
          className='primary'    
          onClick={onHandleUpload}      
          disabled={emails === null ? true : false}
        >
          Upload
        </Button>
      </div>

    </div>
  )
}

export default AddMembersFromFileContainer;