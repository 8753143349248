import { useCallback, useEffect, useMemo, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Card, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import MemberCircleInfo from '@components/Members/MemberCircleInfo/MemberCircleInfo';
import { GqlMember, LeadersI, FormMemberValues } from '@models/members.model';
import MembersInfoForm from '@components/Members/MembersInfoForm/MembersInfoForm';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_MEMBER } from '@graphql/members.queries';
import Spinner from '@components/Shared/Spinner/Spinner';
import './styles.scss';
import {
  getMembersCatalogue,
  toggleActiveSuspenseLeader,
  updateMember,
} from '@services/members.service';
import { ROUTE } from '@utils/routes';
import GenericSuccessModal from '@components/Shared/GenericSuccessModal/GenericSuccessModal';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import { setOrganizationLoading } from '@store/Organization/Info/info.action';
import { useDispatch, useSelector } from 'react-redux';
import GenericConfirmModal from '@components/Shared/GenericConfirmModal/GenericConfirmModal';
import ChangeLeaderModal from '@components/Shared/TegduModal/TegduModal';
import ChangeLeaderComponent from '../../../components/Members/ChangeLeaderComponent/ChangeLeaderComponent';
import { GqlGetTeams } from '@models/teams.model';
import { GET_TEAMS } from '@graphql/teams.queries';
import { RootState } from '@store/rootReducer';
import { TeamLeadersI } from '@models/members.model';
import { OptionsModel } from '@models/helpful.model';
import useToastNotification from 'hooks/useToastNotification';

const IndividualMemberContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const idMember: { id: string } = useParams();
  const [loadingR, setLoadingR] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isChangeLeaderModalOpen, setIsChangeLeaderModalOpen] = useState(false);
  const [submitInfo, setSubmitInfo] = useState<any>(null);
  const [dataToSubmit, setDataToSubmit] = useState<FormMemberValues>();
  const [memberOptions, setMemberOptions] = useState<OptionsModel[]>([]);
  const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const toastNotification = useToastNotification();

  const [getMember, { data, loading, error }] = useLazyQuery<GqlMember>(GET_MEMBER, {
    variables: idMember,
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingTeamLeader,
    error: errorTeamLeader,
    data: teamLeaderData,
  } = useQuery<GqlGetTeams>(GET_TEAMS, {
    variables: { organizationId },
  });

  const teamLeaders = useMemo(() => {
    if (
      loadingTeamLeader === false &&
      errorTeamLeader === undefined &&
      teamLeaderData !== undefined
    ) {
      return teamLeaderData.tgdTeams.map((team) => ({
        name: team.name,
        leaders: team.leader.map((leader) => ({
          id: leader.user.id,
          first_name: leader.user.first_name,
          last_Name: leader.user.last_name,
        })),
      }));
    } else {
      return [];
    }
  }, [loadingTeamLeader, errorTeamLeader, teamLeaderData]);

  const teamsWhereIsLeader: { name: string[] } = useMemo(() => {
    let teams: any = { name: [] };
    if (teamLeaders !== undefined) {
      teamLeaders.map((team) => {
        team.leaders.filter((leader) =>
          leader.id === idMember.id ? teams.name.push(team.name) : []
        );
        return team.leaders.filter((leader) => leader.id === idMember.id);
      });
    }
    return teams;
  }, [idMember.id, teamLeaders]);

  const onSubmitUpdateRole = useCallback(async () => {
    setConfirmModal(false);
    if (submitInfo !== null) {
      setLoadingR(true);
      try {
        await updateMember(idMember.id, submitInfo);
        setLoadingR(false);
        setSuccessModal(true);
      } catch {
        setLoadingR(false);
      }
    } else {
    }
  }, [submitInfo, idMember.id]);

  const getLeaderId = (arr: TeamLeadersI[], id: string) => {
    const teamLeadersArr = arr.map((leaders: TeamLeadersI) =>
      leaders.leaders.map((user: LeadersI) => user.id).filter((elem: string) => elem === id)
    );

    const flattenedTeamLeaderArr = teamLeadersArr.flat().toString();
    return flattenedTeamLeaderArr;
  };

  const onSubmit = useCallback(
    async (info: FormMemberValues) => {
      setLoadingR(true);
      setDataToSubmit(info);
      try {
        if (editRole === false) {
          setSubmitInfo(info);
          setConfirmModal(true);
        } else {
          const status = info.status === 'false' ? false : true;
          // If suspend check if the user has teams
          if (!status && getLeaderId(teamLeaders, idMember.id)) {
            setIsChangeLeaderModalOpen(true);
          } else {
            dispatch(setOrganizationLoading('UPDATED'));
            await updateMember(idMember.id, info);
            await toggleActiveSuspenseLeader(idMember.id, status);
            setSuccessModal(true);
          }
        }
      } catch (error) {
        toastNotification('individualmember.form.error', true);
      } finally {
        setLoadingR(false);
      }
    },
    [dispatch, editRole, idMember.id, teamLeaders, toastNotification]
  );

  const onGotIt = useCallback(() => {
    getMember();
    dispatch(setOrganizationLoading('UPDATED'));
    history.push(ROUTE.members.path);
    setSuccessModal(false);
  }, [getMember, history, dispatch]);

  const onGotItError = useCallback(() => {
    history.push(ROUTE.members.path);
    setErrorModal(false);
  }, [history]);

  const getMembers = useCallback(async () => {
    if (organizationId !== null) {
      setLoadingMembers(true);
      try {
        const members = await getMembersCatalogue(organizationId);
        setMemberOptions(members);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMembers(false);
      }
    }
  }, [organizationId]);

  useEffect(() => {
    if (idMember !== null && idMember !== undefined) {
      getMember();
    }
  }, [idMember, getMember]);

  useEffect(() => {
    const isNumber = /^\d+$/.test(idMember.id);
    if (isNumber === false) {
      history.push(ROUTE.members.path);
    }
  }, [idMember.id, history]);

  useEffect(() => {
    if (data !== undefined && data.user === null) {
      history.push(ROUTE.members.path);
    }
  }, [data, history]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);
  console.log(data?.user, "HEY")

  return (
    <div className='individual-members'>
      {loading === false && error === undefined && data !== undefined && data.user !== null && (
        <>
          <div className='back-btn-container' onClick={() => history.goBack()}>
            <Grid container className='back-btn'>
              <ArrowBackIcon />
              <FormattedMessage id='individualmember.container.back' />
            </Grid>
          </div>

          <Card className='card-container'>
            <MemberCircleInfo
              name={data.user.first_name + ' ' + data.user.last_name}
              status={data.user.active}
              jobTitle={data.user.job_title !== undefined ? data.user.job_title : ''}
            />
            <MembersInfoForm
              data={data.user}
              editable={true}
              loading={loadingR}
              onEditRole={(value: boolean) => setEditRole(value)}
              onSubmit={onSubmit}
            />
          </Card>
        </>
      )}

      {loading === true && (
        <div className='container-loader'>
          <div className='loader'>
            <Spinner color='primary' size={100} thickness={2} />
          </div>
        </div>
      )}

      <GenericSuccessModal
        isOpen={successModal}
        handleClose={onGotIt}
        messageId={'general.modal.success.changes'}
      />

      <GenericErrorModal
        isOpen={errorModal}
        handleClose={onGotItError}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: 'updating', subject: 'member' },
        }}
      />

      <GenericConfirmModal
        isOpen={confirmModal}
        confirmButtonLabel='members.confirm.role.update.button'
        messageParagraphId='generic.confirm.modal.confirm.p'
        messageTitleId='members.confirm.role.update.title'
        titleLabelValues={{ name: data !== undefined ? data.user.first_name : '' }}
        handleCancel={() => setConfirmModal(false)}
        handleConfirm={() => onSubmitUpdateRole()}
      />

      <ChangeLeaderModal
        isOpen={isChangeLeaderModalOpen}
        maxWidth='xs'
        handleClose={() => {
          setIsChangeLeaderModalOpen(false);
        }}
        component={
          <ChangeLeaderComponent
            data={data?.user}
            closeModal={() => setIsChangeLeaderModalOpen(false)}
            dataToSubmit={dataToSubmit}
            setLoadingOff={() => setLoadingR(false)}
            setLoadingOn={() => setLoadingR(true)}
            memberOptions={memberOptions}
            loadingMembers={loadingMembers}
            teamLeaders={teamLeaders}
            teamsWhereIsLeader={teamsWhereIsLeader}
          />
        }
      />
    </div>
  );
};

export default IndividualMemberContainer;
