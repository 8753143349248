import './styles.scss';
import React, { useCallback, useEffect, useState } from 'react';
import LoginForm from '@containers/Login/LoginForm';
import { validateDomain } from '@services/login.service';
import LoginLoader from '@components/Login/LoginLoader/LoginLoader';

function Login() {
  const [validating, setValidating] = useState(true);
  const [companyName, setCompanyName] = useState('');

  const getValidateDomain = useCallback(async () => {
    try {
      const validationResponse = await validateDomain();
      setCompanyName(validationResponse.public_name);
    } catch (e: any) {
      setCompanyName(' ');
    } finally {
      setValidating(false);
    }
  }, []);

  useEffect(() => {
    getValidateDomain();
  }, [getValidateDomain]);

  return (
    <div className='login__container' style={{ backgroundImage: `url('/images/Fondo.svg')` }}>
      {validating === false ? <LoginForm companyName={companyName} /> : <LoginLoader />}
    </div>
  );
}

export default Login;
