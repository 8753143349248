import { ObjectivesAddActions, SET_CLOSE_ADD, SET_OPEN_ADD } from "./ObjectivesOpenAdd.action"
import { ObjectivesOpenAddI } from "./ObjectivesOpenAdd.model"

const initialState: ObjectivesOpenAddI = {
  isOpenAdd: false
}

export const objectivesOpenAddReducer = (
  state: ObjectivesOpenAddI = initialState,
  actions: ObjectivesAddActions
  ): ObjectivesOpenAddI => {
  switch(actions.type) {
    case SET_OPEN_ADD: 
      return {
        ...state,
        isOpenAdd: true
      }
    case SET_CLOSE_ADD: 
      return {
        ...state,
        isOpenAdd: false
      }
    default:
      return state
  }
}