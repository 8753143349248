import TgdClient from "@helpers/HttpClients/TgdClient"
import { ApiSendInvitationResponse, InvitationData, InviteMembersService, PostSignupInfo, VerifyInvitationResponse } from "@models/invitations.model"
import { ApiPostLogin } from "@models/user.model"

export const inviteMembers = async (data: InviteMembersService): Promise<ApiSendInvitationResponse> => {
  return TgdClient.post(`/tgd-invitations/send-invitation`, data)
}

export const verifyInvitation = async (uuid: string): Promise<VerifyInvitationResponse> => {
  return TgdClient.get(`/tgd-invitations/verify-invitation?uuid=${uuid}`)
}

export const submitRegisterInfo = async (data: PostSignupInfo): Promise<ApiPostLogin> => {
  return TgdClient.post(`/tgd-invitations/register-by-invitation`, data)
}

export const getInvitationsService = async (status: string): Promise<InvitationData[]> => {
  return TgdClient.get(`/tgd-invitations?status=${status}`)
}

export const deleteInvitationsService = async (invitationId: string): Promise<any> => {
  return TgdClient.delete(`/tgd-invitations/${invitationId}`)
}