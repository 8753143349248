import { BottomSheet } from "react-spring-bottom-sheet"

interface TegduMobileBottomSheetProps {
  isOpen: boolean
  container: JSX.Element;
  handleClose: () => void
}

const TegduMobileBottomSheet = (props: TegduMobileBottomSheetProps) => {
  const { isOpen, container, handleClose } = props;
  return(
    <BottomSheet
      className="bottom-sheet"
      onDismiss={handleClose}
      open={isOpen}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
    >
      {container}
    </BottomSheet>
  )
}

export default TegduMobileBottomSheet;