import OnboardingHeader from '@components/Template/OnboardingHeader/OnboardingHeader';
import OnboardingInviteContainer from '@containers/Onboarding/OnboardingInviteContainer/OnboardingInviteContainer';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';

import './styles.scss';

const OnboardingScreen = () => {
  const publicName = useSelector((state: RootState) => state.Organization.Info.data.publicName);

  return (
    <div className='screen-onboarding'>
      <div className='onboarding-header'>
        <OnboardingHeader publicName={publicName} organizationsImage={null} />
      </div>
      <div className='onboarding-containers'>
        <OnboardingInviteContainer />
      </div>
    </div>
  );
};

export default OnboardingScreen;
