import React from "react";
import InvalidScreenContainer from '@containers/Helpers/InvalidScreenContainer/index';



const InactiveScreen = () => {
  return (
    <div className='resetPasswordScreen__container'>
      <InvalidScreenContainer />
    </div>
  )
}

export default InactiveScreen;
