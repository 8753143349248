import { TimeZoneCatalogueActionTypes, TimeZoneCatalogueState } from './timeZone.model';
import {
  SET_TIMEZONE_CATALOGUE_BEGIN,
  SET_TIMEZONE_CATALOGUE_SUCCESS,
  SET_TIMEZONE_CATALOGUE_ERROR,
} from './timeZone.action';

const initialState: TimeZoneCatalogueState = {
  data: [],
  loading: false,
  error: null,
};

export default function timeZoneReducer(
  state: TimeZoneCatalogueState = initialState,
  action: TimeZoneCatalogueActionTypes
): TimeZoneCatalogueState {
  switch (action.type) {
    case SET_TIMEZONE_CATALOGUE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SET_TIMEZONE_CATALOGUE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SET_TIMEZONE_CATALOGUE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
