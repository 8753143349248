import { TegduSelectOptionsModel } from "@models/global.model";
import { TimeframesDatesModel } from "@models/timeframes.models";
import { GetAllTeamsResponse, TeamsLeaderModel } from "@models/teams.model";
import { GetObjectivesInitialData } from "@store/Objectives/ObjectivesMainActions/objectivesMainActions.model";
import { CreateObjectiveMainFormModel } from "lib/TegduForms/CreateObjectiveMainFormUtils";


export const timeframesToOptionsModel = (
    timeframes: {value: string , label: string, date_until: string, date_initial: string} []
  ):TegduSelectOptionsModel<TimeframesDatesModel> []  => {
  
  let timeframesAsOptions: TegduSelectOptionsModel<TimeframesDatesModel> []  = [];

  if(timeframes.length > 0){
    timeframes.forEach((element) => {
      timeframesAsOptions.push({
        value: element.value,
        label: element.label,
        complementaryData: {
          date_initial: element.date_initial,
          date_until: element.date_until
        }
      })
    })
    
    return timeframesAsOptions;
  }else {
    return []
  }
}

export const teamsLeadersToOptionsModel = (leaders: 
  { teamUserId: string;
    user: {
      id: string;
      first_name: string;
      last_name: string;
    };
  }[]): string [] => {
    let leadersArray: string [] = [];
    if(leaders.length > 0){
      leaders.forEach((element) => {
        leadersArray.push(element.user.id)
      })
    
      return leadersArray;
    }else {
      return []
    }
}

export const teamsToOptionsModel = (teams: GetAllTeamsResponse []): TegduSelectOptionsModel<TeamsLeaderModel> [] => {
  let teamsAsOptions: TegduSelectOptionsModel<TeamsLeaderModel> [] = [];

  if(teams.length > 0){
    teams.forEach((element) => {
      teamsAsOptions.push({
        value: element.id,
        label: element.name,
        complementaryData: {
          leadersIds: teamsLeadersToOptionsModel(element.leader)
        }
      })
    })
  return teamsAsOptions;
  }else {
    return []
  }
}

export const isTeamLeader = (teams: GetAllTeamsResponse [], userId: string): TegduSelectOptionsModel []  => {
  let teamsImLeader: TegduSelectOptionsModel []  = []
  teams.forEach((team) => {
    team.leader.forEach((leader) => {
      if(leader.user.id.toString() === userId.toString()){
        teamsImLeader.push({
          value: team.id,
          label: team.name
        })
      }
    })
  })

  return teamsImLeader;
}

export const obtainMembersLeaders = (teams: GetAllTeamsResponse [], members: TegduSelectOptionsModel []): TegduSelectOptionsModel [] => {
  let membersLeaders: TegduSelectOptionsModel [] = [];
  let uniqueMembersLeaders: TegduSelectOptionsModel [] = [];

  members.forEach((member) => {
    teams.forEach((team) => {
      team.leader.forEach((leader) => {      
        if(leader.user.id.toString() === member.value.toString()){
          membersLeaders.push({
            value: member.value,
            label: member.label
          })
          return false
        }
        
      })
    })
  })

  uniqueMembersLeaders = membersLeaders.filter((element, index, self) => 
    index === self.findIndex((value) => (
      (value.value as number) === (element.value as number)
    ))
  )

  return uniqueMembersLeaders
}

export const buildCreateObjectiveInitialValues = (
  requiredData: GetObjectivesInitialData,
  userId: string
  ): CreateObjectiveMainFormModel => {
  let ownerId: number | null = null;


  if(requiredData.members.length > 0){
    requiredData.members.forEach((element, index) => {
      if(element.value === parseInt(userId)){
        ownerId = index
      }
    })
  }
  
  return {
    name: "",
    description: "",
    timeframe: null,
    team: null,
    owner: (ownerId === null) ? null : {value: requiredData.members[ownerId].value, label: requiredData.members[ownerId].label},
    coach: null,
    tags: null,
    cultural_attributes: null,
    key_results: [{
      name: "", 
      description: "", 
      init_value: "", 
      target_value: "", 
      unit: (requiredData.okrUnits.length > 0) ? 
        {
          value: requiredData.okrUnits[0].value, label: 
          requiredData.okrUnits[0].label
        }
        :
        null
    }]
  }
}