
export const GET_TIMEFRAMES_AS_CATALOGUE = `
  query GetOrganizationTimeframes($organizationId: ID!){
    tgdOrganization(id: $organizationId){
      tgd_timeframes{
        value: id
        label: name      
        date_until
        date_initial  
      }
    }
  }
`

export const GET_CULTURAL_ATTRIBUTES_AS_CATALOGUE = `
  query GetAvailableCUlturalAttributes($organizationId: ID!, ){
    tgdPilars(where: {is_archived: false, tgd_organization: {id: $organizationId}}){
      value: id
      label: name
      complementaryData: type
    }
  }
`

export const GET_TAGS_AS_CATALOGUE = `
  query GetTags($organizationId: ID!, $isArchived: Boolean) {
    tgdTags(where: { tgd_organization: { id: $organizationId }, is_archived: $isArchived}) {
      value: id
      label: name
    }
  }
`

export const GET_OKR_UNITS_AS_CATALOGUE = `
  query GetOkrUnits {
    tgdKrUnitsCatalogs{
      value: id
      label: name    
    }
  }
`