
export enum OBJECTIVES_ROLE_FILTER {
  all = 0,
  owner = 1,
  coach = 2,
  collaborator = 3
}

export enum OBJECTIVES_STATUS_STRING {
  active = "2",
  closed = "3",
  archived = "4",
  darft = "1"
}

export enum OBJECTIVES_ACTIONS {
  addProgress = "AddProgress",
  archive = "ArchiveObjective",
  close = "MarkClosed",
  delete = "DeleteObjective",
  edit = "EditObjective",
  replicate = "ReplicateObjective",
  review = "ReviewObjective",
  unarchive = "UnarchiveObjective"
}

export enum CREATE_OBJECTIVES_ACTIONS {
  create = "create",
  edit = "edit",
  replicate = "replicate"
}

export enum OBJECTIVE_TYPES {  
  individual = 3,
  team = 2,
  organization = 1,  
}

export enum OBJECTIVE_COMMENTS_TYPES {
  comment = "comment",
  request = "request",
  approval = "approval"
}

export enum OBJECTIVES_APPROVE_DECLINE_COMMENTS {
  could = "could",
  good = "good"
}

export const ApproveDeclineHeaderMessage = {
  [OBJECTIVES_APPROVE_DECLINE_COMMENTS.good]: "Looks good, go ahead",
  [OBJECTIVES_APPROVE_DECLINE_COMMENTS.could]: "You could improve",
}