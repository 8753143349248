
import { TegduCreatableSelectProps } from "@components/TegduFormComponents/TegduCreatableSelect/TegduCreatableSelect";
import { Controller } from "react-hook-form";


interface CreatableSelectFormPropsHOC extends Omit<TegduCreatableSelectProps, 'onChange' | 'value'> {
  name: string
  control: any
}

const withCreatableSelectController = (WrappedComponent: (props: TegduCreatableSelectProps) => JSX.Element) => {
  const WithCreatableSelectController = (props: CreatableSelectFormPropsHOC) => {
    const { name, control } = props;

    return(
      <Controller 
        control={control}
        name={name}
        render = { ( { field } ) => (
          <WrappedComponent 
            {...props}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
          />
        )}
      />
    )
  }

  return WithCreatableSelectController
}

export default withCreatableSelectController;