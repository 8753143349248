import { TimeframesDatesModel } from "@models/timeframes.models";
import moment from "moment";
import './styles.scss';

interface TegduSelectCulturalAttributesOptionsProps {
  label: string
  timeframes: TimeframesDatesModel | undefined
}

const TegduSelectTimeframesOptions = (props: TegduSelectCulturalAttributesOptionsProps) => {
  const { label, timeframes } = props;
  
  return(
    <div className="tegdu-select-timeframes-options">
      <span>{label}</span> 
                    
      {(timeframes !== undefined) &&
        <span className='dates-range'>
          {(timeframes.date_initial !== undefined && timeframes.date_until !== undefined) &&
            moment(timeframes.date_initial).format('ll')} - {moment(timeframes.date_until).format('ll')
          }
        </span>
      }
      
    </div>
  )
}

export default TegduSelectTimeframesOptions;