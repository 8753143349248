import * as Yup from 'yup';


export interface CreateSkillsAptitudesFormModel {
    name: string,
    type: string,
    description: string
}

export const CreateSkillsAptitudesFormSchema = Yup.object().shape({
  name: Yup.string().max(50).required(),
  description: Yup.string().max(150).required(),
})

export const createSkillsAptitudesFormInitialValues: CreateSkillsAptitudesFormModel = {
  name: "",
  type: "skill",
  description: "",
}