import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import UpdateIcon from '@material-ui/icons/Update';
import { OBJECTIVE_STATUS } from '@utils/objectives';
import { OBJECTIVES_ACTIONS } from 'lib/enums/objectives.enum';

export interface ObjectivesMenuDotOptionsResponse {
  messageId: string
  keyName: OBJECTIVES_ACTIONS
  icon: any
}

export const ObjectivesMenuDotOptions = (objectiveStatus: number, specialCoach?: boolean, inTable?: boolean):ObjectivesMenuDotOptionsResponse [] => {
  switch (objectiveStatus) {
    case OBJECTIVE_STATUS.draft:
      return [
        {
          messageId: 'objectives.button.edit',
          keyName: OBJECTIVES_ACTIONS.edit,
          icon: <EditIcon />,
        },
        {
          messageId: 'objectives.button.delete',
          keyName: OBJECTIVES_ACTIONS.delete,
          icon: <DeleteIcon />,
        },
      ];
    case OBJECTIVE_STATUS.active:
      if (specialCoach === true) {
        if (inTable) {
          return [
            {
              messageId: 'objectives.button.addProgress',
              keyName: OBJECTIVES_ACTIONS.addProgress,
              icon: <UpdateIcon />,
            }
          ];          
        } else {
          return [
            {
              messageId: 'objectives.button.review',
              icon: <StarIcon />,
              keyName: OBJECTIVES_ACTIONS.review
            },
            {
              messageId: 'objectives.button.addProgress',
              keyName: OBJECTIVES_ACTIONS.addProgress,
              icon: <UpdateIcon />,
            }
          ];
        }
      } else {
        if (inTable) {
          return [
            {
              messageId: 'objectives.button.addProgress',
              keyName: OBJECTIVES_ACTIONS.addProgress,
              icon: <UpdateIcon />,
            },
            {
              messageId: 'objectives.button.edit',
              keyName: OBJECTIVES_ACTIONS.edit,
              icon: <EditIcon />,
            },
            {
              messageId: 'objectives.button.close',
              icon: <CheckCircleIcon />,
              keyName: OBJECTIVES_ACTIONS.close
            },
            {
              messageId: 'objectives.button.replicate',
              icon: <FileCopyIcon />,
              keyName: OBJECTIVES_ACTIONS.replicate,
            },
            {
              messageId: 'objectives.button.archive',
              icon: <ArchiveIcon />,
              keyName: OBJECTIVES_ACTIONS.archive
            }
          ];
        } else {
          return [
            {
              messageId: 'objectives.button.edit',
              keyName: OBJECTIVES_ACTIONS.edit,
              icon: <EditIcon />,
            },
            {
              messageId: 'objectives.button.close',
              icon: <CheckCircleIcon />,
              keyName: OBJECTIVES_ACTIONS.close
            },
            {
              messageId: 'objectives.button.replicate',
              icon: <FileCopyIcon />,
              keyName: OBJECTIVES_ACTIONS.replicate,
            },
            {
              messageId: 'objectives.button.archive',
              icon: <ArchiveIcon />,
              keyName: OBJECTIVES_ACTIONS.archive
            },
            {
              messageId: 'objectives.button.review',
              icon: <StarIcon />,
              keyName: OBJECTIVES_ACTIONS.review
            }
          ];
        }
      }
    case OBJECTIVE_STATUS.closed:
      return [];
    case OBJECTIVE_STATUS.archived:
      return [
        {
          messageId: 'objectives.button.unarchive',
          icon: <UnarchiveIcon />,
          keyName: OBJECTIVES_ACTIONS.unarchive
        },
      ];
    default:
      return [];
  }
};
