import { FormattedMessage } from 'react-intl';

import './styles.scss';

interface SectionDividerProps {
  labelId: string;
  children: React.ReactNode;
  intlValues?: any;
  contentClass?: string;
}

const SectionDivider = ({
  labelId,
  children,
  intlValues = {},
  contentClass = '',
}: SectionDividerProps) => {
  return (
    <div className='component-sectiondivider'>
      <h4>
        <FormattedMessage id={labelId} values={intlValues} />
      </h4>
      <div className={contentClass ? contentClass : ''}>{children}</div>
    </div>
  );
};

export default SectionDivider;
