import GenericTable from '@components/Shared/GenericTable/GenericTable'
import { TableData } from '@containers/Invitations/AcceptedInvitationsContainer/AcceptedInvitationsContainer'
import { Card } from '@material-ui/core'
import AcceptedInvitationsColumns from './AcceptedInvitationsColumns'
import "./style.scss"

interface AcceptedInvitationsTableProps {
  data: TableData[] | null
  error: any;
  loading: boolean;
  retryCallback: () => void
}

const AcceptedTable = (props: AcceptedInvitationsTableProps) => {
  const { data, error, loading, retryCallback } = props

  return (
    <div className='accepted-table'>
      <Card className='card-container'>
        <GenericTable
          label='invitations'
          columns={AcceptedInvitationsColumns()}
          data={data}
          pagination={true}
          loading={loading}
          error={error}
          retryCallback={retryCallback}
        />
      </Card>
    </div>
  )
}

export default AcceptedTable