import { TegduSelectProps } from "@components/TegduFormComponents/TegduSelect/TegduSelect";
import { Controller } from "react-hook-form";

interface SelectControllerPropsHOC extends Omit<TegduSelectProps, 'value'| 'onChange'| 'onBlur'> {
  name: string
  control: any
}

const withSelectController = (WrappedComponent: (props: TegduSelectProps) => JSX.Element) => {
  const WithSelectController = (props: SelectControllerPropsHOC) => {
    const { name, control } = props;
    
    return(
      <Controller 
        name={name}
        control={control}
        render={ ( { field } ) => (
          <WrappedComponent 
            {...props}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
          />
        )}
      />
    )
  }
  return WithSelectController;
}

export default withSelectController;