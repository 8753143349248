import { ObjectivesLogs, ObjectivesOKRReviewRate } from "@models/objetives.model";
import { getObjectiveLogs, getObjectiveOKRReviews } from "@services/objectives.service";
import { Dispatch } from "redux";

export const GET_OBJECTIVES_DEATILS_OKR_LOADING = "GET_OBJECTIVES_DEATILS_OKR_LOADING";
export const GET_OBJECTIVES_LOGS_LOADING = "GET_OBJECTIVES_LOGS_LOADING";

export const setLogsLoading = (isLoading: boolean) => {
  return {
    type: GET_OBJECTIVES_LOGS_LOADING,
    payload: isLoading
  }
}
export const setRatesOKRReviewsLoading = (isLoading: boolean) => {
  return {
    type: GET_OBJECTIVES_DEATILS_OKR_LOADING,
    payload: isLoading
  }
}

export const getRatesOKRReviews = (
  objectiveId: number,
  handleSuccess: (data:ObjectivesOKRReviewRate)=>void
  ) => async (dispatch: Dispatch) => {
  dispatch(setRatesOKRReviewsLoading(true))
  try {
    const data = await getObjectiveOKRReviews(objectiveId)
    dispatch(setRatesOKRReviewsLoading(false))
    handleSuccess(data)
    return data
  } catch {
    dispatch(setRatesOKRReviewsLoading(false))
    return {}
  }
}

export const getLogs = (
  objectiveId: number,
  handleSuccess: (data:ObjectivesLogs[])=>void
  ) => async (dispatch: Dispatch) => {
  dispatch(setLogsLoading(true))
  try {
    const data = await getObjectiveLogs(objectiveId)
    dispatch(setLogsLoading(false))
    handleSuccess(data)
    return data
  } catch {
    dispatch(setLogsLoading(false))
    return {}
  }
}