import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { LocalState } from '@store/User/Info/info.model';
import { connect } from 'react-redux';
import { RootState } from '@store/rootReducer';
import Layout from '@components/Template/Layout/Layout';
import { useSelector } from 'react-redux';
import { MenuRole } from '@models/permissions.model';
import { ROUTE } from '@utils/routes';
import { LayoutMobile } from 'pwa/components/Template/Layout/Layout';

interface AuthorizedRouteHOCStateProps {
  localState: LocalState;
}

interface AuthorizedRouteProps extends RouteProps, AuthorizedRouteHOCStateProps {
  authState: LocalState;
  layout?: boolean;
  isLayoutMobile?:boolean;
  withHeaderMobile?:boolean;
  withBottomMenuMobile?:boolean
}

const checkPageAccess = (path: string | string[] | undefined, appRoutes: MenuRole[]) => {
  let isUserAuthorized = false;
  let url = path;
  //let allowedActionsName = [] as string[];

  // If permittedPages exists
  if (Array.isArray(appRoutes) && appRoutes.length > 0) {
    if (url?.includes('/members/') || url?.includes('/objectives/')) {
      isUserAuthorized =
        appRoutes.find((route) => url?.includes(route.key_name)) !== undefined ? true : false;
    } else {
      isUserAuthorized =
        appRoutes.find((route) => route.key_name === url?.slice(1)) !== undefined ? true : false;
    }

    appRoutes.forEach((element) => {
      // If Url exists
      if (element.key_name === url?.slice(1)) {
        // If Action exists
        // if (Array.isArray(element.actions) && element.actions.length > 0) {
        //   // Prepare Action List
        //   element.actions.forEach((item) => {
        //     allowedActionsName.push(item.actionUrl);
        //   });
        // }
      }
    });
  }
  return {
    isUserAuthorized,
    //allowedActionsName,
  };
};

export const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({
  localState,
  authState,
  layout,
  component: Component,
  path,
  isLayoutMobile,
  withHeaderMobile= true,
  withBottomMenuMobile = true,
  ...rest
}: AuthorizedRouteProps) => {
  const menuOptions = useSelector((state: RootState) => state.User.Info.menuOptions);
  const { isUserAuthorized } = checkPageAccess(path, menuOptions);

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          (isUserAuthorized === false || typeof isUserAuthorized == 'undefined') && authState ? (
            // <Redirect to={{ pathname: '/error', state: { from: props.location } }} />
            <Redirect
              to={{
                pathname:
                  localState === 'initial'
                    ? ROUTE.login.path
                    : localState === 'onboarding'
                    ? ROUTE.onboarding.path
                    : localState === 'accessState'
                    ? menuOptions
                      ? menuOptions[0]?.key_name
                      : ROUTE.error.path
                    : ROUTE.error.path,
                state: { from: props.location },
              }}
            />
          ) : localState === authState && Component ? (
            <>
              {isLayoutMobile && !layout &&
               <LayoutMobile header={withHeaderMobile} bottomBar={withBottomMenuMobile}>  
                  <Component {...{ ...props }} />
                </LayoutMobile>
               }
              {layout  && !isLayoutMobile && (
                <Layout>
                  <Component {...{ ...props }} />
                </Layout>
              ) }
              {!layout && !isLayoutMobile && <Component {...props} />}
            </>
          ) : (
            <Redirect
              to={{
                pathname:
                  localState === 'initial'
                    ? ROUTE.login.path
                    : localState === 'onboarding'
                    ? ROUTE.onboarding.path
                    : localState === 'accessState'
                    ? ROUTE.dashboard.path
                    : ROUTE.error.path,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </>
  );
};

const mapStateToProps = (state: RootState): AuthorizedRouteHOCStateProps => {
  return {
    localState: state.User.Info.localState,
  };
};

export default connect(mapStateToProps)(AuthorizedRoute);
