import React, { useCallback } from 'react';
import GenericTable from '@components/Shared/GenericTable/GenericTable';
import { TeamTableData } from '@models/teams.model';
import ExpandableCell from '../ExpandableCell/ExpandableCell';
import moment from 'moment';
import LettersCircle from '@components/Shared/LettersCircle/LettersCircle';
interface TeamsTableProps {
  data: TeamTableData[];
  loading: boolean;
  error: any;
  onOpenDetails: (id: string) => void;
}

const customStyles = {
  tableWrapper: {
    style: {
      tableLayout: 'fixed',
    },
  },
  cells: {
    style: {
      paddingTop: '15px',
      paddingBottom: '15px',
      alignItems: 'flex-start',
    },
  },
};

const columns = [
  {
    width: '60px', // custom width for icon button
    style: {
      paddingTop: '5px',
      paddingBottom: '0',
    },
    cell: (row: TeamTableData) => <LettersCircle name={row.name} size='md' />,
  },
  {
    name: 'TEAM',
    selector: 'name',
    sortable: true,
    grow: 1,
  },
  {
    name: 'LEADERS',
    selector: 'leads',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'DATE CREATED',
    maxWidth: '200px',
    selector: 'create_time',
    sortable: true,
    format: (row: TeamTableData) => moment(row.create_time).format('MM/DD/YYYY'),
    grow: 1.5,
    hide: 'sm',
  },
  {
    name: 'MEMBERS',
    selector: 'members',
    sortable: true,
    cell: (row: TeamTableData) => {
      return <ExpandableCell data={row.members} />;
    },
    grow: 3,
    hide: 'sm',
  },
];

const TeamsTable = ({ data, loading, error, onOpenDetails }: TeamsTableProps) => {
  const handleRowClick = useCallback(
    (team: TeamTableData) => onOpenDetails(team.id),
    [onOpenDetails]
  );

  return (
    <div className='component-teamstable'>
      <GenericTable
        label={'teams'}
        columns={columns}
        data={data}
        pagination={true}
        customStyles={customStyles}
        onRowClicked={handleRowClick}
        pointerOnHover={true}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default TeamsTable;
