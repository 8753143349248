import { AppBar, Tab, Tabs } from "@material-ui/core";
import RateGeneralReview from "pwa/components/Score/RateGeneralReview/RateGeneralReview";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl"
import "./style.scss"
import TabPanel from '@components/Shared/TabPanel/TabPanel';
import FormIndividualRate from "pwa/components/Score/FormIndividualRate/FormIndividualRate";
import { ObjectiveScore } from "@models/score.model";
import { getObjectiveUsers } from "@services/objectives.service";
import { ApiGetObjectiveUser } from "@models/objetives.model";

interface ModalRateObjectiveProps {
  objectiveId: number | string;
  onSkip: () => void;
  onGeneralRate: (comments: string, score: number) => void;
  onIndividualRate: (scores: ObjectiveScore[]) => void;
  loadingGeneralRate: boolean;
  loadingIndividualRate: boolean;
  objectiveName: string
  teamName?: string
}

const ObjectivesMarkAsClosedContainer = (props: ModalRateObjectiveProps) => {
  const { objectiveId, onSkip, onGeneralRate, onIndividualRate, loadingGeneralRate, loadingIndividualRate, objectiveName, teamName } = props
  const [tabValue, setTabValue] = useState(0)
  const [users, setUsers] = useState<ApiGetObjectiveUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const getObjectiveUsersData = useCallback(async () => {
    if (objectiveId) {
      try {
        setLoadingUsers(true);
        const data = await getObjectiveUsers(objectiveId);
        setUsers(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingUsers(false);
      }
    }
  }, [objectiveId]);

  useEffect(() => {
    getObjectiveUsersData();
  }, [getObjectiveUsersData]);


  return (
    <div className="container-mark-as-closed">
      <span className="review-title"><FormattedMessage id="mobile.objectives.review.container.subtitle" /></span>
      <div className="tabs-wrapper">
        <AppBar position="static">
          <Tabs value={tabValue} onChange={handleTabChange} className="review-tabs" >
            <Tab label={<FormattedMessage id='mobile.objectives.review.tab.general' />} id='general' />
            <Tab label={<FormattedMessage id='mobile.objectives.review.tab.individual' />} id='individual' />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0}>
          <RateGeneralReview
            onCancel={onSkip}
            onSubmit={onGeneralRate}
            loading={loadingGeneralRate}
            teamName={teamName}
            objectiveName={objectiveName}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FormIndividualRate
            onCancel={onSkip}
            onSubmit={onIndividualRate}
            loading={loadingIndividualRate}
            loadingUsers={loadingUsers}
            objectiveUsers={users}
            objectiveName={objectiveName}
          />
        </TabPanel>

      </div>
    </div>
  )
}

export default ObjectivesMarkAsClosedContainer