import TegduSelect from "../../TegduFormComponents/TegduSelect/TegduSelect";
import { TegduSelectOptionsModel } from "@models/global.model";
import { useCallback, useState } from "react";
import './styles.scss';

interface TegduMenuProps {
  defaultValue?: TegduSelectOptionsModel
  options: TegduSelectOptionsModel []
  type: 'normal' | 'datepicker' | 'light'
  handleSelectedValue: (value: string | number) => void
}

const TegduMenu = (props: TegduMenuProps) => {
  const { defaultValue, options, type, handleSelectedValue } = props;
  const [value, setValue] = useState<TegduSelectOptionsModel | undefined>(defaultValue);

  const handleChange = useCallback((data: TegduSelectOptionsModel) => {
    setValue(data);
    handleSelectedValue(data.value)
  }, [handleSelectedValue])

  return(
    <div className={`tegdu-menu-${type}`}>
      <TegduSelect       
        onChange={handleChange}
        value={value}
        options={options}
        isClearable={false}
        isSearchable={false}
        isMulti={false}
      />
    </div>
  )
}

export default TegduMenu;