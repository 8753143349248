import { TegduSelectOptionsModel } from "@models/global.model";
import { ObjectiveDataGraphModel, ObjectiveKeyResultsGraphModel, ObjectiveRoleUsersGraphModel, TgdElementGraphModel } from "@models/objectives.models";
import { GetObjectivesInitialData } from "@store/Objectives/ObjectivesMainActions/objectivesMainActions.model";
import { OBJECTIVE_ROLE, OBJECTIVE_STATUS } from "@utils/objectives";
import { OBJECTIVE_TYPES } from "lib/enums/objectives.enum";
import { CreateObjectiveMainFormModel, ObjectivesKeyResultsFormModel } from "lib/TegduForms/CreateObjectiveMainFormUtils";

export const findObjectiveStatus = (id: string): OBJECTIVE_STATUS => {
  switch(id){
    case OBJECTIVE_STATUS.active.toString():
      return OBJECTIVE_STATUS.active
    case OBJECTIVE_STATUS.archived.toString():
      return OBJECTIVE_STATUS.archived
    case OBJECTIVE_STATUS.closed.toString():
      return OBJECTIVE_STATUS.closed
    case OBJECTIVE_STATUS.deleted.toString():
      return OBJECTIVE_STATUS.deleted
    case OBJECTIVE_STATUS.draft.toString():
      return OBJECTIVE_STATUS.draft
    default:
      return OBJECTIVE_STATUS.closed
  }
}

export const findObjectiveType = (objectiveType: null | { id: string }): null | OBJECTIVE_TYPES => {
  if(objectiveType === null){
    return null
  }else {
    switch(objectiveType.id){
      case OBJECTIVE_TYPES.individual.toString():
        return OBJECTIVE_TYPES.individual
      case OBJECTIVE_TYPES.organization.toString():
        return OBJECTIVE_TYPES.organization
      case OBJECTIVE_TYPES.team.toString():
        return OBJECTIVE_TYPES.team
      default:
        return null
    }
  }
} 

const findCoachesUserIds = (rolesArray: ObjectiveRoleUsersGraphModel []): string [] => {
  let coachesIds: string [] = [];

  rolesArray.forEach((element) => {
    if(element.tgd_role.id === OBJECTIVE_ROLE.coach.toString()){
      coachesIds.push(element.user.id)
    }
  })

  return coachesIds
}

const findOwnerUserId = (rolesArray: ObjectiveRoleUsersGraphModel []): string => {
  let userId: string = "0";

  rolesArray.forEach((element) => {
    if(element.tgd_role.id === OBJECTIVE_ROLE.owner.toString()){
      userId = element.user.id
    }
  })

  return userId
}

const buildSingleOptionValue = (
    id: string, 
    catalogue: TegduSelectOptionsModel []
  ): TegduSelectOptionsModel => {
  
  let indexFromCatalogue: number = 0;
  catalogue.forEach((element, index) => {
    if(parseInt(id) === parseInt(element.value as string)){
      indexFromCatalogue = index 
    }
  })

  return { 
    value: catalogue[indexFromCatalogue].value,
    label: catalogue[indexFromCatalogue].label,
  }
}

const buildMultipleOptionValues = (
    ids: string [], 
    catalogue: TegduSelectOptionsModel []
  ): TegduSelectOptionsModel [] | null => {

  let indexes: number [] = [];
  let multipleValues: TegduSelectOptionsModel [] = [];
  if(ids.length === 0){
    return null
  }else {
    ids.forEach((id) => {
      catalogue.forEach((element, index) => {        
        if(id.toString() === element.value.toString()){
          indexes.push(index)
        }
      })
    })

    indexes.forEach((element) => {
      multipleValues.push({
        value: catalogue[element].value,
        label: catalogue[element].label,
      })
    })

    return multipleValues

  }
}

const findTgdElementIds = (elementArray: TgdElementGraphModel []): string [] => {
  let indexes: string [] = []

  elementArray.forEach((element) => {
    indexes.push(element.tgd_element.id)
  })

  return indexes
}

const buildKeyResultsFromGraph = (
    graphKeyResults: ObjectiveKeyResultsGraphModel [], 
    unitsCatalog: TegduSelectOptionsModel [],
    isReplica: boolean
  ): ObjectivesKeyResultsFormModel[] => {

  let keyresultsFormModel: ObjectivesKeyResultsFormModel [] = [];

  graphKeyResults.forEach((element) => {
    keyresultsFormModel.push({
      id: element.creationId,
      name: element.name,
      description: element.description,
      init_value: isReplica ? 
        (element.current_value === null) ? "" : element.current_value.toString()
        : (element.init_value === null) ? "" : element.init_value.toString(),
      target_value: (element.target_value === null) ? "" : element.target_value.toString(),
      unit: buildSingleOptionValue(element.unit.id, unitsCatalog)
    })
  })

  return keyresultsFormModel
}

export const initializeFromObjectiveCreated = (
    objectiveData: ObjectiveDataGraphModel, 
    catalogs: GetObjectivesInitialData,
    isReplica: boolean
  ): CreateObjectiveMainFormModel => {
  return {
    name: objectiveData.name,
    description: objectiveData.description,
    timeframe: (objectiveData.tgd_timeframe === null) ? null : buildSingleOptionValue(objectiveData.tgd_timeframe.id, catalogs.timeframes),
    owner: buildSingleOptionValue(findOwnerUserId(objectiveData.tgd_obj_role_users), catalogs.members),
    coach: buildMultipleOptionValues(findCoachesUserIds(objectiveData.tgd_obj_role_users), catalogs.members),
    tags: buildMultipleOptionValues(findTgdElementIds(objectiveData.tgd_tag_objectives) , catalogs.tags),
    cultural_attributes: buildMultipleOptionValues(findTgdElementIds(objectiveData.tgd_obj_pillars) , catalogs.culturalAttributes),
    key_results: buildKeyResultsFromGraph(objectiveData.tgd_key_results, catalogs.okrUnits, isReplica),
    team: buildMultipleOptionValues(findTgdElementIds(objectiveData.tgd_objective_teams), catalogs.teams)
  }
  
}