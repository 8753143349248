import ReviewsContainer from "@containers/Reviews/ReviewsContainer/ReviewsContainer";

const ReviewsScreen = () => {
  return (
    <div id='tourstep-reviews'>
      <ReviewsContainer />;
    </div>
  )
};

export default ReviewsScreen;
