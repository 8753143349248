import { buildMemberOptions } from '@utils/builders';
import TgdClient from '@helpers/HttpClients/TgdClient';
import {
  ApiGetMembersCatalogue,
  ApiUpdateMembers,
  ChangeLeaderDataI,
  MembersModel,
  FormMemberValues,
} from '@models/members.model';
import { TegduSelectOptionsModel } from '@models/global.model';
//import { GET_MEMBERS_PLAIN } from '@graphql/members.queries';

export const getMembersCatalogue = async (
  organizationId: number,
  name?: string
): Promise<TegduSelectOptionsModel[]> => {
  const nameSearch = name ? name : '';
  const data = await TgdClient.get<ApiGetMembersCatalogue[]>(
    `/custom-members/catalogue?organizationId=${organizationId}&name=${nameSearch}`
  );
  return buildMemberOptions(data);
};

export const dummyMembers = async (): Promise<any> => {
  return await TgdClient.get(`/users/`);
};

/* Update individual member */
export const updateMember = async (id: string, data: FormMemberValues): Promise<any> => {
  const membersBody: ApiUpdateMembers = {
    first_name: data.firstName,
    last_name: data.lastName,
    job_title: data.jobTitle,
    role: {
      id: data.role,
    },
    hr: data.hr
  };

  return await TgdClient.put(`/tgd-users/${id}`, membersBody);
};

/* Update personal info */
export const updatePersonalInfo = async (id: string, data: any): Promise<any> => {
  const membersBody = {
    first_name: data.first_name,
    last_name: data.last_name,
    job_title: data.job_title,
  };

  return await TgdClient.put(`/users/${id}`, membersBody);
};

/* Gter members */
export const getMembersNoLimit = async (organizationId: number): Promise<MembersModel[]> => {
  return TgdClient.get(`/users?_limit=-1&tgd_organization.id=${organizationId}`);
};

/* Change leader */
export const changeLeader = async (data: ChangeLeaderDataI): Promise<any> => {
  const newLeader = {
    current: data.current,
    new: data.new,
  };

  return await TgdClient.post(`/tgd-teams/leader/change`, newLeader);
};

/* Suspend a leader */
export const toggleActiveSuspenseLeader = async (id: string, isActive: boolean): Promise<any> => {
  const leaderToSuspend = {
    active: isActive,
  };
  return await TgdClient.put(`/users/${id}/status`, leaderToSuspend);
};
