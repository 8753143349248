import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { ResetPassSchema } from '@formsValidations/formsValidations';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import LabelWitchCheckbox from '@components/Shared/LabelWithCheckbox/labelWithCheckbox';
import ForrmikListener from '@components/FormikComponents/FormikListener/ForrmikListener';
import withTextFastField from '@hocs/withTextFastField';
import './styles.scss';
import Spinner from '@components/Shared/Spinner/Spinner';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);

interface NewPasswordFormProps {
  loading: boolean;
  mode: 'reset' | 'reactivate';
  isValidForm?: (value: boolean) => void;
  onCancel?: () => void;
  onSend: () => void;
  onValues: (email: any) => void;
}

const NewPasswordForm = ({
  loading,
  mode,
  onCancel,
  isValidForm,
  onValues,
  onSend,
}: NewPasswordFormProps) => {
  const [values, setValues] = useState<undefined | string>(undefined);
  const [validateCapital, setValidateCapital] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [validateLength, setValidateLength] = useState(false);
  const [validateSpecial, setValidateSpecial] = useState(false);

  const initialValues = useMemo(() => {
    return {
      password: '',
      confirmPassword: '',
    };
  }, []);

  const getIsValid = useCallback(
    (value: boolean) => {
      if (isValidForm) {
        isValidForm(value);
      }
    },
    [isValidForm]
  );

  const getValidationsValues = useCallback(
    (valuesPass: any) => {
      setValues(valuesPass.password);
      onValues(valuesPass);
    },
    [onValues]
  );

  //Revisar validaciones de values
  useEffect(() => {
    const validateCapitalLetter = /[A-Z]/;
    const validateNumber = /\d/;
    // eslint-disable-next-line
    const validateSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (values !== undefined) {
      if (validateCapitalLetter.test(values)) {
        setValidateCapital(true);
      } else {
        setValidateCapital(false);
      }
      if (validateNumber.test(values)) {
        setValidateNumber(true);
      } else {
        setValidateNumber(false);
      }
      if (validateSpecialChars.test(values)) {
        setValidateSpecial(true);
      } else {
        setValidateSpecial(false);
      }
      if (values.length >= 8) {
        setValidateLength(true);
      } else {
        setValidateLength(false);
      }
    }
  }, [values]);

  return (
    <>
      <div className='titleCreatePass__container'>
        <h1>
          <FormattedMessage
            id={
              mode === 'reset' ? 'resetPassword.subtitle.createPassword' : 'reactivate.form.title'
            }
          />
        </h1>
      </div>
      <div className='instructionsCreatePass__container'>
        <p>
          <FormattedMessage
            id={
              mode === 'reset'
                ? 'resetPassword.createPassword.instrucctions'
                : 'reactivate.form.description'
            }
          />
        </p>
      </div>
      <Grid className='password-checkboxes' container alignItems={'center'} spacing={2}>
        <Grid item md={6} xs={12} sm={6}>
          <LabelWitchCheckbox
            color={'secondary'}
            checked={validateLength}
            label={'resetPassword.passwordRequired.8characters'}
          />
        </Grid>
        <Grid item md={6} xs={12} sm={6}>
          <LabelWitchCheckbox
            color={'secondary'}
            checked={validateSpecial}
            label={'resetPassword.passwordRequired.1specialCharacter'}
          />
        </Grid>
        <Grid item md={6} xs={12} sm={6}>
          <LabelWitchCheckbox
            color={'secondary'}
            checked={validateCapital}
            label={'resetPassword.passwordRequired.1capitalletter'}
          />
        </Grid>
        <Grid item md={6} xs={12} sm={6}>
          <LabelWitchCheckbox
            color={'secondary'}
            checked={validateNumber}
            label={'resetPassword.passwordRequired.1number'}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={() => undefined}
        validationSchema={ResetPassSchema}
      >
        {(formik) => {
          let areEqual = false;
          let validForm = formik.isValid;
          if (JSON.stringify(formik.values) === JSON.stringify(initialValues)) {
            areEqual = true;
          } else {
            areEqual = false;
          }
          const resetForm = () => {
            formik.resetForm({ values: initialValues });
            // eslint-disable-next-line
            formik.setTouched({ ...formik.touched });
            if (onCancel) {
              onCancel();
            }
          };
          return (
            <Form className='form-newpassword'>
              <ForrmikListener getIsValid={getIsValid} getValues={getValidationsValues} />
              <FormikTextInputFastField
                name='password'
                labelId='resetPassword.inputLabel.newPassword'
                type='password'
                viewMode={false}
              />
              <FormikTextInputFastField
                name='confirmPassword'
                labelId='resetPassword.inputLabel.confirmPassword'
                type='password'
                viewMode={false}
              />
              <div className='newpassword-footer'>
                {onCancel ? (
                  <Button className='secondary cancel__btn' disabled={loading} onClick={resetForm}>
                    <FormattedMessage id={'resetPassword.button.cancel'} />
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  className='primary'
                  type='button'
                  disabled={areEqual || !validForm || loading}
                  onClick={onSend}
                >
                  {loading === true && <Spinner />}
                  <FormattedMessage
                    id={
                      mode === 'reset' ? 'resetPassword.button.continue' : 'reactivate.form.button'
                    }
                  />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NewPasswordForm;
