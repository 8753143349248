import moment from 'moment';
import DoubleCircularProgress from 'pwa/components/Shared/DoubleCircularProgress/DoubleCircularProgress';
import { dataToAddProgressI } from 'pwa/components/Template/ModalSearchObjectivesContainer/ModalSearchObjectivesContainer'
import  './styles.scss'

interface ObjectiveRowToSelectProps extends dataToAddProgressI{
  getDataObjectiveSelected:  (data:dataToAddProgressI)=>void;
}

const ObjectiveRowToSelect = (props: ObjectiveRowToSelectProps) => {
  const {getDataObjectiveSelected, due, id, nameObjective, progress} = props;
  return (
    <div onClick={
      ()=>
      getDataObjectiveSelected({nameObjective, due, id, progress})
      } className='item-objective-row-to-select' >
      <div className='name'>{nameObjective}</div>
      <div className='date'>
        {moment(due).format("MM/DD/YYYY")}
      </div>
      <div className='progress'>
        <DoubleCircularProgress
          isTable
          sizeCirular={36}
          value={progress * 100}
          valueToShow={progress.toFixed(1)}
        />
      </div>
    </div>
  )
}

export default ObjectiveRowToSelect