import { TextareaAutosize } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import './styles.scss';

export interface TegduTextAreaProps {  
  errorMessage?: string
  isEditMode: boolean
  labelMessageId?: string
  maxLength?: number
  value: string
  withErrorArea?: boolean
  withMaxLength?: boolean
  onBlur?: (data: any) => void
  onChange: (data: any) => void  
}

const TegduTextArea = (props: TegduTextAreaProps) => {
  const { 
    value, 
    isEditMode, 
    labelMessageId, 
    errorMessage, 
    maxLength, 
    withMaxLength, 
    withErrorArea 
  } = props;
  const { onBlur, onChange } = props;

  return(
    <div className={`tegdu-text-area ${errorMessage ? 'with-error' : ''}`}>
      {labelMessageId &&
        <div className="label-message">
          <span>
            <FormattedMessage 
              id={labelMessageId}
            />
          </span>
        </div>
      }

      {(isEditMode === false) ?
        <div className="view-mode-value">
          {value}
        </div>
      :        
        <TextareaAutosize
          className={`text-area-style`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rowsMin={4}        
          rowsMax={4}
          maxLength={maxLength ? maxLength : 200}
        />        
      }
      
      {((withErrorArea || withMaxLength) && isEditMode === true)&&
        <div className="complementary-info">
          <div className="error-message">
            {(errorMessage) &&
              <FormattedMessage 
                id={errorMessage}
              />
            }
          </div>
  
          <div className="max-length">
            {maxLength &&
              <>
                {`${value?.length}/${maxLength}`}
              </>
            }            
          </div>
        </div>
      }          
      
    </div>
  )
}

export default TegduTextArea;