//TODO: Coach tgd_role: 1

import { gql } from '@apollo/client';

export const GET_OBJECTIVE_DATA = `
  query GetObjective($objectiveId: ID!) {
    tgdObjective(id: $objectiveId) {
      id
      name
      description
      due_date
      current_progress    
      tgd_timeframe{id}    
      tgd_objective_type{id}
      tgd_obj_role_users(where: {active:1}){creationId: id, tgd_role {id, name}, user{id},active}
      tgd_objective_teams(where:{active:1}){creationId:id , active , tgd_element: tgd_team {id}}
      tgd_obj_pillars(where: {active: 1}){creationId:id, active, tgd_element: tgd_pilar{id}}
      tgd_tag_objectives(where:{active:1}){creationId:id, tgd_element: tgd_tag {id,name}}
      tgd_key_results(where:{active:1}){
        creationId: id 
        name
        description
        init_value
        target_value
        current_progress
        current_value
        active
        unit{id}
      }
      status{id}
    }
  }
`;

export const GET_OBJECTIVE = `
query GetObjective($objectiveId: ID!) {
  tgdObjective(id: $objectiveId) {
    id
    name
    description
    due_date
    current_progress    
    tgd_timeframe_catalog {id}
    tgd_objective_type{id}
    tgd_obj_role_users(where: {active:1}){ id, tgd_role {id, name}, user{id},active}
    tgd_objective_teams(where:{active:1}){id , active , tgd_team {id}}
    tgd_obj_pillars(where: {active: 1}){id, active, tgd_pilar{id}}
    tgd_tag_objectives(where:{active:1}){id, tgd_tag {id,name}}
    tgd_key_results(where:{active:1}){
      id 
      name
      description
      init_value
      target_value
      current_progress
      current_value
      active
      unit{
        id
        name
      }
    }
    status{
      id,
      name
    }
  }
}
`;

export const GET_OBJECTIVE_DETAILS = `
  query GetObjectiveDetails($objectiveId: ID!) {
    tgdObjective(id: $objectiveId) {
      name
      due_date
      description
      current_progress
      due_date
      tgd_timeframe {
        name
        date_initial
        date_until
      }
      tgd_obj_role_users(where: { active: 1 }) {
        id
        tgd_role {
          id
          name
        }
        user {
          id
          first_name
          last_name
        }
        active
      }
      tgd_key_results(where: { active: 1 }) {
        id
        name
        init_value
        target_value
        current_progress
        current_value
        description
        active
        unit {
          id
        }
      }
      tgd_tag_objectives(where: { active: 1 }) {
        id
        tgd_tag {
          id
          name
        }
        active
      }
      status {
        id
        name
      }
      tgd_objective_teams(where: { active: 1 }) {
        id
        tgd_team {
          name
        }
      }
      tgd_obj_pillars(where: { active: 1 }) {
        id
        tgd_pilar {
          name
        }
      }
    }
  }
`;

export const GET_OBJECTIVE_STATUS_CATALOG = `
  {
    tgdObjectiveStatusCatalogs(where: {active: true}){
      value:id
      label:name
    }
  }
`;

export const GET_OKR_MEASURE_UNITS = `
  {
    tgdKrUnitsCatalogs{
      value: id
      label: name    
    }
  }
`;

export const GET_OBJECTIVE_TO_VALIDATE_BELONG = `
  query GetObjectiveBelong($objectiveId: ID!){
    tgdObjective(id: $objectiveId){
      tgd_obj_role_users{
        tgd_role{
          id
        }
        user{
          id
        }
      }
      
      status{
        id
        name
      }
    }
  }
`;

export const GET_OBJECTIVE_SCORE = gql`
  query GetObjectiveScore($objectiveId: ID!) {
    tgdObjectiveScores(where: { tgd_objective: $objectiveId }, sort: "created_at:desc") {
      average_score
      created_at
    }
  }
`;
