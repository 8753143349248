import './styles.scss'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const CurrentPassword = () => {
    return(
        <div className='current-password'>
            <p id="title">
                <FormattedMessage id='account.screen.tab.security.currentpssw'/>
            </p>
            <p id="content">
                <FormattedMessage id='account.screen.tab.security.currentpssw.val' />
            </p>
        </div>
    )
}

export default CurrentPassword;