import React, { useMemo } from 'react';
import FormikCreatableDropdown from '@components/FormikComponents/FormikCreatableDropdown/FormikCreatableDropdown';
import FormikDropdown from '@components/FormikComponents/FormikDropdown/FormikDropdown';
import withCreatableDropFastField from '@hocs/withCreatableDropFastField';
import withDropFastField from '@hocs/withDropFastField';
import { Form, Formik } from 'formik';
import './style.scss'
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { AddMembersEmailsModel, AddMembersFormModel } from '@models/invitations.model';
import Spinner from '@components/Shared/Spinner/Spinner';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const FormikCreatableDropdownFastField = withCreatableDropFastField(FormikCreatableDropdown);
const FormikDropdownFastField = withDropFastField(FormikDropdown);

export interface AddMembersFormProps {
  onSubmit: (data: AddMembersFormModel) => void
  onUploadFile: () => void
  emailsFromFile: AddMembersEmailsModel [] | null
}


const AddMembersForm = (props: AddMembersFormProps) => {
  const { onSubmit, onUploadFile, emailsFromFile } = props;
  const rolesCatalog = useSelector((state: RootState) => state.Catalogs.Roles.data);
  const isAddingMembersLoading = useSelector((state: RootState) => state.Invitations.isAddMembersLoading);

  const initialValues = useMemo(() => {
    if(emailsFromFile === null ){
      return {
        emails: [],
        role: "0"
      }
    }else {
      return {
        emails: emailsFromFile, 
        role: "0"
      }
    }
  }, [emailsFromFile])

  return(
    <div className='add-members-form'>
      {/* <div className='upload-label'>
        <InsertDriveFileIcon />
        <span onClick={onUploadFile}>Upload .xlsx file</span>
      </div> */}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount
      >
        <Form>
          <FormikCreatableDropdownFastField
            name={`emails`}
            label='objectives.modal.invite.label.to'
            placeholder='objectives.modal.invite.label.to.placeholder'
            viewMode={false}
            options={[]}
            validateOnClick            
            blockCallback={true}
            validateEmails
          />

          <div className='role-input'>
            <FormikDropdownFastField
              name='role'
              label='account.personalInfo.role'
              placeholder='objectives.modal.invite.label.role.placeholder'
              options={rolesCatalog}
              cleanFilter={false}
              isSearchable={false}
              viewMode={false}
              isLoading={false}
              isDisabled={false}
              withComplexShouldUpdate
              maxMenuHeight={80}
              validateOnClick            
            />
          </div>

          <div className='button'>
            <Button
              className='primary'    
              type='submit'        
              disabled={isAddingMembersLoading}      
            >
              {isAddingMembersLoading ? 
                <div className='spinner-button'>
                  <Spinner color='white' size={15} thickness={5} />  
                </div>                
              :
                <FormattedMessage id='objectives.modal.invite.button' />
              }
              
            </Button>
          </div>

                                    
        </Form>
      </Formik>
    </div>
  )
}

export default AddMembersForm;