import { GET_OBJECTIVE_STATUS_CATALOG } from "@graphql/objectives.queries";
import { getGraphqlData } from "@services/graphql.service";
import { useCallback, useMemo, useState } from "react";

export const useGetObjectiveStatus = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([])

    const statusCatalogueBackup = useMemo(() => {
        return [
            {
                value: "1",
                label: "Draft"
            },
            {
                value: "2",
                label: "Open"
            },
            {
                value: "3",
                label: "Closed"
            }
        ]
    }, [])

    const getObjectiveStatus = useCallback(async () => {
        setLoading(true)
        try{
            const objectiveStatus = await getGraphqlData(GET_OBJECTIVE_STATUS_CATALOG);
            setData(objectiveStatus.tgdObjectiveStatusCatalogs)
            setLoading(false)            
        }catch{
            setLoading(false)
            setData(statusCatalogueBackup)
        }
    }, [statusCatalogueBackup])

    return { getObjectiveStatus, data, loading }
}

export default useGetObjectiveStatus;