import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputLabel } from '@material-ui/core';
import { OptionsModel } from '@models/helpful.model';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import debounce from 'lodash/debounce';
import CircleWithName from '../CircleWithName/CircleWithName';
import './styles.scss';
import StyledDropdownOptionTag from '../StyledDropdownOptionTag/StyledDropdownOptionTag';
import { PILLARS_TYPES, PILLAR_TYPES_DESCRIPTION } from '@utils/organization';
import moment from 'moment';
import { ValueType } from '@models/notifications.models';
import CircleWithNameOption from '../CircleWithNameOption/CircleWithNameOption';

export interface StyledDropdownProps {
  cleanFilter: boolean;
  customComponents?: { Option?: any };
  defaultValue?: OptionsModel | OptionsModel[] | null;
  description?: string;
  error?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isSearchable: boolean;
  label?: string;
  options: OptionsModel[];
  placeholder?: string;
  value?: ValueType;
  viewMode: boolean;
  width?: string;
  withCircleName?: boolean;
  withTimeframes?: boolean;
  withEndTag?: boolean;
  maxMenuHeight?: number;
  asyncOptions?: (inputValue: string, callback: any) => void;
  getOptions?: () => void;
  onSelectOption: (data: ValueType) => void;
  onBlur?: (event: any) => void;
  name?: string;
  isBorderTransparent?: boolean
}



const instanceOfOptionsModel = (data: any): data is OptionsModel => {
  return 'value' in data;
};

const chooseValue = (
  value: StyledDropdownProps['value'],
  options: StyledDropdownProps['options']
) =>
  typeof value !== 'undefined' && value !== null
    ? typeof value === 'string' || typeof value === 'number'
      ? options.find((option) => option.value === value) || null
      : options.filter((option) => (value as Array<React.ReactText>).includes(option.value))
    : null;

const StyledDropdown = (props: StyledDropdownProps) => {
  // Ref: passing a function to useState
  // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [selectedOption, setSelectedOption] = useState<OptionsModel | OptionsModel[] | null>(() =>
    props.defaultValue ? props.defaultValue : chooseValue(props.value, props.options)
  );
  const intl = useIntl();
  const placeholder = useMemo(
    () =>
      props.viewMode
        ? '- - -'
        : props.placeholder
          ? intl.formatMessage({ id: props.placeholder })
          : intl.formatMessage({ id: 'general.placeholder.dropdown' }),
    [props.placeholder, props.viewMode, intl]
  );

  const optionSelected = useCallback(
    (data: OptionsModel | OptionsModel[] | null) => {
      setSelectedOption(data);
      if (data) {
        // Check if it's not an array
        if (instanceOfOptionsModel(data)) {
          props.onSelectOption(data.value); // Send string | number = [8]
          //props.onBlur!(data?.value)
        } else {
          const arrayOfValues = data.map((option) => option.value);
          props.onSelectOption(arrayOfValues); // Send string[] | number [] = [0, 1, 2]
          //props.onBlur!(arrayOfValues)
        }
      } else {
        props.onSelectOption(data);
      }
    },
    // eslint-disable-next-line
    [props.onSelectOption]
  );

  useEffect(() => {
    if (props.cleanFilter === true) {
      setSelectedOption(null);
    }
  }, [props.cleanFilter]);

  useEffect(() => {
    //console.log('props.value', props.value, props.options);
    if (props.options.length) {
      const value = chooseValue(props.value, props.options);
      //console.log('props.value', value);
      setSelectedOption(value);
    }
    // Execute it only when value changes
  }, [props.value, props.options]);

  // console.log("-----------------------------")
  // console.log("Options: ", props.options)
  // console.log("Values: ", selectedOption)
  // console.log("-----------------------------")

  return (
    <div className='styled-dropdown' style={{ width: `${props.width}` }}>
      {props.label !== undefined && (
        <InputLabel htmlFor={`drop-${props.label}`}>
          <FormattedMessage id={props.label} />
        </InputLabel>
      )}
      {props.description !== undefined && props.description !== '' && (
        <p className='description'>
          <FormattedMessage id={props.description} />
        </p>
      )}
      {!props.asyncOptions ? (
        <Select
          className={`type-drop-container${props.error ? ' error' : ''}${props.viewMode ? ' view-mode' : '' 
            }${props.isBorderTransparent ? ' border-transparent' : ''}` }
          classNamePrefix={'type-drop'}
          placeholder={placeholder}
          value={selectedOption}
          onChange={(data: any) => optionSelected(data)}
          options={props.options}
          isClearable={props.isClearable}
          isMulti={props.isMulti}
          onBlur={props.onBlur}
          components={
            props.withTimeframes
              ? {
                SingleValue: (props: any) => (
                  <components.SingleValue {...props}>
                    <div className='timeframe-objective-options'>
                      <span>{props.data.label}</span>

                      <span className='dates-range'>
                        {props.data.date_initial !== undefined &&
                          props.data.date_until !== undefined &&
                          moment(props.data.date_initial).format('ll')}{' '}
                        - {moment(props.data.date_until).format('ll')}
                      </span>
                    </div>
                  </components.SingleValue>
                ),
                Option: (props: any) => (
                  <components.Option {...props}>
                    <div className='timeframe-objective-options'>
                      <span>{props.data.label}</span>

                      <span className='dates-range'>
                        {props.data.date_initial !== undefined &&
                          props.data.date_until !== undefined &&
                          moment(props.data.date_initial).format('ll')}{' '}
                        - {moment(props.data.date_until).format('ll')}
                      </span>
                    </div>
                  </components.Option>
                ),
              }
              : props.withCircleName
                ? {
                  DropdownIndicator: () => <ArrowDropDownIcon />,
                  Option: CircleWithNameOption,
                  SingleValue: (props: any) => (
                    <components.SingleValue {...props}>
                      <CircleWithName name={props.children} size='xs' />
                    </components.SingleValue>
                  ),
                  ...props.customComponents,
                }
                : props.withEndTag
                  ? {
                    DropdownIndicator: () => <ArrowDropDownIcon />,
                    Option: (props: any) => (
                      <components.Option {...props}>
                        {' '}
                        <StyledDropdownOptionTag
                          label={props.data.label}
                          type={
                            props.data.type !== null && props.data.type !== undefined
                              ? PILLAR_TYPES_DESCRIPTION[props.data.type]
                              : PILLAR_TYPES_DESCRIPTION[PILLARS_TYPES.pillar]
                          }
                        />{' '}
                      </components.Option>
                    ),
                  }
                  : {
                    DropdownIndicator: () => <ArrowDropDownIcon />,
                    ...props.customComponents,
                  }
          }
          maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : 120}
          isSearchable={props.isSearchable}
          isDisabled={props.isDisabled || props.viewMode}
          isLoading={props.isLoading}
          name={props.name}
        />
      ) : (
        <AsyncSelect
          className={`type-drop-container${props.error ? ' error' : ''}${props.viewMode ? ' view-mode' : ''
            }`}
          classNamePrefix={'type-drop'}
          placeholder={placeholder}
          value={selectedOption}
          onChange={(data: any) => optionSelected(data)}
          isClearable={props.isClearable}
          isMulti={props.isMulti}
          components={{
            DropdownIndicator: () => <ArrowDropDownIcon />,
            ...props.customComponents,
          }}
          maxMenuHeight={120}
          isSearchable={props.isSearchable}
          isDisabled={props.isDisabled || props.viewMode}
          isLoading={props.isLoading}
          cacheOptions
          defaultOptions={props.options}
          loadOptions={debounce(props.asyncOptions, 600)}
        />
      )}
    </div>
  );
};

export default StyledDropdown;
