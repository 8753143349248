import AcceptedTable from '@components/Invitations/AcceptedTable/AcceptedTable';
import Searchbar from '@components/Shared/Searchbar/Searchbar';
import { Grid } from '@material-ui/core'
import { INVITATIONS_STATUS } from '@models/invitations.model';
import { getInvitationsData } from '@store/Invitations/invitations.action';
import { RootState } from '@store/rootReducer';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

export interface TableData {
  name: string;
  role: string;
  job_title: string | undefined;
  invited_by: string;
  email: string;
}

const AcceptedInvitationsContainer = () => {
  const acceptedInvitationsData = useSelector((state: RootState) => state.Invitations.invitations);
  const loadingInvitations = useSelector((state: RootState) => state.Invitations.isInvitationsLoading);
  const [searchText, setSearchText] = useState<string>('');
  const [error, setError] = useState<any>(undefined);
  const dispatch = useDispatch()

  const onSearchTerm = useCallback((term) => {
    setSearchText(term);
  }, []);

  const formattedData: TableData[] | null = acceptedInvitationsData?.data && acceptedInvitationsData?.data!.map((values: any) => ({
    name: values.user_created?.first_name === undefined ? "- - - - -" : values?.user_created?.first_name! + " " + values?.user_created?.last_name!,
    role: values.role?.role === undefined ? "- - - - -" : values.role.role,
    job_title: values.user_created?.job_title === "" ? "- - - - -" : values.user_created?.job_title,
    invited_by: values.invited_by.first_name !== undefined ? values.invited_by.first_name + " " + values.invited_by.last_name : "",
    email: values.email || "",
    member_since: moment(values.invitation_date).format('MM/DD/YYYY') || ""
  }))

  const data: TableData[] | null = useMemo(() =>
    formattedData ? formattedData?.filter(
      (item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase())
    )
      : [],
    [formattedData, searchText]
  );

  const retryGetAcceptedInvitations = useCallback(() => {
    try {
      dispatch(getInvitationsData(INVITATIONS_STATUS.accepted))
    } catch {
      setError(true)
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getInvitationsData(INVITATIONS_STATUS.accepted))
  }, [dispatch])

  return (
    <div className='requests-container'>
      <Grid className='container-filters'>
        <Searchbar
          intlId='invitations.searchbar.placeholder'
          onSearch={(term) => onSearchTerm(term)}
          className='teams-searchbar'
          valueInput={searchText}
        />
      </Grid>
      <AcceptedTable data={data} error={error} loading={loadingInvitations.isLoading} retryCallback={retryGetAcceptedInvitations} />
    </div>
  )
}

export default AcceptedInvitationsContainer