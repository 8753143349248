import ProgressBar from "@components/Shared/ProgressBar/ProgressBar";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import "./styles.scss";

interface BarProgressRecentlyProps {
  nameOKR?: string;
  dueDate?: string;
  progress?: number;
}
const BarProgressRecently = ({
  nameOKR,
  dueDate,
  progress,
}: BarProgressRecentlyProps) => {
  return (
    <div className="container-bar-progress">
      <div className="okr-name-and-date">
        <div className="okr">
          <span>{nameOKR}</span>
        </div>
        <div className="due-date">
          <span>
            <FormattedMessage id={"dashboard.objectives.recently.due.date"} />
            {moment(dueDate).format("MM/DD/YYYY")}
          </span>
        </div>
      </div>
      <div className="bar-progress">
        <ProgressBar value={progress || 0} thickness={8} />
      </div>
    </div>
  );
};

export default BarProgressRecently;
