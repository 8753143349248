import TegduSkeletonLoading from "@components/Shared/TegduSkeletonLoading/TegduSkeletonLoading"

const SupportContainerLoading = () => {
  return(
		<div style={{display: 'flex', flexDirection: 'row'}}>
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<TegduSkeletonLoading
					type="form"
					width="150px"
				/>
				<TegduSkeletonLoading
					marginTop="16px"
					type="form"
					width="340px"
				/>
				<TegduSkeletonLoading
					marginTop="24px"
					type="form"
					width="311px"
				/>
				<TegduSkeletonLoading
					marginTop="32px"
					type="form"
					width="640px"
				/>
				<TegduSkeletonLoading
					marginTop="24px"
					type="form"
					width="640px"
				/>
				<TegduSkeletonLoading
					marginTop="24px"
					type="form"
					width="640px"
				/>
				<TegduSkeletonLoading
					marginTop="24px"
					type="form"
					width="640px"
				/>
				<TegduSkeletonLoading
					marginTop="24px"
					type="form"
					width="640px"
				/>
				<TegduSkeletonLoading
					marginTop="24px"
					type="form"
					width="640px"
				/>
			</div>
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<div style={{display: 'flex', flexDirection: 'column', marginLeft: '64px', marginRight: '20px'}}>
					<TegduSkeletonLoading
						height="160px"
						marginTop="164px"
						type="chart"
						width="296px"
					/>
				</div>
			</div>
		</div>
	)
}

export default SupportContainerLoading;