import { Dialog } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";
import "./style.scss"

interface ObjectivesModalMarkAsClosedProps {
  isOpen: boolean;
  onClose: () => void;
  container: JSX.Element;
}
const ObjectivesModalMarkAsClosed = (props: ObjectivesModalMarkAsClosedProps) => {
  const { isOpen, onClose, container } = props



  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      scroll="body"
    >
      <div className="mark-as-closed-modal">
        <div className="header">
          <button className="close-btn" onClick={onClose}><CloseIcon /></button>
          <span>
            <FormattedMessage id="mobile.objectives.review.title" />
          </span>
        </div>
      </div>
      {container}
    </Dialog>
  )
}

export default ObjectivesModalMarkAsClosed