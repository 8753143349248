import React, { useMemo } from 'react';
import ProgressBar from '@components/Shared/ProgressBar/ProgressBar';
import moment from 'moment';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE } from '@utils/routes';

import './styles.scss';

interface ObjectiveProgressResumeProps {
  id: number;
  date: string;
  name: string;
  progress: number;
}
function ObjectiveProgressResume({ id, date, name, progress }: ObjectiveProgressResumeProps) {
  const history = useHistory();
  const isOutOfDate = useMemo(() => 
    moment(date) < moment()
  ,[date]);

  return (
    <div className='objective_progress_container' onClick={() => history.push(generatePath(ROUTE.objectivesDetail.path, { id: id }))}>
      <label className={`due_date${isOutOfDate ? ' out_of_date' : ''}`}>
        {`Due date: ${moment(date).format('MM/DD/YYYY')}`}
      </label>
      <h3 className='name'>
        {name}
      </h3>
      {/**TODO: Verify progress */}
      <ProgressBar value={progress ? progress : 0} thickness={8} />
    </div>
  );
}

export default ObjectiveProgressResume;
