import { Button, Dialog } from "@material-ui/core"
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import Error from "@material-ui/icons/Error";
import { OBJECTIVE_STATUS } from "@utils/objectives";
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from "lib/enums/objectives.enum";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

interface CancelCreateObjectivesModalProps {
		action: CREATE_OBJECTIVES_ACTIONS
		objectiveStatus?: OBJECTIVE_STATUS
		objectiveType: OBJECTIVE_TYPES | null  
    isOpen: boolean;
    handleCloseModal: () => void;
		handleCancel : () => void;
		handleSaveAsDraft: () => void
}

const CancelCreateObjectivesModal = (props: CancelCreateObjectivesModalProps) => {
    const { isOpen, objectiveStatus, action, objectiveType } = props;
		const {handleCloseModal, handleCancel, handleSaveAsDraft } = props;
		const methods = useFormContext();
		const watchObjectiveName = methods.watch("name");

		const saveAsDraftAvailable = useMemo(() => {
			if(watchObjectiveName !== "" && objectiveType !== null){
				return true
			} else {
				return false
			}
		}, [watchObjectiveName, objectiveType])
		
    return (
        <Dialog
            open={isOpen}
            className="close-objectives-modal"
            onClose={handleCloseModal}
            disableBackdropClick
						fullWidth
        >
            <div className="cancel-modal-content">
							<div className="icon-container">
								<Error className="icon"/>
							</div>
							<span><FormattedMessage id="objectives.modal.cancel.title"/></span>
							{(action === CREATE_OBJECTIVES_ACTIONS.create ||
								(objectiveStatus && objectiveStatus === OBJECTIVE_STATUS.draft)
								) &&
								<Button 
									className="button"
									onClick={saveAsDraftAvailable ? handleSaveAsDraft : () => null}
									>
										<FormattedMessage id="objectiveform.save.draft" />
									</Button>
							}
							<div onClick={handleCancel}>
								<h5><FormattedMessage id="objectives.modal.cancel.close"/></h5>
							</div>
						</div>
        </Dialog>
    )
}

export default CancelCreateObjectivesModal;