import { getFrequencyGoalsCatalogue } from '@services/catalogs.service';
import { RootState } from '@store/rootReducer';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { OptionsModel } from '@models/helpful.model';
import {
  SetFrequencyGoalsCatalogueBeginAction,
  SetFrequencyGoalsCatalogueSuccessAction,
  SetFrequencyGoalsCatalogueErrorAction,
} from './frequencyGoals.model';

export const SET_FREQUENCYGOALS_CATALOGUE_BEGIN = 'SET_FREQUENCYGOALS_CATALOGUE_BEGIN';
export const SET_FREQUENCYGOALS_CATALOGUE_SUCCESS = 'SET_FREQUENCYGOALS_CATALOGUE_SUCCESS';
export const SET_FREQUENCYGOALS_CATALOGUE_ERROR = 'SET_FREQUENCYGOALS_CATALOGUE_ERROR';

export const setFrequencyGoalsBegin = (): SetFrequencyGoalsCatalogueBeginAction => ({
  type: SET_FREQUENCYGOALS_CATALOGUE_BEGIN,
});

export const setFrequencyGoalsSuccess = (
  data: OptionsModel[]
): SetFrequencyGoalsCatalogueSuccessAction => ({
  type: SET_FREQUENCYGOALS_CATALOGUE_SUCCESS,
  payload: data,
});

export const setFrequencyGoalsError = (error: any): SetFrequencyGoalsCatalogueErrorAction => ({
  type: SET_FREQUENCYGOALS_CATALOGUE_ERROR,
  payload: error,
});

export const getFrequencyGoalsCatalogueData = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch(setFrequencyGoalsBegin());
  return await getFrequencyGoalsCatalogue()
    .then((data) => {
      const options: OptionsModel[] = data.map((option) => ({
        value: option.id.toString(),
        label: option.description,
      }));
      dispatch(setFrequencyGoalsSuccess(options));
    })
    .catch((error) => {
      dispatch(setFrequencyGoalsError(error));
    });
};
