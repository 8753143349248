import { 
  SET_AUTOMATIC_TIMEFRAMES_ID,
  SET_AUTOMATIC_TIMEFRAMES_REQUEST_LOADING, 
  SET_DISABLE_AUTOMATIC_TIMEFRAMES_LOADING, 
  SET_TIMEFRAMES_REQUEST_ERROR, 
  SET_TIMEFRAMES_REQUEST_LOADING, 
  SET_TIMEFRAMES_UPDATE_ERROR, 
  SET_TIMEFRAMES_UPDATE_LOADING 
} from "./timeframes.actions";
import { TimeframesActions, TimeframesStateModel } from "./timeframes.model";

const initialState: TimeframesStateModel = {
  isRequestLoading: true,
  isRequestError: false,
  isUpdateLoading: false,
  isUpdateError: false,
  isAutomaticRequestLoading: false,
  isDeactivateAutomaticLoading: false,
  automaticTimeframesId: null
}

const timeframesReducer = (state: TimeframesStateModel = initialState, actions: TimeframesActions): TimeframesStateModel => {
  switch(actions.type){
    case SET_TIMEFRAMES_REQUEST_LOADING:
      return {
        ...state,
        isRequestLoading: actions.payload
      }
    case SET_TIMEFRAMES_REQUEST_ERROR:
      return {
        ...state,
        isRequestError: actions.payload
      }
    case SET_TIMEFRAMES_UPDATE_LOADING:
      return {
        ...state,
        isUpdateLoading: actions.payload
      }
    case SET_TIMEFRAMES_UPDATE_ERROR:
      return {
        ...state,
        isUpdateError: actions.payload
      }
    case SET_AUTOMATIC_TIMEFRAMES_REQUEST_LOADING:
      return {
        ...state,
        isAutomaticRequestLoading: actions.payload
      }
    case SET_AUTOMATIC_TIMEFRAMES_ID:
      return {
        ...state,
        automaticTimeframesId: actions.payload
      }
    case SET_DISABLE_AUTOMATIC_TIMEFRAMES_LOADING:
      return {
        ...state,
        isDeactivateAutomaticLoading: actions.payload
      }
    default:
      return state
  }
}

export default timeframesReducer;