import CulturalAttributesForm from "@components/Organizations/CulturalAttributes/CulturalAttributesForm/CulturalAttributesForm";
import BackButton from "@components/Shared/BackButton/BackButton";
import { GET_CULTURAL_ATTRIBUTES } from "@graphql/organization.queries";
import { GetCulturalAttributesInfoResponse, GetCulturalAttributesInitialData } from "@models/culturalAttributes.models";
import { graphQlQuery } from "@services/graphql.service";
import { archiveCulturalAttribute } from "@store/Organization/CulturalAttributes/culturalAttributes.actions";
import { RootState } from "@store/rootReducer";
import { translateGetCulturalAttributes } from "@utils/organization";
import useToastNotification from "hooks/useToastNotification";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CulturalAttributesContainerLoading from "../CulturalAttributesContainer/CulturalAttributesContainerLoading";
import './styles.scss';

const ArchiveCulturalAttributesContainer = () => {
  const organizationId = useSelector((state: RootState) => state.User.Info.user.tgd_organization.id);
  const isArchiveLoading = useSelector((state: RootState) => state.Organization.CulturalAttributes.isArchiveLoading);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<GetCulturalAttributesInitialData | null>(null);
  const toastNotification = useToastNotification();
  const dispatch = useDispatch();

  const getArchivedCulturalAttributes = useCallback(async () => {
    setLoading(true)
    try {
      const archivedAttributes = await graphQlQuery<GetCulturalAttributesInfoResponse>(GET_CULTURAL_ATTRIBUTES, {
        organizationId: organizationId,
        archived: true
      })
      setInitialData({
        culturalAttributes: { culturalAttribute: translateGetCulturalAttributes(archivedAttributes.data.tgdPilars) },
        existingData: [],
        members: [],
        tags: []
        
      })
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }, [organizationId])

  useEffect(() => {
    getArchivedCulturalAttributes()
  }, [getArchivedCulturalAttributes])

  const handleSuccessUpdate = useCallback(() => {
    getArchivedCulturalAttributes()
    toastNotification("pillars.success.update", false)
  }, [getArchivedCulturalAttributes, toastNotification])

  const handleUnArchiveAttribute = useCallback((id: string) => {
    dispatch(archiveCulturalAttribute(
      id,
      handleSuccessUpdate,
      () => null,
      false
    ))
  }, [dispatch, handleSuccessUpdate])

  return(
    <>
      {loading === false  &&
        <div className="unarchive-pillars">
          <BackButton id='pillars' labelId='culturalAttributes.archive.back' />
          <h1 className='archive-title'><FormattedMessage id={"culturalAttributes.archived.title"} /></h1>
          <h3 className='archive-description'><FormattedMessage id={"culturalAttributes.archived.description"} /></h3>
          {initialData?.culturalAttributes.culturalAttribute.length ? 
            <CulturalAttributesForm 
              intialData={initialData}
              onSubmitForm={() => null}
              handleArchiveAttribute={handleUnArchiveAttribute}
              isArchiveLoading={isArchiveLoading}
              isShowingArchived
            /> : 
            (<div className='empty-screen'>
              <p id='message'>
                <FormattedMessage
                  id={'culturalAttributes.archived.empty.label'}
                />
              </p>
            </div>
          )}
        </div>
      }

      {loading === true &&
        <CulturalAttributesContainerLoading />
      }
      
    </>
  )
}

export default ArchiveCulturalAttributesContainer;