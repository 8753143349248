
export interface InviteMembersService {
  role: number
  emails: string[]
}

export interface ApiSendInvitationResponse {
  status: string
  existing: string[]
  new: string[]
}

export interface AddMembersEmailsModel {
  label: string
  value: number
}

export interface AddMembersFormModel {
  role: string
  emails: AddMembersEmailsModel[]
}

export interface InvitationBasicInfo {
  first_name: string
  last_name: string
  job_title: string
}

export interface VerifyInvitationInfo {
  id: number
  public_name: string
  subdomain: string
  invitation_uuid: string
  status: number
}

export interface VerifyInvitationResponse {
  email: string
  invitation_uuid: string
  role: number
  subdomain: string
  status: number
  tgd_organization: {
    id: number
    public_name: string
  }
}

export interface PostSignupInfo {
  password: string
  first_name: string
  last_name: string
  job_title: string
  invitation_code: string
}

export enum INVITATIONS_STATUS {
  pending = '1',
  accepted = '2',
  waiting_for_acceptance = '3'
}

export interface InvitationResponse {
  data: InvitationData[]
}

export interface InvitationsInitialValue {
  data: [
    {
      user_created?: any;
      role: Role;
      invited_by: Invitedby;
      email: string;
      invitation_date: string;
      status: number;
    }
  ]
}

export interface InvitationData {
  user_created?: UserCreated;
  role: Role;
  invited_by: Invitedby;
  email: string;
  invitation_date: string;
  status: number;
  id: number
}

interface Invitedby {
  id: number;
  first_name: string;
  last_name: string;
}

interface Role {
  id: number;
  role: string;
}
interface UserCreated {
  first_name: string;
  last_name: string;
  id: number;
  job_title: string;
}