import GenericTable from "@components/Shared/GenericTable/GenericTable";
import { Card } from "@material-ui/core";
import { ReviewsModelI } from "@store/Reviews/reviews.model";
import { ROUTE } from "@utils/routes";
import { useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom";
import ReviewsTableColumns from "../ReviewsTableColumns/ReviewsTableColumns";
import "./styles.scss";
interface ReviewsTablesProps {
  dataTableReview?: ReviewsModelI[] | any;
  isGiven?: boolean;
  loading?: boolean;
}

const ReviewsTables = ({
  dataTableReview,
  isGiven = true,
  loading = false,
}: ReviewsTablesProps) => {
  const history = useHistory()

  const handleRowClick = useCallback((review: ReviewsModelI) => {
    history.push(
      generatePath(ROUTE.objectivesDetail.path, { id: review.tgd_objective.id })
    );
  }, [history]);

  return (
    <div className="container-table-card">
      <Card>
        <GenericTable
          label={"reviews"}
          columns={ReviewsTableColumns(isGiven)}
          data={dataTableReview}
          pagination={true}
          pointerOnHover={true}
          loading={loading}
          error={undefined}
          onRowClicked={handleRowClick}
        />
      </Card>
    </div>
  );
};

export default ReviewsTables;
