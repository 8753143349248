import { SET_HR_TEAM_TAB_STATE, SET_LOADING_SKILLS_APTITUDES, SET_SKILLS_APTITUDES_TAB } from "./hrteam.action";


export enum HRTeamTabState {
    evaluations = 0,
    questionnaires = 1,
    skillsAptitudes = 2,
}

export enum SkillsAptitudesTabState {
    all = 0,
    skills = 1,
    aptitudes = 2,
}

export interface HRTeamState {
    hrTeamActiveTabState: HRTeamTabState,
    skillsAptitudesTabState: SkillsAptitudesTabState,
    isCreateSkillsAptitudesLoading: boolean,
}

export interface GetSkillsAptitudesModelI {
    created_at: string
    description: string
    id: number
    name: string
    type: string
    updated_at: string
}

export interface CreateSkillsAptitudesPost {
    name: string,
    description: string
    type: string //aptitude or skill
}

export interface SetHRTeamActiveTabStateAction {
    type: typeof SET_HR_TEAM_TAB_STATE;
    payload: HRTeamTabState;
}

export interface SetSkillsAptitudesTabStateAction {
    type: typeof SET_SKILLS_APTITUDES_TAB;
    payload: SkillsAptitudesTabState;
}

export interface SetIsCreateSkillsAptitudesLoading {
    type: typeof SET_LOADING_SKILLS_APTITUDES
    payload: boolean
}

export type HRTeamActions =
    SetHRTeamActiveTabStateAction |
    SetSkillsAptitudesTabStateAction |
    SetIsCreateSkillsAptitudesLoading