import React from 'react';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import './style.scss'
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import AddMembersContainer from '@containers/Members/AddMembersContainer/AddMembersContainer';

interface AddMemberModalProps {
  isOpen: boolean
  onClose: () => void
}

const AddMemberModal = (props: AddMemberModalProps) => {
  const {isOpen, onClose} = props;
  const organizationName = useSelector((state: RootState) => state.Organization.Info.data.publicName);

  return(
    <Dialog
      open={isOpen}
      className={'add-member-modal'}
      onClose={onClose}
      
    >
      <div className='add-member-modal-content'>
        <div className='close-icon' onClick={onClose}>
          <CloseIcon />
        </div>
        <div className='title'>
          <MailIcon />
          <div className='text'>
            <span><FormattedMessage id="objectives.modal.invite.title" /></span> <br />
            <span>{organizationName}</span>
          </div>
        </div>
        <div className='inputs'>
          <AddMembersContainer 
            onClose={onClose}
          />
        </div>

      </div>
    </Dialog>
  )
}

export default AddMemberModal;