import Spinner from '@components/Shared/Spinner/Spinner';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RootState } from '@store/rootReducer';
import { OBJECTIVE_STATUS } from '@utils/objectives';
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from 'lib/enums/objectives.enum';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import './styles.scss';

interface ObjectivesMainActionsHeaderProps {
  action: CREATE_OBJECTIVES_ACTIONS
  objectiveStatus?: OBJECTIVE_STATUS
  objectiveType: OBJECTIVE_TYPES | null
  isValidForm: boolean
  handleClose: () => void
  handleSaveAsDraft: () => void
}

const ObjectivesMainActionsHeader = (props: ObjectivesMainActionsHeaderProps) => {
  const { action, isValidForm, objectiveStatus, objectiveType, handleSaveAsDraft, handleClose } = props; 
  const methods = useFormContext();
  const watchObjectiveName = methods.watch("name");
  const editObjectiveLoading = useSelector((state: RootState) => state.Objectives.ObjectivesMainActions.isUpdatingObjectiveLoading);
  // true only when the objective is create in the first instance
  const createObjectiveLoading = useSelector((state: RootState) => state.Objectives.ObjectivesMainActions.isCreateObjectiveLoading);
  
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

  useEffect(() => {
    setIsFormEdited(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const saveAsDraftAvailable = useMemo(() => {
    if(watchObjectiveName !== "" && objectiveType !== null){
      return true
    }else {
      return false
    }
  }, [watchObjectiveName, objectiveType])

  return(
    <div className="objectives-main-actions-header">
      <div className='actions-header-content'>
        <div className='basic-info-content'>
          <div className='icon'>
            <CloseIcon onClick={handleClose}/>
          </div>
          <div className='title'>
            <span>
              {(action === CREATE_OBJECTIVES_ACTIONS.create) &&
                <>Add a new objective</>
              }

              {(action === CREATE_OBJECTIVES_ACTIONS.edit) &&
                <>Edit your objective</>
              }

              {(action === CREATE_OBJECTIVES_ACTIONS.replicate) &&
                <>Replicate your objective</>
              }
            </span>
          </div>
        </div>

        <div className='actions-content'>
          {(action === CREATE_OBJECTIVES_ACTIONS.create || 
            action === CREATE_OBJECTIVES_ACTIONS.replicate || 
            (objectiveStatus && objectiveStatus === OBJECTIVE_STATUS.draft)
            ) &&
            <div className='is-draft'>
              <Button 
                className='secondary' 
                disabled={!saveAsDraftAvailable || editObjectiveLoading || !isFormEdited}
                style={{width: "109px"}}
                onClick={saveAsDraftAvailable ? handleSaveAsDraft : () => null}
              >
                {editObjectiveLoading ?
                  <Spinner color='primary' size={15} thickness={7}/>
                  :
                  <>Save as draft</>
                }
              </Button>
            </div>
          }

          <div className='save-or-create'>
            <Button 
              type='submit' 
              form={"create-objectives-main-form"}
              disabled={!isValidForm || createObjectiveLoading || editObjectiveLoading || (objectiveStatus && objectiveStatus === OBJECTIVE_STATUS.active && !isFormEdited)}
              style={{width: "130px"}}
            >
              <>
                {(createObjectiveLoading || (objectiveStatus && objectiveStatus === OBJECTIVE_STATUS.active && editObjectiveLoading)) ?
                  <Spinner color='white' size={15} thickness={7}/>                
                :
                  <>
                    {(action === CREATE_OBJECTIVES_ACTIONS.create) &&
                      <>Create objective</>
                    }

                    {action === CREATE_OBJECTIVES_ACTIONS.edit &&
                      <>
                        {objectiveStatus && objectiveStatus === OBJECTIVE_STATUS.draft ? 
                          <>Create objective</>
                        :
                          <>Save changes</>                
                        }
                      </>
                    }

                    {(action === CREATE_OBJECTIVES_ACTIONS.replicate) &&
                      <>Create objective</>
                    }
                  </>
                }             
              </>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectivesMainActionsHeader;