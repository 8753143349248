import { useQuery } from '@apollo/client';
import Spinner from '@components/Shared/Spinner/Spinner';
import { GET_PILLARS_DASHBOARD, GET_STATEMENTS_DASHBOARD } from '@graphql/dashboard.queries';
import { GqlGetPillarsForDashboard, GqlGetStatementsForDashboard } from '@models/catalogs.model';
import { ApiGetObjective, DashboardActiveObjective } from '@models/objetives.model';
import { getAvargeObjectives, getObjectives } from '@services/objectives.service';
import { RootState } from '@store/rootReducer';
import { MEMBER_ROLE } from '@utils/members';
import { OBJECTIVE_STATUS } from '@utils/objectives';
import BarProgressRecently from 'pwa/components/Dashboard/BarProgressRecently/BarProgressRecently';
import CarouselPoints from 'pwa/components/Dashboard/CarouselPoints/CarouselPoints';
import CardShadow from 'pwa/components/Shared/CardShadow/CardShadow';
import DoubleCircularProgress from 'pwa/components/Shared/DoubleCircularProgress/DoubleCircularProgress';
import StartRatingAverage from 'pwa/components/Shared/StartRatingAverage/StartRatingAverage';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import './styles.scss';

const DashboardContainer = () => {
  const [loadingObjectives, setLoadingObjectives] = useState<boolean>(false);
  const [activeObjectives, setActiveObjectives] = useState<DashboardActiveObjective | null>(null);
  const [, /*errorObjectives*/ setErrorObjectives] = useState<any>(undefined);
  const [lastObjectives, setLastObjectives] = useState<ApiGetObjective[]>([]);
  const [seeAllObjectives, setSeeAllObjectives] = useState<boolean>(false);
  const [getAverage, setGetAverage] = useState<number>(0);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);

  const { data: pillars } = useQuery<GqlGetPillarsForDashboard>(GET_PILLARS_DASHBOARD, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
  });

  const { data: statements } = useQuery<GqlGetStatementsForDashboard>(GET_STATEMENTS_DASHBOARD, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
  });

  const getObjectivesData = useCallback(async () => {
    setLoadingObjectives(true);
    try {
      const asOwner = await getObjectives(MEMBER_ROLE.owner);
      const asCoach = await getObjectives(MEMBER_ROLE.coach);
      const asCollaborator = await getObjectives(MEMBER_ROLE.collaborator);

      const activeAsOwner = asOwner.filter((obj) => obj.status === OBJECTIVE_STATUS.active);
      const activeAsCoach = asCoach.filter((obj) => obj.status === OBJECTIVE_STATUS.active);
      const activeAsCollaborator = asCollaborator.filter(
        (obj) => obj.status === OBJECTIVE_STATUS.active
      );

      let allActiveObjectives = [];
      if (asCollaborator.length) {
        allActiveObjectives = Array.from(
          new Set([...activeAsOwner, ...activeAsCoach, ...activeAsCollaborator])
        );
      } else {
        allActiveObjectives = [...activeAsOwner, ...activeAsCoach];
      }
      allActiveObjectives.sort((a, b) =>
        a.progress > b.progress ? 1 : a.progress < b.progress ? -1 : 0
      );

      setActiveObjectives(() => ({
        owner: {
          totals: activeAsOwner.map((obj) => obj.progress),
        },
        coach: {
          totals: activeAsCoach.map((obj) => obj.progress),
        },
        collaborator: {
          totals: activeAsCollaborator.map((obj) => obj.progress),
        },
      }));
      setLastObjectives(allActiveObjectives);
    } catch (error) {
      setErrorObjectives(error);
    } finally {
      setLoadingObjectives(false);
    }
  }, []);

  const getAverageObjectivesUser = useCallback(async () => {
    try {
      const { average } = await getAvargeObjectives();
      setGetAverage(average);
    } catch (error) {
      setGetAverage(0);
    }
  }, []);

  useEffect(() => {
    getAverageObjectivesUser();
    getObjectivesData();
  }, [getObjectivesData, getAverageObjectivesUser]);
  return (
    <>
      {loadingObjectives ? (
        <div className='container-spinner'>
          <Spinner color='primary' size={50} thickness={3} />
        </div>
      ) : (
        <>
          <div className='container-dash'>
            {/* ACTIVE OBJECTIVES */}
            <CardShadow title='dashboard.objectives.active.mobile'>
              <div className='card-active-objectives'>
                <div className='container-rating'>
                  <DoubleCircularProgress
                    value={activeObjectives?.owner.totals.length || 0}
                    typeOfRole='Coach'
                  />
                  <DoubleCircularProgress
                    value={activeObjectives?.collaborator.totals.length || 0}
                    typeOfRole='Owner'
                  />
                  <DoubleCircularProgress
                    value={activeObjectives?.coach.totals.length || 0}
                    typeOfRole='Collaborator'
                  />
                  <div className='container-start-rating'>
                    <StartRatingAverage avarage={getAverage.toFixed(1)} />
                  </div>
                </div>
              </div>
            </CardShadow>
            {/* Recently updated */}
            <CardShadow title='dashboard.objectives.recently.mobile'>
              <div
                className={
                  seeAllObjectives ? 'card-container-recently-all' : 'card-container-recently'
                }
              >
                {lastObjectives.map((e, index) => (
                  <BarProgressRecently
                    key={index}
                    dueDate={e.due_date}
                    nameOKR={e.name}
                    progress={e.progress}
                  />
                ))}
              </div>
              {/* SEE ALL */}
              {lastObjectives.length >= 4 && (
                <div
                  className={!seeAllObjectives ? 'container-see-all' : 'container-see-all-close'}
                >
                  <span onClick={() => setSeeAllObjectives(!seeAllObjectives)}>
                    {!seeAllObjectives ? (
                      <FormattedMessage id={'dashboard.objectives.recently.see.all'} />
                    ) : (
                      <FormattedMessage id={'dashboard.objectives.recently.see.close'} />
                    )}
                  </span>
                </div>
              )}
            </CardShadow>
            {/* Statements */}
            <CardShadow
              howmanyItems={statements?.tgdStatements.length}
              title='dashboard.objectives.statements'
            >
              <div className='card-carousel'>
                <CarouselPoints pointsItems={statements?.tgdStatements} />
              </div>
            </CardShadow>
            {/* Cultural attributes */}
            <CardShadow
              howmanyItems={pillars?.tgdPilars.length}
              title='mobile.dahsboard.card.title.ca'
            >
              <div className='card-carousel'>
                <CarouselPoints pointsItems={pillars?.tgdPilars} />
              </div>
            </CardShadow>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardContainer;
