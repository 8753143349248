import NewPasswordForm from '@components/Password/NewPasswordForm';
import { recoverPassword } from '@services/login.service';
import { setUserLogin } from '@store/User/Info/info.actions';
import { ROUTE } from '@utils/routes';
import useToastNotification from 'hooks/useToastNotification';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ResetPasswordStructure } from '../ResetPasswordWizard';

import './styles.scss';

interface ResetPasswordContainerProps {
  token: string;
  mode: 'reset' | 'reactivate';
  onCancel?: () => void;
}

const ResetPasswordContainer = ({ token, mode, onCancel }: ResetPasswordContainerProps) => {
  const [validPassword, setvalidPassword] = useState(false);
  const [valuesPassword, setValuesPassword] = useState<ResetPasswordStructure>({});
  const [loading, setLoading] = useState(false);
  const toastNotification = useToastNotification();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleResetPassword = useCallback(async () => {
    if (validPassword && valuesPassword) {
      setLoading(true);
      try {
        const data = await recoverPassword({ ...valuesPassword, code: token });
        if (data) {
          dispatch(setUserLogin(data));
        } else {
          throw new Error('Login Error!');
        }
      } catch (error) {
        toastNotification('resetPassword.toast.error.expiration', true);
        console.error('handleResetPassword', error);
      } finally {
        setLoading(false);
      }
    }
  }, [validPassword, valuesPassword, token, dispatch, toastNotification]);

  return (
    <div className='card-newpassword'>
      <div className='card-newpassword-header'>
        <img src='/images/tegdu.png' alt='logo' onClick={() => history.push(ROUTE.login.path)} />
        <hr />
      </div>
      <div className='card-newpassword-body'>
        <NewPasswordForm
          loading={loading}
          onValues={(values) => setValuesPassword(values)}
          isValidForm={(value) => setvalidPassword(value)}
          onSend={handleResetPassword}
          onCancel={onCancel}
          mode={mode}
        />
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
