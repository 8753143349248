import RoundButton from "@components/Shared/RoundButton/RoundButton"
import { CULTURAL_ATTRIBUTES_TYPES } from "lib/enums/organizations.enums";
import { useCallback } from "react";
import './styles.scss';

interface CuturalAttributesTypeSelectorProps {
  editMode: boolean
  value: CULTURAL_ATTRIBUTES_TYPES | null
  handleCulturalAttributeType: (type: CULTURAL_ATTRIBUTES_TYPES) => void
}

const CulturalAttributesTypeSelector = (props: CuturalAttributesTypeSelectorProps) => {
  const { 
    editMode,
    value,
    handleCulturalAttributeType
  } = props; 

  const getCulturalAttrobuteType = useCallback((type: string) => {
    handleCulturalAttributeType(type as CULTURAL_ATTRIBUTES_TYPES)
  }, [handleCulturalAttributeType])

  return(
    <div className="cuturalAttributes-type-selector">
       <RoundButton
        selected={value === CULTURAL_ATTRIBUTES_TYPES.pillar}
        disabled={!editMode}
        text={'organization.pillar.pillar'}
        onButtonClick={getCulturalAttrobuteType}
        type={CULTURAL_ATTRIBUTES_TYPES.pillar}
        styles="culturalAttributes"
      />

      <RoundButton 
        selected={value === CULTURAL_ATTRIBUTES_TYPES.principle}
        disabled={!editMode}
        onButtonClick={getCulturalAttrobuteType}
        type={CULTURAL_ATTRIBUTES_TYPES.principle}
        text={'organization.pillar.principle'}
        styles="culturalAttributes"
      />

      <RoundButton 
        selected={value === CULTURAL_ATTRIBUTES_TYPES.value}
        disabled={!editMode}
        onButtonClick={getCulturalAttrobuteType}
        type={CULTURAL_ATTRIBUTES_TYPES.value}
        text={'organization.pillar.value'}
        styles="culturalAttributes"
      />
    </div>
  )
}

export default CulturalAttributesTypeSelector;