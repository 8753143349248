import TegduSkeletonLoading from "@components/Shared/TegduSkeletonLoading/TegduSkeletonLoading"
import TegduSkeletonLoadingForm from "@components/Shared/TegduSkeletonLoadingForm/TegduSkeletonLoadingForm";

const CulturalAttributesContainerLoading = () => {
  return(
    <div style={{
      marginTop: "33.5px",
      display: "flex", 
      flexDirection: "column", 
    }}>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <div style={{width: "400px"}}>
          <TegduSkeletonLoading 
            type="form"
            width="100%"
          />
        </div>

        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{marginRight: "10px"}}>
            <TegduSkeletonLoading 
              type="form"
              width="120px"              
            />
          </div>

          <TegduSkeletonLoading 
            type="form"
            width="120px"
          />
        </div>
      </div>

      <div style={{
        marginTop: "20px",
        display: "flex",
        width: "700px",
        flexDirection: "column"
      }}>
        <div style={{display: "flex", width: "100%"}}>
          <TegduSkeletonLoading 
            type="form"
            width="400px"
          />

          <div style={{marginLeft: "10px", width: "100%"}}>
            <TegduSkeletonLoadingForm 
              type="textInput"
            />
          </div>
        </div>

        <div style={{
          marginTop: "20px",
          display: "flex",
          width: "100%",
          flexDirection: "column"
        }}>
          <TegduSkeletonLoading 
            type="row"
            width="100%"
          />

          <TegduSkeletonLoadingForm 
            type="textArea"        
            marginTop="24px"    
          />

        </div>

      </div>
    </div>
  )
}

export default CulturalAttributesContainerLoading;