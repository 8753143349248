import TegduSkeletonLoading from "../TegduSkeletonLoading/TegduSkeletonLoading";

interface TegduSkeletonLoadingFormProps {
  marginTop?: string
  type: 'textInput' | 'textArea'
}

const TegduSkeletonLoadingForm = (props: TegduSkeletonLoadingFormProps) => {
  const {  marginTop, type } = props;
  switch(type){
    case 'textInput':
      return(
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: marginTop}}>
          <TegduSkeletonLoading
            type="row"
            width='150px'
          />
          <TegduSkeletonLoading
            type="form"
            width='100%'
            marginTop="4px"
          />
        </div>
      )
    case 'textArea':
      return(
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: marginTop}}>
          <TegduSkeletonLoading
            type="row"
            width='150px'
          />
          <TegduSkeletonLoading
            type="chart"
            width='100%'
            marginTop="4px"
          />
        </div>
      )
  }
}

export default TegduSkeletonLoadingForm;