import { gql } from '@apollo/client';

export const GET_TAGS = gql`
   query($idOrganization: Int, $isArchived: Boolean){
      tgdTags(where: {tgd_organization:{id: $idOrganization}, is_archived: $isArchived}){
         id,
         name,
         is_archived
         tgd_organization{
            id
         }
         tgd_tag_objectives{
            id
            tgd_objective{
              name
            }
          }
       }
   }
`