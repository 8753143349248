import RecoverPassEmailForm from '@components/Password/RecoverPassEmailForm';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE } from '@utils/routes';
import { sendEmailForgotPassword } from '@services/login.service';
import { useDispatch, useSelector } from 'react-redux';
import { setResetPasswordRequest } from '@store/Shared/shared.action';
import { RootState } from '@store/rootReducer';
import ResetPasswordContainer from './ResetPasswordContainer/ResetPasswordContainer';
import useToastNotification from 'hooks/useToastNotification';

import './styles.scss';

export interface ResetPasswordStructure {
  code?: string;
  password?: string;
  confirmPassword?: string;
}

interface ResetPasswordScreen {
  token?: string;
}

const ResetPasswordWizard = ({ token }: ResetPasswordScreen) => {
  const recoverRequestsTries = useSelector(
    (state: RootState) => state.Shared.login.resetPasswordRequests
  );
  const [onConfirm, setOnConfirm] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const toastNotification = useToastNotification();
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmitEmail = useCallback(
    async (emailUser) => {
      const { email } = emailUser;
      setLoadingSendEmail(true);
      try {
        await sendEmailForgotPassword(email);
        setOnConfirm(true);
        dispatch(setResetPasswordRequest());
      } catch (error) {
        toastNotification('forgotPassword.toast.error', true);
        console.log('onSubmitEmail', error);
      } finally {
        setLoadingSendEmail(false);
      }
    },
    [dispatch, toastNotification]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTE.login.path);
  }, [history]);

  return (
    <>
      {token ? (
        <ResetPasswordContainer token={token} mode='reset' onCancel={onCancel} />
      ) : (
        <div className='resetPassword__container'>
          <div className='logo__container'>
            <img src='/images/tegdu.png' alt='logo' />
          </div>
          <>
            {!onConfirm && recoverRequestsTries < 4 && (
              <div className='formEmail__container'>
                <RecoverPassEmailForm
                  loading={loadingSendEmail}
                  onSubmit={onSubmitEmail}
                  onCancel={onCancel}
                />
              </div>
            )}
            {(onConfirm || recoverRequestsTries >= 3) && (
              <div className='confirm__container'>
                <div className='sucessIcon__container'>
                  <CheckCircleIcon className={'circleIcon '} />
                </div>
                <div className='confirmSendEmail__container'>
                  <h3>
                    <FormattedMessage id={'resetPassword.confirmEmail'} />
                  </h3>
                </div>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default ResetPasswordWizard;
