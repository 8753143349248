import history from '@helpers/history';
import AuthorizedRoute from '@hocs/AuthorizedRoute';
import SimpleRoute from '@hocs/SimpleRoute';
import DataInitializer from '@hubs/DataInitializer';
import { ThemeProvider } from '@material-ui/core/styles';
import AccessScreen from '@screens/Access/AccessScreen';
import Account from '@screens/Account/Account';
import InactiveScreen from '@screens/InactiveScreen';
import Invitations from '@screens/Invitations/Invitations';
import InvitationsOnboarding from '@screens/InvitationsOnboarding/InvitationsOnboarding';
import IndividualMember from '@screens/Members/IndividualMember/IndividualMember';
import MemberScreen from '@screens/Members/MemberScreen/MemberScreen';
import ObjetivesScreen from '@screens/Objetives/ObjectivesScreen/ObjetivesScreen';
import ObjetivesDetailsScreen from '@screens/Objetives/ObjetivesDetailsScreen/ObjetivesDetailsScreen';
import OnboardingScreen from '@screens/Onboarding/OnboardingScreen';
import ArchivePillars from '@screens/Organization/ArchivePillars/ArchivePillars';
import ArchiveStatements from '@screens/Organization/ArchiveStatements/ArchiveStatements';
import Organization from '@screens/Organization/Organization';
import ReviewsScreen from '@screens/Reviews/ReviewsScreen/ReviewsScreen';
import ArchivedTags from '@screens/Settings/ArchivedTags/ArchivedTags';
import Settings from '@screens/Settings/Settings/Settings';
import AddTeamScreen from '@screens/Teams/AddTeamScreen/AddTeamScreen';
import Support from '@screens/Support/Support';
import TeamDetailsScreen from '@screens/Teams/TeamDetailsScreen/TeamDetailsScreen';
import TeamsScreen from '@screens/Teams/TeamsScreen/TeamsScreen';
import { RootState } from '@store/rootReducer';
import { ROUTE } from '@utils/routes';
import { getMessages } from 'i18n/LanguageManager';
import { SnackbarProvider } from 'notistack';
import useSnackbarStyles from 'hooks/useSnackbarStyles';
import { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import Dashboard from './screens/Dashboard/Dashboard';
import Login from './screens/Login/Login';
import ResetPassword from './screens/ResetPassword/ResetPassword';
import getMuiTheme from './theme/material/MuiTheme';
import './theme/styles.scss';

//PWA
import NotificationsListener from '@hubs/NotificationsListener';
import { PALETTE_CLASSNAME_REGEX, PALETTE_DEFAULT_COLOR } from '@utils/theming';
import { useIsMobile } from 'hooks/useIsMobile';
import { DashboardScreenMobile } from 'pwa/screens/Dashboard/Dashboard';
import { ObjectivesScreenMobile } from 'pwa/screens/Objectives/ObjectivesScreen/ObjectivesScreen';
import { ObjectivesScreenDetailsMobile } from 'pwa/screens/Objectives/ObjectivesScreenDetails/ObjectivesScreenDetails';
import { MobileLoginScreen } from './pwa/screens/LoginScreen/LoginScreen';
import { ReviewsScreenMobile } from 'pwa/screens/Reviews/ReviewsScreen';
import ReactivateScreen from '@screens/Reactivate/ReactivateScreen';
import HRTeam from '@screens/HRTeam/HRTeam';
import TegduModals from '@hubs/TegduModals';
import BeginingTour from '@components/Tour/BeginingTour';

function App() {
  const locale = useSelector((state: RootState) => state.Language.Language.locale);
  const color = useSelector((state: RootState) => state.Organization.Info.data.color);
  const classes = useSnackbarStyles();
  const { isMobile } = useIsMobile();

  const muiTheme = useMemo(() => getMuiTheme(color), [color]);

  // Switching palette color
  useEffect(() => {
    const newColor = color && color !== PALETTE_DEFAULT_COLOR ? color : 'default';
    const classList = document.body.classList.value.split(' ');
    const paletteClassName = classList.find((className) => PALETTE_CLASSNAME_REGEX.test(className)) || '';
    if (paletteClassName) {
      document.body.classList.replace(paletteClassName, `palette-${newColor}`);
    } else {
      document.body.classList.add(`palette-${newColor}`);
    }
  }, [color]);

  // useEffect(() => {
  //   navigator.serviceWorker.getRegistrations()
  //     .then(registrationsArray => {
  //       registrationsArray[0].update();
  //     })
  // }, [])

  return (
    <IntlProvider messages={getMessages(locale)} locale={locale} key={locale}>
      <ThemeProvider theme={muiTheme}>
        <DataInitializer />
        <NotificationsListener />
        <TegduModals />
        {/* <LoggedObserverHub /> */}
        <Router history={history}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{ containerRoot: classes.containerRoot }}
            className={isMobile ? `pwa-general-snackbar general-snackbar` : `general-snackbar`}
            hideIconVariant
            preventDuplicate
          >
            {/* Aqui se llama al listener-container */}
            <BeginingTour isMobile={isMobile} />
            <Switch>
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.dashboard.path}
                component={isMobile ? DashboardScreenMobile : Dashboard}
                layout={!isMobile}
                isLayoutMobile={isMobile}
              />
              {/* OBJETIVES */}
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.objetives.path}
                component={isMobile ? ObjectivesScreenMobile : ObjetivesScreen}
                layout={!isMobile}
                isLayoutMobile={isMobile}
              />
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.objectivesDetail.path}
                component={isMobile ? ObjectivesScreenDetailsMobile : ObjetivesDetailsScreen}
                layout={!isMobile}
                isLayoutMobile={isMobile}
                withHeaderMobile={false}
                withBottomMenuMobile={false}
              />
              {/* REVIEWS */}
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.reviews.path}
                component={isMobile ? ReviewsScreenMobile : ReviewsScreen}
                layout={!isMobile}
                isLayoutMobile={isMobile}
                withHeaderMobile={true}
                withBottomMenuMobile={true}
              />
              {/* TEAMS */}
              <AuthorizedRoute authState='accessState' exact path={ROUTE.teams.path} component={TeamsScreen} layout />
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.addTeam.path}
                component={AddTeamScreen}
                layout
              />
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.teamDetails.path}
                component={TeamDetailsScreen}
                layout
              />
              {/* MEMBERS */}
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.members.path}
                component={MemberScreen}
                layout
              />
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.membersDetail.path}
                component={IndividualMember}
                layout
              />

              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.invitations.path}
                component={Invitations}
                layout
              />
              {/*HR TEAM */}
              <AuthorizedRoute authState='accessState' exact path={ROUTE.hr.path} component={HRTeam} layout />
              {/* SETTINGS */}
              <AuthorizedRoute authState='accessState' exact path={ROUTE.settings.path} component={Settings} layout />
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.archiveTags.path}
                component={ArchivedTags}
                layout
              />
              <AuthorizedRoute authState='accessState' exact path={ROUTE.faqs.path} component={Support} layout />
              {/* ORGANIZATION */}
              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.organization.path}
                component={Organization}
                layout
              />

              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.archiveStatements.path}
                component={ArchiveStatements}
                layout
              />

              <AuthorizedRoute
                authState='accessState'
                exact
                path={ROUTE.archivePillars.path}
                component={ArchivePillars}
                layout
              />

              <AuthorizedRoute authState='accessState' exact path={ROUTE.account.path} component={Account} layout />
              <AuthorizedRoute authState='onboarding' exact path={ROUTE.onboarding.path} component={OnboardingScreen} />
              <SimpleRoute
                authState='initial'
                exact
                path={ROUTE.invitationsOnboarding.path}
                component={InvitationsOnboarding}
              />
              <SimpleRoute
                authState='initial'
                exact
                path={ROUTE.login.path}
                component={isMobile ? MobileLoginScreen : Login}
              />
              <SimpleRoute authState='initial' exact path={ROUTE.access.path} component={AccessScreen} />
              <SimpleRoute authState='initial' exact path={ROUTE.recoverPassword.path} component={ResetPassword} />
              <SimpleRoute authState='initial' exact path={ROUTE.reactivate.path} component={ReactivateScreen} />
              <SimpleRoute authState='initial' exact path={`${ROUTE.error}`} component={InactiveScreen} />
            </Switch>
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
