import React, { useCallback, useEffect, useState } from "react";
import { culturalAttributesFormInitialValue, CulturalAttributesFormModel, culturalAttributesFormSchema } from "lib/TegduForms/CulturalAttributesFormUtils";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import OrganizationsFormsHeader from "../../OrganizationsFormsHeader/OrganizationsFormsHeader";
import { GetCulturalAttributesInitialData } from "@models/culturalAttributes.models";
import CulturalAttributesSubForm from "../CulturalAttributesSubForm/CulturalAttributesSubForm";
import './styles.scss'
import { useSelector } from "react-redux";
import { RootState } from "@store/rootReducer";
import { Button } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
import { ROUTE } from "@utils/routes";
import { yupResolver } from "@hookform/resolvers/yup";
interface CulturalAttributesFormProps {
  intialData: GetCulturalAttributesInitialData | null
  isArchiveLoading: boolean
  isShowingArchived?: boolean
  archivedCulturalAttCount?: number
  onSubmitForm: (data: CulturalAttributesFormModel) => void
  handleArchiveAttribute: (id: string) => void
}

const CulturalAttributesForm = (props: CulturalAttributesFormProps) => {
  const { intialData, onSubmitForm, isArchiveLoading, isShowingArchived, archivedCulturalAttCount, handleArchiveAttribute } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const isRequestLoading = useSelector((state: RootState) => state.Organization.CulturalAttributes.isUpdateLoading);
  const history = useHistory();
  
  const methods = useForm<CulturalAttributesFormModel>({
    defaultValues: intialData !== null && intialData.culturalAttributes.culturalAttribute.length ? intialData.culturalAttributes : culturalAttributesFormInitialValue,
    resolver: yupResolver(culturalAttributesFormSchema),
  });

  const { fields, append, remove } = useFieldArray({  
    name: 'culturalAttribute', 
    control: methods.control 
  });


  useEffect(() => {
    if(intialData !== null && intialData.culturalAttributes.culturalAttribute.length === 0){
      setEditMode(true)
    }else {
      setEditMode(false)
    }
  }, [intialData])

  const handelCancelForm =  useCallback(() => {
    methods.reset(
      intialData !== null && intialData.culturalAttributes.culturalAttribute.length ? intialData.culturalAttributes: culturalAttributesFormInitialValue 
    )
    if (intialData && intialData.culturalAttributes.culturalAttribute.length > 0) {
      setEditMode(false)
    }
  }, [intialData, methods])

  const navigateToArchived = useCallback(() => {
    history.push(ROUTE.archivePillars.path)
  }, [history])

  const removeAttribute = useCallback((index: number) => {
    try {
      remove(index)
    } catch (error) {
      console.log("error: ", error)
    }
  }, [remove])

  return(
    <FormProvider {...methods}>
      <form
        className="cultural-attributes-form-component"
        onSubmit={methods.handleSubmit(onSubmitForm)}  
      >
        {(isShowingArchived === undefined) &&
          <OrganizationsFormsHeader
            editMode={editMode}
            isCancelDisabled={isRequestLoading || isArchiveLoading}
            isLoading={isRequestLoading || isArchiveLoading}
            isValidForm={methods.formState.isValid}
            titleId={"culturalAttributes.container.description"}
            editCallback={() => setEditMode(true)}
            cancelCallback={handelCancelForm}
            
          />       
        } 

        {fields.map((field, index) => {
          return (
            <CulturalAttributesSubForm 
              key={index}
              field={field}
              index={index}
              editMode={editMode}
              members={intialData !== null ? intialData.members : []}
              tags={intialData !== null ? intialData.tags : []}
              handleArchiveAttribute={handleArchiveAttribute}
              isArchiveLoading={isArchiveLoading}
              attributeId={
                (intialData !== null && intialData.culturalAttributes.culturalAttribute[index] !== undefined) ? 
                intialData.culturalAttributes.culturalAttribute[index].id.toString() : undefined
              }
              isShowingArchived={isShowingArchived}
              removeAttribute={removeAttribute}
            />
          )
        })}

        {(editMode) && 
          <div className="add-attribute">
            <Button 
              className='outline'
              onClick={() => {
                append(culturalAttributesFormInitialValue.culturalAttribute)
              }}
              disabled={(methods.formState.isValid === false) || ((fields.length) === 9)}
            >
              <Icon>add</Icon>
              <p>
                <FormattedMessage id={'pillars.container.button.add.pillar'} />
              </p>
            </Button>
          </div>
        }

       {archivedCulturalAttCount !== undefined && archivedCulturalAttCount > 0 && 
          <div className="see-archived">
            <span onClick={navigateToArchived}>
              <FormattedMessage 
                id="pillars.archive.pillars"
              />
            </span>          
          </div>
        }
        
      </form>
    </FormProvider>
  )
}

export default CulturalAttributesForm;