import React, { useCallback, useMemo, useState } from 'react';
import TagsTable from '@components/Settings/Tags/TagsTable/TagsTable';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ModalNewTag from '@components/Settings/Tags/ModalNewTag/ModalNewTag';
import ModalEditTag from '@components/Settings/Tags/ModalEditTag/ModalEditTag';
import { useQuery } from '@apollo/client';
import { GET_TAGS } from '@graphql/settings.queries';
import { GraphTagsModel, TagsModel } from '@models/helpful.model';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import './styles.scss';
import { useHistory } from 'react-router';
import { ROUTE } from '@utils/routes';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import { TOUR_SETTINGS_TABLE } from '@samples/tour.settings.sample';
const TagsContainer = () => {
  const isTourOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const actions = useSelector((state: RootState) => state.User.Info.actions.settings);
  const { loading, error, data, refetch } = useQuery<GraphTagsModel>(GET_TAGS, {
    variables: { idOrganization: organizationId, isArchived: false },
    fetchPolicy: 'no-cache',
  });
  const [newTag, setNewTag] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [infoTag, setInfoTag] = useState<TagsModel>({ id: '', name: '', tgd_tag_objectives: [] });
  const history = useHistory();

  const allowAdd = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.ADD)) return true;
    return false;
  }, [actions]);

  const seeArchives = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.SHOW_ARCHIVED)) return true;
    return false;
  }, [actions]);

  const rowClicked = useCallback((data: TagsModel) => {
    setInfoTag(data);
    setEditModal(true);
  }, []);

  return (
    <div className='tags-container'>
      <Grid container className='tags-grid'>
        <Grid item md={9} sm={6} xs={6} className='label'>
          <FormattedMessage id={'settings.tab.tags.sublabel'} />
        </Grid>
        {allowAdd && (
          <Grid item md={3} sm={6} xs={6} className='button'>
            <Button className={'outline new-tag-btn'} onClick={() => setNewTag(true)}>
              <FormattedMessage id={'settings.tab.tags.newtag'} />
            </Button>
          </Grid>
        )}
      </Grid>
      <div className='tags-table'>
          <TagsTable
          data={isTourOpen ? TOUR_SETTINGS_TABLE : data?.tgdTags || []}
            rowClicked={rowClicked}
            loading={loading && !isTourOpen}
            error={error}
            retryCallback={() => refetch()}
          />
      </div>
      {/* TODO: INTEGRAR ARCHIVE TAGS */}
      {seeArchives && (
        <div className='tags-archive-label'>
          <span onClick={() => history.push(ROUTE.archiveTags.path)}>
            <FormattedMessage id='settings.tab.tags.archive' />
          </span>
        </div>
      )}

      <ModalNewTag
        isOpen={newTag}
        onClose={() => setNewTag(false)}
        refetchCallback={() => refetch()}
      />
      <ModalEditTag
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        tagInfo={infoTag}
        refetchCallback={() => refetch()}
      />
    </div>
  );
};

export default TagsContainer;
