import { MEMBER_ROLE, MEMBER_ROLE_LABEL } from '@utils/members';
import LettersCircle from '../LettersCircle/LettersCircle';
import './styles.scss';

interface CircleWithNameProps {
  name: string;
  roleId?: MEMBER_ROLE;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const CircleWithName = (props: CircleWithNameProps) => {
  return (
    <div className={'component-circlewithname'}>
      <LettersCircle name={props.name} size={props.size ? props.size : 'sm'} />
      <div>
        <span className={`name ${props.size ? props.size : 'sm'}`} data-tag='allowRowEvents'>
          {props.name}
        </span>
        {props.roleId ? <p>{MEMBER_ROLE_LABEL[props.roleId]}</p> : <></>}
      </div>
    </div>
  );
};

export default CircleWithName;
