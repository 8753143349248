import { useEffect } from 'react';
import Spinner from '../Spinner/Spinner';

import './styles.scss';

interface SplashScreenProps {
  fixed?: boolean;
}

// IMPORTANT!!! Update rendering-splash-screen in index.html when update this component
// Check index.html to see SplashScreen before react render
const SplashScreen = (props: SplashScreenProps) => {
  const show = true;

  useEffect(() => {
    if (show && document.body.style.overflowY !== 'hidden') {
      document.body.style.overflowY = 'hidden';
    } else {
      if (document.body.style.overflowX !== 'hidden') {
        document.body.style.overflow = '';
      }
    }
  }, [show]);

  return (
    <>
      {(show || props.fixed === true) && (
        <div id='splash-screen' className={`${!props.fixed ? 'animated' : ''}`}>
          <Spinner color='primary' size={50} thickness={4} />
        </div>
      )}
    </>
  );
};

export default SplashScreen;
