import React from 'react';
import './styles.scss';

interface RichTextProps {
  text: string;
  className?: string;
}
const RichText = ({ text, className }: RichTextProps) => {
  // eslint-disable-next-line global-require
  const showdown = require('showdown');
  const converter = new showdown.Converter();
  const html = converter.makeHtml(text);

  return (
    // eslint-disable-next-line react/no-danger
    <div className={!className ? 'richtext-content' : `richtext-content ${className}`} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default RichText;