import { GetSkillsAptitudesModelI } from '@store/HRTeam/HRTeamTabs/hrteam.model'
import moment from 'moment'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import './styles.scss';

const SkillsAptitudesColumns = () => {
	const columns = useMemo(() => {
		const name = {
			id: "name",
			name: (
				<div className="title-container">
					<div className="name-title">
						<FormattedMessage id='hr.skills.aptitudes.skills.table.name'/>
					</div>
					<UnfoldMore className='unfold-icon'/>
				</div>
			),
			cell: (row: GetSkillsAptitudesModelI) => (
				<div className="name-container">
					<div className="expand-button">
					</div>
					<span className='name-cell'>{row?.name}</span>
				</div>
			),
			hide: "sm",
		};
		const type = {
			id: "type",
			name: (
				<div className="title-container">
					<div className="text-title">
						<FormattedMessage id='hr.skills.aptitudes.skills.table.type'/>
					</div>
					<UnfoldMore className='unfold-icon'/>
				</div>
			),
			cell: (row: GetSkillsAptitudesModelI) => (
				<span id=''>{row?.type}</span>
			),
			hide: "sm",
		}
		const date = {
			id: "date",
			name: (
				<div className="title-container">
					<div className="text-title">
						<FormattedMessage id='hr.skills.aptitudes.skills.table.date'/>
					</div>
					<UnfoldMore className='unfold-icon'/>
				</div>
			),
			cell: (row: GetSkillsAptitudesModelI) => (
				<span id=''>{moment(row?.created_at).format("DD/MM/YYYY")}</span>
			),
			hide: "sm",
		};
		return [name, type, date];
	}, [])
	return columns
}

export default SkillsAptitudesColumns
