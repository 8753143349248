import { FormikTextDateProps } from "@components/FormikComponents/FormikTextDate/FormikTextDate";
import { FastField } from "formik"
import useFormikShouldUpdate from "hooks/useFormikShouldUpdate";

type FormikTextDatePropsHOC = Omit<FormikTextDateProps, 'meta' | 'field'>

const withDateFastField = (WrappedComponent: (props: FormikTextDateProps) => JSX.Element) => {
   const WithDateFastField = (props: FormikTextDatePropsHOC) => {
      const { viewMode, name } = props
      const shouldUpdate = useFormikShouldUpdate(viewMode)
      return(
         <FastField name={name} shouldUpdate={shouldUpdate}>
            {({field, meta} : any) => (
               <WrappedComponent 
                  {...props}
                  field={field}
                  meta={meta}                  
               />
            )}
         </FastField>
      )
   }
   return WithDateFastField;
}

export default withDateFastField;