import React, { useMemo } from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import FormikStyledError from '../FormikStyledError/FormikStyledError';
import moment from 'moment';
import './styles.scss';
import { checkOutOfDate } from '@utils/objectives';
import { FormikFieldModel } from '@models/formik.model';

export interface FormikTextDateProps {
  defaultValue?: string;
  field: FormikFieldModel
  label?: string;
  maxWidth?: string;
  meta: any;
  min?: string;
  name: string;
  placeholder?: string;
  specialViewMode?: boolean;
  validateOnClick?: boolean;
  viewMode: boolean;
}

const FormikTextDate = (props: FormikTextDateProps) => {
  const { field, maxWidth, meta } = props;
  const dateViewMode = useMemo(() => moment(field.value).format('MM/DD/YYYY'), [field.value]);
  const dateValue = useMemo(() => moment(field.value).format('YYYY-MM-DD'), [field.value]);
  const isPastDate = useMemo(() => (field.value ? checkOutOfDate(field.value) : false), [field.value]);

  //console.log("FORMIK TEXT DATE")
  return (
    <div className={'formik-text-input'} style={{ maxWidth }}>
      {props.label && (
        <InputLabel htmlFor={`input-${props.name}`}>
          <FormattedMessage id={props.label} />
        </InputLabel>
      )}
      {props.viewMode === true && (
        <p className={`view-mode${!!isPastDate ? ' text-outdated' : ''}`}>{dateViewMode}</p>
      )}

      {props.specialViewMode === undefined && props.viewMode === false && (
        <TextField
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={dateValue}
          className='formik-datepicker'
          type='date'
          variant='outlined'
          label=''
          error={
            props.validateOnClick
              ? meta.error && meta.touched
                ? true
                : false
              : meta.error
              ? true
              : false
          }
          inputProps={{
            min: props.min,
          }}
        />
      )}

      {props.specialViewMode === true && (
        <TextField
          type='date'
          disabled={true}
          className={`formik-datepicker special-view`}
          variant='outlined'
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={dateValue}
          required
          error={
            props.validateOnClick
              ? meta.error && meta.touched
                ? true
                : false
              : meta.error
              ? true
              : false
          }
        />
      )}

      <Grid container className={'extra-info'}>
        <Grid item className={'error'} md={12}>
          {props.validateOnClick ? (
            <>{meta.error && meta.touched && <FormikStyledError message={meta.error} />}</>
          ) : (
            <>{meta.error !== undefined && <FormikStyledError message={meta.error} />}</>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormikTextDate;
