import { combineReducers } from 'redux';
import infoReducer from './Info/info.reducer';
import selectedTabReducer from './selectedTab/selectedTab.reducer';
import timeframesReducer from './Timeframes/timeframes.reducer';
import culturalAttributesReducer from './CulturalAttributes/culturalAttributes.reducer';;

export default combineReducers({
  Info: infoReducer,
  selectedTab: selectedTabReducer,
  Timeframes: timeframesReducer,
  CulturalAttributes: culturalAttributesReducer
});
