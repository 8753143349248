import LabelWitchCheckbox from '@components/Shared/LabelWithCheckbox/labelWithCheckbox';
import { useEffect, useState } from 'react';
import './styles.scss';

interface TegduPasswordShapeProps {
  passwordValue: string
  handlePasswordStatus: (valid: boolean) => void
}

const TegduPasswordShape = (props: TegduPasswordShapeProps) => {
  const { passwordValue, handlePasswordStatus } = props;
  const [validateCapital, setValidateCapital] = useState<boolean>(false);
  const [validateNumber, setValidateNumber] = useState<boolean>(false);
  const [validateLength, setValidateLength] = useState<boolean>(false);
  const [validateSpecial, setValidateSpecial] = useState<boolean>(false);

  useEffect(() => {
    const validateCapitalLetter = /[A-Z]/;
    const validateNumber = /\d/;
    // eslint-disable-next-line
    const validateSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (passwordValue !== undefined) {
      if (validateCapitalLetter.test(passwordValue)) {
        setValidateCapital(true);
      } else {
        setValidateCapital(false);
      }
      if (validateNumber.test(passwordValue)) {
        setValidateNumber(true);
      } else {
        setValidateNumber(false);
      }
      if (validateSpecialChars.test(passwordValue)) {
        setValidateSpecial(true);
      } else {
        setValidateSpecial(false);
      }
      if (passwordValue.length >= 8) {
        setValidateLength(true);
      } else {
        setValidateLength(false);
      }
    }
  }, [passwordValue]);

  useEffect(() => {
    if(validateCapital && validateNumber && validateLength && validateSpecial){
      handlePasswordStatus(true)
    }else {
      handlePasswordStatus(false)
    }
  }, [
      validateCapital, 
      validateNumber, 
      validateLength, 
      validateSpecial, 
      handlePasswordStatus
  ])

  return(
    <div className="tegdu-password-shape">
      <LabelWitchCheckbox
        color={'secondary'}
        checked={validateLength}
        label={'resetPassword.passwordRequired.8characters'}
      />
    
      <LabelWitchCheckbox
        color={'secondary'}
        checked={validateSpecial}
        label={'resetPassword.passwordRequired.1specialCharacter'}
      />

      <LabelWitchCheckbox
        color={'secondary'}
        checked={validateCapital}
        label={'resetPassword.passwordRequired.1capitalletter'}
      />
    
      <LabelWitchCheckbox
        color={'secondary'}
        checked={validateNumber}
        label={'resetPassword.passwordRequired.1number'}
      />
    </div>
  )
}

export default TegduPasswordShape;