import './styles.scss'
import React, { useCallback, useState } from 'react'
import PersonalInfoForm from '@components/Account/PersonalInfoForm/PersonalInfoForm'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@apollo/client';
import { GqlMember } from '@models/members.model';
import { GET_MEMBER } from '@graphql/members.queries';
import Spinner from '@components/Shared/Spinner/Spinner';
import { GET_TEAMS_CATALOG } from '@graphql/teams.queries';
import { updatePersonalInfo } from '@services/members.service';
import { setUserInfo } from '@store/User/Info/info.actions';
import { User } from '@models/user.model';
import GenericErrorComponent from '@components/Shared/GenericErrorComponent/GenericErrorComponent';

const PersonalInfoContainer = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const idMember = useSelector((state: RootState) => state.User.Info.user.id);
  const memberRole = useSelector((state: RootState) => state.User.Info.user.role);
  const memberOrganization = useSelector((state: RootState) => state.User.Info.user.tgd_organization);
  const [succesModal, setSuccesModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { data, loading, error, refetch } = useQuery<GqlMember>(GET_MEMBER, {
    variables: { id: idMember },
    fetchPolicy: 'no-cache'
  });

  const {
    data: teams,
    loading: loading_teams,
    error: error_teams,
  } = useQuery(GET_TEAMS_CATALOG, {
    variables: { organizationId },
  });

  const onSubmit = useCallback(async (info: any) => {
    const infoUser: User = { 
      first_name: info.first_name,
      last_name: info.last_name,
      id: idMember,
      role: memberRole,
      tgd_organization: memberOrganization      
    }
    setLoadingSubmit(true)
    try {
      await updatePersonalInfo(idMember, info);
      refetch()
      dispatch(setUserInfo(infoUser));
      setLoadingSubmit(false)
      setSuccesModal(true)      
    } catch {
      setLoadingSubmit(false)
      setErrorModal(false)
    }
  }, [idMember, dispatch, memberOrganization, memberRole, refetch]);




  return (
    <div className='personal-info-container'>
      <div className='form-row'>
        {(data !== undefined && teams !== undefined) &&
          (error === undefined && error_teams === undefined) &&
          (loading === false && loading_teams === false) &&
          <PersonalInfoForm
            data={data.user}
            loadingSubmit={loadingSubmit}
            onSubmitForm={onSubmit}
            teamsCatalogue={teams.tgdTeams}
            succesModal={succesModal}
            errorModal={errorModal}
            closeSuccesModal={() => setSuccesModal(false)}
            closeErrorModal={() => setErrorModal(false)}
          />
        }

        {(loading === true || loading_teams === true) &&
          <div className='screen-loader'> 
            <Spinner
              size={40}
              color='primary'
              thickness={4}
            />
          </div>
        }

        {((error !== undefined) || (error_teams !== undefined)) &&
          <GenericErrorComponent 
            label='generic.error.personalInfo'
          />
        }
      </div>
    </div>
  )
}

export default PersonalInfoContainer;