import { SET_FORGOT_PASSWORD_VERIFY_LOADING } from "./forgotPassword.actions";
import { ForgotPasswordActions, ForgotPasswordModel } from "./forgotPassword.model";

const initialState: ForgotPasswordModel = {
  isVerifyCodeLoading: false,
  isCreatingNewPasswordLoading: false
}

const forgotPasswordReducer = (
  state: ForgotPasswordModel = initialState,
  actions: ForgotPasswordActions
): ForgotPasswordModel => {
  switch(actions.type){
    case SET_FORGOT_PASSWORD_VERIFY_LOADING:
      return {
        ...state,
        isVerifyCodeLoading: actions.payload
      }
    default:
      return state
  }
}

export default forgotPasswordReducer;