import TgdClient from '@helpers/HttpClients/TgdClient';
import { GraphqlQueryResponse } from '@models/global.model';

/**=========================
 * @required TgdClient
 =========================*/
export const getGraphqlData = async (
  query: string,
  variables?: { [key: string]: any }
): Promise<any> => {
  const res = await TgdClient.post(`/graphql`, {
    query,
    variables,
  });
  return res.data;
};

export const graphQlQuery = async <T>(query: string, variables?: { [key: string]: any }): Promise<GraphqlQueryResponse<T>> => {
  return TgdClient.post(`/graphql`, { query, variables })
}