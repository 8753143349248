import SkillsAptitudesButtons from '@components/HRTeam/SkillsAptitudesButtons/SkillsAptitudesButtons';
import SkillsAptitudesModal from '@components/HRTeam/SkillsAptitudesModal/SkillsAptitudesModal';
import SkillsAptitudesTable from '@components/HRTeam/SkillsAptitudesTable/SkillsAptitudesTable';
import { Button, Icon } from '@material-ui/core';
import { getAll, getAptitudes, getSkills } from '@services/hrteam.service';
import { GetSkillsAptitudesModelI } from '@store/HRTeam/HRTeamTabs/hrteam.model';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const SkillsAptitudesContainer = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataTable, setDataTable] = useState<GetSkillsAptitudesModelI | any>([{}]);

  const getAllSkillsAptitudes = useCallback(async() => {
    setLoading(true);
    try{
      const data = await getAll();
      console.log(data);
      setDataTable(data);
      setLoading(false)
    } catch (error) {
      setDataTable(undefined);
      setLoading(false)
    }
  }, []);

  const getAllSkills = useCallback(async() => {
    setLoading(true);
    try{
      const data = await getSkills();
      setDataTable(data);
      setLoading(false)
    } catch (error) {
      setDataTable(undefined);
      setLoading(false)
    }
  }, []);

  const getAllAptitudes = useCallback(async() => {
    setLoading(true);
    try{
      const data = await getAptitudes();
      setDataTable(data);
      setLoading(false)
    } catch (error) {
      setDataTable(undefined);
      setLoading(false)
    }
  }, []);

  const handleCloseSkillsAptitudesModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleOpenSkillsAptitudesModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  return (
    <div className="skills-aptitudes-container">
      <div className="tabs-button-container">
        <SkillsAptitudesButtons 
          getSkillsMethod={getAllSkills} 
          getAllMethod={getAllSkillsAptitudes} 
          getAptitudesMethod={getAllAptitudes}
        />

        <div className="button-container">
          <Button onClick={handleOpenSkillsAptitudesModal}>
            <Icon>add</Icon>
            <FormattedMessage id='hr.skills.aptitudes.button.new'/>
          </Button>
        </div>
      </div>

      {/*TABLE*/}
      <div className="table-container">
        <SkillsAptitudesTable
          dataTable={dataTable}
          loading={isLoading}
          />
      </div>

      {/*MODAL*/}
      <SkillsAptitudesModal 
        isOpen={isModalOpen} 
        handleClose={handleCloseSkillsAptitudesModal}
        getSkillsMethod={getAllSkills} 
        getAllMethod={getAllSkillsAptitudes} 
        getAptitudesMethod={getAllAptitudes}
      />

    </div>
  )
}

export default SkillsAptitudesContainer
