import React, { useCallback, useState } from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import './style.scss';
import { FormattedMessage } from 'react-intl';
import * as XLSX from "xlsx";
import { EmailsFromXLSX } from '@models/members.model';

interface TegduDropableAreaProps {
  acceptedFiles: Accept
  dragDescriptionMessage: string
  maxFiles: number
  handleAcceptedFiles: (files: EmailsFromXLSX [] | null) => void  
}

const TegduDropableArea = (props: TegduDropableAreaProps) => {
  const [withFiles, setWithFiles] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const { handleAcceptedFiles, acceptedFiles, maxFiles, dragDescriptionMessage } = props;

  const preHandleAcceptedFiles = useCallback(async (files: File []) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(files[0]);
    setFileName(files[0].name)

    await new Promise((resolve, reject) => {
      reader.onload = function(e: any) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "buffer" })
        const sheetName = workbook.SheetNames[0];
        const workbookSheet = workbook.Sheets[sheetName];
        const dataJSON = XLSX.utils.sheet_to_json(workbookSheet);
        resolve(dataJSON);
      };
    
      reader.onerror = function() {
        console.log("Error: ", reader.error);
        reject()
      };
    })

    .then((data: any) => {
      handleAcceptedFiles(data)
      setWithFiles(true)
    }).catch(() => {

    })

  }, [handleAcceptedFiles])

  const removeAcceptedFiles = useCallback(() => {
    setWithFiles(false)
    handleAcceptedFiles(null)
  }, [handleAcceptedFiles])

  return(
      <div className="tegdu-dropable-area">
        {withFiles === false ?
          <Dropzone onDrop={preHandleAcceptedFiles} accept={acceptedFiles} maxFiles={maxFiles}>
            {({getRootProps, getInputProps}) => (          
              <div {...getRootProps()} className="dropable-area">
                <input {...getInputProps()} />
                <div className='description'>
                  <div className='icon'>
                    <GetAppIcon />
                  </div>
                  <span>
                    <FormattedMessage id={dragDescriptionMessage}/>
                  </span>
                </div>
                <span id='select-file'>
                  <FormattedMessage id={"objectives.modal.invite.label.selectfile"}/>
                </span>
              </div>          
            )}
          </Dropzone>
        :
          <div className='dropable-area'>
            <div className='dropable-area-content'>
              <div className='filename'>
                <InsertDriveFileIcon />
                <span>{fileName}</span>
              </div>
              <div className='remove'>
                <span onClick={removeAcceptedFiles}>Remove</span>
              </div>
            </div>
          </div>
        }
      </div>
  )
}

export default TegduDropableArea;