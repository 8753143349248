import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from '@store/store';

/*
 * REFERENCE [A]
 * Topic: Merge function
 * URL: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
 */
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const state = store.getState();
  const jwt = state.User.Info.jwt;

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${jwt}`,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: {
            keyArgs: false,
            /* REFERENCE [A] */
            merge(existing: any[] = [], incoming: any[], { args, readField }) {
              const merged = existing ? existing.slice(0) : [];
              /* Step 1.- Obtain a Set of all existing task IDs. */
              const existingIdSet = new Set(merged.map((task) => readField('id', task)));
              /* Step 2.-  Remove incoming tasks already present in the existing data. */
              incoming = incoming.filter((task) => !existingIdSet.has(readField('id', task)));
              /* Step 3 .- Find the index of the task just before the incoming page of tasks. */
              const afterIndex = merged.findIndex((task) => args === readField('id', task));
              if (afterIndex >= 0) {
                /*  If we found afterIndex, insert incoming after that index. */
                merged.splice(afterIndex + 1, 0, ...incoming);
              } else {
                /*  Otherwise insert incoming at the end of the existing data. */
                merged.push(...incoming);
              }
              return merged;
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(httpLink),
});

export default client;
