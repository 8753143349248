import DashboardContainer from '@containers/Dashboard/DashboardContainer';

function Dashboard() {
  return (
    <div id='tourstep-dashboard' className='dashboard__container'>
      <DashboardContainer />
    </div>
  );
}

export default Dashboard;
