import { UpdatePasswordStructure } from '@components/Account/UpdatePasswordForm/UpdatePasswordForm';
import { ResetPasswordStructure } from '@containers/Password/ResetPasswordWizard';
import TgdClient from '@helpers/HttpClients/TgdClient';
import { ApiPostExchangeToken, ApiPostLogin } from '@models/user.model';

export const getDummyLogin = async (): Promise<any> => {
  return {
    jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNjE2MTExNTIzLCJleHAiOjE2MTg3MDM1MjN9.vuwsDVOlSAcbSwe7Z_wCXaC6aj2V5MzJnZUudlQbQTk',
    user: { first_name: 'Alain', last_name: 'Bonilla' },
    organizationId: 5,
  };
};

export const resetPassword = async (pass: UpdatePasswordStructure): Promise<any> => {
  const body = {
    password: pass.oldPassword,
    newPassword: pass.newPassword,
    confirmPassword: pass.confirmPassword,
  };

  return await TgdClient.put(`/custom-utilities/password-reset`, body);
};


export const sendEmailForgotPassword = async (email: string, mode?: boolean): Promise<any> => {
  const body = ( mode !== undefined )?{ email: email, mode: "code" }: {email: email};
  return TgdClient.post(`/auth/forgot-password`, body);
};

export const verifyForgotPasswordCodeService = (email: string, code: string): Promise<{ resetPasswordToken: string }> => {
  return TgdClient.post(`/auth/forgot-password/verify`, { email, code })
}

export const recoverPassword = async ({
  code,
  password,
  confirmPassword,
}: ResetPasswordStructure): Promise<ApiPostLogin> => {
  const body = {
    code: code,
    password: password,
    passwordConfirmation: confirmPassword,
  };

  return TgdClient.post(`/auth/reset-password`, body);
};

export const validateDomain = async () => {
  return await TgdClient.get(`/spot`);
};

export const getAccess = async (): Promise<ApiPostExchangeToken> =>
  await TgdClient.post('/auth/access');

