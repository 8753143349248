import ResetPasswordWizard from '@containers/Password/ResetPasswordWizard';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';

const ResetPassword = () => {
  const { search } = useLocation();
  const [token, setToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (search.indexOf('?code=') !== -1) {
      setToken(search.substring(search.indexOf('=') + 1, search.length));
    } else {
      setToken(undefined);
    }
  }, [search]);

  return (
    <div
      className='resetPasswordScreen__container'
      style={{ backgroundImage: `url('/images/Fondo.svg')` }}
    >
      <ResetPasswordWizard token={token} />
    </div>
  );
};

export default ResetPassword;
