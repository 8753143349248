import React from 'react';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

interface GenericErrorModalProps {
  isOpen: boolean;
  handleClose: () => void;
  intlMessage: { id: string; values?: any };
}

const GenericErrorModal = (props: GenericErrorModalProps) => {
  return (
    <Dialog
      open={props.isOpen}
      className={'generic-error-modal'}
      onClose={props.handleClose}
      disableBackdropClick
    >
      <DialogContent className={'error-modal-content'}>
        <Grid container className={'error-modal-container'}>
          <Grid item md={12} className={'icon'}>
            <div className={'div-icon'}>
              <ErrorIcon />
            </div>
          </Grid>
          <Grid item md={12} className={'message'}>
            <p>
              {props.intlMessage && (
                <FormattedMessage id={props.intlMessage.id} values={props.intlMessage.values} />
              )}
            </p>
          </Grid>
          <Grid item md={12} className='bottom'>
            <hr />
            <div className='footer'>
              <Button className='modal-button' onClick={props.handleClose}>
                <FormattedMessage id={'modal.success.button.label'} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GenericErrorModal;
