import { Checkbox } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

interface LabelWithProps {
  label: string;
  thin?: boolean;
  checked: boolean | undefined;
  checkboxClassName?: string;
  color?: 'primary' | 'secondary' | 'default';
}

const LabelWithCheckbox = (props: LabelWithProps) => {
  return (
    <div className='component-labelwithcheckbox'>
      <Checkbox
        icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />}
        name={`${props.label}-check`}
        disabled
        checked={props.checked ? props.checked : false}
        className={props.checkboxClassName}
        color={props.color ? props.color : 'primary'}
      />
      <p className={`${props.thin ? 'thin' : ''}`}>
        <FormattedMessage id={props.label} />
      </p>
    </div>
  );
};

export default LabelWithCheckbox;
