import FormikDropdown from '@components/FormikComponents/FormikDropdown/FormikDropdown';
import { useQuery } from '@apollo/client';
import { GET_TEAMS_CATALOG } from '@graphql/teams.queries';
import { Button, Grid, Icon } from '@material-ui/core';
import { FormMemberValues, GqlRoles, MembersModel } from '@models/members.model';
import { Form, Formik } from 'formik';
import { MembersInfoSchema } from 'lib/formsValidations/formsValidations';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import StatusComponent from '@components/Shared/StatusComponent/StatusComponent';
import { GET_ROLES } from '@graphql/members.queries';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { transformTeams } from 'lib/parseFunctions/organizationFunctions';
import Spinner from '@components/Shared/Spinner/Spinner';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import { USER_ROLE } from '@utils/user';
import { useEffect } from 'react';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import { setRoleCatalog } from '@utils/members';
import withTextFastField from '@hocs/withTextFastField';
import withDropFastField from '@hocs/withDropFastField';

import './styles.scss';
import TegduCheckbox from '@components/TegduFormComponents/TegduCheckbox/TegduCheckbox';
import withCheckboxFastField from '@hocs/withCheckboxFastField';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikDropdownFastField = withDropFastField(FormikDropdown);
const FormikCheckBoxFastField = withCheckboxFastField(TegduCheckbox);


const statusOptions = [
  { value: 'false', label: <StatusComponent size='table' status={false} /> },
  { value: 'true', label: <StatusComponent size='table' status={true} /> },
];

interface MembersInfoFormProps {
  data: MembersModel;
  editable: boolean;
  loading: boolean;
  onEditRole: (value: boolean) => void;
  onSubmit: (data: FormMemberValues) => void;
}

const MembersInfoForm = (props: MembersInfoFormProps) => {
  const { data, onEditRole } = props;

  const [sameRole, setSameRole] = useState(true);
  const [viewMode, setViewMode] = useState(true);
  const actions = useSelector((state: RootState) => state.User.Info.actions.members);
  const idUser = useSelector((state: RootState) => state.User.Info.user.id);
  const userRole = useSelector((state: RootState) => state.User.Info.user.role.id);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const { data: roles, loading: loading_roles, error: error_roles } = useQuery<GqlRoles>(GET_ROLES);
  const {
    data: teams,
    loading: loading_teams,
    error: error_teams,
  } = useQuery(GET_TEAMS_CATALOG, {
    variables: {
      organizationId,
    },
  });

  const {formatMessage: t} =useIntl();

  const allowEdit = useMemo(() => {
    if (actions?.find((action: any) => action.key_name === ACTIONS_CATALOGS.EDIT)) return true;
    if (actions?.find((action: any) => action.key_name === ACTIONS_CATALOGS.EDIT_OWNER)) {
      if (parseInt(props.data.id) === parseInt(idUser)) {
        return true;
      }
    }
    return false;
  }, [actions, idUser, props.data.id]);

  const allowEditStatus = useMemo(() => {
    if (actions?.find((action: any) => action.key_name === ACTIONS_CATALOGS.EDIT_STATUS)) {
      return true;
    }
    return false;
  }, [actions]);

  const initialValues: FormMemberValues = useMemo(() => {
    return {
      firstName: props.data.first_name,
      lastName: props.data.last_name,
      role: props.data.role.id,
      jobTitle: props.data.job_title || '',
      memberSince: new Date(props.data.created_at).toLocaleDateString(),
      status: props.data.active.toString(),
      teams: transformTeams(props.data.tgd_team_role_users),
      hr: props.data.hr,
    };  
  }, [props]);

  const teamsCatalog = useMemo(() => {
    if (loading_teams === false && error_teams === undefined && teams !== undefined) {
      return teams.tgdTeams;
    } else {
      return [];
    }
  }, [loading_teams, error_teams, teams]);

  const rolesCatalog = useMemo(() => {
    if (loading_roles === false && error_roles === undefined && roles !== undefined) {
      return setRoleCatalog(
        userRole,
        parseInt(data.role?.id !== undefined ? data.role?.id : '-1'),
        roles.roles.filter((role) => role.visible === true)
      );
    } else {
      return [];
    }
  }, [loading_roles, error_roles, roles, data.role?.id, userRole]);

  const onSubmit = useCallback(
    async (info: FormMemberValues) => {
      props.onSubmit(info);
    },
    [props]
  );

  const onEditRoleValue = useMemo(() => {
    switch (userRole) {
      case USER_ROLE.owner:
        if (data.role?.id && data.role.id && parseInt(data.role.id) === USER_ROLE.owner) {
          return false;
        } else {
          return true;
        }
      case USER_ROLE.officer:
        if (
          data.role?.id &&
          data.role.id &&
          (parseInt(data.role.id) === USER_ROLE.officer ||
            parseInt(data.role.id) === USER_ROLE.owner)
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return false;
    }
  }, [userRole, data.role?.id]);
  
  useEffect(() => {
    onEditRole(sameRole);
  }, [onEditRole, sameRole]);

  //TODO: ver si es posible quitar cb de formik del render y mover la logica a un componente que
  // solo se encargue de  resetForm y updateViewMode y consumir los metodos mediante contexto de Formik
  // https://formik.org/docs/api/useFormikContext

  return (
    <div className='members-info-general'>
      <div className='members-form'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={MembersInfoSchema}
          validateOnMount
        >
          {(formik) => {
            let areEqual = false;
            let validForm = formik.isValid;

            if (JSON.stringify(formik.values) === JSON.stringify(initialValues)) {
              areEqual = true;
            } else {
              areEqual = false;
            }

            if (JSON.stringify(formik.values.role) !== JSON.stringify(initialValues.role)) {
              setSameRole(false);
            } else {
              setSameRole(true);
            }

            const resetForm = () => {
              formik.resetForm({ values: initialValues });
              setViewMode(!viewMode);
              // eslint-disable-next-line
              formik.setTouched({ ...formik.touched, ['memberSince']: false });
            };

            const updateViewMode = () => {
              setViewMode(!viewMode);
              // eslint-disable-next-line
              formik.setTouched({ ...formik.touched, ['memberSince']: false });
            };

            return (
              <Form>
                <Grid container className='form-title'>
                  <Grid item md={6} className='title'>
                    <p id='title'>
                      <FormattedMessage id={'members.component.info.form.title'} />
                    </p>
                  </Grid>
                  {viewMode === true && allowEdit && (
                    <Grid item md={6} className={'buttons'}>
                      <div className={'intern-edit'}>
                        <Button className='secondary' onClick={updateViewMode}>
                          <Icon>edit</Icon>
                          <FormattedMessage id='statement.edit' />
                        </Button>
                      </div>
                    </Grid>
                  )}
                  {viewMode === false && (
                    <Grid item md={6} className={'buttons-edit'}>
                      <div className={'edit-btn'} onClick={resetForm}>
                        <Button className={'secondary'} disabled={!formik.isValid || props.loading}>
                          <FormattedMessage id={'statement.cancel'} />
                        </Button>
                      </div>
                      <div className={'save-btn'}>
                        <Button
                          type='submit'
                          disabled={(areEqual === validForm && !props.loading) || props.loading }
                        >
                          {props.loading === true && (
                            <Spinner color='white' size={15} thickness={5} />
                          )}
                          <FormattedMessage id={'statement.save'} />
                        </Button>
                      </div>
                    </Grid>
                  )}
                </Grid>

                <hr />
                {/* GENERAL INFORMATION FORM */}
                <Grid container className={'form-top'} spacing={2}>
                  <Grid item md={4} sm={4} xs={12}>
                    <FormikTextInputFastField
                      name='firstName'
                      labelId='general.form.label.firstName'
                      viewMode={viewMode}
                    />
                  </Grid>

                  <Grid item md={4} sm={4} xs={12}>
                    <FormikTextInputFastField
                      name='lastName'
                      labelId='general.form.label.lastName'
                      viewMode={viewMode}
                    />
                  </Grid>

                  <Grid className='container-input-role' item md={4} sm={4} xs={12}>
                    {!viewMode &&
                    <div className='container-hr-checkbox'>
                      <FormikCheckBoxFastField
                          size="small"
                          name='hr'
                          viewMode={viewMode}
                          labelElement={<span id='hr-check'>{t({id:"members.form.hr"})}</span>}  
                        />
                    </div>}
                    <FormikDropdownFastField
                      name='role'
                      label='general.form.label.role'
                      options={rolesCatalog}
                      cleanFilter={false}
                      isSearchable={false}
                      viewMode={onEditRoleValue ? viewMode : true}
                    />
                  </Grid>
                </Grid>

                <Grid container className={'form-bottom'} spacing={2}>
                  <Grid item md={4} sm={4} xs={12}>
                    <FormikTextInputFastField
                      name='jobTitle'
                      labelId='general.form.label.jobTitle'
                      viewMode={viewMode}
                    />
                  </Grid>

                  <Grid item md={4} sm={4} xs={12}>
                    <FormikTextInputFastField
                      name='memberSince'
                      labelId='general.form.label.memberSince'
                      viewMode={true}
                    />
                  </Grid>

                  <Grid item md={4} sm={4} xs={12}>
                    <FormikDropdownFastField
                      name='status'
                      label='general.form.label.status'
                      options={statusOptions}
                      cleanFilter={false}
                      isSearchable={false}
                      viewMode={allowEditStatus ? viewMode : true}
                    />
                  </Grid>
                </Grid>

                <Grid container className='members-drop'>
                  <Grid item md={12}>
                    <p id='title2'>
                      <FormattedMessage id={'members.component.teams.title'} />
                    </p>
                    <hr id='hr-title' />
                    <FormikDropdownFastField
                      name='teams'
                      options={teamsCatalog}
                      cleanFilter={false}
                      isClearable={false}
                      isMulti={true}
                      isSearchable={false}
                      viewMode={true}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default MembersInfoForm;
