import { combineReducers } from "redux";
import { objectivesDetailsOKRReducer } from "./ObjectivesDetails/ObjectivesDetails.reducer";
import objectivesMainActionsReducer from "./ObjectivesMainActions/objectivesMainActions.reducer";
import { objectivesOpenAddReducer } from "./ObjectivesOpenAdd/ObjectivesDetails/ObjectivesOpenAdd.reducer";
import objectivesSectionReducer from "./ObjectivesSection/objectivesSection.reducer";

export default combineReducers({
  ObjectiveDetails: objectivesDetailsOKRReducer,
  ObjectiveSection: objectivesSectionReducer,
  ObjectivesMainActions: objectivesMainActionsReducer,
  ObjectiveAddShow: objectivesOpenAddReducer
});
