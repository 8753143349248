export const capitalizeString = (stringToCapitalize: string) => {
  return stringToCapitalize.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const floatNumberString = (numberString: string) => {
  if(numberString !== null && numberString !== undefined){
    return numberString.replace(/[^0-9.0-9]+/g, "")
  }else {
    return ""
  }
}