import TgdClient from "@helpers/HttpClients/TgdClient";
import { CreateSkillsAptitudesPost, GetSkillsAptitudesModelI } from "@store/HRTeam/HRTeamTabs/hrteam.model";

export const getAll = async (): Promise<GetSkillsAptitudesModelI[]> =>
    TgdClient.get('/tgd-skill-aptitudes');

export const getSkills = async (): Promise<GetSkillsAptitudesModelI[]> => 
    TgdClient.get('/tgd-skill-aptitudes?type=skill');

export const getAptitudes = async (): Promise<GetSkillsAptitudesModelI[]> => 
    TgdClient.get('/tgd-skill-aptitudes?type=aptitude');

export const createSkillsAptitudes = async (data: CreateSkillsAptitudesPost): Promise <any> =>
    TgdClient.post('tgd-skill-aptitudes', data)