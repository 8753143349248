import FormikTextInput from "@components/FormikComponents/FormikTextInput/FormikTextInput";
import withTextFastField from "@hocs/withTextFastField";
import { Formik, Form } from "formik";
import { LoginSchema } from "@formsValidations/formsValidations";
import { useMemo } from "react";
import "./styles.scss";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Spinner from "@components/Shared/Spinner/Spinner";

interface LoginFormProps {
  actionSubmit: (values: any) => void;
  loading?: boolean;
  onClickForgotPassword: () => void
  onHandleChange?: ()=>void;
}

const FormikTextInputFastField = withTextFastField(FormikTextInput);
const LoginForm = ({ actionSubmit, loading, onHandleChange, onClickForgotPassword }: LoginFormProps) => {

  const initialValues = useMemo(
    () => ({
      email: "",
      password: "",
    }),
    []
  );


  return (
    <div className={"container-login-forms"}>
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values) => actionSubmit(values)}
        validateOnMount
      >
        {({ isValid }) => (
          <Form className="form">
            <div className="inputs-section">
              <FormikTextInputFastField
                name="email"
                cssId="email-input"
                type="email"
                labelId="login.label.email"
                viewMode={false}
                onTouchedInput={onHandleChange}
                validateOnClick
              />
              <FormikTextInputFastField
                name="password"
                cssId="pssw-input"
                type="password"
                labelId="login.label.password"
                viewMode={false}
                onTouchedInput={onHandleChange}
                validateOnClick
              />
            </div>
            <div className="button-forgot-pass">
              <div className="forgot-password" onClick={onClickForgotPassword}>
                <FormattedMessage id="login.label.forgotpw" />
              </div>
              <div className="btn-login">
                {loading ? (
                  <Spinner color="primary" />
                ) : (
                  <Button
                    className="primary footer-btn"
                    type="submit"
                    disabled={!isValid}
                  >
                    <FormattedMessage id="login.button.login" />
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
