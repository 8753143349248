import CircularProgress from "@components/Shared/CircularProgress/CircularProgress";
import { BottomSheet } from "react-spring-bottom-sheet";
import "./styles.scss";

interface ObjectivesBottomSheetDescriptionProps {
  onCloseSheet: () => void;
  openSheet: boolean;
  dataKey: any;
}
const ObjectivesBottomSheetDescription = ({
  onCloseSheet,
  openSheet,
  dataKey,
}: ObjectivesBottomSheetDescriptionProps) => {
  return (
    <BottomSheet
      className="bottom-sheet"
      onDismiss={onCloseSheet}
      open={openSheet}
      snapPoints={({ minHeight }) => [minHeight]}
    >
      <div className="container-info-bottom-descr">
        <div className="header">
          <div className="progress-circular">
            <CircularProgress
              value={dataKey?.current > 1 ? 100 : dataKey?.current}
            />
          </div>
          <div className="title-kr">
            <span>{dataKey.titleKr}</span>
          </div>
        </div>
        <div className="container-description">
          <span>
            {dataKey?.description && dataKey?.description}
          </span>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ObjectivesBottomSheetDescription;
