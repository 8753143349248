import React from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import './styles.scss';

interface CompleteScreenModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  actionsButton?: React.ReactNode;
}
function CompleteScreenModal(props: CompleteScreenModalProps) {
  return (
    <Modal open={props.open}>
      <div className='complete-modal'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='close-content' onClick={props.onClose}>
              <Icon className='icon'>{'close'}</Icon>
              <div className='divider'></div>
            </div>
            <div className='title-content'>
              <h3>{props.title}</h3>
            </div>
            <div className='actions-content'>{props.actionsButton}</div>
          </div>
          <div className='modal-body'>{props.children}</div>
        </div>
      </div>
    </Modal>
  );
}

export default CompleteScreenModal;
