import { TegduDatePickerProps } from "@components/TegduFormComponents/TegduDatePicker/TegduDatePicker";
import { Controller } from "react-hook-form";

interface DatePickerControllerPropsHOC extends Omit<TegduDatePickerProps, 'value' | 'onChange' | 'onBlur'> {
  name: string
  control: any
}

const withDatePickerController = (WrappedComponent: (props: TegduDatePickerProps) => JSX.Element) => {
  const WithDatePickerController = (props: DatePickerControllerPropsHOC) => {
    const { name, control } = props;

    return(
      <Controller 
        control={control}
        name={name}
        render={ ( { field }) => (
          <WrappedComponent 
            {...props}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
          />
        )}
      />
    )
  }
  return WithDatePickerController;
}

export default withDatePickerController;