import { ApiPillarUser, ApiSavePillars } from "@models/pillars.model";
import { NumberTag } from "@models/tags.model";
import { MEMBER_ROLE } from "@utils/members";
import { CulturalAttributesFormObjectModel } from "lib/TegduForms/CulturalAttributesFormUtils";

export const buildTagsToAdd = (tags: string []): NumberTag [] => {
  let newTags: NumberTag [] = [];

  tags.forEach((value) => {
    newTags.push({
      id: parseInt(value)
    })
  })

  return newTags
}

export const buildAddNewCulturalAttributes = (
  formData: CulturalAttributesFormObjectModel [],
  organizationId: number
): ApiSavePillars [] => {
  const newAttributesBody: ApiSavePillars [] = formData.map((attribute) => {
    let tgd_pilar_role_users: ApiPillarUser[] = [];
    if(attribute.owner){
      tgd_pilar_role_users.push({
        tgd_role: MEMBER_ROLE.owner,
        tgd_user: (attribute.owner.value as number).toString(),
      });
    }

    if(attribute.coach.length > 0){
      attribute.coach.forEach(coach => {
        tgd_pilar_role_users.push({
          tgd_role: MEMBER_ROLE.coach,
          tgd_user: (coach.value as number).toString(),
        });
      });
    }

    return {
      tgd_organization: organizationId,
      name: attribute.name,
      description: attribute.description,
      valid_until: attribute.valid_until.toISOString(),
      tgd_tags: buildTagsToAdd(attribute.tgd_pilar_tags.map((tag) => tag.value as string)),
      tgd_pilar_role_users,
      is_archived: false,
      type: attribute.type
    }

  })


  return newAttributesBody
}