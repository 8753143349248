import { AddMembersFormModel, InviteMembersService, AddMembersEmailsModel, VerifyInvitationInfo, PostSignupInfo, InvitationData, ApiSendInvitationResponse } from "@models/invitations.model";
import { deleteInvitationsService, getInvitationsService, inviteMembers, submitRegisterInfo, verifyInvitation } from "@services/invitations.service";
import { setOrganizationColor, setOrganizationId } from "@store/Organization/Info/info.action";
import { setActions, setLocalState, setMenuOptions, setUserInfo, setUserState, storeActionByMenu } from "@store/User/Info/info.actions";
import { batch } from "react-redux";
import { Dispatch } from "redux";
import { InvitationsTabStates } from "./invitations.model";

export const SET_ADD_MEMBERS_LOADING = "SET_ADD_MEMBERS_LOADING";
export const SET_ONBOARDING_INVITATION_INFO_LOADING = "SET_ONBOARDING_INVITATION_INFO_LOADING";
export const SET_ONBOARDING_INVITATION_INFO = "SET_ONBOARDING_INVITATION_INFO";
export const SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING = "SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING";
export const SET_INVITATIONS_TAB_STATE = "SET_INVITATIONS_TAB_STATE"
export const SET_LOADING_INVITATIONS = "SET_LOADING_INVITATIONS"
export const SET_INVITATIONS_INFO = "SET_INVITATIONS_INFO"

export const setAddMembersLoading = (isLoading: boolean) => {
  return {
    type: SET_ADD_MEMBERS_LOADING,
    payload: isLoading
  }
}

export const setRequestOnboardingInvitationInfo = (isLoading: boolean) => {
  return {
    type: SET_ONBOARDING_INVITATION_INFO_LOADING,
    payload: isLoading
  }
}

export const setOnboardingInvitationInfo = (info: VerifyInvitationInfo) => {
  return {
    type: SET_ONBOARDING_INVITATION_INFO,
    payload: info
  }
}

export const setSubmitInvitationLoading = (isLoading: boolean) => {
  return {
    type: SET_ONBOARDING_INVITATION_INFO_SUBMIT_LOADING,
    payload: isLoading
  }
}

export const setInvitationTabState = (autoState: InvitationsTabStates) => {
  return {
    type: SET_INVITATIONS_TAB_STATE,
    payload: autoState,
  };
};

export const setRequestLoadingInvitations = (isLoading: boolean) => {
  return {
    type: SET_LOADING_INVITATIONS,
    payload: isLoading
  }
}

export const setRequestInvitationsInfo = (info: InvitationData[]) => {
  return {
    type: SET_INVITATIONS_INFO,
    payload: info
  }
}

export const addNewMembers = (
  data: AddMembersFormModel,
  handleSuccess: (res: ApiSendInvitationResponse) => void,
  handleError: (error: any) => void
) => async (dispatch: Dispatch) => {
  dispatch(setAddMembersLoading(true))
  try {
    const serviceData: InviteMembersService = {
      role: parseInt(data.role),
      emails: data.emails.map((element: AddMembersEmailsModel) => element.label)
    }
    const res = await inviteMembers(serviceData)
    dispatch(setAddMembersLoading(false))
    handleSuccess(res)
  } catch (error) {
    dispatch(setAddMembersLoading(false))
    handleError(error)
  }
}

export const getOnboardingInvitationInfo = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(setRequestOnboardingInvitationInfo(true))
  try {
    const response = await verifyInvitation(uuid)
    if (response) {
      const data: VerifyInvitationInfo = {
        id: response.tgd_organization.id,
        public_name: response.tgd_organization.public_name,
        subdomain: response.subdomain,
        invitation_uuid: response.invitation_uuid,
        status: response.status
      }
      dispatch(setOnboardingInvitationInfo(data))
    }
    dispatch(setRequestOnboardingInvitationInfo(false))
  } catch {
    dispatch(setRequestOnboardingInvitationInfo(false))
  }
}

export const sendOnboardingInvitationInfo = (
  data: PostSignupInfo,
  handleSuccess: () => void
) => async (dispatch: Dispatch) => {
  dispatch(setSubmitInvitationLoading(true))
  try {
    const response = await submitRegisterInfo(data)
    const actionsObject = storeActionByMenu(response.tgd_menu_role.tgd_menus);
    batch(() => {
      dispatch(setOrganizationColor(response.user.tgd_organization.color));
      dispatch(setSubmitInvitationLoading(false))
      dispatch(setUserState(response.jwt));
      dispatch(setUserInfo(response.user));
      /* Menu*/
      dispatch(setMenuOptions(response.tgd_menu_role.tgd_menus));
      dispatch(setActions(actionsObject));
      dispatch(setOrganizationId(response.user.tgd_organization.id));
      dispatch(setLocalState('accessState'));
    })

    handleSuccess()
  } catch {
    dispatch(setSubmitInvitationLoading(false))
  }
}

export const getInvitationsData = (status: string) => async (dispatch: Dispatch) => {
  dispatch(setRequestLoadingInvitations(true))
  try {
    return await getInvitationsService(status)
      .then((data) => {
        dispatch(setRequestInvitationsInfo(data))
        dispatch(setRequestLoadingInvitations(false))
      })
  } catch (error) {
    console.error(error)
  }
}

export const deleteInvitationByID = (id: string,
  handleSuccess: () => void,
  handleError: () => void) => async () => {
    try {
      await deleteInvitationsService(id)
      handleSuccess()
    } catch (error) {
      handleError()
    }
  }