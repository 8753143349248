import { USER_ROLE } from '@utils/user';
import {
  SET_USER_STATE,
  SET_LOCAL_STATE,
  SET_USER_INFO,
  SET_MENU_OPTIONS,
  SET_ACTIONS,
  SET_USER_TOUR,
} from './info.actions';
import { UserActionTypes, UserState } from './info.model';

const initialState: UserState = {
  jwt: '',
  user: {
    id: '',
    first_name: '',
    last_name: '',
    hr: false,
    tour: true,
    role: {
      id: USER_ROLE.member,
      name: '',
    },
    tgd_organization: {
      id: 0,
      public_name: '',
      legal_name: '',
      company_website: '',
      color: '',
      tgd_company_size_catalog: null,
      tgd_frequency_goals_catalog: null,
      tgd_timezone_catalog: null,
    },
  },
  menuOptions: [
    {
      column_name: '',
      key_name: '',
      actions: [],
    },
  ],
  actions: {},
  localState: 'initial',
};

export default function userReducer(state: UserState = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        jwt: action.payload.jwt,
      };
    case SET_LOCAL_STATE:
      return {
        ...state,
        localState: action.payload.localState,
      };
    case SET_USER_INFO:
      return {
        ...state,
        user: action.payload.user,
      };
    case SET_MENU_OPTIONS:
      return {
        ...state,
        menuOptions: action.payload.menuOptions,
      };
    case SET_ACTIONS:
      return {
        ...state,
        actions: action.payload.actions,
      };
    case SET_USER_TOUR:
      return {
        ...state,
        user: {
          ...state.user,
          tour: action.payload.tour,
        },
      };
    default:
      return state;
  }
}
