import InvitationsOnboardingBasicInfoForm from '@components/Invitations/InvitationsOnboardingBasicInfoForm/InvitationsOnboardingBasicInfoForm';
import InvitationsOnboardingSetPassword from '@components/Invitations/InvitationsOnboardingSetPassword/InvitationsOnboardingSetPassword';
import Spinner from '@components/Shared/Spinner/Spinner';
import { InvitationBasicInfo } from '@models/invitations.model';
import { sendOnboardingInvitationInfo } from '@store/Invitations/invitations.action';
import { RootState } from '@store/rootReducer';
import { ROUTE } from '@utils/routes';
import { INVITATION_CODE_STATUS } from 'lib/enums/invitations.enum';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ExpiredInvitationCodeContainer from '../ExpiredInvitationCodeContainer/ExpiredInvitationCodeContainer';
import './styles.scss';

const InvitationsOnboardingContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(0);
  const [basicInfo, setBasicInfo] = useState<InvitationBasicInfo | null>(null);
  const isValidating = useSelector((state: RootState) => state.Invitations.validatingLandingCode.isLoading);
  const validatedData = useSelector((state: RootState) => state.Invitations.validatingLandingCode.validatedData);

  const onSubmitBasicInfo = useCallback((info: InvitationBasicInfo) => {
    setBasicInfo(info);
    setState(1);
  }, []);

  const returnToBasicInfo = useCallback(() => {
    setState(0);
  }, []);

  const handlesuccessData = useCallback(() => {
    history.push(ROUTE.dashboard.path);
  }, [history]);

  const submitInfo = useCallback(
    (password: string) => {
      if (basicInfo !== null && validatedData && validatedData.invitation_uuid !== undefined) {
        dispatch(
          sendOnboardingInvitationInfo(
            {
              first_name: basicInfo.first_name,
              last_name: basicInfo.last_name,
              job_title: basicInfo.job_title,
              password: password, // TODO: AGUAS! La contraseña permite espacios
              invitation_code: validatedData.invitation_uuid,
            },
            handlesuccessData
          )
        );
      }
    },
    [basicInfo, validatedData, dispatch, handlesuccessData]
  );

  return isValidating ? (
    <div className='loading'>
      <Spinner size={40} thickness={4} color='primary' />
    </div>
  ) : validatedData && validatedData.status === INVITATION_CODE_STATUS.pending ? (
    <div className='invitations-onboarding-container'>
      <div className='content'>
        <div className='logo-tegdu'>
          <img src='/images/tegdu.png' alt='tegdu' />
        </div>

        <span id='company-title'>
          <FormattedMessage id='invitationsOnboarding.signup.title' values={{ company: validatedData.public_name }} />
        </span>

        <span id='domain-title'>
          <FormattedMessage id='invitationsOnboarding.subdomain' values={{ subdomain: validatedData.subdomain }} />
        </span>

        <div className='line'></div>

        {state === 0 && (
          <div className='container-title'>
            <FormattedMessage id='invitationsOnboarding.basicInfoForm.title' />
          </div>
        )}

        <div className='containers-area'>
          {state === 0 ? (
            <InvitationsOnboardingBasicInfoForm onSubmit={onSubmitBasicInfo} defaultValues={basicInfo} />
          ) : (
            <InvitationsOnboardingSetPassword backToBasicInfo={returnToBasicInfo} submitInfo={submitInfo} />
          )}
        </div>
      </div>
    </div>
  ) : (
    <ExpiredInvitationCodeContainer />
  );
};

export default InvitationsOnboardingContainer;
