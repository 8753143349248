import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'

interface NoDataTableProps{
    labelId: string
}

const NoDataTable = (props: NoDataTableProps) => {
    return(
        <div className='no-data-table-general'>
            <FormattedMessage id={`nodatatable.message`} values={{elements: props.labelId}}/>
        </div>
    )
}

export default NoDataTable;