import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import './sytles.scss';

interface LettersCircleProps {
  name: string;
  className?: string;
  // xs: 24 | sm: 28 | md: 36 | lg: 64 | xl: 80
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const getInitials = (name: string) => {
  var names = name.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

function LettersCircle({ name, className, size }: LettersCircleProps) {
  return (
    <Tooltip title={name} placement={'top'}>
      <div
        data-tag='allowRowEvents'
        className={`lcircle__container ${className ? className : ''} ${size}`}
      >
        {getInitials(name)}
      </div>
    </Tooltip>
  );
}

export default LettersCircle;
