import Spinner from "@components/Shared/Spinner/Spinner";
import TegduDatePicker from "@components/TegduFormComponents/TegduDatePicker/TegduDatePicker";
import TegduSelect from "@components/TegduFormComponents/TegduSelect/TegduSelect";
import TegduTextArea from "@components/TegduFormComponents/TegduTextArea/TegduTextArea";
import TegduTextInputComponent from "@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent";
import withDatePickerController from "@hocs/withDatePickerController";
import withSelectController from "@hocs/withSelectController";
import withTextAreaController from "@hocs/withTextAreaController";
import withTextInputController from "@hocs/withTextInputController";
import { Button } from "@material-ui/core";
import { TegduSelectOptionsModel } from "@models/global.model";
import { CULTURAL_ATTRIBUTES_TYPES } from "lib/enums/organizations.enums";
import { CulturalAttributesFormObjectModel } from "lib/TegduForms/CulturalAttributesFormUtils";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import CulturalAttributesTypeSelector from "../CulturalAttributesTypeSelector/CulturalAttributesTypeSelector";
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);
const TegduDatePickerForm = withDatePickerController(TegduDatePicker);
const TegduSelectInputForm = withSelectController(TegduSelect);
const TegduTextAreaInputForm = withTextAreaController(TegduTextArea);

interface CulturalAttributesSubFormProps {
  field: CulturalAttributesFormObjectModel
  index: number
  editMode: boolean
  members: TegduSelectOptionsModel [] 
  tags: TegduSelectOptionsModel [] 
  isArchiveLoading: boolean
  attributeId?: string | number
  isShowingArchived?: boolean
  handleArchiveAttribute: (id: string) => void
  removeAttribute: (index: number) => void
}

const CulturalAttributesSubForm = (props: CulturalAttributesSubFormProps) => {
  const { 
    field, 
    attributeId,
    index, 
    editMode, 
    members, 
    tags, 
    isArchiveLoading, 
    handleArchiveAttribute,
    isShowingArchived,
    removeAttribute
  } = props;
  const [attributeType, setAttributeType] = useState<CULTURAL_ATTRIBUTES_TYPES>(field.type);
  const methods = useFormContext();

  const ownerSelected: TegduSelectOptionsModel = methods.watch(`culturalAttribute.${index}.owner`);
  const coachesSelected: TegduSelectOptionsModel [] = methods.watch(`culturalAttribute.${index}.coach`);

  const coachOptions = useMemo(() => {
    let availableCoaches: TegduSelectOptionsModel [] = [];
    if(ownerSelected !== null){
      members.forEach((element) => {
        if(element.value !== ownerSelected.value){
          availableCoaches.push(element)
        }
      })
      return availableCoaches
    }else {
      return members
    }
  }, [ownerSelected, members])

  const ownerOptions = useMemo(() => {
    let availableOwners: TegduSelectOptionsModel [] = members;
    if(coachesSelected !== null && coachesSelected.length > 0){
      coachesSelected.forEach((coach) => {
        members.forEach((owner, index) => {
          if(owner.value  === coach.value){
            availableOwners.splice(index, 1)
          }
        })
      })

      return availableOwners

    }else {
      return members
    }

  }, [members, coachesSelected])

  const handleAttributeChange =  useCallback((type: CULTURAL_ATTRIBUTES_TYPES) => {
    setAttributeType(type)
    methods.setValue(`culturalAttribute.${index}.type`, type)
  }, [methods, index])

  return(
    <div className="culturalAttributes-form-inputs" key={index}>
      <div className="type-name">
        <div className="attribute-type">
          <span className="label-type">
            <FormattedMessage 
              id="organization.pillar.attribute"
              values={{
                id: index + 1
              }}
            />
          </span> 
          <CulturalAttributesTypeSelector
            value={attributeType}
            handleCulturalAttributeType={handleAttributeChange}
            editMode={editMode}
          />
        </div>

        <div className="attribute-name">
          <TegduTextInputForm
            key={field.id}
            name={`culturalAttribute.${index}.name`}
            control={methods.control}
            isEditMode={editMode}
            labelId={"pillars.form.name"}
            index={index}
          />
        </div>

      </div>

      <div className="valid-until">
        <TegduDatePickerForm
          key={field.id}
          name={`culturalAttribute.${index}.valid_until`}
          control={methods.control}
          editMode={editMode}
          labelId={"pillars.form.validUntil"}
        />
      </div>

      <div className="owner-coach">
        <div className="owner">
          <TegduSelectInputForm 
            placeholderId={!editMode ? "general.form.label.empty" : undefined}
            labelMessageId="pillars.form.owner"        
            name={`culturalAttribute.${index}.owner`}
            control={methods.control}
            options={ownerOptions}
            isSearchable={false}
            isClearable={false}
            isMulti={false}
            disabled={
              !editMode
            }
            viewMode={!editMode}
            
        />
        </div>

        <div className="coach">
          <TegduSelectInputForm 
            placeholderId={!editMode ? "general.form.label.empty" : undefined}
            labelMessageId="pillars.form.coach"        
            name={`culturalAttribute.${index}.coach`}
            control={methods.control}
            options={coachOptions}
            isSearchable={false}
            isClearable={false}
            isMulti={true}
            disabled={
              !editMode
            }
            viewMode={!editMode}
          />
        </div>
      </div>

      <div className="tags">
        <TegduSelectInputForm 
          labelMessageId="pillars.form.tags"    
          placeholderId={!editMode ? "general.form.label.empty" : undefined}
          name={`culturalAttribute.${index}.tgd_pilar_tags`}
          control={methods.control}
          options={tags}
          isSearchable={false}
          isClearable={false}
          isMulti={true}
          disabled={!editMode}
          viewMode={!editMode}
        />
      </div>

      <div className="description">
        <TegduTextAreaInputForm 
          name={`culturalAttribute.${index}.description`}
          labelMessageId="pillars.form.description"
          control={methods.control}
          isEditMode={editMode}
          withMaxLength={true}
          maxLength={200}
        />
      </div>

      {(attributeId !== undefined && attributeId !== -1) &&
        <div className="archive">
          {editMode === false &&
            <Button
              className='secondary archive-button'
              onClick={attributeId !== undefined ? () => handleArchiveAttribute(attributeId !== undefined ? attributeId as string: "-1") : () => null}
              disabled={isArchiveLoading}
            >
              {isArchiveLoading? (
                <Spinner />
              ) : (
                <Icon>archive</Icon>
              )}
              <FormattedMessage id={isShowingArchived === undefined ? 'pillars.archive' : 'pillars.unarchive'} />
            </Button>
          }
        </div>
      }

      {(index !== 0 && attributeId === undefined) &&
        <div className="delete-icon">
          <DeleteIcon onClick={() => removeAttribute(index)} />
        </div>
      }
      
      
    </div>
  )
}

export default CulturalAttributesSubForm;