import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { checkOutOfDate, currentValueWithUnits, OBJECTIVE_LOG_STATUS, OBJECTIVE_STATUS } from
  '@utils/objectives';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Spinner from '@components/Shared/Spinner/Spinner';
import {
  GET_OBJECTIVE_DETAILS,
  GET_OBJECTIVE_TO_VALIDATE_BELONG,
} from '@graphql/objectives.queries';
import { GET_IS_OBJECTIVES_PUBLIC } from '@graphql/organization.queries';
import { Button, Grid, Icon } from '@material-ui/core';
import { ObjectiveGet, ObjectivesLogs, ObjectivesOKRReviewRate } from '@models/objetives.model';
import { getGraphqlData } from '@services/graphql.service';
import {
  getLogs,
  getRatesOKRReviews,
} from '@store/Objectives/ObjectivesDetails/ObjectivesDetails.action';
import { RootState } from '@store/rootReducer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OBJECTIVE_ROLE, validateAbleToSeeObjective } from '@utils/objectives';
import { ROUTE } from '@utils/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';
import useToastNotification from 'hooks/useToastNotification';
import { FormattedMessage } from 'react-intl';
import ObjectivesRateTeammates from '@components/Objetives/ObjectivesRateTeammates/ObjectivesRateTeammates';
import SimpleTag from '@components/Shared/SimpleTag/SimpleTag';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleCircularProgress from 'pwa/components/Shared/DoubleCircularProgress/DoubleCircularProgress';
import moment from 'moment';
import ObjectiveRoleComponent from '@components/Objetives/ObjectiveRoleComponent/ObjectiveRoleComponent';
import CircularProgress from '@components/Shared/CircularProgress/CircularProgress';
import KrDescription from '@components/Objetives/KrDescription/KrDescription';
import ObjectiveLog from '@components/Objetives/ObjectiveLog/ObjectiveLog';
import useCheckScroll from 'hooks/useCheckScroll';
import ButtonTapBottom from 'pwa/components/Shared/ButtonTapBottom/ButtonTapBottom';
import ObjectivesModalEdit from 'pwa/components/Objectives/ObjectivesModalEdit/ObjectivesModalEdit';
import ObjectivesModalAllKeyResults from 'pwa/components/Objectives/ObjectivesModalAllKeyResults/ObjectivesModalAllKeyResults';
import TegduMobileBottomSheet from 'pwa/components/Shared/TegduMobileBottomSheet/TegduMobileBottomSheet';
import ObjectivesActionsBottomContainer from '../ObjectivesActionsBottomContainer/ObjectivesActionsBottomContainer';
import { OBJECTIVES_ACTIONS, OBJECTIVES_STATUS_STRING } from 'lib/enums/objectives.enum';
import ObjectivesCloseActionBottomContainer from '../ObjectivesCloseActionBottomContainer/ObjectivesCloseActionBottomContainer';
import { getObjectives, updateObjectiveStatus } from '@services/objectives.service';
import { sendGeneralScore, sendIndividualScore } from '@services/score.service';
import { ObjectiveScore } from '@models/score.model';
import ObjectivesModalMarkAsClosed from 'pwa/components/Objectives/ObjectivesModalMarkAsClosed/ObjectivesModalMarkAsClosed';
import ObjectivesMarkAsClosedContainer from '../ObjectivesMarkAsClosedContainer/ObjectivesMarkAsClosedContainer';

const ObjectivesContainerDetails = () => {
  const [showCloseObjectiveSection, setShowCloseObjectiveSection] = useState<boolean>(false);
  const [showObjectivesMenuActions, setShowObjectivesMenuActions] = useState<boolean>(false);
  const [data, setData] = useState<ObjectiveGet | null>(null);
  const [requestloading, setRequestLoading] = useState<boolean>(false);
  const [, /*error*/ setError] = useState<boolean>(false);
  const [openModalSeeAllKeyR, setOpenModalSeeAllKeyR] = useState<boolean>(false);
  const [dataOKRReviews, setDataOKRReviews] = useState<ObjectivesOKRReviewRate>();
  const [dataLogs, setDataLogs] = useState<ObjectivesLogs[]>();
  const [expandMoreOKRReviews, setExpandMoreOKRReviews] = useState<boolean>(false);
  const [handleModalEdit, setHandleModalEdit] = useState<boolean>(false);
  const [isMarkAsClosedOpen, setIsMarkAsClosedModal] = useState<boolean>(false)
  const [isMarkAsClosedLoading, setIsMarkAsClosedLoading] = useState<boolean>(false)
  const [isGeneralRateLoading, setIsGeneralRateLoading] = useState<boolean>(false)
  const [isIndividualRateLoading, setIsIndividualRateLoading] = useState<boolean>(false)
  const [isGeneralRateSuccess, setIsGeneralRateSuccess] = useState<boolean>(false)
  const [isIndividualRateSuccess, setIsIndividualRateSuccess] = useState<boolean>(false)

  const objectiveId: { id: string } = useParams();
  const toastNotification = useToastNotification();
  const userId = useSelector((state: RootState) => state.User.Info.user.id);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const { isTopScroll } = useCheckScroll();
  const dispatch = useDispatch();
  const history = useHistory();

  const getDataOKRSuccess = (data: ObjectivesOKRReviewRate) => {
    if (JSON.stringify(data) !== '{}') {
      setDataOKRReviews(data);
    }
  };

  const getDataLogsSuccess = useCallback((data: ObjectivesLogs[]) => {
    setDataLogs(data);
  }, []);

  const getCustomObjectives = useCallback(async () => {
    setRequestLoading(true)
    try {
      await getObjectives()

    } catch {
      toastNotification("objectiveform.error", true);
    }
    setRequestLoading(false)
  }, [toastNotification])

  const dataObjectivesLogs = useCallback(
    (objectiveID: number) => {
      dispatch(getLogs(objectiveID, getDataLogsSuccess));
    },
    [dispatch, getDataLogsSuccess]
  );
  const redirectToMainObjectivesScreen = useCallback(async () => {
    history.push(ROUTE.objetives.path);
  }, [history]);

  useEffect(() => {
    if (!!objectiveId.id) {
      dataObjectivesLogs(parseInt(objectiveId.id));
    }
  }, [dataObjectivesLogs, objectiveId.id]);

  const getNewObjectives = useCallback(() => {
    history.push(ROUTE.objetives.path);
  }, [history]);
  const getIsObjectivesPublic = useCallback(async () => {
    setRequestLoading(true);
    try {
      const response = await getGraphqlData(GET_IS_OBJECTIVES_PUBLIC, {
        organizationId: organizationId,
      });
      if (response.tgdOrganization.response === true) {
        try {
          const response = await getGraphqlData(GET_OBJECTIVE_DETAILS, {
            objectiveId: objectiveId.id,
          });
          setData(response.tgdObjective);
          dispatch(getRatesOKRReviews(parseInt(objectiveId.id), getDataOKRSuccess));
        } catch {
          await redirectToMainObjectivesScreen();
          toastNotification('objectiveform.error', true);
          setError(true);
        } finally {
          setRequestLoading(false);
        }
      } else {
        const response = await getGraphqlData(GET_OBJECTIVE_TO_VALIDATE_BELONG, {
          objectiveId: objectiveId.id,
        });
        const isAbleToSeeInfo = validateAbleToSeeObjective(
          response.tgdObjective,
          userId,
          response.tgdObjective.status.id
        );
        if (isAbleToSeeInfo) {
          try {
            const response = await getGraphqlData(GET_OBJECTIVE_DETAILS, {
              objectiveId: objectiveId.id,
            });
            setData(response.tgdObjective);
            dispatch(getRatesOKRReviews(parseInt(objectiveId.id), getDataOKRSuccess));
          } catch {
            await redirectToMainObjectivesScreen();
            toastNotification('objectiveform.error', true);
            setError(true);
          }
        } else {
          await redirectToMainObjectivesScreen();
          toastNotification('objectiveform.error', true);
          setError(true);
        }
        setRequestLoading(false);
      }
    } catch {
      await redirectToMainObjectivesScreen();
      toastNotification('objectiveform.error', true);
    }
  }, [
    organizationId,
    objectiveId.id,
    dispatch,
    redirectToMainObjectivesScreen,
    toastNotification,
    userId,
  ]);

  const isPastDate = useMemo(
    () => (data && data.due_date ? checkOutOfDate(data.due_date) : false),
    [data]
  );
  const isOwner = useMemo(
    () =>
      data
        ? data.tgd_obj_role_users.some(
          (u: any) =>
            u.user.id.toString() === userId.toString() &&
            u.tgd_role.id.toString() === OBJECTIVE_ROLE.owner.toString()
        )
        : false,
    [data, userId]
  );

  const isCoach = useMemo(
    () =>
      data
        ? data.tgd_obj_role_users.some(
          (u: any) =>
            u.user.id.toString() === userId.toString() &&
            u.tgd_role.id.toString() === OBJECTIVE_ROLE.coach.toString()
        )
        : false,
    [data, userId]
  );

  const objectiveMembers = useMemo(
    () =>
      data
        ? data.tgd_obj_role_users.filter(
          (roleUser, index, self) =>
            index === self.findIndex((selfRoleUser) => selfRoleUser.user.id === roleUser.user.id)
        )
        : [],
    [data]
  );

  // Skip score
  const handleSkipScore = useCallback(() => {
    setIsMarkAsClosedModal(false)
    getCustomObjectives()
  }, [getCustomObjectives]);

  const handleModalAllKeyResults = useCallback(() => {
    setOpenModalSeeAllKeyR(!openModalSeeAllKeyR);
  }, [openModalSeeAllKeyR]);

  useEffect(() => {
    getIsObjectivesPublic();
  }, [getIsObjectivesPublic]);

  const handleCloseObjectiveAction = useCallback(() => {
    setShowCloseObjectiveSection(false);
  }, []);

  const handleOpenModalMarkAsClosed = useCallback(async () => {
    setIsMarkAsClosedLoading(true);
    if (objectiveId) {
      try {
        await updateObjectiveStatus({
          id: objectiveId.id,
          status: OBJECTIVE_STATUS.closed,
        });
        dataObjectivesLogs(Number(objectiveId.id))
        getCustomObjectives()
        setShowCloseObjectiveSection(false)
        setIsMarkAsClosedModal(true)
      } catch {
        setShowCloseObjectiveSection(false)
        toastNotification('objectiveform.error', true);
      }
      finally {
        setIsMarkAsClosedLoading(false)
      }
    }
  }, [dataObjectivesLogs, getCustomObjectives, objectiveId, toastNotification])

  const handleCloseMarkAsClosedModal = useCallback(() => {
    setIsMarkAsClosedModal(false)
  }, [])

  const handleShowMenuActions = useCallback(() => {
    setShowObjectivesMenuActions((value: boolean) => !value);
  }, []);

  const handleMenuActionsSelected = useCallback((keyName: OBJECTIVES_ACTIONS) => {
    switch (keyName) {
      case OBJECTIVES_ACTIONS.close:
        setShowObjectivesMenuActions(false);
        setShowCloseObjectiveSection(true);
        break;
      case OBJECTIVES_ACTIONS.addProgress:
        setShowObjectivesMenuActions(false);
        setHandleModalEdit(true)
        break;

      default:
        setShowObjectivesMenuActions(false);
    }
  }, [])

  // Save general score
  const handleSaveGeneralScore = useCallback(async (comments: string, score: number) => {
    if (objectiveId.id) {
      setIsGeneralRateLoading(true);
      try {
        const body = {
          objective_id: objectiveId.id,
          comment: comments,
          score,
        };
        await sendGeneralScore(body);
        setIsMarkAsClosedModal(false)
        dataObjectivesLogs(Number(objectiveId.id))
        getCustomObjectives()
        setIsGeneralRateLoading(false);
        setIsGeneralRateSuccess(true)
      } catch {
        toastNotification('score.rate.modal.error', true);
      } finally {
        setIsGeneralRateLoading(false);
      }
    }
  }, [dataObjectivesLogs, getCustomObjectives, objectiveId.id, toastNotification]);

  // Save individual score
  const handleSaveIndividualScore = useCallback(async (scores: ObjectiveScore[]) => {
    if (objectiveId.id !== undefined) {
      try {
        setIsIndividualRateLoading(true);
        const body = {
          objective_id: objectiveId.id,
          scores,
        };
        await sendIndividualScore(body);
        dataObjectivesLogs(Number(objectiveId.id))
        getCustomObjectives()
        setIsMarkAsClosedModal(false);
        setIsIndividualRateSuccess(true)
      } catch (error) {
        toastNotification('score.rate.modal.error', true);
      } finally {
        setIsIndividualRateLoading(false);
      }
    }
  },
    [dataObjectivesLogs, getCustomObjectives, objectiveId.id, toastNotification]
  );

  useEffect(() => {
    isMarkAsClosedOpen !== undefined && isMarkAsClosedOpen && requestloading === false && toastNotification('objectiveform.success.closed', false);
  }, [isMarkAsClosedOpen, requestloading, toastNotification])

  useEffect(() => {
    isGeneralRateSuccess !== undefined && isGeneralRateSuccess && requestloading === false && toastNotification('score.rate.modal.success', false);
  }, [getIsObjectivesPublic, isGeneralRateSuccess, requestloading, toastNotification])

  useEffect(() => {
    isGeneralRateSuccess && getIsObjectivesPublic()
  }, [getIsObjectivesPublic, getNewObjectives, isGeneralRateSuccess])

  useEffect(() => {
    isIndividualRateSuccess !== undefined && isIndividualRateSuccess && requestloading === false && toastNotification('score.rate.modal.success', false);
  }, [getIsObjectivesPublic, isIndividualRateSuccess, requestloading, toastNotification])

  useEffect(() => {
    isIndividualRateSuccess && getIsObjectivesPublic()
  }, [getIsObjectivesPublic, getNewObjectives, isIndividualRateSuccess])

  return (
    <div className='container-details-mobile'>
      {requestloading ? (
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      ) : (
        <>
          {/* HEADER */}
          <div
            className={
              isTopScroll
                ? 'back-btn-copy-id-container-mobile'
                : 'back-btn-copy-id-container-mobile-top'
            }
          >
            <div className='icon-arrow'>
              <ArrowBackIcon onClick={() => history.push(ROUTE.objetives.path)} />
            </div>
            {!isTopScroll && (
              <div className='container-title-header'>
                <span>{data?.name}</span>
              </div>
            )}
            {data?.status?.name !== undefined && data?.status?.name === OBJECTIVE_LOG_STATUS.active ?
              (<div className='icon-more' onClick={handleShowMenuActions}>
                <MoreVertIcon />
              </div>) :
              <></>
            }
          </div >
          {/* TITLE WITH PROGRESS */}
          < div className='container-title-progress' >
            <div>
              <DoubleCircularProgress
                value={
                  data?.current_progress
                    ? data?.current_progress * 100
                    : data?.current_progress || 0
                }
                valueToShow={Number.parseFloat(data?.current_progress.toString() || '').toFixed(1)}
                isTable
                sizeCirular={50}
              />
            </div>
            <div className='name-okr'>
              <span>{data?.name}</span>
            </div>
          </div >
          {/* DATE AND COPY ID */}
          < div className='container-date-copy' >
            <div className='date'>
              <span>
                <b>
                  <FormattedMessage id='mobile.objective.detail.dueDate' />
                </b>
                <span className={isPastDate ? `due-date` : ''}>
                  {moment(data?.due_date || '').format('MMMM DD')}
                </span>
              </span>
            </div>
            <div className='copy-btn'>
              <span>{objectiveId.id}</span>
              <FileCopyOutlinedIcon id='icon-copy' />
            </div>
          </div >
          {/* TAGS */}
          {
            !!data?.tgd_tag_objectives.length && (
              <div className='container-tags'>
                {data?.tgd_tag_objectives.map((e) => (
                  <SimpleTag key={e.id} color={'soft'} tag={e.tgd_tag.name || ''} width='72px' />
                ))}
              </div>
            )
          }
          {/* PILARS AND DESCRIPTION */}
          <Grid item container className='container-description-pilar'>
            <div>
              <span>
                <b>
                  <FormattedMessage id={'objectives.main.description'} />
                </b>
                {data?.description ? (
                  data?.description
                ) : (
                  <FormattedMessage id={'objectives.main.description.text'} />
                )}
              </span>
            </div>
            <div>
              {!!data?.tgd_obj_pillars?.length && (
                <span>
                  <b>
                    <FormattedMessage id={'objectives.main.pillar'} />
                  </b>
                  {data?.tgd_obj_pillars.map((e) => e.tgd_pilar.name).join(', ')}
                </span>
              )}
            </div>
          </Grid>
          {/* OKR TEAMMATES REVIEW */}
          {
            (isOwner || isCoach) &&
            dataOKRReviews !== undefined &&
            dataOKRReviews.reviewer &&
            dataOKRReviews.reviewer.user &&
            dataOKRReviews.reviewer.user.first_name && (
              <Grid container className='details-teammate-rates'>
                <Grid container className='details-title-review'>
                  <Grid item md={10} xs={7}>
                    <h1>
                      <FormattedMessage id={'objectives.okr.teammate.title'} />
                    </h1>
                  </Grid>
                  <Grid item md={2} xs={5} justify='flex-end' style={{ display: 'flex' }}>
                    <Button>
                      <FormattedMessage id={'objectives.review.teammate'} />
                    </Button>
                  </Grid>
                </Grid>
                <div className={!expandMoreOKRReviews ? 'container-rates' : 'container-rates-more'}>
                  {dataOKRReviews.Scores.map((e, index) => {
                    return (
                      <ObjectivesRateTeammates
                        key={index}
                        nameTeamMate={e.tgd_obj_role_user.user.first_name}
                        dateRate={e.created_at}
                        reviewer={dataOKRReviews.reviewer.user.first_name}
                        rateValue={e.score}
                      />
                    );
                  })}
                </div>
                {dataOKRReviews.Scores.length >= 5 && (
                  <div
                    onClick={() => setExpandMoreOKRReviews(!expandMoreOKRReviews)}
                    className='see-more'
                  >
                    <span>See more</span>
                    <div>
                      <ExpandMoreIcon />
                    </div>
                  </div>
                )}
              </Grid>
            )
          }
          {/* ROLES */}
          {
            objectiveMembers.length > 0 && (
              <div className='container-roles'>
                <div className='title-role'>
                  <span>
                    <FormattedMessage id={'objectives.section.role.title'} />
                  </span>
                </div>
                <div className='container-roles-content'>
                  {objectiveMembers.map((e, index) => (
                    <ObjectiveRoleComponent
                      role={parseInt(e.tgd_role.id)}
                      isObjectiveView
                      key={index}
                      name={`${e.user.first_name} ${e.user.last_name}`}
                      size={'md'}
                    />
                  ))}
                </div>
              </div>
            )
          }
          {/*TEAM*/}
          {
            !!data?.tgd_objective_teams.length && (
              <div className='container-teams'>
                <div className='title-teams'>
                  <span>
                    <FormattedMessage id={'objectives.section.team.title'} />
                  </span>
                </div>
                <div className='container-tags-teams'>
                  {data?.tgd_objective_teams.map((e, index) => (
                    <SimpleTag key={index} color={'soft'} tag={e.tgd_team.name || ''} />
                  ))}
                </div>
              </div>
            )
          }
          {/* KEY RESULTS */}
          <div className='container-kr'>
            <div className='title-kr'>
              <span>
                <FormattedMessage
                  id={'objectives.kr.detail.title.label'}
                  values={{ length: data?.tgd_key_results.length }}
                />
              </span>
              <div onClick={handleModalAllKeyResults} className={'container-text-see-all'}>
                <span>
                  <FormattedMessage id={'objectives.btn.mobile.see.all'} />
                </span >
              </div >
            </div >
            <div>
              {data?.tgd_key_results.map((kr: any, index: any) => (
                <div key={index + kr} className={'key-detail'}>
                  <div className={'progress-circle'}>
                    <CircularProgress value={kr.current_progress > 1 ? 100 : kr.current_progress} />
                  </div>
                  <div className='kr-details'>
                    <h4>
                      <span>
                        <FormattedMessage
                          id={'objectives.modal.detail.keyresult.id'}
                          values={{ id: index + 1 }}
                        />
                      </span>
                      {kr.name !== '' ? (
                        kr.name
                      ) : (
                        <i>
                          <FormattedMessage id={'objectives.modal.detail.keyresult.notitle'} />
                        </i>
                      )}
                    </h4>
                    <p>
                      <span>Current value: </span>
                      {kr.current_value !== null
                        ? currentValueWithUnits(parseFloat(kr.current_value), parseInt(kr.unit.id))
                        : '0.0'}
                    </p>
                    {kr.description ? <KrDescription description={kr.description} /> : <></>}
                  </div>
                </div>
              ))}
            </div>
          </div >
          {/* LOGS */}
          < div className='container-logs' >
            <div className='title-logs'>
              <span>
                <FormattedMessage id={'objectives.logs.detail.title.label'} />
              </span>
            </div>
            <div className='objective-log-wrapper'>
              {dataLogs?.map((e, index: any) => {
                return (
                  <ObjectiveLog
                    key={index}
                    logType={e.description.name}
                    date={e.created_at}
                    nameUser={e.author.first_name}
                    statusTarget={e.status.id}
                  />
                );
              })}
            </div>
          </div >
          {/* MODAL EDIT */}
          < ObjectivesModalEdit
            nameObjective={data?.name}
            due={data?.due_date}
            progress={data?.current_progress}
            id={parseInt(objectiveId.id)}
            open={handleModalEdit}
            onClose={() => setHandleModalEdit(false)}
            refetchObjectives={getNewObjectives}
          />
          {/* MODAL SEE ALL KEY RESULTS */}
          < ObjectivesModalAllKeyResults
            open={openModalSeeAllKeyR}
            onClose={handleModalAllKeyResults}
            keyResultsData={data?.tgd_key_results}
          />
          {/* BUTTON ADD PROGRESS */}
          {
           ( (isOwner || isCoach) && data?.status?.name === OBJECTIVE_LOG_STATUS.active) &&
              < ButtonTapBottom
                onAction={() => setHandleModalEdit(true)}
                title={'objectives.btn.title.mobile.add.progress'}
                iconLeft={< Icon > update</Icon >}
              />
          }

          {/* OBJECTIVES ACTIONS BOTTOM SHEET */}
          <TegduMobileBottomSheet
            isOpen={showObjectivesMenuActions}
            handleClose={handleShowMenuActions}
            container={
              <ObjectivesActionsBottomContainer
                objectiveStatus={data !== null ? data.status.id : OBJECTIVES_STATUS_STRING.closed}
                handleMenuSelection={handleMenuActionsSelected}
                specialCoach={isCoach}
              />
            }
          />

          {/* OBJECTIVES ACTIONS CLOSE */}
          <TegduMobileBottomSheet
            isOpen={showCloseObjectiveSection}
            handleClose={handleCloseObjectiveAction}
            container={
              <ObjectivesCloseActionBottomContainer
                objectiveName={data !== null ? data.name : '---'}
                teamName={
                  data !== null && data.tgd_objective_teams.length > 0
                    ? data.tgd_objective_teams[0].tgd_team.name
                    : undefined
                }
                cancelAction={handleCloseObjectiveAction}
                closeObjectiveAction={handleOpenModalMarkAsClosed}
                isMarkAsClosedLoading={isMarkAsClosedLoading}
              />
            }
          />
          {/* MODAL MARK AS CLOSED */}
          {
            objectiveId.id !== undefined ? <ObjectivesModalMarkAsClosed
              isOpen={isMarkAsClosedOpen}
              onClose={handleCloseMarkAsClosedModal}
              container={
                <ObjectivesMarkAsClosedContainer
                  objectiveId={objectiveId.id}
                  onSkip={handleSkipScore}
                  onGeneralRate={handleSaveGeneralScore}
                  onIndividualRate={handleSaveIndividualScore}
                  loadingGeneralRate={isGeneralRateLoading}
                  loadingIndividualRate={isIndividualRateLoading}
                  objectiveName={(data !== null) ? data.name : '---'}
                  teamName={(data !== null && data.tgd_objective_teams.length > 0) ? data.tgd_objective_teams[0].tgd_team.name : undefined}
                />
              }
            /> : <></>
          }
        </>
      )}
    </div >
  );
};

export { ObjectivesContainerDetails as ObjectivesContainerDetailsMobile };