import { ApiPostLogin } from "@models/user.model";
import { createNewPassword } from "@store/Login/ForgotPassword/forgotPassword.actions";
import { setUserLogin } from "@store/User/Info/info.actions";
import { ROUTE } from "@utils/routes";
import useToastNotification from "hooks/useToastNotification";
import { CreatePasswordFormModel } from "lib/TegduForms/CreatePasswordFormUtils";
import CreatePasswordForm from "pwa/components/login/CreatePasswordForm/CreatePasswordForm";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import './styles.scss';

interface ForgotPasswordCreatePasswordContainerProps {
  token: string
  handleCloseModal: () => void
}

const ForgotPasswordCreatePasswordContainer = (props: ForgotPasswordCreatePasswordContainerProps) => {
  const { token, handleCloseModal } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const toastNotification = useToastNotification();

  const handleSuccess = useCallback((data: ApiPostLogin) => {
    dispatch(setUserLogin(data))
    history.replace(ROUTE.dashboard.path)
    handleCloseModal()
  }, [history, handleCloseModal, dispatch])

  const handleError = useCallback(() => {
    toastNotification("resetPassword.toast.error.creating", true, true)
  }, [toastNotification])


  const handleSubmit = useCallback((data: CreatePasswordFormModel) => {
    dispatch(createNewPassword(
      {
        code: token,
        password: data.password,
        confirmPassword: data.confirmPassword,
      },
      handleSuccess,
      handleError
    ))
  }, [token, handleSuccess, handleError, dispatch])

  return(
    <div className="forgot-password-create-password-container">
      <div className="title">
        <FormattedMessage id="resetPassword.subtitle.createPassword"/>
      </div>

      <CreatePasswordForm 
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default ForgotPasswordCreatePasswordContainer;