import { OBJECTIVES_ROLE_FILTER } from "lib/enums/objectives.enum";
import {
    SET_OBJECTIVE_REPORT_LOADING,
    SET_OBJECTIVES_SECTION_ROLE_FILTER,
    SET_OBJECTIVES_SECTION_STATUS_FILTER
} from "./objectivesSection.action";
import { ObjectivesSectionActions, ObjectivesSectionModel } from "./objectivesSection.model";

const initalState: ObjectivesSectionModel = {
  okrFilterRoleSelected: OBJECTIVES_ROLE_FILTER.owner,
  statusFilterSelected: null,
  isReportLoading: false
}

const objectivesSectionReducer = (
  state: ObjectivesSectionModel = initalState,
  actions: ObjectivesSectionActions): ObjectivesSectionModel => {

  switch(actions.type){
    case SET_OBJECTIVES_SECTION_ROLE_FILTER:
      return {
        ...state,
        okrFilterRoleSelected: actions.payload
      }
    case SET_OBJECTIVES_SECTION_STATUS_FILTER:
      return {
        ...state,
        statusFilterSelected: actions.payload
      }
      case SET_OBJECTIVE_REPORT_LOADING:
          return {
              ...state,
              isReportLoading: actions.payload
          }
    default:
      return state
  }
}

export default objectivesSectionReducer;
