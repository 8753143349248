import React from 'react'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import './style.scss'

interface GenericErrorComponentProps{
    label: string // i18n label
    retryCallback?: () => void
}

const GenericErrorComponent = (props: GenericErrorComponentProps) => {
    const { retryCallback } = props;

    return(
        <div className='generic-error-component'>
            <p>
                <FormattedMessage 
                    id={retryCallback ? 'generic.error.label.retry' : 'generic.error.label'}
                    values={{
                        table: <FormattedMessage id={props.label}/> 
                    }}
                />
            </p>
            {retryCallback && 
                <Button className='try-btn' onClick={props.retryCallback}>
                    <FormattedMessage id='general.button.try.again' />
                </Button>
            }
        </div>
    )
}

export default GenericErrorComponent;