import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, AppBar, Tab, Tabs } from '@material-ui/core';
import TabPanel from '@components/Shared/TabPanel/TabPanel';
import { FormattedMessage } from 'react-intl';
import RateGeneralReview from '../../Score/RateGeneralReview/RateGeneralReview';
import FormIndividualRate from '@components/Score/FormIndividualRate/FormIndividualRate';
import { getObjectiveUsers } from '@services/objectives.service';
import { ApiGetObjectiveUser } from '@models/objetives.model';
import { ObjectiveScore } from '@models/score.model';

import './styles.scss';

interface ModalRateObjectiveProps {
  isOpen: boolean;
  objectiveId: number | string;
  onSkip: () => void;
  onGeneralRate: (comments: string, score: number) => void;
  onIndividualRate: (scores: ObjectiveScore[]) => void;
  loadingGeneralRate: boolean;
  loadingIndividualRate: boolean;
}

const ModalRateObjective = ({
  isOpen,
  objectiveId,
  onSkip,
  onGeneralRate,
  onIndividualRate,
  loadingGeneralRate,
  loadingIndividualRate,
}: ModalRateObjectiveProps) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [users, setUsers] = useState<ApiGetObjectiveUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const getObjectiveUsersData = useCallback(async () => {
    if (objectiveId) {
      try {
        setLoadingUsers(true);
        const data = await getObjectiveUsers(objectiveId);
        setUsers(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingUsers(false);
      }
    }
  }, [objectiveId]);

  useEffect(() => {
    getObjectiveUsersData();
  }, [getObjectiveUsersData]);

  return (
    <Dialog
      maxWidth='lg'
      open={isOpen}
      className='modal-rateobjective'
      onClose={onSkip}
      disableBackdropClick
    >
      <DialogContent className={'modal-rateobjective-content'}>
        <p className='modal-rateobjective-title'>
          <FormattedMessage id='score.rate.modal.title' />
        </p>
        <p className='modal-rateobjective-subtitle'>
          <FormattedMessage id='score.rate.modal.subtitle' />
        </p>
        <AppBar position='static'>
          <Tabs value={tabValue} onChange={handleTabChange} className='all-tabs' centered>
            <Tab label={<FormattedMessage id={'score.rate.modal.tab.general'} />} id={'general'} />
            <Tab
              label={<FormattedMessage id={'score.rate.modal.tab.individual'} />}
              id={'individual'}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0}>
          <RateGeneralReview
            onCancel={onSkip}
            onSubmit={onGeneralRate}
            loading={loadingGeneralRate}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FormIndividualRate
            onCancel={onSkip}
            onSubmit={onIndividualRate}
            loading={loadingIndividualRate}
            loadingUsers={loadingUsers}
            objectiveUsers={users}
          />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRateObjective;
