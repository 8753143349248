import TegduWarningMessage from "@components/Shared/TegduWarningMessage/TegduWarningMessage";
import TegduSelect from "@components/TegduFormComponents/TegduSelect/TegduSelect";
import TegduTextArea from "@components/TegduFormComponents/TegduTextArea/TegduTextArea";
import TegduTextInputComponent from "@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent";
import withSelectController from "@hocs/withSelectController";
import withTextAreaController from "@hocs/withTextAreaController";
import withTextInputController from "@hocs/withTextInputController";
import { Button, Icon } from "@material-ui/core";
import { TegduSelectOptionsModel } from "@models/global.model";
import { builaInitialValuesToAddKeyResults } from "@utils/objectives/add.objectives.utils";
import { ObjectivesKeyResultsFormModel } from "lib/TegduForms/CreateObjectiveMainFormUtils";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import './styles.scss';

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);
const TegduTextAreaInputForm = withTextAreaController(TegduTextArea);
const TegduSelectInputForm = withSelectController(TegduSelect);

interface ObjectivesKeyResultsFormProps {
  okrUnitsCatalogue: TegduSelectOptionsModel []
}

const ObjectivesKeyResultsForm = (props: ObjectivesKeyResultsFormProps) => {
  const { okrUnitsCatalogue } = props;
  const methods = useFormContext();
  const krUnit: ObjectivesKeyResultsFormModel [] = methods.watch("key_results")
  const { fields, append, remove } = useFieldArray({name: 'key_results', control: methods.control});

  return(
    <div className="objectives-key-results-form">
      {fields.map((field: any, index) => {
        return(
          <div className="key-results-input" key={index}>
            <div className="instructions">
              <span className="instructions-title">
                <FormattedMessage 
                  id="createobjectivemainform.label.keyResult.title"
                  values={{number: `${index + 1}`}}
                />
              </span> <br />
              <span className="instructions-description">
                <FormattedMessage 
                  id="createobjectivemainform.label.keyResult.description" 
                />
              </span>
            </div>

            <TegduTextInputForm 
              labelId={"createobjectivemainform.label.keyResult"}
              name={`key_results.${index}.name`}
              control={methods.control}
              isEditMode={true}
              maxLength={150}
              withErrorArea={true}
              
            />

            <TegduTextAreaInputForm 
              name={`key_results.${index}.description`}
              labelMessageId="createobjectivemainform.label.description"
              control={methods.control}
              isEditMode={true}
              withMaxLength={true}
              maxLength={200}
            />

            <div className="metrics">
              <div className="instructions">
                <span className="instructions-title">
                  <FormattedMessage 
                    id="createobjectivemainform.label.metrics.title"                    
                  />
                </span> <br />
                <span className="instructions-description">
                  <FormattedMessage 
                    id="createobjectivemainform.label.metrics.description" 
                  />
                </span>
              </div>

              <div className="metric-selector">
                <span>
                  This KR will be measured as: 
                </span>
                <div className="selector">
                  <TegduSelectInputForm 
                    name={`key_results.${index}.unit`}
                    placeholderId="createobjectivemainform.placeholder.metrics"
                    control={methods.control}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                    options={okrUnitsCatalogue}
                  />
                </div>
                
              </div>
              
              <div className="metric-values">
                <div className="values">
                  <TegduTextInputForm 
                    name={`key_results.${index}.init_value`}
                    control={methods.control}
                    stringAsFloat
                    isEditMode={true}
                    labelId={"createobjectivemainform.label.initialValue"}
                    adornmentsType={parseInt(krUnit[index].unit?.value as string)}
                  />
                </div>

                <div className="values">
                  <TegduTextInputForm 
                    name={`key_results.${index}.target_value`}
                    control={methods.control}
                    isEditMode={true}
                    stringAsFloat
                    labelId={"createobjectivemainform.label.targetValue"}
                    adornmentsType={parseInt(krUnit[index].unit?.value as string)}
                  />
                </div>                
              </div>

              <div className="extra-messages">
                {(krUnit[index].init_value !== "" && krUnit[index].target_value !== "" &&
                  krUnit[index].init_value === krUnit[index].target_value) &&                  
                  <TegduWarningMessage />
                }
              </div>
            </div>

            <div className="add-key-result">              
              <Button 
                className='secondary' 
                onClick={((index + 1) !== 1) ? () => remove(index) : () =>  null}
                disabled={(index + 1) === 1}
              >
                <FormattedMessage id='general.button.remove' />
              </Button>                
            </div>
            
          </div>
        )
      })}

      <div className="add-kr-button">
        <Button
          className='outline'
          disabled={(methods.formState.isValid === false) || ((fields.length) === 8)}
          onClick={
            (methods.formState.isValid && ((fields.length) < 8)) ?
              () => {append(builaInitialValuesToAddKeyResults(okrUnitsCatalogue))}
            :
              () => null
          }
        >
          <Icon>add</Icon>
          <p>
            <FormattedMessage id={'createobjectivemainform.label.keyResult.add'} />
          </p>
        </Button>
      </div>
    </div>
  )
}

export default ObjectivesKeyResultsForm;