import { MembersModel } from '@models/members.model';
export const TOUR_MEMBERS_TABLE: MembersModel[] = [
  {
    id: '0',
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Laura',
    last_name: 'Gómez',
    job_title: 'Marketing Manager',
    active: true,
    role: {
      id: '0',
      name: 'Owner',
    },
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Alejandro',
    last_name: 'Santana',
    job_title: 'Digital Marketing Specialist',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'María',
    last_name: 'Hernández',
    job_title: 'Content Marketing Manager',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Pedro',
    last_name: 'López',
    job_title: 'Social Media Strategist',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Carolina',
    last_name: 'Ruiz',
    job_title: 'Email Marketing Specialist',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Ricardo',
    last_name: 'Martínez',
    job_title: 'SEO Manager',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Ana',
    last_name: 'García',
    job_title: 'Paid Advertising Specialist',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Roberto',
    last_name: 'Pérez',
    job_title: 'Marketing Analyst',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
  {
    id: '0',
    role: {
      id: '0',
      name: 'Owner',
    },
    created_at: new Date('2021-08-26T21:10:27.000Z'),
    first_name: 'Isabel',
    last_name: 'Rodríguez',
    job_title: 'Brand Manager',
    active: true,
    tgd_team_role_users: [], // Aquí debes proporcionar el valor adecuado
  },
];
