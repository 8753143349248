import GenericTable from '@components/Shared/GenericTable/GenericTable'
import { TableData } from '@containers/Invitations/PendingInvitationsContainer/PendingInvitationsContainer'
import { Button, Card } from '@material-ui/core'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import CheckName from '../CheckName/CheckName'
import PendingInvitationsColumns from './PendingInvitationsColumns'
import "./style.scss"
import { addNewMembers, deleteInvitationByID } from '@store/Invitations/invitations.action';
import useToastNotification from 'hooks/useToastNotification'

interface PendingInvitationsTableProps {
  data: TableData[] | null
  error: any;
  loading: boolean;
  retryCallback: () => void
}

const PendingTable = (props: PendingInvitationsTableProps) => {
  const { data, error, loading, retryCallback } = props
  const toastNotification = useToastNotification();
  const dispatch = useDispatch()

  const selectProps = { indeterminate: (isIndeterminate: any) => isIndeterminate };
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const isDisabled = selectedRows.length === 0 ? true : false

  const getRoleId = useCallback((role: string) => {
    switch (role) {
      case "Public":
        return "2";

      case "Manager":
        return "3";

      case "Member":
        return "5";

      case "Officer":
        return "6";

      case "Owner":
        return "7";

      default:
        return ""
    }
  }, [])

  const handleSelect = useCallback((data: any) => {
    const resendInvitationData = data?.selectedRows.map((rowSelected: any) => ({
      role: getRoleId(rowSelected?.role),
      emails: [{ label: rowSelected.email }],
      id: rowSelected.id
    }))
    return setSelectedRows([...resendInvitationData])
  }, [getRoleId])

  const handleErrorAddMembersForm = useCallback(() => {
    toastNotification('general.error.members.invitations', true)
  }, [toastNotification])

  const handleSuccessAddMembersForm = useCallback(() => {
    toastNotification('general.success.members.invitations', false)
  }, [toastNotification])

  const handleErrorCancelInvitation = useCallback(() => {
    toastNotification('general.error.cancel.invitations', true)
    return retryCallback()
  }, [retryCallback, toastNotification])

  const handleSuccessCancelInvitation = useCallback(() => {
    toastNotification('general.success.cancel.invitations', false)
    return retryCallback()
  }, [retryCallback, toastNotification])

  const handleResendInvitation = useCallback(() => {
    if (selectedRows.length === 1) {
      const [dataToSubmit] = selectedRows
      dispatch(addNewMembers(dataToSubmit, handleSuccessAddMembersForm, handleErrorAddMembersForm))
      return setToggleCleared(!toggleCleared)
    }
    else if (selectedRows.length !== 0 && selectedRows.length > 1) {
      selectedRows.map((member: any) => dispatch(addNewMembers(member, handleSuccessAddMembersForm, handleErrorAddMembersForm)))
      return setToggleCleared(!toggleCleared)
    }
  }, [dispatch, handleErrorAddMembersForm, handleSuccessAddMembersForm, selectedRows, toggleCleared])


  const handleCancelInvitation = useCallback(() => {
    const [idToSubmit] = selectedRows
    if (selectedRows.length === 1) {
      dispatch(deleteInvitationByID(idToSubmit.id, handleSuccessCancelInvitation, handleErrorCancelInvitation))
      setToggleCleared(!toggleCleared)
    } else if (selectedRows.length !== 0 && selectedRows.length > 1) {
      selectedRows.map((member: any) => dispatch(deleteInvitationByID(member.id, handleSuccessCancelInvitation, handleErrorCancelInvitation)));
      setToggleCleared(!toggleCleared)
    }
  }, [dispatch, handleErrorCancelInvitation, handleSuccessCancelInvitation, selectedRows, toggleCleared])

  return (
    <div className='pending-table'>
      <Card className='card-container'>
        <GenericTable
          label='invitations'
          columns={PendingInvitationsColumns()}
          data={data}
          pagination={true}
          loading={loading}
          error={error}
          selectableRows
          subHeader
          onSelectedRowsChange={selected => handleSelect(selected)}
          selectableRowsComponentProps={selectProps}
          selectableRowsComponent={CheckName as any}
          clearSelectedRows={toggleCleared}
          retryCallback={retryCallback}
        />
        <div className='buttons-wrapper'>
          <Button variant='outlined' disabled={isDisabled} onClick={handleCancelInvitation}>
            <FormattedMessage id="invitations.pending.button.cancel" />
          </Button>
          <Button disabled={isDisabled} onClick={handleResendInvitation}>
            <FormattedMessage id="invitations.pending.button.resend" />
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default PendingTable