import { Action, combineReducers } from 'redux';
import Organization from './Organization';
import Catalogs from './Catalogs';
import User from './User';
import Language from './Language';
import Login from './Login';
import Objectives from './Objectives';
import sharedReducer from './Shared/shared.reducer';
import { invitationsReducer } from './Invitations/invitations.reducer';
import { hrTeamReducer } from './HRTeam/HRTeamTabs/hrteam.reducer';
import notificationsReducer from './Notifications/notifications.reducer';
import errorsReducer from './Errors/errors.reducer';


export const combinedReducers = combineReducers({
  User: User,
  Organization: Organization,
  Catalogs: Catalogs,
  Language: Language,
  Shared: sharedReducer,
  Invitations: invitationsReducer,
  Objectives: Objectives,
  Notifications: notificationsReducer,
  Login: Login,
  HRTeam: hrTeamReducer,
  Errors: errorsReducer
});


export type RootState = ReturnType<typeof combinedReducers>;

const rootReducer = (state: RootState | undefined, action: Action<string>) => {
  // Ref: https://stackoverflow.com/questions/52296953/how-to-reset-value-of-state-in-a-redux-store
  if (action.type === 'USER_LOGOUT') {
    state = {} as RootState;
  }
  return combinedReducers(state, action);
};

export default rootReducer
