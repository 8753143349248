import { useCallback } from 'react';
import { ApiCatalogueLabel } from '@models/catalogs.model';
import { OptionsModel } from '@models/helpful.model';

const useCatalogue = () => {
  const getCatalogue = useCallback(
    async (
      service: () => Promise<ApiCatalogueLabel[]>,
      onSuccess: (data: OptionsModel[]) => void,
      onError: (error: any) => void
    ) => {
      try {
        const res = await service();
        const options: OptionsModel[] = res.map((option) => ({
          value: option.id.toString(),
          label: option.label,
        }));
        onSuccess(options);
      } catch (error) {
        console.error(error);
        onError(error);
      }
    },
    []
  );

  return { getCatalogue };
};

export default useCatalogue;
