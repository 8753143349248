import Header from "@components/Template/Header/Header";
import TegduMobileModal from "pwa/components/Shared/TegduMobileModal/TegduMobileModal";
import { getObjectives } from "@services/objectives.service";
import { ROUTE } from "@utils/routes";
import { CREATE_OBJECTIVES_ACTIONS } from "lib/enums/objectives.enum";
import ObjectivesActionsModal from "pwa/components/Objectives/CreateObjective/ObjectivesActionsModal/ObjectivesActionsModal";
import useOpenAddObjective from "pwa/hooks/useOpenAddObjective";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import BottomBarMenu from "../BottomBarMenu/BottomBarMenu";
import BottomNewObjective from "../BottomNewObjective/BottomNewObjective";
import ModalSearchObjectivesContainer from "../ModalSearchObjectivesContainer/ModalSearchObjectivesContainer";
import { SlideBarMobile } from "../SlideBar/SlideBar";
import "./styles.scss";
interface LayoutProps {
  children: React.ReactNode;
  header?: boolean;
  bottomBar?: boolean;
}

const Layout = ({ children, bottomBar, header }: LayoutProps) => {
  const [responsiveMenu, setResponsiveMenu] = useState<boolean>(false);
  const [nameHeader, setNameHeader] = useState<string>("");
  const [openModalAddProgress, setOpenModalAddProgress] = useState<boolean>(false)
  const {onOpen, onClose, isOpenAdd} = useOpenAddObjective();
  const history =  useHistory();
  
  const [isCreateObjectiveOpen, setIsCreateObjectiveOpen] = useState<boolean>(false);
  const [objectiveModalAction, setObjectiveModalAction] = useState<CREATE_OBJECTIVES_ACTIONS>(CREATE_OBJECTIVES_ACTIONS.create);
  const [objectiveId, setObjectiveId] = useState<undefined | number>(undefined);
  const [optionHaveSelected, setOptionHaveSelected] = useState<number>(0);

  const onHandleModalAddProgress = useCallback(
    () => {setOpenModalAddProgress(!openModalAddProgress)},
    [openModalAddProgress],)
  
  const onActionAddProgress = ()=>{
    onClose();
    onHandleModalAddProgress()
  }
  const onActionNewObjective = ()=>{
    onClose();
    setObjectiveId(undefined);
    setObjectiveModalAction(CREATE_OBJECTIVES_ACTIONS.create);
    setIsCreateObjectiveOpen(true);
  }

  const closeCreateObjectiveModal = useCallback(() => {
    setIsCreateObjectiveOpen(false)
  }, [])

  const getSelectedOption = useCallback(async (selectedOption: number) => {
    setOptionHaveSelected(selectedOption);
    getObjectives(selectedOption)
    history.push(ROUTE.objetives.path)
  }, [history]);
  
  return (
    <div className="container-layout">
      {header && (
        <div className="container-header">
          <Header
            isProfile
            nameSection={nameHeader === "sidebar.dashboard" ? undefined : nameHeader}
            onClickReponsiveIcon={() => setResponsiveMenu(true)}
          />
        </div>
      )}
      <SlideBarMobile
        isResponsiveMenuOpen={responsiveMenu}
        closeResponsiveMenu={() => setResponsiveMenu(false)}
      />
      {children}
      <div className={isOpenAdd ? "container-both-bar-menus":"container-both-bar-menus-height"}>
        {/* NEW OBJECTIVE OR ADD PROGRESS */}
        <BottomNewObjective
          onClose={onClose}
          open={isOpenAdd}
          onActionAddProgress={onActionAddProgress}
          onActionNewObjective={onActionNewObjective}
          />
        {/* BOTTOM BAR */}
        {bottomBar && <BottomBarMenu isAddActionsOpen={isOpenAdd} onActionAdd={onOpen} handleNamesRoutes={(e) => setNameHeader(e)} />}
        </div> 
        {/* MODALS */}  
        <TegduMobileModal
            type="close"
            onClose={onHandleModalAddProgress}
            messageHeaderId="objectives.dashboard.tooltip.Progress"
            isOpen={openModalAddProgress}
            container={<ModalSearchObjectivesContainer onHasBeenAddedProgress={onHandleModalAddProgress}/>}
            />

        <ObjectivesActionsModal 
          isOpen={isCreateObjectiveOpen}
          handleClose={closeCreateObjectiveModal}
          objectiveId={objectiveId}
          action={objectiveModalAction}
          getObjectives={() => getSelectedOption(optionHaveSelected)}
      />
      </div>
  );
};

export { Layout as LayoutMobile };
