import { GET_OBJECTIVES_DEATILS_OKR_LOADING, GET_OBJECTIVES_LOGS_LOADING } from "./ObjectivesDetails.action";
import { ObjectivesOKRSActions, ObjectivesOKRState } from "./ObjectivesDetails.model";

const initialState: ObjectivesOKRState = {
  isObjectiveOKRLoading: false,
  isObjectiveLogsLoading: false
}

export const objectivesDetailsOKRReducer = (
  state: ObjectivesOKRState = initialState,
  actions: ObjectivesOKRSActions
  ): ObjectivesOKRState => {
  switch(actions.type) {
    case GET_OBJECTIVES_DEATILS_OKR_LOADING: 
      return {
        ...state,
        isObjectiveOKRLoading: actions.payload
      }
    case GET_OBJECTIVES_LOGS_LOADING: 
      return {
        ...state,
        isObjectiveLogsLoading: actions.payload
      }
    default:
      return state
  }
}