import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Form, Formik, useFormikContext } from 'formik';
import { FormTeamValues, TeamsData } from '@models/teams.model';
import { OptionsModel } from '@models/helpful.model';
import FormikDropdown from '@components/FormikComponents/FormikDropdown/FormikDropdown';
import { TeamSchema } from 'lib/formsValidations/formsValidations';
import CircleWithNameOption from '@components/Shared/CircleWithNameOption/CircleWithNameOption';
import { buildMemberOptions } from '@utils/builders';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';
import withDropFastField from '@hocs/withDropFastField';
import { ValueType } from '@models/notifications.models';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikDropdownFastField = withDropFastField(FormikDropdown);

interface FormTeamProps {
  formId: string;
  loadingSubmit: boolean;
  loadingMembers: boolean;
  onSubmit: (data: FormTeamValues, setErrors: any) => void;
  // Form
  team?: TeamsData;
  members: OptionsModel[];
  onValidate: (isValid: boolean) => void;
  viewMode?: boolean;
}

interface GetIsValidFormComponentProps {
  onValidate: (isValid: boolean) => void;
  loadingSubmit: boolean;
}

const GetIsValidFormComponent = (props: GetIsValidFormComponentProps) => {
  const { isValid, values, initialValues } = useFormikContext();
  const areEqual = JSON.stringify(values) === JSON.stringify(initialValues);
  useEffect(() => {
    props.onValidate(!isValid || areEqual === isValid || props.loadingSubmit);
  }, [areEqual, isValid, props, props.onValidate]);

  return null;
};

const FormTeam = ({
  formId,
  team,
  members,
  onSubmit,
  onValidate,
  loadingSubmit,
  loadingMembers,
  viewMode,
}: FormTeamProps) => {
  const [leadsOptions, setLeadsOptions] = useState<OptionsModel[]>([]);
  const [membersOptions, setMembersOptions] = useState<OptionsModel[]>([]);

  const initialValues: FormTeamValues = useMemo(() => {
    const teamMembers = team ? team.members.map((member) => member.id) : [];
    const teamLeads = team ? team.leads.map((lead) => lead.id) : [];

    return {
      name: team ? team.name : '',
      leads: teamLeads,
      members: teamMembers,
    };
  }, [team]);

  const defaultLeads: OptionsModel[] = useMemo(
    () => (team ? buildMemberOptions(team.leads) : []),
    [team]
  );
  const defaultMembers: OptionsModel[] = useMemo(
    () => (team ? buildMemberOptions(team.members) : []),
    [team]
  );

  useEffect(() => {
    // When team is received in props, divide the options
    if (team) {
      const teamMembers = team.members.map((user) => user.id);
      const teamLeads = team.leads.map((user) => user.id);
      const leads = members.filter((option) => !teamMembers.includes(option.value as string));
      const membs = members.filter((option) => !teamLeads.includes(option.value as string));
      setLeadsOptions(leads);
      setMembersOptions(membs);
    } else {
      setLeadsOptions(members);
      setMembersOptions(members);
    }
  }, [team, members]);

  const handleSubmit = useCallback(
    (data: FormTeamValues, { setErrors }) => {
      onSubmit(data, setErrors);
    },
    [onSubmit]
  );

  const handleSelectChange = useCallback(
    (value: ValueType, name: 'leads' | 'members') => {
      if (name === 'leads') {
        const newMembers = members.filter((option) => !(value as Array<React.ReactText>).includes(option.value));
        setMembersOptions(newMembers);
      } else {
        const newLeads = members.filter(
          (option) => !(value as Array<React.ReactText>).includes(option.value)
        );
        setLeadsOptions(newLeads);
      }
    },
    [members]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={TeamSchema}
      onChange={() => console.log('')}
    >
      <Form id={formId}>
        <GetIsValidFormComponent
          onValidate={(isValid: boolean) => onValidate(isValid)}
          loadingSubmit={loadingSubmit}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInputFastField
              name='name'
              labelId='teams.add.screen.label.name'
              placeholder='teams.add.screen.placeholder.name'
              validateOnClick={!team}
              viewMode={viewMode ? viewMode : false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormikDropdownFastField
              name='leads'
              label='teams.add.screen.label.leader'
              placeholder='general.placeholder.search'
              defaultValue={defaultLeads}
              onChange={(value) => handleSelectChange(value, 'leads')}
              options={leadsOptions}
              isSearchable
              isClearable
              isMulti
              cleanFilter={false}
              customComponents={{
                Option: CircleWithNameOption,
              }}
              isLoading={loadingMembers}
              viewMode={viewMode ? viewMode : false}
              withComplexShouldUpdate
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDropdownFastField
              name='members'
              label='teams.add.screen.label.members'
              description={!viewMode ? 'teams.add.screen.label.members.caption' : ''}
              placeholder='general.placeholder.search'
              defaultValue={defaultMembers}
              options={membersOptions}
              onChange={(value) => handleSelectChange(value, 'members')}
              isMulti
              isSearchable
              cleanFilter={false}
              customComponents={{
                Option: CircleWithNameOption,
              }}
              isLoading={loadingMembers}
              viewMode={viewMode ? viewMode : false}
              withComplexShouldUpdate
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default FormTeam;
