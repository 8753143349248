import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react'
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { NewTagSchema } from '@formsValidations/formsValidations';
import { postTag } from '@services/settings.service';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';
import Spinner from '@components/Shared/Spinner/Spinner';
import './styles.scss'
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);

interface ModalNewTagProps{
    isOpen: boolean
    onClose: () =>  void
    refetchCallback: () => void
}

const initialValues = {
    tag: ''
}

const ModalNewTag = (props: ModalNewTagProps) => {
    const [loading, setLoading] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
    const { onClose, refetchCallback } =  props

    const onSubmit = useCallback(async (data: any) => {
        setLoading(true)
        try{
            await postTag(data.tag, organizationId)
            setLoading(false)
            onClose()
            refetchCallback()
            
        }catch{
            setLoading(false)
            setErrorModal(true)
        }
    }, [onClose, refetchCallback, organizationId])

    return(
        <>
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            className={'modal-new-tag'}
            scroll={"body"}
            maxWidth="xs"
            disableBackdropClick
        >
            <DialogContent className="content">
                <Grid container className={'new-tag-form'}>
                    <Grid item className={'title'} xs={12}>
                        <p>
                            <FormattedMessage id='settings.tab.tags.newtag.form.title' />
                        </p>
                    </Grid>

                    <Grid item className={'form'} xs={12}>
                        <Formik initialValues={initialValues} validationSchema={NewTagSchema} onSubmit={onSubmit} validateOnMount>
                            {(formik) => {
                                return(
                                    <Form>
                                        <Grid container className={'form-container'}>
                                            <Grid item xs={12}>                                                
                                                <FormikTextInputFastField 
                                                    name='tag'
                                                    labelId='general.form.label.new.tag'
                                                    viewMode={false}
                                                    validateOnClick
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={'buttons'}>
                                                <div className={'cancel-btn'}>
                                                    <Button className={'secondary'} onClick={props.onClose}>
                                                        <FormattedMessage id={'statement.cancel'} />
                                                    </Button>
                                                </div>
                                                <div className={'save-btn'}>
                                                    <Button type='submit' disabled={!formik.isValid && loading}>
                                                        {loading === true &&
                                                            <Spinner color="white" size={15} thickness={5}/>
                                                        }                                                        
                                                        <FormattedMessage id={'statement.save'} />
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>

                </Grid>
            </DialogContent>            
        </Dialog>
            <GenericErrorModal 
                isOpen={errorModal}
                intlMessage={{id: 'general.modal.error.label', values: {action: 'adding', subject: 'tag'}}}
                handleClose={() => setErrorModal(false)}
            />
        </>
    )
}

export default ModalNewTag;