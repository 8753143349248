import { RootState } from "@store/rootReducer";
import { useSelector } from "react-redux";
import AcceptedInvitationsContainer from "../AcceptedInvitationsContainer/AcceptedInvitationsContainer";
import PendingInvitationsContainer from "../PendingInvitationsContainer/PendingInvitationsContainer";
// import RequestsContainer from "../RequestsContainer/ResquestsContainer";

const ActiveContainer = () => {
  const invitationsTabState = useSelector((state: RootState) => state.Invitations.invitationsActiveTabState);

  switch (invitationsTabState) {
    case 0:
      return <PendingInvitationsContainer />;
    case 1:
      return <AcceptedInvitationsContainer />;
    // case 2:
    //   return <AcceptedInvitationsContainer />;
    default:
      // Change to Requests Container when finished
      return <PendingInvitationsContainer />;
  }
}

export default ActiveContainer