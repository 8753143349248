import { ROUTE } from '@utils/routes';

export const menuOptions = [
  {
    icon: 'dashboard',
    title: 'sidebar.dashboard',
    href: ROUTE.dashboard.path,
  },
  {
    icon: 'list_alt',
    title: 'sidebar.objectives',
    href: ROUTE.objetives.path,
  },
  {
    icon: 'star_roundedIcon',
    title: 'sidebar.reviews',
    href: ROUTE.reviews.path,
  },
  {
    icon: 'business',
    title: 'sidebar.organization',
    href: ROUTE.organization.path,
  },
  {
    icon: 'group',
    title: 'sidebar.hr',
    href: ROUTE.hr.path,
  },
  {
    icon: 'person',
    title: 'sidebar.members',
    href: ROUTE.members.path,
  },
  {
    icon: 'groups',
    title: 'sidebar.teams',
    href: ROUTE.teams.path,
  },
  {
    icon: 'group_add',
    title: 'sidebar.invitations',
    href: ROUTE.invitations.path
  }
];
