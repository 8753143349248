import React, { useCallback, useState } from 'react';
import { Button, Card } from '@material-ui/core';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import { ROUTE } from '@utils/routes';
import { useHistory } from 'react-router-dom';
import { setSelectedTabAction } from '@store/Organization/selectedTab/selectedTab.action';
import { useDispatch } from 'react-redux';
import Spinner from '@components/Shared/Spinner/Spinner';

interface DashboardGenericCardProps {
  id: 'statements' | 'pillars';
  titleId: string;
  count?: number;
  data: { name: string; description: string }[];
  loading: boolean;
  error: any; // TODO: Agregar validación
  allowAdd: boolean;
}

function DashboardGenericCard(props: DashboardGenericCardProps) {
  const [index, setIndex] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDotClick = useCallback((i) => {
    setIndex(i);
  }, []);

  const redirectTo = useCallback(() => {
    if (props.id === 'statements') {
      dispatch(setSelectedTabAction(1));
    } else {
      dispatch(setSelectedTabAction(2));
    }
    history.push(ROUTE.organization.path);
  }, [dispatch, history, props.id]);

  return (
    <Card className='dashboard-card'>
      <div className='card__header'>
        <h3><FormattedMessage id={props.titleId} values={{ count: props.count }} /></h3>
      </div>
      {props.loading ? (
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      ) : (
        <>
          <div className='card__body'>
            {!props.data.length ? (
              <>
                <h3 className='empty'>
                  <FormattedMessage id='dashboard.general.empty' values={{ category: props.id }} />
                </h3>
                {props.allowAdd && (
                  <Button className='search-button outline' onClick={redirectTo}>
                    <FormattedMessage id='dashboard.general.add' values={{ category: props.id }} />
                  </Button>
                )}
              </>
            ) : (
              <div className='name__content'>
                <h3>{props.data[index] && props.data[index].name}</h3>
                <p> {props.data[index] && props.data[index].description}</p>
              </div>
            )}
          </div>
          <div className='card__footer'>
            {props.data.map((el, i) => {
              return (
                <div
                  key={`dot.${i}`}
                  className={`custom-dot ${index === i ? 'selected' : ''}`}
                  onClick={() => onDotClick(i)}
                ></div>
              );
            })}
          </div>
        </>
      )}
    </Card>
  );
}

export default DashboardGenericCard;
