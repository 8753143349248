
const messagesEn = require('./reactIntlMessages-en-US.json');
const messagesEs = require('./reactIntlMessages-es-MX.json')

export const getMessages = (locale: string) => {
    let messages = null;
    switch(locale.toLowerCase()){
        case 'en' :
		case 'en-us' :
			messages = messagesEn;
			break;
		case 'es' :
		case 'es-mx' :
		case 'es-es' :
		case 'es-us' :
            messages = messagesEs;
            break;
		default :
			messages = messagesEn;
			break;
    }

    return messages; 
}