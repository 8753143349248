import { GQLGetTags } from '@models/settings.model';
export const TOUR_SETTINGS_TABLE: GQLGetTags[] = [
  {
    id: '0',
    name: 'New York',
    is_archived: false,
    tgd_organization: {
      id: '0',
      __typename: 'TgdOrganizations',
    },
    tgd_tag_objectives: [
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 1: Increase user engagement',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 2: Improve website performance',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
    ],
    __typename: 'TgdTags',
  },
  {
    id: '0',
    name: 'Londres',
    is_archived: false,
    tgd_organization: {
      id: '0',
      __typename: 'TgdOrganizations',
    },
    tgd_tag_objectives: [
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 1: Increase user engagement',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 2: Improve website performance',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 3: Expand market reach',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
    ],
    __typename: 'TgdTags',
  },
  {
    id: '0',
    name: 'Berlín',
    is_archived: false,
    tgd_organization: {
      id: '0',
      __typename: 'TgdOrganizations',
    },
    tgd_tag_objectives: [
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 1: Increase user engagement',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 2: Improve website performance',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 3: Expand market reach',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 4: Enhance customer experience',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 5: Optimize conversion rate',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
    ],
    __typename: 'TgdTags',
  },
  {
    id: '0',
    name: 'City of  México',
    is_archived: false,
    tgd_organization: {
      id: '0',
      __typename: 'TgdOrganizations',
    },
    tgd_tag_objectives: [
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 1: Increase user engagement',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 2: Improve website performance',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 3: Expand market reach',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 4: Enhance customer experience',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 5: Optimize conversion rate',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 6: Strengthen brand awareness',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 7: Increase social media presence',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
    ],
    __typename: 'TgdTags',
  },
  {
    id: '0',
    name: 'Tokyo',
    is_archived: false,
    tgd_organization: {
      id: '0',
      __typename: 'TgdOrganizations',
    },
    tgd_tag_objectives: [
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 1: Increase user engagement',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 2: Improve website performance',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 3: Expand market reach',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 4: Enhance customer experience',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 5: Optimize conversion rate',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 6: Strengthen brand awareness',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 7: Increase social media presence',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 8: Enhance product quality',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
      {
        id: '0',
        tgd_objective: {
          name: 'Objective 9: Streamline operational processes',
          __typename: 'TgdObjectives',
        },
        __typename: 'TgdTagObjectives',
      },
    ],
    __typename: 'TgdTags',
  },
];
