import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import ObjectiveDetailsMainComponent from '../ObjectiveDetailsMainComponent/ObjectiveDetailsMainComponent';
import { GqlGetTgdObjRoleUsers, ObjectiveGet, ObjectivesLogs, ObjectivesOKRReviewRate } from '@models/objetives.model';
import Spinner from '@components/Shared/Spinner/Spinner';
import GenericErrorComponent from '@components/Shared/GenericErrorComponent/GenericErrorComponent';
import { OBJECTIVE_ROLE } from '@utils/objectives';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';
import SimpleTag from '@components/Shared/SimpleTag/SimpleTag';
import BackButton from '@components/Shared/BackButton/BackButton';
import CopyToClipboard from '@components/Shared/CopyToClipboard/CopyToClipboard';
import SectionDivider from '../SectionDivider/SectionDivider';
import CircleWithName from '@components/Shared/CircleWithName/CircleWithName';
import InlineKeyResult from '../InlineKeyResult/InlineKeyResult';
import ObjectiveLogsGroup from '../ObjectiveLogsGroup/ObjectiveLogsGroup';

import './styles.scss';
import ObjectiveCommentsContainer from '@containers/ObjectivesDetails/ObjectiveCommentsContainer/ObjectiveCommentsContainer';

interface ObjectivesDetailsContentProps {
  valueId: string;
  data: ObjectiveGet | null;
  error: any;
  loading: boolean;
  dataLogs?: ObjectivesLogs[];
  dataOKRReviews?: ObjectivesOKRReviewRate;
  onActionModalProgress?: () => void;
  onReviewObjectiveOKR?: () => void;
  getActionSelected: (keyName: string, objectiveId: number) => void;
  statusID?: string;
}

const ObjectivesDetailsContent = ({
  valueId,
  data,
  error,
  loading,
  dataLogs = [],
  dataOKRReviews,
  onActionModalProgress,
  getActionSelected,
  onReviewObjectiveOKR,
  statusID,
}: ObjectivesDetailsContentProps) => {
  const userId = useSelector((state: RootState) => state.User.Info.user.id);

  const isOwner = useMemo(
    () =>
      data
        ? data.tgd_obj_role_users.some(
            (u: any) =>
              u.user.id.toString() === userId.toString() && u.tgd_role.id.toString() === OBJECTIVE_ROLE.owner.toString()
          )
        : false,
    [data, userId]
  );

  const isCoach = useMemo(
    () =>
      data
        ? data.tgd_obj_role_users.some(
            (u: any) =>
              u.user.id.toString() === userId.toString() && u.tgd_role.id.toString() === OBJECTIVE_ROLE.coach.toString()
          )
        : false,
    [data, userId]
  );

  const objectiveMembers: GqlGetTgdObjRoleUsers[] = useMemo(
    () =>
      data
        ? data.tgd_obj_role_users.filter(
            (roleUser, index, self) =>
              index === self.findIndex((selfRoleUser) => selfRoleUser.user.id === roleUser.user.id)
          )
        : [],
    [data]
  );

  return (
    <div className='objectives-details-content'>
      {loading ? (
        <div className='screen-loader'>
          <Spinner size={40} color='primary' thickness={4} />
        </div>
      ) : error ? (
        <div className='screen-loader'>
          <GenericErrorComponent label='the detail of the objective' />
        </div>
      ) : (
        data !== null && (
          <>
            {/* HEADER */}
            <div className='back-head'>
              <BackButton labelId='objectives.details.back' />
              <CopyToClipboard value={valueId} />
            </div>
            {/* MAIN DETAILS */}
            <ObjectiveDetailsMainComponent
              onActionProgress={onActionModalProgress}
              name={data.name}
              dueDate={data.due_date}
              progress={data.current_progress}
              timeframe={data.tgd_timeframe}
              tags={data.tgd_tag_objectives}
              description={data.description}
              culturalAttributes={data.tgd_obj_pillars}
              getActionSelected={getActionSelected}
              statusID={statusID}
              objectiveId={valueId}
              isOwner={isOwner}
              isCoach={isCoach}
            />
            <Grid container spacing={3}>
              {/* ROLES */}
              {objectiveMembers.length ? (
                <Grid className='detailscontent-roles' item xs={12} md={6}>
                  <SectionDivider labelId='objectives.section.role.title' contentClass='roles-group'>
                    {objectiveMembers.map((e) =>
                      e.active === false ? (
                        <></>
                      ) : (
                        <CircleWithName
                          name={`${e.user.first_name} ${e.user.last_name}`}
                          roleId={parseInt(e.tgd_role.id)}
                          size='md'
                        />
                      )
                    )}
                  </SectionDivider>
                </Grid>
              ) : (
                <></>
              )}
              {/*TEAM*/}
              {data.tgd_objective_teams.length > 0 && (
                <Grid className='detailscontent-teams' item xs={12} md={6}>
                  <SectionDivider labelId='objectives.section.team.title' contentClass='teams-tags'>
                    {data.tgd_objective_teams.map((e) => (
                      <SimpleTag color={'soft'} tag={e.tgd_team.name || ''} key={e.id} />
                    ))}
                  </SectionDivider>
                </Grid>
              )}
              {/* KEY RESULTS */}
              <Grid className='detailscontent-krs' item xs={12}>
                <SectionDivider
                  labelId='objectives.section.kr.title'
                  intlValues={{ length: data.tgd_key_results.length }}
                  contentClass='keyresults-group'
                >
                  {data.tgd_key_results.map((kr: any, index: any) => (
                    <InlineKeyResult
                      index={index}
                      name={kr.name}
                      description={kr.description}
                      progress={kr.current_progress}
                      currentValue={kr.current_value}
                      targetValue={kr.target_value}
                      unitId={kr.unit.id}
                      showDescription
                    />
                  ))}
                </SectionDivider>
              </Grid>
              {/* COMMENTS */}
              {/* <Grid className='detailscontent-comments' item xs={12}>
                <SectionDivider labelId='objectives.section.comments.title' contentClass='comments-group'>
                  <ObjectiveCommentsContainer objectiveId={valueId} objectiveTeamMembers={data.tgd_obj_role_users} />
                </SectionDivider>
              </Grid> */}
              {/* LOGS */}
              {dataLogs.length >= 1 && (
                <Grid className='detailscontent-logs' item xs={12}>
                  <SectionDivider labelId='objectives.logs.detail.title.label' contentClass='logs-group'>
                    <ObjectiveLogsGroup data={dataLogs} />
                  </SectionDivider>
                </Grid>
              )}
            </Grid>
          </>
        )
      )}
    </div>
  );
};

export default ObjectivesDetailsContent;
