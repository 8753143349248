import './styles.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, InputLabel } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormikStyledError from '../FormikStyledError/FormikStyledError';
import { FormikFieldModel } from '@models/formik.model';

export interface FormikTextAreaProps {
  charsLimit?: number;
  field: FormikFieldModel;
  height?: string;
  label?: string;
  maxWidth?: string;
  meta: any;
  name: string;
  placeholder?: string;
  rowsMax?: number;
  rowsMin?: number;
  validateOnClick?: boolean;
  viewMode: boolean;
  disabled?: boolean;
}

const FormikTextArea = (props: FormikTextAreaProps) => {
  const { meta, maxWidth, placeholder, field, height } = props;
  return (
    <div className={'formik-text-area'} style={{ maxWidth }}>
      {props.label ? (
        <InputLabel htmlFor={`input-${props.name}`}>
          <FormattedMessage id={props.label} />
        </InputLabel>
      ) : (
        <></>
      )}
      {props.viewMode ? (
        <p>{field.value}</p>
      ) : (
        <>
          <TextareaAutosize
            style={{ height: `${height}` }}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            className={`text-area ${
              props.validateOnClick
                ? meta.error && meta.touched
                  ? 'error'
                  : ''
                : meta.error
                ? 'error'
                : ''
            }`}
            aria-label='minimum height'
            rowsMin={props.rowsMin ? props.rowsMin : 3}
            maxLength={props.charsLimit ? props.charsLimit : 200}
            placeholder={placeholder}
            rowsMax={4}
            disabled={props.disabled}
          />
        </>
      )}

      <Grid container className={'extra-info'}>
        <Grid item className={'error-info'} md={9}>
          {props.validateOnClick ? (
            <>{meta.error && meta.touched && <FormikStyledError message={meta.error} />}</>
          ) : (
            <>{meta.error !== undefined && <FormikStyledError message={meta.error} />}</>
          )}
        </Grid>
        <Grid item className={'chars-info'} md={3}>
          {!props.viewMode && (
            <div className='char-area'>
              {props.charsLimit && (
                <p className='chars-limit'>
                  {props.charsLimit ? `${field.value.length}/${props.charsLimit}` : ''}
                </p>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormikTextArea;
