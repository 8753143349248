import { FormattedMessage } from "react-intl";
import { BottomSheet } from "react-spring-bottom-sheet";
import { ObjectivesFiltersMobile } from "../ObjectivesFilters/ObjectivesFilters";
import "react-spring-bottom-sheet/dist/style.css";
import "./styles.scss";
import ObjectivesFiltersStatus from "../ObjectivesFiltersStatus/ObjectivesFiltersStatus";

interface ObjectivesBottomSheetProps {
  onCloseSheet: () => void;
  openSheet: boolean;
  optionHaveSelectedRole?: number;
  getSelectedOptionRole: (option: number) => void;
  optionHaveSelectedStatus?: number;
  getSelectedOptionStatus: (statusId: number, option:number) => void;
  onClearFilter: () => void;
}

const ObjectivesBottomSheet = ({
  onCloseSheet,
  openSheet,
  optionHaveSelectedRole,
  getSelectedOptionRole,
  getSelectedOptionStatus,
  optionHaveSelectedStatus,
  onClearFilter,
}: ObjectivesBottomSheetProps) => {
  return (
    <BottomSheet
      className="bottom-sheet"
      onDismiss={onCloseSheet}
      open={openSheet}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
    >
      <div className="objectives-bottom-sheet-container">
        <div className="header-sheet">
          <div>
            <span>
              <FormattedMessage id={"objectives.sheet.title.mobile.filter"} />
            </span>
          </div>
          <div>
            <span onClick={onClearFilter}>
              <FormattedMessage id={"objectives.sheet.title.mobile.clear"} />
            </span>
          </div>
        </div>
        <div className="content-sheet">
          <div className="role-content">
            <div className="title-content">
              <span>
                <FormattedMessage
                  id={"objectives.sheet.title.mobile.filter.role"}
                />
              </span>
            </div>
            <div className="buttons-content-filters">
              <ObjectivesFiltersMobile
                optionSelected={optionHaveSelectedRole}
                onSelectOption={getSelectedOptionRole}
              />
            </div>
          </div>
          <div className="role-content">
            <div className="title-content">
              <span>
                <FormattedMessage
                  id={"objectives.sheet.title.mobile.filter.status"}
                />
              </span>
            </div>
            <div className="buttons-content-filters">
              <ObjectivesFiltersStatus
                optionSelected={optionHaveSelectedStatus}
                onSelectOption={getSelectedOptionStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ObjectivesBottomSheet;
