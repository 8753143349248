import React from 'react';
import { OBJECTIVE_STATUS, OBJECTIVE_STATUS_DESCRIPTION } from '@utils/objectives';

import './styles.scss';

interface ObjectiveStatusComponentProps {
  status: OBJECTIVE_STATUS;
  size?: 'small';
}

const ObjectiveStatusComponent = (props: ObjectiveStatusComponentProps) => {
  const { status, size } = props;

  return (
    <div
      className={`component-statustag ${status !== OBJECTIVE_STATUS.active ? 'closed' : 'active'} ${
        size ? size : ''
      }`}
    >
      <span className='status-text'>{`${OBJECTIVE_STATUS_DESCRIPTION[status]}`}</span>
    </div>
  );
};

export default ObjectiveStatusComponent;
