import React, { useCallback } from 'react'
import { Grid, } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import { UpdatePasswordSchema } from '@formsValidations/formsValidations';
import ForrmikListener from '@components/FormikComponents/FormikListener/ForrmikListener';
import withTextFastField from '@hocs/withTextFastField';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput)

interface UpdatePasswordFormProps {
  isValidForm: (value: boolean) => void
  getValidations: (value: any) => void
}

export interface UpdatePasswordStructure {
  oldPassword?: string
  newPassword?: string
  confirmPassword?: string
}

const initialValues = {
  password: '',
  new_password: '',
  confirm_password: ''
}

const UpdatePasswordForm = (props: UpdatePasswordFormProps) => {
  const { isValidForm, getValidations } = props;

  const getIsValid = useCallback((value: boolean) => {
    isValidForm(value)
  }, [isValidForm])

  const getValues = useCallback((values: any) => {
    const { password, new_password, confirm_password } = values
    getValidations({ oldPassword: password, newPassword: new_password, confirmPassword: confirm_password })
  }, [getValidations])

  return (
    <div className='update-password-form'>
      <Formik
        initialValues={initialValues}
        onSubmit={() => undefined}
        validationSchema={UpdatePasswordSchema}
        validateOnMount
      >
        <Form>
          <ForrmikListener getIsValid={getIsValid} getValues={getValues} />
          <Grid item xs={12} sm={9} md={8}>
            <FormikTextInputFastField 
              name='password'
              viewMode={false}
              labelId='account.screen.tab.security.update.current'
              type='password'
              validateOnClick
            />

            <FormikTextInputFastField 
              name='new_password'
              type='password'
              labelId='account.screen.tab.security.update.confirm'
              viewMode={false}
              validateOnClick
            />
            
            <FormikTextInputFastField 
              name='confirm_password'
              labelId='account.screen.tab.security.update.new'
              type='password'
              viewMode={false}
              validateOnClick
            />
          </Grid>
        </Form>
      </Formik>
    </div>
  )
}

export default UpdatePasswordForm;