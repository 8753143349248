import SettingsContainer from '@containers/Settings/SettingsContainer/SettingsContainer';
import React from 'react';

const Settings = () => {
  return (
    <div id='tourstep-settings'>
      <SettingsContainer />
    </div>
  );
};

export default Settings;
