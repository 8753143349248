import React, { useMemo } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { truncNumber } from '@utils/numbers';

import './styles.scss';

interface ProgressBarProps {
  value: number; // 1 - 100
  thickness: number; // Height in pixels
  labelLeft?: boolean;
  minWidth?: number;
  maxWidth?: number;
}

const CustomLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 6,
    },
    colorPrimary: {
      backgroundColor: '#e0e0e0',
    },
    bar: {
      backgroundColor: `${theme.palette.primary.main}99`,
    },
  })
)(LinearProgress);

const ProgressBar = ({ value, thickness, labelLeft, minWidth, maxWidth }: ProgressBarProps) => {
  const label: string = useMemo(() => (value > 1 ? '1.0' : truncNumber(value)), [value]);

  return (
    <div className='component-progressbar' style={{ maxWidth, minWidth }} data-tag='allowRowEvents'>
      {labelLeft ? (
        <>
          <p className='label-left'>{label}</p>
          <CustomLinearProgress
            style={{ height: thickness }}
            variant='determinate'
            value={value > 1 ? 100 : value * 100}
          />
        </>
      ) : (
        <Tooltip title={label} placement={'bottom'}>
          <CustomLinearProgress
            style={{ height: thickness }}
            variant='determinate'
            value={value > 1 ? 100 : value * 100}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ProgressBar;
