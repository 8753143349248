import StyledDropdown from "@components/Shared/StyledDropdown/StyledDropdown"
import Switch from "@components/Shared/Switch/Switch"
import { ExpirationDropdown, PeriodicityProps, ValueType } from "@models/notifications.models"
import { Form, Formik } from "formik"
import { EXPIRATION_VALUES } from "lib/enums/notifications/notifications.enums"
import { useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import "./style.scss"

interface NotificationsSettingsInputs {
  switchLabel: string;
  switchName: string;
  isChecked: boolean;
  onSwitchChange: (actionId: string, handleSwitchChange: boolean) => void;
  isExpirationDropdown?: boolean
  expirationDropdown?: ExpirationDropdown;
  isOnlyPeriodicityDropdown?: boolean;
  periodicity?: PeriodicityProps;
}


const SettingsNotificationsInputs = (props: NotificationsSettingsInputs) => {

  const { switchLabel, switchName, isChecked, onSwitchChange, isExpirationDropdown, expirationDropdown = {
    expirationLabel: "", expirationDropdownOptions: [],
    expirationDropdownValues: "",
    onSelectExpirationOption: () => undefined,
    expirationDropdownPlaceholder: "",
    expirationDropdownName: ""
  },
    isOnlyPeriodicityDropdown,
    periodicity = {
      periodicityLabel: "",
      intervalValue: "",
      periodicityOptions: [],
      onSelectPeriodicityOption: () => undefined,
      periodicityPlaceholder: "",
      onPeriodicityBlur: () => undefined,
      weeksOrDaysValue: "",
      weeksOrDaysOptions: [],
      onSelectPeriodicOption: () => undefined,
      weeksOrDaysplaceholder: ""
    }
  } = props

  const {
    expirationLabel,
    expirationDropdownOptions,
    expirationDropdownValues,
    onSelectExpirationOption,
    expirationDropdownPlaceholder,
    expirationDropdownName
  } = expirationDropdown

  const {
    periodicityLabel,
    intervalValue,
    periodicityOptions,
    onSelectPeriodicityOption,
    periodicityPlaceholder,
    onPeriodicityBlur,
    weeksOrDaysValue,
    weeksOrDaysOptions,
    onSelectPeriodicOption,
    weeksOrDaysplaceholder
  } = periodicity

  const [isActive, setIsActive] = useState<boolean>()
  const [reminderValue, setReminderValue] = useState<ValueType>("")
  const [periodicValue, setPeriodicValue] = useState<string | undefined>("")


  const initialValues = { isObjectiveIsDue: expirationDropdown && expirationDropdown.expirationDropdownValues }

  useEffect(() => {
    setReminderValue(expirationDropdown.expirationDropdownValues)
  }, [expirationDropdown.expirationDropdownValues])


  useEffect(() => {
    setIsActive(isChecked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPeriodicValue(intervalValue)
  }, [intervalValue])

  const handleSwitchChange = useCallback((isActive: boolean) => {
    onSwitchChange(switchName, isActive)
    setIsActive(isActive)
  }, [onSwitchChange, switchName])

  const handleReminderSelection = useCallback((val: ValueType) => {
    onSelectExpirationOption(val);
    setReminderValue(val)
  }, [onSelectExpirationOption])

  return (
    <Formik initialValues={initialValues} onSubmit={() => undefined} validateOnMount>
      <Form>
        <div className={`section ${isExpirationDropdown || isOnlyPeriodicityDropdown ? "spacing" : ""}`}>
          {/* SWITCH COMPONENT */}
          <div className='label-switch-wrapper'>
            <label>
              <FormattedMessage id={switchLabel === undefined ? "" : switchLabel} />
            </label>
            <Switch
              checked={isChecked}
              onChange={handleSwitchChange}
              name={switchName}
              disabled={false}
            />
          </div>
          <div className='dropdowns-wrapper'>
            {/* EXPIRATION DROPDOWN */}
            {isExpirationDropdown && (<div className='objective-date-dropdown first'>
              <label>
                <FormattedMessage id={expirationLabel} />
              </label>
              <div className='objective-reminder-dropdown'>
                <StyledDropdown
                  cleanFilter={true}
                  isDisabled={!isActive ? true : false}
                  isSearchable={false}
                  value={expirationDropdownValues}
                  options={expirationDropdownOptions}
                  onSelectOption={handleReminderSelection}
                  placeholder={expirationDropdownPlaceholder}
                  viewMode={false}
                  name={expirationDropdownName}
                />
              </div>
            </div>)}

            <div>
              {/* EXPIRATION AND PERIODICITY DROPDOWNS */}
              {isExpirationDropdown && reminderValue === EXPIRATION_VALUES.beforeExpiration && <div className='label-switch-wrapper add-okr-progress'>
                <div className='okr-input-wrapper'>
                  {/* AMOUNT OF DAYS OR WEEKS*/}
                  <StyledDropdown
                    cleanFilter={true}
                    isDisabled={!isActive}
                    isSearchable={true}
                    viewMode={false}
                    value={periodicValue}
                    options={periodicityOptions}
                    onSelectOption={onSelectPeriodicityOption}
                    placeholder={periodicityPlaceholder}
                    width="70px"
                    onBlur={onPeriodicityBlur}
                  />

                  {/* DAYS OR WEEKS */}
                  <StyledDropdown
                    cleanFilter={true}
                    isDisabled={!isActive}
                    isSearchable={false}
                    value={weeksOrDaysValue}
                    options={weeksOrDaysOptions}
                    onSelectOption={onSelectPeriodicOption}
                    placeholder={weeksOrDaysplaceholder}
                    viewMode={false}
                  />
                  <span>
                    <FormattedMessage id='settings.tab.notifications.okr.add.progress.label.before' />
                  </span>
                </div>
              </div>}

              {/* PERIODICITY DROPDOWN `Reminds you to periodically add progress to Key Results in a OKR */}
              {isOnlyPeriodicityDropdown && <div className='label-switch-wrapper add-okr-progress'>
                <label>
                  <FormattedMessage id={periodicityLabel} />
                </label>
                <div className='okr-input-wrapper'>
                  <StyledDropdown
                    cleanFilter={true}
                    isDisabled={!isActive}
                    isSearchable={true}
                    viewMode={false}
                    value={periodicValue}
                    options={periodicityOptions}
                    onSelectOption={onSelectPeriodicityOption}
                    placeholder={periodicityPlaceholder}
                    width="70px"
                    onBlur={onPeriodicityBlur}
                  />
                  <StyledDropdown
                    cleanFilter={true}
                    isDisabled={!isActive}
                    isSearchable={false}
                    value={weeksOrDaysValue}
                    options={weeksOrDaysOptions}
                    onSelectOption={onSelectPeriodicOption}
                    placeholder={weeksOrDaysplaceholder}
                    viewMode={false}
                  />
                  <span>
                    <FormattedMessage id='settings.tab.notifications.okr.add.progress.label.before' />
                  </span>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

export default SettingsNotificationsInputs