import TimeFramesForm from '@components/Organizations/Timeframes/TimeFramesForm/TimeFramesForm';
import TegduErrorCallback from '@components/Shared/TegduErrorCallback/TegduErrorCallback';
import { 
  disableAutomaticTimeframes, 
  getTimeFramesData, 
  setAutomaticTimeframesData, 
  setTimeframesData, 
  setTimeframesRequestLoading, 
  updateTimeframesData 
} from '@store/Organization/Timeframes/timeframes.actions';
import { RootState } from '@store/rootReducer';
import { TimeFramesFormModel, TimeFramesFormObjectModel } from 'lib/TegduForms/TimeFramesFormUtils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimeFramesContainerLoading from './TimeFramesContainerLoading';
import './styles.scss';
import useToastNotification from 'hooks/useToastNotification';
import AutomaticTimeframesForm from '@components/Organizations/Timeframes/AutomaticTimeframesForm/AutomaticTimeframesForm';
import { AutomaticTimeframesFormModel } from 'lib/TegduForms/AutomaticTimeframesFormUtils';
import TegduCheckbox from '@components/TegduFormComponents/TegduCheckbox/TegduCheckbox';
import { SetAutomaticTimeframesRequest } from '@models/organizations.model';
import { TIMEFRAMES_AUTOMATIC_TYPES } from 'lib/enums/timeframes.enum';
import { FormattedMessage } from 'react-intl';
import GenericConfirmModal from '@components/Shared/GenericConfirmModal/GenericConfirmModal';


const OrganizationTimeFramesContainer = () => {
  const dispatch = useDispatch();
  const toastNotification = useToastNotification();
  const [editMode, setEditMode] = useState<boolean>(true);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [initialData, setInitialData] = useState<TimeFramesFormModel | undefined>(undefined);
  const [automaticCheck, setAutomaticCheck] = useState<boolean>(false);
  const [automaticTimeframesType, setAutomaticTimeframesType] = useState<null | TIMEFRAMES_AUTOMATIC_TYPES>(null);
  const [disableAutomaticTimeframesModal, setDisableAutomaticTimeframesModal] = useState<boolean>(false);
  const deactivateLoading = useSelector((state: RootState) => state.Organization.Timeframes.isDeactivateAutomaticLoading);
  const activatingLoading = useSelector((state: RootState) => state.Organization.Timeframes.isAutomaticRequestLoading);
  const requestLoading = useSelector((state: RootState) => state.Organization.Timeframes.isRequestLoading);
  const submitLoading = useSelector((state: RootState) => state.Organization.Timeframes.isUpdateLoading);
  const requestError = useSelector((state: RootState) => state.Organization.Timeframes.isRequestError);
  const organizationId = useSelector((state: RootState) => state.User.Info.user.tgd_organization.id);
  

  const handleGetSuccessTimeframes = useCallback((data: TimeFramesFormObjectModel [], isAutomaticTimeframes?: TIMEFRAMES_AUTOMATIC_TYPES) => {
    if(data.length === 0){
      setFirstTime(true)
    }else {
      setEditMode(false)
      setInitialData({ timeframe: data })
      setFirstTime(false)
    }

    if(isAutomaticTimeframes !== undefined){
      setAutomaticTimeframesType(isAutomaticTimeframes)
      setAutomaticCheck(true)
    }else {
      setAutomaticCheck(false)
    }
    dispatch(setTimeframesRequestLoading(false))
  }, [dispatch])

  const getTimeframesDataCallback = useCallback(() => {
    dispatch(getTimeFramesData(organizationId, handleGetSuccessTimeframes))
  }, [dispatch, organizationId, handleGetSuccessTimeframes])

  useEffect(() => {
    getTimeframesDataCallback()
  }, [getTimeframesDataCallback]) 

  const handleUpdateEditMode = useCallback(() => {
    setEditMode((editMode: boolean) => !editMode);
  }, [])

  const handleFormSubmitSuccess = useCallback(() => {
    getTimeframesDataCallback()
    toastNotification('timeframesform.success.creation', false)
  }, [toastNotification, getTimeframesDataCallback])

  const handleFormUpdateSuccess = useCallback(() => {
    getTimeframesDataCallback()
    toastNotification('timeframesform.success.update', false)
  }, [toastNotification, getTimeframesDataCallback])

  const handleFormUpdateError = useCallback(() => {
    toastNotification('timeframesform.error', true)
  }, [toastNotification])

  const handleFormSubmit = useCallback((data: TimeFramesFormModel) => {
    if(firstTime){
      dispatch(setTimeframesData(data.timeframe, handleFormSubmitSuccess))
    }else {
      dispatch(updateTimeframesData(data.timeframe, handleFormUpdateSuccess, handleFormUpdateError))
    }
  }, [firstTime, dispatch, handleFormSubmitSuccess, handleFormUpdateSuccess, handleFormUpdateError])

  const handleSuccessAutomaticDeactivation = useCallback(() => {
    getTimeframesDataCallback()
    toastNotification('timeframesform.success.update', false)
    setAutomaticTimeframesType(null)
    setDisableAutomaticTimeframesModal(false)
  }, [getTimeframesDataCallback, toastNotification])

  const handleSuccessAutomaticTimeframesRequest = useCallback(() => {
    getTimeframesDataCallback()
    toastNotification('timeframesform.success.update', false)
    setDisableAutomaticTimeframesModal(false)
  }, [getTimeframesDataCallback, toastNotification])

  const handleAutomaticFormSubmit = useCallback((data: AutomaticTimeframesFormModel) => {
    const parseData: SetAutomaticTimeframesRequest = { timeframe: data.timeframe.value as TIMEFRAMES_AUTOMATIC_TYPES, start_date: data.start_date.toISOString() }
    dispatch(setAutomaticTimeframesData(parseData, handleSuccessAutomaticTimeframesRequest))
  }, [dispatch, handleSuccessAutomaticTimeframesRequest])

  const handleTimeframesDeactivation = useCallback(() => {
    dispatch(disableAutomaticTimeframes(handleSuccessAutomaticDeactivation))
  }, [dispatch, handleSuccessAutomaticDeactivation])

  return(
    <div className="organization-timeframes-container">      
      {(requestLoading === false && requestError === false) &&
        <TimeFramesForm 
          initialData={initialData}
          isEditMode={editMode}
          handleFormSubmit={handleFormSubmit}
          updateEditMode={handleUpdateEditMode}
          isFirstTime={firstTime}
          isSubmitLoading={submitLoading}
        />
      }

      {(requestLoading === false) &&
        <div className='automatic-timeframes'>
          <div className='section'>
            <div onClick={(automaticCheck === true && automaticTimeframesType !== null) 
              ? () => setDisableAutomaticTimeframesModal(true) :  () => null}
            >
              <TegduCheckbox 
                value={automaticCheck}
                disabled={automaticCheck}
                onChange={() => setAutomaticCheck((value: boolean) => !value)}
                labelElement={
                  <FormattedMessage 
                    id={automaticTimeframesType === null ? "timeframesform.automatic.noselected" : "timeframesform.automatic.selected"}
                    values={{timeframe: automaticTimeframesType}}
                  />
                }
              />
            </div>
            

            {(automaticCheck === true && automaticTimeframesType === null) && 
              <AutomaticTimeframesForm 
                handleFormSubmit={handleAutomaticFormSubmit}
                isLoading={activatingLoading}
              />
            }
          </div>
        </div>
      }
     
      
      {(requestLoading === true) &&
        <TimeFramesContainerLoading />
      }

      {(requestLoading === false && requestError === true) &&
        <TegduErrorCallback 
          intlObjectId='settings.tab.timeframes'
          retryCallback={getTimeframesDataCallback}
        />
      }

      <GenericConfirmModal 
        isOpen={disableAutomaticTimeframesModal}
        messageTitleId={"timeframesform.automatic.modal.title"}
        messageParagraphId={"timeframesform.automatic.modal.paragraph"}
        confirmButtonLabel={"timeframesform.automatic.modal.confirm"}
        handleCancel={() => setDisableAutomaticTimeframesModal(false)}
        handleConfirm={handleTimeframesDeactivation}
        loading={deactivateLoading}
      />
     
    </div>
  )
}

export default OrganizationTimeFramesContainer;