import React from 'react';
import {Grid, Tooltip} from "@material-ui/core";
import './styles.scss';
import LettersCircle from "@components/Shared/LettersCircle/LettersCircle";
import {MEMBER_ROLE, MEMBER_ROLE_LABEL} from "@utils/members";

interface ObjectiveRoleComponentProps {
    name: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    isDropdwonOption?: boolean
    role: MEMBER_ROLE;
    isObjectiveView?: boolean;
}



const ObjectiveRoleComponent = (props: ObjectiveRoleComponentProps ) => {
    const {role, isObjectiveView = false}  = props
    return (
        <div className={'circle-name'}>
            <Grid container className={'circle-container'} spacing={2} data-tag='allowRowEvents'>
                <Grid item className={'circle'} md={1}>
                    <LettersCircle name={props.name} size={props.size ? props.size : 'md'} />
                </Grid>
                <Grid item className={'name'} md={8}>
                {isObjectiveView ?
                    <div className='container-objective-view'>
                        <span  data-tag='allowRowEvents'>
                            {props.name}
                        </span>
                        <span data-tag='allowRowEvents'>
                            {`${MEMBER_ROLE_LABEL[role]}`}
                        </span>
                    </div>
                    : 
                    props.isDropdwonOption ? (
                            <Tooltip title={props.name} placement={'bottom'}>
                                <div className={'intern-name'} data-tag='allowRowEvents'>
                                    <h1>{props.name}</h1>
                                    <div className={'intern-name'} data-tag='allowRowEvents'>
                                        <h1>{`${MEMBER_ROLE_LABEL[role]}`} </h1>
                                    </div>
                                </div>
                            </Tooltip>
                        )
                        :
                        (
                            <div className={'intern-name'} data-tag='allowRowEvents'>
                                {props.name}
                            </div>
                        )
                           }
                </Grid>
            </Grid>
        </div>
    );
};

export default ObjectiveRoleComponent;
