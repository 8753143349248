import React from 'react';
import OrganizationContainer from '@containers/Organization/OrganizationContainer';
import { FormattedMessage } from 'react-intl';

function Organization() {
  return (
    <div className='screen-organization'>
      <h1 className='screen-title'>
        <FormattedMessage id={'organization.title'} />
      </h1>
      <h3 className='screen-description'>
        <FormattedMessage id={'organization.description'} />
      </h3>
      <OrganizationContainer />
    </div>
  );
}

export default Organization;
