import HRTeamTabNavigation from '@components/HRTeam/HRTeamTabNavigation/HRTeamTabNavigation';
import { AppBar } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import HRTabsContainer from '../HRTabsContainer/HRTabsContainer';
import './styles.scss';

const HRTeamContainer = () =>{
  return (
    <div className='hr-container'>
        <div className="hr-title-container">
            <h1 className='screen-title'>
                <FormattedMessage id='hr.screen.title'/>
            </h1>
            <h3>
                <FormattedMessage id='hr.screen.subtitle'/>
            </h3>
        </div>
        <div className="appbar-container">
            <AppBar position='static'>
                <HRTeamTabNavigation />
            </AppBar>
            <HRTabsContainer />
        </div>
    </div>
  )
}

export default HRTeamContainer
