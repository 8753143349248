import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Switch from '@components/Shared/Switch/Switch';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PUBLIC_OBJ, UPDATE_PUBLIC_OBJ } from '@graphql/organization.queries';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';
import { ACTIONS_CATALOGS } from '@utils/objectives';

import './styles.scss';

const OkrsContainer = () => {
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const actions = useSelector((state: RootState) => state.User.Info.actions);
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const editionEnabled = useMemo(
    () =>
      actions && actions['settings/okrs']
        ? actions['settings/okrs'].some((action: any) => action.key_name === ACTIONS_CATALOGS.EDIT)
        : false,
    [actions]
  );

  const {
    data: publicObj,
    loading,
    error,
  } = useQuery(GET_PUBLIC_OBJ, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  const [changePucblicObj, { error: errorPublicObjMut, loading: loadingPublicObjMut }] =
    useMutation(UPDATE_PUBLIC_OBJ);

  const handleChange = (check: boolean) => {
    setIsPublic(check);
    changePucblicObj({
      variables: {
        organizationId: organizationId,
        isPublic: check,
      },
    });
  };

  // Update check when data is received
  useEffect(() => {
    if (publicObj && publicObj.tgdOrganization) {
      setIsPublic(publicObj.tgdOrganization.is_public_objectives);
    }
  }, [publicObj]);

  // Restore state on error
  useEffect(() => {
    if (!!errorPublicObjMut) {
      if (publicObj.tgdOrganization) {
        setIsPublic(publicObj.tgdOrganization.is_public_objectives);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPublicObjMut]);

  return (
    <div className='container-settings-okrs'>
      <p className='label'>
        <FormattedMessage id={'settings.tab.okrs.public.label'} />
      </p>
      <div className='group'>
        <p className='description'>
          <FormattedMessage id={'settings.tab.okrs.public.description'} />
        </p>
        <Switch
          checked={isPublic}
          onChange={handleChange}
          name='ispublic'
          disabled={!editionEnabled || loading || !publicObj || !!error || loadingPublicObjMut}
        />
      </div>
    </div>
  );
};

export default OkrsContainer;
