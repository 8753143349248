export type ERROR_TYPES = 'schema' | 'general';

enum TEAMS_CODE {
  DUPLICATE = 'TEAMS_DUP',
}

export const handleTeamsErrorResponse = (
  error: any
): { type: ERROR_TYPES; schema: { [field: string]: string } } => {
  let type: ERROR_TYPES = 'general';
  let schema = {};

  if (
    error.response &&
    error.response.data &&
    error.response.data.data &&
    error.response.data.data.code
  ) {
    switch (error.response.data.data.code) {
      case TEAMS_CODE.DUPLICATE:
        type = 'schema';
        schema = {
          name: error.response.data.data.message
            ? error.response.data.data.message
            : 'Team name already exists!',
        };
        break;
      default:
        break;
    }
  }

  return { type, schema };
};
