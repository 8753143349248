import ErrorIcon from '@material-ui/icons/Error';
import { ROUTE } from '@utils/routes';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import './styles.scss';

const ExpiredInvitationCodeContainer = () => {
  const history = useHistory();
  return (
    <div className='expiredinvitationcode-container'>
      <div className='expiredinvitationcode-card'>
        <img src='images/tegdu.png' alt='Tegdu' />
        <div className='expiredinvitationcode-content'>
          <div className='warning-element'>
            <ErrorIcon />
            <span>
              <FormattedMessage id='invitations.expiredcode.title' />
            </span>
          </div>

          <div className='text-info'>
            <span>
              <FormattedMessage id='invitations.expiredcode.description' />
            </span>
          </div>
        </div>
      </div>
      <div className='expiredinvitationcode-redirect'>
        <span>
          <FormattedMessage id='invitations.expiredcode.already' />
        </span>
        <span className='expiredinvitationcode-redirect-link' onClick={() => history.replace(ROUTE.login.path)}>
          <FormattedMessage id='invitations.expiredcode.login' />
        </span>
      </div>
    </div>
  );
};

export default ExpiredInvitationCodeContainer;
