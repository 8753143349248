import ObjectiveDetailsContainer from "@containers/ObjectivesDetails/ObjectiveDetailsContainer/ObjectiveDetailsContainer"

const ObjetivesDetailsScreen = () => {
  return (
    <div className="screen-details">
      <ObjectiveDetailsContainer />
    </div>
  )
}

export default ObjetivesDetailsScreen