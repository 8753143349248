import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { OKR_TYPES } from '@utils/objectives';
import './styles.scss';

interface RoundButtonProps {
  text: string;
  type: number;
  selected?: boolean;
  onButtonClick: (type: OKR_TYPES) => void;
  disabled?: boolean;
}

const TypeButtonSelector = ({ text, type, selected, onButtonClick, disabled }: RoundButtonProps) => {
  const onClick = () => {
    onButtonClick(type);
  };

  return (
    <Button
      style={{height: "32px !important", padding: "0 !important"}}
      disabled={disabled ? true : false}
      onClick={onClick}
      className={
        'secondary-gray ' + (selected ? 'objective-type-button-pwa selected' : 'objective-type-button-pwa')
      }
    >
      <FormattedMessage id={text} />
    </Button>
  );
}

export default TypeButtonSelector;
