import React from 'react';
import ProgressBar from '@components/Shared/ProgressBar/ProgressBar';
import './styles.scss';

interface ObjectiveByRoleProgressProps {
  role: string;
  objectivesCount: number;
  progress: number;
}

function ObjectiveByRoleProgress({
  role,
  objectivesCount,
  progress,
}: ObjectiveByRoleProgressProps) {
  return (
    <div className='objective_role_progress_container'>
      <div className='progress_info'>
        <label className='progress'>{objectivesCount}</label>
        <label className='role'>{role}</label>
      </div>
      <ProgressBar value={progress} thickness={6} minWidth={90} maxWidth={90} />
    </div>
  );
}

export default ObjectiveByRoleProgress;
