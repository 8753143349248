import './styles.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '@material-ui/core';
import ObjectivesTableContainer from '../ObjectivesTableContainer/ObjectivesTableContainer';
import ModalObjectiveUpdate from '@components/Objetives/ModalObjectiveUpdate/ModalObjectiveUpdate';
import { OBJECTIVE_STATUS } from '@utils/objectives';
import { getObjectives, updateObjectiveStatus } from '@services/objectives.service';
import GenericConfirmModal from '@components/Shared/GenericConfirmModal/GenericConfirmModal';
import useToastNotification from 'hooks/useToastNotification';
import ModalRateObjective from '@components/Objetives/ModalRateObjective/ModalRateObjective';
import { sendGeneralScore, sendIndividualScore } from '@services/score.service';
import { ObjectiveScore } from '@models/score.model';
import Searchbar from '@components/Shared/Searchbar/Searchbar';
import ObjectivesMainActionsModal from '@components/Objetives/CreateObjective/ObjectivesMainActionsModal/ObjectivesActionsModal';
import { CREATE_OBJECTIVES_ACTIONS } from 'lib/enums/objectives.enum';
import { ApiGetObjective } from '@models/objetives.model';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { TOUR_OBJECTIVES_TABLE } from '@samples/tour.sample';

const ObjectivesContainer = () => {
  const isTourOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const toastNotification = useToastNotification();

  /* REFACTOR ELEMENTS */
  const [objectiveId, setObjectiveId] = useState<undefined | number>(undefined);
  const [isCreateObjectiveOpen, setIsCreateObjectiveOpen] = useState<boolean>(false);
  const [objectiveModalAction, setObjectiveModalAction] = useState<CREATE_OBJECTIVES_ACTIONS>(
    CREATE_OBJECTIVES_ACTIONS.create
  );

  /* REFACTOR ELEMENTS END */

  const [ownerName, setOwnerName] = useState<undefined | string>(undefined);
  const [addProgress, setAddProgress] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [objectivesTableInfo, setObjectivesTableInfo] = useState<ApiGetObjective[]>([]);
  const [withRoleColumn, setWithRoleColumn] = useState(true);
  const [statusSelected, setStatusSelected] = useState<number>(-1);

  /* Search Bar */
  const [searchText, setSearchText] = useState<string>('');

  const [objectiveToClose, setObjectiveToClose] = useState('');
  const [showConfirmClosedModal, setShowConfirmClosedModal] = useState(false);
  // Delete objective
  const [objectiveToDelete, setObjectiveToDelete] = useState<number | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [markAsCloseLoading, setMarkAsCloseLoading] = useState(false);
  // Score modal
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);
  const [loadingGeneralRate, setLoadingGeneralRate] = useState<boolean>(false);
  const [loadingIndividualRate, setLoadingIndividualRate] = useState<boolean>(false);

  /* REFACTOR ELEMENTS */
  const handleOpenCreaeObjectiveModalAsCreate = useCallback(() => {
    setObjectiveId(undefined);
    setObjectiveModalAction(CREATE_OBJECTIVES_ACTIONS.create);
    setIsCreateObjectiveOpen(true);
  }, []);

  const handleCloseCreateObjectiveModal = useCallback(() => {
    setIsCreateObjectiveOpen(false);
  }, []);

  const handleOpenCreaeObjectiveModalAsEditOrReplicate = useCallback(
    (objectiveId: number, action: CREATE_OBJECTIVES_ACTIONS) => {
      setObjectiveId(objectiveId);
      if (action === CREATE_OBJECTIVES_ACTIONS.edit) {
        setObjectiveModalAction(CREATE_OBJECTIVES_ACTIONS.edit);
      } else {
        setObjectiveModalAction(CREATE_OBJECTIVES_ACTIONS.replicate);
      }
      setIsCreateObjectiveOpen(true);
    },
    []
  );

  /* FILL DATA TABLE */
  const getSelectedOption = useCallback(
    async (selectedOption: number) => {
      setSelectedOption(selectedOption);
      setLoadingTable(true);
      /* Set columns to table */
      setWithRoleColumn(selectedOption === 0 ? true : false);

      try {
        const response = await getObjectives(selectedOption === 0 ? undefined : selectedOption);
        if (statusSelected > 0) {
          setObjectivesTableInfo(
            response.filter((objective) => objective.status === statusSelected)
          );
        } else {
          setObjectivesTableInfo(response);
        }

        //console.log('response', response);

        setLoadingTable(false);
      } catch {
        setLoadingTable(false);
      }
    },
    [statusSelected]
  );

  /* Imported function */
  const onConfirmMarkAsClose = useCallback(async (objectiveId) => {
    setObjectiveToClose(objectiveId);
    setShowConfirmClosedModal(true);
  }, []);

  const onArchiveObjective = useCallback(
    async (objectiveId: number) => {
      try {
        await updateObjectiveStatus({
          id: objectiveId,
          status: OBJECTIVE_STATUS.archived,
        });
        toastNotification('objectiveform.success.archive', false);
      } catch {
        toastNotification('objectiveform.error', true);
      } finally {
        getSelectedOption(selectedOption);
      }
    },
    [getSelectedOption, selectedOption, toastNotification]
  );

  const onUnarchiveObjective = useCallback(
    async (objectiveId: number) => {
      try {
        await updateObjectiveStatus({
          id: objectiveId,
          status: OBJECTIVE_STATUS.active,
        });
        toastNotification('objectiveform.success.unarchive', false);
      } catch {
        toastNotification('objectiveform.error', true);
      } finally {
        getSelectedOption(selectedOption);
      }
    },
    [getSelectedOption, selectedOption, toastNotification]
  );

  /* Imported function */
  const getObjectiveActionSelected = useCallback(
    (keyName: string, objectiveId: number, ownerName: string) => {
      setObjectiveId(objectiveId);
      setOwnerName(ownerName);
      if (keyName === 'AddProgress') {
        setAddProgress(true);
      } else if (keyName === 'EditObjective') {
        handleOpenCreaeObjectiveModalAsEditOrReplicate(objectiveId, CREATE_OBJECTIVES_ACTIONS.edit);
      } else if (keyName === 'MarkClosed') {
        onConfirmMarkAsClose(objectiveId);
      } else if (keyName === 'ReplicateObjective') {
        handleOpenCreaeObjectiveModalAsEditOrReplicate(
          objectiveId,
          CREATE_OBJECTIVES_ACTIONS.replicate
        );
      } else if (keyName === 'ArchiveObjective') {
        onArchiveObjective(objectiveId);
      } else if (keyName === 'UnarchiveObjective') {
        onUnarchiveObjective(objectiveId);
      } else if (keyName === 'DeleteObjective') {
        setObjectiveToDelete(objectiveId);
        setShowConfirmDeleteModal(true);
      }
    },
    [
      handleOpenCreaeObjectiveModalAsEditOrReplicate,
      onConfirmMarkAsClose,
      onArchiveObjective,
      onUnarchiveObjective,
    ]
  );

  /* Imported function */
  const onConfirmCloseObjective = useCallback(async () => {
    setMarkAsCloseLoading(true);
    try {
      await updateObjectiveStatus({
        id: objectiveToClose,
        status: OBJECTIVE_STATUS.closed,
      });
      setMarkAsCloseLoading(false);
      setShowConfirmClosedModal(false);
      setIsRateOpen(true); // Open rate modal
      toastNotification('objectiveform.success.closed', false);
    } catch {
      setShowConfirmClosedModal(false);
      toastNotification('objectiveform.error', true);
    }
  }, [objectiveToClose, toastNotification]);

  /* Handle close for MARK AS CLOSED objectives */
  const closeMarkAsClose = useCallback(() => {
    getSelectedOption(selectedOption);
    setShowConfirmClosedModal(false);
  }, [getSelectedOption, selectedOption]);

  /* Handle close for DELETE objectives */
  const closeDeleteModal = useCallback(() => {
    setObjectiveToDelete(null);
    setShowConfirmDeleteModal(false);
  }, []);

  const onConfirmDeleteObjective = useCallback(async () => {
    if (objectiveToDelete !== null) {
      setDeleteLoading(true);
      try {
        await updateObjectiveStatus({
          id: objectiveToDelete,
          status: OBJECTIVE_STATUS.deleted,
        });
        toastNotification('objectiveform.success.delete', false);
      } catch {
        toastNotification('objectiveform.error', true);
      } finally {
        setDeleteLoading(false);
        getSelectedOption(selectedOption);
        setShowConfirmDeleteModal(false);
        setObjectiveToDelete(null);
      }
    }
  }, [getSelectedOption, objectiveToDelete, selectedOption, toastNotification]);

  // Save general score
  const handleSaveGeneralScore = useCallback(
    async (comments: string, score: number) => {
      if (objectiveId) {
        try {
          setLoadingGeneralRate(true);
          const body = {
            objective_id: objectiveId,
            comment: comments,
            score,
          };
          await sendGeneralScore(body);
          setIsRateOpen(false);
          getSelectedOption(selectedOption);
          toastNotification('score.rate.modal.success', false);
        } catch (error) {
          toastNotification('score.rate.modal.error', true);
          console.error(error);
        } finally {
          setLoadingGeneralRate(false);
        }
      }
    },
    [getSelectedOption, objectiveId, selectedOption, toastNotification]
  );

  // Save individual score
  const handleSaveIndividualScore = useCallback(
    async (scores: ObjectiveScore[]) => {
      if (objectiveId) {
        try {
          setLoadingIndividualRate(true);
          const body = {
            objective_id: objectiveId,
            scores,
          };
          await sendIndividualScore(body);
          setIsRateOpen(false);
          getSelectedOption(selectedOption);
          toastNotification('score.rate.modal.success', false);
        } catch (error) {
          toastNotification('score.rate.modal.error', true);
          console.error(error);
        } finally {
          setLoadingIndividualRate(false);
        }
      }
    },
    [getSelectedOption, objectiveId, selectedOption, toastNotification]
  );

  // Skip score
  const handleSkipScore = useCallback(() => {
    setIsRateOpen(false);
    getSelectedOption(selectedOption);
  }, [getSelectedOption, selectedOption]);

  /* Search bar */
  const onSearchTerm = useCallback((term: string) => {
    setSearchText(term);
  }, []);

  const objectivesDataTable: ApiGetObjective[] = useMemo(() => {
    if (isTourOpen) {
      return TOUR_OBJECTIVES_TABLE;
    }else if (searchText === '') {
      return objectivesTableInfo;
    } else {
      return objectivesTableInfo.filter(
        (element: any) =>
          element.name.toLowerCase().includes(searchText.toLowerCase()) ||
          element.id.toString().includes(searchText)
      );
    }
  }, [searchText, objectivesTableInfo, isTourOpen]);

  return (
    <div className='container__objectives'>
      <div className='screen-title-flex'>
        <h1>
          <FormattedMessage id='objectives.screen.title' />
        </h1>
        <Button id='btnobjective-tour' onClick={handleOpenCreaeObjectiveModalAsCreate}>
          <Icon>add</Icon>
          <FormattedMessage id={'objectives.buttonAdd.label'} />
        </Button>
      </div>

      <div className='objectives-searchbar'>
        <Searchbar
          intlId='objectives.searchbar.placeholder'
          onSearch={onSearchTerm}
          valueInput={searchText}
        />
      </div>

      <div className='objectives__table__section'>
        <ObjectivesTableContainer
          loading={loadingTable && !isTourOpen}
          objectivesTableInfo={objectivesDataTable}
          withRoleColumn={withRoleColumn}
          getObjectiveActionSelected={getObjectiveActionSelected}
          getSelectedOption={getSelectedOption}
          getStatusSelected={(statusId: number) => setStatusSelected(statusId)}
        />
      </div>

      {/* CREATE OBJECTOVES */}
      <ObjectivesMainActionsModal
        isOpen={isCreateObjectiveOpen}
        handleClose={handleCloseCreateObjectiveModal}
        action={objectiveModalAction}
        objectiveId={objectiveId}
        getObjectives={() => getSelectedOption(selectedOption)}
      />

      {/* ADD PROGRESS MODAL */}
      {objectiveId !== undefined && ownerName !== undefined && addProgress === true && (
        <ModalObjectiveUpdate
          isOpen={addProgress}
          onClose={() => setAddProgress(false)}
          id={objectiveId}
          name={ownerName}
          refetchObjectives={() => getSelectedOption(selectedOption)}
        />
      )}

      {/* CONFIRM CLOSE MODAL */}
      <GenericConfirmModal
        isOpen={showConfirmClosedModal}
        handleCancel={closeMarkAsClose}
        handleConfirm={onConfirmCloseObjective}
        messageParagraphId={'generic.confirm.modal.confirm.p'}
        messageTitleId={'objective.confirm.modal.title'}
        confirmButtonLabel={'objective.confirm.modal.button.confirm'}
        loading={markAsCloseLoading}
      />

      {/* CONFIRM DELETE */}
      <GenericConfirmModal
        isOpen={showConfirmDeleteModal}
        handleCancel={closeDeleteModal}
        handleConfirm={onConfirmDeleteObjective}
        messageParagraphId={'generic.confirm.modal.confirm.p'}
        messageTitleId={'objective.delete.confirm.modal.title'}
        confirmButtonLabel={'objective.delete.confirm.modal.button.confirm'}
        loading={deleteLoading}
      />

      {/* ADD SCORE MODAL */}
      {objectiveId !== undefined ? (
        <ModalRateObjective
          isOpen={isRateOpen}
          objectiveId={objectiveId}
          onSkip={handleSkipScore}
          onGeneralRate={handleSaveGeneralScore}
          loadingGeneralRate={loadingGeneralRate}
          onIndividualRate={handleSaveIndividualScore}
          loadingIndividualRate={loadingIndividualRate}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ObjectivesContainer;
