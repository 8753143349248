import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { Button, Icon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner/Spinner';

import './styles.scss';

interface FixedEditHeaderProps {
  refId: string;
  form: string;
  editMode: boolean;
  onCancel: () => void;
  onEdit: () => void;
  cancelDisabled: boolean | undefined;
  submitDisabled: boolean | undefined;
  loading: boolean | undefined;
}

const FixedEditHeader = ({
  refId,
  form,
  editMode,
  onCancel,
  onEdit,
  cancelDisabled,
  submitDisabled,
  loading,
}: FixedEditHeaderProps) => {
  const organizationName = useSelector(
    (state: RootState) => state.Organization.Info.data.publicName
  );
  const [fixedEdit, setFixedEdit] = useState<boolean>(false);

  const handleScroll = () => {
    const description = document.getElementById(refId);
    let descriptionLimit = 30;
    let descriptionHeight = 0;

    if (description) {
      descriptionLimit = description.getBoundingClientRect().top;
      descriptionHeight = description.getBoundingClientRect().height;
    }

    if (descriptionHeight === 0) {
      if (fixedEdit) {
        setFixedEdit(false);
      }
    } else {
      if (descriptionLimit <= 30) {
        if (!fixedEdit) {
          setFixedEdit(true);
        }
      } else {
        if (fixedEdit) {
          setFixedEdit(false);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, [fixedEdit]);

  return (
    <>
      {fixedEdit && (
        <div className='component-fixededitheader'>
          <div className='company__info'>
            <div className='logo__container'>
              <img
                className='header__logo'
                src=''
                alt=''
                // onClick={'redirect'}
              />
            </div>
            <div className='company__name'>
              <h1>{organizationName}</h1>
            </div>
          </div>
          <div className='edit-div'>
            {!editMode ? (
              <Button disabled={editMode} className='secondary' onClick={onEdit}>
                <Icon className='icon'>edit</Icon>
                <FormattedMessage id={'general.edit'} />
              </Button>
            ) : (
              <>
                <Button className='secondary' disabled={cancelDisabled} onClick={onCancel}>
                  <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button form={form} disabled={submitDisabled} type='submit'>
                  {loading && <Spinner />} <FormattedMessage id={'statement.save'} />
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FixedEditHeader;
