export const PALETTE_COLOR = {
  red: 'red',
  yellow: 'yellow',
  green: 'green',
  blue: 'blue',
  purple: 'purple',
  pink: 'pink',
};

export const PALETTE_DEFAULT_COLOR = PALETTE_COLOR.green;

export const PALETTE_CLASSNAME_REGEX = /palette-[a-z]*/;
