import React, { useCallback, useEffect, useState, useMemo } from 'react';
import FormTeam from '@components/Teams/FormTeam/FormTeam';
import { ApiTeamUser, FormTeamValues, TeamsData } from '@models/teams.model';
import GenericSuccessModal from '@components/Shared/GenericSuccessModal/GenericSuccessModal';
import { putUpdateTeam } from '@services/teams.service';
import { getMembersCatalogue } from '@services/members.service';
import { OptionsModel } from '@models/helpful.model';
import CompleteScreenModal from '@components/Shared/CompleteScreenModal/CompleteScreenModal';
import { Button } from '@material-ui/core';
import Spinner from '@components/Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { MEMBER_ROLE } from '@utils/members';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import { handleTeamsErrorResponse } from 'lib/responses/teams';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import useToastNotification from 'hooks/useToastNotification';
import _ from 'lodash';

interface TeamDetailsContainerProps {
  team: TeamsData | null;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

const TeamDetailsContainer = ({ team, open, onClose, refetch }: TeamDetailsContainerProps) => {
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const actions = useSelector((state: RootState) => state.User.Info.actions.teams);
  // let { id } = useParams<{ id: string }>();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  // const [team, setTeam] = useState<TeamsData>();
  const [memberOptions, setMemberOptions] = useState<OptionsModel[]>([]);
  const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  // const history = useHistory();
  const toastNotification = useToastNotification();

  const allowEdit = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.EDIT)) return true;
    return false;
  }, [actions]);

  const getMembers = useCallback(async () => {
    if (organizationId !== null) {
      setLoadingMembers(true);
      try {
        const members = await getMembersCatalogue(organizationId);
        setMemberOptions(members);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMembers(false);
      }
    }
  }, [organizationId]);

  useEffect(() => {
    if (open) {
      getMembers();
    }
  }, [open, getMembers]);

  const handleSubmit = useCallback(
    async (formTeam: FormTeamValues, setErrors: any) => {
      if (organizationId !== null && team) {
        let users: ApiTeamUser[] = [];
        setLoadingSubmit(true);

        // LEADER
        // When team already had a leader
        if (team.leads.length) {
          // Remove leads
          team.leads.forEach((lead) => {
            if (!formTeam.leads.includes(lead.id)) {
              users.push({
                id: lead.teamUserId,
                active: false,
              });
            }
          });

          // Add new leads
          formTeam.leads.forEach((inputLeads) => {
            let isIncluded = false;
            for (let k = 0; k < team.leads.length; k++) {
              if (inputLeads === team.leads[k].id) {
                isIncluded = true;
              }
            }

            if (isIncluded === false) {
              users.push({
                tgd_role: MEMBER_ROLE.leader,
                tgd_user: inputLeads,
              });
            }
          });

          // When team changes leader
        } else {
          // When team didn't have a leader
          if (!_.isEmpty(formTeam.leads)) {
            formTeam.leads.forEach((lead) => {
              users.push({
                tgd_role: MEMBER_ROLE.leader,
                tgd_user: lead,
              });
            });
          }
        }

        // MEMBERS
        // New members
        if (!team.members.length) {
          formTeam.members.forEach((member) => {
            users.push({
              tgd_role: MEMBER_ROLE.collaborator, // collaborator = member
              tgd_user: member,
            });
          });
        } else {
          formTeam.members.forEach((member) => {
            if (!team.members.some((currentMember) => currentMember.id === member)) {
              users.push({
                tgd_role: MEMBER_ROLE.collaborator, // collaborator = member
                tgd_user: member,
              });
            }
          });
        }
        // Remove members
        team.members.forEach((currentMember) => {
          if (!formTeam.members.includes(currentMember.id)) {
            users.push({
              id: currentMember.teamUserId,
              active: false,
            });
          }
        });

        try {
          const updatedTeam = await putUpdateTeam(organizationId, team.id, formTeam.name, users);
          if (updatedTeam && updatedTeam.alert) {
            toastNotification(updatedTeam.alert, false);
          }
          refetch();
          onClose();
          setOpenSuccessModal(true);
        } catch (err) {
          const error = handleTeamsErrorResponse(err);
          if (error.type === 'schema') {
            // Set error in Form input
            setErrors(error.schema);
          } else {
            setOpenErrorModal(true);
          }
        } finally {
          setLoadingSubmit(false);
        }
      }
    },
    [organizationId, team, refetch, onClose, toastNotification]
  );

  return (
    <>
      <CompleteScreenModal
        open={open}
        onClose={onClose}
        title={allowEdit ? 'Edit team' : 'Team details'}
        actionsButton={
          allowEdit && (
            <>
              <Button className='secondary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' disabled={saveDisabled} form='form-teamdetails'>
                {loadingSubmit && <Spinner />} Save teams
              </Button>
            </>
          )
        }
      >
        {team && (
          <FormTeam
            formId='form-teamdetails'
            team={team}
            members={memberOptions}
            onSubmit={handleSubmit}
            loadingSubmit={loadingSubmit}
            loadingMembers={loadingMembers}
            onValidate={(isValid) => setSaveDisabled(isValid)}
            viewMode={allowEdit ? false : true}
          />
        )}
      </CompleteScreenModal>
      <GenericSuccessModal
        isOpen={openSuccessModal}
        handleClose={() => setOpenSuccessModal(false)}
        messageId='general.modal.success.changes'
      />
      <GenericErrorModal
        isOpen={openErrorModal}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: 'updating', subject: 'team' },
        }}
        handleClose={() => setOpenErrorModal(false)}
      />
    </>
  );
};

export default TeamDetailsContainer;
