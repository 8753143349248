import { getTimeZoneCatalogue } from '@services/catalogs.service';
import { RootState } from '@store/rootReducer';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { OptionsModel } from '@models/helpful.model';
import {
  SetTimeZoneCatalogueBeginAction,
  SetTimeZoneCatalogueSuccessAction,
  SetTimeZoneCatalogueErrorAction,
} from './timeZone.model';

export const SET_TIMEZONE_CATALOGUE_BEGIN = 'SET_TIMEZONE_CATALOGUE_BEGIN';
export const SET_TIMEZONE_CATALOGUE_SUCCESS = 'SET_TIMEZONE_CATALOGUE_SUCCESS';
export const SET_TIMEZONE_CATALOGUE_ERROR = 'SET_TIMEZONE_CATALOGUE_ERROR';

export const setTimeZoneBegin = (): SetTimeZoneCatalogueBeginAction => ({
  type: SET_TIMEZONE_CATALOGUE_BEGIN,
});

export const setTimeZoneSuccess = (data: OptionsModel[]): SetTimeZoneCatalogueSuccessAction => ({
  type: SET_TIMEZONE_CATALOGUE_SUCCESS,
  payload: data,
});

export const setTimeZoneError = (error: any): SetTimeZoneCatalogueErrorAction => ({
  type: SET_TIMEZONE_CATALOGUE_ERROR,
  payload: error,
});

export const getTimeZoneCatalogueData = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch(setTimeZoneBegin());
  return await getTimeZoneCatalogue()
    .then((data) => {
      const options: OptionsModel[] = data.map((option) => ({
        value: option.id.toString(),
        label: option.description.replace('_', ' '),
      }));
      dispatch(setTimeZoneSuccess(options));
    })
    .catch((error) => {
      dispatch(setTimeZoneError(error));
    });
};
