import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.scss";


interface ReviewsFiltersProps {
  onSelectOption: (option: number) => void;
  optionSelected?: number;
}

const ReviewsFilters = ({
  onSelectOption,
  optionSelected,
}: ReviewsFiltersProps) => {

  return (
    <div className="container-buttons-filters-reviews">
      <Button
        onClick={() => onSelectOption(0)}
        className={
          optionSelected === 0 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="mobile.review.component.filter.button.given" />
      </Button>
      <Button
        onClick={() => onSelectOption(1)}
        className={
          optionSelected === 1 ? "button-filter-selected" : "button-filter"
        }
      >
        <FormattedMessage id="mobile.review.component.filter.button.received" />
      </Button>
    </div>
  );
};

export { ReviewsFilters as ReviewsFiltersMobile };
