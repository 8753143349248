import ObjectivesDetailsContent from '@components/Objetives/ObjectivesDetailsContent/ObjectivesDetailsContent';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { getGraphqlData } from '@services/graphql.service';
import { GET_IS_OBJECTIVES_PUBLIC } from '@graphql/organization.queries';
import {
  GET_OBJECTIVE_DETAILS,
  GET_OBJECTIVE_SCORE,
  GET_OBJECTIVE_TO_VALIDATE_BELONG,
} from '@graphql/objectives.queries';
import { ObjectiveGet, ObjectivesLogs, ObjectivesOKRReviewRate } from '@models/objetives.model';
import { ROUTE } from '@utils/routes';
import useToastNotification from 'hooks/useToastNotification';
import {
  getOwnerNameObjective,
  OBJECTIVE_STATUS,
  validateAbleToSeeObjective,
} from '@utils/objectives';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';
import ModalObjectiveUpdate from '@components/Objetives/ModalObjectiveUpdate/ModalObjectiveUpdate';
import { useQuery } from '@apollo/client';
import ModalRateObjective from '@components/Objetives/ModalRateObjective/ModalRateObjective';
import GenericConfirmModal from '@components/Shared/GenericConfirmModal/GenericConfirmModal';
import { GqlGetObjectiveScore, ObjectiveScore } from '@models/score.model';
import { updateObjectiveStatus } from '@services/objectives.service';
import { sendGeneralScore, sendIndividualScore } from '@services/score.service';
import {
  getLogs,
  getRatesOKRReviews,
} from '@store/Objectives/ObjectivesDetails/ObjectivesDetails.action';
import { useDispatch } from 'react-redux';
import ObjectivesMainActionsModal from '@components/Objetives/CreateObjective/ObjectivesMainActionsModal/ObjectivesActionsModal';
import { CREATE_OBJECTIVES_ACTIONS } from 'lib/enums/objectives.enum';
import { generatePath } from 'react-router-dom';

const ObjectiveDetailsContainer = () => {
  const objectiveId: { id: string } = useParams();
  const history = useHistory();
  const toastNotification = useToastNotification();
  const userId = useSelector((state: RootState) => state.User.Info.user.id);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const [requestloading, setRequestLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<ObjectiveGet | null>(null);
  const [addProgress, setAddProgress] = useState<boolean>(false);

  /* OBJECTIVES REFACTOR */
  const [isCreateObjectiveOpen, setIsCreateObjectiveOpen] = useState<boolean>(false);
  const [objectiveModalAction, setObjectiveModalAction] = useState<CREATE_OBJECTIVES_ACTIONS>(
    CREATE_OBJECTIVES_ACTIONS.create
  );

  /* status */
  const [objectiveToClose, setObjectiveToClose] = useState('');
  const [showConfirmClosedModal, setShowConfirmClosedModal] = useState(false);
  const [markAsCloseLoading, setMarkAsCloseLoading] = useState(false);
  // Delete objective
  const [objectiveToDelete, setObjectiveToDelete] = useState<number | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  // Score modal
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);
  const [loadingGeneralRate, setLoadingGeneralRate] = useState<boolean>(false);
  const [loadingIndividualRate, setLoadingIndividualRate] = useState<boolean>(false);
  const [fromMarkAsClose, setFromMarkAsClose] = useState<boolean>(false)
  // Objective to manipulate
  const [dataOKRReviews, setDataOKRReviews] = useState<ObjectivesOKRReviewRate>();
  const [dataLogs, setDataLogs] = useState<ObjectivesLogs[]>();
  const dispatch = useDispatch();
  // Objective score
  const {
    // data: objectiveScore,
    // loading: loadingObjectiveScore,
    refetch: refetchObjectiveScore,
  } = useQuery<GqlGetObjectiveScore>(GET_OBJECTIVE_SCORE, {
    variables: { objectiveId: objectiveId.id },
    fetchPolicy: 'no-cache',
  });

  /* OBJECTIVES REFACTOR */
  const handleCloseCreateObjectiveModal = useCallback(() => {
    setIsCreateObjectiveOpen(false);
  }, []);

  const handleOpenCreaeObjectiveModalAsEditOrReplicate = useCallback(
    (action: CREATE_OBJECTIVES_ACTIONS) => {
      if (action === CREATE_OBJECTIVES_ACTIONS.edit) {
        setObjectiveModalAction(CREATE_OBJECTIVES_ACTIONS.edit);
      } else {
        setObjectiveModalAction(CREATE_OBJECTIVES_ACTIONS.replicate);
      }
      setIsCreateObjectiveOpen(true);
    },
    []
  );

  /* ASYNC REDIRECT */
  const redirectToMainObjectivesScreen = useCallback(async () => {
    history.push(ROUTE.objetives.path);
  }, [history]);

  /*ASYNC REDIRECT TO OBJECTIVE ID */
  const redirectToObjectiveScreen = useCallback(async () => {
    history.push(ROUTE.objetives.path);
    history.push(generatePath(ROUTE.objectivesDetail.path, { id: objectiveId.id }));
  }, [history, objectiveId.id]);

  const getIsObjectivesPublic = useCallback(async () => {
    setRequestLoading(true);
    try {
      const response = await getGraphqlData(GET_IS_OBJECTIVES_PUBLIC, {
        organizationId: organizationId,
      });
      if (response.tgdOrganization.response === true) {
        try {
          const response = await getGraphqlData(GET_OBJECTIVE_DETAILS, {
            objectiveId: objectiveId.id,
          });
          setData(response.tgdObjective);
          dispatch(getRatesOKRReviews(parseInt(objectiveId.id), getDataOKRSuccess));
        } catch {
          await redirectToMainObjectivesScreen();
          toastNotification('objectiveform.error', true);
          setError(true);
        }
        setRequestLoading(false);
      } else {
        const response = await getGraphqlData(GET_OBJECTIVE_TO_VALIDATE_BELONG, {
          objectiveId: objectiveId.id,
        });
        const isAbleToSeeInfo = validateAbleToSeeObjective(
          response.tgdObjective,
          userId,
          response.tgdObjective.status.id
        );
        if (isAbleToSeeInfo) {
          try {
            const response = await getGraphqlData(GET_OBJECTIVE_DETAILS, {
              objectiveId: objectiveId.id,
            });
            setData(response.tgdObjective);
            dispatch(getRatesOKRReviews(parseInt(objectiveId.id), getDataOKRSuccess));
          } catch {
            await redirectToMainObjectivesScreen();
            toastNotification('objectiveform.error', true);
            setError(true);
          }
        } else {
          await redirectToMainObjectivesScreen();
          toastNotification('objectiveform.error', true);
          setError(true);
        }
        setRequestLoading(false);
      }
    } catch {
      await redirectToMainObjectivesScreen();
      toastNotification('objectiveform.error', true);
    }
  }, [
    organizationId,
    objectiveId.id,
    dispatch,
    redirectToMainObjectivesScreen,
    toastNotification,
    userId,
  ]);

  useEffect(() => {
    getIsObjectivesPublic();
  }, [getIsObjectivesPublic]);

  const ownerName = getOwnerNameObjective(
    data !== null && data !== undefined ? data.tgd_obj_role_users : undefined
  );
  const getDataOKRSuccess = (data: ObjectivesOKRReviewRate) => {
    if (JSON.stringify(data) !== '{}') {
      setDataOKRReviews(data);
    }
  };
  const getDataLogsSuccess = (data: ObjectivesLogs[]) => {
    setDataLogs(data);
  };

  const dataObjectivesLogs = useCallback(
    (objectiveID: number) => {
      dispatch(getLogs(objectiveID, getDataLogsSuccess));
    },
    [dispatch]
  );

  /* Callback to MASK AS CLOSE an objective */
  const onConfirmMarkAsClose = useCallback(async (objectiveId) => {
    setObjectiveToClose(objectiveId);
    setIsRateOpen(true);
    setFromMarkAsClose(true)
  }, []);

  const onArchiveObjective = useCallback(
    async (objectiveId: number) => {
      try {
        await updateObjectiveStatus({
          id: objectiveId,
          status: OBJECTIVE_STATUS.archived,
        });
        toastNotification('objectiveform.success.archive', false);
        await redirectToMainObjectivesScreen();
      } catch {
        toastNotification('objectiveform.error', true);
      }
    },
    [redirectToMainObjectivesScreen, toastNotification]
  );

  const onUnarchiveObjective = useCallback(
    async (objectiveId: number) => {
      try {
        await updateObjectiveStatus({
          id: objectiveId,
          status: OBJECTIVE_STATUS.active,
        });
        toastNotification('objectiveform.success.unarchive', false);
        await redirectToMainObjectivesScreen();
      } catch {
        toastNotification('objectiveform.error', true);
      }
    },
    [redirectToMainObjectivesScreen, toastNotification]
  );

  /* Callback to execute an action according to the keyName selected+*/
  const getActionSelected = useCallback(
    (keyName: string, objectiveId: number) => {
      if (keyName === 'AddProgress') {
        setAddProgress(true);
      } else if (keyName === 'EditObjective') {
        handleOpenCreaeObjectiveModalAsEditOrReplicate(CREATE_OBJECTIVES_ACTIONS.edit);
      } else if (keyName === 'MarkClosed') {
        onConfirmMarkAsClose(objectiveId);
      } else if (keyName === 'ReplicateObjective') {
        handleOpenCreaeObjectiveModalAsEditOrReplicate(CREATE_OBJECTIVES_ACTIONS.replicate);
      } else if (keyName === 'ArchiveObjective') {
        onArchiveObjective(objectiveId);
      } else if (keyName === 'UnarchiveObjective') {
        onUnarchiveObjective(objectiveId);
      } else if (keyName === 'DeleteObjective') {
        setObjectiveToDelete(objectiveId);
        setShowConfirmDeleteModal(true);
      } else if (keyName === 'ReviewObjective') {
        setIsRateOpen(true); // Open rate modal
      }
    },
    [
      onArchiveObjective,
      onConfirmMarkAsClose,
      onUnarchiveObjective,
      handleOpenCreaeObjectiveModalAsEditOrReplicate,
    ]
  );

  /* Function to close the objective */
  const onConfirmCloseObjective = useCallback(async () => {
    setMarkAsCloseLoading(true);
    try {
      await updateObjectiveStatus({
        id: objectiveToClose,
        status: OBJECTIVE_STATUS.closed,
      });
      setShowConfirmClosedModal(false);
      toastNotification('objectiveform.success.closed', false);
      getIsObjectivesPublic();
      setFromMarkAsClose(false)
      await redirectToObjectiveScreen();
    } catch {
      setShowConfirmClosedModal(false);
      toastNotification('objectiveform.error', true);
    }
  }, [getIsObjectivesPublic, objectiveToClose, redirectToObjectiveScreen, toastNotification]);

  /* Handle close for MARK AS CLOSED objectives */
  const closeMarkAsClose = useCallback(() => {
    setShowConfirmClosedModal(false);
  }, []);

  /* Handle close for DELETE objectives */
  const closeDeleteModal = useCallback(() => {
    setObjectiveToDelete(null);
    setShowConfirmDeleteModal(false);
  }, []);

  const onConfirmDeleteObjective = useCallback(async () => {
    if (objectiveToDelete !== null) {
      setDeleteLoading(true);
      try {
        await updateObjectiveStatus({
          id: objectiveToDelete,
          status: OBJECTIVE_STATUS.deleted,
        });
        await redirectToMainObjectivesScreen();
        toastNotification('objectiveform.success.delete', false);
      } catch {
        toastNotification('objectiveform.error', true);
      } finally {
        setDeleteLoading(false);
        setShowConfirmDeleteModal(false);
        setObjectiveToDelete(null);
      }
    }
  }, [objectiveToDelete, redirectToMainObjectivesScreen, toastNotification]);

  // Save general score
  const handleSaveGeneralScore = useCallback(
    async (comments: string, score: number) => {
      try {
        setLoadingGeneralRate(true);
        const body = {
          objective_id: objectiveId.id,
          comment: comments,
          score,
        };
        await sendGeneralScore(body);
        setIsRateOpen(false);
        toastNotification('score.rate.modal.success', false);
        refetchObjectiveScore({ objectiveId: objectiveId.id });
        dispatch(getRatesOKRReviews(parseInt(objectiveId.id), getDataOKRSuccess));
        if (fromMarkAsClose) {
          setShowConfirmClosedModal(true);
          setFromMarkAsClose(false);
        }else{
          await redirectToObjectiveScreen();
        }
      } catch (error) {
        toastNotification('score.rate.modal.error', true);
        console.error(error);
      } finally {
        setLoadingGeneralRate(false);
      }
    },
    [objectiveId.id, toastNotification, refetchObjectiveScore, dispatch, fromMarkAsClose, redirectToObjectiveScreen]
  );

  // Save individual score
  const handleSaveIndividualScore = useCallback(
    async (scores: ObjectiveScore[]) => {
      try {
        setLoadingIndividualRate(true);
        const body = {
          objective_id: objectiveId.id,
          scores,
        };
        await sendIndividualScore(body);
        setIsRateOpen(false);
        toastNotification('score.rate.modal.success', false);
        refetchObjectiveScore({ objectiveId: objectiveId.id });
        dispatch(getRatesOKRReviews(parseInt(objectiveId.id), getDataOKRSuccess));
        if (fromMarkAsClose) {
          setShowConfirmClosedModal(true);
          setFromMarkAsClose(false);
        }else{
          await redirectToObjectiveScreen();
        }
      } catch (error) {
        toastNotification('score.rate.modal.error', true);
        console.error(error);
      } finally {
        setLoadingIndividualRate(false);
      }
    },
    [objectiveId.id, toastNotification, refetchObjectiveScore, dispatch, fromMarkAsClose, redirectToObjectiveScreen]
  );

  // Skip score
  const handleSkipScore = useCallback(() => {
    setIsRateOpen(false);
    if (fromMarkAsClose) setShowConfirmClosedModal(true);
  }, [fromMarkAsClose]);

  useEffect(() => {
    if (!!objectiveId.id) {
      dataObjectivesLogs(parseInt(objectiveId.id));
    }
  }, [dataObjectivesLogs, objectiveId.id]);

  const handlerScoreShowModal = useCallback((objectiveId: number) => {
    setIsRateOpen(true);
  }, []);
  return (
    <>
      <ObjectivesDetailsContent
        getActionSelected={getActionSelected}
        error={error}
        statusID={data?.status.id}
        loading={requestloading}
        data={data}
        valueId={objectiveId.id}
        dataLogs={dataLogs}
        dataOKRReviews={dataOKRReviews}
        onReviewObjectiveOKR={() => setIsRateOpen(true)}
        onActionModalProgress={() => setAddProgress(true)}
      />
      {/* ADD PROGRESS MODAL */}
      {objectiveId !== undefined && ownerName !== undefined && addProgress === true && (
        <ModalObjectiveUpdate
          isOpen={addProgress}
          showScoreModal={handlerScoreShowModal}
          onClose={() => setAddProgress(false)}
          id={parseInt(objectiveId.id)}
          name={ownerName}
          refetchObjectives={() => history.push(ROUTE.objetives.path)}
        />
      )}

      {/* CREATE OBJECTIVES */}
      <ObjectivesMainActionsModal
        isOpen={isCreateObjectiveOpen}
        handleClose={handleCloseCreateObjectiveModal}
        action={objectiveModalAction}
        objectiveId={parseInt(objectiveId.id)}
        getObjectives={redirectToMainObjectivesScreen}
      />

      {/* CONFIRM CLOSE MODAL */}
      <GenericConfirmModal
        isOpen={showConfirmClosedModal}
        handleCancel={closeMarkAsClose}
        handleConfirm={onConfirmCloseObjective}
        messageParagraphId={'generic.confirm.modal.confirm.p'}
        messageTitleId={'objective.confirm.modal.title'}
        confirmButtonLabel={'objective.confirm.modal.button.confirm'}
        loading={markAsCloseLoading}
      />

      {/* CONFIRM DELETE */}
      <GenericConfirmModal
        isOpen={showConfirmDeleteModal}
        handleCancel={closeDeleteModal}
        handleConfirm={onConfirmDeleteObjective}
        messageParagraphId={'generic.confirm.modal.confirm.p'}
        messageTitleId={'objective.delete.confirm.modal.title'}
        confirmButtonLabel={'objective.delete.confirm.modal.button.confirm'}
        loading={deleteLoading}
      />

      {/* ADD SCORE MODAL */}
      <ModalRateObjective
        isOpen={isRateOpen}
        objectiveId={objectiveId.id}
        onSkip={handleSkipScore}
        onGeneralRate={handleSaveGeneralScore}
        loadingGeneralRate={loadingGeneralRate}
        onIndividualRate={handleSaveIndividualScore}
        loadingIndividualRate={loadingIndividualRate}
      />
    </>
  );
};

export default ObjectiveDetailsContainer;
