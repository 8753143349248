import { gql } from '@apollo/client';
import { MEMBER_ROLE } from '@utils/members';

export const GET_TEAMS = gql`
  query GetTeams($organizationId: ID!) {
    tgdTeams(where: { tgd_organization: { id: $organizationId } },sort: "created_at:desc") {
      id
      name
      created_at
      leader: tgd_team_role_users(where: { tgd_role: { id: ${MEMBER_ROLE.leader} }, active: true }) {
        teamUserId: id
        user {
          id
          first_name
          last_name
        }
      }
      members: tgd_team_role_users(where: { tgd_role: { id: ${MEMBER_ROLE.collaborator} }, active: true },sort: "user:asc") {
        teamUserId: id
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

//  where: { tgd_organization: { id: $organizationId }, tgd_objective_teams: { active: 1 } }
export const GET_ACTIVE_TEAMS = gql`
  query GetActiveTeams($organizationId: ID!) {
    tgdTeams(
      where: { tgd_organization: { id: $organizationId }}
    ) {
      id
      name
    }
  }
`;

export const GET_TEAMS_CATALOG = gql`
  query GetTeamsCatalog($organizationId: ID!) {
    tgdTeams(where: { tgd_organization: { id: $organizationId } }) {
      value: id
      label: name
    }
  }
`;
