import './styles.scss';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CompleteScreenModal from '@components/Shared/CompleteScreenModal/CompleteScreenModal';
import { Button, Grid } from '@material-ui/core';
import LettersCircle from '@components/Shared/LettersCircle/LettersCircle';
import { FormattedMessage } from 'react-intl';
import ProgressBar from '@components/Shared/ProgressBar/ProgressBar';
import { getObjectiveById, updateKeyResults } from '@services/objectives.service';
import Spinner from '@components/Shared/Spinner/Spinner';
import {
  FormikUpdateKeyResultsObject,
  KeyResultsUpdate,
  ParseUpdateKeyResult,
  UpdateKeyResults,
} from '@models/objetives.model';
import UpdateObjectiveForm from '../UpdateObjectiveForm/UpdateObjectiveForm';
import { calculateGeneralProgress } from '@utils/krProgress';
import useToastNotification from 'hooks/useToastNotification';
import { truncNumber } from '@utils/numbers';

export interface ModalObjectiveUpdateProps {
  isOpen: boolean;
  id: number;
  name: string;
  onClose: () => void;
  refetchObjectives: () => void;
  showScoreModal?: (id: number) => void;
}

const ModalObjectiveUpdate = (props: ModalObjectiveUpdateProps) => {
  const today = useMemo(() => moment().format('YYYY-MM-DD'), []);
  const { onClose, showScoreModal, id } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [objective, setObjective] = useState<UpdateKeyResults | null>(null);
  const [savingData, setSavingData] = useState(false);
  const [formValues, setFormValues] = useState<any[]>([]);
  const [generalProgress, setGeneralProgress] = useState<number>(0.0);
  const [isValidForm, setIsValidForm] = useState(false);
  const showToastNotification = useToastNotification();
  const [formChanged, setFormChanged] = useState(false);
  const handleFormChange = () => {
    setFormChanged(true);
  };

  const getObjectiveData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getObjectiveById(props.id);
      setObjective(data.tgdObjective);
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  }, [props.id]);
  const keyResults: ParseUpdateKeyResult = useMemo(() => {
    if (objective !== null) {
      const parsed_key_results: ParseUpdateKeyResult = { key_results: [] };
      for (let k = 0; k < objective.tgd_key_results.length; k++) {
        parsed_key_results.key_results.push({
          id: objective.tgd_key_results[k].id,
          name: objective.tgd_key_results[k].name,
          description: objective.tgd_key_results[k].description,
          init_value: objective.tgd_key_results[k].init_value,
          target_value: objective.tgd_key_results[k].target_value,
          current_progress: objective.tgd_key_results[k].current_progress,
          current_value: objective.tgd_key_results[k].current_value,
          unit: objective.tgd_key_results[k].unit
            ? parseInt(objective.tgd_key_results[k].unit.id)
            : 1,
          new_progress: '0.0',
        });
      }
      return parsed_key_results;
    } else {
      return { key_results: [] };
    }
  }, [objective]);
  const onSubmitForm = useCallback(
    async (info: any) => {
      const new_values: FormikUpdateKeyResultsObject[] = info.keyResults;
      const key_results: KeyResultsUpdate[] = info.key_results;
      if (info.keyResults !== undefined) {
        setSavingData(true);
        try {
          await updateKeyResults(new_values, key_results);
          onClose();
          if (showScoreModal !== undefined) {
            showScoreModal(id);
          }
          showToastNotification('general.modal.success.changes', false);
          setSavingData(false);
        } catch {
          onClose();
          showToastNotification('general.modal.error.label', true);
          setSavingData(false);
        }
      }
    },
    [onClose, showToastNotification, id, showScoreModal]
  );

  useEffect(() => {
    if (props.id !== null) {
      getObjectiveData();
    }
  }, [getObjectiveData, props.id]);

  const fixedKeyResultValues = useMemo(() => {
    const values = [];
    const onlyValues = [];
    if (keyResults !== undefined && objective !== null) {
      if (keyResults.key_results.length !== 0) {
        for (let k = 0; k < keyResults.key_results.length; k++) {
          values.push({
            init_value: objective.tgd_key_results[k].init_value,
            target_value: objective.tgd_key_results[k].target_value,
            current_progress: objective.tgd_key_results[k].current_progress,
            current_value:
              objective.tgd_key_results[k].current_value === null
                ? objective.tgd_key_results[k].init_value
                : objective.tgd_key_results[k].current_value,
          });

          onlyValues.push(
            objective.tgd_key_results[k].current_value === null
              ? objective.tgd_key_results[k].init_value
              : objective.tgd_key_results[k].current_value
          );
        }
      }
      return values;
    } else {
      return [];
    }
  }, [keyResults, objective]);

  const getFormValues = useCallback((info: any) => {
    setFormValues([...info]);
  }, []);

  const initialProgress = useMemo(() => {
    if (keyResults !== undefined && keyResults.key_results !== undefined) {
      let progress = 0.0;
      for (let k = 0; k < keyResults.key_results.length; k++) {
        progress = progress + keyResults.key_results[k].current_progress;
      }

      progress = progress / keyResults.key_results.length;

      return progress;
    } else {
      return 0.0;
    }
  }, [keyResults]);

  const updateProgress = useMemo(() => {
    if (formValues.length !== 0) {
      let updatedValues = [];

      for (let k = 0; k < keyResults.key_results.length; k++) {
        updatedValues.push({
          init_value: keyResults.key_results[k].init_value,
          target_value: keyResults.key_results[k].target_value,
          current_value: formValues[k].current_val,
        });
      }

      return calculateGeneralProgress(updatedValues);
    } else {
      return 0.0;
    }
  }, [keyResults, formValues]);

  const getIsValidForm = useCallback((info: boolean) => {
    setIsValidForm(info);
  }, []);

  useEffect(() => {
    if (formValues.length !== 0) {
      setGeneralProgress(updateProgress);
    } else {
      setGeneralProgress(initialProgress);
    }
  }, [formValues, initialProgress, updateProgress]);

  const disabledBtn = useMemo(() => {
    if (formChanged === true && isValidForm === true) {
      return false;
    } else {
      return true;
    }
  }, [isValidForm, formChanged]);

  return (
    <>
      <CompleteScreenModal
        open={props.isOpen}
        onClose={props.onClose}
        title={'Add progress'}
        actionsButton={
          <React.Fragment>
            <Button
              className='secondary'
              onClick={props.onClose}
              disabled={keyResults.key_results.length === 0 ? true : false}
            >
              <FormattedMessage id={'objectiveform.cancel'} />
            </Button>

            <Button
              form={'form-update-kr'}
              type='submit'
              disabled={savingData === true ? savingData : disabledBtn}
            >
              {savingData === true && <Spinner color='white' size={15} thickness={5} />}
              <FormattedMessage id={'statement.save'} />
            </Button>
          </React.Fragment>
        }
      >
        {/* -- IF LOADING -- */}
        {loading === true && error === false && objective !== null && (
          <div className='screen-loader'>
            <Spinner size={50} thickness={3} color='primary' />
          </div>
        )}

        {/* -- IF NOT ERROR -- */}
        {loading === false && error === false && objective !== null && (
          <div className='modal-objective-update'>
            <div className='content'>
              {/* OBJECTIVE NAME */}
              <Grid container className={'title-top'}>
                <Grid item className={'title-item'} md={12}>
                  <div className={'logo'}>
                    <LettersCircle name={props.name} size={'lg'} />
                  </div>
                </Grid>
                <Grid item className={'title-text'} md={12}>
                  <p>{objective.name}</p>
                </Grid>
              </Grid>

              {/* OBJECTIVE PROGRESS AND DUE DATE */}
              <Grid container className={'progress-info'}>
                <Grid container className={'data-actions'}>
                  <Grid item className={'data'} md={12}>
                    <p>
                      <span>
                        <FormattedMessage id={'objectives.modal.detail.date.label'} />
                      </span>
                      <span className={objective.due_date <= today ? 'out' : ''}>
                        {moment(objective.due_date).format('MMMM DD')}
                      </span>
                    </p>
                    <p>
                      <span>
                        <FormattedMessage id={'objectives.modal.detail.progress.label'} />
                      </span>
                      {truncNumber(generalProgress)}
                    </p>
                  </Grid>
                </Grid>
                <Grid item className={'bar'} md={12}>
                  <ProgressBar value={generalProgress} thickness={12} labelLeft={false} />
                </Grid>
              </Grid>
              {/* IF NO KEY RESULTS */}
              {keyResults.key_results.length === 0 && (
                <p id='no-key-results'>
                  <FormattedMessage id={'objectives.modal.detail.noKr'} />
                </p>
              )}

              {/* UPDATE OBJECTIVES FORM */}
              <UpdateObjectiveForm
                keyResults={keyResults}
                initialValues={fixedKeyResultValues}
                getFormValues={getFormValues}
                idForm='form-update-kr'
                onSubmitForm={onSubmitForm}
                getIsValidForm={getIsValidForm}
                handleFormChange={handleFormChange}
              />
            </div>
          </div>
        )}
        {/* IF ERROR ... */}
      </CompleteScreenModal>
    </>
  );
};

export default ModalObjectiveUpdate;
