import { Grid } from '@material-ui/core';
import { Formik, FieldArray, Form } from 'formik';
import { UpdateObjectiveSchema } from '@formsValidations/formsValidations';
import FormikTextDate from '@components/FormikComponents/FormikTextDate/FormikTextDate';
import FormikListener from '@components/FormikComponents/FormikListener/ForrmikListener';
import { ParseUpdateKeyResult } from '@models/objetives.model';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMemo } from 'react';
import { calculateKrProgress } from '@utils/krProgress';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';
import withDateFastField from '@hocs/withDateFastField';
import InlineKeyResult from '../InlineKeyResult/InlineKeyResult';

import './styles.scss';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput);
const FormikTextDateFastField = withDateFastField(FormikTextDate);

interface UpdateObjectiveFormProps {
  idForm: string;
  initialValues: any[];
  keyResults: ParseUpdateKeyResult;
  getFormValues: (values: any) => void;
  getIsValidForm: (value: boolean) => any;
  onSubmitForm: (values: any) => any;
  handleFormChange: () => any;
}

const UpdateObjectiveForm = (props: UpdateObjectiveFormProps) => {
  const { initialValues, getFormValues, getIsValidForm } = props;
  const kr_currentValues = useMemo(() => {
    if (initialValues !== undefined && initialValues.length !== 0) {
      const currentValues = [];
      for (let k = 0; k < initialValues.length; k++) {
        currentValues.push({
          current_val: initialValues[k].current_value,
        });
      }

      return currentValues;
    } else {
      return [];
    }
  }, [initialValues]);

  const getValues = useCallback(
    (info: any) => {
      if (info.keyResults !== undefined) {
        for (let k = 0; k < info.keyResults.length; k++) {
          if (info.keyResults[k] !== undefined) {
            if (info.keyResults[k].new_progress.length !== 0) {
              kr_currentValues[k].current_val = parseFloat(info.keyResults[k].new_progress);
            } else {
              kr_currentValues[k].current_val = parseFloat(initialValues[k].current_value);
            }
          }
        }
        getFormValues(kr_currentValues);
      }
    },
    [initialValues, kr_currentValues, getFormValues]
  );

  const getIsValid = useCallback(
    (info: any) => {
      getIsValidForm(info);
    },
    [getIsValidForm]
  );

  return (
    <Grid container className='key-result'>
      <Formik
        initialValues={props.keyResults}
        validationSchema={UpdateObjectiveSchema}
        onSubmit={props.onSubmitForm}
      >
        {(formik) => {
          return (
            <Form id={props.idForm} onChange={props.handleFormChange}>
              <FormikListener getValues={getValues} getIsValid={getIsValid} />
              <FieldArray
                name='keyResults'
                render={() => {
                  return (
                    <>
                      {formik.values.key_results !== undefined &&
                        formik.values.key_results.length > 0 &&
                        formik.values.key_results.map((keyResult: any, index: any) => {
                          return (
                            <Grid container key={`key-results-grid.${index}`}>
                              <Grid item md={12} className='key-detail'>
                                <InlineKeyResult index={index}
                                  name={keyResult.name}
                                  description={keyResult.description}
                                  progress={calculateKrProgress(
                                    kr_currentValues[index].current_val,
                                    initialValues[index].init_value,
                                    initialValues[index].target_value
                                  )}
                                  showDescription />
                              </Grid>
                              <Grid item md={2} className='key-info'>
                                <p>
                                  <FormattedMessage
                                    id={'objectives.update.progress.form.initial.value'}
                                  />
                                </p>
                                <div className={'text-value'}>
                                  <p>{keyResult.init_value}</p>
                                </div>
                              </Grid>

                              <Grid item md={2} className='key-info'>
                                <p>
                                  <FormattedMessage
                                    id={'objectives.update.progress.form.target.value'}
                                  />
                                </p>
                                <div className={'text-value'}>
                                  <p>{keyResult.target_value}</p>
                                </div>
                              </Grid>

                              <Grid item md={4} className='key-info'>
                                <p>
                                  <FormattedMessage
                                    id={'objectives.update.progress.form.current.progress'}
                                  />
                                </p>
                                <FormikTextInputFastField
                                  name={`keyResults.${index}.new_progress`}
                                  viewMode={false}
                                  adornment={keyResult.unit}
                                  maxWidth='90%'
                                  charsLimit={9}
                                  placeholder='objectives.modal.update.progress'
                                  placeholderValues={{
                                    value:
                                      keyResult.current_value === null
                                        ? keyResult.init_value
                                        : keyResult.current_value,
                                  }}
                                  hideCharsLimitDescription
                                  validateOnClick
                                />
                              </Grid>

                              <Grid item md={4} className='key-info'>
                                <p>
                                  <FormattedMessage id={'objectives.update.progress.form.date'} />
                                </p>
                                {/* <FormikTextDate
                                  name={`keyResults.${index}.update_date`}
                                  specialViewMode={true}
                                /> */}
                                <FormikTextDateFastField
                                  name={`keyResults.${index}.update_date`}
                                  viewMode={false}
                                  specialViewMode={true}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                    </>
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default UpdateObjectiveForm;
