

export enum CULTURAL_ATTRIBUTES_TYPES {
  pillar = "pillar",
  principle = "principle",
  value = "value"
}

export const CULTURAL_ATTRIBUTES_DESCRIPTION = {
  [`${CULTURAL_ATTRIBUTES_TYPES.pillar}`]: "organization.pillar.pillar",
  [`${CULTURAL_ATTRIBUTES_TYPES.principle}`]: "organization.pillar.principle",
  [`${CULTURAL_ATTRIBUTES_TYPES.value}`]: "organization.pillar.value",
}