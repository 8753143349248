import React from 'react';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './styles.scss';
import { FormattedMessage } from 'react-intl';

interface GenericSuccessModalProps {
  isOpen: boolean;
  handleClose: () => void;
  messageId: string;
  confirmButtonLabel?: string;
}

const GenericSuccessModal = (props: GenericSuccessModalProps) => {
  return (
    <Dialog
      open={props.isOpen}
      className={'generic-success-modal'}
      onClose={props.handleClose}
      disableBackdropClick
    >
      <DialogContent className={'success-modal-content'}>
        <Grid container className={'success-modal-container'}>
          <Grid item xs={12} className={'icon'}>
            <div className={'div-icon'}>
              <CheckCircleIcon />
            </div>
          </Grid>
          <Grid item xs={12} className={'message'}>
            <p>{props.messageId && <FormattedMessage id={props.messageId} />}</p>
          </Grid>
          <Grid item xs={12} className='bottom'>
            <hr />
            <div className='footer'>
              <Button className='modal-button' onClick={props.handleClose}>
                <FormattedMessage id={props.confirmButtonLabel ? props.confirmButtonLabel : 'modal.success.button.label'} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GenericSuccessModal;
