import { UpdateGeneralProgress } from "@models/objetives.model";

export const calculateKrProgress = (current_value: number, initial_value: number, target_value: number) : number => {
   // TODO: Commented for TEGWEB-172
   // const krProgress = (current_value - initial_value) / (target_value - initial_value)!;
   let krProgress = 0
   if (target_value === initial_value) {
      krProgress = current_value > target_value ? 1 : current_value < initial_value ? 0 : 1;
   } else {
      krProgress = (current_value - initial_value) / (target_value - initial_value)!;
   }
   
   if(isNaN(krProgress)){      
      return 0;      
   }else{
      if(krProgress < 0){
         return 0
      }else{
         if(krProgress > 1){
            return 1
         }else{
            return krProgress
         }
      }
      
   }
}

export const calculateGeneralProgress = (values: UpdateGeneralProgress []): number => {
   let progress = 0.0
   let individualProgress: number[] = []
   for(let k = 0; k < values.length; k++){
      individualProgress.push(calculateKrProgress(values[k].current_value, values[k].init_value, values[k].target_value))
   }

   for(let k = 0; k < values.length; k++){
      progress = progress + individualProgress[k]
   }

   progress = progress / values.length

   return progress
}