import './styles.scss'
import React, { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { Button, Grid, Icon } from '@material-ui/core'
import CurrentPassword from '@components/Account/CurrentPassword/CurrentPassword'
import UpdatePasswordContainer from '../UpdatePasswordContainer/UpdatePasswordContainer'
import Spinner from '@components/Shared/Spinner/Spinner'
import GenericSuccessModal from '@components/Shared/GenericSuccessModal/GenericSuccessModal'
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal'
import { resetPassword } from '@services/login.service'
import { UpdatePasswordStructure } from '@components/Account/UpdatePasswordForm/UpdatePasswordForm'

const SecurityContainer = () => {
  const [viewMode, setViewMode] = useState(true);
  const [validForm, setValidForm] = useState(false)
  const [values, setValues] = useState<UpdatePasswordStructure>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [sucessModal, setSucessModal] = useState(false);

  const getIsValid = useCallback((value: boolean) => {
    setValidForm(value)
  }, [])

  const handleSave = useCallback(async () => {
    if (values && validForm) {
      try {
        setLoadingSubmit(true)
        await resetPassword(values)
        setSucessModal(true)
        setViewMode(true)
      } catch {
        setLoadingSubmit(false)
        setErrorModal(true)
      }
    }
  }, [values, validForm])

  useEffect(() => {
    if (viewMode) {
      setLoadingSubmit(false)
    }
  }, [viewMode])

  return (
    <div className='security-container'>
      <div className='description-row'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <div className='description'>
              <p>
                <FormattedMessage id='account.screen.tab.security.description' />
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className='buttons'>
              {viewMode === true ?
                <div className='buttons-container'>
                  <Button className='secondary' onClick={() => setViewMode(false)}>
                    <Icon>edit</Icon>
                    <FormattedMessage id='general.edit' />
                  </Button>
                </div>
                :
                <div className='buttons-container'>
                  <div className={'edit-btn'}>
                    <Button
                      className={'secondary'}
                      onClick={() => setViewMode(true)}
                    >
                      <FormattedMessage id={'statement.cancel'} />
                    </Button>
                  </div>
                  <div className={'save-btn'}>
                    <Button
                      type='button'
                      disabled={!validForm}
                      onClick={handleSave}
                    >
                      {loadingSubmit === true && (
                        <Spinner color='white' size={15} thickness={5} />
                      )}
                      <FormattedMessage id={'statement.save'} />
                    </Button>
                  </div>
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='content-row'>
        {viewMode === true ?
          <CurrentPassword />
          :
          <div>
            <UpdatePasswordContainer
              isValid={getIsValid}
              onValues={setValues}
            />
          </div>
        }
        <GenericSuccessModal
          isOpen={sucessModal}
          handleClose={() => setSucessModal(false)}
          messageId={'account.screen.modal.security.succes'}
        />
        <GenericErrorModal
          isOpen={errorModal}
          handleClose={() => setErrorModal(false)}
          intlMessage={{ id: 'general.modal.error.label', values: { action: 'updating', subject: 'password' } }}
        />
      </div>
    </div>
  )
}

export default SecurityContainer;