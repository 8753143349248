import { Tab, Tabs } from '@material-ui/core';
import { setHRTeamTabState } from '@store/HRTeam/HRTeamTabs/hrteam.action';
import { RootState } from '@store/rootReducer'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'

const HRTeamTabNavigation = () => {
    const hrTeamTabState = useSelector((state:RootState) => state.HRTeam.hrTeamActiveTabState);

    const dispatch = useDispatch();

    const onChange = (e : any, value : number) => {
        dispatch(setHRTeamTabState(value))
    };

  return (
    <Tabs value={hrTeamTabState} onChange={onChange} className="hr-tabs">
        <Tab label={<FormattedMessage id='hr.tab.evaluations' />} id='evaluations'/>
        <Tab label={<FormattedMessage id='hr.tab.questionnaires'/>} id='questionnaires'/>
        <Tab label={<FormattedMessage id='hr.tab.skills.aptitudes'/>} id='skills' />
    </Tabs>
  )
}

export default HRTeamTabNavigation
