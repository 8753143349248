import { useCallback, useState } from 'react';
import Card from '@material-ui/core/Card';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Spinner from '@components/Shared/Spinner/Spinner';
import CreatableDropdown from '@components/Shared/CreatableDropdown/CreatableDropdown';
import { OptionsModel } from '@models/helpful.model';
import { useDispatch } from 'react-redux';
import { setLocalState } from '@store/User/Info/info.actions';
import { USER_ROLE } from '@utils/user';
import { inviteMembers } from '@services/invitations.service';
import { InviteMembersService } from '@models/invitations.model';
import useToastNotification from 'hooks/useToastNotification';

import './styles.scss';

const OnboardingInviteContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<OptionsModel[]>([]);

  const dispatch = useDispatch();
  const toastNotification = useToastNotification();

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const serviceData: InviteMembersService = {
        role: USER_ROLE.member,
        emails: values.map((element: OptionsModel) => element.label),
      };
      await inviteMembers(serviceData);
      toastNotification('general.success.members.invitations', false);
    } catch (error) {
      toastNotification('general.error.members.invitations', true);
      console.error(error);
    } finally {
      toastNotification('onboarding.invite.success.toast', false);
      dispatch(setLocalState('accessState'));
      setLoading(false);
    }
  }, [dispatch, toastNotification, values]);

  const handleSkip = useCallback(() => {
    toastNotification('onboarding.invite.success.toast', false);
    dispatch(setLocalState('accessState'));
  }, [dispatch, toastNotification]);

  return (
    <Card className='onboardinginvite-container'>
      <h1>
        <FormattedMessage id={'onboarding.invite.title'} />
      </h1>
      <p>
        <FormattedMessage id={'onboarding.invite.description'} />
      </p>
      <div className='add-members-form'>
        <p className='label'>
          <FormattedMessage id={'onboarding.invite.input.label'} />
        </p>
        <CreatableDropdown
          value={values}
          options={[]}
          placeholder={''}
          viewMode={false}
          onChangeValues={setValues}
          blockCallback={true}
        />
        <div className='buttons'>
          <Button
            className='primary'
            type='submit'
            disabled={!values.length || loading}
            onClick={handleSubmit}
          >
            {loading ? (
              <div className='spinner-button'>
                <Spinner color='white' size={15} thickness={5} />
              </div>
            ) : (
              <FormattedMessage id='onboarding.invite.invite.button' />
            )}
          </Button>
          <Button className='secondary' type='submit' disabled={loading} onClick={handleSkip}>
            <FormattedMessage id='onboarding.invite.skip.button' />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default OnboardingInviteContainer;
