import { FormattedMessage } from 'react-intl';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { getInputData, useNotificationsSettings } from 'hooks/useNotificatonsSettings';
import { EmailNotificationsI } from '@containers/Settings/NotificationsContainer/NotificationsContainer';
import { OptionsModel } from '@models/helpful.model';
import { updateExpirationPreferences, updateNotificationPreferences, updatePeriodicityPreferences } from '@services/notifications.service';
import './style.scss';
import { NOTIFIACTIONS_HANDLERS_ID, EXPIRATION_VALUES, PERIODICITY_VALUES } from 'lib/enums/notifications/notifications.enums';
import { USER_ROLE } from '@utils/user';
import SettingsNotificationsInputs from '../SettingsNotificationsInputs/SettingsNotificationsInputs';
import { ValueType } from '@models/notifications.models';

interface EmailNotificationsProps {
  data: EmailNotificationsI[] | any;
  periodicOptions: OptionsModel[];
  expirationOptions: OptionsModel[];
  loadingPeriodic: boolean;
  loadingExpiration: boolean;
  errorPeriodic: any | null;
  errorExpiration: any | null;
  daysAndWeeksOptions: OptionsModel[];
  role: number;
}

const EmailNotificationsComponent = (props: EmailNotificationsProps) => {
  const { data, periodicOptions, expirationOptions, daysAndWeeksOptions, role } = props;
  const [onChangeObjectiveIsDue, setOnChangeObjectiveIsDue] = useState<string>('')
  const [onChangeAddProgress, setOnChangeAddProgress] = useState<string>('')
  const [onChangeCultAttribute, setOnChangeCultAttribute] = useState<string>('')
  const [onChangeOrgStatement, setOnChangeOrgStatement] = useState<string>('')

  const { handleSwitchChange: handleChange, handleExpirationSelection, handlePeriodicityChange } = useNotificationsSettings();

  // Generate the object to fill the form data
  const inputData = useMemo(() => getInputData(data), [data]);

  const objectiveIsDueIntervalData = useMemo(() => {
    const data = inputData.objectiveIsDue &&
      inputData.objectiveIsDue.expiration_preference &&
      inputData.objectiveIsDue.expiration_preference.interval !== null ?
      inputData.objectiveIsDue.expiration_preference.interval?.toString() : "1"
    return data
  }, [inputData.objectiveIsDue])

  const addProgressIntervalData = useMemo(() => {
    const data = inputData.addProgress &&
      inputData.addProgress.periodic_preference &&
      inputData.addProgress.periodic_preference.interval.toString()
    return data
  }, [inputData.addProgress])

  const culturalAttributeIntervalData = useMemo(() => {
    const data = inputData.culturalExpiring &&
      inputData.culturalExpiring.expiration_preference &&
      inputData.culturalExpiring.expiration_preference.interval !== null ?
      inputData.culturalExpiring.expiration_preference.interval!.toString() : "1"
    return data
  }, [inputData.culturalExpiring])

  const organizatinStatementIntervalData = useMemo(() => {
    const data = inputData.statementExpiring &&
      inputData.statementExpiring.expiration_preference &&
      inputData.statementExpiring.expiration_preference.interval !== null ?
      inputData.statementExpiring.expiration_preference.interval!.toString() : "1"
    return data
  }, [inputData.statementExpiring])

  const initialValues = {
    addedToNewTeam: inputData &&
      inputData.addedToNewTeam &&
      inputData.addedToNewTeam.preference ?
      inputData.addedToNewTeam.preference.webapp : false,
    assignedAsCoach: inputData &&
      inputData.assignedAsCoach &&
      inputData.assignedAsCoach.preference ?
      inputData.assignedAsCoach.preference.webapp : false,
    leadershipTransferred: inputData &&
      inputData.leadershipTransferred &&
      inputData.leadershipTransferred.preference ?
      inputData.leadershipTransferred.preference.webapp : false,
    OKRTransferred: inputData &&
      inputData.OKRTransferred &&
      inputData.OKRTransferred.preference ?
      inputData.OKRTransferred.preference.webapp : false,
    isObjectiveDueDate: inputData &&
      inputData.objectiveIsDue &&
      inputData.objectiveIsDue.preference ?
      inputData.objectiveIsDue.preference.webapp : false,
    objectiveReminder: inputData &&
      inputData.objectiveIsDue &&
      inputData.objectiveIsDue.expiration_preference &&
      inputData.objectiveIsDue.expiration_preference.tgd_notification_expiration_catalog &&
      inputData.objectiveIsDue.expiration_preference.tgd_notification_expiration_catalog.id ?
      inputData.objectiveIsDue.expiration_preference.tgd_notification_expiration_catalog.id.toString()
      : '',
    isOKRKeyAddProgress: inputData &&
      inputData.addProgress &&
      inputData.addProgress.preference ?
      inputData.addProgress.preference.webapp : false,
    addProgressEvery: inputData &&
      inputData.addProgress &&
      inputData.addProgress.periodic_preference ?
      inputData.addProgress.periodic_preference.interval : false,
    isMyCulturalAttributeExpiring: inputData &&
      inputData.culturalExpiring &&
      inputData.culturalExpiring.preference ?
      inputData.culturalExpiring.preference.webapp : false,
    isOrgStatementExpiring: inputData &&
      inputData.statementExpiring &&
      inputData.statementExpiring.preference ?
      inputData.statementExpiring.preference.webapp : false,
  };

  const handleUpdates = useCallback((actionId: string, handleSwitchChange: boolean) => {
    switch (actionId) {
      case NOTIFIACTIONS_HANDLERS_ID.isNewTeam:
        const newTeamId = data?.[0].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, { id: newTeamId });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isNewOKRCoach:
        const newCoachId = data?.[1].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, { id: newCoachId });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isNewLeadership:
        const newLeadershipId = data?.[2].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, { id: newLeadershipId });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isNewOKRTransferred:
        const OKRTransferredId = data?.[3].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, { id: OKRTransferredId });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isObjectiveDueDate:
        const isObjectiveDueId = data?.[4].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, { id: isObjectiveDueId });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isAddProgressReminder:
        const isAddProggressId = data?.[5].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, {
          id: isAddProggressId,
        });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isCulturalAttributeExp:
        const isCulturalAttrExpiringId = data?.[6].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, {
          id: isCulturalAttrExpiringId,
        });
        break;
      case NOTIFIACTIONS_HANDLERS_ID.isStatementExp:
        const isStatementExpId = data?.[7].preference?.id;
        handleChange(handleSwitchChange, updateNotificationPreferences, { id: isStatementExpId });
        break;
    }
  }, [data, handleChange])

  const handleSwitchUpdate = useCallback((actionId: string, handleSwitchChange: boolean) => {
    handleUpdates(actionId, handleSwitchChange)
  }, [handleUpdates])

  // OBJECTIVE IS DUE
  // Before expiration and At expiration day dropdown handler
  const handleObjectiveIsDueExpirationPreferences = useCallback((expiration_id: ValueType) => {
    const id = inputData.objectiveIsDue?.expiration_preference?.id;
    handleExpirationSelection(id, { expiration_id: Number(expiration_id) }, updateExpirationPreferences)
  }, [inputData.objectiveIsDue?.expiration_preference?.id, handleExpirationSelection,])

  // NUMBER of days or weeks selected 
  const handleAmountSelectedObjectiveIsDue = useCallback((interval: ValueType) => {
    const id = inputData?.objectiveIsDue?.expiration_preference?.id
    if (objectiveIsDueIntervalData !== interval) {
      handleExpirationSelection(id, { interval: Number(interval) }, updateExpirationPreferences)
    }
  }, [inputData?.objectiveIsDue?.expiration_preference, objectiveIsDueIntervalData, handleExpirationSelection])

  // Handle AMOUNT of days or weeks on blur
  const handleOnBlurObjectiveIsDue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const id = inputData?.objectiveIsDue?.expiration_preference?.id;

    // Check if the value contains letters
    if (!/[a-zA-Z]/.test(event.target.value)) {
      if (objectiveIsDueIntervalData !== event.target.value && event.target.value !== "") {
        setOnChangeObjectiveIsDue(event.target.value)
        handleExpirationSelection(id, { interval: Number(event.target.value) }, updateExpirationPreferences)
      }
    }
  }, [handleExpirationSelection, inputData?.objectiveIsDue?.expiration_preference?.id, objectiveIsDueIntervalData])

  // Handle DAYS or WEEKS selection
  const handleObjectiveIsDuePeriodicity = useCallback((periodicity_id: ValueType) => {
    const id = inputData.objectiveIsDue?.expiration_preference?.id

    handleExpirationSelection(id, { periodicity_id: Number(periodicity_id) }, updateExpirationPreferences)
  }, [handleExpirationSelection, inputData.objectiveIsDue?.expiration_preference?.id])

  // ADD PROGRESS (PERIODICITY)
  const handleAddProgressPeriodicity = useCallback((periodicity_id: ValueType) => {
    const id = inputData?.addProgress?.periodic_preference?.id;
    handlePeriodicityChange(id, { periodicity_id: Number(periodicity_id) }, updatePeriodicityPreferences)
  }, [handlePeriodicityChange, inputData?.addProgress?.periodic_preference?.id])

  const handleAmountSelectedAddProgress = useCallback((interval: ValueType) => {
    const id = inputData?.addProgress?.periodic_preference?.id;

    if (addProgressIntervalData !== interval) {
      handlePeriodicityChange(id, { interval: Number(interval) }, updatePeriodicityPreferences)
    }
  }, [handlePeriodicityChange, inputData?.addProgress?.periodic_preference?.id, addProgressIntervalData])

  const handleOnBlurAddProgress = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const id = inputData?.addProgress?.periodic_preference?.id;

    // Check if the value contains letters
    if (!/[a-zA-Z]/.test(event.target.value)) {
      if (addProgressIntervalData !== event.target.value && event.target.value !== "") {
        setOnChangeAddProgress(event.target.value)
        handlePeriodicityChange(id, { interval: Number(event.target.value) }, updatePeriodicityPreferences)
      }
    }
  }, [handlePeriodicityChange, inputData?.addProgress?.periodic_preference?.id, addProgressIntervalData])


  // CULTURAL ATTRIBUTE
  // Before expiration and At expiration day dropdown handler
  const handleCulturalAttributeExpirationPreferences = useCallback((expiration_id: ValueType) => {
    const id = inputData.culturalExpiring?.expiration_preference?.id;
    handleExpirationSelection(id, { expiration_id: Number(expiration_id) }, updateExpirationPreferences)
  }, [handleExpirationSelection, inputData])

  // NUMBER of days or weeks selected 
  const handleAmountSelectedCulturalAttribute = useCallback((interval: ValueType) => {
    const id = inputData?.culturalExpiring?.expiration_preference?.id
    if (culturalAttributeIntervalData !== interval) {
      handleExpirationSelection(id, { interval: Number(interval) }, updateExpirationPreferences)
    }
  }, [inputData?.culturalExpiring?.expiration_preference?.id, culturalAttributeIntervalData, handleExpirationSelection])

  // Handle amount of DAYS or WEEKS on blur
  const handleOnBlurCulturalAttribute = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const id = inputData?.culturalExpiring?.expiration_preference?.id

    // Check if the value contains letters
    if (!/[a-zA-Z]/.test(event.target.value)) {
      if (culturalAttributeIntervalData !== event.target.value && event.target.value !== "") {
        setOnChangeCultAttribute(event.target.value)
        handleExpirationSelection(id, { interval: Number(event.target.value) }, updateExpirationPreferences)
      }
    }
  }, [culturalAttributeIntervalData, handleExpirationSelection, inputData?.culturalExpiring?.expiration_preference?.id])

  // Handle DAYS or WEEKS selection
  const handleCulturalAttributePeriodicity = useCallback((periodicity_id: ValueType) => {
    const id = inputData?.culturalExpiring?.expiration_preference?.id

    handleExpirationSelection(id, { periodicity_id: Number(periodicity_id) }, updateExpirationPreferences)
  }, [handleExpirationSelection, inputData?.culturalExpiring?.expiration_preference?.id])

  // STATEMENT EXPIRING
  // Before expiration and At expiration day dropdown handler
  const handleOrgStatementExpirationPreferences = useCallback((expiration_id: ValueType) => {
    const id = inputData.statementExpiring?.expiration_preference?.id;
    handleExpirationSelection(id, { expiration_id: Number(expiration_id) }, updateExpirationPreferences)
  }, [handleExpirationSelection, inputData])

  // NUMBER of days or weeks selected 
  const handleAmountSelectedOrgStatement = useCallback((interval: ValueType) => {
    const id = inputData?.statementExpiring?.expiration_preference?.id
    if (organizatinStatementIntervalData !== interval) {
      handleExpirationSelection(id, { interval: Number(interval) }, updateExpirationPreferences)
    }
  }, [inputData?.statementExpiring?.expiration_preference?.id, organizatinStatementIntervalData, handleExpirationSelection])

  // Handle amount of DAYS or WEEKS on blur
  const handleOnBlurOrgStatement = useCallback((event) => {
    event.preventDefault()
    const id = inputData?.statementExpiring?.expiration_preference?.id

    // Check if the value contains letters
    if (!/[a-zA-Z]/.test(event.target.value)) {
      if (organizatinStatementIntervalData !== event.target.value && event.target.value !== "") {
        setOnChangeOrgStatement(event.target.value)
        handleExpirationSelection(id, { interval: Number(event.target.value) }, updateExpirationPreferences)
      }
    }
  }, [handleExpirationSelection, inputData?.statementExpiring?.expiration_preference?.id, organizatinStatementIntervalData])

  // Handle DAYS or WEEKS selection
  const handleOrgStatementPeriodicity = useCallback((periodicity_id: ValueType) => {
    const id = inputData?.statementExpiring?.expiration_preference?.id
    handleExpirationSelection(id, { periodicity_id: Number(periodicity_id) }, updateExpirationPreferences)
  }, [handleExpirationSelection, inputData?.statementExpiring?.expiration_preference?.id])

  return (
    <div className='email-notifications-component'>
      <h4 className='header'>
        <FormattedMessage id='settings.tab.notifications.title' />
      </h4>

      {/* Notifies you when you have been added to a new team. */}
      <SettingsNotificationsInputs
        switchLabel="settings.tab.notifications.new.team"
        switchName={NOTIFIACTIONS_HANDLERS_ID.isNewTeam}
        isChecked={initialValues.addedToNewTeam}
        onSwitchChange={handleSwitchUpdate}
        isExpirationDropdown={false}
      />

      {/* Notifies you when you are assigned as a coach to an OKR. */}
      <SettingsNotificationsInputs
        switchLabel="settings.tab.notifications.assigned.as.coach"
        switchName={NOTIFIACTIONS_HANDLERS_ID.isNewOKRCoach}
        isChecked={initialValues.assignedAsCoach}
        onSwitchChange={handleSwitchUpdate}
        isExpirationDropdown={false}
      />

      {/* Notifies you that the leadership of a team has been transferred to you. */}
      <SettingsNotificationsInputs
        switchLabel="settings.tab.notifications.transferred.leadership"
        switchName={NOTIFIACTIONS_HANDLERS_ID.isNewLeadership}
        isChecked={initialValues.assignedAsCoach}
        onSwitchChange={handleSwitchUpdate}
        isExpirationDropdown={false}
      />

      {/* Notifies you when a OKR has been transferred to you. */}
      <SettingsNotificationsInputs
        switchLabel="settings.tab.notifications.transferred.okr"
        switchName={NOTIFIACTIONS_HANDLERS_ID.isNewOKRTransferred}
        isChecked={initialValues.OKRTransferred}
        onSwitchChange={handleSwitchUpdate}
        isExpirationDropdown={false}
      />

      {/* Reminds you when an objective is due.  
      <SettingsNotificationsInputs
        switchLabel="settings.tab.notifications.objective.is.due.date"
        switchName={NOTIFIACTIONS_HANDLERS_ID.isObjectiveDueDate}
        isChecked={initialValues.isObjectiveDueDate}
        onSwitchChange={handleSwitchUpdate}
        isExpirationDropdown={true}
        expirationDropdown={{
          expirationLabel: "settings.tab.notifications.objective.reminder.label",
          expirationDropdownOptions: expirationOptions,
          expirationDropdownValues:
            inputData &&
              inputData.objectiveIsDue && inputData.objectiveIsDue.expiration_preference &&
              inputData.objectiveIsDue.expiration_preference.tgd_notification_expiration_catalog &&
              inputData.objectiveIsDue.expiration_preference.tgd_notification_expiration_catalog.id ?
              inputData.objectiveIsDue.expiration_preference.tgd_notification_expiration_catalog.id.toString() : "1",
          onSelectExpirationOption: handleObjectiveIsDueExpirationPreferences,
          expirationDropdownPlaceholder: "general.placeholder.dropdown",
          expirationDropdownName: NOTIFIACTIONS_HANDLERS_ID.isObjectiveDueDate,
        }}
        periodicity={{
          intervalValue: onChangeObjectiveIsDue === undefined || onChangeObjectiveIsDue.length === 0 || /[a-zA-Z]/.test(onChangeObjectiveIsDue) ?
            objectiveIsDueIntervalData
            : onChangeObjectiveIsDue && onChangeObjectiveIsDue,
          periodicityOptions: daysAndWeeksOptions,
          onSelectPeriodicityOption: handleAmountSelectedObjectiveIsDue,
          periodicityPlaceholder: 'general.placeholder.dropdown',
          onPeriodicityBlur: handleOnBlurObjectiveIsDue,
          weeksOrDaysValue: inputData &&
            inputData.objectiveIsDue &&
            inputData.objectiveIsDue.expiration_preference &&
            inputData.objectiveIsDue.expiration_preference.tgd_notification_periodicity_catalog &&
            inputData.objectiveIsDue.expiration_preference.tgd_notification_periodicity_catalog.id ?
            inputData.objectiveIsDue.expiration_preference.tgd_notification_periodicity_catalog.id.toString() : PERIODICITY_VALUES.days,
          weeksOrDaysOptions: periodicOptions,
          onSelectPeriodicOption: handleObjectiveIsDuePeriodicity,
          weeksOrDaysplaceholder: 'general.placeholder.dropdown'
        }}
      />

      {/* Reminds you to periodically add progress to Key Results in an OKR. 
      <SettingsNotificationsInputs
        switchLabel="settings.tab.notifications.okr.add.progress.reminder"
        switchName={NOTIFIACTIONS_HANDLERS_ID.isAddProgressReminder}
        isChecked={initialValues.isOKRKeyAddProgress}
        onSwitchChange={handleSwitchUpdate}
        isOnlyPeriodicityDropdown={true}
        periodicity={{
          periodicityLabel: 'settings.tab.notifications.okr.add.progress.dropdown.label',
          intervalValue: onChangeAddProgress === undefined || onChangeAddProgress.length === 0 || /[a-zA-Z]/.test(onChangeAddProgress) ?
            addProgressIntervalData
            : onChangeAddProgress && onChangeAddProgress,
          periodicityOptions: daysAndWeeksOptions,
          onSelectPeriodicityOption: handleAmountSelectedAddProgress,
          periodicityPlaceholder: 'general.placeholder.dropdown',
          onPeriodicityBlur: handleOnBlurAddProgress,
          weeksOrDaysValue: inputData.addProgress &&
            inputData.addProgress &&
            inputData.addProgress.periodic_preference &&
            inputData.addProgress.periodic_preference.tgd_notification_periodicity_catalog &&
            inputData.addProgress.periodic_preference.tgd_notification_periodicity_catalog.id
            ? inputData.addProgress.periodic_preference.tgd_notification_periodicity_catalog.id.toString()
            : PERIODICITY_VALUES.days,
          weeksOrDaysOptions: periodicOptions,
          onSelectPeriodicOption: handleAddProgressPeriodicity,
          weeksOrDaysplaceholder: 'general.placeholder.dropdown'
        }}

      />

      {(role === USER_ROLE.officer || role === USER_ROLE.owner) &&
        <>
          {/* Reminds you that a cultural attribute that you own is expiring. 
          <SettingsNotificationsInputs
            switchLabel="settings.tab.notifications.cultural.attribute.is.expiring"
            switchName={NOTIFIACTIONS_HANDLERS_ID.isCulturalAttributeExp}
            isChecked={initialValues.isMyCulturalAttributeExpiring}
            onSwitchChange={handleSwitchUpdate}
            isExpirationDropdown={true}
            expirationDropdown={{
              expirationLabel: "settings.tab.notifications.objective.reminder.label",
              expirationDropdownOptions: expirationOptions,
              expirationDropdownValues: inputData.culturalExpiring &&
                inputData.culturalExpiring.expiration_preference &&
                inputData.culturalExpiring.expiration_preference
                  .tgd_notification_expiration_catalog.id
                ? inputData.culturalExpiring.expiration_preference.tgd_notification_expiration_catalog.id.toString()
                : EXPIRATION_VALUES.atExpirationDay,
              onSelectExpirationOption: handleCulturalAttributeExpirationPreferences,
              expirationDropdownPlaceholder: "general.placeholder.dropdown",
              expirationDropdownName: NOTIFIACTIONS_HANDLERS_ID.isCulturalAttributeExp,
            }}
            periodicity={{
              intervalValue: onChangeCultAttribute === undefined || onChangeCultAttribute.length === 0 || /[a-zA-Z]/.test(onChangeCultAttribute) ?
                culturalAttributeIntervalData
                : onChangeCultAttribute && onChangeCultAttribute,
              periodicityOptions: daysAndWeeksOptions,
              onSelectPeriodicityOption: handleAmountSelectedCulturalAttribute,
              periodicityPlaceholder: 'general.placeholder.dropdown',
              onPeriodicityBlur: handleOnBlurCulturalAttribute,
              weeksOrDaysValue: inputData &&
                inputData.culturalExpiring &&
                inputData.culturalExpiring.expiration_preference &&
                inputData.culturalExpiring.expiration_preference.tgd_notification_periodicity_catalog &&
                inputData.culturalExpiring.expiration_preference.tgd_notification_periodicity_catalog.id ?
                inputData.culturalExpiring.expiration_preference.tgd_notification_periodicity_catalog.id.toString() : PERIODICITY_VALUES.days,
              weeksOrDaysOptions: periodicOptions,
              onSelectPeriodicOption: handleCulturalAttributePeriodicity,
              weeksOrDaysplaceholder: 'general.placeholder.dropdown'
            }}
          />

          {/* Reminds you that a statement for the organization is expiring.
          <SettingsNotificationsInputs
            switchLabel="settings.tab.notifications.org.statement.is.expiring"
            switchName={NOTIFIACTIONS_HANDLERS_ID.isStatementExp}
            isChecked={initialValues.isOrgStatementExpiring}
            onSwitchChange={handleSwitchUpdate}
            isExpirationDropdown={true}
            expirationDropdown={{
              expirationLabel: "settings.tab.notifications.objective.reminder.label",
              expirationDropdownOptions: expirationOptions,
              expirationDropdownValues: inputData.statementExpiring &&
                inputData.statementExpiring.expiration_preference &&
                inputData.statementExpiring.expiration_preference.tgd_notification_expiration_catalog &&
                inputData.statementExpiring.expiration_preference.tgd_notification_expiration_catalog.id
                ? inputData.statementExpiring.expiration_preference.tgd_notification_expiration_catalog.id.toString()
                : EXPIRATION_VALUES.atExpirationDay,
              onSelectExpirationOption: handleOrgStatementExpirationPreferences,
              expirationDropdownPlaceholder: "general.placeholder.dropdown",
              expirationDropdownName: NOTIFIACTIONS_HANDLERS_ID.isStatementExp,
            }}
            periodicity={{
              intervalValue: onChangeOrgStatement === undefined || onChangeOrgStatement.length === 0 || /[a-zA-Z]/.test(onChangeOrgStatement) ?
                organizatinStatementIntervalData
                : onChangeOrgStatement && onChangeOrgStatement,
              periodicityOptions: daysAndWeeksOptions,
              onSelectPeriodicityOption: handleAmountSelectedOrgStatement,
              periodicityPlaceholder: 'general.placeholder.dropdown',
              onPeriodicityBlur: handleOnBlurOrgStatement,
              weeksOrDaysValue: inputData &&
                inputData.statementExpiring &&
                inputData.statementExpiring.expiration_preference &&
                inputData.statementExpiring.expiration_preference.tgd_notification_periodicity_catalog &&
                inputData.statementExpiring.expiration_preference.tgd_notification_periodicity_catalog.id ?
                inputData.statementExpiring.expiration_preference.tgd_notification_periodicity_catalog.id.toString()
                : PERIODICITY_VALUES.days,
              weeksOrDaysOptions: periodicOptions,
              onSelectPeriodicOption: handleOrgStatementPeriodicity,
              weeksOrDaysplaceholder: 'general.placeholder.dropdown'
            }}
          />
        </>
      }*/}
    </div>
  );
};

export default EmailNotificationsComponent;
