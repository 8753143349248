import { GET_ORGANZIATION_TIMEFRAMES } from "@graphql/organization.queries";
import { SetAutomaticTimeframesRequest } from "@models/organizations.model";
import { GetOrganziationTimeframesResponse } from "@models/timeframes.models";
import { graphQlQuery } from "@services/graphql.service";
import { disableAutomaticTimeframesService, setAutomaticTimeFramesDataService, setFirstTimeTimeframesData, updateTimeFramesDataService, validateAutomaticTimeframesExist } from "@services/organization.service";
import { store } from "@store/store";
import { getTimeframesToUpdate, translateGetOrganziationsTimeframes } from "@utils/organization";
import { TIMEFRAMES_AUTOMATIC_TYPES } from "lib/enums/timeframes.enum";
import { TimeFramesFormObjectModel } from "lib/TegduForms/TimeFramesFormUtils";
import { Dispatch } from "redux";

export const SET_TIMEFRAMES_REQUEST_LOADING = "SET_TIMEFRAMES_REQUEST_LOADING";
export const SET_TIMEFRAMES_REQUEST_ERROR = "SET_TIMEFRAMES_REQUEST_ERROR";
export const SET_TIMEFRAMES_UPDATE_LOADING = "SET_TIMEFRAMES_UPDATE_LOADING";
export const SET_TIMEFRAMES_UPDATE_ERROR = "SET_TIMEFRAMES_UPDATE_ERROR";
export const SET_AUTOMATIC_TIMEFRAMES_REQUEST_LOADING = "SET_AUTOMATIC_TIMEFRAMES_REQUEST_LOADING";
export const SET_AUTOMATIC_TIMEFRAMES_ID = "SET_AUTOMATIC_TIMEFRAMES_ID";
export const SET_DISABLE_AUTOMATIC_TIMEFRAMES_LOADING = "SET_DISABLE_AUTOMATIC_TIMEFRAMES_LOADING";

export const setDisableAutomaticTimeframesLoading = (isLoading: boolean) => {
  return {
    type: SET_DISABLE_AUTOMATIC_TIMEFRAMES_LOADING,
    payload: isLoading
  }
}

export const setAutomaticTimeframesId = (id: number | null) => {
  return {
    type: SET_AUTOMATIC_TIMEFRAMES_ID,
    payload: id
  }
}

export const setAutomaticTimeframesRequestLoading = (isLoading: boolean) => {
  return {
    type: SET_AUTOMATIC_TIMEFRAMES_REQUEST_LOADING,
    payload: isLoading
  }
}

export const setTimeframesRequestLoading = (isLoading: boolean) => {
  return {
    type: SET_TIMEFRAMES_REQUEST_LOADING,
    payload: isLoading
  }
}

export const setTimeframesRequestError = (isError: boolean) => {
  return {
    type: SET_TIMEFRAMES_REQUEST_ERROR,
    payload: isError
  }
}

export const setTimeframesUpdatetLoading = (isLoading: boolean) => {
  return {
    type: SET_TIMEFRAMES_UPDATE_LOADING,
    payload: isLoading
  }
}

export const setTimeframesUpdateError = (isError: boolean) => {
  return {
    type: SET_TIMEFRAMES_UPDATE_ERROR,
    payload: isError
  }
}

export const getTimeFramesData = (
  organizationId: number, 
  handleSuccess: (data: TimeFramesFormObjectModel [], isAutomaticTimeframes?: TIMEFRAMES_AUTOMATIC_TYPES) => void
) => async (dispatch: Dispatch) => {
  dispatch(setTimeframesRequestLoading(true))
  dispatch(setTimeframesRequestError(false))
  try {
    const response = await graphQlQuery<GetOrganziationTimeframesResponse>(GET_ORGANZIATION_TIMEFRAMES, { organizationId: organizationId})    
    const responseUpdated = translateGetOrganziationsTimeframes(response.data.tgdOrganization.tgd_timeframes)
    try {
      const automaticResponse = await validateAutomaticTimeframesExist();
      dispatch(setAutomaticTimeframesId(automaticResponse.id))
      handleSuccess(responseUpdated, automaticResponse.id === null ? undefined : automaticResponse.timeframe )    
    }catch {
      handleSuccess(responseUpdated, undefined)    
    }    

  } catch {
    dispatch(setTimeframesRequestLoading(false))
    dispatch(setTimeframesRequestError(true))
  }
}

export const setTimeframesData = (
  data: TimeFramesFormObjectModel [], 
  handleSuccess: () => void
  ) => async (dispatch: Dispatch) => {
  dispatch(setTimeframesUpdatetLoading(true))
  dispatch(setTimeframesUpdateError(false))
  try {
    await setFirstTimeTimeframesData(data)
    dispatch(setTimeframesUpdatetLoading(false))
    handleSuccess()
  } catch {
    dispatch(setTimeframesUpdatetLoading(false))
    dispatch(setTimeframesUpdateError(true))
  }
}

export const updateTimeframesData = (
  data: TimeFramesFormObjectModel [],
  handleSuccessUpdate: () => void,
  handleSuccessError: () => void
  ) => async (dispatch: Dispatch) => {
  dispatch(setTimeframesUpdatetLoading(true))

  const updatedData: TimeFramesFormObjectModel [] = getTimeframesToUpdate(data, "updated")
  const newData: TimeFramesFormObjectModel [] = getTimeframesToUpdate(data, "new")

  if(updatedData.length > 0 && newData.length > 0){
    // Update and insert
    await Promise.allSettled([setFirstTimeTimeframesData(newData), updateTimeFramesDataService(updatedData)])
    dispatch(setTimeframesUpdatetLoading(false))
    handleSuccessUpdate()
  }else if(updatedData.length > 0){
    // Update 
    try {
      await updateTimeFramesDataService(updatedData)
      dispatch(setTimeframesUpdatetLoading(false))
      handleSuccessUpdate()
    } catch {
      dispatch(setTimeframesUpdatetLoading(false))
      handleSuccessError()
    }
  }else if(newData.length > 0){
    // Insert
    try {
      await setFirstTimeTimeframesData(newData)
      dispatch(setTimeframesUpdatetLoading(false))
      handleSuccessUpdate()
    } catch {
      dispatch(setTimeframesUpdatetLoading(false))
      handleSuccessError()
    }

  }
}

export const setAutomaticTimeframesData = (
  data: SetAutomaticTimeframesRequest,
  handleSuccess: () => void
) => async (dispatch: Dispatch) => { 
  dispatch(setAutomaticTimeframesRequestLoading(true))
  try{
    await setAutomaticTimeFramesDataService(data)
    dispatch(setAutomaticTimeframesRequestLoading(false))
    handleSuccess()
  }catch {
    dispatch(setAutomaticTimeframesRequestLoading(false))
  }
}

export const disableAutomaticTimeframes = (
  handleSuccess: () => void
) => async (dispatch: Dispatch) => {
  dispatch(setDisableAutomaticTimeframesLoading(true))
  const automaticTimeframesId = store.getState().Organization.Timeframes.automaticTimeframesId;
  if(automaticTimeframesId !== null){
    try {
      await disableAutomaticTimeframesService(automaticTimeframesId)
      dispatch(setDisableAutomaticTimeframesLoading(false))
      handleSuccess()
    } catch {
      dispatch(setDisableAutomaticTimeframesLoading(false))
      handleSuccess()
    }
  }else {
    dispatch(setDisableAutomaticTimeframesLoading(false))
    handleSuccess()
  }  
}