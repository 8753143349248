import TgdClient from "@helpers/HttpClients/TgdClient"

/* UPDATE TAG */
export const putTag = async (id: string, name: string): Promise<any> => {
    return await TgdClient.put(`/tgd-tags/${id}`, { name: name })
}

/* INSERT TAG */
export const postTag = async (name: string, organizationId: number | null): Promise<any> => {
    return TgdClient.post(`/tgd-tags/`, { name: name, tgd_organization: { id: organizationId }, is_archived: false })
}

/* ARCHIVE TAG */
export const archiveTags = async (id: string): Promise<any> => {
    return await TgdClient.put(`/tgd-tags/${id}`, { is_archived: true })
}

/* UNARCHIVE TAG */
export const unarchiveTags = async (id: string): Promise<any> => {
    return await TgdClient.put(`/tgd-tags/${id}`, { is_archived: false })
}