import React from 'react';
import TeamsContainer from '@containers/Teams/TeamsContainer/TeamsContainer';

const TeamsScreen = () => {
  return (
    <div id='tourstep-teams' className='screen-teams'>      
      <TeamsContainer />
    </div>
  );
};

export default TeamsScreen;
