import { FormattedMessage } from "react-intl";
import "./styles.scss";

interface StartRatingAverageProps {
  avarage?: number | string;
}

const StartRatingAverage = ({ avarage }: StartRatingAverageProps) => {
  const isNotThereAvarge = avarage === 0 && true;
  return (
    <div className="container-start">
      <div className="container-img-start">
        <div className="icon-rate">
          {isNotThereAvarge ? (
            <img src="images/estrella-disa.svg" alt="" />
          ) : (
            <>
              <img src="images/estrella.svg" alt="" />
              <span>{avarage}</span>
            </>
          )}
        </div>
      </div>
      <div className="container-title">
        <span>
          <FormattedMessage
            id={
              isNotThereAvarge
                ? "dashboard.objectives.rating.average.not"
                : "dashboard.objectives.rating.average"
            }
          />
        </span>
      </div>
    </div>
  );
};

export default StartRatingAverage;
