import { RootState } from "@store/rootReducer"
import { OBJECTIVE_STATUS } from "@utils/objectives"
import { CREATE_OBJECTIVES_ACTIONS, OBJECTIVE_TYPES } from "lib/enums/objectives.enum"
import { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CancelCreateObjectivesModal from '../CancelCreateObjectiveModal/CancelCreateObjectivesModal';
import { IconButton } from "@material-ui/core"
import Spinner from "@components/Shared/Spinner/Spinner"
import './styles.scss';

interface ObjectivesActionsHeaderProps {
  action: CREATE_OBJECTIVES_ACTIONS
	objectiveStatus?: OBJECTIVE_STATUS
	objectiveType: OBJECTIVE_TYPES | null  
	isValidForm: boolean
	handleClose: () => void
	handleSaveAsDraft: () => void
}

const ObjectivesActionsHeader = (props: ObjectivesActionsHeaderProps) => {
	const { action, isValidForm, objectiveStatus, objectiveType, handleSaveAsDraft, handleClose } = props;
	const editObjectiveLoading = useSelector((state: RootState) => state.Objectives.ObjectivesMainActions.isUpdatingObjectiveLoading);
	const createObjectiveLoading = useSelector((state: RootState) => state.Objectives.ObjectivesMainActions.isCreateObjectiveLoading);
	const [isCancelCreateObjectiveOpen, setIsCancelCreateObjectiveOpen] = useState<boolean>(false);

	const handleOpenCancelCreateObjectivesModal = useCallback(() => {
		setIsCancelCreateObjectiveOpen(true)
	}, []);
	
	const handleCloseCancelObjectivesModal = useCallback(() => {
		setIsCancelCreateObjectiveOpen(false)
	}, [])

	return (
		<div className="objectives-actions-header">
			<div className="actions-header-content">
				<div className="basic-info-content">
					<div className="icon">
						<CloseIcon onClick={handleOpenCancelCreateObjectivesModal}/>
					</div>
					<div className="title">
						<span>
							{(action === CREATE_OBJECTIVES_ACTIONS.create) &&
								<>Add a new objective</>
							}

							{(action === CREATE_OBJECTIVES_ACTIONS.edit) &&
								<>Edit your objective</>
							}

							{(action === CREATE_OBJECTIVES_ACTIONS.replicate) &&
								<>Replicate your objective</>
							}
						</span>
					</div>
				</div>

				<div className='actions-content'>
          <div className='save-or-create'>
            <IconButton 
              type='submit' 
							className="root"
              form={"create-objectives-form"}
              disabled={!isValidForm || createObjectiveLoading || editObjectiveLoading}
            >
              <>
                {(createObjectiveLoading || editObjectiveLoading) ?
                  <Spinner color="complementary"  size={15} thickness={7}/>                
                :
                 <CheckIcon />
                }             
              </>
            </IconButton>
          </div>
        </div>

				<CancelCreateObjectivesModal
					action={action} 
					handleCloseModal={handleCloseCancelObjectivesModal}
					handleCancel={handleClose}
					handleSaveAsDraft={handleSaveAsDraft}
					isOpen={isCancelCreateObjectiveOpen} 
					objectiveStatus={objectiveStatus}
					objectiveType={objectiveType}
				/>

			</div>
		</div>
	)
}

export default ObjectivesActionsHeader;