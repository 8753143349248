import { TegduSelectOptionsModel } from "@models/global.model";
import { getLeaderTeams } from "@services/teams.service";
import { RootState } from "@store/rootReducer";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";


const useGetTeamsFromLeaderSelected = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [teams, setTeams] = useState<TegduSelectOptionsModel []>([]);
  const organizationId = useSelector((state: RootState) => state.User.Info.user.tgd_organization.id);

  const teamsFromLeaderCallback = useCallback(async (userId: number) => {
    let teams: TegduSelectOptionsModel [] = [];
    setLoading(true)
    try {      
      const response = await getLeaderTeams(organizationId, userId.toString());
      response.forEach((element) => {
        element.teams.forEach((subElement) => {
          teams.push({
            value: subElement.id.toString(),
            label: subElement.name
          })
        })
      })
      setLoading(false)
      setTeams(teams)
      
    } catch {
      setTeams(teams)
    }
  }, [organizationId])

  return { loading, teams, teamsFromLeaderCallback }
}

export default useGetTeamsFromLeaderSelected;