import { TextField } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import './styles.scss';

export interface TegduTextInputComponentProps {
  adornmentsType?: any
  errorMessage?: string
  index?: number
  isEditMode: boolean
  isPassword?: boolean
  maxLength?: number
  labelId?: string
  placeholderId?: string
  showAdornmentViewMode?: boolean
  value?: string
  withErrorArea?: boolean
  withMaxLength?: boolean
  onBlur?: any
  onChange?: (data: any) => void
}

const TegduTextInputComponent = (props: TegduTextInputComponentProps) => {
  const intl = useIntl();
  const {
    adornmentsType,
    value,
    isEditMode,
    isPassword,
    withErrorArea,
    errorMessage,
    labelId,
    placeholderId,
    showAdornmentViewMode,
    maxLength,
    withMaxLength,
    onChange,
    onBlur
  } = props;

  return (
    <div className={`tegdu-text-input-component ${errorMessage ? 'with-error' : ''}`}>

      {labelId &&
        <div className="label-message">
          <FormattedMessage
            id={labelId}
          />
        </div>
      }

      <div className="text-input">
        {(isEditMode === false) ?
          <span className="text-input-edit-mode">
            {(showAdornmentViewMode && adornmentsType !== undefined) &&
              <AccessTimeIcon color="primary" style={{ marginRight: "14px", width: "19px", height: "19px" }} />
            }
            {value}
          </span>
          :
          <TextField
            name='search'
            type={isPassword ? "password" : undefined}
            variant='outlined'
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
            inputProps={{
              maxLength: maxLength ? maxLength : 100,
            }}
            InputProps={
              adornmentsType === 1 ? {
                endAdornment: <div className={`metric-style-${(value === "" || value === undefined) ? "light" : "strong"}`}></div>
              } : adornmentsType === 2 ? {
                endAdornment: <div className={`metric-style-${(value === "" || value === undefined) ? "light" : "strong"}`}>%</div>
              } : adornmentsType === 3 ? {
                startAdornment: <div className={`metric-style-${(value === "" || value === undefined) ? "light" : "strong"}`}>$</div>
              } : adornmentsType === 4 ? {
                startAdornment: <AccessTimeIcon color="primary" style={{ marginRight: "14px", width: "19px", height: "19px" }} />
              } : adornmentsType === 5 ? {
                startAdornment: <StarRoundedIcon style={{ marginRight: "14px", width: "19px", height: "19px", }} />
              } :

                {}
            }
          />
        }
      </div>

      {(withErrorArea || withMaxLength) &&
        <div className="complementary-info">
          <div className="error-message">
            {(errorMessage) &&
              <FormattedMessage
                id={errorMessage}
              />
            }
          </div>

          <div className="max-length">
            {maxLength &&
              <>
                {`${value?.length}/${maxLength}`}
              </>
            }
          </div>
        </div>
      }
    </div>

  )
}

export default TegduTextInputComponent;