import ReviewsFilters from '@components/Reviews/ReviewsFilters/ReviewsFilters';
import ReviewsTables from '@components/Reviews/ReviewsTables/ReviewsTables';
import { getReviewsBy } from '@services/reviews.service';
import { ReviewsModelI } from '@store/Reviews/reviews.model';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { TOUR_REVIEWS_TABLE } from '@samples/tour.reviews.sample';
import './styles.scss';

const ReviewsContainer = () => {
  const isTourOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const [isGiven, setIsGiven] = useState<boolean>(true);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [dataTableReview, setDataTableReview] = useState<ReviewsModelI[]>([]);

  const getAllReviewsBy = useCallback(async () => {
    setLoadingTable(true);
    try {
      const data = await getReviewsBy(isGiven ? 'given' : 'received');
      setDataTableReview(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTable(false);
    }
  }, [isGiven]);

  useEffect(() => {
    getAllReviewsBy();
  }, [getAllReviewsBy]);

  return (
    <div className='container-review-general'>
      {/* TITLE AND SUBTITLE */}
      <div className='container-title-subtitle'>
        <div className='screen-title'>
          <h1>
            <FormattedMessage id={'sidebar.reviews'} />
          </h1>
        </div>
        <span>
          <FormattedMessage id={'review.screen.decription'} />
        </span>
      </div>
      {/* FILTERS */}
      <div className='container-filters'>
        <ReviewsFilters
          onActionButtons={(selected) => setIsGiven(selected)}
          optionSelectedButton={isGiven ? 0 : 1}
        />
      </div>
      {/* TABLE */}
      <div className='container-table'>
        <ReviewsTables dataTableReview={!isTourOpen ? dataTableReview : TOUR_REVIEWS_TABLE} isGiven={isGiven} loading={!isTourOpen && loadingTable} />
      </div>
    </div>
  );
};

export default ReviewsContainer;
