import { useEffect } from 'react';
import './styles.scss'
import ObjectiveButtonSelectOption from 'pwa/components/Objectives/ObjectiveButtonSelectOption/ObjectiveButtonSelectOption';

interface BottomNewObjectiveProps{
  open: boolean;
  onClose: ()=>void;
  onActionNewObjective: ()=>void;
  onActionAddProgress: ()=>void;
}

const BottomNewObjective = (props:BottomNewObjectiveProps) => {
  const {open, onClose, onActionAddProgress, onActionNewObjective} = props;
  
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : ""
  }, [open])
  
  return (
    <div className={open ?"container-new-objective-add-progress-backdrop": "hidden-tab"}>
      <div onClick={onClose} className="backdrop"/>
      <div className="container-new-objective-add-progress">
        <div className="container-option-to-select">
            <ObjectiveButtonSelectOption 
              iconName='add'
              onAction={onActionNewObjective}
              title='objectives.dashboard.tooltip.new.objective'
            />
            <ObjectiveButtonSelectOption 
              iconName="update"
              onAction={onActionAddProgress}
              title='objectives.dashboard.tooltip.Progress'
            />
        </div>
      </div>
  </div> 
  )
}

export default BottomNewObjective