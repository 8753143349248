import { SetAddCloseModalAction, SetAddOpenModalAction } from "./ObjectivesOpenAdd.model";

export const SET_OPEN_ADD = "SET_OPEN_ADD";
export const SET_CLOSE_ADD = "SET_CLOSE_ADD";

export const setOpenAdd = () => {
  return {
    type: SET_OPEN_ADD,
    payload: true
  }
}
export const setCloseAdd = () => {
  return {
    type: SET_CLOSE_ADD,
    payload: false
  }
}

export type ObjectivesAddActions = SetAddOpenModalAction | SetAddCloseModalAction;