import { Button, Dialog, DialogContent, Grid, Icon } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditTagSchema } from '@formsValidations/formsValidations';
import { TagsModel } from '@models/helpful.model';
import { archiveTags, putTag } from '@services/settings.service';
import Spinner from '@components/Shared/Spinner/Spinner';
import GenericErrorModal from '@components/Shared/GenericErrorModal/GenericErrorModal';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';
import './styles.scss';
import { ACTIONS_CATALOGS } from '@utils/objectives';
import FormikTextInput from '@components/FormikComponents/FormikTextInput/FormikTextInput';
import withTextFastField from '@hocs/withTextFastField';

/* Formik Wrapped Components */
const FormikTextInputFastField = withTextFastField(FormikTextInput); 

interface ModalEditTagProps {
  isOpen: boolean;
  tagInfo: TagsModel;
  onClose: () => void;  
  refetchCallback: () => void;
}

const ModalEditTag = (props: ModalEditTagProps) => {
  const [loading, setLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const actions = useSelector((state: RootState) => state.User.Info.actions.settings);
  const role = useSelector((state: RootState) => state.User.Info.user.role.id);

  const { onClose, refetchCallback } = props;

  const idTag = useMemo(() => {
    if (props.tagInfo !== undefined) {
      return props.tagInfo.id;
    } else {
      return '';
    }
  }, [props.tagInfo]);

  const initialValues = useMemo(() => {
    return {
      name: props.tagInfo.name,
    };
  }, [props.tagInfo.name]);

  const allowEdit = useMemo(() => {
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.EDIT)) return true;
    return false;
  }, [actions]);

  const onSubmit = useCallback(
    async (data: any) => {
      setLoading(true);
      try {
        await putTag(idTag, data.name);
        setLoading(false);
        onClose();
        refetchCallback();
      } catch {
        setLoading(false);
      }
    },
    [idTag, onClose, refetchCallback]
  );

  const archiveTag = useCallback(async () => {
    setArchiveLoading(true);
    try {
      await archiveTags(idTag);
      setArchiveLoading(false);
      onClose();
      refetchCallback();
    } catch {
      setArchiveLoading(false);
    }
  }, [idTag, onClose, refetchCallback]);

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        className={'modal-edit-tag'}
        scroll={'body'}
        maxWidth='xs'
        disableBackdropClick
      >
        <DialogContent className='content'>
          <Grid container className={'edit-tag'}>
            <Grid item className={'title'} xs={12} sm={12} md={12}>
              <p>
                <FormattedMessage
                  id={
                    allowEdit
                      ? 'settings.tab.tags.editag.form.title'
                      : 'settings.tab.tags.detail.form.title'
                  }
                />
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={EditTagSchema}
                validateOnMount
              >
                {(formik) => {
                  let areEqual = false;
                  let validForm = formik.isValid;

                  if (JSON.stringify(formik.values) === JSON.stringify(initialValues)) {
                    areEqual = true;
                  } else {
                    areEqual = false;
                  }
                  return (
                    <Form>
                      <Grid container className='form-container'>
                        <Grid item xs={12} sm={12} md={12}>                          
                          <FormikTextInputFastField 
                            name='name'
                            labelId='general.form.label.new.tag'
                            viewMode={allowEdit ? false : true}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          className={`objectives-section 
                                                    ${
                                                      props.tagInfo.tgd_tag_objectives.length > 4
                                                        ? 'scroll'
                                                        : ''
                                                    }
                                                `}
                        >
                          <p id='objectives-title'>
                            <FormattedMessage
                              id={'settings.tab.tags.editag.form.objectives'}
                              values={{ objectives: props.tagInfo.tgd_tag_objectives.length }}
                            />
                          </p>

                          {props.tagInfo.tgd_tag_objectives.length !== 0 ? (
                            <div className='objectives'>
                              {props.tagInfo.tgd_tag_objectives.map((data, index) => {
                                return <p key={index}>{data.tgd_objective.name}</p>;
                              })}
                            </div>
                          ) : (
                            <div className='objectives empty'>
                              <p>
                                <FormattedMessage id='settings.tab.tags.no.objectives' />
                              </p>
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className={'buttons'}>
                          <div className='start-btns'>
                            {role !== 6 ?
                            <> </>
                            :
                            <div className={'archive-btn'}>
                              <Button className={'secondary'} onClick={archiveTag}>
                                {archiveLoading === true ? (
                                  <Spinner color='white' size={15} thickness={5} />
                                ) : (
                                  <Icon>archive</Icon>
                                )}

                                <FormattedMessage id={'settings.tag.tags.archive.tag'} />
                              </Button>
                            </div>
                            }
                          </div>

                          <div className='end-btns'>
                            <div className={'cancel-btn'}> 
                              <Button className={'secondary'} onClick={props.onClose}>
                                <FormattedMessage id={'statement.cancel'} />
                              </Button>
                            </div>
                            {allowEdit && (
                              <div className={'save-btn'}>
                                <Button type='submit' disabled={areEqual === validForm}>
                                  {loading === true && (
                                    <Spinner color='white' size={15} thickness={5} />
                                  )}
                                  <FormattedMessage id={'statement.save'} />
                                </Button>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <GenericErrorModal
        isOpen={errorModal}
        handleClose={() => setErrorModal(false)}
        intlMessage={{
          id: 'general.modal.error.label',
          values: { action: 'updating', subject: 'tag' },
        }}
      />
    </>
  );
};

export default ModalEditTag;
