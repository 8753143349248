import DataTable, { RowRecord } from 'react-data-table-component';
import { ArrowUpward } from '@material-ui/icons';
import NoDataTable from '../NoDataTable/NoDataTable';
import Spinner from '../Spinner/Spinner';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { JSXElementConstructor, ReactElement } from 'react';

import './styles.scss';

interface GenericTableProps {
  columns: any[];
  data: any[];
  pagination: boolean;
  customStyles?: any;
  loading: boolean;
  error: any;
  onRowClicked?: (info: any) => void
  pointerOnHover?: boolean
  retryCallback?: () => void
  label: 'members' | 'teams' | 'objetives' | 'teams' | 'tags' | 'drafts' | 'reviews' | 'invitations' | 'archived' | 'skills-aptitudes'
  paginationPerPage?: number
  totalRows?: number
  onChangePage?: () => void
  selectableRows?: boolean;
  selectableRowsComponent?: ReactElement<any, string | JSXElementConstructor<any>> | "input" | undefined;
  selectableRowsComponentProps?: RowRecord | undefined;
  clearSelectedRows?: boolean | undefined;
  onSelectedRowsChange?: ((selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void) | undefined
  subHeader?: boolean;
  expandableRows?: boolean;
  expandableRowsComponent?: ReactElement<any, string | JSXElementConstructor<any>>
}

const paginationComponentOptions = {
  rangeSeparatorText: 'of',
  noRowsPerPage: true,
  selectAllRowsItem: true,
  selectAllRowsItemText: 'All',
};

const GenericTable = (props: GenericTableProps) => {

  return (
    <div className={'generic-data-table'}>
      <DataTable
        progressPending={props.loading}
        persistTableHead
        highlightOnHover
        columns={props.columns}
        data={props.data}
        pagination={props.pagination}
        paginationComponentOptions={paginationComponentOptions}
        sortIcon={
          <div className='generic-sort-icon'>
            <ArrowUpward />
          </div>
        }
        customStyles={props.customStyles}
        expandableRows={props.expandableRows}
        expandableRowsComponent={props.expandableRowsComponent}
        onRowClicked={props.onRowClicked}
        pointerOnHover={props.pointerOnHover}
        paginationPerPage={props.paginationPerPage}
        paginationTotalRows={props.totalRows}
        onChangePage={props.onChangePage}
        subHeader={props.subHeader}
        selectableRows={props.selectableRows}
        selectableRowsComponent={props.selectableRowsComponent}
        selectableRowsComponentProps={props.selectableRowsComponentProps}
        onSelectedRowsChange={props.onSelectedRowsChange}
        clearSelectedRows={props.clearSelectedRows}
        progressComponent={
          <div className='screen-loader'>
            <Spinner size={40} color='primary' thickness={4} />
          </div>
        }
        noDataComponent={
          <>
            {/* If no data available and not loading error ...*/}
            {!props.error && !props.loading && <NoDataTable labelId={props.label} />}

            {/* If loadding error ... */}
            {props.error && !props.loading && (
              <div className='table-error-message'>
                <p>
                  <FormattedMessage id={'general.table.error.message'} />
                </p>
                <Button className='try-btn' onClick={props.retryCallback}>
                  <FormattedMessage id='general.button.try.again' />
                </Button>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default GenericTable;
