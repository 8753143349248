import Icon from "@material-ui/core/Icon";
import { bottomBarMenuOptions } from "./BottomBarMenuOptions";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.scss";

interface BottomBarMenuProps {
  handleNamesRoutes?: (name: string) => void;
  onActionAdd?: ()=>void
  isAddActionsOpen?: boolean
}

const BottomBarMenu = ({ handleNamesRoutes, onActionAdd, isAddActionsOpen }: BottomBarMenuProps) => {
  const location = useLocation();
  const history = useHistory();

  const onChangeSection = (route: any, index:number) => {
    if (index === 2 && onActionAdd) {
       onActionAdd()
    }else{
      history.push(route.href);
      handleNamesRoutes && handleNamesRoutes(route.title);
    }
  };

  return (
    <div className="container-bottom-menu">
      {bottomBarMenuOptions.map((e, index) => {
        const currentLocation = location.pathname
          .toLocaleLowerCase()
          .startsWith(e?.href);
        return (
          <div
            key={index}
            onClick={() => onChangeSection(e, index)}
            className="menu-each-one"
          >
              <Icon
                color={
                  !currentLocation || index === 4
                    ? "disabled"
                    : "primary"
                }
                className={isAddActionsOpen && index === 2? "hidden-icon-plus":"icon"}
              >
                {e.icon}
              </Icon>

          </div>
        );
      })}
    </div>
  );
};

export default BottomBarMenu;
