import GenericTable from '@components/Shared/GenericTable/GenericTable';
import SimpleTag from '@components/Shared/SimpleTag/SimpleTag';
import { Card } from '@material-ui/core';
import { TagsModel } from '@models/helpful.model';
import React, { useCallback } from 'react';

interface TagsTableProps {
  archivedTable?: boolean
  data?: TagsModel[];
  loading: boolean;
  error: any;
  rowClicked: (data: any) => void;
  retryCallback?: () => void;
}

const columns = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
    cell: (row?: any) => 
      <SimpleTag 
        tag={row.name} 
        color={row.is_archived === true ? 'archived' : 'primary'} 
        width={'64 px'} 
      />,
  },
  {
    name: 'OKRs',
    selector: 'okr',
    sortable: true,
    cell: (row?: TagsModel) => <> {row?.tgd_tag_objectives.length} OKR's</>,
  },
];

const TagsTable = (props: TagsTableProps) => {
  const { rowClicked } = props;

  const onRowClicked = useCallback(
    (data: any) => {
      rowClicked(data);
    },
    [rowClicked]
  );

  return (
    <Card>
      <GenericTable
        data={props.data}
        pagination={true}
        loading={props.loading}
        error={props.error}
        label={'tags'}
        columns={columns}
        onRowClicked={onRowClicked}
        pointerOnHover={true}
        retryCallback={props.retryCallback}
      />
    </Card>
  );
};

export default TagsTable;
