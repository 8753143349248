import { RootState } from '@store/rootReducer'
import React from 'react'
import { useSelector } from 'react-redux'
import EvaluationsContainer from '../EvaluationsContainer/EvaluationsContainer';
import QuestionnairesContainer from '../QuestionnairesContainer/QuestionnairesContainer';
import SkillsAptitudesContainer from '../SkillsAptitudesContainer/SkillsAptitudesContainer';

const HRTabsContainer = () => {
	const hrTabState = useSelector((state: RootState) => state.HRTeam.hrTeamActiveTabState);
		switch(hrTabState) {
			case 0:
				return <EvaluationsContainer />;
			case 1: 
				return <QuestionnairesContainer />;
			case 2:
				return <SkillsAptitudesContainer />;
			default:
				return <SkillsAptitudesContainer />;
		}
}

export default HRTabsContainer
