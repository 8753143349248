import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import UserInfoCard from '@components/Dashboard/UserInfoCard/UserInfoCard';
import DashboardGenericCard from '@components/Dashboard/DashboardGenericCard/DashboardGenericCard';
import { useQuery } from '@apollo/client';
import { GqlGetPillarsForDashboard, GqlGetStatementsForDashboard } from '@models/catalogs.model';
import { RootState } from '@store/rootReducer';
import { useSelector } from 'react-redux';
import { GET_PILLARS_DASHBOARD, GET_STATEMENTS_DASHBOARD } from '@graphql/dashboard.queries';
import { getObjectives } from '@services/objectives.service';
import { MEMBER_ROLE } from '@utils/members';
import { ApiGetObjective, DashboardActiveObjective } from '@models/objetives.model';
import { ACTIONS_CATALOGS, OBJECTIVE_STATUS } from '@utils/objectives';
import { TOUR_DASHBOARD_STATEMENTS_SAMPLE, TOUR_DASHBOARD_STATEMENTS,TOUR_DASHBOARD_CULTURAL_ATT , TOUR_DASHBOARD_OBJ_PROGRESS } from '@samples/tour.dashboard.sample';
function DashboardContainer() {
  const isTourOpen = useSelector((state: RootState) => state.Shared.tour.isOpen);
  const organizationId = useSelector((state: RootState) => state.Organization.Info.organizationId);
  const actions = useSelector((state: RootState) => state.User.Info.actions.dashboard);
  const [loadingObjectives, setLoadingObjectives] = useState<boolean>(true);
  const [errorObjectives, setErrorObjectives] = useState<any>(undefined);
  const [activeObjectives, setActiveObjectives] = useState<DashboardActiveObjective | null>(null);
  const [lastObjectives, setLastObjectives] = useState<ApiGetObjective[]>([]);

  const {
    data: pillars,
    loading: loadingPillars,
    error: errorPillars,
  } = useQuery<GqlGetPillarsForDashboard>(GET_PILLARS_DASHBOARD, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
  });
  //Set diferent statements where tour is open
  const {
    data: statements,
    loading: loadingStatements,
    error: errorStatements,
  } = useQuery<GqlGetStatementsForDashboard>(GET_STATEMENTS_DASHBOARD, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
  });

  const allowAdd = useMemo(() => {
    //Add action allow let user add statement and pillars from dashboard
    if (actions?.find((action) => action.key_name === ACTIONS_CATALOGS.ADD)) {
      return true;
    }
    return false;
  }, [actions]);

  const getObjectivesData = useCallback(async () => {
    setLoadingObjectives(true);
    try {
      const asOwner = await getObjectives(MEMBER_ROLE.owner);
      const asCoach = await getObjectives(MEMBER_ROLE.coach);
      const asCollaborator = await getObjectives(MEMBER_ROLE.collaborator);

      const activeAsOwner = asOwner.filter((obj) => obj.status === OBJECTIVE_STATUS.active);
      const activeAsCoach = asCoach.filter((obj) => obj.status === OBJECTIVE_STATUS.active);
      const activeAsCollaborator = asCollaborator.filter(
        (obj) => obj.status === OBJECTIVE_STATUS.active
      );

      let allActiveObjectives = [];
      if (asCollaborator.length) {
        allActiveObjectives = Array.from(
          new Set([...activeAsOwner, ...activeAsCoach, ...activeAsCollaborator])
        );
      } else {
        allActiveObjectives = [...activeAsOwner, ...activeAsCoach];
      }
      allActiveObjectives.sort((a, b) =>
        a.progress > b.progress ? 1 : a.progress < b.progress ? -1 : 0
      );

      setActiveObjectives(() => {
          return {
            owner: {
              totals: activeAsCoach.map((obj) => obj.progress),
            },
            coach: {
              totals: activeAsCoach.map((obj) => obj.progress),
            },
            collaborator: {
              totals: activeAsCollaborator.map((obj) => obj.progress),
            },
          };
      });

      setLastObjectives(allActiveObjectives.slice(0, 2));
    } catch (error) {
      setErrorObjectives(error);
    } finally {
      setLoadingObjectives(false);
    }
  }, []);

  useEffect(() => {
    getObjectivesData();
  }, [getObjectivesData]);
  return (
    <Grid id='dashboard' container spacing={2}>
      <Grid sm={12} xs={12} lg={6} item>
        <UserInfoCard
          activeObjectives={isTourOpen ? TOUR_DASHBOARD_OBJ_PROGRESS : activeObjectives}
          lastObjectives={isTourOpen ? TOUR_DASHBOARD_STATEMENTS_SAMPLE : lastObjectives}
          loading={isTourOpen ? false : loadingObjectives}
          error={errorObjectives}
        />
      </Grid>
      <Grid item sm={12} xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid sm={6} xs={12} lg={12} item>
            <DashboardGenericCard
              loading={isTourOpen ? false : loadingStatements}
              error={errorStatements}
              id='statements'
              titleId='dashboard.statements.title'
              data={isTourOpen ? TOUR_DASHBOARD_STATEMENTS : statements ? statements.tgdStatements : []}
              allowAdd={allowAdd}
              count={isTourOpen ? TOUR_DASHBOARD_STATEMENTS.length : (statements ? (statements.tgdStatements ? statements.tgdStatements.length : 0) : 0)}
            />
          </Grid>
          <Grid sm={6} xs={12} lg={12} item>
            <DashboardGenericCard
              loading={isTourOpen ? false : loadingPillars}
              error={errorPillars}
              id='pillars'
              titleId='dashboard.culturalAttributes.title'
              data={isTourOpen ? TOUR_DASHBOARD_CULTURAL_ATT : pillars ? pillars.tgdPilars : []}
              allowAdd={allowAdd}
              count={isTourOpen ? TOUR_DASHBOARD_CULTURAL_ATT.length : (pillars ? (pillars.tgdPilars ? pillars.tgdPilars.length : 0) : 0)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardContainer;
