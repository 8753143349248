import React from 'react';
import Fade from '@material-ui/core/Fade';
import { FormattedMessage } from 'react-intl';
import ErrorIcon from '@material-ui/icons/Error';

import './styles.scss';

interface WarningAlertProps {
  show: boolean;
  textId: string; // Intl
  className?: string;
}

const WarningAlert = ({ show, textId, className }: WarningAlertProps) => {
  return (
    <Fade in={show} disableStrictModeCompat>
      <div className={`component-warningalert ${className ? className : ''}`}>
        <ErrorIcon />
        <label>{textId && <FormattedMessage id={textId} />}</label>
      </div>
    </Fade>
  );
};

export default WarningAlert;
