import RoundButton from '@components/Shared/RoundButton/RoundButton';
import TegduTextArea from '@components/TegduFormComponents/TegduTextArea/TegduTextArea';
import TegduTextInputComponent from '@components/TegduFormComponents/TegduTextInputComponent/TegduTextInputComponent';
import withTextAreaController from '@hocs/withTextAreaController';
import withTextInputController from '@hocs/withTextInputController';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog } from '@material-ui/core';
import { createSkillsAptitudesAction } from '@store/HRTeam/HRTeamTabs/hrteam.action';
import { SkillsAptitudesTabState } from '@store/HRTeam/HRTeamTabs/hrteam.model';
import { RootState } from '@store/rootReducer';
import { createSkillsAptitudesRequest } from '@utils/hrteam/create.skillsaptitudes.utils';
import { TYPE_SKILLS_APTITUDES } from 'lib/enums/skillsaptitudes.enum';
import { createSkillsAptitudesFormInitialValues, CreateSkillsAptitudesFormModel, CreateSkillsAptitudesFormSchema } from 'lib/TegduForms/SkillsAptitudesFormUtils';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss'

const TegduTextInputForm = withTextInputController(TegduTextInputComponent);
const TegduTextAreaInputForm = withTextAreaController(TegduTextArea);

interface SkillsAptitudesModalInterface {
  isOpen: boolean
	handleClose: () => void
	getSkillsMethod: () => Promise<void>
	getAllMethod: () => Promise<void>
	getAptitudesMethod: () => Promise<void>
}

const SkillsAptitudesModal = (props: SkillsAptitudesModalInterface) => {
	const { isOpen, handleClose, getSkillsMethod, getAllMethod, getAptitudesMethod } = props;

	const dispatch = useDispatch();
	const [buttonType, setButtonType] = useState<TYPE_SKILLS_APTITUDES>(TYPE_SKILLS_APTITUDES.skill)
	const [initialData, /*setInitialData*/] = useState<CreateSkillsAptitudesFormModel>(createSkillsAptitudesFormInitialValues)
	const skillsAptitudesTabState = useSelector((state: RootState) => state.HRTeam.skillsAptitudesTabState);

	const methods = useForm<CreateSkillsAptitudesFormModel>({
		defaultValues: initialData,
		mode: "onChange",
		resolver: yupResolver(CreateSkillsAptitudesFormSchema)
	})

	const handleButtonType = useCallback((type: TYPE_SKILLS_APTITUDES) => {
		setButtonType(type)
		methods.setValue('type', type)
	}, [methods])

	const handleSuccessEvents = useCallback(() => {
		methods.reset(initialData)
		handleClose()
		if (skillsAptitudesTabState === SkillsAptitudesTabState.all){
			getAllMethod()
		} else if (skillsAptitudesTabState === SkillsAptitudesTabState.skills){
			getSkillsMethod()
		} else if (skillsAptitudesTabState === SkillsAptitudesTabState.aptitudes){
			getAptitudesMethod()
		}
	}, [getAllMethod, getAptitudesMethod, getSkillsMethod, handleClose, initialData, methods, skillsAptitudesTabState])

	const handleFormSubmit = useCallback((data: CreateSkillsAptitudesFormModel) => {
		dispatch(createSkillsAptitudesAction(
			createSkillsAptitudesRequest(data),
			handleSuccessEvents
		))
	}, [dispatch, handleSuccessEvents])

  return (
		<FormProvider {...methods}>
			<Dialog
				open={isOpen}
				onClose={handleClose}
				className="skills-aptitudes-modal"
				disableBackdropClick
				fullWidth
				>
				<div className="form-title-container">
					<h1 className='form-title'>
							<FormattedMessage id='hr.skills.aptitudes.button.new'/>
					</h1>
				</div>

				<form
					id='skills-aptitudes-form'
					className='skills-aptitudes-main-form'
					onSubmit={methods.handleSubmit(handleFormSubmit)}
					>
					<TegduTextInputForm 
						name='name'
						labelId='hr.skills.aptitudes.form.name'
						control={methods.control}
						errorMessage={methods.formState.errors.name?.message}
						maxLength={50}
						withErrorArea
						isEditMode
						/>

					<div className="type-container">
						<span className='type-title'>
							<FormattedMessage id='hr.skills.aptitudes.form.type'/>
						</span>
						<div className="round-buttons-container">
							<RoundButton 
								selected={buttonType === TYPE_SKILLS_APTITUDES.skill}
								text={'hr.skills.aptitudes.skills'}
								type={TYPE_SKILLS_APTITUDES.skill}
								onButtonClick={handleButtonType}
								/>
							<RoundButton 
								selected={buttonType === TYPE_SKILLS_APTITUDES.aptitude}
								text={'hr.skills.aptitudes.aptitudes'}
								type={TYPE_SKILLS_APTITUDES.aptitude}
								onButtonClick={handleButtonType}
								/>
						</div>
					</div>

					<div className="description-container">
						<TegduTextAreaInputForm 
							name='description'
							labelMessageId='hr.skills.aptitudes.form.description'
							control={methods.control}
							errorMessage={methods.formState.errors.description?.message}
							maxLength={150}
							withMaxLength
							isEditMode
							withErrorArea
						/>
					</div>
				</form>

				<div className="buttons-container">
					<Button
						className='secondary'
						style={{width: "111px"}}
						onClick={handleClose}
					>
						<FormattedMessage id="hr.skills.aptitudes.button.cancel"/>
					</Button>
					<Button
						type='submit'
						form={"skills-aptitudes-form"} 
						style={{width: "111px"}}
					>
						<FormattedMessage id="hr.skills.aptitudes.button.create"/>
					</Button>
				</div>
			</Dialog>
		</FormProvider>
  )
}

export default SkillsAptitudesModal
